import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, retry, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor{
  private refreshTokenInProgress = false;
  private refreshToken$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.addAuthToken(req);

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.url !== environment.apiTokenUrl && error.status === 401) {
          if (this.refreshTokenInProgress) {
            return this.refreshToken$.pipe(
              filter(result => result !== null),
              take(1),
              switchMap((success) => {
                if (success) {
                  return next.handle(this.addAuthToken(req));
                } else {
                  return throwError(error);
                }
              })
            );
          } else {
            this.refreshTokenInProgress = true;
            this.refreshToken$.next(null);

            return this.authService.refreshToken().pipe(
              switchMap((success: boolean) => {
                this.refreshToken$.next(success);
                if (success) {
                  return next.handle(this.addAuthToken(req));
                } else {
                  return throwError(error);
                }
              }),
              finalize(() => {this.refreshTokenInProgress = false;})
            );
          }
        } else {
          return throwError(error);
        }
      }),
      retry(2)
    );
  }

  addAuthToken(req: HttpRequest<any>): HttpRequest<any> {
    let authToken: string | null = null;
    // These 3 APIs are called with no signed in user, they use the generic auth token, rather than a user specific one
    if (req.url === environment.apiTokenUrl || req.url === environment.apiForgotPasswordUrl || req.url === environment.apiResetPasswordUrl) {
      authToken = environment.apiTokenAuthToken;
    } else if (this.authService.token !== null) {
      authToken = 'Bearer ' + this.authService.token;
    }

    if (authToken !== null) {
      return req.clone({
        setHeaders: {
          'Authorization': authToken
        }
      });
    }

    return req;
  }
}
