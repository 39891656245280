export enum Humboldt2020ResourceFamilyApprovalActivityActivityOption {
  InterviewAssessment = 'Interview/assessment',
  CorrespondenceStaffing = 'Correspondence/staffing',
  GatherMaintainReviewDocumentation = 'Gather/maintain/review documentation',
  ReportWriting = 'Report writing',
  ComplaintForm = 'Complaint form',
  LegalConsult = 'Legal consult',
  ProvideTraining = 'Provide training',
  Other = 'Other'
}
