import { Component, OnDestroy } from '@angular/core';
import { TitleService } from '../title/title.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProcessingDialogData } from '../processing-dialog/processing-dialog-data';
import { ProcessingDialogComponent } from '../processing-dialog/processing-dialog.component';
import { DatabaseService } from '../database/database.service';
import { UserData } from '../auth/user-data';
import { OnlineService } from '../online/online.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css'],
  host: {'class' : 'mat-elevation-z4'}
})
export class AppHeaderComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  isLoggedIn: boolean | null = null;
  user: UserData | null = null;
  title = '';
  appVersion = '3.2';
  isProduction = environment.production;

  constructor(
    public authService: AuthService,
    private databaseService: DatabaseService,
    private dialog: MatDialog,
    public onlineService: OnlineService,
    private titleService: TitleService
  ) {
    this.authService.isLoggedIn.pipe(takeUntil(this.unsubscribe$)).subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      this.user = this.authService.user;
    });
    this.titleService.title.pipe(takeUntil(this.unsubscribe$)).subscribe(title => this.title = title);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearDataDialog(): void {
    this.dialog.open(MessageDialogComponent, {
      data: {
        title: 'Clear Local Device Data',
        message: 'This will clear offline data stored on the local device. Any offline data that has not been synced to the data server for any users will be lost.' +
          ' Are you sure you want to do this?',
        okButtonLabel: 'Clear Local Device Data'
      },
      width: '800px'
    }).afterClosed().subscribe((result: boolean | null) => {
      if (result) {
        this.clearData();
      }
    });
  }

  async clearData(): Promise<void> {
    const processingData: ProcessingDialogData = {
      message: new BehaviorSubject<string>('')
    };
    this.dialog.open(ProcessingDialogComponent, {
      data: processingData,
      disableClose: true,
      width: '800px'
    });

    try {
      processingData.message.next('Clearing offline data');
      await this.databaseService.clearAllData();
      processingData.message.complete();
    } catch(error) {
      processingData.message.error(error);
    }
  }
}
