export enum FormStatus {
  InProgress = "In Progress",
  InReview = "In Review",
  Completed = "Completed",
  Deleted = "Deleted"
}

export const FormStatuses = [
  FormStatus.InProgress,
  FormStatus.InReview,
  FormStatus.Completed,
  FormStatus.Deleted
];
