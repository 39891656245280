import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { FormComponent } from '../../../form/form.component';
import { getFormTypeById } from '../../form-type-data';
import { MatSort } from '@angular/material/sort';
import { Validators } from '@angular/forms';
import { isArray } from "util";
import { debounceTime } from 'rxjs/operators';
import { FormStatus } from '../../../form/form-status.enum';
import * as moment from 'moment';
import { MessageDialogComponent } from '../../../message-dialog/message-dialog.component';
import { Humboldt2020ResourceFamilyApprovalFormData } from './humboldt2020-resource-family-approval-form-data';
import { Humboldt2020ResourceFamilyApprovalActivityListColumn } from './humboldt2020-resource-family-approval-activity-list-column.enum';
import { Humboldt2020ResourceFamilyApprovalActivityActivityOption } from './humboldt2020-resource-family-approval-activity-dialog/humboldt2020-resource-family-approval-activity-activity-option.enum';
import { Humboldt2020ResourceFamilyApprovalAssignmentTypeOption } from './humboldt2020-resource-family-approval-assignment-type-option.enum';
import { Humboldt2020ResourceFamilyApprovalRfTypeOption } from './humboldt2020-resource-family-approval-rf-type-option.enum';
import { Humboldt2020ResourceFamilyApprovalTribalAffiliationOption } from './humboldt2020-resource-family-approval-tribal-affiliation-option.enum';
import { Humboldt2020ResourceFamilyApprovalTribalAffiliationTypeOption } from './humboldt2020-resource-family-approval-tribal-affiliation-type-option.enum';
import { Humboldt2020ResourceFamilyApprovalComplaintInvestigationFindingsOption } from './humboldt2020-resource-family-approval-complaint-investigation-findings-option.enum';
import { Humboldt2020ResourceFamilyApprovalStudyCaseOccurrenceOption } from './humboldt2020-resource-family-approval-study-case-occurrence-option.enum';
import { Humboldt2020ResourceFamilyApprovalReasonsStandardsNotMetOption } from './humboldt2020-resource-family-approval-reasons-standards-not-met-option.enum';
import { Humboldt2020FacilityNumberValidator, noneApplyValidator } from '../../../validators';
import { Humboldt2020ResourceFamilyApprovalActivityListFilterDialogComponent } from './humboldt2020-resource-family-approval-activity-list-filter-dialog/humboldt2020-resource-family-approval-activity-list-filter-dialog.component';
import { Humboldt2020ResourceFamilyApprovalActivityDialogComponent } from './humboldt2020-resource-family-approval-activity-dialog/humboldt2020-resource-family-approval-activity-dialog.component';
import { YesNoOption } from '../../../form/yes-no-option.enum';
export class Humboldt2020ResourceFamilyApprovalComponent extends FormComponent {
    constructor(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, titleService) {
        super(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, Humboldt2020ResourceFamilyApprovalFormData);
        this.apiService = apiService;
        this.authService = authService;
        this.breakpointObserver = breakpointObserver;
        this.databaseService = databaseService;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.onlineService = onlineService;
        this.route = route;
        this.router = router;
        this.titleService = titleService;
        this.formTypeId = 9;
        this.formType = getFormTypeById(this.formTypeId);
        this.formDataClass = Humboldt2020ResourceFamilyApprovalFormData;
        this.currentFormData = null;
        this.activitiesDataSource = [];
        this.activityListColumnEnum = Humboldt2020ResourceFamilyApprovalActivityListColumn;
        this.activityListDisplayedColumns = Object.values(Humboldt2020ResourceFamilyApprovalActivityListColumn);
        this.activityListFilterForm = this.formBuilder.group({
            activity: [null],
            sorting: [null]
        });
        this.activityListFilters = {
            activity: null,
            sortColumn: null,
            sortDirection: null
        };
        this.activityListFilterInfoString = '';
        this.activityOptions = Object.values(Humboldt2020ResourceFamilyApprovalActivityActivityOption);
        this.activityListSortOptions = [
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.activityDate, sortDirection: 'asc', display: 'Date Ascending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.activityDate, sortDirection: 'desc', display: 'Date Descending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.activity, sortDirection: 'asc', display: 'Activity Ascending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.activity, sortDirection: 'desc', display: 'Activity Descending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.personInvolved, sortDirection: 'asc', display: 'Person Involved Ascending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.personInvolved, sortDirection: 'desc', display: 'Person Involved Descending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.method, sortDirection: 'asc', display: 'Method Ascending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.method, sortDirection: 'desc', display: 'Method Descending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.place, sortDirection: 'asc', display: 'Place Ascending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.place, sortDirection: 'desc', display: 'Place Descending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.activityMinutesSpent, sortDirection: 'asc', display: 'Activity Minutes Spent Ascending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.activityMinutesSpent, sortDirection: 'desc', display: 'Activity Minutes Spent Descending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.travelMinutesSpent, sortDirection: 'asc', display: 'Travel Minutes Spent Ascending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.travelMinutesSpent, sortDirection: 'desc', display: 'Travel Minutes Spent Descending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.activityDescription, sortDirection: 'asc', display: 'Description Ascending' },
            { sortColumn: Humboldt2020ResourceFamilyApprovalActivityListColumn.activityDescription, sortDirection: 'desc', display: 'Description Descending' },
        ];
        this.form = this.formBuilder.group({
            agentName: [null],
            createdDate: [null],
            status: [null],
            statusChangedDate: [null],
            updatedDate: [null],
            rfName: [null, [Validators.required, Validators.maxLength(200)]],
            facilityNumber: [null, [Validators.required, Validators.maxLength(9), Humboldt2020FacilityNumberValidator]],
            assignmentDate: [null, Validators.required],
            assignmentType: [null, Validators.required],
            rfType: [null, Validators.required],
            tribalAffiliation: [null],
            tribalAffiliationType: [[]],
            adultsLivingInHome: [null, [Validators.required, Validators.min(0), Validators.max(99)]],
            regularlyPresentAdultsInHome: [null, [Validators.required, Validators.min(0), Validators.max(99)]],
            youthLivingInHome: [null, [Validators.required, Validators.min(0), Validators.max(99)]],
            icpc: [null],
            comments: [null, Validators.maxLength(500)],
            investigatedErReferralOnThisFacilityDuringTrackingPeriod: [null],
            complaintInvestigationFindings: [null],
            studyCaseOccurrence: [[], [Validators.required, noneApplyValidator]],
            activityListFilter: this.activityListFilterForm,
            standardsMet: [null],
            reasonsStandardsNotMet: [[]],
            supervisorComments: [null, Validators.maxLength(500)]
        });
        this.assignmentTypeOptions = Object.values(Humboldt2020ResourceFamilyApprovalAssignmentTypeOption);
        this.assignmentTypeOptionEnum = Humboldt2020ResourceFamilyApprovalAssignmentTypeOption;
        this.rfTypeOptions = Object.values(Humboldt2020ResourceFamilyApprovalRfTypeOption);
        this.rfTypeOptionEnum = Humboldt2020ResourceFamilyApprovalRfTypeOption;
        this.tribalAffiliationOptionEnum = Humboldt2020ResourceFamilyApprovalTribalAffiliationOption;
        this.tribalAffiliationOptions = Object.values(Humboldt2020ResourceFamilyApprovalTribalAffiliationOption);
        this.tribalAffiliationTypeOptions = Object.values(Humboldt2020ResourceFamilyApprovalTribalAffiliationTypeOption);
        this.complaintInvestigationFindingsOptions = Object.values(Humboldt2020ResourceFamilyApprovalComplaintInvestigationFindingsOption);
        this.studyCaseOccurrenceOptions = Object.values(Humboldt2020ResourceFamilyApprovalStudyCaseOccurrenceOption);
        this.reasonsStandardsNotMetOptions = Object.values(Humboldt2020ResourceFamilyApprovalReasonsStandardsNotMetOption);
        this.showTribalAffiliationTypeQuestion = false;
        this.showInvestigatedErReferralOnThisFacilityDuringTrackingPeriodQuestion = false;
        this.loadFormTypeSettings();
        this.formChangeSubscriptions();
        this.saveFormDialogErrorData.message = 'You must provide a RF Name to save the form.';
        this.sendBackFormDialogErrorData.message = 'You must provide a RF Name to save the form.';
        this.discardChangesDialogData.message = 'If you discard changes, you will lose any changes you have made to this' +
            ' form since you last saved it. This includes any changes to sample information, sample updates, and activities.' +
            ' Are you sure you want to do this?';
        this.submitFormDialogData.message = 'Submitting the form will send it to your supervisor for review and you will no longer be able to edit this form. Make sure you have:';
        this.submitFormDialogData.messageList = [
            'Completed all of the fields in the Sample Information and Sample Updates tabs.',
            'Recorded all the time spent working on this RFA study case during the month.'
        ];
        this.sendBackFormDialogErrorData.title = 'Send Back to Caseworker Error';
        this.sendBackFormDialogData.title = 'Send Back to Caseworker';
        this.sendBackFormDialogData.message = 'This action will send the form back to the Caseworker for modification.' +
            ' This will revert the status of the form to "In Progress" and' +
            ' you will not be able to edit the form until the Caseworker submits it again.' +
            ' Are you sure you want to do this?';
        this.sendBackFormDialogData.okButtonLabel = 'Send Back to Caseworker';
        this.completeFormDialogData.message = 'Approving the form will remove it from your queue and you will no longer be able to edit this form. Make sure:';
        this.completeFormDialogData.messageList = [
            'The form captures all the work that was completed by the caseworker.',
            'You have reviewed the form for required work activities designated by standards.',
            'Time is recorded in minutes.'
        ];
    }
    set activityListSortViewChild(value) {
        this.activityListSort = value;
        // This timeout is needed or angular complains Expression has changed after it was checked.
        window.setTimeout(() => {
            // default in the current set sorting when the sorter is set
            // this happens if the width goes from isSmallDisplay to isLargeDisplay
            if (this.activityListSort && this.activityListFilterForm && this.activityListFilterForm.controls.sorting.value) {
                const sorting = this.activityListFilterForm.controls.sorting.value;
                if (this.activityListSort.active != sorting.sortColumn || this.activityListSort.direction != sorting.sortDirection) {
                    if (this.activityListSort) {
                        this.activityListSort.sort({
                            id: sorting.sortColumn,
                            start: sorting.sortDirection,
                            disableClear: true
                        });
                    }
                }
            }
        });
    }
    /* adds subscriptions for each of the form fields to update this.currentFormData */
    formChangeSubscriptions() {
        this.form.controls.rfName.valueChanges.subscribe(rfName => {
            if (this.currentFormData && this.currentFormData.name !== rfName) {
                this.currentFormData.name = rfName;
                this.saveCurrentFormValues();
            }
            if (this.formType && this.currentFormData) {
                const title = this.formType.name + ' - ' + this.currentFormData.name;
                if (this.titleService.title.value !== title) {
                    this.titleService.title.next(title);
                }
            }
        });
        this.form.controls.facilityNumber.valueChanges.subscribe(facilityNumber => {
            if (this.currentFormData && this.currentFormData.facilityNumber !== facilityNumber) {
                this.currentFormData.facilityNumber = facilityNumber;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.assignmentDate.valueChanges.subscribe(assignmentDate => {
            const numberValue = assignmentDate !== null ? parseInt(assignmentDate.format('x')) : null;
            if (this.currentFormData && this.currentFormData.assignmentDate !== numberValue) {
                this.currentFormData.assignmentDate = numberValue;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.assignmentType.valueChanges.subscribe(assignmentType => {
            if (this.currentFormData && this.currentFormData.assignmentType !== assignmentType) {
                this.currentFormData.caseType = assignmentType;
                this.currentFormData.assignmentType = assignmentType;
                this.saveCurrentFormValues();
                this.updateIcpcValidators();
                this.updateInvestigatedErReferralOnThisFacilityDuringTrackingPeriodValidators();
                this.updateComplaintInvestigationFindingsValidators();
            }
        });
        this.form.controls.rfType.valueChanges.subscribe(rfType => {
            if (this.currentFormData && this.currentFormData.rfType !== rfType) {
                this.currentFormData.rfType = rfType;
                this.saveCurrentFormValues();
                this.updateTribalAffiliationValidators();
                this.updateTribalAffiliationTypeValidators();
            }
        });
        this.form.controls.tribalAffiliation.valueChanges.subscribe(tribalAffiliation => {
            if (this.currentFormData && this.currentFormData.tribalAffiliation !== tribalAffiliation) {
                this.currentFormData.tribalAffiliation = tribalAffiliation;
                this.saveCurrentFormValues();
                this.updateTribalAffiliationTypeValidators();
            }
        });
        this.form.controls.tribalAffiliationType.valueChanges.subscribe(tribalAffiliationTypes => {
            let tribalAffiliationTypeLocalTribe = false;
            let tribalAffiliationTypeNonLocalTribe = false;
            if (isArray(tribalAffiliationTypes)) {
                tribalAffiliationTypes.forEach(tribalAffiliationType => {
                    switch (tribalAffiliationType) {
                        case Humboldt2020ResourceFamilyApprovalTribalAffiliationTypeOption.LocalTribe:
                            tribalAffiliationTypeLocalTribe = true;
                            break;
                        case Humboldt2020ResourceFamilyApprovalTribalAffiliationTypeOption.NonLocalTribe:
                            tribalAffiliationTypeNonLocalTribe = true;
                            break;
                    }
                });
            }
            let saveCurrentFormValues = false;
            if (this.currentFormData && this.currentFormData.tribalAffiliationTypeLocalTribe !== tribalAffiliationTypeLocalTribe) {
                this.currentFormData.tribalAffiliationTypeLocalTribe = tribalAffiliationTypeLocalTribe;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.tribalAffiliationTypeNonLocalTribe !== tribalAffiliationTypeNonLocalTribe) {
                this.currentFormData.tribalAffiliationTypeNonLocalTribe = tribalAffiliationTypeNonLocalTribe;
                saveCurrentFormValues = true;
            }
            if (saveCurrentFormValues) {
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.adultsLivingInHome.valueChanges.subscribe(adultsLivingInHome => {
            if (this.currentFormData && this.currentFormData.adultsLivingInHome !== adultsLivingInHome) {
                this.currentFormData.adultsLivingInHome = adultsLivingInHome;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.regularlyPresentAdultsInHome.valueChanges.subscribe(regularlyPresentAdultsInHome => {
            if (this.currentFormData && this.currentFormData.regularlyPresentAdultsInHome !== regularlyPresentAdultsInHome) {
                this.currentFormData.regularlyPresentAdultsInHome = regularlyPresentAdultsInHome;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.youthLivingInHome.valueChanges.subscribe(youthLivingInHome => {
            if (this.currentFormData && this.currentFormData.youthLivingInHome !== youthLivingInHome) {
                this.currentFormData.youthLivingInHome = youthLivingInHome;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.icpc.valueChanges.subscribe(icpc => {
            if (this.currentFormData && this.currentFormData.icpc !== icpc) {
                this.currentFormData.icpc = icpc;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.comments.valueChanges.subscribe(comments => {
            if (this.currentFormData && this.currentFormData.comments !== comments) {
                this.currentFormData.comments = comments;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.investigatedErReferralOnThisFacilityDuringTrackingPeriod.valueChanges.subscribe(investigatedErReferralOnThisFacilityDuringTrackingPeriod => {
            if (this.currentFormData && this.currentFormData.investigatedErReferralOnThisFacilityDuringTrackingPeriod !== investigatedErReferralOnThisFacilityDuringTrackingPeriod) {
                this.currentFormData.investigatedErReferralOnThisFacilityDuringTrackingPeriod = investigatedErReferralOnThisFacilityDuringTrackingPeriod;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.complaintInvestigationFindings.valueChanges.subscribe(complaintInvestigationFindings => {
            if (this.currentFormData && this.currentFormData.complaintInvestigationFindings !== complaintInvestigationFindings) {
                this.currentFormData.complaintInvestigationFindings = complaintInvestigationFindings;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.studyCaseOccurrence.valueChanges.subscribe(studyCaseOccurrences => {
            let studyCaseOccurrenceExemption = false;
            let studyCaseOccurrenceAppeal = false;
            let studyCaseOccurrenceNoneApply = false;
            if (isArray(studyCaseOccurrences)) {
                studyCaseOccurrences.forEach(studyCaseOccurrence => {
                    switch (studyCaseOccurrence) {
                        case Humboldt2020ResourceFamilyApprovalStudyCaseOccurrenceOption.Exemption:
                            studyCaseOccurrenceExemption = true;
                            break;
                        case Humboldt2020ResourceFamilyApprovalStudyCaseOccurrenceOption.Appeal:
                            studyCaseOccurrenceAppeal = true;
                            break;
                        case Humboldt2020ResourceFamilyApprovalStudyCaseOccurrenceOption.NoneApply:
                            studyCaseOccurrenceNoneApply = true;
                            break;
                    }
                });
            }
            let saveCurrentFormValues = false;
            if (this.currentFormData && this.currentFormData.studyCaseOccurrenceExemption !== studyCaseOccurrenceExemption) {
                this.currentFormData.studyCaseOccurrenceExemption = studyCaseOccurrenceExemption;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.studyCaseOccurrenceAppeal !== studyCaseOccurrenceAppeal) {
                this.currentFormData.studyCaseOccurrenceAppeal = studyCaseOccurrenceAppeal;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.studyCaseOccurrenceNoneApply !== studyCaseOccurrenceNoneApply) {
                this.currentFormData.studyCaseOccurrenceNoneApply = studyCaseOccurrenceNoneApply;
                saveCurrentFormValues = true;
            }
            if (saveCurrentFormValues) {
                this.saveCurrentFormValues();
            }
        });
        this.activityListFilterForm.controls.sorting.valueChanges.subscribe((sorting) => {
            if (sorting && this.activityListSort && (this.activityListSort.active !== sorting.sortColumn || (this.activityListSort.direction || 'asc') !== sorting.sortDirection)) {
                this.activityListSort.sort({
                    id: sorting.sortColumn,
                    start: sorting.sortDirection,
                    disableClear: true
                });
            }
        });
        this.activityListFilterForm.valueChanges.pipe(debounceTime(100)).subscribe(values => {
            let saveActivityListFilters = false;
            if (this.activityListFilters.activity !== values.activity) {
                this.activityListFilters.activity = values.activity;
                saveActivityListFilters = true;
            }
            if (values.sorting) {
                if (this.activityListFilters.sortColumn !== values.sorting.sortColumn || this.activityListFilters.sortDirection !== values.sorting.sortDirection) {
                    this.activityListFilters.sortColumn = values.sorting.sortColumn;
                    this.activityListFilters.sortDirection = values.sorting.sortDirection;
                    saveActivityListFilters = true;
                }
            }
            else if (this.activityListFilters.sortColumn !== null || this.activityListFilters.sortDirection !== null) {
                this.activityListFilters.sortColumn = null;
                this.activityListFilters.sortDirection = null;
                saveActivityListFilters = true;
            }
            if (saveActivityListFilters) {
                this.saveFormTypeSettings();
            }
            this.activityListFilterAndSortData();
        });
        this.form.controls.standardsMet.valueChanges.subscribe(standardsMet => {
            if (this.currentFormData && this.currentFormData.standardsMet !== standardsMet) {
                this.currentFormData.standardsMet = standardsMet;
                this.saveCurrentFormValues();
                this.updateReasonsStandardsNotMetValidators();
            }
        });
        this.form.controls.reasonsStandardsNotMet.valueChanges.subscribe(reasonsStandardsNotMet => {
            let reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = false;
            let reasonsStandardsNotMetCaseworkerStoppedTracking = false;
            let reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = false;
            let reasonsStandardsNotMetOther = false;
            if (isArray(reasonsStandardsNotMet)) {
                reasonsStandardsNotMet.forEach(reasonStandardsNotMet => {
                    switch (reasonStandardsNotMet) {
                        case Humboldt2020ResourceFamilyApprovalReasonsStandardsNotMetOption.NumberOfContactsOtherStandardsNotMet:
                            reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = true;
                            break;
                        case Humboldt2020ResourceFamilyApprovalReasonsStandardsNotMetOption.CaseworkerStoppedTracking:
                            reasonsStandardsNotMetCaseworkerStoppedTracking = true;
                            break;
                        case Humboldt2020ResourceFamilyApprovalReasonsStandardsNotMetOption.NotAllWorkDuringMonthRecorded:
                            reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = true;
                            break;
                        case Humboldt2020ResourceFamilyApprovalReasonsStandardsNotMetOption.Other:
                            reasonsStandardsNotMetOther = true;
                            break;
                    }
                });
            }
            let saveCurrentFormValues = false;
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet !== reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet) {
                this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking !== reasonsStandardsNotMetCaseworkerStoppedTracking) {
                this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking = reasonsStandardsNotMetCaseworkerStoppedTracking;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded !== reasonsStandardsNotMetNotAllWorkDuringMonthRecorded) {
                this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = reasonsStandardsNotMetNotAllWorkDuringMonthRecorded;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetOther !== reasonsStandardsNotMetOther) {
                this.currentFormData.reasonsStandardsNotMetOther = reasonsStandardsNotMetOther;
                saveCurrentFormValues = true;
            }
            if (saveCurrentFormValues) {
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.supervisorComments.valueChanges.subscribe(supervisorComments => {
            if (this.currentFormData && this.currentFormData.supervisorComments !== supervisorComments) {
                this.currentFormData.supervisorComments = supervisorComments;
                this.saveCurrentFormValues();
            }
        });
    }
    /* updates the validators for tribalAffiliation */
    updateTribalAffiliationValidators() {
        if (this.currentFormData && this.currentFormData.rfType === Humboldt2020ResourceFamilyApprovalRfTypeOption.MatchedHome) {
            this.form.controls.tribalAffiliation.setValidators(Validators.required);
        }
        else {
            this.form.controls.tribalAffiliation.clearValidators();
        }
        this.form.controls.tribalAffiliation.updateValueAndValidity();
    }
    /* updates the validators for tribalAffiliationType */
    updateTribalAffiliationTypeValidators() {
        if (this.currentFormData &&
            this.currentFormData.rfType === Humboldt2020ResourceFamilyApprovalRfTypeOption.MatchedHome &&
            this.currentFormData.tribalAffiliation === Humboldt2020ResourceFamilyApprovalTribalAffiliationOption.YesMaybe) {
            this.form.controls.tribalAffiliationType.setValidators(Validators.required);
            this.showTribalAffiliationTypeQuestion = true;
        }
        else {
            this.form.controls.tribalAffiliationType.clearValidators();
            this.showTribalAffiliationTypeQuestion = false;
        }
        this.form.controls.tribalAffiliationType.updateValueAndValidity();
    }
    /* updates the validators for icpc */
    updateIcpcValidators() {
        if (this.currentFormData && this.currentFormData.assignmentType === Humboldt2020ResourceFamilyApprovalAssignmentTypeOption.PreApproval) {
            this.form.controls.icpc.setValidators(Validators.required);
        }
        else {
            this.form.controls.icpc.clearValidators();
        }
        this.form.controls.icpc.updateValueAndValidity();
    }
    /* updates the validators for investigatedErReferralOnThisFacilityDuringTrackingPeriod */
    updateInvestigatedErReferralOnThisFacilityDuringTrackingPeriodValidators() {
        if (this.currentFormData &&
            (this.currentFormData.assignmentType === Humboldt2020ResourceFamilyApprovalAssignmentTypeOption.PreApproval ||
                this.currentFormData.assignmentType === Humboldt2020ResourceFamilyApprovalAssignmentTypeOption.ComplaintInvestigation)) {
            this.form.controls.investigatedErReferralOnThisFacilityDuringTrackingPeriod.setValidators(Validators.required);
            this.showInvestigatedErReferralOnThisFacilityDuringTrackingPeriodQuestion = true;
        }
        else {
            this.form.controls.investigatedErReferralOnThisFacilityDuringTrackingPeriod.clearValidators();
            this.showInvestigatedErReferralOnThisFacilityDuringTrackingPeriodQuestion = false;
        }
        this.form.controls.investigatedErReferralOnThisFacilityDuringTrackingPeriod.updateValueAndValidity();
    }
    /* updates the validators for complaintInvestigationFindings */
    updateComplaintInvestigationFindingsValidators() {
        if (this.currentFormData && this.currentFormData.assignmentType === Humboldt2020ResourceFamilyApprovalAssignmentTypeOption.ComplaintInvestigation) {
            this.form.controls.complaintInvestigationFindings.setValidators(Validators.required);
        }
        else {
            this.form.controls.complaintInvestigationFindings.clearValidators();
        }
        this.form.controls.complaintInvestigationFindings.updateValueAndValidity();
    }
    /* updates the validators for standardsMet */
    updateStandardsMetValidators() {
        if (this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
            this.form.controls.standardsMet.setValidators(Validators.required);
        }
        else {
            this.form.controls.standardsMet.clearValidators();
        }
        this.form.controls.standardsMet.updateValueAndValidity();
    }
    /* updates the validators for reasonsStandardsNotMet */
    updateReasonsStandardsNotMetValidators() {
        if (this.currentFormData && this.currentFormData.status === FormStatus.InReview && this.currentFormData.standardsMet === YesNoOption.No) {
            this.form.controls.reasonsStandardsNotMet.setValidators(Validators.required);
        }
        else {
            this.form.controls.reasonsStandardsNotMet.clearValidators();
        }
        this.form.controls.reasonsStandardsNotMet.updateValueAndValidity();
    }
    /* Hydrates form with currentFormData values */
    afterFormDataLoaded() {
        super.afterFormDataLoaded();
        if (this.formType && this.currentFormData) {
            const title = this.formType.name + ' - ' + this.currentFormData.name;
            if (this.titleService.title.value !== title) {
                this.titleService.title.next(title);
            }
        }
        if (this.currentFormData) {
            this.form.controls.agentName.setValue(this.currentFormData.created.userName);
            this.form.controls.createdDate.setValue(moment(this.currentFormData.created.timestamp, 'x').format('L LT'));
            this.form.controls.status.setValue(this.currentFormData.status);
            this.form.controls.statusChangedDate.setValue(moment(this.currentFormData.statusChangeTimestamp, 'x').format('L LT'));
            this.form.controls.updatedDate.setValue(moment(this.currentFormData.updated.timestamp, 'x').format('L LT'));
            this.form.controls.rfName.setValue(this.currentFormData.name);
            this.form.controls.facilityNumber.setValue(this.currentFormData.facilityNumber);
            this.form.controls.assignmentDate.setValue(this.currentFormData.assignmentDate !== null ? moment(this.currentFormData.assignmentDate, 'x') : null);
            this.form.controls.assignmentType.setValue(this.currentFormData.assignmentType);
            this.form.controls.rfType.setValue(this.currentFormData.rfType);
            this.form.controls.tribalAffiliation.setValue(this.currentFormData.tribalAffiliation);
            const tribalAffiliationType = [];
            if (this.currentFormData.tribalAffiliationTypeLocalTribe)
                tribalAffiliationType.push(Humboldt2020ResourceFamilyApprovalTribalAffiliationTypeOption.LocalTribe);
            if (this.currentFormData.tribalAffiliationTypeNonLocalTribe)
                tribalAffiliationType.push(Humboldt2020ResourceFamilyApprovalTribalAffiliationTypeOption.NonLocalTribe);
            this.form.controls.tribalAffiliationType.setValue(tribalAffiliationType);
            this.form.controls.adultsLivingInHome.setValue(this.currentFormData.adultsLivingInHome);
            this.form.controls.regularlyPresentAdultsInHome.setValue(this.currentFormData.regularlyPresentAdultsInHome);
            this.form.controls.youthLivingInHome.setValue(this.currentFormData.youthLivingInHome);
            this.form.controls.icpc.setValue(this.currentFormData.icpc);
            this.form.controls.comments.setValue(this.currentFormData.comments);
            this.form.controls.investigatedErReferralOnThisFacilityDuringTrackingPeriod.setValue(this.currentFormData.investigatedErReferralOnThisFacilityDuringTrackingPeriod);
            this.form.controls.complaintInvestigationFindings.setValue(this.currentFormData.complaintInvestigationFindings);
            const studyCaseOccurrence = [];
            if (this.currentFormData.studyCaseOccurrenceExemption)
                studyCaseOccurrence.push(Humboldt2020ResourceFamilyApprovalStudyCaseOccurrenceOption.Exemption);
            if (this.currentFormData.studyCaseOccurrenceAppeal)
                studyCaseOccurrence.push(Humboldt2020ResourceFamilyApprovalStudyCaseOccurrenceOption.Appeal);
            if (this.currentFormData.studyCaseOccurrenceNoneApply)
                studyCaseOccurrence.push(Humboldt2020ResourceFamilyApprovalStudyCaseOccurrenceOption.NoneApply);
            this.form.controls.studyCaseOccurrence.setValue(studyCaseOccurrence);
            this.activityListFilterAndSortData();
            this.form.controls.standardsMet.setValue(this.currentFormData.standardsMet);
            const reasonsStandardsNotMet = [];
            if (this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet)
                reasonsStandardsNotMet.push(Humboldt2020ResourceFamilyApprovalReasonsStandardsNotMetOption.NumberOfContactsOtherStandardsNotMet);
            if (this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking)
                reasonsStandardsNotMet.push(Humboldt2020ResourceFamilyApprovalReasonsStandardsNotMetOption.CaseworkerStoppedTracking);
            if (this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded)
                reasonsStandardsNotMet.push(Humboldt2020ResourceFamilyApprovalReasonsStandardsNotMetOption.NotAllWorkDuringMonthRecorded);
            if (this.currentFormData.reasonsStandardsNotMetOther)
                reasonsStandardsNotMet.push(Humboldt2020ResourceFamilyApprovalReasonsStandardsNotMetOption.Other);
            this.form.controls.reasonsStandardsNotMet.setValue(reasonsStandardsNotMet);
            this.form.controls.supervisorComments.setValue(this.currentFormData.supervisorComments);
            this.updateTribalAffiliationValidators();
            this.updateTribalAffiliationTypeValidators();
            this.updateIcpcValidators();
            this.updateInvestigatedErReferralOnThisFacilityDuringTrackingPeriodValidators();
            this.updateComplaintInvestigationFindingsValidators();
            this.updateStandardsMetValidators();
            this.updateReasonsStandardsNotMetValidators();
            // See if the loaded data has a current activity
            // This should only happen if the loaded record is a current form and the user was in the middle of editing that dialog
            if (this.currentFormData.currentActivity) {
                this.editActivity(this.currentFormData.currentActivity);
            }
        }
    }
    loadFormTypeSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const settingsString = yield this.databaseService.getFormTypeSettings(this.formTypeId);
                if (settingsString) {
                    const settings = JSON.parse(settingsString);
                    if (settings.activityListFilters) {
                        this.activityListFilters = settings.activityListFilters;
                        this.activityListFilterForm.controls.activity.setValue(this.activityListFilters.activity);
                        const activitySorting = this.activityListSortOptions.filter(sorting => sorting.sortColumn === this.activityListFilters.sortColumn && sorting.sortDirection === this.activityListFilters.sortDirection);
                        if (activitySorting.length > 0) {
                            this.activityListFilterForm.controls.sorting.setValue(activitySorting[0]);
                        }
                        else {
                            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
                        }
                    }
                    else {
                        this.clearActivityListFilters();
                    }
                }
                else {
                    this.clearActivityListFilters();
                }
            }
            catch (error) {
                console.error('Error retrieving form type settings', error);
            }
        });
    }
    saveFormTypeSettings() {
        try {
            const settings = {
                activityListFilters: this.activityListFilters
            };
            this.databaseService.saveFormTypeSettings(this.formTypeId, JSON.stringify(settings));
        }
        catch (error) {
            console.error('Error saving form type settings', error);
        }
    }
    activityListFilterAndSortData() {
        let sortColumn = Humboldt2020ResourceFamilyApprovalActivityListColumn.activityDate;
        let sortDirection = 'asc';
        if (this.activityListFilterForm.controls.sorting.value) {
            const sorting = this.activityListFilterForm.controls.sorting.value;
            sortColumn = sorting.sortColumn;
            sortDirection = sorting.sortDirection;
        }
        if (this.currentFormData && this.currentFormData.activities) {
            this.activitiesDataSource = this.currentFormData.activities.filter((activity) => {
                return !(this.activityListFilterForm.controls.activity.value && activity.activity !== this.activityListFilterForm.controls.activity.value);
            }).sort((activity1, activity2) => {
                switch (sortColumn) {
                    case Humboldt2020ResourceFamilyApprovalActivityListColumn.activityDate:
                        if ((activity1.activityDate || 0) > (activity2.activityDate || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityDate || 0) < (activity2.activityDate || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ResourceFamilyApprovalActivityListColumn.activity:
                        if ((activity1.activity || '') > (activity2.activity || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activity || '') < (activity2.activity || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ResourceFamilyApprovalActivityListColumn.personInvolved:
                        if ((activity1.personInvolvedDisplay || '') > (activity2.personInvolvedDisplay || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.personInvolvedDisplay || '') < (activity2.personInvolvedDisplay || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ResourceFamilyApprovalActivityListColumn.method:
                        if ((activity1.method || '') > (activity2.method || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.method || '') < (activity2.method || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ResourceFamilyApprovalActivityListColumn.place:
                        if ((activity1.place || '') > (activity2.place || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.place || '') < (activity2.place || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ResourceFamilyApprovalActivityListColumn.activityMinutesSpent:
                        if ((activity1.activityMinutesSpent || 0) > (activity2.activityMinutesSpent || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityMinutesSpent || 0) < (activity2.activityMinutesSpent || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ResourceFamilyApprovalActivityListColumn.travelMinutesSpent:
                        if ((activity1.travelMinutesSpent || 0) > (activity2.travelMinutesSpent || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.travelMinutesSpent || 0) < (activity2.travelMinutesSpent || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ResourceFamilyApprovalActivityListColumn.activityDescription:
                        if ((activity1.activityDescription || '') > (activity2.activityDescription || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityDescription || '') < (activity2.activityDescription || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                }
                // sensible defaults if the key doesn't exist, or if the initial result is a tie
                if ((activity1.activityDate || 0) < (activity2.activityDate || 0))
                    return -1;
                if ((activity1.activityDate || 0) > (activity2.activityDate || 0))
                    return 1;
                return 0;
            });
        }
        this.setActivityListFilterInfoString();
    }
    setActivityListFilterInfoString() {
        const info = [];
        if (this.activityListFilterForm.controls.activity.value) {
            info.push('Activity Filter: ' + this.activityListFilterForm.controls.activity.value);
        }
        if (this.activityListFilterForm.controls.sorting.value) {
            info.push('Sort: ' + this.activityListFilterForm.controls.sorting.value.display);
        }
        this.activityListFilterInfoString = info.join(', ');
    }
    clearActivityListFilters() {
        if (this.activityListFilterForm.controls.activity.value !== null)
            this.activityListFilterForm.controls.activity.setValue(null);
        if (this.activityListFilterForm.controls.sorting.value !== this.activityListSortOptions[0])
            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
    }
    openActivityListFilterDialog() {
        const dialogData = {
            filterForm: this.activityListFilterForm,
            clearFunction: this.clearActivityListFilters.bind(this),
            activityOptions: this.activityOptions,
            sortOptions: this.activityListSortOptions
        };
        this.dialog.open(Humboldt2020ResourceFamilyApprovalActivityListFilterDialogComponent, {
            data: dialogData,
            width: '800px'
        });
    }
    /**
     * update the form filter when the table headers are clicked to keep them in sync
     */
    onActivityListSortChange(sort) {
        const sortingValue = this.activityListSortOptions.filter(sortOption => sortOption.sortColumn === sort.active && sortOption.sortDirection === (sort.direction || 'asc'));
        if (sortingValue.length > 0 && sortingValue[0] !== this.activityListFilterForm.controls.sorting.value) {
            this.activityListFilterForm.controls.sorting.setValue(sortingValue[0]);
        }
    }
    editActivity(activity) {
        const dialogData = {
            activity: activity,
            updateCurrentActivity: this.updateCurrentActivity.bind(this)
        };
        this.dialog.open(Humboldt2020ResourceFamilyApprovalActivityDialogComponent, {
            data: dialogData,
            disableClose: true,
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (this.currentFormData) {
                if (result) {
                    if (result.index !== null) {
                        this.currentFormData.activities[result.index] = result;
                    }
                    else {
                        this.currentFormData.activities.push(result);
                    }
                    this.currentFormData.activities.forEach((activity, index) => activity.index = index);
                    this.activityListFilterAndSortData();
                }
                this.currentFormData.currentActivity = null;
                this.saveCurrentFormValues();
            }
        });
    }
    updateCurrentActivity(activity) {
        if (this.currentFormData) {
            this.currentFormData.currentActivity = activity;
            this.saveCurrentFormValues();
        }
    }
    deleteActivity(activity) {
        this.dialog.open(MessageDialogComponent, {
            data: {
                title: 'Delete Activity',
                message: 'Deleting this activity is irreversible.' +
                    ' Once deleted any information you have entered for the activity will be gone forever.' +
                    ' Are you sure you want to do this?',
                okButtonLabel: 'Delete Activity'
            },
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (this.currentFormData && result && activity.index !== null) {
                this.currentFormData.activities.splice(activity.index, 1);
                this.currentFormData.activities.forEach((activity, index) => activity.index = index);
                this.activityListFilterAndSortData();
                this.saveCurrentFormValues();
            }
        });
    }
}
