<h1 mat-dialog-title>Activity List Options</h1>
<div mat-dialog-content>
  <form class="filter-form" [formGroup]="data.filterForm">
    <mat-form-field>
      <mat-label>Activity Filter</mat-label>
      <mat-select formControlName="activity">
        <mat-option [value]="null"></mat-option>
        <mat-optgroup label="Non-Case-Related Activities">
          <mat-option *ngFor="let option of data.nonCaseRelatedActivityOptions" [value]="option">{{option}}</mat-option>
        </mat-optgroup>
        <mat-optgroup label="Case Support Activities">
          <mat-option *ngFor="let option of data.caseSupportActivityOptions" [value]="option">{{option}}</mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Sorting</mat-label>
      <mat-select formControlName="sorting">
        <mat-option *ngFor="let sort of data.sortOptions" [value]="sort">{{sort.display}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Close</button>
  <button mat-button (click)="clear()">Clear</button>
</div>

