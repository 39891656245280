import { Humboldt2020PlacementActivityActivityOption } from './humboldt2020-placement-activity-dialog/humboldt2020-placement-activity-activity-option.enum';
import { Humboldt2020PlacementActivityMethodOption } from './humboldt2020-placement-activity-dialog/humboldt2020-placement-activity-method-option.enum';
import { Humboldt2020PlacementActivityPlaceOption } from './humboldt2020-placement-activity-dialog/humboldt2020-placement-activity-place-option.enum';
import { Humboldt2020PlacementActivityPersonInvolvedOption } from './humboldt2020-placement-activity-dialog/humboldt2020-placement-activity-person-involved-option.enum';

export interface Humboldt2020PlacementActivityData {
  index: number | null; // Used to store the index when passing data to the dialog
  activityDate: number | null; // unix timestamp in milliseconds
  activity: Humboldt2020PlacementActivityActivityOption | null;
  personInvolvedDisplay: string | null;
  personInvolvedYouth: boolean;
  personInvolvedRelativeNrefm: boolean;
  personInvolvedIntakeCoordinator: boolean;
  personInvolvedSubstituteCareProvider: boolean;
  personInvolvedSocialWorker: boolean;
  personInvolvedDoj: boolean;
  personInvolvedTribe: boolean;
  personInvolvedSupervisor: boolean;
  personInvolvedOther: boolean;
  personInvolvedNone: boolean;
  method: Humboldt2020PlacementActivityMethodOption | null;
  place: Humboldt2020PlacementActivityPlaceOption | null;
  activityMinutesSpent: number | null;
  travelMinutesSpent: number | null;
  activityDescription: string | null;
}

export function getActivityPersonInvolvedArray(activity: Humboldt2020PlacementActivityData): string[] {
  const values: Array<string> = [];
  if (activity.personInvolvedYouth) {
    values.push(Humboldt2020PlacementActivityPersonInvolvedOption.Youth);
  }
  if (activity.personInvolvedRelativeNrefm) {
    values.push(Humboldt2020PlacementActivityPersonInvolvedOption.RelativeNrefm);
  }
  if (activity.personInvolvedIntakeCoordinator) {
    values.push(Humboldt2020PlacementActivityPersonInvolvedOption.IntakeCoordinator);
  }
  if (activity.personInvolvedSubstituteCareProvider) {
    values.push(Humboldt2020PlacementActivityPersonInvolvedOption.SubstituteCareProvider);
  }
  if (activity.personInvolvedSocialWorker) {
    values.push(Humboldt2020PlacementActivityPersonInvolvedOption.SocialWorker);
  }
  if (activity.personInvolvedDoj) {
    values.push(Humboldt2020PlacementActivityPersonInvolvedOption.Doj);
  }
  if (activity.personInvolvedTribe) {
    values.push(Humboldt2020PlacementActivityPersonInvolvedOption.Tribe);
  }
  if (activity.personInvolvedSupervisor) {
    values.push(Humboldt2020PlacementActivityPersonInvolvedOption.Supervisor);
  }
  if (activity.personInvolvedOther) {
    values.push(Humboldt2020PlacementActivityPersonInvolvedOption.Other);
  }
  if (activity.personInvolvedNone) {
    values.push(Humboldt2020PlacementActivityPersonInvolvedOption.None);
  }
  return values;
}
