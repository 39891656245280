import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { noneValidator } from '../../../../validators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { isArray } from "util";
import { Humboldt2020ResourceFamilyApprovalActivityData, getActivityPersonInvolvedArray } from '../humboldt2020-resource-family-approval-activity-data';
import { Humboldt2020ResourceFamilyApprovalActivityActivityOption } from './humboldt2020-resource-family-approval-activity-activity-option.enum';
import { Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption } from './humboldt2020-resource-family-approval-activity-person-involved-option.enum';
import { Humboldt2020ResourceFamilyApprovalActivityMethodOption } from './humboldt2020-resource-family-approval-activity-method-option.enum';
import { Humboldt2020ResourceFamilyApprovalActivityPlaceOption } from './humboldt2020-resource-family-approval-activity-place-option.enum';
import { Humboldt2020ResourceFamilyApprovalActivityDialogData } from './humboldt2020-resource-family-approval-activity-dialog-data';

@Component({
  selector: 'app-humboldt2020-resource-family-approval-activity-dialog',
  templateUrl: './humboldt2020-resource-family-approval-activity-dialog.component.html',
  styleUrls: ['./humboldt2020-resource-family-approval-activity-dialog.component.css']
})
export class Humboldt2020ResourceFamilyApprovalActivityDialogComponent {
  currentActivity: Humboldt2020ResourceFamilyApprovalActivityData;
  form = this.formBuilder.group({
    activityDate: [null, Validators.required],
    activity: [null, Validators.required],
    personInvolved: [[], [Validators.required, noneValidator]],
    method: [null, Validators.required],
    place: [null, Validators.required],
    activityMinutesSpent: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    travelMinutesSpent: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    activityDescription: [null, Validators.maxLength(500)]
  });

  activityOptions = Object.values(Humboldt2020ResourceFamilyApprovalActivityActivityOption);
  activityOptionEnum = Humboldt2020ResourceFamilyApprovalActivityActivityOption;
  personInvolvedOptions = Object.values(Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption);
  methodOptions = Object.values(Humboldt2020ResourceFamilyApprovalActivityMethodOption);
  placeOptions = Object.values(Humboldt2020ResourceFamilyApprovalActivityPlaceOption);

  constructor(
    private dialogRef: MatDialogRef<Humboldt2020ResourceFamilyApprovalActivityDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Humboldt2020ResourceFamilyApprovalActivityDialogData
  ) {
    if (data.activity) {
      // Need to make a copy of the object so updating it doesn't update the origin object until save is applied
      this.currentActivity = {
        index: data.activity.index,
        activityDate: data.activity.activityDate,
        activity: data.activity.activity,
        personInvolvedDisplay: data.activity.personInvolvedDisplay,
        personInvolvedResourceFamily: data.activity.personInvolvedResourceFamily,
        personInvolvedHouseholdMember: data.activity.personInvolvedHouseholdMember,
        personInvolvedRegularlyPresentAdult: data.activity.personInvolvedRegularlyPresentAdult,
        personInvolvedYouthInHome: data.activity.personInvolvedYouthInHome,
        personInvolvedCollateralCommunity: data.activity.personInvolvedCollateralCommunity,
        personInvolvedSupervisor: data.activity.personInvolvedSupervisor,
        personInvolvedOtherCwsStaff: data.activity.personInvolvedOtherCwsStaff,
        personInvolvedTribe: data.activity.personInvolvedTribe,
        personInvolvedLegalConsultant: data.activity.personInvolvedLegalConsultant,
        personInvolvedOther: data.activity.personInvolvedOther,
        personInvolvedNone: data.activity.personInvolvedNone,
        method: data.activity.method,
        place: data.activity.place,
        activityMinutesSpent: data.activity.activityMinutesSpent,
        travelMinutesSpent: data.activity.travelMinutesSpent,
        activityDescription: data.activity.activityDescription
      };

      if (this.currentActivity.activityDate) {
        this.form.controls.activityDate.setValue(moment(this.currentActivity.activityDate, 'x')); // x = unix milliseconds
      }
      this.form.controls.activity.setValue(this.currentActivity.activity);
      this.form.controls.personInvolved.setValue(getActivityPersonInvolvedArray(this.currentActivity));
      this.form.controls.method.setValue(this.currentActivity.method);
      this.form.controls.place.setValue(this.currentActivity.place);
      this.form.controls.activityMinutesSpent.setValue(this.currentActivity.activityMinutesSpent);
      this.form.controls.travelMinutesSpent.setValue(this.currentActivity.travelMinutesSpent);
      this.form.controls.activityDescription.setValue(this.currentActivity.activityDescription);
      this.updateActivityDescriptionValidators();
      this.form.markAllAsTouched();
    } else {
      this.currentActivity = {
        index: null,
        activityDate: null,
        activity: null,
        personInvolvedDisplay: null,
        personInvolvedResourceFamily: false,
        personInvolvedHouseholdMember: false,
        personInvolvedRegularlyPresentAdult: false,
        personInvolvedYouthInHome: false,
        personInvolvedCollateralCommunity: false,
        personInvolvedSupervisor: false,
        personInvolvedOtherCwsStaff: false,
        personInvolvedTribe: false,
        personInvolvedLegalConsultant: false,
        personInvolvedOther: false,
        personInvolvedNone: false,
        method: null,
        place: null,
        activityMinutesSpent: null,
        travelMinutesSpent: null,
        activityDescription: null
      };
    }

    this.formChangeSubscriptions();
  }

  formChangeSubscriptions(): void {
    this.form.controls.activityDate.valueChanges.subscribe(activityDate => {
      const numberValue = activityDate !== null ? parseInt(activityDate.format('x')) : null;
      if (this.currentActivity.activityDate !== numberValue) {
        this.currentActivity.activityDate = numberValue;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activity.valueChanges.subscribe(activity => {
      if (this.currentActivity.activity !== activity) {
        this.currentActivity.activity = activity;
        this.data.updateCurrentActivity(this.currentActivity);
        this.updateActivityDescriptionValidators();
      }
    });

    this.form.controls.personInvolved.valueChanges.subscribe(personInvolved => {
      let personInvolvedResourceFamily = false;
      let personInvolvedHouseholdMember = false;
      let personInvolvedRegularlyPresentAdult = false;
      let personInvolvedYouthInHome = false;
      let personInvolvedCollateralCommunity = false;
      let personInvolvedSupervisor = false;
      let personInvolvedOtherCwsStaff = false;
      let personInvolvedTribe = false;
      let personInvolvedLegalConsultant = false;
      let personInvolvedOther = false;
      let personInvolvedNone = false;

      if (isArray(personInvolved)) {
        (personInvolved as string[]).forEach(personInvolved => {
          switch (personInvolved) {
            case Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.ResourceFamily:
              personInvolvedResourceFamily = true;
              break;
            case Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.HouseholdMember:
              personInvolvedHouseholdMember = true;
              break;
            case Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.RegularlyPresentAdult:
              personInvolvedRegularlyPresentAdult = true;
              break;
            case Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.YouthInHome:
              personInvolvedYouthInHome = true;
              break;
            case Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.CollateralCommunity:
              personInvolvedCollateralCommunity = true;
              break;
            case Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.Supervisor:
              personInvolvedSupervisor = true;
              break;
            case Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.OtherCwsStaff:
              personInvolvedOtherCwsStaff = true;
              break;
            case Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.Tribe:
              personInvolvedTribe = true;
              break;
            case Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.LegalConsultant:
              personInvolvedLegalConsultant = true;
              break;
            case Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.Other:
              personInvolvedOther = true;
              break;
            case Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.None:
              personInvolvedNone = true;
              break;
          }
        })
      }

      let saveCurrentFormValues = false;
      if (this.currentActivity && this.currentActivity.personInvolvedResourceFamily !== personInvolvedResourceFamily) {
        this.currentActivity.personInvolvedResourceFamily = personInvolvedResourceFamily;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedHouseholdMember !== personInvolvedHouseholdMember) {
        this.currentActivity.personInvolvedHouseholdMember = personInvolvedHouseholdMember;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedRegularlyPresentAdult !== personInvolvedRegularlyPresentAdult) {
        this.currentActivity.personInvolvedRegularlyPresentAdult = personInvolvedRegularlyPresentAdult;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedYouthInHome !== personInvolvedYouthInHome) {
        this.currentActivity.personInvolvedYouthInHome = personInvolvedYouthInHome;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedCollateralCommunity !== personInvolvedCollateralCommunity) {
        this.currentActivity.personInvolvedCollateralCommunity = personInvolvedCollateralCommunity;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedSupervisor !== personInvolvedSupervisor) {
        this.currentActivity.personInvolvedSupervisor = personInvolvedSupervisor;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedOtherCwsStaff !== personInvolvedOtherCwsStaff) {
        this.currentActivity.personInvolvedOtherCwsStaff = personInvolvedOtherCwsStaff;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedTribe !== personInvolvedTribe) {
        this.currentActivity.personInvolvedTribe = personInvolvedTribe;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedLegalConsultant !== personInvolvedLegalConsultant) {
        this.currentActivity.personInvolvedLegalConsultant = personInvolvedLegalConsultant;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedOther !== personInvolvedOther) {
        this.currentActivity.personInvolvedOther = personInvolvedOther;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedNone !== personInvolvedNone) {
        this.currentActivity.personInvolvedNone = personInvolvedNone;
        saveCurrentFormValues = true;
      }
      const personInvolvedArray = getActivityPersonInvolvedArray(this.currentActivity);
      const personInvolvedDisplay = personInvolvedArray.length ? personInvolvedArray.join(', ') : null;
      if (this.currentActivity && this.currentActivity.personInvolvedDisplay !== personInvolvedDisplay) {
        this.currentActivity.personInvolvedDisplay = personInvolvedDisplay;
        saveCurrentFormValues = true;
      }
      if (saveCurrentFormValues) {
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.method.valueChanges.subscribe(method => {
      if (this.currentActivity.method !== method) {
        this.currentActivity.method = method;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.place.valueChanges.subscribe(place => {
      if (this.currentActivity.place !== place) {
        this.currentActivity.place = place;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityMinutesSpent.valueChanges.subscribe(activityMinutesSpent => {
      if (this.currentActivity.activityMinutesSpent !== activityMinutesSpent) {
        this.currentActivity.activityMinutesSpent = activityMinutesSpent;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.travelMinutesSpent.valueChanges.subscribe(travelMinutesSpent => {
      if (this.currentActivity.travelMinutesSpent !== travelMinutesSpent) {
        this.currentActivity.travelMinutesSpent = travelMinutesSpent;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityDescription.valueChanges.subscribe(activityDescription => {
      if (this.currentActivity.activityDescription !== activityDescription) {
        this.currentActivity.activityDescription = activityDescription;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });
  }

  /* updates the validators for activityDescription */
  updateActivityDescriptionValidators(): void {
    if (this.currentActivity && this.currentActivity.activity === Humboldt2020ResourceFamilyApprovalActivityActivityOption.Other) {
      this.form.controls.activityDescription.setValidators([Validators.required, Validators.maxLength(500)]);
    } else {
      this.form.controls.activityDescription.setValidators(Validators.maxLength(500));
    }
    this.form.controls.activityDescription.updateValueAndValidity();
  }

  updateActivityList(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dialogRef.close(this.currentActivity);
    }
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
