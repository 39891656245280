import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { controlErrorMessage } from '../../../../utility-functions';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Doc2019CaseSupportTimeActivityData } from '../doc2019-case-support-time-activity-data';
import * as moment from 'moment';
import { YesNoOption } from '../../../../form/yes-no-option.enum';
import { Doc2019CaseSupportTimeActivityDialogData } from './doc2019-case-support-time-activity-dialog-data';
import { Doc2019CaseSupportTimeActivityNonCaseRelatedActivityOption } from './doc2019-case-support-time-activity-non-case-related-activity-option.enum';
import { Doc2019CaseSupportTimeActivityCaseSupportActivityOption } from './doc2019-case-support-time-activity-case-support-activity-option.enum';

@Component({
  selector: 'app-doc2019-case-support-time-activity-dialog',
  templateUrl: './doc2019-case-support-time-activity-dialog.component.html',
  styleUrls: ['./doc2019-case-support-time-activity-dialog.component.css']
})
export class Doc2019CaseSupportTimeActivityDialogComponent {
  currentActivity: Doc2019CaseSupportTimeActivityData;
  form = this.formBuilder.group({
    activityDate: [null, Validators.required],
    activity: [null, Validators.required],
    activityOther: [''],
    activityMinutesSpent: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    travelMinutesSpent: [null, [Validators.min(0), Validators.max(1440)]],
    notes: ['']
  });
  errorMessage = controlErrorMessage;

  yesNoOptions = Object.values(YesNoOption);

  minActivityDate = moment('20191230', 'YYYYMMDD');
  maxActivityDate = moment('20200330', 'YYYYMMDD');

  nonCaseRelatedActivityOptionEnum = Doc2019CaseSupportTimeActivityNonCaseRelatedActivityOption;
  nonCaseRelatedActivityOptions = Object.values(Doc2019CaseSupportTimeActivityNonCaseRelatedActivityOption);

  caseSupportActivityOptionEnum = Doc2019CaseSupportTimeActivityCaseSupportActivityOption;
  caseSupportActivityOptions = Object.values(Doc2019CaseSupportTimeActivityCaseSupportActivityOption);

  constructor(
    private dialogRef: MatDialogRef<Doc2019CaseSupportTimeActivityDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Doc2019CaseSupportTimeActivityDialogData
  ) {
    if (data.activity) {
      // Need to make a copy of the object so updating it doesn't update the origin object until save is applied
      this.currentActivity = {
        index: data.activity.index,
        activityDate: data.activity.activityDate,
        activity: data.activity.activity,
        activityOther: data.activity.activityOther,
        activityMinutesSpent: data.activity.activityMinutesSpent,
        travelMinutesSpent: data.activity.travelMinutesSpent,
        notes: data.activity.notes,
      };

      if (this.currentActivity.activityDate) {
        this.form.controls.activityDate.setValue(moment(this.currentActivity.activityDate, 'x')); // x = unix milliseconds
      }
      this.form.controls.activity.setValue(this.currentActivity.activity);
      this.form.controls.activityOther.setValue(this.currentActivity.activityOther);
      this.form.controls.activityMinutesSpent.setValue(this.currentActivity.activityMinutesSpent);
      this.form.controls.travelMinutesSpent.setValue(this.currentActivity.travelMinutesSpent);
      this.form.controls.notes.setValue(this.currentActivity.notes);
      this.updateTravelMinutesSpentValidators();
      this.form.markAllAsTouched();
    } else {
      this.currentActivity = {
        index: null,
        activityDate: null,
        activity: null,
        activityOther: null,
        activityMinutesSpent: null,
        travelMinutesSpent: null,
        notes: null,
      };
    }

    this.formChangeSubscriptions();
  }

  formChangeSubscriptions(): void {
    this.form.controls.activityDate.valueChanges.subscribe(activityDate => {
      const numberValue = activityDate !== null ? activityDate.format('x') : null;
      if (this.currentActivity.activityDate !== numberValue) {
        this.currentActivity.activityDate = numberValue;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activity.valueChanges.subscribe(activity => {
      if (this.currentActivity.activity !== activity) {
        this.currentActivity.activity = activity;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityOther.valueChanges.subscribe(activityOther => {
      if (this.currentActivity.activityOther !== activityOther) {
        this.currentActivity.activityOther = activityOther;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityMinutesSpent.valueChanges.subscribe(activityMinutesSpent => {
      if (this.currentActivity.activityMinutesSpent !== activityMinutesSpent) {
        this.currentActivity.activityMinutesSpent = activityMinutesSpent;
        this.data.updateCurrentActivity(this.currentActivity);
        this.updateTravelMinutesSpentValidators();
      }
    });

    this.form.controls.travelMinutesSpent.valueChanges.subscribe(travelMinutesSpent => {
      if (this.currentActivity.travelMinutesSpent !== travelMinutesSpent) {
        this.currentActivity.travelMinutesSpent = travelMinutesSpent;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.notes.valueChanges.subscribe(notes => {
      if (this.currentActivity.notes !== notes) {
        this.currentActivity.notes = notes;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });
  }

  /* updates the validators for newSupervisionLevel */
  updateTravelMinutesSpentValidators(): void {
    if (this.currentActivity && this.currentActivity.activityMinutesSpent === 0) {
      this.form.controls.travelMinutesSpent.setValidators([Validators.required, Validators.min(0), Validators.max(1440)]);
    } else {
      this.form.controls.travelMinutesSpent.setValidators([Validators.min(0), Validators.max(1440)]);
    }
    this.form.controls.travelMinutesSpent.updateValueAndValidity();
  }

  updateActivityList(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dialogRef.close(this.currentActivity);
    }
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
