import { Humboldt2020IntakeScreeningActivityActivityOption } from './humboldt2020-intake-screening-activity-dialog/humboldt2020-intake-screening-activity-activity-option.enum';
import { Humboldt2020IntakeScreeningActivityPersonInvolvedOption } from './humboldt2020-intake-screening-activity-dialog/humboldt2020-intake-screening-activity-person-involved-option.enum';
import { Humboldt2020IntakeScreeningActivityMethodOption } from './humboldt2020-intake-screening-activity-dialog/humboldt2020-intake-screening-activity-method-option.enum';
import { Humboldt2020IntakeScreeningActivityPlaceOption } from './humboldt2020-intake-screening-activity-dialog/humboldt2020-intake-screening-activity-place-option.enum';

export interface Humboldt2020IntakeScreeningActivityData {
  index: number | null; // Used to store the index when passing data to the dialog
  activityDate: number | null; // unix timestamp in milliseconds
  activity: Humboldt2020IntakeScreeningActivityActivityOption | null;
  personInvolvedDisplay: string | null;
  personInvolvedReporter: boolean;
  personInvolvedSupervisor: boolean;
  personInvolvedCwsStaff: boolean;
  personInvolvedCollateral: boolean;
  personInvolvedLocalTribe: boolean;
  personInvolvedNonLocalTribe: boolean;
  personInvolvedOther: boolean;
  personInvolvedNone: boolean;
  method: Humboldt2020IntakeScreeningActivityMethodOption | null;
  place: Humboldt2020IntakeScreeningActivityPlaceOption | null;
  activityMinutesSpent: number | null;
  activityDescription: string | null;
}

export function getActivityPersonInvolvedArray(activity: Humboldt2020IntakeScreeningActivityData): string[] {
  const values: Array<string> = [];
  if (activity.personInvolvedReporter) {
    values.push(Humboldt2020IntakeScreeningActivityPersonInvolvedOption.Reporter);
  }
  if (activity.personInvolvedSupervisor) {
    values.push(Humboldt2020IntakeScreeningActivityPersonInvolvedOption.Supervisor);
  }
  if (activity.personInvolvedCwsStaff) {
    values.push(Humboldt2020IntakeScreeningActivityPersonInvolvedOption.CwsStaff);
  }
  if (activity.personInvolvedCollateral) {
    values.push(Humboldt2020IntakeScreeningActivityPersonInvolvedOption.Collateral);
  }
  if (activity.personInvolvedLocalTribe) {
    values.push(Humboldt2020IntakeScreeningActivityPersonInvolvedOption.LocalTribe);
  }
  if (activity.personInvolvedNonLocalTribe) {
    values.push(Humboldt2020IntakeScreeningActivityPersonInvolvedOption.NonLocalTribe);
  }
  if (activity.personInvolvedOther) {
    values.push(Humboldt2020IntakeScreeningActivityPersonInvolvedOption.Other);
  }
  if (activity.personInvolvedNone) {
    values.push(Humboldt2020IntakeScreeningActivityPersonInvolvedOption.None);
  }
  return values;
}
