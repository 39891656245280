import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { Humboldt2020IdDirective } from './humboldt2020-id.directive';
import { Humboldt2020CourtIntakeComponent } from './humboldt2020-court-intake/humboldt2020-court-intake.component';
import { Humboldt2020CourtIntakeActivityDialogComponent } from './humboldt2020-court-intake/humboldt2020-court-intake-activity-dialog/humboldt2020-court-intake-activity-dialog.component';
import { Humboldt2020CourtIntakeActivityListFilterDialogComponent } from './humboldt2020-court-intake/humboldt2020-court-intake-activity-list-filter-dialog/humboldt2020-court-intake-activity-list-filter-dialog.component';
import { Humboldt2020IntakeScreeningComponent } from './humboldt2020-intake-screening/humboldt2020-intake-screening.component';
import { Humboldt2020IntakeScreeningActivityDialogComponent } from './humboldt2020-intake-screening/humboldt2020-intake-screening-activity-dialog/humboldt2020-intake-screening-activity-dialog.component';
import { Humboldt2020IntakeScreeningActivityListFilterDialogComponent } from './humboldt2020-intake-screening/humboldt2020-intake-screening-activity-list-filter-dialog/humboldt2020-intake-screening-activity-list-filter-dialog.component';
import { Humboldt2020ReferralInvestigationComponent } from './humboldt2020-referral-investigation/humboldt2020-referral-investigation.component';
import { Humboldt2020ReferralInvestigationActivityDialogComponent } from './humboldt2020-referral-investigation/humboldt2020-referral-investigation-activity-dialog/humboldt2020-referral-investigation-activity-dialog.component';
import { Humboldt2020ReferralInvestigationActivityListFilterDialogComponent } from './humboldt2020-referral-investigation/humboldt2020-referral-investigation-activity-list-filter-dialog/humboldt2020-referral-investigation-activity-list-filter-dialog.component';
import { Humboldt2020OngoingEfcAdoptComponent } from './humboldt2020-ongoing-efc-adopt/humboldt2020-ongoing-efc-adopt.component';
import { Humboldt2020OngoingEfcAdoptActivityDialogComponent } from './humboldt2020-ongoing-efc-adopt/humboldt2020-ongoing-efc-adopt-activity-dialog/humboldt2020-ongoing-efc-adopt-activity-dialog.component';
import { Humboldt2020OngoingEfcAdoptActivityListFilterDialogComponent } from './humboldt2020-ongoing-efc-adopt/humboldt2020-ongoing-efc-adopt-activity-list-filter-dialog/humboldt2020-ongoing-efc-adopt-activity-list-filter-dialog.component';
import { Humboldt2020PlacementComponent } from './humboldt2020-placement/humboldt2020-placement.component';
import { Humboldt2020PlacementActivityDialogComponent } from './humboldt2020-placement/humboldt2020-placement-activity-dialog/humboldt2020-placement-activity-dialog.component';
import { Humboldt2020PlacementActivityListFilterDialogComponent } from './humboldt2020-placement/humboldt2020-placement-activity-list-filter-dialog/humboldt2020-placement-activity-list-filter-dialog.component';
import { Humboldt2020ResourceFamilyApprovalComponent } from './humboldt2020-resource-family-approval/humboldt2020-resource-family-approval.component';
import { Humboldt2020ResourceFamilyApprovalActivityDialogComponent } from './humboldt2020-resource-family-approval/humboldt2020-resource-family-approval-activity-dialog/humboldt2020-resource-family-approval-activity-dialog.component';
import { Humboldt2020ResourceFamilyApprovalActivityListFilterDialogComponent } from './humboldt2020-resource-family-approval/humboldt2020-resource-family-approval-activity-list-filter-dialog/humboldt2020-resource-family-approval-activity-list-filter-dialog.component';
import { Humboldt2020SupportAdminComponent } from './humboldt2020-support-admin/humboldt2020-support-admin.component';
import { Humboldt2020SupportAdminActivityDialogComponent } from './humboldt2020-support-admin/humboldt2020-support-admin-activity-dialog/humboldt2020-support-admin-activity-dialog.component';
import { Humboldt2020SupportAdminActivityListFilterDialogComponent } from './humboldt2020-support-admin/humboldt2020-support-admin-activity-list-filter-dialog/humboldt2020-support-admin-activity-list-filter-dialog.component';
import { Humboldt2020IlsComponent } from './humboldt2020-ils/humboldt2020-ils.component';
import { Humboldt2020IlsActivityDialogComponent } from './humboldt2020-ils/humboldt2020-ils-activity-dialog/humboldt2020-ils-activity-dialog.component';
import { Humboldt2020IlsActivityListFilterDialogComponent } from './humboldt2020-ils/humboldt2020-ils-activity-list-filter-dialog/humboldt2020-ils-activity-list-filter-dialog.component';

@NgModule({
  declarations: [
    Humboldt2020IdDirective,
    Humboldt2020CourtIntakeComponent,
    Humboldt2020CourtIntakeActivityDialogComponent,
    Humboldt2020CourtIntakeActivityListFilterDialogComponent,
    Humboldt2020IntakeScreeningComponent,
    Humboldt2020IntakeScreeningActivityDialogComponent,
    Humboldt2020IntakeScreeningActivityListFilterDialogComponent,
    Humboldt2020ReferralInvestigationComponent,
    Humboldt2020ReferralInvestigationActivityDialogComponent,
    Humboldt2020ReferralInvestigationActivityListFilterDialogComponent,
    Humboldt2020OngoingEfcAdoptComponent,
    Humboldt2020OngoingEfcAdoptActivityDialogComponent,
    Humboldt2020OngoingEfcAdoptActivityListFilterDialogComponent,
    Humboldt2020PlacementComponent,
    Humboldt2020PlacementActivityDialogComponent,
    Humboldt2020PlacementActivityListFilterDialogComponent,
    Humboldt2020ResourceFamilyApprovalComponent,
    Humboldt2020ResourceFamilyApprovalActivityDialogComponent,
    Humboldt2020ResourceFamilyApprovalActivityListFilterDialogComponent,
    Humboldt2020SupportAdminComponent,
    Humboldt2020SupportAdminActivityDialogComponent,
    Humboldt2020SupportAdminActivityListFilterDialogComponent,
    Humboldt2020IlsComponent,
    Humboldt2020IlsActivityDialogComponent,
    Humboldt2020IlsActivityListFilterDialogComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    DigitOnlyModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  entryComponents: [
    Humboldt2020CourtIntakeActivityDialogComponent,
    Humboldt2020CourtIntakeActivityListFilterDialogComponent,
    Humboldt2020IntakeScreeningActivityDialogComponent,
    Humboldt2020IntakeScreeningActivityListFilterDialogComponent,
    Humboldt2020ReferralInvestigationActivityDialogComponent,
    Humboldt2020ReferralInvestigationActivityListFilterDialogComponent,
    Humboldt2020OngoingEfcAdoptActivityDialogComponent,
    Humboldt2020OngoingEfcAdoptActivityListFilterDialogComponent,
    Humboldt2020PlacementActivityDialogComponent,
    Humboldt2020PlacementActivityListFilterDialogComponent,
    Humboldt2020ResourceFamilyApprovalActivityDialogComponent,
    Humboldt2020ResourceFamilyApprovalActivityListFilterDialogComponent,
    Humboldt2020SupportAdminActivityDialogComponent,
    Humboldt2020SupportAdminActivityListFilterDialogComponent,
    Humboldt2020IlsActivityDialogComponent,
    Humboldt2020IlsActivityListFilterDialogComponent
  ]
})
export class Humboldt2020Module { }
