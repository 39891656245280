import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { controlErrorMessage } from '../../../utility-functions';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Doc2019OffenderSupervisionTimeActivityDialogComponent } from './doc2019-offender-supervision-time-activity-dialog/doc2019-offender-supervision-time-activity-dialog.component';
import { Doc2019OffenderSupervisionTimeActivityData } from './doc2019-offender-supervision-time-activity-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MessageDialogComponent } from '../../../message-dialog/message-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Doc2019OffenderSupervisionTimeViolationData } from './doc2019-offender-supervision-time-violation-data';
import { Doc2019OffenderSupervisionTimeViolationDialogComponent } from './doc2019-offender-supervision-time-violation-dialog/doc2019-offender-supervision-time-violation-dialog.component';
import { DatabaseService } from '../../../database/database.service';
import { FormComponent } from '../../../form/form.component';
import { Doc2019OffenderSupervisionTimeFormData } from './doc2019-offender-supervision-time-form-data';
import { isArray } from 'util';
import { FormStatus } from '../../../form/form-status.enum';
import { YesNoOption } from '../../../form/yes-no-option.enum';
import { Doc2019OffenderSupervisionTimeCaseActivityOption } from './doc2019-offender-supervision-time-case-activity-option.enum';
import { Doc2019OffenderSupervisionTimeNewSupervisionLevelOption } from './doc2019-offender-supervision-time-new-supervision-level-option.enum';
import { Doc2019OffenderSupervisionTimeProgramsOption } from './doc2019-offender-supervision-time-programs-option.enum';
import { Doc2019OffenderSupervisionTimeWorkMetStandardsNoReasonsOption } from './doc2019-offender-supervision-time-work-met-standards-no-reasons-option.enum';
import { digitsOnlyValidator, noneApplyValidator } from '../../../validators';
import { Doc2019OffenderSupervisionTimeViolationDialogData } from './doc2019-offender-supervision-time-violation-dialog/doc2019-offender-supervision-time-violation-dialog-data';
import { Doc2019OffenderSupervisionTimeActivityDialogData } from './doc2019-offender-supervision-time-activity-dialog/doc2019-offender-supervision-time-activity-dialog-data';
import { AuthService } from '../../../auth/auth.service';
import { ApiService } from '../../../api/api.service';
import { OnlineService } from '../../../online/online.service';
import { Doc2019OffenderSupervisionTimeOffenderCaseClassificationOption } from './doc2019-offender-supervision-time-offender-case-classification-option.enum';
import { Doc2019OffenderSupervisionTimeActivityActivityOption } from './doc2019-offender-supervision-time-activity-dialog/doc2019-offender-supervision-time-activity-activity-option.enum';
import { Doc2019OffenderSupervisionTimeActivityListColumn } from './doc2019-offender-supervision-time-activity-list-column.enum';
import { Doc2019OffenderSupervisionTimeViolationListColumn } from './doc2019-offender-supervision-time-violation-list-column.enum';
import { MatSort, Sort } from '@angular/material/sort';
import { Doc2019OffenderSupervisionTimeActivityListFilterData } from './doc2019-offender-supervision-time-activity-list-filter-data';
import { Doc2019OffenderSupervisionTimeActivityListSortOptionData } from './doc2019-offender-supervision-time-activity-list-sort-option-data';
import { debounceTime } from 'rxjs/operators';
import { Doc2019OffenderSupervisionTimeActivityListFilterDialogComponent } from './doc2019-offender-supervision-time-activity-list-filter-dialog/doc2019-offender-supervision-time-activity-list-filter-dialog.component';
import { Doc2019OffenderSupervisionTimeActivityListFilterDialogData } from './doc2019-offender-supervision-time-activity-list-filter-dialog/doc2019-offender-supervision-time-activity-list-filter-dialog-data';
import { getFormTypeById } from '../../form-type-data';
import { TitleService } from '../../../title/title.service';

@Component({
  selector: 'app-doc2019-offender-supervision-time',
  templateUrl: './doc2019-offender-supervision-time.component.html',
  styleUrls: ['./doc2019-offender-supervision-time.component.css']
})
export class Doc2019OffenderSupervisionTimeComponent extends FormComponent implements OnDestroy {
  formTypeId = 3;
  formType = getFormTypeById(this.formTypeId);
  formDataClass = Doc2019OffenderSupervisionTimeFormData;
  currentFormData: Doc2019OffenderSupervisionTimeFormData | null = null;

  activitiesDataSource: Doc2019OffenderSupervisionTimeActivityData[] = [];
  activityListColumnEnum = Doc2019OffenderSupervisionTimeActivityListColumn;
  activityListDisplayedColumns = Object.values(Doc2019OffenderSupervisionTimeActivityListColumn);
  activityListFilterForm = this.formBuilder.group({
    activity: [null],
    sorting: [null]
  });
  activityListFilters: Doc2019OffenderSupervisionTimeActivityListFilterData = {
    activity: null,
    sortColumn: null,
    sortDirection: null
  };
  activityListFilterInfoString = '';
  activityOptionEnum = Doc2019OffenderSupervisionTimeActivityActivityOption;
  activityOptions = Object.values(Doc2019OffenderSupervisionTimeActivityActivityOption);
  activityListSortOptions: Doc2019OffenderSupervisionTimeActivityListSortOptionData[] = [
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.activityDate, sortDirection: 'asc', display: 'Date Ascending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.activityDate, sortDirection: 'desc', display: 'Date Descending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.violationWork, sortDirection: 'asc', display: 'Violation Work Ascending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.violationWork, sortDirection: 'desc', display: 'Violation Work Descending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.activity, sortDirection: 'asc', display: 'Activity Ascending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.activity, sortDirection: 'desc', display: 'Activity Descending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.personInvolved, sortDirection: 'asc', display: 'Person Involved Ascending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.personInvolved, sortDirection: 'desc', display: 'Person Involved Descending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.method, sortDirection: 'asc', display: 'Method Ascending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.method, sortDirection: 'desc', display: 'Method Descending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.agentLocation, sortDirection: 'asc', display: 'Agent\'s Location Ascending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.agentLocation, sortDirection: 'desc', display: 'Agent\'s Location Descending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.activityMinutesSpent, sortDirection: 'asc', display: 'Activity Minutes Spent Ascending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.activityMinutesSpent, sortDirection: 'desc', display: 'Activity Minutes Spent Descending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.travelMinutesSpent, sortDirection: 'asc', display: 'Travel Minutes Spent Ascending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.travelMinutesSpent, sortDirection: 'desc', display: 'Travel Minutes Spent Descending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.waitingMinutesSpent, sortDirection: 'asc', display: 'Waiting Minutes Spent Ascending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.waitingMinutesSpent, sortDirection: 'desc', display: 'Waiting Minutes Spent Descending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.notes, sortDirection: 'asc', display: 'Notes Ascending'},
    {sortColumn: Doc2019OffenderSupervisionTimeActivityListColumn.notes, sortDirection: 'desc', display: 'Notes Descending'},
  ];
  activityListSort: MatSort;
  @ViewChild('activityTable', {read: MatSort, static: false}) set activityListSortViewChild(value: MatSort) {
    this.activityListSort = value;
    // This timeout is needed or angular complains Expression has changed after it was checked.
    window.setTimeout(() => {
      // default in the current set sorting when the sorter is set
      // this happens if the width goes from isSmallDisplay to isLargeDisplay
      if (this.activityListSort && this.activityListFilterForm && this.activityListFilterForm.controls.sorting.value) {
        const sorting: Doc2019OffenderSupervisionTimeActivityListSortOptionData = this.activityListFilterForm.controls.sorting.value;
        if (this.activityListSort.active != sorting.sortColumn || this.activityListSort.direction != sorting.sortDirection) {
          if (this.activityListSort) {
            this.activityListSort.sort({
              id: sorting.sortColumn,
              start: sorting.sortDirection,
              disableClear: true
            });
          }
        }
      }
    });
  }

  violationsDataSource: MatTableDataSource<Doc2019OffenderSupervisionTimeViolationData>;
  violationListColumnEnum = Doc2019OffenderSupervisionTimeViolationListColumn;
  violationListDisplayedColumns = Object.values(Doc2019OffenderSupervisionTimeViolationListColumn);

  form = this.formBuilder.group({
    agentName: [null],
    createdDate: [null],
    status: [null],
    statusChangedDate: [null],
    updatedDate: [null],
    offenderName: [null, [Validators.required, Validators.maxLength(200)]],
    offenderId: [null, [Validators.required, Validators.maxLength(6), digitsOnlyValidator]],
    offenderCaseClassification: [null, Validators.required],
    programs: [[], [Validators.required, noneApplyValidator]],
    activityListFilter: this.activityListFilterForm,
    caseActivity: [[], [Validators.required, noneApplyValidator]],
    csrCompleted: [null, Validators.required],
    dateCsrCompleted: [null],
    newSupervisionLevel: [null],
    agentComments: [null],
    workMetStandards: [null],
    workMetStandardsNoReasons: [[]],
    workMetStandardsNoReasonOtherText: [null],
    supervisorComments: [null]
  });
  errorMessage = controlErrorMessage;

  offenderCaseClassificationOptions = Object.values(Doc2019OffenderSupervisionTimeOffenderCaseClassificationOption);

  programsOptions = Object.values(Doc2019OffenderSupervisionTimeProgramsOption);

  caseActivityOptionEnum = Doc2019OffenderSupervisionTimeCaseActivityOption;
  caseActivityOptions = Object.values(Doc2019OffenderSupervisionTimeCaseActivityOption);

  minDateCsrCompleted = moment('20200106', 'YYYYMMDD');
  maxDateCsrCompleted = moment('20200330', 'YYYYMMDD');

  newSupervisionLevelOptions = Object.values(Doc2019OffenderSupervisionTimeNewSupervisionLevelOption);

  workMetStandardsNoReasonsOptions = Object.values(Doc2019OffenderSupervisionTimeWorkMetStandardsNoReasonsOption);

  constructor(
    public apiService: ApiService,
    public authService: AuthService,
    public breakpointObserver: BreakpointObserver,
    public databaseService: DatabaseService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    public onlineService: OnlineService,
    public route: ActivatedRoute,
    public router: Router,
    public titleService: TitleService
  ) {
    super(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, Doc2019OffenderSupervisionTimeFormData);
    this.loadFormTypeSettings();
    this.formChangeSubscriptions();

    this.saveFormDialogErrorData.message = 'You must provide an Offender Name to save the form.';
    this.sendBackFormDialogErrorData.message = 'You must provide an Offender Name to save the form.';

    this.submitFormDialogData.message = 'Submitting the form will send it to your supervisor for review and you will no longer be able to edit this form. Make sure you have:';
    this.submitFormDialogData.messageList = [
      'Recorded all the time spent working on this case during the month',
      'Recorded any violation end dates (if they occurred) during the month'
    ];

    this.completeFormDialogData.message = 'Approving the form will remove it from your queue and you will no longer be able to edit this form. Make sure:';
    this.completeFormDialogData.messageList = [
      'The form captures all the work that was completed by the agent.',
      'You have reviewed the form for required work activities designated by standards.',
      'Time is recorded in minutes.'
    ];
  }

  /* adds subscriptions for each of the form fields to update this.currentFormData */
  formChangeSubscriptions(): void {
    this.form.controls.offenderName.valueChanges.subscribe(offenderName => {
      if (this.currentFormData && this.currentFormData.name !== offenderName) {
        this.currentFormData.name = offenderName;
        this.saveCurrentFormValues();
      }
      if (this.formType && this.currentFormData) {
        const title = this.formType.name + ' - ' + this.currentFormData.name;
        if (this.titleService.title.value !== title) {
          this.titleService.title.next(title);
        }
      }
    });

    this.form.controls.offenderId.valueChanges.subscribe(offenderId => {
      if (this.currentFormData && this.currentFormData.offenderId !== offenderId) {
        this.currentFormData.offenderId = offenderId;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.offenderCaseClassification.valueChanges.subscribe(offenderCaseClassification => {
      if (this.currentFormData && this.currentFormData.offenderCaseClassification !== offenderCaseClassification) {
        this.currentFormData.caseType = offenderCaseClassification;
        this.currentFormData.offenderCaseClassification = offenderCaseClassification;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.programs.valueChanges.subscribe(programs => {
      let programEmpBracelet = false;
      let programGps = false;
      let programOars = false;
      let programSoberlinkTad = false;
      let programSpecialtyCourt = false;
      let programNoneApply = false;

      if (isArray(programs)) {
        (programs as string[]).forEach(program => {
          switch (program) {
            case Doc2019OffenderSupervisionTimeProgramsOption.EmpBracelet:
              programEmpBracelet = true;
              break;
            case Doc2019OffenderSupervisionTimeProgramsOption.Gps:
              programGps = true;
              break;
            case Doc2019OffenderSupervisionTimeProgramsOption.Oars:
              programOars = true;
              break;
            case Doc2019OffenderSupervisionTimeProgramsOption.SoberlinkTad:
              programSoberlinkTad = true;
              break;
            case Doc2019OffenderSupervisionTimeProgramsOption.SpecialtyCourt:
              programSpecialtyCourt = true;
              break;
            case Doc2019OffenderSupervisionTimeProgramsOption.NoneApply:
              programNoneApply = true;
              break;
          }
        })
      }

      let saveCurrentFormValues = false;
      if (this.currentFormData && this.currentFormData.programEmpBracelet !== programEmpBracelet) {
        this.currentFormData.programEmpBracelet = programEmpBracelet;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.programGps !== programGps) {
        this.currentFormData.programGps = programGps;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.programOars !== programOars) {
        this.currentFormData.programOars = programOars;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.programSoberlinkTad !== programSoberlinkTad) {
        this.currentFormData.programSoberlinkTad = programSoberlinkTad;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.programSpecialtyCourt !== programSpecialtyCourt) {
        this.currentFormData.programSpecialtyCourt = programSpecialtyCourt;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.programNoneApply !== programNoneApply) {
        this.currentFormData.programNoneApply = programNoneApply;
        saveCurrentFormValues = true;
      }
      if (saveCurrentFormValues) {
        this.saveCurrentFormValues();
      }
    });

    this.activityListFilterForm.controls.sorting.valueChanges.subscribe((sorting: Doc2019OffenderSupervisionTimeActivityListSortOptionData | null ) => {
      if (sorting && this.activityListSort && (this.activityListSort.active !== sorting.sortColumn || (this.activityListSort.direction || 'asc') !== sorting.sortDirection)) {
        this.activityListSort.sort({
          id: sorting.sortColumn,
          start: sorting.sortDirection,
          disableClear: true
        });
      }
    });

    this.activityListFilterForm.valueChanges.pipe(debounceTime(100)).subscribe(values => {
      let saveActivityListFilters = false;
      if (this.activityListFilters.activity !== values.activity) {
        this.activityListFilters.activity = values.activity;
        saveActivityListFilters = true;
      }

      if (values.sorting) {
        if (this.activityListFilters.sortColumn !== values.sorting.sortColumn || this.activityListFilters.sortDirection !== values.sorting.sortDirection) {
          this.activityListFilters.sortColumn = values.sorting.sortColumn;
          this.activityListFilters.sortDirection = values.sorting.sortDirection;
          saveActivityListFilters = true;
        }
      } else if (this.activityListFilters.sortColumn !== null || this.activityListFilters.sortDirection !== null) {
        this.activityListFilters.sortColumn = null;
        this.activityListFilters.sortDirection = null;
        saveActivityListFilters = true;
      }

      if (saveActivityListFilters) {
        this.saveFormTypeSettings();
      }

      this.activityListFilterAndSortData();
    });

    this.form.controls.caseActivity.valueChanges.subscribe(caseActivity => {
      let caseActivityAbsconder = false;
      let caseActivityInstallRemoveMonitoringDevice = false;
      let caseActivityIntrastateTransferOutgoing = false;
      let caseActivityViolation = false;
      let caseActivityAtr = false;
      let caseActivityRevocation = false;
      let caseActivityNoneApply = false;

      if (isArray(caseActivity)) {
        (caseActivity as string[]).forEach(caseActivity => {
          switch (caseActivity) {
            case Doc2019OffenderSupervisionTimeCaseActivityOption.Absconder:
              caseActivityAbsconder = true;
              break;
            case Doc2019OffenderSupervisionTimeCaseActivityOption.InstallRemoveMonitoringDevice:
              caseActivityInstallRemoveMonitoringDevice = true;
              break;
            case Doc2019OffenderSupervisionTimeCaseActivityOption.IntrastateTransferOutgoing:
              caseActivityIntrastateTransferOutgoing = true;
              break;
            case Doc2019OffenderSupervisionTimeCaseActivityOption.Violation:
              caseActivityViolation = true;
              break;
            case Doc2019OffenderSupervisionTimeCaseActivityOption.Atr:
              caseActivityAtr = true;
              break;
            case Doc2019OffenderSupervisionTimeCaseActivityOption.Revocation:
              caseActivityRevocation = true;
              break;
            case Doc2019OffenderSupervisionTimeCaseActivityOption.NoneApply:
              caseActivityNoneApply = true;
              break;
          }
        })
      }

      let saveCurrentFormValues = false;
      if (this.currentFormData && this.currentFormData.caseActivityAbsconder !== caseActivityAbsconder) {
        this.currentFormData.caseActivityAbsconder = caseActivityAbsconder;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.caseActivityInstallRemoveMonitoringDevice !== caseActivityInstallRemoveMonitoringDevice) {
        this.currentFormData.caseActivityInstallRemoveMonitoringDevice = caseActivityInstallRemoveMonitoringDevice;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.caseActivityIntrastateTransferOutgoing !== caseActivityIntrastateTransferOutgoing) {
        this.currentFormData.caseActivityIntrastateTransferOutgoing = caseActivityIntrastateTransferOutgoing;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.caseActivityViolation !== caseActivityViolation) {
        this.currentFormData.caseActivityViolation = caseActivityViolation;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.caseActivityAtr !== caseActivityAtr) {
        this.currentFormData.caseActivityAtr = caseActivityAtr;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.caseActivityRevocation !== caseActivityRevocation) {
        this.currentFormData.caseActivityRevocation = caseActivityRevocation;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.caseActivityNoneApply !== caseActivityNoneApply) {
        this.currentFormData.caseActivityNoneApply = caseActivityNoneApply;
        saveCurrentFormValues = true;
      }
      if (saveCurrentFormValues) {
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.csrCompleted.valueChanges.subscribe(csrCompleted => {
      if (this.currentFormData && this.currentFormData.csrCompleted !== csrCompleted) {
        this.currentFormData.csrCompleted = csrCompleted;
        this.saveCurrentFormValues();
        this.updateDateCsrCompletedValidators();
        this.updateNewSupervisionLevelValidators();
      }
    });

    this.form.controls.dateCsrCompleted.valueChanges.subscribe(dateCsrCompleted => {
      const numberValue = dateCsrCompleted !== null ? dateCsrCompleted.format('x') : null;
      if (this.currentFormData && this.currentFormData.dateCsrCompleted !== numberValue) {
        this.currentFormData.dateCsrCompleted = numberValue;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.newSupervisionLevel.valueChanges.subscribe(newSupervisionLevel => {
      if (this.currentFormData && this.currentFormData.newSupervisionLevel !== newSupervisionLevel) {
        this.currentFormData.newSupervisionLevel = newSupervisionLevel;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.agentComments.valueChanges.subscribe(agentComments => {
      if (this.currentFormData && this.currentFormData.agentComments !== agentComments) {
        this.currentFormData.agentComments = agentComments;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.workMetStandards.valueChanges.subscribe(workMetStandards => {
      if (this.currentFormData && this.currentFormData.workMetStandards !== workMetStandards) {
        this.currentFormData.workMetStandards = workMetStandards;
        this.saveCurrentFormValues();
        this.updateWorkMetStandardsNoReasonsValidators();
        this.updateWorkMetStandardsNoReasonsOtherValidators();
      }
    });

    this.form.controls.workMetStandardsNoReasons.valueChanges.subscribe(workMetStandardsNoReasons => {
      let workMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet = false;
      let workMetStandardsNoReasonActivitiesNotReflective = false;
      let workMetStandardsNoReasonWorkerStoppedTracking = false;
      let workMetStandardsNoReasonNotAllWorkDoneDuringMonth = false;
      let workMetStandardsNoReasonOther = false;

      if (isArray(workMetStandardsNoReasons)) {
        (workMetStandardsNoReasons as string[]).forEach(workMetStandardsNoReason => {
          switch (workMetStandardsNoReason) {
            case Doc2019OffenderSupervisionTimeWorkMetStandardsNoReasonsOption.NumberOfContactsOtherStandardsNotMet:
              workMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet = true;
              break;
            case Doc2019OffenderSupervisionTimeWorkMetStandardsNoReasonsOption.ActivitiesNotReflective:
              workMetStandardsNoReasonActivitiesNotReflective = true;
              break;
            case Doc2019OffenderSupervisionTimeWorkMetStandardsNoReasonsOption.WorkerStoppedTracking:
              workMetStandardsNoReasonWorkerStoppedTracking = true;
              break;
            case Doc2019OffenderSupervisionTimeWorkMetStandardsNoReasonsOption.NotAllWorkDoneDuringMonth:
              workMetStandardsNoReasonNotAllWorkDoneDuringMonth = true;
              break;
            case Doc2019OffenderSupervisionTimeWorkMetStandardsNoReasonsOption.Other:
              workMetStandardsNoReasonOther = true;
              break;
          }
        })
      }

      let saveCurrentFormValues = false;
      if (this.currentFormData && this.currentFormData.workMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet !== workMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet) {
        this.currentFormData.workMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet = workMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.workMetStandardsNoReasonActivitiesNotReflective !== workMetStandardsNoReasonActivitiesNotReflective) {
        this.currentFormData.workMetStandardsNoReasonActivitiesNotReflective = workMetStandardsNoReasonActivitiesNotReflective;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.workMetStandardsNoReasonWorkerStoppedTracking !== workMetStandardsNoReasonWorkerStoppedTracking) {
        this.currentFormData.workMetStandardsNoReasonWorkerStoppedTracking = workMetStandardsNoReasonWorkerStoppedTracking;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.workMetStandardsNoReasonNotAllWorkDoneDuringMonth !== workMetStandardsNoReasonNotAllWorkDoneDuringMonth) {
        this.currentFormData.workMetStandardsNoReasonNotAllWorkDoneDuringMonth = workMetStandardsNoReasonNotAllWorkDoneDuringMonth;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.workMetStandardsNoReasonOther !== workMetStandardsNoReasonOther) {
        this.currentFormData.workMetStandardsNoReasonOther = workMetStandardsNoReasonOther;
        saveCurrentFormValues = true;
        this.updateWorkMetStandardsNoReasonsOtherValidators();
      }
      if (saveCurrentFormValues) {
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.workMetStandardsNoReasonOtherText.valueChanges.subscribe(workMetStandardsNoReasonOtherText => {
      if (this.currentFormData && this.currentFormData.workMetStandardsNoReasonOtherText !== workMetStandardsNoReasonOtherText) {
        this.currentFormData.workMetStandardsNoReasonOtherText = workMetStandardsNoReasonOtherText;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.supervisorComments.valueChanges.subscribe(supervisorComments => {
      if (this.currentFormData && this.currentFormData.supervisorComments !== supervisorComments) {
        this.currentFormData.supervisorComments = supervisorComments;
        this.saveCurrentFormValues();
      }
    });
  }

  /* updates the validators for dateCsrCompleted */
  updateDateCsrCompletedValidators(): void {
    if (this.currentFormData && this.currentFormData.csrCompleted === YesNoOption.Yes) {
      this.form.controls.dateCsrCompleted.setValidators([Validators.required]);
    } else {
      this.form.controls.dateCsrCompleted.setValidators([]);
    }
    this.form.controls.dateCsrCompleted.updateValueAndValidity();
  }

  /* updates the validators for newSupervisionLevel */
  updateNewSupervisionLevelValidators(): void {
    if (this.currentFormData && this.currentFormData.csrCompleted === YesNoOption.Yes) {
      this.form.controls.newSupervisionLevel.setValidators(Validators.required);
    } else {
      this.form.controls.newSupervisionLevel.clearValidators();
    }
    this.form.controls.newSupervisionLevel.updateValueAndValidity();
  }

  /* updates the validators for workMetStandardsNoReasons */
  updateWorkMetStandardsValidators(): void {
    if (this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
      this.form.controls.workMetStandards.setValidators(Validators.required);
    } else {
      this.form.controls.workMetStandards.clearValidators();
    }
    this.form.controls.workMetStandards.updateValueAndValidity();
  }

  /* updates the validators for workMetStandardsNoReasons */
  updateWorkMetStandardsNoReasonsValidators(): void {
    if (this.currentFormData && this.currentFormData.status === FormStatus.InReview &&
      this.currentFormData.workMetStandards === YesNoOption.No) {
      this.form.controls.workMetStandardsNoReasons.setValidators(Validators.required);
    } else {
      this.form.controls.workMetStandardsNoReasons.clearValidators();
    }
    this.form.controls.workMetStandardsNoReasons.updateValueAndValidity();
  }

  /* updates the validators for workMetStandardsNoReasonOtherText */
  updateWorkMetStandardsNoReasonsOtherValidators(): void {
    if (this.currentFormData && this.currentFormData.status === FormStatus.InReview &&
      this.currentFormData.workMetStandards === YesNoOption.No && this.currentFormData.workMetStandardsNoReasonOther) {
      this.form.controls.workMetStandardsNoReasonOtherText.setValidators(Validators.required);
    } else {
      this.form.controls.workMetStandardsNoReasonOtherText.clearValidators();
    }
    this.form.controls.workMetStandardsNoReasonOtherText.updateValueAndValidity();
  }

  /* Hydrates form with currentFormData values */
  afterFormDataLoaded(): void {
    super.afterFormDataLoaded();
    if (this.formType && this.currentFormData) {
      const title = this.formType.name + ' - ' + this.currentFormData.name;
      if (this.titleService.title.value !== title) {
        this.titleService.title.next(title);
      }
    }
    if (this.currentFormData) {
      this.form.controls.agentName.setValue(this.currentFormData.created.userName);
      this.form.controls.createdDate.setValue(moment(this.currentFormData.created.timestamp, 'x').format('L LT'));
      this.form.controls.status.setValue(this.currentFormData.status);
      this.form.controls.statusChangedDate.setValue(moment(this.currentFormData.statusChangeTimestamp, 'x').format('L LT'));
      this.form.controls.updatedDate.setValue(moment(this.currentFormData.updated.timestamp, 'x').format('L LT'));
      this.form.controls.offenderName.setValue(this.currentFormData.name);
      this.form.controls.offenderId.setValue(this.currentFormData.offenderId);
      this.form.controls.offenderCaseClassification.setValue(this.currentFormData.offenderCaseClassification);
      const programs: string[] = [];
      if (this.currentFormData.programEmpBracelet) programs.push(Doc2019OffenderSupervisionTimeProgramsOption.EmpBracelet);
      if (this.currentFormData.programGps) programs.push(Doc2019OffenderSupervisionTimeProgramsOption.Gps);
      if (this.currentFormData.programOars) programs.push(Doc2019OffenderSupervisionTimeProgramsOption.Oars);
      if (this.currentFormData.programSoberlinkTad) programs.push(Doc2019OffenderSupervisionTimeProgramsOption.SoberlinkTad);
      if (this.currentFormData.programSpecialtyCourt) programs.push(Doc2019OffenderSupervisionTimeProgramsOption.SpecialtyCourt);
      if (this.currentFormData.programNoneApply) programs.push(Doc2019OffenderSupervisionTimeProgramsOption.NoneApply);
      this.form.controls.programs.setValue(programs);

      this.activityListFilterAndSortData();

      const caseActivity: string[] = [];
      if (this.currentFormData.caseActivityAbsconder) caseActivity.push(Doc2019OffenderSupervisionTimeCaseActivityOption.Absconder);
      if (this.currentFormData.caseActivityInstallRemoveMonitoringDevice) caseActivity.push(Doc2019OffenderSupervisionTimeCaseActivityOption.InstallRemoveMonitoringDevice);
      if (this.currentFormData.caseActivityIntrastateTransferOutgoing) caseActivity.push(Doc2019OffenderSupervisionTimeCaseActivityOption.IntrastateTransferOutgoing);
      if (this.currentFormData.caseActivityViolation) caseActivity.push(Doc2019OffenderSupervisionTimeCaseActivityOption.Violation);
      if (this.currentFormData.caseActivityAtr) caseActivity.push(Doc2019OffenderSupervisionTimeCaseActivityOption.Atr);
      if (this.currentFormData.caseActivityRevocation) caseActivity.push(Doc2019OffenderSupervisionTimeCaseActivityOption.Revocation);
      if (this.currentFormData.caseActivityNoneApply) caseActivity.push(Doc2019OffenderSupervisionTimeCaseActivityOption.NoneApply);
      this.form.controls.caseActivity.setValue(caseActivity);
      this.violationsDataSource = new MatTableDataSource(this.currentFormData.violations);
      this.form.controls.csrCompleted.setValue(this.currentFormData.csrCompleted);
      this.form.controls.dateCsrCompleted.setValue(this.currentFormData.dateCsrCompleted !== null ? moment(this.currentFormData.dateCsrCompleted, 'x') : null);
      this.form.controls.newSupervisionLevel.setValue(this.currentFormData.newSupervisionLevel);
      this.form.controls.agentComments.setValue(this.currentFormData.agentComments);

      this.form.controls.workMetStandards.setValue(this.currentFormData.workMetStandards);
      const workMetStandardsNoReasons: string[] = [];
      if (this.currentFormData.workMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet) workMetStandardsNoReasons.push(Doc2019OffenderSupervisionTimeWorkMetStandardsNoReasonsOption.NumberOfContactsOtherStandardsNotMet);
      if (this.currentFormData.workMetStandardsNoReasonActivitiesNotReflective) workMetStandardsNoReasons.push(Doc2019OffenderSupervisionTimeWorkMetStandardsNoReasonsOption.ActivitiesNotReflective);
      if (this.currentFormData.workMetStandardsNoReasonWorkerStoppedTracking) workMetStandardsNoReasons.push(Doc2019OffenderSupervisionTimeWorkMetStandardsNoReasonsOption.WorkerStoppedTracking);
      if (this.currentFormData.workMetStandardsNoReasonNotAllWorkDoneDuringMonth) workMetStandardsNoReasons.push(Doc2019OffenderSupervisionTimeWorkMetStandardsNoReasonsOption.NotAllWorkDoneDuringMonth);
      if (this.currentFormData.workMetStandardsNoReasonOther) workMetStandardsNoReasons.push(Doc2019OffenderSupervisionTimeWorkMetStandardsNoReasonsOption.Other);
      this.form.controls.workMetStandardsNoReasons.setValue(workMetStandardsNoReasons);
      this.form.controls.workMetStandardsNoReasonOtherText.setValue(this.currentFormData.workMetStandardsNoReasonOtherText);
      this.form.controls.supervisorComments.setValue(this.currentFormData.supervisorComments);

      this.updateDateCsrCompletedValidators();
      this.updateNewSupervisionLevelValidators();
      this.updateWorkMetStandardsValidators();
      this.updateWorkMetStandardsNoReasonsValidators();
      this.updateWorkMetStandardsNoReasonsOtherValidators();

      // See if the loaded data has a current violation or activity
      // This should only happen if the loaded record is a current form and the user was in the middle of editing that dialog
      if (this.currentFormData.currentViolation) {
        this.editViolation(this.currentFormData.currentViolation);
      } else if (this.currentFormData.currentActivity) {
        this.editActivity(this.currentFormData.currentActivity);
      }
    }
  }

  /**
   * This function does validation other than form.valid before submitting or completing
   * If validation does not pass it should return false and possibly set
   * submitFormDialogErrorData.message or completeFormDialogErrorData.message
   */
  extraFormValidation(action: 'submit' | 'complete'): boolean {
    let errorMessage: string | null = null;
    let errorList: string[] = [];
    if (this.currentFormData !== null) {
      if (this.currentFormData.caseActivityViolation &&
        (this.currentFormData.activities.filter(activity => activity.violationWork === YesNoOption.Yes).length === 0 ||
          this.currentFormData.violations.length === 0)) {
        errorMessage = 'You indicated there was a violation by' +
          ' selecting "Violation" for "Which of the following case activities apply?" under the "Case Changes" tab.' +
          ' Please complete the information by:';
        if (this.currentFormData.activities.filter(activity => activity.violationWork === YesNoOption.Yes).length === 0) {
          errorList.push('Selecting "Yes" for "Violation Work?" on least one activity under the "Activities" tab.');
        }
        if (this.currentFormData.violations.length === 0) {
          errorList.push('Adding violation information with the "+ Add Violation" button under the "Case Changes" tab.');
        }
      } else if (this.currentFormData.activities.filter(activity => activity.violationWork === YesNoOption.Yes).length > 0 &&
        (!this.currentFormData.caseActivityViolation || this.currentFormData.violations.length === 0)) {
        errorMessage = 'You indicated there was a violation by' +
          ' selecting "Yes" for "Violation Work?" on least one activity under the "Activities" tab.' +
          ' Please complete the information by:';
        if (!this.currentFormData.caseActivityViolation) {
          errorList.push('Selecting "Violation" for "Which of the following case activities apply?" under the "Case Changes" tab.');
        }
        if (this.currentFormData.violations.length === 0) {
          errorList.push('Adding violation information with the "+ Add Violation" button under the "Case Changes" tab.');
        }
      }

      if (errorMessage !== null) {
        if (action === 'submit') {
          this.submitFormDialogErrorData.message = errorMessage;
          this.submitFormDialogErrorData.messageList = errorList;
        }
        if (action === 'complete') {
          this.completeFormDialogErrorData.message = errorMessage;
          this.completeFormDialogErrorData.messageList = errorList;
        }
        return false;
      }
    }
    return true;

  }

  private async loadFormTypeSettings(): Promise<void> {
    try {
      const settingsString = await this.databaseService.getFormTypeSettings(this.formTypeId);
      if (settingsString) {
        const settings = JSON.parse(settingsString);
        if (settings.activityListFilters) {
          this.activityListFilters = settings.activityListFilters;
          this.activityListFilterForm.controls.activity.setValue(this.activityListFilters.activity);
          const activitySorting = this.activityListSortOptions.filter(sorting => sorting.sortColumn === this.activityListFilters.sortColumn && sorting.sortDirection === this.activityListFilters.sortDirection);
          if (activitySorting.length > 0) {
            this.activityListFilterForm.controls.sorting.setValue(activitySorting[0]);
          } else {
            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
          }
        } else {
          this.clearActivityListFilters();
        }
      } else {
        this.clearActivityListFilters();
      }
    } catch (error) {
      console.error('Error retrieving form type settings', error);
    }
  }

  private saveFormTypeSettings(): void {
    try {
      const settings = {
        activityListFilters: this.activityListFilters
      };
      this.databaseService.saveFormTypeSettings(this.formTypeId, JSON.stringify(settings));
    } catch (error) {
      console.error('Error saving form type settings', error);
    }
  }

  private activityListFilterAndSortData(): void {
    let sortColumn = Doc2019OffenderSupervisionTimeActivityListColumn.activityDate;
    let sortDirection = 'asc';
    if (this.activityListFilterForm.controls.sorting.value) {
      const sorting: Doc2019OffenderSupervisionTimeActivityListSortOptionData = this.activityListFilterForm.controls.sorting.value;
      sortColumn = sorting.sortColumn;
      sortDirection = sorting.sortDirection;
    }

    if (this.currentFormData && this.currentFormData.activities) {
      this.activitiesDataSource = this.currentFormData.activities.filter((activity: Doc2019OffenderSupervisionTimeActivityData) => {
        if (this.activityListFilterForm.controls.activity.value && activity.activity !== this.activityListFilterForm.controls.activity.value) {
          return false;
        }
        return true;
      }).sort((activity1: Doc2019OffenderSupervisionTimeActivityData, activity2: Doc2019OffenderSupervisionTimeActivityData) => {
        switch (sortColumn) {
          case Doc2019OffenderSupervisionTimeActivityListColumn.activityDate:
            if ((activity1.activityDate || 0) > (activity2.activityDate || 0)) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.activityDate || 0) < (activity2.activityDate || 0)) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Doc2019OffenderSupervisionTimeActivityListColumn.violationWork:
            if ((activity1.violationWork || '') > (activity2.violationWork || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.violationWork || '') < (activity2.violationWork || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Doc2019OffenderSupervisionTimeActivityListColumn.activity:
            const activityDisplay1 = activity1.activity === Doc2019OffenderSupervisionTimeActivityActivityOption.Other && activity1.activityOther ? activity1.activityOther : activity1.activity;
            const activityDisplay2 = activity2.activity === Doc2019OffenderSupervisionTimeActivityActivityOption.Other && activity2.activityOther ? activity2.activityOther : activity2.activity;
            if ((activityDisplay1 || '') > (activityDisplay2 || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activityDisplay1 || '') < (activityDisplay2 || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Doc2019OffenderSupervisionTimeActivityListColumn.personInvolved:
            if ((activity1.personInvolvedDisplay || '') > (activity2.personInvolvedDisplay || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.personInvolvedDisplay || '') < (activity2.personInvolvedDisplay || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Doc2019OffenderSupervisionTimeActivityListColumn.method:
            if ((activity1.method || '') > (activity2.method || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.method || '') < (activity2.method || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Doc2019OffenderSupervisionTimeActivityListColumn.agentLocation:
            if ((activity1.agentLocation || '') > (activity2.agentLocation || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.agentLocation || '') < (activity2.agentLocation || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Doc2019OffenderSupervisionTimeActivityListColumn.activityMinutesSpent:
            if ((activity1.activityMinutesSpent || 0) > (activity2.activityMinutesSpent || 0)) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.activityMinutesSpent || 0) < (activity2.activityMinutesSpent || 0)) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Doc2019OffenderSupervisionTimeActivityListColumn.travelMinutesSpent:
            if ((activity1.travelMinutesSpent || 0) > (activity2.travelMinutesSpent || 0)) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.travelMinutesSpent || 0) < (activity2.travelMinutesSpent || 0)) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Doc2019OffenderSupervisionTimeActivityListColumn.waitingMinutesSpent:
            if ((activity1.waitingMinutesSpent || 0) > (activity2.waitingMinutesSpent || 0)) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.waitingMinutesSpent || 0) < (activity2.waitingMinutesSpent || 0)) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Doc2019OffenderSupervisionTimeActivityListColumn.notes:
            if ((activity1.notes || '') > (activity2.notes || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.notes || '') < (activity2.notes || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
        }
        // sensible defaults if the key doesn't exist, or if the initial result is a tie
        if ((activity1.activityDate || 0) < (activity2.activityDate || 0)) return -1;
        if ((activity1.activityDate || 0) > (activity2.activityDate || 0)) return 1;
        return 0;
      });
    }
    this.setActivityListFilterInfoString();
  }

  private setActivityListFilterInfoString(): void {
    const info: Array<string> = [];
    if (this.activityListFilterForm.controls.activity.value) {
      info.push('Activity Filter: ' + this.activityListFilterForm.controls.activity.value);
    }
    if (this.activityListFilterForm.controls.sorting.value) {
      info.push('Sort: ' + this.activityListFilterForm.controls.sorting.value.display);
    }

    this.activityListFilterInfoString = info.join(', ');
  }

  clearActivityListFilters(): void {
    if (this.activityListFilterForm.controls.activity.value !== null) this.activityListFilterForm.controls.activity.setValue(null);
    if (this.activityListFilterForm.controls.sorting.value !== this.activityListSortOptions[0]) this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
  }

  openActivityListFilterDialog(): void {
    const dialogData: Doc2019OffenderSupervisionTimeActivityListFilterDialogData = {
      filterForm: this.activityListFilterForm,
      clearFunction: this.clearActivityListFilters.bind(this),
      activityOptions: this.activityOptions,
      sortOptions: this.activityListSortOptions
    };
    this.dialog.open(Doc2019OffenderSupervisionTimeActivityListFilterDialogComponent, {
      data: dialogData,
      width: '800px'
    })
  }

  /**
   * update the form filter when the table headers are clicked to keep them in sync
   */
  onActivityListSortChange(sort: Sort): void {
    const sortingValue = this.activityListSortOptions.filter(sortOption => sortOption.sortColumn === sort.active && sortOption.sortDirection === (sort.direction || 'asc'));
    if (sortingValue.length > 0 && sortingValue[0] !== this.activityListFilterForm.controls.sorting.value) {
      this.activityListFilterForm.controls.sorting.setValue(sortingValue[0]);
    }
  }

  editActivity(activity: Doc2019OffenderSupervisionTimeActivityData | null): void {
    const dialogData: Doc2019OffenderSupervisionTimeActivityDialogData = {
      activity: activity,
      updateCurrentActivity: this.updateCurrentActivity.bind(this)
    };
    this.dialog.open(Doc2019OffenderSupervisionTimeActivityDialogComponent, {
      data: dialogData,
      disableClose: true,
      width: '800px'
    }).afterClosed().subscribe((result: Doc2019OffenderSupervisionTimeActivityData | null) => {
      if (this.currentFormData) {
        if (result) {
          if (result.index !== null) {
            this.currentFormData.activities[result.index] = result;
          } else {
            this.currentFormData.activities.push(result);
          }
          this.currentFormData.activities.forEach((activity, index) => activity.index = index);
          this.activityListFilterAndSortData();
        }
        this.currentFormData.currentActivity = null;
        this.saveCurrentFormValues();
      }
    });
  }

  updateCurrentActivity(activity: Doc2019OffenderSupervisionTimeActivityData): void {
    if (this.currentFormData) {
      this.currentFormData.currentActivity = activity;
      this.saveCurrentFormValues();
    }
  }

  deleteActivity(activity: Doc2019OffenderSupervisionTimeActivityData): void {
    this.dialog.open(MessageDialogComponent, {
      data: {
        title: 'Delete Activity',
        message: 'Deleting this activity is irreversible.' +
          ' Once deleted any information you have entered for the activity will be gone forever.' +
          ' Are you sure you want to do this?',
        okButtonLabel: 'Delete Activity'
      },
      width: '800px'
    }).afterClosed().subscribe((result: boolean | null) => {
      if (this.currentFormData && result && activity.index !== null) {
        this.currentFormData.activities.splice(activity.index, 1);
        this.currentFormData.activities.forEach((activity, index) => activity.index = index);
        this.activityListFilterAndSortData();
        this.saveCurrentFormValues();
      }
    });
  }

  editViolation(violation: Doc2019OffenderSupervisionTimeViolationData | null): void {
    const dialogData: Doc2019OffenderSupervisionTimeViolationDialogData = {
      violation: violation,
      updateCurrentViolation: this.updateCurrentViolation.bind(this)
    };
    this.dialog.open(Doc2019OffenderSupervisionTimeViolationDialogComponent, {
      data: dialogData,
      disableClose: true,
      width: '800px'
    }).afterClosed().subscribe((result: Doc2019OffenderSupervisionTimeViolationData | null) => {
      if (this.currentFormData) {
        if (result) {
          if (result.index !== null) {
            this.currentFormData.violations[result.index] = result;
          } else {
            this.currentFormData.violations.push(result);
          }
          this.currentFormData.violations.sort((violation1, violation2) => {
            if ((violation1.violationAddressedDate || 0) > (violation2.violationAddressedDate || 0)) return 1;
            if ((violation1.violationAddressedDate || 0) < (violation2.violationAddressedDate || 0)) return -1;
            return 0;
          }).forEach((violation, index) => violation.index = index);
          this.violationsDataSource.data = this.currentFormData.violations;
        }
        this.currentFormData.currentViolation = null;
        this.saveCurrentFormValues();
      }
    });
  }

  updateCurrentViolation(violation: Doc2019OffenderSupervisionTimeViolationData): void {
    if (this.currentFormData) {
      this.currentFormData.currentViolation = violation;
      this.saveCurrentFormValues();
    }
  }

  deleteViolation(violation: Doc2019OffenderSupervisionTimeViolationData): void {
    this.dialog.open(MessageDialogComponent, {
      data: {
        title: 'Delete Violation',
        message: 'Deleting this violation is irreversible.' +
          ' Once deleted any information you have entered for the violation will be gone forever.' +
          ' Are you sure you want to do this?',
        okButtonLabel: 'Delete Violation'
      },
      width: '800px'
    }).afterClosed().subscribe((result: boolean | null) => {
      if (this.currentFormData && result && violation.index !== null) {
        this.currentFormData.violations.splice(violation.index, 1);
        this.currentFormData.violations.forEach((violation, index) => violation.index = index);
        this.violationsDataSource.data = this.currentFormData.violations;
        this.saveCurrentFormValues();
      }
    });
  }
}
