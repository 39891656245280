import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Mddjs2020CaseSupportActivityData } from '../mddjs2020-case-support-activity-data';
import { Mddjs2020CaseSupportActivityCaseSupportActivityOption } from './mddjs2020-case-support-activity-case-support-activity-option.enum';
import { Mddjs2020CaseSupportActivityAdminActivityOption } from './mddjs2020-case-support-activity-admin-activity-option.enum';
import { Mddjs2020CaseSupportActivityDialogData } from './mddjs2020-case-support-activity-dialog-data';

@Component({
  selector: 'app-mddjs2020-case-support-activity-dialog',
  templateUrl: './mddjs2020-case-support-activity-dialog.component.html',
  styleUrls: ['./mddjs2020-case-support-activity-dialog.component.css']
})
export class Mddjs2020CaseSupportActivityDialogComponent {
  currentActivity: Mddjs2020CaseSupportActivityData;
  form = this.formBuilder.group({
    activityDate: [null, Validators.required],
    activity: [null, Validators.required],
    activityMinutes: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    activityDescription: [null, Validators.maxLength(500)]
  });

  caseSupportActivityOptions = Object.values(Mddjs2020CaseSupportActivityCaseSupportActivityOption);
  caseSupportActivityOptionEnum = Mddjs2020CaseSupportActivityCaseSupportActivityOption;
  adminActivityOptions = Object.values(Mddjs2020CaseSupportActivityAdminActivityOption);
  adminActivityOptionEnum = Mddjs2020CaseSupportActivityAdminActivityOption;

  constructor(
    private dialogRef: MatDialogRef<Mddjs2020CaseSupportActivityDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Mddjs2020CaseSupportActivityDialogData
  ) {
    if (data.activity) {
      // Need to make a copy of the object so updating it doesn't update the origin object until save is applied
      this.currentActivity = {
        index: data.activity.index,
        activityDate: data.activity.activityDate,
        activity: data.activity.activity,
        activityMinutes: data.activity.activityMinutes,
        activityDescription: data.activity.activityDescription
      };

      if (this.currentActivity.activityDate) {
        this.form.controls.activityDate.setValue(moment(this.currentActivity.activityDate, 'x')); // x = unix milliseconds
      }
      this.form.controls.activity.setValue(this.currentActivity.activity);
      this.form.controls.activityMinutes.setValue(this.currentActivity.activityMinutes);
      this.form.controls.activityDescription.setValue(this.currentActivity.activityDescription);
      this.updateActivityDescriptionValidators();
      this.form.markAllAsTouched();
    } else {
      this.currentActivity = {
        index: null,
        activityDate: null,
        activity: null,
        activityMinutes: null,
        activityDescription: null
      };
    }

    this.formChangeSubscriptions();
  }

  formChangeSubscriptions(): void {
    this.form.controls.activityDate.valueChanges.subscribe(activityDate => {
      const numberValue = activityDate !== null ? parseInt(activityDate.format('x')) : null;
      if (this.currentActivity.activityDate !== numberValue) {
        this.currentActivity.activityDate = numberValue;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activity.valueChanges.subscribe(activity => {
      if (this.currentActivity.activity !== activity) {
        this.currentActivity.activity = activity;
        this.data.updateCurrentActivity(this.currentActivity);
        this.updateActivityDescriptionValidators();
      }
    });

    this.form.controls.activityMinutes.valueChanges.subscribe(activityMinutes => {
      if (this.currentActivity.activityMinutes !== activityMinutes) {
        this.currentActivity.activityMinutes = activityMinutes;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityDescription.valueChanges.subscribe(activityDescription => {
      if (this.currentActivity.activityDescription !== activityDescription) {
        this.currentActivity.activityDescription = activityDescription;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });
  }

  /* updates the validators for activityDescription */
  updateActivityDescriptionValidators(): void {
    if (this.currentActivity &&
      (this.currentActivity.activity === Mddjs2020CaseSupportActivityCaseSupportActivityOption.Other ||
        this.currentActivity.activity === Mddjs2020CaseSupportActivityAdminActivityOption.Other)) {
      this.form.controls.activityDescription.setValidators([Validators.required, Validators.maxLength(500)]);
    } else {
      this.form.controls.activityDescription.setValidators(Validators.maxLength(500));
    }
    this.form.controls.activityDescription.updateValueAndValidity();
  }

  updateActivityList(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dialogRef.close(this.currentActivity);
    }
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
