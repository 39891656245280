export enum Humboldt2020ReferralInvestigationActivityActivityOption {
  InterviewAssessConsult = 'Interview/assess/consult',
  DocumentationReview = 'Documentation review',
  DocumentationContacts = 'Documentation (contacts)',
  ReportWriting = 'Report writing (investigative narratives, referral closure)',
  ReferralStaffingCft = 'Referral staffing/CFT',
  ArrangeServicesServiceAuthorizationPayments = 'Arrange services/service authorization/payments',
  TransportFamilyChildOrChildSupervision = 'Transport family/child or child supervision',
  Other = 'Other'
}
