import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TitleService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  title = new BehaviorSubject<string>('NCCD');

  constructor(
    private titleService: Title
  ) {
    this.title.pipe(takeUntil(this.unsubscribe$)).subscribe(title => {
      this.titleService.setTitle(title);
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
