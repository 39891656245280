import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  forgotPasswordForm = this.formBuilder.group({
    username: ['', Validators.required]
  });

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) { }

  requestChangePasswordCode(): void {
    if (this.forgotPasswordForm.valid) {
      this.authService.forgotPassword(this.forgotPasswordForm.controls.username.value);
    } else {
      this.forgotPasswordForm.markAllAsTouched();
    }
  }

  onUsernameKeydown(event) {
    if (event.key === "Enter") {
      this.requestChangePasswordCode();
    }
  }
}
