import { SharedFormData } from '../../../form/shared-form-data';
import { UserData } from '../../../auth/user-data';
import { YesNoOption } from '../../../form/yes-no-option.enum';
import { ApiSaveSurveyData } from '../../../api/api-save-survey-data';
import { ApiGetSurveyResponse } from '../../../api/api-get-survey-response';
import { isArray } from "util";
import {
  getActivityPersonInvolvedArray,
  Humboldt2020CourtIntakeActivityData
} from './humboldt2020-court-intake-activity-data';
import { Humboldt2020CourtIntakeCourtReportTypeOption } from './humboldt2020-court-intake-court-report-type-option.enum';
import { Humboldt2020CourtIntakeTribalAffiliationOption } from './humboldt2020-court-intake-tribal-affiliation-option.enum';
import { Humboldt2020CourtIntakeNumberOfChildrenOption } from './humboldt2020-court-intake-number-of-children-option.enum';

export class Humboldt2020CourtIntakeFormData extends SharedFormData {
  caseId: string;
  reportAssignmentDate: number | null;
  courtReportType: Humboldt2020CourtIntakeCourtReportTypeOption | null;
  nonDetainedCase: YesNoOption | null;
  tribalAffiliation: Humboldt2020CourtIntakeTribalAffiliationOption | null;
  tribalAffiliationTypeLocalTribe: boolean;
  tribalAffiliationTypeNonLocalTribe: boolean;
  numberOfChildrenYouthOnReport: Humboldt2020CourtIntakeNumberOfChildrenOption | null;
  removalWarrantReport: YesNoOption | null;
  comments: string | null;

  // currentActivity should only be used by the current form when an activity is open and being edited
  currentActivity: Humboldt2020CourtIntakeActivityData | null;
  activities: Humboldt2020CourtIntakeActivityData[];

  standardsMet: YesNoOption | null;
  reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet: boolean;
  reasonsStandardsNotMetCaseworkerStoppedTracking: boolean;
  reasonsStandardsNotMetNotAllWorkDuringMonthRecorded: boolean;
  reasonsStandardsNotMetOther: boolean;
  supervisorComments: string | null;

  constructor(data: any) {
    super(data);

    this.caseId = data.caseId || '';
    this.reportAssignmentDate = data.reportAssignmentDate || null;
    this.courtReportType = data.courtReportType || null;
    this.nonDetainedCase = data.nonDetainedCase || null;
    this.tribalAffiliation = data.tribalAffiliation || null;
    this.tribalAffiliationTypeLocalTribe = data.tribalAffiliationTypeLocalTribe || false;
    this.tribalAffiliationTypeNonLocalTribe = data.tribalAffiliationTypeNonLocalTribe || false;
    this.numberOfChildrenYouthOnReport = data.numberOfChildrenYouthOnReport || null;
    this.removalWarrantReport = data.removalWarrantReport || null;
    this.comments = data.comments || null;

    this.currentActivity = data.currentActivity || null;
    this.activities = data.activities || [];

    this.standardsMet = data.standardsMet || null;
    this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = data.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet || false;
    this.reasonsStandardsNotMetCaseworkerStoppedTracking = data.reasonsStandardsNotMetCaseworkerStoppedTracking || false;
    this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = data.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded || false;
    this.reasonsStandardsNotMetOther = data.reasonsStandardsNotMetOther || false;
    this.supervisorComments = data.supervisorComments || null;
  }

  /* Creates a new Humboldt2020CourtIntakeFormData class from the data */
  static createNewForm(id: number, formTypeId: number, user: UserData, timestamp: number): Humboldt2020CourtIntakeFormData {
    return new Humboldt2020CourtIntakeFormData(SharedFormData.newFormData(id, formTypeId, user, timestamp));
  }

  /* creates the object to store in the database */
  toDatabaseObject(): object {
    const databaseObject: any = {
      caseId: this.caseId,
      reportAssignmentDate: this.reportAssignmentDate,
      courtReportType: this.courtReportType,
      nonDetainedCase: this.nonDetainedCase,
      tribalAffiliation: this.tribalAffiliation,
      tribalAffiliationTypeLocalTribe: this.tribalAffiliationTypeLocalTribe,
      tribalAffiliationTypeNonLocalTribe: this.tribalAffiliationTypeNonLocalTribe,
      numberOfChildrenYouthOnReport: this.numberOfChildrenYouthOnReport,
      removalWarrantReport: this.removalWarrantReport,
      comments: this.comments,

      currentActivity: this.currentActivity,

      standardsMet: this.standardsMet,
      reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet: this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet,
      reasonsStandardsNotMetCaseworkerStoppedTracking: this.reasonsStandardsNotMetCaseworkerStoppedTracking,
      reasonsStandardsNotMetNotAllWorkDuringMonthRecorded: this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded,
      reasonsStandardsNotMetOther: this.reasonsStandardsNotMetOther,
      supervisorComments: this.supervisorComments
    };

    if (this.activities.length > 0) {
      databaseObject.activities = this.activities;
    }

    return Object.assign({}, super.toDatabaseObject(), databaseObject);
  }

  /* Creates a Humboldt2020CourtIntakeFormData class given the string stored in the database */
  static createFromDatabaseString(data: string): Humboldt2020CourtIntakeFormData {
    return new Humboldt2020CourtIntakeFormData(JSON.parse(data));
  }

  /* creates a data server object to send to the server */
  toServerObject(): ApiSaveSurveyData {
    const serverObject = super.toServerObject();

    SharedFormData.addSurveyItem(serverObject, 'CaseId', this.caseId);
    SharedFormData.addTimestampSurveyItem(serverObject, 'ReportAssignmentDate', this.reportAssignmentDate);
    SharedFormData.addSurveyItem(serverObject, 'CourtReportType', this.courtReportType);
    SharedFormData.addSurveyItem(serverObject, 'NonDetainedCase', this.nonDetainedCase);
    SharedFormData.addSurveyItem(serverObject, 'TribalAffiliation', this.tribalAffiliation);
    SharedFormData.addBooleanSurveyItem(serverObject, 'TribalAffiliationTypeLocalTribe',
      this.tribalAffiliation === Humboldt2020CourtIntakeTribalAffiliationOption.YesMaybe ? this.tribalAffiliationTypeLocalTribe : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'TribalAffiliationTypeNonLocalTribe',
      this.tribalAffiliation === Humboldt2020CourtIntakeTribalAffiliationOption.YesMaybe ? this.tribalAffiliationTypeNonLocalTribe : false);
    SharedFormData.addSurveyItem(serverObject, 'NumberOfChildrenYouthOnReport', this.numberOfChildrenYouthOnReport);
    SharedFormData.addSurveyItem(serverObject, 'RemovalWarrantReport', this.removalWarrantReport);
    SharedFormData.addSurveyItem(serverObject, 'Comments', this.comments);

    this.activities.forEach((activity, index) => {
      SharedFormData.addTimestampSurveyItem(serverObject, 'ActivityDate', activity.activityDate, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityActivity', activity.activity, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedChildYouth', activity.personInvolvedChildYouth, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedParentGuardian', activity.personInvolvedParentGuardian, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedTribe', activity.personInvolvedTribe, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedCourtPersonnel', activity.personInvolvedCourtPersonnel, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedCountyCounsel', activity.personInvolvedCountyCounsel, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedSubstituteCareProvider', activity.personInvolvedSubstituteCareProvider, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedCollateralReporter', activity.personInvolvedCollateralReporter, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedSupervisor', activity.personInvolvedSupervisor, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOtherCwsStaff', activity.personInvolvedOtherCwsStaff, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOther', activity.personInvolvedOther, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedNone', activity.personInvolvedNone, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityMethod', activity.method, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityPlace', activity.place, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityActivityMinutesSpent', activity.activityMinutesSpent, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityTravelMinutesSpent', activity.travelMinutesSpent, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityDescription', activity.activityDescription, index);
    });

    SharedFormData.addSurveyItem(serverObject, 'StandardsMet', this.standardsMet);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetCaseworkerStoppedTracking',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetCaseworkerStoppedTracking : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetNotAllWorkDuringMonthRecorded',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetOther',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetOther : false);
    SharedFormData.addSurveyItem(serverObject, 'SupervisorComments', this.supervisorComments);

    return serverObject;
  }

  /* creates this class from a data server object
  * fullRecord is false for records created from api/getlist */
  static createFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): Humboldt2020CourtIntakeFormData {
    return new Humboldt2020CourtIntakeFormData(Humboldt2020CourtIntakeFormData.createDataFromServerObject(serverObject, fullRecord));
  }

  /* creates the data object to parse into a new class instance, exposed as a separate function so child classes can use it
  * fullRecord is false for records created from api/getlist */
  static createDataFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): any {
    const data = SharedFormData.createDataFromServerObject(serverObject, fullRecord);

    if (serverObject.SurveyItems && isArray(serverObject.SurveyItems)) {
      serverObject.SurveyItems.forEach(item => {
        let itemIndex = 0;
        switch (item.ItemName) {
          case 'CaseId':
            data.caseId = item.ItemValue;
            break;
          case 'ReportAssignmentDate':
            data.reportAssignmentDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'CourtReportType':
            data.courtReportType = item.ItemValue;
            break;
          case 'NonDetainedCase':
            data.nonDetainedCase = item.ItemValue;
            break;
          case 'TribalAffiliation':
            data.tribalAffiliation = item.ItemValue;
            break;
          case 'TribalAffiliationTypeLocalTribe':
            data.tribalAffiliationTypeLocalTribe = (item.ItemValue === 'true');
            break;
          case 'TribalAffiliationTypeNonLocalTribe':
            data.tribalAffiliationTypeNonLocalTribe = (item.ItemValue === 'true');
            break;
          case 'NumberOfChildrenYouthOnReport':
            data.numberOfChildrenYouthOnReport = item.ItemValue;
            break;
          case 'RemovalWarrantReport':
            data.removalWarrantReport = item.ItemValue;
            break;
          case 'Comments':
            data.comments = item.ItemValue;
            break;

          case 'ActivityDate':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'ActivityActivity':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activity = item.ItemValue;
            break;
          case 'ActivityPersonInvolvedChildYouth':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedChildYouth = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedParentGuardian':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedParentGuardian = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedTribe':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedTribe = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedCourtPersonnel':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedCourtPersonnel = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedCountyCounsel':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedCountyCounsel = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedSubstituteCareProvider':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedSubstituteCareProvider = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedCollateralReporter':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedCollateralReporter = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedSupervisor':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedSupervisor = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOtherCwsStaff':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOtherCwsStaff = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOther':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOther = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedNone':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedNone = (item.ItemValue === 'true');
            break;
          case 'ActivityMethod':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].method = item.ItemValue;
            break;
          case 'ActivityPlace':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].place = item.ItemValue;
            break;
          case 'ActivityActivityMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityTravelMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].travelMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityDescription':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020CourtIntakeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDescription = item.ItemValue;
            break;

          case 'StandardsMet':
            data.standardsMet = item.ItemValue;
            break;
          case 'ReasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet':
            data.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetCaseworkerStoppedTracking':
            data.reasonsStandardsNotMetCaseworkerStoppedTracking = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetNotAllWorkDuringMonthRecorded':
            data.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetOther':
            data.reasonsStandardsNotMetOther = (item.ItemValue === 'true');
            break;
          case 'SupervisorComments':
            data.supervisorComments = item.ItemValue;
            break;

          default:
            console.error('Unknown item name (' + item.ItemName + ') while parsing SurveyItems');
        }
      });
    }

    if (data.activities && isArray(data.activities)) {
      data.activities.forEach(activity => {
        const personInvolvedArray = getActivityPersonInvolvedArray(activity);
        activity.personInvolvedDisplay = personInvolvedArray.length ? personInvolvedArray.join(', ') : null;
      })
    }

    return data;
  }

  /* creates empty placeholders in the activities array on data up to index */
  static setDataActivityCount(data: any, index: number) {
    if (!data.activities) data.activities = [];
    while (data.activities.length <= index) {
      const newActivity: Humboldt2020CourtIntakeActivityData = {
        index: data.activities.length,
        activityDate: null,
        activity: null,
        personInvolvedDisplay: null,
        personInvolvedChildYouth: false,
        personInvolvedParentGuardian: false,
        personInvolvedTribe: false,
        personInvolvedCourtPersonnel: false,
        personInvolvedCountyCounsel: false,
        personInvolvedSubstituteCareProvider: false,
        personInvolvedCollateralReporter: false,
        personInvolvedSupervisor: false,
        personInvolvedOtherCwsStaff: false,
        personInvolvedOther: false,
        personInvolvedNone: false,
        method: null,
        place: null,
        activityMinutesSpent: null,
        travelMinutesSpent: null,
        activityDescription: null
      };
      data.activities.push(newActivity);
    }
  }
}
