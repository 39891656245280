import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { FormComponent } from '../../../form/form.component';
import { getFormTypeById } from '../../form-type-data';
import { MatSort } from '@angular/material/sort';
import { Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { FormStatus } from '../../../form/form-status.enum';
import { YesNoOption } from '../../../form/yes-no-option.enum';
import * as moment from 'moment';
import { MessageDialogComponent } from '../../../message-dialog/message-dialog.component';
import { Mddjs2020IntakeFormData } from './mddjs2020-intake-form-data';
import { Mddjs2020IntakeActivityListColumn } from './mddjs2020-intake-activity-list-column.enum';
import { Mddjs2020IntakeActivityActivityOption } from './mddjs2020-intake-activity-dialog/mddjs2020-intake-activity-activity-option.enum';
import { Mddjs2020Region } from '../mddjs2020-region.enum';
import { Mddjs2020IntakeCaseTypeOption } from './mddjs2020-intake-case-type-option.enum';
import { Mddjs2020IntakeReasonServiceStandardNotMetOption } from './mddjs2020-intake-reason-service-standard-not-met-option.enum';
import { getOfficesForRegion } from '../mddjs2020-office';
import { Mddjs2020IntakeActivityListFilterDialogComponent } from './mddjs2020-intake-activity-list-filter-dialog/mddjs2020-intake-activity-list-filter-dialog.component';
import { Mddjs2020IntakeActivityDialogComponent } from './mddjs2020-intake-activity-dialog/mddjs2020-intake-activity-dialog.component';
export class Mddjs2020IntakeComponent extends FormComponent {
    constructor(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, titleService) {
        super(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, Mddjs2020IntakeFormData);
        this.apiService = apiService;
        this.authService = authService;
        this.breakpointObserver = breakpointObserver;
        this.databaseService = databaseService;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.onlineService = onlineService;
        this.route = route;
        this.router = router;
        this.titleService = titleService;
        this.formTypeId = 12;
        this.formType = getFormTypeById(this.formTypeId);
        this.formDataClass = Mddjs2020IntakeFormData;
        this.currentFormData = null;
        this.activitiesDataSource = [];
        this.activityListColumnEnum = Mddjs2020IntakeActivityListColumn;
        this.activityListDisplayedColumns = Object.values(Mddjs2020IntakeActivityListColumn);
        this.activityListFilterForm = this.formBuilder.group({
            activity: [null],
            sorting: [null]
        });
        this.activityListFilters = {
            activity: null,
            sortColumn: null,
            sortDirection: null
        };
        this.activityListFilterInfoString = '';
        this.activityOptions = Object.values(Mddjs2020IntakeActivityActivityOption);
        this.activityListSortOptions = [
            { sortColumn: Mddjs2020IntakeActivityListColumn.activityDate, sortDirection: 'asc', display: 'Date Ascending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.activityDate, sortDirection: 'desc', display: 'Date Descending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.activity, sortDirection: 'asc', display: 'Activity Ascending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.activity, sortDirection: 'desc', display: 'Activity Descending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.person, sortDirection: 'asc', display: 'Person Ascending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.person, sortDirection: 'desc', display: 'Person Descending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.method, sortDirection: 'asc', display: 'Method Ascending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.method, sortDirection: 'desc', display: 'Method Descending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.place, sortDirection: 'asc', display: 'Place Ascending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.place, sortDirection: 'desc', display: 'Place Descending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.activityMinutes, sortDirection: 'asc', display: 'Activity Minutes Ascending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.activityMinutes, sortDirection: 'desc', display: 'Activity Minutes Descending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.travelMinutes, sortDirection: 'asc', display: 'Travel Minutes Ascending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.travelMinutes, sortDirection: 'desc', display: 'Travel Minutes Descending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.waitingMinutes, sortDirection: 'asc', display: 'Waiting Minutes Ascending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.waitingMinutes, sortDirection: 'desc', display: 'Waiting Minutes Descending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.activityDescription, sortDirection: 'asc', display: 'Description Ascending' },
            { sortColumn: Mddjs2020IntakeActivityListColumn.activityDescription, sortDirection: 'desc', display: 'Description Descending' },
        ];
        this.form = this.formBuilder.group({
            agentName: [null],
            createdDate: [null],
            status: [null],
            statusChangedDate: [null],
            updatedDate: [null],
            caseName: [null, [Validators.required, Validators.maxLength(200)]],
            djsClientId: [null, [Validators.required, Validators.maxLength(7)]],
            region: [null, Validators.required],
            office: [null, Validators.required],
            caseType: [null, Validators.required],
            comments: [null, Validators.maxLength(500)],
            activityListFilter: this.activityListFilterForm,
            serviceStandardMet: [null],
            reasonServiceStandardNotMet: [null],
            supervisorComments: [null, Validators.maxLength(500)]
        });
        this.regionOptions = Object.values(Mddjs2020Region);
        this.officeOptions = [];
        this.caseTypeOptions = Object.values(Mddjs2020IntakeCaseTypeOption);
        this.reasonServiceStandardNotMetOptions = Object.values(Mddjs2020IntakeReasonServiceStandardNotMetOption);
        this.reasonServiceStandardNotMetOptionsEnum = Mddjs2020IntakeReasonServiceStandardNotMetOption;
        this.loadFormTypeSettings();
        this.discardChangesDialogData.message = 'If you discard changes, you will lose any changes you have made to this form since you last saved it.' +
            ' This includes any changes to case information and activities. Are you sure you want to do this?';
        this.saveFormDialogErrorData.message = 'You must provide a Sample Case Name to save the form.';
        this.submitFormDialogData.message = 'Submitting the form will send it to your supervisor for review and' +
            ' you will not longer be able to edit this form. Make sure you have recorded all the time spent working on this case during the month.';
        this.sendBackFormDialogErrorData.title = 'Send Back to CMS/CDO Error';
        this.sendBackFormDialogErrorData.message = 'You must provide a Sample Case Name to save the form.';
        this.sendBackFormDialogData.title = 'Send Back to CMS/CDO';
        this.sendBackFormDialogData.message = 'This action will send the form back to the CMS/CDO for modification.' +
            ' This will revert the status of the form to "In Progress" and you will not be able to edit the form until the CMS/CDO submits it again.' +
            ' Are you sure you want to do this?';
        this.sendBackFormDialogData.okButtonLabel = 'Send Back to CMS/CDO';
        this.completeFormDialogData.message = 'Approving the form will remove it from your queue and you will no longer be able to edit this form. Make sure:';
        this.completeFormDialogData.messageList = [
            'The form captures all the work that was completed by the CMS.',
            'You have reviewed the form for required work activities designated by standards.',
            'Time is recorded in minutes.'
        ];
    }
    set activityListSortViewChild(value) {
        this.activityListSort = value;
        // This timeout is needed or angular complains Expression has changed after it was checked.
        window.setTimeout(() => {
            // default in the current set sorting when the sorter is set
            // this happens if the width goes from isSmallDisplay to isLargeDisplay
            if (this.activityListSort && this.activityListFilterForm && this.activityListFilterForm.controls.sorting.value) {
                const sorting = this.activityListFilterForm.controls.sorting.value;
                if (this.activityListSort.active != sorting.sortColumn || this.activityListSort.direction != sorting.sortDirection) {
                    if (this.activityListSort) {
                        this.activityListSort.sort({
                            id: sorting.sortColumn,
                            start: sorting.sortDirection,
                            disableClear: true
                        });
                    }
                }
            }
        });
    }
    /* adds subscriptions for each of the form fields to update this.currentFormData */
    formChangeSubscriptions() {
        this.form.controls.caseName.valueChanges.subscribe(caseName => {
            if (this.currentFormData && this.currentFormData.name !== caseName) {
                this.currentFormData.name = caseName;
                this.saveCurrentFormValues();
            }
            if (this.formType && this.currentFormData) {
                const title = this.formType.name + ' - ' + this.currentFormData.name;
                if (this.titleService.title.value !== title) {
                    this.titleService.title.next(title);
                }
            }
        });
        this.form.controls.djsClientId.valueChanges.subscribe(djsClientId => {
            if (this.currentFormData && this.currentFormData.djsClientId !== djsClientId) {
                this.currentFormData.djsClientId = djsClientId;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.region.valueChanges.subscribe(region => {
            if (this.currentFormData && this.currentFormData.region !== region) {
                this.currentFormData.region = region;
                this.officeOptions = getOfficesForRegion(region);
                if (this.currentFormData.office !== null && this.officeOptions.indexOf(this.currentFormData.office) === -1) {
                    // if the current value of office is not in the new region's choices clear it
                    this.form.controls.office.setValue(null);
                }
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.office.valueChanges.subscribe(office => {
            if (this.currentFormData && this.currentFormData.office !== office) {
                this.currentFormData.office = office;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.caseType.valueChanges.subscribe(caseType => {
            if (this.currentFormData && this.currentFormData.caseType !== caseType) {
                this.currentFormData.caseType = caseType;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.comments.valueChanges.subscribe(comments => {
            if (this.currentFormData && this.currentFormData.comments !== comments) {
                this.currentFormData.comments = comments;
                this.saveCurrentFormValues();
            }
        });
        this.activityListFilterForm.controls.sorting.valueChanges.subscribe((sorting) => {
            if (sorting && this.activityListSort && (this.activityListSort.active !== sorting.sortColumn || (this.activityListSort.direction || 'asc') !== sorting.sortDirection)) {
                this.activityListSort.sort({
                    id: sorting.sortColumn,
                    start: sorting.sortDirection,
                    disableClear: true
                });
            }
        });
        this.activityListFilterForm.valueChanges.pipe(debounceTime(100)).subscribe(values => {
            let saveActivityListFilters = false;
            if (this.activityListFilters.activity !== values.activity) {
                this.activityListFilters.activity = values.activity;
                saveActivityListFilters = true;
            }
            if (values.sorting) {
                if (this.activityListFilters.sortColumn !== values.sorting.sortColumn || this.activityListFilters.sortDirection !== values.sorting.sortDirection) {
                    this.activityListFilters.sortColumn = values.sorting.sortColumn;
                    this.activityListFilters.sortDirection = values.sorting.sortDirection;
                    saveActivityListFilters = true;
                }
            }
            else if (this.activityListFilters.sortColumn !== null || this.activityListFilters.sortDirection !== null) {
                this.activityListFilters.sortColumn = null;
                this.activityListFilters.sortDirection = null;
                saveActivityListFilters = true;
            }
            if (saveActivityListFilters) {
                this.saveFormTypeSettings();
            }
            this.activityListFilterAndSortData();
        });
        this.form.controls.serviceStandardMet.valueChanges.subscribe(serviceStandardMet => {
            if (this.currentFormData && this.currentFormData.serviceStandardMet !== serviceStandardMet) {
                this.currentFormData.serviceStandardMet = serviceStandardMet;
                this.saveCurrentFormValues();
                this.updateReasonServiceStandardNotMetValidators();
                this.updateSupervisorCommentsValidators();
            }
        });
        this.form.controls.reasonServiceStandardNotMet.valueChanges.subscribe(reasonServiceStandardNotMet => {
            if (this.currentFormData && this.currentFormData.reasonServiceStandardNotMet !== reasonServiceStandardNotMet) {
                this.currentFormData.reasonServiceStandardNotMet = reasonServiceStandardNotMet;
                this.saveCurrentFormValues();
                this.updateSupervisorCommentsValidators();
            }
        });
        this.form.controls.supervisorComments.valueChanges.subscribe(supervisorComments => {
            if (this.currentFormData && this.currentFormData.supervisorComments !== supervisorComments) {
                this.currentFormData.supervisorComments = supervisorComments;
                this.saveCurrentFormValues();
            }
        });
    }
    /* updates the validators for serviceStandardMet */
    updateServiceStandardMetValidators() {
        if (this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
            this.form.controls.serviceStandardMet.setValidators(Validators.required);
        }
        else {
            this.form.controls.serviceStandardMet.clearValidators();
        }
        this.form.controls.serviceStandardMet.updateValueAndValidity();
    }
    /* updates the validators for reasonServiceStandardNotMet */
    updateReasonServiceStandardNotMetValidators() {
        if (this.currentFormData && this.currentFormData.status === FormStatus.InReview && this.currentFormData.serviceStandardMet === YesNoOption.No) {
            this.form.controls.reasonServiceStandardNotMet.setValidators(Validators.required);
        }
        else {
            this.form.controls.reasonServiceStandardNotMet.clearValidators();
        }
        this.form.controls.reasonServiceStandardNotMet.updateValueAndValidity();
    }
    /* updates the validators for supervisorComments */
    updateSupervisorCommentsValidators() {
        if (this.currentFormData && this.currentFormData.status === FormStatus.InReview
            && this.currentFormData.serviceStandardMet === YesNoOption.No
            && this.currentFormData.reasonServiceStandardNotMet === Mddjs2020IntakeReasonServiceStandardNotMetOption.Other) {
            this.form.controls.supervisorComments.setValidators([Validators.required, Validators.maxLength(500)]);
        }
        else {
            this.form.controls.supervisorComments.setValidators(Validators.maxLength(500));
        }
        this.form.controls.supervisorComments.updateValueAndValidity();
    }
    /* Hydrates form with currentFormData values */
    afterFormDataLoaded() {
        super.afterFormDataLoaded();
        if (this.formType && this.currentFormData) {
            const title = this.formType.name + ' - ' + this.currentFormData.name;
            if (this.titleService.title.value !== title) {
                this.titleService.title.next(title);
            }
        }
        if (this.currentFormData) {
            this.form.controls.agentName.setValue(this.currentFormData.created.userName);
            this.form.controls.createdDate.setValue(moment(this.currentFormData.created.timestamp, 'x').format('L LT'));
            this.form.controls.status.setValue(this.currentFormData.status);
            this.form.controls.statusChangedDate.setValue(moment(this.currentFormData.statusChangeTimestamp, 'x').format('L LT'));
            this.form.controls.updatedDate.setValue(moment(this.currentFormData.updated.timestamp, 'x').format('L LT'));
            this.form.controls.caseName.setValue(this.currentFormData.name);
            this.form.controls.djsClientId.setValue(this.currentFormData.djsClientId);
            if (this.currentFormData.region !== null) {
                this.officeOptions = getOfficesForRegion(this.currentFormData.region);
            }
            this.form.controls.region.setValue(this.currentFormData.region);
            this.form.controls.office.setValue(this.currentFormData.office);
            this.form.controls.caseType.setValue(this.currentFormData.caseType);
            this.form.controls.comments.setValue(this.currentFormData.comments);
            this.activityListFilterAndSortData();
            this.form.controls.serviceStandardMet.setValue(this.currentFormData.serviceStandardMet);
            this.form.controls.reasonServiceStandardNotMet.setValue(this.currentFormData.reasonServiceStandardNotMet);
            this.form.controls.supervisorComments.setValue(this.currentFormData.supervisorComments);
            this.updateServiceStandardMetValidators();
            this.updateReasonServiceStandardNotMetValidators();
            this.updateSupervisorCommentsValidators();
            // See if the loaded data has a current activity
            // This should only happen if the loaded record is a current form and the user was in the middle of editing that dialog
            if (this.currentFormData.currentActivity) {
                this.editActivity(this.currentFormData.currentActivity);
            }
        }
        this.formChangeSubscriptions();
    }
    loadFormTypeSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const settingsString = yield this.databaseService.getFormTypeSettings(this.formTypeId);
                if (settingsString) {
                    const settings = JSON.parse(settingsString);
                    if (settings.activityListFilters) {
                        this.activityListFilters = settings.activityListFilters;
                        this.activityListFilterForm.controls.activity.setValue(this.activityListFilters.activity);
                        const activitySorting = this.activityListSortOptions.filter(sorting => sorting.sortColumn === this.activityListFilters.sortColumn && sorting.sortDirection === this.activityListFilters.sortDirection);
                        if (activitySorting.length > 0) {
                            this.activityListFilterForm.controls.sorting.setValue(activitySorting[0]);
                        }
                        else {
                            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
                        }
                    }
                    else {
                        this.clearActivityListFilters();
                    }
                }
                else {
                    this.clearActivityListFilters();
                }
            }
            catch (error) {
                console.error('Error retrieving form type settings', error);
            }
        });
    }
    saveFormTypeSettings() {
        try {
            const settings = {
                activityListFilters: this.activityListFilters
            };
            this.databaseService.saveFormTypeSettings(this.formTypeId, JSON.stringify(settings));
        }
        catch (error) {
            console.error('Error saving form type settings', error);
        }
    }
    activityListFilterAndSortData() {
        let sortColumn = Mddjs2020IntakeActivityListColumn.activityDate;
        let sortDirection = 'asc';
        if (this.activityListFilterForm.controls.sorting.value) {
            const sorting = this.activityListFilterForm.controls.sorting.value;
            sortColumn = sorting.sortColumn;
            sortDirection = sorting.sortDirection;
        }
        if (this.currentFormData && this.currentFormData.activities) {
            this.activitiesDataSource = this.currentFormData.activities.filter((activity) => {
                return !(this.activityListFilterForm.controls.activity.value && activity.activity !== this.activityListFilterForm.controls.activity.value);
            }).sort((activity1, activity2) => {
                switch (sortColumn) {
                    case Mddjs2020IntakeActivityListColumn.activityDate:
                        if ((activity1.activityDate || 0) > (activity2.activityDate || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityDate || 0) < (activity2.activityDate || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020IntakeActivityListColumn.activity:
                        if ((activity1.activity || '') > (activity2.activity || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activity || '') < (activity2.activity || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020IntakeActivityListColumn.person:
                        if ((activity1.personDisplay || '') > (activity2.personDisplay || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.personDisplay || '') < (activity2.personDisplay || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020IntakeActivityListColumn.method:
                        if ((activity1.method || '') > (activity2.method || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.method || '') < (activity2.method || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020IntakeActivityListColumn.place:
                        if ((activity1.place || '') > (activity2.place || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.place || '') < (activity2.place || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020IntakeActivityListColumn.activityMinutes:
                        if ((activity1.activityMinutes || 0) > (activity2.activityMinutes || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityMinutes || 0) < (activity2.activityMinutes || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020IntakeActivityListColumn.travelMinutes:
                        if ((activity1.travelMinutes || 0) > (activity2.travelMinutes || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.travelMinutes || 0) < (activity2.travelMinutes || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020IntakeActivityListColumn.waitingMinutes:
                        if ((activity1.waitingMinutes || 0) > (activity2.waitingMinutes || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.waitingMinutes || 0) < (activity2.waitingMinutes || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020IntakeActivityListColumn.activityDescription:
                        if ((activity1.activityDescription || '') > (activity2.activityDescription || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityDescription || '') < (activity2.activityDescription || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                }
                // sensible defaults if the key doesn't exist, or if the initial result is a tie
                if ((activity1.activityDate || 0) < (activity2.activityDate || 0))
                    return -1;
                if ((activity1.activityDate || 0) > (activity2.activityDate || 0))
                    return 1;
                return 0;
            });
        }
        this.setActivityListFilterInfoString();
    }
    setActivityListFilterInfoString() {
        const info = [];
        if (this.activityListFilterForm.controls.activity.value) {
            info.push('Activity Filter: ' + this.activityListFilterForm.controls.activity.value);
        }
        if (this.activityListFilterForm.controls.sorting.value) {
            info.push('Sort: ' + this.activityListFilterForm.controls.sorting.value.display);
        }
        this.activityListFilterInfoString = info.join(', ');
    }
    clearActivityListFilters() {
        if (this.activityListFilterForm.controls.activity.value !== null)
            this.activityListFilterForm.controls.activity.setValue(null);
        if (this.activityListFilterForm.controls.sorting.value !== this.activityListSortOptions[0])
            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
    }
    openActivityListFilterDialog() {
        const dialogData = {
            filterForm: this.activityListFilterForm,
            clearFunction: this.clearActivityListFilters.bind(this),
            activityOptions: this.activityOptions,
            sortOptions: this.activityListSortOptions
        };
        this.dialog.open(Mddjs2020IntakeActivityListFilterDialogComponent, {
            data: dialogData,
            width: '800px'
        });
    }
    /**
     * update the form filter when the table headers are clicked to keep them in sync
     */
    onActivityListSortChange(sort) {
        const sortingValue = this.activityListSortOptions.filter(sortOption => sortOption.sortColumn === sort.active && sortOption.sortDirection === (sort.direction || 'asc'));
        if (sortingValue.length > 0 && sortingValue[0] !== this.activityListFilterForm.controls.sorting.value) {
            this.activityListFilterForm.controls.sorting.setValue(sortingValue[0]);
        }
    }
    editActivity(activity) {
        const dialogData = {
            activity: activity,
            updateCurrentActivity: this.updateCurrentActivity.bind(this)
        };
        this.dialog.open(Mddjs2020IntakeActivityDialogComponent, {
            data: dialogData,
            disableClose: true,
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (this.currentFormData) {
                if (result) {
                    if (result.index !== null) {
                        this.currentFormData.activities[result.index] = result;
                    }
                    else {
                        this.currentFormData.activities.push(result);
                    }
                    this.currentFormData.activities.forEach((activity, index) => activity.index = index);
                    this.activityListFilterAndSortData();
                }
                this.currentFormData.currentActivity = null;
                this.saveCurrentFormValues();
            }
        });
    }
    updateCurrentActivity(activity) {
        if (this.currentFormData) {
            this.currentFormData.currentActivity = activity;
            this.saveCurrentFormValues();
        }
    }
    deleteActivity(activity) {
        this.dialog.open(MessageDialogComponent, {
            data: {
                title: 'Delete Activity',
                message: 'Deleting this activity is irreversible.' +
                    ' Once deleted any information you have entered for the activity will be gone forever.' +
                    ' Are you sure you want to do this?',
                okButtonLabel: 'Delete Activity'
            },
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (this.currentFormData && result && activity.index !== null) {
                this.currentFormData.activities.splice(activity.index, 1);
                this.currentFormData.activities.forEach((activity, index) => activity.index = index);
                this.activityListFilterAndSortData();
                this.saveCurrentFormValues();
            }
        });
    }
}
