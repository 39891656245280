import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { controlErrorMessage } from '../../../../utility-functions';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Doc2019OffenderSupervisionTimeActivityData, getActivityPersonInvolvedArray } from '../doc2019-offender-supervision-time-activity-data';
import * as moment from 'moment';
import { Doc2019OffenderSupervisionTimeActivityDialogData } from './doc2019-offender-supervision-time-activity-dialog-data';
import { Doc2019OffenderSupervisionTimeActivityPersonInvolvedOption } from './doc2019-offender-supervision-time-activity-person-involved-option.enum';
import { YesNoOption } from '../../../../form/yes-no-option.enum';
import { isArray } from "util";
import { Doc2019OffenderSupervisionTimeActivityActivityOption } from './doc2019-offender-supervision-time-activity-activity-option.enum';
import { maxItemsValidator, noneValidator } from '../../../../validators';
import { Doc2019OffenderSupervisionTimeActivityAgentLocationOption } from './doc2019-offender-supervision-time-activity-agent-location-option.enum';
import { Doc2019OffenderSupervisionTimeActivityMethodOption } from './doc2019-offender-supervision-time-activity-method-option.enum';

@Component({
  selector: 'app-doc2019-offender-supervision-time-activity-dialog',
  templateUrl: './doc2019-offender-supervision-time-activity-dialog.component.html',
  styleUrls: ['./doc2019-offender-supervision-time-activity-dialog.component.css']
})
export class Doc2019OffenderSupervisionTimeActivityDialogComponent {
  currentActivity: Doc2019OffenderSupervisionTimeActivityData;
  form = this.formBuilder.group({
    activityDate: [null, Validators.required],
    violationWork: [null, Validators.required],
    activity: [null, Validators.required],
    activityOther: [''],
    personInvolved: [[], [Validators.required, maxItemsValidator(3), noneValidator]],
    method: [null, Validators.required],
    agentLocation: [null, Validators.required],
    activityMinutesSpent: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    travelMinutesSpent: [null, [Validators.min(0), Validators.max(1440)]],
    waitingMinutesSpent: [null, [Validators.min(0), Validators.max(1440)]],
    notes: ['']
  });
  errorMessage = controlErrorMessage;

  yesNoOptions = Object.values(YesNoOption);

  minActivityDate = moment('20191230', 'YYYYMMDD');
  maxActivityDate = moment('20200330', 'YYYYMMDD');

  activityOptionEnum = Doc2019OffenderSupervisionTimeActivityActivityOption;
  activityOptions = Object.values(Doc2019OffenderSupervisionTimeActivityActivityOption);

  personInvolvedOptions = Object.values(Doc2019OffenderSupervisionTimeActivityPersonInvolvedOption);

  methodOptions = Object.values(Doc2019OffenderSupervisionTimeActivityMethodOption);

  agentLocationOptions = Object.values(Doc2019OffenderSupervisionTimeActivityAgentLocationOption);

  constructor(
    private dialogRef: MatDialogRef<Doc2019OffenderSupervisionTimeActivityDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Doc2019OffenderSupervisionTimeActivityDialogData
  ) {
    if (data.activity) {
      // Need to make a copy of the object so updating it doesn't update the origin object until save is applied
      this.currentActivity = {
        index: data.activity.index,
        activityDate: data.activity.activityDate,
        violationWork: data.activity.violationWork,
        activity: data.activity.activity,
        activityOther: data.activity.activityOther,
        personInvolvedDisplay: data.activity.personInvolvedDisplay,
        personInvolvedOffender: data.activity.personInvolvedOffender,
        personInvolvedCollateral: data.activity.personInvolvedCollateral,
        personInvolvedUnitSupervisor: data.activity.personInvolvedUnitSupervisor,
        personInvolvedOtherDocStaff: data.activity.personInvolvedOtherDocStaff,
        personInvolvedVictim: data.activity.personInvolvedVictim,
        personInvolvedOther: data.activity.personInvolvedOther,
        personInvolvedNone: data.activity.personInvolvedNone,
        method: data.activity.method,
        agentLocation: data.activity.agentLocation,
        activityMinutesSpent: data.activity.activityMinutesSpent,
        travelMinutesSpent: data.activity.travelMinutesSpent,
        waitingMinutesSpent: data.activity.waitingMinutesSpent,
        notes: data.activity.notes,
      };

      if (this.currentActivity.activityDate) {
        this.form.controls.activityDate.setValue(moment(this.currentActivity.activityDate, 'x')); // x = unix milliseconds
      }
      this.form.controls.violationWork.setValue(this.currentActivity.violationWork);
      this.form.controls.activity.setValue(this.currentActivity.activity);
      this.form.controls.activityOther.setValue(this.currentActivity.activityOther);
      this.form.controls.personInvolved.setValue(getActivityPersonInvolvedArray(this.currentActivity));
      this.form.controls.method.setValue(this.currentActivity.method);
      this.form.controls.agentLocation.setValue(this.currentActivity.agentLocation);
      this.form.controls.activityMinutesSpent.setValue(this.currentActivity.activityMinutesSpent);
      this.form.controls.travelMinutesSpent.setValue(this.currentActivity.travelMinutesSpent);
      this.form.controls.waitingMinutesSpent.setValue(this.currentActivity.waitingMinutesSpent);
      this.form.controls.notes.setValue(this.currentActivity.notes);
      this.updateTravelMinutesSpentValidators();
      this.form.markAllAsTouched();
    } else {
      this.currentActivity = {
        index: null,
        activityDate: null,
        violationWork: null,
        activity: null,
        activityOther: null,
        personInvolvedDisplay: null,
        personInvolvedOffender: false,
        personInvolvedCollateral: false,
        personInvolvedUnitSupervisor: false,
        personInvolvedOtherDocStaff: false,
        personInvolvedVictim: false,
        personInvolvedOther: false,
        personInvolvedNone: false,
        method: null,
        agentLocation: null,
        activityMinutesSpent: null,
        travelMinutesSpent: null,
        waitingMinutesSpent: null,
        notes: null,
      };
    }

    this.formChangeSubscriptions();
  }

  formChangeSubscriptions(): void {
    this.form.controls.activityDate.valueChanges.subscribe(activityDate => {
      const numberValue = activityDate !== null ? activityDate.format('x') : null;
      if (this.currentActivity.activityDate !== numberValue) {
        this.currentActivity.activityDate = numberValue;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.violationWork.valueChanges.subscribe(violationWork => {
      if (this.currentActivity.violationWork !== violationWork) {
        this.currentActivity.violationWork = violationWork;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activity.valueChanges.subscribe(activity => {
      if (this.currentActivity.activity !== activity) {
        this.currentActivity.activity = activity;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityOther.valueChanges.subscribe(activityOther => {
      if (this.currentActivity.activityOther !== activityOther) {
        this.currentActivity.activityOther = activityOther;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.personInvolved.valueChanges.subscribe(personInvolved => {
      let personInvolvedOffender = false;
      let personInvolvedCollateral = false;
      let personInvolvedUnitSupervisor = false;
      let personInvolvedOtherDocStaff = false;
      let personInvolvedVictim = false;
      let personInvolvedOther = false;
      let personInvolvedNone = false;

      if (isArray(personInvolved)) {
        (personInvolved as string[]).forEach(personInvolved => {
          switch (personInvolved) {
            case Doc2019OffenderSupervisionTimeActivityPersonInvolvedOption.Offender:
              personInvolvedOffender = true;
              break;
            case Doc2019OffenderSupervisionTimeActivityPersonInvolvedOption.Collateral:
              personInvolvedCollateral = true;
              break;
            case Doc2019OffenderSupervisionTimeActivityPersonInvolvedOption.UnitSupervisor:
              personInvolvedUnitSupervisor = true;
              break;
            case Doc2019OffenderSupervisionTimeActivityPersonInvolvedOption.OtherDocStaff:
              personInvolvedOtherDocStaff = true;
              break;
            case Doc2019OffenderSupervisionTimeActivityPersonInvolvedOption.Victim:
              personInvolvedVictim = true;
              break;
            case Doc2019OffenderSupervisionTimeActivityPersonInvolvedOption.Other:
              personInvolvedOther = true;
              break;
            case Doc2019OffenderSupervisionTimeActivityPersonInvolvedOption.None:
              personInvolvedNone = true;
              break;
          }
        })
      }

      let saveCurrentFormValues = false;
      if (this.currentActivity && this.currentActivity.personInvolvedOffender !== personInvolvedOffender) {
        this.currentActivity.personInvolvedOffender = personInvolvedOffender;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedCollateral !== personInvolvedCollateral) {
        this.currentActivity.personInvolvedCollateral = personInvolvedCollateral;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedUnitSupervisor !== personInvolvedUnitSupervisor) {
        this.currentActivity.personInvolvedUnitSupervisor = personInvolvedUnitSupervisor;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedOtherDocStaff !== personInvolvedOtherDocStaff) {
        this.currentActivity.personInvolvedOtherDocStaff = personInvolvedOtherDocStaff;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedVictim !== personInvolvedVictim) {
        this.currentActivity.personInvolvedVictim = personInvolvedVictim;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedOther !== personInvolvedOther) {
        this.currentActivity.personInvolvedOther = personInvolvedOther;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedNone !== personInvolvedNone) {
        this.currentActivity.personInvolvedNone = personInvolvedNone;
        saveCurrentFormValues = true;
      }
      const personInvolvedArray = getActivityPersonInvolvedArray(this.currentActivity);
      const personInvolvedDisplay = personInvolvedArray.length ? personInvolvedArray.join(', ') : null;
      if (this.currentActivity && this.currentActivity.personInvolvedDisplay !== personInvolvedDisplay) {
        this.currentActivity.personInvolvedDisplay = personInvolvedDisplay;
        saveCurrentFormValues = true;
      }
      if (saveCurrentFormValues) {
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.method.valueChanges.subscribe(method => {
      if (this.currentActivity.method !== method) {
        this.currentActivity.method = method;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.agentLocation.valueChanges.subscribe(agentLocation => {
      if (this.currentActivity.agentLocation !== agentLocation) {
        this.currentActivity.agentLocation = agentLocation;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityMinutesSpent.valueChanges.subscribe(activityMinutesSpent => {
      if (this.currentActivity.activityMinutesSpent !== activityMinutesSpent) {
        this.currentActivity.activityMinutesSpent = activityMinutesSpent;
        this.data.updateCurrentActivity(this.currentActivity);
        this.updateTravelMinutesSpentValidators();
      }
    });

    this.form.controls.travelMinutesSpent.valueChanges.subscribe(travelMinutesSpent => {
      if (this.currentActivity.travelMinutesSpent !== travelMinutesSpent) {
        this.currentActivity.travelMinutesSpent = travelMinutesSpent;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.waitingMinutesSpent.valueChanges.subscribe(waitingMinutesSpent => {
      if (this.currentActivity.waitingMinutesSpent !== waitingMinutesSpent) {
        this.currentActivity.waitingMinutesSpent = waitingMinutesSpent;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.notes.valueChanges.subscribe(notes => {
      if (this.currentActivity.notes !== notes) {
        this.currentActivity.notes = notes;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });
  }

  /* updates the validators for newSupervisionLevel */
  updateTravelMinutesSpentValidators(): void {
    if (this.currentActivity && this.currentActivity.activityMinutesSpent === 0) {
      this.form.controls.travelMinutesSpent.setValidators([Validators.required, Validators.min(0), Validators.max(1440)]);
    } else {
      this.form.controls.travelMinutesSpent.setValidators([Validators.min(0), Validators.max(1440)]);
    }
    this.form.controls.travelMinutesSpent.updateValueAndValidity();
  }

  updateActivityList(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dialogRef.close(this.currentActivity);
    }
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
