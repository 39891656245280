export enum Doc2019OffenderSupervisionTimeActivityListColumn {
  action = 'action',
  activityDate = 'activityDate',
  violationWork = 'violationWork',
  activity = 'activity',
  personInvolved = 'personInvolved',
  method = 'method',
  agentLocation = 'agentLocation',
  activityMinutesSpent = 'activityMinutesSpent',
  travelMinutesSpent = 'travelMinutesSpent',
  waitingMinutesSpent = 'waitingMinutesSpent',
  notes = 'notes'
}
