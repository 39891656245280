const prodHostname = 'workload.nccdglobal.org';
const hostname = location.hostname || null;
const isProduction = (hostname === prodHostname || hostname === null); // Checking null if the browser doesn't support location.hostname
const authUrl = isProduction ? 'https://auth.nccdglobal.org' : 'https://qaauth.nccdglobal.org';
const appUrl = isProduction ? 'https://wasp.nccdglobal.org' : 'https://qaappwasp.nccdglobal.org';

export const environment = {
  production: isProduction,
  apiTokenUrl: authUrl + '/api/token',
  apiTokenAuthToken: 'Basic 5829613325d4b6a1608a2507fec08a25',
  apiUserInfoUrl: authUrl + '/api/userinfo',
  apiChangePasswordUrl: authUrl + '/api/password/change',
  apiForgotPasswordUrl: authUrl + '/api/password/forget',
  apiResetPasswordUrl: authUrl + '/api/password/reset',
  apiGetList: appUrl + '/api/getlist',
  apiGetListSupervisor: appUrl + '/api/getlistsupervisor',
  apiGetListAdmin: appUrl + '/api/getlistadmin/',
  apiGetSurvey: appUrl + '/api/getsurvey/',
  apiSaveSurvey: appUrl + '/api/savesurvey'
};
