import { Doc2019CaseSupportTimeFormData } from './doc2019/doc2019-case-support-time/doc2019-case-support-time-form-data';
import { Doc2019FieldInvestigationTimeFormData } from './doc2019/doc2019-field-investigation-time/doc2019-field-investigation-time-form-data';
import { Doc2019OffenderSupervisionTimeFormData } from './doc2019/doc2019-offender-supervision-time/doc2019-offender-supervision-time-form-data';
import { Humboldt2020CourtIntakeFormData } from './humboldt2020/humboldt2020-court-intake/humboldt2020-court-intake-form-data';
import { Humboldt2020IntakeScreeningFormData } from './humboldt2020/humboldt2020-intake-screening/humboldt2020-intake-screening-form-data';
import { Humboldt2020ReferralInvestigationFormData } from './humboldt2020/humboldt2020-referral-investigation/humboldt2020-referral-investigation-form-data';
import { Humboldt2020OngoingEfcAdoptFormData } from './humboldt2020/humboldt2020-ongoing-efc-adopt/humboldt2020-ongoing-efc-adopt-form-data';
import { Humboldt2020PlacementFormData } from './humboldt2020/humboldt2020-placement/humboldt2020-placement-form-data';
import { Humboldt2020ResourceFamilyApprovalFormData } from './humboldt2020/humboldt2020-resource-family-approval/humboldt2020-resource-family-approval-form-data';
import { Humboldt2020SupportAdminFormData } from './humboldt2020/humboldt2020-support-admin/humboldt2020-support-admin-form-data';
import { Humboldt2020IlsFormData } from './humboldt2020/humboldt2020-ils/humboldt2020-ils-form-data';
import { Mddjs2020IntakeFormData } from './mddjs2020/mddjs2020-intake/mddjs2020-intake-form-data';
import { Mddjs2020SupervisionFormData } from './mddjs2020/mddjs2020-supervision/mddjs2020-supervision-form-data';
import { Mddjs2020CaseSupportFormData } from './mddjs2020/mddjs2020-case-support/mddjs2020-case-support-form-data';
import { Mddjs2020CdEmFormData } from './mddjs2020/mddjs2020-cd-em/mddjs2020-cd-em-form-data';
import { Mddjs2020CdFloatingFormData } from './mddjs2020/mddjs2020-cd-floating/mddjs2020-cd-floating-form-data';

export function getDataClassForFormType(formTypeId: number): object {
  switch (formTypeId) {
    case 1:
      return Doc2019CaseSupportTimeFormData;
    case 2:
      return Doc2019FieldInvestigationTimeFormData;
    case 3:
      return Doc2019OffenderSupervisionTimeFormData;
    case 4:
      return Humboldt2020CourtIntakeFormData;
    case 5:
      return Humboldt2020IntakeScreeningFormData;
    case 6:
      return Humboldt2020ReferralInvestigationFormData;
    case 7:
      return Humboldt2020OngoingEfcAdoptFormData;
    case 8:
      return Humboldt2020PlacementFormData;
    case 9:
      return Humboldt2020ResourceFamilyApprovalFormData;
    case 10:
      return Humboldt2020SupportAdminFormData;
    case 11:
      return Humboldt2020IlsFormData;
    case 12:
      return Mddjs2020IntakeFormData;
    case 13:
      return Mddjs2020SupervisionFormData;
    case 14:
      return Mddjs2020CaseSupportFormData;
    case 15:
      return Mddjs2020CdEmFormData;
    case 16:
      return Mddjs2020CdFloatingFormData;
    default:
      throw('Case Type not found in getDataClassForFormType');
  }
}
