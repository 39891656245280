import { Component } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { passwordValidator } from '../validators';

function passwordNotChanged(formGroup: FormGroup): {[key: string]: any} | null {
  const currentPasswordControl: AbstractControl | null = formGroup.get('currentPassword');
  const newPasswordControl: AbstractControl | null = formGroup.get('newPassword');

  if (currentPasswordControl === null || newPasswordControl === null) return null;

  if (currentPasswordControl.value === '') return null;

  if (currentPasswordControl.value === newPasswordControl.value) {
    return {'passwordNotChanged': true};
  }

  return null;
}

/* checks that otherControl's value matches the specified control */
function newPasswordsNoMatch(formGroup: FormGroup): {[key: string]: any} | null {
  const newPasswordControl: AbstractControl | null = formGroup.get('newPassword');
  const retypePasswordControl: AbstractControl | null = formGroup.get('retypePassword');

  if (newPasswordControl === null || retypePasswordControl === null) return null;

  if (newPasswordControl.value !== retypePasswordControl.value) {
    return {'newPasswordsNoMatch': true};
  }

  return null;
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  changePasswordForm = this.formBuilder.group({
    currentPassword: [this.authService.tempPasswordHolder || '', Validators.required],
    newPassword: ['', [Validators.required, passwordValidator]],
    retypePassword: ['', [Validators.required]]
  }, {
    validators: [passwordNotChanged, newPasswordsNoMatch]
  });
  passwordContainsPlus = false;

  constructor(
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private location: Location
  ) {
    this.changePasswordForm.controls.currentPassword.valueChanges.subscribe(password => {
      this.passwordContainsPlus = (typeof password === 'string' && password.indexOf('+') !== -1);
    });
  }

  changePassword(): void {
    if (this.changePasswordForm.valid) {
      this.authService.changePassword(this.changePasswordForm.controls.currentPassword.value, this.changePasswordForm.controls.newPassword.value)
      .then((success) => {
        if (success) {
          this.location.back();
        }
      });
    } else {
      this.changePasswordForm.markAllAsTouched();
    }
  }

  cancel(): void {
    this.location.back();
  }

  onNewPasswordKeydown(event) {
    if (event.key === "Enter") {
      this.changePassword();
    }
  }
}
