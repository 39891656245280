/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./processing-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i4 from "@angular/material/progress-spinner";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/common";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "./processing-dialog.component";
var styles_ProcessingDialogComponent = [i0.styles];
var RenderType_ProcessingDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProcessingDialogComponent, data: {} });
export { RenderType_ProcessingDialogComponent as RenderType_ProcessingDialogComponent };
function View_ProcessingDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); }); }
function View_ProcessingDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-spinner", [["class", "mat-progress-spinner"], ["color", "primary"], ["diameter", "30"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i3.View_MatProgressSpinner_0, i3.RenderType_MatProgressSpinner)), i1.ɵdid(1, 114688, null, 0, i4.MatProgressSpinner, [i1.ElementRef, i5.Platform, [2, i6.DOCUMENT], [2, i7.ANIMATION_MODULE_TYPE], i4.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"], mode: [2, "mode"] }, null)], function (_ck, _v) { var currVal_7 = "primary"; var currVal_8 = "30"; var currVal_9 = "indeterminate"; _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; var currVal_3 = ((i1.ɵnov(_v, 1).mode === "determinate") ? 0 : null); var currVal_4 = ((i1.ɵnov(_v, 1).mode === "determinate") ? 100 : null); var currVal_5 = ((i1.ɵnov(_v, 1).mode === "determinate") ? i1.ɵnov(_v, 1).value : null); var currVal_6 = i1.ɵnov(_v, 1).mode; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ProcessingDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.additionalButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""])), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.data.additionalButtonText)); _ck(_v, 2, 0, currVal_2); }); }
function View_ProcessingDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(3, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(4, 0, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProcessingDialogComponent_4)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.data.additionalButtonText)); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.data.errorCloseButtonLabel; _ck(_v, 4, 0, currVal_2); }); }
export function View_ProcessingDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessingDialogComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessingDialogComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessingDialogComponent_3)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.errored; _ck(_v, 5, 0, currVal_1); var currVal_3 = _co.errored; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.message; _ck(_v, 7, 0, currVal_2); }); }
export function View_ProcessingDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-processing-dialog", [], null, null, null, View_ProcessingDialogComponent_0, RenderType_ProcessingDialogComponent)), i1.ɵdid(1, 180224, null, 0, i11.ProcessingDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var ProcessingDialogComponentNgFactory = i1.ɵccf("app-processing-dialog", i11.ProcessingDialogComponent, View_ProcessingDialogComponent_Host_0, {}, {}, []);
export { ProcessingDialogComponentNgFactory as ProcessingDialogComponentNgFactory };
