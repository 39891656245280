import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormComponent } from '../../../form/form.component';
import { getFormTypeById } from '../../form-type-data';
import { MatSort, Sort } from '@angular/material/sort';
import { FormBuilder, Validators } from '@angular/forms';
import { Mddjs2020Region } from '../mddjs2020-region.enum';
import { ApiService } from '../../../api/api.service';
import { AuthService } from '../../../auth/auth.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DatabaseService } from '../../../database/database.service';
import { MatDialog } from '@angular/material/dialog';
import { OnlineService } from '../../../online/online.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from '../../../title/title.service';
import { debounceTime } from 'rxjs/operators';
import { FormStatus } from '../../../form/form-status.enum';
import { YesNoOption } from '../../../form/yes-no-option.enum';
import * as moment from 'moment';
import { MessageDialogComponent } from '../../../message-dialog/message-dialog.component';
import { Mddjs2020CdEmActivityListSortOptionData } from './mddjs2020-cd-em-activity-list-sort-option-data';
import { Mddjs2020CdEmReasonServiceStandardNotMetOption } from './mddjs2020-cd-em-reason-service-standard-not-met-option.enum';
import { Mddjs2020CdEmActivityDialogData } from './mddjs2020-cd-em-activity-dialog/mddjs2020-cd-em-activity-dialog-data';
import { Mddjs2020CdEmActivityListColumn } from './mddjs2020-cd-em-activity-list-column.enum';
import { Mddjs2020CdEmActivityDialogComponent } from './mddjs2020-cd-em-activity-dialog/mddjs2020-cd-em-activity-dialog.component';
import { Mddjs2020CdEmActivityActivityOption } from './mddjs2020-cd-em-activity-dialog/mddjs2020-cd-em-activity-activity-option.enum';
import { Mddjs2020CdEmFormData } from './mddjs2020-cd-em-form-data';
import { Mddjs2020CdEmActivityListFilterDialogComponent } from './mddjs2020-cd-em-activity-list-filter-dialog/mddjs2020-cd-em-activity-list-filter-dialog.component';
import { Mddjs2020CdEmActivityListFilterData } from './mddjs2020-cd-em-activity-list-filter-data';
import { Mddjs2020CdEmActivityListFilterDialogData } from './mddjs2020-cd-em-activity-list-filter-dialog/mddjs2020-cd-em-activity-list-filter-dialog-data';
import { Mddjs2020CdEmActivityData } from './mddjs2020-cd-em-activity-data';
import { Mddjs2020CdEmCaseTypeOption } from './mddjs2020-cd-em-case-type-option.enum';
import { getCdEmOfficesForRegion } from '../mddjs2020-office';

@Component({
  selector: 'app-mddjs2020-cd-em',
  templateUrl: './mddjs2020-cd-em.component.html',
  styleUrls: ['./mddjs2020-cd-em.component.css']
})
export class Mddjs2020CdEmComponent extends FormComponent implements OnDestroy {
  formTypeId = 15;
  formType = getFormTypeById(this.formTypeId);
  formDataClass = Mddjs2020CdEmFormData;
  currentFormData: Mddjs2020CdEmFormData | null = null;

  activitiesDataSource: Mddjs2020CdEmActivityData[] = [];
  activityListColumnEnum = Mddjs2020CdEmActivityListColumn;
  activityListDisplayedColumns = Object.values(Mddjs2020CdEmActivityListColumn);
  activityListFilterForm = this.formBuilder.group({
    activity: [null],
    sorting: [null]
  });
  activityListFilters: Mddjs2020CdEmActivityListFilterData = {
    activity: null,
    sortColumn: null,
    sortDirection: null
  };
  activityListFilterInfoString = '';
  activityOptions = Object.values(Mddjs2020CdEmActivityActivityOption);
  activityListSortOptions: Mddjs2020CdEmActivityListSortOptionData[] = [
    {sortColumn: Mddjs2020CdEmActivityListColumn.activityDate, sortDirection: 'asc', display: 'Date Ascending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.activityDate, sortDirection: 'desc', display: 'Date Descending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.activity, sortDirection: 'asc', display: 'Activity Ascending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.activity, sortDirection: 'desc', display: 'Activity Descending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.person, sortDirection: 'asc', display: 'Person Ascending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.person, sortDirection: 'desc', display: 'Person Descending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.method, sortDirection: 'asc', display: 'Method Ascending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.method, sortDirection: 'desc', display: 'Method Descending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.place, sortDirection: 'asc', display: 'Place Ascending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.place, sortDirection: 'desc', display: 'Place Descending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.activityMinutes, sortDirection: 'asc', display: 'Activity Minutes Ascending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.activityMinutes, sortDirection: 'desc', display: 'Activity Minutes Descending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.travelMinutes, sortDirection: 'asc', display: 'Travel Minutes Ascending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.travelMinutes, sortDirection: 'desc', display: 'Travel Minutes Descending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.waitingMinutes, sortDirection: 'asc', display: 'Waiting Minutes Ascending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.waitingMinutes, sortDirection: 'desc', display: 'Waiting Minutes Descending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.activityDescription, sortDirection: 'asc', display: 'Description Ascending'},
    {sortColumn: Mddjs2020CdEmActivityListColumn.activityDescription, sortDirection: 'desc', display: 'Description Descending'},
  ];
  activityListSort: MatSort;
  @ViewChild('activityTable', {read: MatSort, static: false}) set activityListSortViewChild(value: MatSort) {
    this.activityListSort = value;
    // This timeout is needed or angular complains Expression has changed after it was checked.
    window.setTimeout(() => {
      // default in the current set sorting when the sorter is set
      // this happens if the width goes from isSmallDisplay to isLargeDisplay
      if (this.activityListSort && this.activityListFilterForm && this.activityListFilterForm.controls.sorting.value) {
        const sorting: Mddjs2020CdEmActivityListSortOptionData = this.activityListFilterForm.controls.sorting.value;
        if (this.activityListSort.active != sorting.sortColumn || this.activityListSort.direction != sorting.sortDirection) {
          if (this.activityListSort) {
            this.activityListSort.sort({
              id: sorting.sortColumn,
              start: sorting.sortDirection,
              disableClear: true
            });
          }
        }
      }
    });
  }

  form = this.formBuilder.group({
    agentName: [null],
    createdDate: [null],
    status: [null],
    statusChangedDate: [null],
    updatedDate: [null],
    caseName: [null, [Validators.required, Validators.maxLength(200)]],
    djsClientId: [null, [Validators.required, Validators.maxLength(7)]],
    region: [null, Validators.required],
    office: [null, Validators.required],
    caseType: [null, Validators.required],
    youthHasHomePhone: [null, Validators.required],
    comments: [null, Validators.maxLength(500)],
    activityListFilter: this.activityListFilterForm,
    serviceStandardMet: [null],
    reasonServiceStandardNotMet: [null],
    supervisorComments: [null, Validators.maxLength(500)]
  });

  regionOptions = Object.values(Mddjs2020Region);
  officeOptions: string[] = [];
  caseTypeOptions = Object.values(Mddjs2020CdEmCaseTypeOption);
  reasonServiceStandardNotMetOptions = Object.values(Mddjs2020CdEmReasonServiceStandardNotMetOption);
  reasonServiceStandardNotMetOptionsEnum = Mddjs2020CdEmReasonServiceStandardNotMetOption;

  constructor(
    public apiService: ApiService,
    public authService: AuthService,
    public breakpointObserver: BreakpointObserver,
    public databaseService: DatabaseService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    public onlineService: OnlineService,
    public route: ActivatedRoute,
    public router: Router,
    public titleService: TitleService
  ) {
    super(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, Mddjs2020CdEmFormData);
    this.loadFormTypeSettings();

    this.discardChangesDialogData.message = 'If you discard changes, you will lose any changes you have made to this form since you last saved it.' +
      ' This includes any changes to case information and activities. Are you sure you want to do this?';

    this.saveFormDialogErrorData.message = 'You must provide a Sample Case Name to save the form.';

    this.submitFormDialogData.message = 'Submitting the form will send it to your supervisor for review and' +
      ' you will not longer be able to edit this form. Make sure you have recorded all the time spent working on this case during the month.';

    this.sendBackFormDialogErrorData.title = 'Send Back to CMS/CDO Error';
    this.sendBackFormDialogErrorData.message = 'You must provide a Sample Case Name to save the form.';

    this.sendBackFormDialogData.title = 'Send Back to CMS/CDO';
    this.sendBackFormDialogData.message = 'This action will send the form back to the CMS/CDO for modification.' +
      ' This will revert the status of the form to "In Progress" and you will not be able to edit the form until the CMS/CDO submits it again.' +
      ' Are you sure you want to do this?';
    this.sendBackFormDialogData.okButtonLabel = 'Send Back to CMS/CDO';

    this.completeFormDialogData.message = 'Approving the form will remove it from your queue and you will no longer be able to edit this form. Make sure:';
    this.completeFormDialogData.messageList = [
      'The form captures all the work that was completed by the CDO.',
      'You have reviewed the form for required work activities designated by standards.',
      'Time is recorded in minutes.'
    ];
  }

  /* adds subscriptions for each of the form fields to update this.currentFormData */
  formChangeSubscriptions(): void {
    this.form.controls.caseName.valueChanges.subscribe(caseName => {
      if (this.currentFormData && this.currentFormData.name !== caseName) {
        this.currentFormData.name = caseName;
        this.saveCurrentFormValues();
      }
      if (this.formType && this.currentFormData) {
        const title = this.formType.name + ' - ' + this.currentFormData.name;
        if (this.titleService.title.value !== title) {
          this.titleService.title.next(title);
        }
      }
    });

    this.form.controls.djsClientId.valueChanges.subscribe(djsClientId => {
      if (this.currentFormData && this.currentFormData.djsClientId !== djsClientId) {
        this.currentFormData.djsClientId = djsClientId;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.region.valueChanges.subscribe(region => {
      if (this.currentFormData && this.currentFormData.region !== region) {
        this.currentFormData.region = region;
        this.officeOptions = getCdEmOfficesForRegion(region);
        if (this.currentFormData.office !== null && this.officeOptions.indexOf(this.currentFormData.office) === -1) {
          // if the current value of office is not in the new region's choices clear it
          this.form.controls.office.setValue(null);
        }
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.office.valueChanges.subscribe(office => {
      if (this.currentFormData && this.currentFormData.office !== office) {
        this.currentFormData.office = office;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.caseType.valueChanges.subscribe(caseType => {
      if (this.currentFormData && this.currentFormData.caseType !== caseType) {
        this.currentFormData.caseType = caseType;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.youthHasHomePhone.valueChanges.subscribe(youthHasHomePhone => {
      if (this.currentFormData && this.currentFormData.youthHasHomePhone !== youthHasHomePhone) {
        this.currentFormData.youthHasHomePhone = youthHasHomePhone;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.comments.valueChanges.subscribe(comments => {
      if (this.currentFormData && this.currentFormData.comments !== comments) {
        this.currentFormData.comments = comments;
        this.saveCurrentFormValues();
      }
    });

    this.activityListFilterForm.controls.sorting.valueChanges.subscribe((sorting: Mddjs2020CdEmActivityListSortOptionData | null ) => {
      if (sorting && this.activityListSort && (this.activityListSort.active !== sorting.sortColumn || (this.activityListSort.direction || 'asc') !== sorting.sortDirection)) {
        this.activityListSort.sort({
          id: sorting.sortColumn,
          start: sorting.sortDirection,
          disableClear: true
        });
      }
    });

    this.activityListFilterForm.valueChanges.pipe(debounceTime(100)).subscribe(values => {
      let saveActivityListFilters = false;
      if (this.activityListFilters.activity !== values.activity) {
        this.activityListFilters.activity = values.activity;
        saveActivityListFilters = true;
      }

      if (values.sorting) {
        if (this.activityListFilters.sortColumn !== values.sorting.sortColumn || this.activityListFilters.sortDirection !== values.sorting.sortDirection) {
          this.activityListFilters.sortColumn = values.sorting.sortColumn;
          this.activityListFilters.sortDirection = values.sorting.sortDirection;
          saveActivityListFilters = true;
        }
      } else if (this.activityListFilters.sortColumn !== null || this.activityListFilters.sortDirection !== null) {
        this.activityListFilters.sortColumn = null;
        this.activityListFilters.sortDirection = null;
        saveActivityListFilters = true;
      }

      if (saveActivityListFilters) {
        this.saveFormTypeSettings();
      }

      this.activityListFilterAndSortData();
    });

    this.form.controls.serviceStandardMet.valueChanges.subscribe(serviceStandardMet => {
      if (this.currentFormData && this.currentFormData.serviceStandardMet !== serviceStandardMet) {
        this.currentFormData.serviceStandardMet = serviceStandardMet;
        this.saveCurrentFormValues();
        this.updateReasonServiceStandardNotMetValidators();
        this.updateSupervisorCommentsValidators();
      }
    });

    this.form.controls.reasonServiceStandardNotMet.valueChanges.subscribe(reasonServiceStandardNotMet => {
      if (this.currentFormData && this.currentFormData.reasonServiceStandardNotMet !== reasonServiceStandardNotMet) {
        this.currentFormData.reasonServiceStandardNotMet = reasonServiceStandardNotMet;
        this.saveCurrentFormValues();
        this.updateSupervisorCommentsValidators();
      }
    });

    this.form.controls.supervisorComments.valueChanges.subscribe(supervisorComments => {
      if (this.currentFormData && this.currentFormData.supervisorComments !== supervisorComments) {
        this.currentFormData.supervisorComments = supervisorComments;
        this.saveCurrentFormValues();
      }
    });
  }

  /* updates the validators for serviceStandardMet */
  updateServiceStandardMetValidators(): void {
    if (this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
      this.form.controls.serviceStandardMet.setValidators(Validators.required);
    } else {
      this.form.controls.serviceStandardMet.clearValidators();
    }
    this.form.controls.serviceStandardMet.updateValueAndValidity();
  }

  /* updates the validators for reasonServiceStandardNotMet */
  updateReasonServiceStandardNotMetValidators(): void {
    if (this.currentFormData && this.currentFormData.status === FormStatus.InReview && this.currentFormData.serviceStandardMet === YesNoOption.No) {
      this.form.controls.reasonServiceStandardNotMet.setValidators(Validators.required);
    } else {
      this.form.controls.reasonServiceStandardNotMet.clearValidators();
    }
    this.form.controls.reasonServiceStandardNotMet.updateValueAndValidity();
  }

  /* updates the validators for supervisorComments */
  updateSupervisorCommentsValidators(): void {
    if (this.currentFormData && this.currentFormData.status === FormStatus.InReview
      && this.currentFormData.serviceStandardMet === YesNoOption.No
      && this.currentFormData.reasonServiceStandardNotMet === Mddjs2020CdEmReasonServiceStandardNotMetOption.Other) {
      this.form.controls.supervisorComments.setValidators([Validators.required, Validators.maxLength(500)]);
    } else {
      this.form.controls.supervisorComments.setValidators(Validators.maxLength(500));
    }
    this.form.controls.supervisorComments.updateValueAndValidity();
  }

  /* Hydrates form with currentFormData values */
  afterFormDataLoaded(): void {
    super.afterFormDataLoaded();
    if (this.formType && this.currentFormData) {
      const title = this.formType.name + ' - ' + this.currentFormData.name;
      if (this.titleService.title.value !== title) {
        this.titleService.title.next(title);
      }
    }
    if (this.currentFormData) {
      this.form.controls.agentName.setValue(this.currentFormData.created.userName);
      this.form.controls.createdDate.setValue(moment(this.currentFormData.created.timestamp, 'x').format('L LT'));
      this.form.controls.status.setValue(this.currentFormData.status);
      this.form.controls.statusChangedDate.setValue(moment(this.currentFormData.statusChangeTimestamp, 'x').format('L LT'));
      this.form.controls.updatedDate.setValue(moment(this.currentFormData.updated.timestamp, 'x').format('L LT'));
      this.form.controls.caseName.setValue(this.currentFormData.name);
      this.form.controls.djsClientId.setValue(this.currentFormData.djsClientId);
      if (this.currentFormData.region !== null) {
        this.officeOptions = getCdEmOfficesForRegion(this.currentFormData.region);
      }
      this.form.controls.region.setValue(this.currentFormData.region);
      this.form.controls.office.setValue(this.currentFormData.office);
      this.form.controls.caseType.setValue(this.currentFormData.caseType);
      this.form.controls.youthHasHomePhone.setValue(this.currentFormData.youthHasHomePhone);
      this.form.controls.comments.setValue(this.currentFormData.comments);

      this.activityListFilterAndSortData();

      this.form.controls.serviceStandardMet.setValue(this.currentFormData.serviceStandardMet);
      this.form.controls.reasonServiceStandardNotMet.setValue(this.currentFormData.reasonServiceStandardNotMet);
      this.form.controls.supervisorComments.setValue(this.currentFormData.supervisorComments);

      this.updateServiceStandardMetValidators();
      this.updateReasonServiceStandardNotMetValidators();
      this.updateSupervisorCommentsValidators();

      // See if the loaded data has a current activity
      // This should only happen if the loaded record is a current form and the user was in the middle of editing that dialog
      if (this.currentFormData.currentActivity) {
        this.editActivity(this.currentFormData.currentActivity);
      }
    }
    this.formChangeSubscriptions();
  }

  private async loadFormTypeSettings(): Promise<void> {
    try {
      const settingsString = await this.databaseService.getFormTypeSettings(this.formTypeId);
      if (settingsString) {
        const settings = JSON.parse(settingsString);
        if (settings.activityListFilters) {
          this.activityListFilters = settings.activityListFilters;
          this.activityListFilterForm.controls.activity.setValue(this.activityListFilters.activity);
          const activitySorting = this.activityListSortOptions.filter(sorting => sorting.sortColumn === this.activityListFilters.sortColumn && sorting.sortDirection === this.activityListFilters.sortDirection);
          if (activitySorting.length > 0) {
            this.activityListFilterForm.controls.sorting.setValue(activitySorting[0]);
          } else {
            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
          }
        } else {
          this.clearActivityListFilters();
        }
      } else {
        this.clearActivityListFilters();
      }
    } catch (error) {
      console.error('Error retrieving form type settings', error);
    }
  }

  private saveFormTypeSettings(): void {
    try {
      const settings = {
        activityListFilters: this.activityListFilters
      };
      this.databaseService.saveFormTypeSettings(this.formTypeId, JSON.stringify(settings));
    } catch (error) {
      console.error('Error saving form type settings', error);
    }
  }

  private activityListFilterAndSortData(): void {
    let sortColumn = Mddjs2020CdEmActivityListColumn.activityDate;
    let sortDirection = 'asc';
    if (this.activityListFilterForm.controls.sorting.value) {
      const sorting: Mddjs2020CdEmActivityListSortOptionData = this.activityListFilterForm.controls.sorting.value;
      sortColumn = sorting.sortColumn;
      sortDirection = sorting.sortDirection;
    }

    if (this.currentFormData && this.currentFormData.activities) {
      this.activitiesDataSource = this.currentFormData.activities.filter((activity: Mddjs2020CdEmActivityData) => {
        return !(this.activityListFilterForm.controls.activity.value && activity.activity !== this.activityListFilterForm.controls.activity.value);
      }).sort((activity1: Mddjs2020CdEmActivityData, activity2: Mddjs2020CdEmActivityData) => {
        switch (sortColumn) {
          case Mddjs2020CdEmActivityListColumn.activityDate:
            if ((activity1.activityDate || 0) > (activity2.activityDate || 0)) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.activityDate || 0) < (activity2.activityDate || 0)) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Mddjs2020CdEmActivityListColumn.activity:
            if ((activity1.activity || '') > (activity2.activity || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.activity || '') < (activity2.activity || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Mddjs2020CdEmActivityListColumn.person:
            if ((activity1.personDisplay || '') > (activity2.personDisplay || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.personDisplay || '') < (activity2.personDisplay || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Mddjs2020CdEmActivityListColumn.method:
            if ((activity1.method || '') > (activity2.method || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.method || '') < (activity2.method || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Mddjs2020CdEmActivityListColumn.place:
            if ((activity1.place || '') > (activity2.place || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.place || '') < (activity2.place || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Mddjs2020CdEmActivityListColumn.activityMinutes:
            if ((activity1.activityMinutes || 0) > (activity2.activityMinutes || 0)) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.activityMinutes || 0) < (activity2.activityMinutes || 0)) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Mddjs2020CdEmActivityListColumn.travelMinutes:
            if ((activity1.travelMinutes || 0) > (activity2.travelMinutes || 0)) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.travelMinutes || 0) < (activity2.travelMinutes || 0)) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Mddjs2020CdEmActivityListColumn.waitingMinutes:
            if ((activity1.waitingMinutes || 0) > (activity2.waitingMinutes || 0)) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.waitingMinutes || 0) < (activity2.waitingMinutes || 0)) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Mddjs2020CdEmActivityListColumn.activityDescription:
            if ((activity1.activityDescription || '') > (activity2.activityDescription || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.activityDescription || '') < (activity2.activityDescription || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
        }
        // sensible defaults if the key doesn't exist, or if the initial result is a tie
        if ((activity1.activityDate || 0) < (activity2.activityDate || 0)) return -1;
        if ((activity1.activityDate || 0) > (activity2.activityDate || 0)) return 1;
        return 0;
      });
    }
    this.setActivityListFilterInfoString();
  }

  private setActivityListFilterInfoString(): void {
    const info: Array<string> = [];
    if (this.activityListFilterForm.controls.activity.value) {
      info.push('Activity Filter: ' + this.activityListFilterForm.controls.activity.value);
    }
    if (this.activityListFilterForm.controls.sorting.value) {
      info.push('Sort: ' + this.activityListFilterForm.controls.sorting.value.display);
    }

    this.activityListFilterInfoString = info.join(', ');
  }

  clearActivityListFilters(): void {
    if (this.activityListFilterForm.controls.activity.value !== null) this.activityListFilterForm.controls.activity.setValue(null);
    if (this.activityListFilterForm.controls.sorting.value !== this.activityListSortOptions[0]) this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
  }

  openActivityListFilterDialog(): void {
    const dialogData: Mddjs2020CdEmActivityListFilterDialogData = {
      filterForm: this.activityListFilterForm,
      clearFunction: this.clearActivityListFilters.bind(this),
      activityOptions: this.activityOptions,
      sortOptions: this.activityListSortOptions
    };
    this.dialog.open(Mddjs2020CdEmActivityListFilterDialogComponent, {
      data: dialogData,
      width: '800px'
    });
  }

  /**
   * update the form filter when the table headers are clicked to keep them in sync
   */
  onActivityListSortChange(sort: Sort): void {
    const sortingValue = this.activityListSortOptions.filter(sortOption => sortOption.sortColumn === sort.active && sortOption.sortDirection === (sort.direction || 'asc'));
    if (sortingValue.length > 0 && sortingValue[0] !== this.activityListFilterForm.controls.sorting.value) {
      this.activityListFilterForm.controls.sorting.setValue(sortingValue[0]);
    }
  }

  editActivity(activity: Mddjs2020CdEmActivityData | null): void {
    const dialogData: Mddjs2020CdEmActivityDialogData = {
      activity: activity,
      updateCurrentActivity: this.updateCurrentActivity.bind(this)
    };
    this.dialog.open(Mddjs2020CdEmActivityDialogComponent, {
      data: dialogData,
      disableClose: true,
      width: '800px'
    }).afterClosed().subscribe((result: Mddjs2020CdEmActivityData | null) => {
      if (this.currentFormData) {
        if (result) {
          if (result.index !== null) {
            this.currentFormData.activities[result.index] = result;
          } else {
            this.currentFormData.activities.push(result);
          }
          this.currentFormData.activities.forEach((activity, index) => activity.index = index);
          this.activityListFilterAndSortData();
        }
        this.currentFormData.currentActivity = null;
        this.saveCurrentFormValues();
      }
    });
  }

  updateCurrentActivity(activity: Mddjs2020CdEmActivityData): void {
    if (this.currentFormData) {
      this.currentFormData.currentActivity = activity;
      this.saveCurrentFormValues();
    }
  }

  deleteActivity(activity: Mddjs2020CdEmActivityData): void {
    this.dialog.open(MessageDialogComponent, {
      data: {
        title: 'Delete Activity',
        message: 'Deleting this activity is irreversible.' +
          ' Once deleted any information you have entered for the activity will be gone forever.' +
          ' Are you sure you want to do this?',
        okButtonLabel: 'Delete Activity'
      },
      width: '800px'
    }).afterClosed().subscribe((result: boolean | null) => {
      if (this.currentFormData && result && activity.index !== null) {
        this.currentFormData.activities.splice(activity.index, 1);
        this.currentFormData.activities.forEach((activity, index) => activity.index = index);
        this.activityListFilterAndSortData();
        this.saveCurrentFormValues();
      }
    });
  }
}
