<h1 mat-dialog-title>Violation</h1>
<div mat-dialog-content>
  <form class="filter-form" [formGroup]="form">
    <mat-form-field>
      <mat-label>Date violation addressed</mat-label>
      <input matInput [min]="minViolationAddressedDate" [max]="maxViolationAddressedDate" [matDatepicker]="violationAddressedDatePicker" formControlName="violationAddressedDate">
      <mat-datepicker-toggle matSuffix [for]="violationAddressedDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #violationAddressedDatePicker></mat-datepicker>
      <mat-error *ngIf="form.controls.violationAddressedDate.invalid">{{errorMessage(form.controls.violationAddressedDate)}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Custody violation?</mat-label>
      <mat-select formControlName="custodyViolation">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let option of yesNoOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls.custodyViolation.invalid">{{errorMessage(form.controls.custodyViolation)}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Date decision made to not revoke or date 414 served</mat-label>
      <input matInput [min]="minDecisionDate" [max]="maxDecisionDate" [matDatepicker]="decisionDatePicker" formControlName="decisionDate">
      <mat-datepicker-toggle matSuffix [for]="decisionDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #decisionDatePicker></mat-datepicker>
      <mat-error *ngIf="form.controls.decisionDate.invalid">{{errorMessage(form.controls.decisionDate)}}</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="updateViolationList()">Update</button>
</div>
