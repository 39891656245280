import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Humboldt2020SupportAdminActivitySupportActivityOption } from './humboldt2020-support-admin-activity-support-activity-option.enum';
import { Humboldt2020SupportAdminActivityAdminActivityOption } from './humboldt2020-support-admin-activity-admin-activity-option.enum';
import { Humboldt2020SupportAdminCwsProgramSupportOption } from './humboldt2020-support-admin-cws-program-support-option.enum';
import { Humboldt2020SupportAdminActivityData } from '../humboldt2020-support-admin-activity-data';
import { Humboldt2020SupportAdminActivityDialogData } from './humboldt2020-support-admin-activity-dialog-data';
import { Humboldt2020SupportAdminPrimaryProgramResponsibilityOption } from '../humboldt2020-support-admin-primary-program-responsibility-option.enum';

@Component({
  selector: 'app-humboldt2020-support-admin-activity-dialog',
  templateUrl: './humboldt2020-support-admin-activity-dialog.component.html',
  styleUrls: ['./humboldt2020-support-admin-activity-dialog.component.css']
})
export class Humboldt2020SupportAdminActivityDialogComponent {
  currentActivity: Humboldt2020SupportAdminActivityData;
  form = this.formBuilder.group({
    activityDate: [null, Validators.required],
    activity: [null, Validators.required],
    cwsProgramSupport: [null],
    activityMinutesSpent: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    travelMinutesSpent: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    activityDescription: [null, Validators.maxLength(500)]
  });

  supportActivityOptions = Object.values(Humboldt2020SupportAdminActivitySupportActivityOption);
  adminActivityOptions = Object.values(Humboldt2020SupportAdminActivityAdminActivityOption);
  cwsProgramSupportOptions = Object.values(Humboldt2020SupportAdminCwsProgramSupportOption);

  showCwsProgramSupportQuestion = false;
  activityDescriptionRequired = false;

  constructor(
    private dialogRef: MatDialogRef<Humboldt2020SupportAdminActivityDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Humboldt2020SupportAdminActivityDialogData
  ) {
    // If primaryProgramResponsibility is specified, remove the options only allowed for that program
    if (data.primaryProgramResponsibility !== Humboldt2020SupportAdminPrimaryProgramResponsibilityOption.CourtIntake) {
      this.supportActivityOptions = this.supportActivityOptions.filter((value) => value !== Humboldt2020SupportAdminActivitySupportActivityOption.NonRemovalWarrantsOtherReports);
    }
    if (data.primaryProgramResponsibility !== Humboldt2020SupportAdminPrimaryProgramResponsibilityOption.Rfa) {
      this.supportActivityOptions = this.supportActivityOptions.filter((value) => value !== Humboldt2020SupportAdminActivitySupportActivityOption.RfaFollowUpCaseManagement);
    }
    if (data.primaryProgramResponsibility !== Humboldt2020SupportAdminPrimaryProgramResponsibilityOption.Ils) {
      this.adminActivityOptions = this.adminActivityOptions.filter((value) => {
        return value !== Humboldt2020SupportAdminActivityAdminActivityOption.Outreach &&
          value !== Humboldt2020SupportAdminActivityAdminActivityOption.WorkshopsAndEventsCoordinationPlanning &&
          value !== Humboldt2020SupportAdminActivityAdminActivityOption.WorkshopAndEventDelivery &&
          value !== Humboldt2020SupportAdminActivityAdminActivityOption.CalendarNewsletter;
      });
    }

    if (data.activity) {
      // Need to make a copy of the object so updating it doesn't update the origin object until save is applied
      this.currentActivity = {
        index: data.activity.index,
        activityDate: data.activity.activityDate,
        activity: data.activity.activity,
        cwsProgramSupport: data.activity.cwsProgramSupport,
        activityMinutesSpent: data.activity.activityMinutesSpent,
        travelMinutesSpent: data.activity.travelMinutesSpent,
        activityDescription: data.activity.activityDescription
      };

      if (this.currentActivity.activityDate) {
        this.form.controls.activityDate.setValue(moment(this.currentActivity.activityDate, 'x')); // x = unix milliseconds
      }
      this.form.controls.activity.setValue(this.currentActivity.activity);
      this.form.controls.cwsProgramSupport.setValue(this.currentActivity.cwsProgramSupport);
      this.form.controls.activityMinutesSpent.setValue(this.currentActivity.activityMinutesSpent);
      this.form.controls.travelMinutesSpent.setValue(this.currentActivity.travelMinutesSpent);
      this.form.controls.activityDescription.setValue(this.currentActivity.activityDescription);
      this.updateCwsProgramSupportValidators();
      this.updateActivityDescriptionValidators();
      this.form.markAllAsTouched();
    } else {
      this.currentActivity = {
        index: null,
        activityDate: null,
        activity: null,
        cwsProgramSupport: null,
        activityMinutesSpent: null,
        travelMinutesSpent: null,
        activityDescription: null
      };
    }

    this.formChangeSubscriptions();
  }

  formChangeSubscriptions(): void {
    this.form.controls.activityDate.valueChanges.subscribe(activityDate => {
      const numberValue = activityDate !== null ? parseInt(activityDate.format('x')) : null;
      if (this.currentActivity.activityDate !== numberValue) {
        this.currentActivity.activityDate = numberValue;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activity.valueChanges.subscribe(activity => {
      if (this.currentActivity.activity !== activity) {
        this.currentActivity.activity = activity;
        this.updateCwsProgramSupportValidators();
        this.updateActivityDescriptionValidators();
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.cwsProgramSupport.valueChanges.subscribe(cwsProgramSupport => {
      if (this.currentActivity.cwsProgramSupport !== cwsProgramSupport) {
        this.currentActivity.cwsProgramSupport = cwsProgramSupport;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityMinutesSpent.valueChanges.subscribe(activityMinutesSpent => {
      if (this.currentActivity.activityMinutesSpent !== activityMinutesSpent) {
        this.currentActivity.activityMinutesSpent = activityMinutesSpent;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.travelMinutesSpent.valueChanges.subscribe(travelMinutesSpent => {
      if (this.currentActivity.travelMinutesSpent !== travelMinutesSpent) {
        this.currentActivity.travelMinutesSpent = travelMinutesSpent;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityDescription.valueChanges.subscribe(activityDescription => {
      if (this.currentActivity.activityDescription !== activityDescription) {
        this.currentActivity.activityDescription = activityDescription;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });
  }

  /* updates the validators for cwsProgramSupport */
  updateCwsProgramSupportValidators(): void {
    if (this.currentActivity &&
      (this.currentActivity.activity === Humboldt2020SupportAdminActivitySupportActivityOption.CwsSupportSubstituteCoverage ||
        this.currentActivity.activity === Humboldt2020SupportAdminActivitySupportActivityOption.NonRemovalWarrantsOtherReports)) {
      this.form.controls.cwsProgramSupport.setValidators(Validators.required);
      this.showCwsProgramSupportQuestion = true;
    } else {
      this.form.controls.cwsProgramSupport.clearValidators();
      this.showCwsProgramSupportQuestion = false;
      this.form.controls.cwsProgramSupport.setValue(null); // Normal we don't clear this in case the user flips back, but in this case we don't want it showing in the table on the form
    }
    this.form.controls.cwsProgramSupport.updateValueAndValidity();
  }

  /* updates the validators for activityDescription */
  updateActivityDescriptionValidators(): void {
    if (this.currentActivity &&
      (this.currentActivity.activity === Humboldt2020SupportAdminActivitySupportActivityOption.OtherCaseSupport ||
        this.currentActivity.activity === Humboldt2020SupportAdminActivityAdminActivityOption.OtherNonCaseRelatedAdministrativeActivity)) {
      this.form.controls.activityDescription.setValidators([Validators.required, Validators.maxLength(500)]);
      this.activityDescriptionRequired = true;
    } else {
      this.form.controls.activityDescription.setValidators(Validators.maxLength(500));
      this.activityDescriptionRequired = false;
    }
    this.form.controls.activityDescription.updateValueAndValidity();
  }

  updateActivityList(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dialogRef.close(this.currentActivity);
    }
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
