import { Humboldt2020IlsActivityActivityOption } from './humboldt2020-ils-activity-dialog/humboldt2020-ils-activity-activity-option.enum';
import { Humboldt2020IlsActivityMethodOption } from './humboldt2020-ils-activity-dialog/humboldt2020-ils-activity-method-option.enum';
import { Humboldt2020IlsActivityPlaceOption } from './humboldt2020-ils-activity-dialog/humboldt2020-ils-activity-place-option.enum';
import { Humboldt2020IlsActivityPersonInvolvedOption } from './humboldt2020-ils-activity-dialog/humboldt2020-ils-activity-person-involved-option.enum';

export interface Humboldt2020IlsActivityData {
  index: number | null; // Used to store the index when passing data to the dialog
  activityDate: number | null; // unix timestamp in milliseconds
  activity: Humboldt2020IlsActivityActivityOption | null;
  personInvolvedDisplay: string | null;
  personInvolvedYouth: boolean;
  personInvolvedTribe: boolean;
  personInvolvedOtherCwsTayStaff: boolean;
  personInvolvedSupervisor: boolean;
  personInvolvedOtherServiceProvider: boolean;
  personInvolvedOther: boolean;
  personInvolvedNone: boolean;
  method: Humboldt2020IlsActivityMethodOption | null;
  place: Humboldt2020IlsActivityPlaceOption | null;
  activityMinutesSpent: number | null;
  travelMinutesSpent: number | null;
  activityDescription: string | null;
}

export function getActivityPersonInvolvedArray(activity: Humboldt2020IlsActivityData): string[] {
  const values: Array<string> = [];
  if (activity.personInvolvedYouth) {
    values.push(Humboldt2020IlsActivityPersonInvolvedOption.Youth);
  }
  if (activity.personInvolvedTribe) {
    values.push(Humboldt2020IlsActivityPersonInvolvedOption.Tribe);
  }
  if (activity.personInvolvedOtherCwsTayStaff) {
    values.push(Humboldt2020IlsActivityPersonInvolvedOption.OtherCwsTayStaff);
  }
  if (activity.personInvolvedSupervisor) {
    values.push(Humboldt2020IlsActivityPersonInvolvedOption.Supervisor);
  }
  if (activity.personInvolvedOtherServiceProvider) {
    values.push(Humboldt2020IlsActivityPersonInvolvedOption.OtherServiceProvider);
  }
  if (activity.personInvolvedOther) {
    values.push(Humboldt2020IlsActivityPersonInvolvedOption.Other);
  }
  if (activity.personInvolvedNone) {
    values.push(Humboldt2020IlsActivityPersonInvolvedOption.None);
  }
  return values;
}
