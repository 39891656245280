export enum Mddjs2020CdEmActivityPlaceOption {
  Office = 'Office',
  FamilyResidence = 'Family residence',
  School = 'School',
  ProgramPlacement = 'Program/placement',
  Court = 'Court',
  Detention = 'Detention',
  Cdovehicle = 'CDO\'s vehicle',
  Other = 'Other'
}
