import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  loginForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });
  passwordContainsPlus = false;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {
    this.loginForm.controls.password.valueChanges.subscribe(password => {
      this.passwordContainsPlus = (typeof password === 'string' && password.indexOf('+') !== -1);
    });
  }

  login(): void {
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.controls.username.value, this.loginForm.controls.password.value);
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  onPasswordKeydown(event) {
    if (event.key === "Enter") {
      this.login();
    }
  }
}
