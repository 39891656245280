export enum Doc2019OffenderSupervisionTimeActivityActivityOption {
  AppointmentPreparation = 'Appointment preparation',
  SupervisonContact = 'Supervison contact',
  InterviewingFactFindingStaffing = 'Interviewing/fact-finding/staffing',
  DocumentionCompasCaseFileWorkReportWriting = 'Documention/COMPAS/case file work/report writing',
  Referral = 'Referral',
  Ua = 'UA',
  CustodyTransportOffender = 'Custody/transport offender',
  MonitoringEquipmentWork = 'Monitoring equipment work',
  Other = 'Other'
}
