<h1 mat-dialog-title>Version Conflict</h1>
<div mat-dialog-content>
  <p>
    You have an offline save at {{ data.localFormData.offlineSaveTimestamp | date:'short' }} for form {{ data.localFormData.name }}.
    There is a version on the server updated at {{ data.serverFormData.updated.timestamp | date:'short' }}.
    Saving your changes will overwrite any changes on the server version.
    What would you like to do?
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close(null)">Cancel</button>
  <button mat-button (click)="close('discardChanges')">Discard My Changes</button>
  <button mat-button (click)="close('saveChanges')">Save My Changes</button>
</div>
