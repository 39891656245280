import { BreakpointObserver } from '@angular/cdk/layout';
import { map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserData } from '../auth/user-data';
import { DatabaseService } from '../database/database.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material';
import { MessageDialogData } from '../message-dialog/message-dialog-data';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedFormData } from './shared-form-data';
import { ProcessingDialogData } from '../processing-dialog/processing-dialog-data';
import { ProcessingDialogComponent } from '../processing-dialog/processing-dialog.component';
import { getCurrentMillisecondTimestampRoundedToNearestSecond } from '../utility-functions';
import { FormStatus } from './form-status.enum';
import { YesNoOption } from './yes-no-option.enum';
import { ApiSaveSurveyResponse } from '../api/api-save-survey-response';
import { ApiGetSurveyResponse } from '../api/api-get-survey-response';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';
import { OnlineService } from '../online/online.service';
import { FormTypeData } from '../project/form-type-data';
import { UserRole } from '../auth/user-role.enum';

/* This class is not intended to be used directly, but extended for various forms */
export class FormComponent {
  unsubscribe$ = new Subject<void>();
  user: UserData;
  UserRole = UserRole;

  isSmallDisplay = false; // If true the display will show a list interface
  isLargeDisplay = false; // If true the display will show a table interface

  formId: string | null = null;
  formStatusEnum = FormStatus;
  formTypeId: number;
  formType: FormTypeData | null;
  formDataClass = SharedFormData;
  currentFormData: SharedFormData | null = null;
  form: FormGroup;
  readonly = true;
  displaySupervisorFields = false;
  hasUnsavedChanges = false;
  unsavedChangesFunction: (event) => {};

  yesNoOptionsEnum = YesNoOption;
  yesNoOptions = Object.values(YesNoOption);

  cancelOfflineSaveStatus: string | null = null;
  cancelOfflineSaveTimestamp: number | null = null;

  constructor(
    public apiService: ApiService,
    public authService: AuthService,
    public breakpointObserver: BreakpointObserver,
    public databaseService: DatabaseService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    public onlineService: OnlineService,
    public route: ActivatedRoute,
    public router: Router,
    formDataClass: object
  ) {
    this.formDataClass = (formDataClass as any);
    if (this.authService.user !== null) {
      this.user = this.authService.user;
      this.checkDataLoad();
    } else {
      this.router.navigate(['/login']);
    }

    this.route.paramMap.subscribe(paramMap => {
      this.formId = paramMap.get('id');
      this.checkDataLoad();
    });

    breakpointObserver.observe('(max-width: 960px)').pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
      this.isSmallDisplay = result.matches;
      this.isLargeDisplay = !result.matches;
    });

    this.unsavedChangesFunction = this.warnBeforeUnload.bind(this);
    window.addEventListener('beforeunload', this.unsavedChangesFunction);
    this.authService.logoutCheck = this.logoutCheck.bind(this);
  }

  /* Method is used by child classes */
  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.unsavedChangesFunction);
    this.authService.logoutCheck = null;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /* This warning is for when the browser is navigating away from the site or closing the tab */
  warnBeforeUnload(event): string | undefined {
    if (this.hasUnsavedChanges) {
      event.preventDefault();
      event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
      return event.returnValue;
    }
    return undefined;
  }

  /* Called before logout, to make sure we can logout */
  async logoutCheck(): Promise<boolean> {
    if (this.hasUnsavedChanges) {
      const dialogData: MessageDialogData = {
        title: 'Discard Unsaved Changes',
        message: 'You have unsaved changes on the form. You will lose these changes if you logout. Are you sure?',
        okButtonLabel: 'Discard Unsaved Changes'
      };

      return this.dialog.open(MessageDialogComponent, {
        data: dialogData,
        width: '800px'
      }).afterClosed().pipe(
        map(result => {
          if (result) {
            this.hasUnsavedChanges = false;
            this.databaseService.deleteCurrentForm();
            return true;
          }
          return false;
        })
      ).toPromise();
    }
    return Promise.resolve(true);
  }

  async checkDataLoad() {
    if (this.formId !== null) {
      if (this.user.role === UserRole.ProjectAdmin || this.user.role === UserRole.NccdAdmin) {
        await this.loadFormDataForAdmin();
        this.afterFormDataLoaded();
      } else if (this.currentFormData === null || this.formId === 'new' || parseInt(this.formId, 10) !== this.currentFormData.id) {
        if (this.formId === 'new') {
          await this.createNewFormData();
        } else {
          await this.loadFormDataForAgentOrSupervisor();
        }
        this.afterFormDataLoaded();
      }
    }
  }

  /* Creates a new currentFormData object with the appropriate class*/
  async createNewFormData(): Promise<void> {
    try {
      this.currentFormData = this.formDataClass.createNewForm(
        await this.databaseService.getNewFormId(),
        this.formTypeId,
        this.user,
        getCurrentMillisecondTimestampRoundedToNearestSecond()
      );
      await this.saveCurrentFormValues();
      this.router.navigate(['/form', this.formTypeId.toString(), this.currentFormData.id.toString()], {replaceUrl: true});
    } catch (error) {
      console.error('Error creating new form data object', error);
    }
  }

  /* Loads the currentFormData object with the appropriate class */
  async loadFormDataForAgentOrSupervisor(): Promise<void> {
    const processingData: ProcessingDialogData = {
      message: new BehaviorSubject<string>('')
    };
    const dialogRef = this.dialog.open(ProcessingDialogComponent, {
      data: processingData,
      disableClose: true,
      width: '800px'
    });

    try {
      if (this.formId) {
        const formId = parseInt(this.formId, 10);
        processingData.message.next('Loading form data from local device.');
        const currentFormData = await this.databaseService.getCurrentForm(this.formDataClass.createFromDatabaseString);
        if (currentFormData !== null && currentFormData.id === formId) {
          this.currentFormData = currentFormData;
          this.hasUnsavedChanges = true;
        } else {
          this.currentFormData = await this.databaseService.getForm(formId, this.formDataClass.createFromDatabaseString);

          if (this.onlineService.online.value && formId > 0 && (this.currentFormData && this.currentFormData.offlineSaveTimestamp === null)) {
            // Device is online, not local only id, and not offline saved
            processingData.message.next('Loading form data from data server.');
            let getSurveyResponse: ApiGetSurveyResponse | null = null;
            try {
              const getSurveyResponse = await this.apiService.apiGetSurvey(formId);
              const serverVersion = this.formDataClass.createFromServerObject(getSurveyResponse, true);
              if (!this.currentFormData.fullRecord || serverVersion.updated.timestamp > this.currentFormData.updated.timestamp) {
                this.currentFormData = serverVersion;
                await this.databaseService.saveForm(this.currentFormData);
              }
            } catch (error) {
              if (this.currentFormData && this.currentFormData.fullRecord) {
                processingData.message.error(error + ' Using the locally cached data.');
                return ;
              } else {
                processingData.message.error(error);
                dialogRef.afterClosed().subscribe(() => this.router.navigate(['/form-list']));
                return ;
              }
            }
          }
        }
      }
      if (this.currentFormData === null) {
        processingData.message.error('Unable to load form.');
        dialogRef.afterClosed().subscribe(() => this.router.navigate(['/form-list']));
        return ;
      }
      if (!this.currentFormData.fullRecord) {
        console.error('Only summary data loaded on edit page', this.currentFormData);
        processingData.message.error('Only summary form data is loaded. Unable to load the rest of the form from the data server.');
        dialogRef.afterClosed().subscribe(() => this.router.navigate(['/form-list']));
        return ;
      }
      processingData.message.complete();
    } catch (error) {
      console.error('Error loading form', error);
      processingData.message.error(error);
    }
  }

  /* Loads the currentFormData object with the appropriate class */
  async loadFormDataForAdmin(): Promise<void> {
    const processingData: ProcessingDialogData = {
      message: new BehaviorSubject<string>('')
    };
    const dialogRef = this.dialog.open(ProcessingDialogComponent, {
      data: processingData,
      disableClose: true,
      width: '800px'
    });

    try {
      if (this.formId) {
        const formId = parseInt(this.formId, 10);
        processingData.message.next('Loading form data from data server.');
        const getSurveyResponse = await this.apiService.apiGetSurvey(formId);
        this.currentFormData = this.formDataClass.createFromServerObject(getSurveyResponse, true);
      }
      if (this.currentFormData === null) {
        processingData.message.error('Unable to load form.');
        dialogRef.afterClosed().subscribe(() => this.router.navigate(['/form-list']));
        return ;
      }
      processingData.message.complete();
    } catch (error) {
      console.error('Error loading form', error);
      processingData.message.error(error);
    }
  }

  /* This function should be overridden in child classes to hydrate form with currentFormData values */
  afterFormDataLoaded(): void {
    if (this.currentFormData) {
      this.readonly = (this.user.role === UserRole.Agent && this.currentFormData.status !== FormStatus.InProgress) ||
        (this.user.role === UserRole.Supervisor && this.currentFormData.status !== FormStatus.InReview) ||
        this.user.role === UserRole.ProjectAdmin || this.user.role === UserRole.NccdAdmin;
      this.displaySupervisorFields = (this.user.role !== UserRole.Agent || this.currentFormData.status !== FormStatus.InProgress);
    }
  }

  async saveCurrentFormValues(): Promise<void> {
    try {
      if (!this.readonly && this.currentFormData) {
        this.hasUnsavedChanges = true;
        await this.databaseService.saveCurrentForm(this.currentFormData);
      }
    } catch (error) {
      console.error('Error saving current form values', error);
    }
  }

  deleteFormDialogData: MessageDialogData = {
    title: 'Delete Form',
    message: 'STOP! Deleting this form is irreversible.' +
      ' Once deleted any information you have entered for the form will be gone forever.' +
      ' This action should only be used if this form was created by mistake.' +
      ' Are you sure you want to do this? ',
    okButtonLabel: 'Delete Form'
  };

  deleteFormDialog(): void {
    if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InProgress) {
      this.dialog.open(MessageDialogComponent, {
        data: this.deleteFormDialogData,
        width: '800px'
      }).afterClosed().subscribe((result: boolean | null) => {
        if (result) {
          this.deleteForm();
        }
      });
    }
  }

  deleteForm(): void {
    if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InProgress) {
      this.currentFormData.updated = {
        userId: this.user.id,
        userName: this.user.name,
        timestamp: getCurrentMillisecondTimestampRoundedToNearestSecond()
      };
      this.cancelOfflineSaveStatus = this.currentFormData.status;
      this.cancelOfflineSaveTimestamp = this.currentFormData.statusChangeTimestamp;
      this.currentFormData.status = FormStatus.Deleted;
      this.currentFormData.statusChangeTimestamp = this.currentFormData.updated.timestamp;

      this.saveToServer();
    }
  }

  discardChangesDialogData: MessageDialogData = {
    title: 'Discard Changes',
    message: 'Discarding changes will revert any changes you have made to this form since you last saved it.' +
      ' This includes any changes to lists or tables made through dialogs.' +
      ' Are you sure you want to do this?',
    okButtonLabel: 'Discard Changes'
  };

  discardChangesDialog(): void {
    if (this.readonly || !this.hasUnsavedChanges) {
      this.router.navigate(['/form-list']);
    } else {
      this.dialog.open(MessageDialogComponent, {
        data: this.discardChangesDialogData,
        width: '800px'
      }).afterClosed().subscribe((result: boolean | null) => {
        if (result) {
          this.discardChanges();
        }
      });
    }
  }

  async discardChanges(): Promise<void>  {
    try {
      this.hasUnsavedChanges = false;
      await this.databaseService.deleteCurrentForm();
      this.router.navigate(['/form-list']);
    } catch (error) {
      console.error('Error discarding changes', error);
    }
  }

  saveFormDialogErrorData: MessageDialogData = {
    title: 'Save Form Error',
    message: 'You must provide a name to save the form.',
    okButtonLabel: 'Return to Form',
    hideCancel: true
  };

  async saveForm(): Promise<void> {
    if (!this.readonly) {
      if (!this.currentFormData || !this.currentFormData.name) {
        this.dialog.open(MessageDialogComponent, {
          data: this.saveFormDialogErrorData,
          width: '800px'
        });
      } else {
        this.currentFormData.updated = {
          userId: this.user.id,
          userName: this.user.name,
          timestamp: getCurrentMillisecondTimestampRoundedToNearestSecond()
        };

        this.saveToServer();
      }
    }
  }

  async saveToServer(): Promise<void> {
    if (!this.onlineService.online.value) {
      this.saveOfflineDialog('The device is not currently connected to the internet.');
      return;
    }

    if (!this.readonly && this.currentFormData) {
      const processingData: ProcessingDialogData = {
        message: new BehaviorSubject<string>('')
      };
      this.dialog.open(ProcessingDialogComponent, {
        data: processingData,
        disableClose: true,
        width: '800px'
      });

      try {
        processingData.message.next('Saving form to the data server.');
        let saveSurveyResponse: ApiSaveSurveyResponse | null = null;
        try {
          saveSurveyResponse = await this.apiService.saveSurvey(this.currentFormData.toServerObject());
        } catch (error) {
          processingData.message.complete();
          this.saveOfflineDialog(error);
          return ;
        }

        if (saveSurveyResponse.Status !== "Success") {
          processingData.message.error('Data server returned an error while saving the form. Error: ' + saveSurveyResponse.Error);
          return ;
        }

        processingData.message.next('Saving data locally to device.');
        if (this.currentFormData.offlineSaveTimestamp !== null) {
          this.currentFormData.offlineSaveTimestamp = null;
        }

        const responseSurveyId = parseInt(saveSurveyResponse.SurveyId, 10);
        if (responseSurveyId !== this.currentFormData.id) {
          const oldId = this.currentFormData.id;
          this.currentFormData.id = responseSurveyId;

          if (this.currentFormData.status !== FormStatus.Deleted) {
            await this.databaseService.saveForm(this.currentFormData);
          }
          await this.databaseService.deleteForm(oldId);

          // Replace url with new id for back button purposes
          this.router.navigate(['/form', this.currentFormData.formType.id.toString(), this.currentFormData.id.toString()], {replaceUrl: true});
        } else {
          if (this.currentFormData.status !== FormStatus.Deleted) {
            await this.databaseService.saveForm(this.currentFormData);
          } else {
            await this.databaseService.deleteForm(this.currentFormData.id);
          }
        }

        this.hasUnsavedChanges = false;
        await this.databaseService.deleteCurrentForm();
        processingData.message.complete();
        this.router.navigate(['/form-list']);
      } catch (error) {
        console.error(error);
        processingData.message.error(error);
      }
    }
  }

  saveOfflineDialogData: MessageDialogData = {
    title: 'Save Form Offline',
    message: ' Do you wish to save the data offline?' +
      ' This will save the data locally to this device and sync the data to the data server later.',
    okButtonLabel: 'Save Form Offline'
  };

  saveOfflineDialog(error: string): void {
    const dialogData = {
      title: this.saveOfflineDialogData.title,
      message: error + this.saveOfflineDialogData.message,
      okButtonLabel: this.saveOfflineDialogData.okButtonLabel
    };
    this.dialog.open(MessageDialogComponent, {
      data: dialogData,
      width: '800px'
    }).afterClosed().subscribe((result: boolean | null) => {
      if (result) {
        this.saveOffline();
      } else if (this.currentFormData !== null && this.cancelOfflineSaveStatus !== null && this.cancelOfflineSaveTimestamp !== null) {
        this.currentFormData.status = this.cancelOfflineSaveStatus;
        this.currentFormData.statusChangeTimestamp = this.cancelOfflineSaveTimestamp;
        this.cancelOfflineSaveStatus = null;
        this.cancelOfflineSaveTimestamp = null;
      }
    });
  }

  async saveOffline(): Promise<void> {
    if (!this.readonly && this.currentFormData) {
      const processingData: ProcessingDialogData = {
        message: new BehaviorSubject<string>('')
      };
      this.dialog.open(ProcessingDialogComponent, {
        data: processingData,
        disableClose: true,
        width: '800px'
      });

      try {
        processingData.message.next('Saving data locally to device.');
        if (this.currentFormData.updated) {
          this.currentFormData.offlineSaveTimestamp = this.currentFormData.updated.timestamp;
        } else {
          this.currentFormData.offlineSaveTimestamp = getCurrentMillisecondTimestampRoundedToNearestSecond();
        }
        await this.databaseService.saveForm(this.currentFormData);
        this.hasUnsavedChanges = false;
        await this.databaseService.deleteCurrentForm();
        processingData.message.complete();
        this.router.navigate(['/form-list']);
      } catch (error) {
        console.error(error);
        processingData.message.error(error);
        return;
      }
    }
  }

  /**
   * This function does validation other than form.valid before submitting or completing
   * If validation does not pass it should return false and possibly set
   * submitFormDialogErrorData.message or completeFormDialogErrorData.message
   */
  extraFormValidation(action: 'submit' | 'complete'): boolean {
    return true;
  }

  /* This is exposed as a separate element because the extraFormValidation method may change the error message
    and it needs to be reset to this before the extra form validation is run. */
  submitFormDialogErrorMessage = 'There are errors (e.g. required fields that are missing information) in the form' +
    ' that prevent you from submitting the form. Please review each tab and correct the errors displayed in red for each field.';

  submitFormDialogErrorData: MessageDialogData = {
    title: 'Submit Form Error',
    message: this.submitFormDialogErrorMessage,
    okButtonLabel: 'Return to Form',
    hideCancel: true
  };

  submitFormDialogData: MessageDialogData = {
    title: 'Submit Form',
    message: 'This action will send the form to your supervisor for review.' +
      ' This will change the status from "In Progress" to "In Review" and' +
      ' you will not be able to edit the form after you submit it.' +
      ' Are you sure you want to do this?',
    okButtonLabel: 'Submit Form'
  };

  submitFormDialog(): void {
    if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InProgress) {
      this.form.markAllAsTouched();
      this.submitFormDialogErrorData.message = this.submitFormDialogErrorMessage;
      delete this.submitFormDialogErrorData.messageList;
      if (!this.form.valid || !this.extraFormValidation('submit')) {
        this.dialog.open(MessageDialogComponent, {
          data: this.submitFormDialogErrorData,
          width: '800px'
        });
      } else {
        this.dialog.open(MessageDialogComponent, {
          data: this.submitFormDialogData,
          width: '800px'
        }).afterClosed().subscribe((result: boolean | null) => {
          if (result) {
            this.submitForm();
          }
        });
      }
    }
  }

  async submitForm(): Promise<void> {
    if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InProgress) {
      this.currentFormData.updated = {
        userId: this.user.id,
        userName: this.user.name,
        timestamp: getCurrentMillisecondTimestampRoundedToNearestSecond()
      };
      this.cancelOfflineSaveStatus = this.currentFormData.status;
      this.cancelOfflineSaveTimestamp = this.currentFormData.statusChangeTimestamp;
      this.currentFormData.status = FormStatus.InReview;
      this.currentFormData.statusChangeTimestamp = this.currentFormData.updated.timestamp;

      this.saveToServer();
    }
  }

  sendBackFormDialogErrorData: MessageDialogData = {
    title: 'Send Back to Agent Error',
    message: 'You must provide a name to save the form.',
    okButtonLabel: 'Return to Form',
    hideCancel: true
  };

  sendBackFormDialogData: MessageDialogData = {
    title: 'Send Back to Agent',
    message: 'This action will send the form back to the Agent for modification.' +
      ' This will revert the status of the form to "In Progress" and' +
      ' you will not be able to edit the form until the Agent submits it again.' +
      ' Are you sure you want to do this?',
    okButtonLabel: 'Send Back to Agent'
  };

  sendBackFormDialog(): void {
    if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
      if (!this.currentFormData || !this.currentFormData.name) {
        this.dialog.open(MessageDialogComponent, {
          data: this.sendBackFormDialogErrorData,
          width: '800px'
        });
      } else {
        this.dialog.open(MessageDialogComponent, {
          data: this.sendBackFormDialogData,
          width: '800px'
        }).afterClosed().subscribe((result: boolean | null) => {
          if (result) {
            this.sendBackForm();
          }
        });
      }
    }
  }

  async sendBackForm(): Promise<void> {
    if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
      this.currentFormData.updated = {
        userId: this.user.id,
        userName: this.user.name,
        timestamp: getCurrentMillisecondTimestampRoundedToNearestSecond()
      };
      this.cancelOfflineSaveStatus = this.currentFormData.status;
      this.cancelOfflineSaveTimestamp = this.currentFormData.statusChangeTimestamp;
      this.currentFormData.status = FormStatus.InProgress;
      this.currentFormData.statusChangeTimestamp = this.currentFormData.updated.timestamp;

      this.saveToServer();
    }
  }

  /* This is exposed as a separate element because the extraFormValidation method may change the error message
    and it needs to be reset to this before the extra form validation is run. */
  completeFormDialogErrorMessage = 'There are errors (e.g. required fields that are missing information) in the form' +
    ' that prevent you from completing the form. Please review each tab and correct the errors displayed in red for each field.';

  completeFormDialogErrorData: MessageDialogData = {
    title: 'Complete Form Error',
    message: this.completeFormDialogErrorMessage,
    okButtonLabel: 'Return to Form',
    hideCancel: true
  };

  completeFormDialogData: MessageDialogData = {
    title: 'Complete Form',
    message: 'This action will change the status from "In Review" to "Completed" and' +
      ' you will not be able to edit the form after you complete it.' +
      ' Are you sure you want to do this?',
    okButtonLabel: 'Complete Form'
  };

  completeFormDialog(): void {
    if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
      this.form.markAllAsTouched();
      this.completeFormDialogErrorData.message = this.completeFormDialogErrorMessage;
      delete this.completeFormDialogErrorData.messageList;
      if (!this.form.valid || !this.extraFormValidation('complete')) {
        this.dialog.open(MessageDialogComponent, {
          data: this.completeFormDialogErrorData,
          width: '800px'
        });
      } else {
        this.dialog.open(MessageDialogComponent, {
          data: this.completeFormDialogData,
          width: '800px'
        }).afterClosed().subscribe((result: boolean | null) => {
          if (result) {
            this.completeForm();
          }
        });
      }
    }
  }

  async completeForm(): Promise<void> {
    if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
      this.currentFormData.updated = {
        userId: this.user.id,
        userName: this.user.name,
        timestamp: getCurrentMillisecondTimestampRoundedToNearestSecond()
      };
      this.cancelOfflineSaveStatus = this.currentFormData.status;
      this.cancelOfflineSaveTimestamp = this.currentFormData.statusChangeTimestamp;
      this.currentFormData.status = FormStatus.Completed;
      this.currentFormData.statusChangeTimestamp = this.currentFormData.updated.timestamp;

      this.saveToServer();
    }
  }
}
