import { OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
export class TitleService {
    constructor(titleService) {
        this.titleService = titleService;
        this.unsubscribe$ = new Subject();
        this.title = new BehaviorSubject('NCCD');
        this.title.pipe(takeUntil(this.unsubscribe$)).subscribe(title => {
            this.titleService.setTitle(title);
        });
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
TitleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TitleService_Factory() { return new TitleService(i0.ɵɵinject(i1.Title)); }, token: TitleService, providedIn: "root" });
