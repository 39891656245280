import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { Doc2019CaseSupportTimeComponent } from './doc2019-case-support-time/doc2019-case-support-time.component';
import { Doc2019CaseSupportTimeActivityDialogComponent } from './doc2019-case-support-time/doc2019-case-support-time-activity-dialog/doc2019-case-support-time-activity-dialog.component';
import { Doc2019CaseSupportTimeActivityListFilterDialogComponent } from './doc2019-case-support-time/doc2019-case-support-time-activity-list-filter-dialog/doc2019-case-support-time-activity-list-filter-dialog.component';
import { Doc2019FieldInvestigationTimeComponent } from './doc2019-field-investigation-time/doc2019-field-investigation-time.component';
import { Doc2019FieldInvestigationTimeViolationDialogComponent } from './doc2019-field-investigation-time/doc2019-field-investigation-time-violation-dialog/doc2019-field-investigation-time-violation-dialog.component';
import { Doc2019FieldInvestigationTimeActivityListFilterDialogComponent } from './doc2019-field-investigation-time/doc2019-field-investigation-time-activity-list-filter-dialog/doc2019-field-investigation-time-activity-list-filter-dialog.component';
import { Doc2019FieldInvestigationTimeActivityDialogComponent } from './doc2019-field-investigation-time/doc2019-field-investigation-time-activity-dialog/doc2019-field-investigation-time-activity-dialog.component';
import { Doc2019OffenderSupervisionTimeComponent } from './doc2019-offender-supervision-time/doc2019-offender-supervision-time.component';
import { Doc2019OffenderSupervisionTimeActivityDialogComponent } from './doc2019-offender-supervision-time/doc2019-offender-supervision-time-activity-dialog/doc2019-offender-supervision-time-activity-dialog.component';
import { Doc2019OffenderSupervisionTimeActivityListFilterDialogComponent } from './doc2019-offender-supervision-time/doc2019-offender-supervision-time-activity-list-filter-dialog/doc2019-offender-supervision-time-activity-list-filter-dialog.component';
import { Doc2019OffenderSupervisionTimeViolationDialogComponent } from './doc2019-offender-supervision-time/doc2019-offender-supervision-time-violation-dialog/doc2019-offender-supervision-time-violation-dialog.component';
import { DigitOnlyModule } from '@uiowa/digit-only';

@NgModule({
  declarations: [
    Doc2019CaseSupportTimeComponent,
    Doc2019CaseSupportTimeActivityDialogComponent,
    Doc2019CaseSupportTimeActivityListFilterDialogComponent,
    Doc2019FieldInvestigationTimeComponent,
    Doc2019FieldInvestigationTimeViolationDialogComponent,
    Doc2019FieldInvestigationTimeActivityListFilterDialogComponent,
    Doc2019FieldInvestigationTimeActivityDialogComponent,
    Doc2019OffenderSupervisionTimeComponent,
    Doc2019OffenderSupervisionTimeActivityDialogComponent,
    Doc2019OffenderSupervisionTimeActivityListFilterDialogComponent,
    Doc2019OffenderSupervisionTimeViolationDialogComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    DigitOnlyModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  entryComponents: [
    Doc2019CaseSupportTimeActivityDialogComponent,
    Doc2019CaseSupportTimeActivityListFilterDialogComponent,
    Doc2019FieldInvestigationTimeViolationDialogComponent,
    Doc2019FieldInvestigationTimeActivityListFilterDialogComponent,
    Doc2019FieldInvestigationTimeActivityDialogComponent,
    Doc2019OffenderSupervisionTimeActivityDialogComponent,
    Doc2019OffenderSupervisionTimeActivityListFilterDialogComponent,
    Doc2019OffenderSupervisionTimeViolationDialogComponent
  ]
})
export class Doc2019Module { }
