import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { FormComponent } from '../../../form/form.component';
import { getFormTypeById } from '../../form-type-data';
import { MatSort } from '@angular/material/sort';
import { Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { FormStatus } from '../../../form/form-status.enum';
import * as moment from 'moment';
import { MessageDialogComponent } from '../../../message-dialog/message-dialog.component';
import { Mddjs2020CdFloatingStudyMonthOption } from './mddjs2020-cd-floating-study-month-option.enum';
import { Mddjs2020CdFloatingActivityListColumn } from './mddjs2020-cd-floating-activity-list-column.enum';
import { Mddjs2020CdFloatingFormData } from './mddjs2020-cd-floating-form-data';
import { Mddjs2020CdFloatingActivityDialogComponent } from './mddjs2020-cd-floating-activity-dialog/mddjs2020-cd-floating-activity-dialog.component';
import { Mddjs2020CdFloatingActivityActivityOption } from './mddjs2020-cd-floating-activity-dialog/mddjs2020-cd-floating-activity-activity-option.enum';
import { Mddjs2020CdFloatingActivityListFilterDialogComponent } from './mddjs2020-cd-floating-activity-list-filter-dialog/mddjs2020-cd-floating-activity-list-filter-dialog.component';
export class Mddjs2020CdFloatingComponent extends FormComponent {
    constructor(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, titleService) {
        super(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, Mddjs2020CdFloatingFormData);
        this.apiService = apiService;
        this.authService = authService;
        this.breakpointObserver = breakpointObserver;
        this.databaseService = databaseService;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.onlineService = onlineService;
        this.route = route;
        this.router = router;
        this.titleService = titleService;
        this.formTypeId = 16;
        this.formType = getFormTypeById(this.formTypeId);
        this.formDataClass = Mddjs2020CdFloatingFormData;
        this.currentFormData = null;
        this.activitiesDataSource = [];
        this.activityListColumnEnum = Mddjs2020CdFloatingActivityListColumn;
        this.activityListDisplayedColumns = Object.values(Mddjs2020CdFloatingActivityListColumn);
        this.activityListFilterForm = this.formBuilder.group({
            activity: [null],
            sorting: [null]
        });
        this.activityListFilters = {
            activity: null,
            sortColumn: null,
            sortDirection: null
        };
        this.activityListFilterInfoString = '';
        this.activityOptions = Object.values(Mddjs2020CdFloatingActivityActivityOption);
        this.activityListSortOptions = [
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.caseName, sortDirection: 'asc', display: 'Case Name Ascending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.caseName, sortDirection: 'desc', display: 'Case Name Descending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.djsClientId, sortDirection: 'asc', display: 'DJS Client ID Ascending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.djsClientId, sortDirection: 'desc', display: 'DJS Client ID Descending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.activityDate, sortDirection: 'asc', display: 'Date Ascending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.activityDate, sortDirection: 'desc', display: 'Date Descending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.activity, sortDirection: 'asc', display: 'Activity Ascending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.activity, sortDirection: 'desc', display: 'Activity Descending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.person, sortDirection: 'asc', display: 'Person Ascending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.person, sortDirection: 'desc', display: 'Person Descending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.method, sortDirection: 'asc', display: 'Method Ascending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.method, sortDirection: 'desc', display: 'Method Descending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.place, sortDirection: 'asc', display: 'Place Ascending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.place, sortDirection: 'desc', display: 'Place Descending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.activityMinutes, sortDirection: 'asc', display: 'Activity Minutes Ascending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.activityMinutes, sortDirection: 'desc', display: 'Activity Minutes Descending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.travelMinutes, sortDirection: 'asc', display: 'Travel Minutes Ascending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.travelMinutes, sortDirection: 'desc', display: 'Travel Minutes Descending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.waitingMinutes, sortDirection: 'asc', display: 'Waiting Minutes Ascending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.waitingMinutes, sortDirection: 'desc', display: 'Waiting Minutes Descending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.activityDescription, sortDirection: 'asc', display: 'Description Ascending' },
            { sortColumn: Mddjs2020CdFloatingActivityListColumn.activityDescription, sortDirection: 'desc', display: 'Description Descending' },
        ];
        this.form = this.formBuilder.group({
            agentName: [null],
            createdDate: [null],
            status: [null],
            statusChangedDate: [null],
            updatedDate: [null],
            studyMonth: [null, Validators.required],
            comments: [null, Validators.maxLength(500)],
            activityListFilter: this.activityListFilterForm,
            allWorkDocumented: [null],
            supervisorComments: [null, Validators.maxLength(500)]
        });
        this.studyMonthOptions = Object.values(Mddjs2020CdFloatingStudyMonthOption);
        this.loadFormTypeSettings();
        this.discardChangesDialogData.message = 'If you discard changes, you will lose any changes you have made to this form since you last saved it.' +
            ' This includes any changes to case information and activities. Are you sure you want to do this?';
        this.submitFormDialogData.message = 'Submitting the form will send it to your supervisor for review and' +
            ' you will not longer be able to edit this form. Make sure you have recorded all the time spent working on this case during the month.';
        this.sendBackFormDialogErrorData.title = 'Send Back to CMS/CDO Error';
        this.sendBackFormDialogData.title = 'Send Back to CMS/CDO';
        this.sendBackFormDialogData.message = 'This action will send the form back to the CMS/CDO for modification.' +
            ' This will revert the status of the form to "In Progress" and you will not be able to edit the form until the CMS/CDO submits it again.' +
            ' Are you sure you want to do this?';
        this.sendBackFormDialogData.okButtonLabel = 'Send Back to CMS/CDO';
        this.completeFormDialogData.message = 'Approving the form will remove it from your queue and you will no longer be able to edit this form. Make sure:';
        this.completeFormDialogData.messageList = [
            'The form captures all the work that was completed by the CDO.',
            'You have reviewed the form for required work activities designated by standards.',
            'Time is recorded in minutes.'
        ];
    }
    set activityListSortViewChild(value) {
        this.activityListSort = value;
        // This timeout is needed or angular complains Expression has changed after it was checked.
        window.setTimeout(() => {
            // default in the current set sorting when the sorter is set
            // this happens if the width goes from isSmallDisplay to isLargeDisplay
            if (this.activityListSort && this.activityListFilterForm && this.activityListFilterForm.controls.sorting.value) {
                const sorting = this.activityListFilterForm.controls.sorting.value;
                if (this.activityListSort.active != sorting.sortColumn || this.activityListSort.direction != sorting.sortDirection) {
                    if (this.activityListSort) {
                        this.activityListSort.sort({
                            id: sorting.sortColumn,
                            start: sorting.sortDirection,
                            disableClear: true
                        });
                    }
                }
            }
        });
    }
    /* adds subscriptions for each of the form fields to update this.currentFormData */
    formChangeSubscriptions() {
        this.form.controls.studyMonth.valueChanges.subscribe(studyMonth => {
            if (this.currentFormData && this.currentFormData.studyMonth !== studyMonth) {
                this.currentFormData.studyMonth = studyMonth;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.comments.valueChanges.subscribe(comments => {
            if (this.currentFormData && this.currentFormData.comments !== comments) {
                this.currentFormData.comments = comments;
                this.saveCurrentFormValues();
            }
        });
        this.activityListFilterForm.controls.sorting.valueChanges.subscribe((sorting) => {
            if (sorting && this.activityListSort && (this.activityListSort.active !== sorting.sortColumn || (this.activityListSort.direction || 'asc') !== sorting.sortDirection)) {
                this.activityListSort.sort({
                    id: sorting.sortColumn,
                    start: sorting.sortDirection,
                    disableClear: true
                });
            }
        });
        this.activityListFilterForm.valueChanges.pipe(debounceTime(100)).subscribe(values => {
            let saveActivityListFilters = false;
            if (this.activityListFilters.activity !== values.activity) {
                this.activityListFilters.activity = values.activity;
                saveActivityListFilters = true;
            }
            if (values.sorting) {
                if (this.activityListFilters.sortColumn !== values.sorting.sortColumn || this.activityListFilters.sortDirection !== values.sorting.sortDirection) {
                    this.activityListFilters.sortColumn = values.sorting.sortColumn;
                    this.activityListFilters.sortDirection = values.sorting.sortDirection;
                    saveActivityListFilters = true;
                }
            }
            else if (this.activityListFilters.sortColumn !== null || this.activityListFilters.sortDirection !== null) {
                this.activityListFilters.sortColumn = null;
                this.activityListFilters.sortDirection = null;
                saveActivityListFilters = true;
            }
            if (saveActivityListFilters) {
                this.saveFormTypeSettings();
            }
            this.activityListFilterAndSortData();
        });
        this.form.controls.allWorkDocumented.valueChanges.subscribe(allWorkDocumented => {
            if (this.currentFormData && this.currentFormData.allWorkDocumented !== allWorkDocumented) {
                this.currentFormData.allWorkDocumented = allWorkDocumented;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.supervisorComments.valueChanges.subscribe(supervisorComments => {
            if (this.currentFormData && this.currentFormData.supervisorComments !== supervisorComments) {
                this.currentFormData.supervisorComments = supervisorComments;
                this.saveCurrentFormValues();
            }
        });
    }
    /* updates the validators for allWorkDocumented */
    updateAllWorkDocumentedValidators() {
        if (this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
            this.form.controls.allWorkDocumented.setValidators(Validators.required);
        }
        else {
            this.form.controls.allWorkDocumented.clearValidators();
        }
        this.form.controls.allWorkDocumented.updateValueAndValidity();
    }
    /* Hydrates form with currentFormData values */
    afterFormDataLoaded() {
        super.afterFormDataLoaded();
        if (this.formType && this.currentFormData) {
            const title = this.formType.name + ' - ' + this.currentFormData.name;
            if (this.titleService.title.value !== title) {
                this.titleService.title.next(title);
            }
        }
        if (this.currentFormData) {
            if (!this.currentFormData.name || this.currentFormData.name !== this.currentFormData.created.userName) {
                // Setting the name from the created user's name since there is no name field on the form
                this.currentFormData.name = this.currentFormData.created.userName;
            }
            if (this.formType) {
                const title = this.formType.name + ' - ' + this.currentFormData.name;
                if (this.titleService.title.value !== title) {
                    this.titleService.title.next(title);
                }
            }
            this.form.controls.agentName.setValue(this.currentFormData.created.userName);
            this.form.controls.createdDate.setValue(moment(this.currentFormData.created.timestamp, 'x').format('L LT'));
            this.form.controls.status.setValue(this.currentFormData.status);
            this.form.controls.statusChangedDate.setValue(moment(this.currentFormData.statusChangeTimestamp, 'x').format('L LT'));
            this.form.controls.updatedDate.setValue(moment(this.currentFormData.updated.timestamp, 'x').format('L LT'));
            this.form.controls.studyMonth.setValue(this.currentFormData.studyMonth);
            this.form.controls.comments.setValue(this.currentFormData.comments);
            this.activityListFilterAndSortData();
            this.form.controls.allWorkDocumented.setValue(this.currentFormData.allWorkDocumented);
            this.form.controls.supervisorComments.setValue(this.currentFormData.supervisorComments);
            this.updateAllWorkDocumentedValidators();
            // See if the loaded data has a current activity
            // This should only happen if the loaded record is a current form and the user was in the middle of editing that dialog
            if (this.currentFormData.currentActivity) {
                this.editActivity(this.currentFormData.currentActivity);
            }
        }
        this.formChangeSubscriptions();
    }
    loadFormTypeSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const settingsString = yield this.databaseService.getFormTypeSettings(this.formTypeId);
                if (settingsString) {
                    const settings = JSON.parse(settingsString);
                    if (settings.activityListFilters) {
                        this.activityListFilters = settings.activityListFilters;
                        this.activityListFilterForm.controls.activity.setValue(this.activityListFilters.activity);
                        const activitySorting = this.activityListSortOptions.filter(sorting => sorting.sortColumn === this.activityListFilters.sortColumn && sorting.sortDirection === this.activityListFilters.sortDirection);
                        if (activitySorting.length > 0) {
                            this.activityListFilterForm.controls.sorting.setValue(activitySorting[0]);
                        }
                        else {
                            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
                        }
                    }
                    else {
                        this.clearActivityListFilters();
                    }
                }
                else {
                    this.clearActivityListFilters();
                }
            }
            catch (error) {
                console.error('Error retrieving form type settings', error);
            }
        });
    }
    saveFormTypeSettings() {
        try {
            const settings = {
                activityListFilters: this.activityListFilters
            };
            this.databaseService.saveFormTypeSettings(this.formTypeId, JSON.stringify(settings));
        }
        catch (error) {
            console.error('Error saving form type settings', error);
        }
    }
    activityListFilterAndSortData() {
        let sortColumn = Mddjs2020CdFloatingActivityListColumn.activityDate;
        let sortDirection = 'asc';
        if (this.activityListFilterForm.controls.sorting.value) {
            const sorting = this.activityListFilterForm.controls.sorting.value;
            sortColumn = sorting.sortColumn;
            sortDirection = sorting.sortDirection;
        }
        if (this.currentFormData && this.currentFormData.activities) {
            this.activitiesDataSource = this.currentFormData.activities.filter((activity) => {
                return !(this.activityListFilterForm.controls.activity.value && activity.activity !== this.activityListFilterForm.controls.activity.value);
            }).sort((activity1, activity2) => {
                switch (sortColumn) {
                    case Mddjs2020CdFloatingActivityListColumn.caseName:
                        if ((activity1.caseName || '') > (activity2.caseName || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.caseName || '') < (activity2.caseName || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020CdFloatingActivityListColumn.djsClientId:
                        if ((activity1.djsClientId || '') > (activity2.djsClientId || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.djsClientId || '') < (activity2.djsClientId || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020CdFloatingActivityListColumn.activityDate:
                        if ((activity1.activityDate || 0) > (activity2.activityDate || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityDate || 0) < (activity2.activityDate || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020CdFloatingActivityListColumn.activity:
                        if ((activity1.activity || '') > (activity2.activity || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activity || '') < (activity2.activity || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020CdFloatingActivityListColumn.person:
                        if ((activity1.personDisplay || '') > (activity2.personDisplay || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.personDisplay || '') < (activity2.personDisplay || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020CdFloatingActivityListColumn.method:
                        if ((activity1.method || '') > (activity2.method || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.method || '') < (activity2.method || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020CdFloatingActivityListColumn.place:
                        if ((activity1.place || '') > (activity2.place || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.place || '') < (activity2.place || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020CdFloatingActivityListColumn.activityMinutes:
                        if ((activity1.activityMinutes || 0) > (activity2.activityMinutes || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityMinutes || 0) < (activity2.activityMinutes || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020CdFloatingActivityListColumn.travelMinutes:
                        if ((activity1.travelMinutes || 0) > (activity2.travelMinutes || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.travelMinutes || 0) < (activity2.travelMinutes || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020CdFloatingActivityListColumn.waitingMinutes:
                        if ((activity1.waitingMinutes || 0) > (activity2.waitingMinutes || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.waitingMinutes || 0) < (activity2.waitingMinutes || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Mddjs2020CdFloatingActivityListColumn.activityDescription:
                        if ((activity1.activityDescription || '') > (activity2.activityDescription || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityDescription || '') < (activity2.activityDescription || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                }
                // sensible defaults if the key doesn't exist, or if the initial result is a tie
                if ((activity1.activityDate || 0) < (activity2.activityDate || 0))
                    return -1;
                if ((activity1.activityDate || 0) > (activity2.activityDate || 0))
                    return 1;
                return 0;
            });
        }
        this.setActivityListFilterInfoString();
    }
    setActivityListFilterInfoString() {
        const info = [];
        if (this.activityListFilterForm.controls.activity.value) {
            info.push('Activity Filter: ' + this.activityListFilterForm.controls.activity.value);
        }
        if (this.activityListFilterForm.controls.sorting.value) {
            info.push('Sort: ' + this.activityListFilterForm.controls.sorting.value.display);
        }
        this.activityListFilterInfoString = info.join(', ');
    }
    clearActivityListFilters() {
        if (this.activityListFilterForm.controls.activity.value !== null)
            this.activityListFilterForm.controls.activity.setValue(null);
        if (this.activityListFilterForm.controls.sorting.value !== this.activityListSortOptions[0])
            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
    }
    openActivityListFilterDialog() {
        const dialogData = {
            filterForm: this.activityListFilterForm,
            clearFunction: this.clearActivityListFilters.bind(this),
            activityOptions: this.activityOptions,
            sortOptions: this.activityListSortOptions
        };
        this.dialog.open(Mddjs2020CdFloatingActivityListFilterDialogComponent, {
            data: dialogData,
            width: '800px'
        });
    }
    /**
     * update the form filter when the table headers are clicked to keep them in sync
     */
    onActivityListSortChange(sort) {
        const sortingValue = this.activityListSortOptions.filter(sortOption => sortOption.sortColumn === sort.active && sortOption.sortDirection === (sort.direction || 'asc'));
        if (sortingValue.length > 0 && sortingValue[0] !== this.activityListFilterForm.controls.sorting.value) {
            this.activityListFilterForm.controls.sorting.setValue(sortingValue[0]);
        }
    }
    editActivity(activity) {
        const dialogData = {
            activity: activity,
            updateCurrentActivity: this.updateCurrentActivity.bind(this)
        };
        this.dialog.open(Mddjs2020CdFloatingActivityDialogComponent, {
            data: dialogData,
            disableClose: true,
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (this.currentFormData) {
                if (result) {
                    if (result.index !== null) {
                        this.currentFormData.activities[result.index] = result;
                    }
                    else {
                        this.currentFormData.activities.push(result);
                    }
                    this.currentFormData.activities.forEach((activity, index) => activity.index = index);
                    this.activityListFilterAndSortData();
                }
                this.currentFormData.currentActivity = null;
                this.saveCurrentFormValues();
            }
        });
    }
    updateCurrentActivity(activity) {
        if (this.currentFormData) {
            this.currentFormData.currentActivity = activity;
            this.saveCurrentFormValues();
        }
    }
    deleteActivity(activity) {
        this.dialog.open(MessageDialogComponent, {
            data: {
                title: 'Delete Activity',
                message: 'Deleting this activity is irreversible.' +
                    ' Once deleted any information you have entered for the activity will be gone forever.' +
                    ' Are you sure you want to do this?',
                okButtonLabel: 'Delete Activity'
            },
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (this.currentFormData && result && activity.index !== null) {
                this.currentFormData.activities.splice(activity.index, 1);
                this.currentFormData.activities.forEach((activity, index) => activity.index = index);
                this.activityListFilterAndSortData();
                this.saveCurrentFormValues();
            }
        });
    }
}
