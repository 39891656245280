import { Mddjs2020CdEmActivityActivityOption } from './mddjs2020-cd-em-activity-dialog/mddjs2020-cd-em-activity-activity-option.enum';
import { Mddjs2020CdEmActivityMethodOption } from './mddjs2020-cd-em-activity-dialog/mddjs2020-cd-em-activity-method-option.enum';
import { Mddjs2020CdEmActivityPlaceOption } from './mddjs2020-cd-em-activity-dialog/mddjs2020-cd-em-activity-place-option.enum';
import { Mddjs2020CdEmActivityPersonOption } from './mddjs2020-cd-em-activity-dialog/mddjs2020-cd-em-activity-person-option.enum';

export interface Mddjs2020CdEmActivityData {
  index: number | null; // Used to store the index when passing data to the dialog
  activityDate: number | null; // unix timestamp in milliseconds
  activity: Mddjs2020CdEmActivityActivityOption | null;
  personDisplay: string | null;
  personYouth: boolean;
  personParentGuardianCaretaker: boolean;
  personOtherDjsStaff: boolean;
  personServiceProvider: boolean;
  personDssStaff: boolean;
  personOtherCollateral: boolean;
  personNone: boolean;
  method: Mddjs2020CdEmActivityMethodOption | null;
  place: Mddjs2020CdEmActivityPlaceOption | null;
  activityMinutes: number | null;
  travelMinutes: number | null;
  waitingMinutes: number | null;
  activityDescription: string | null;
}

export function getActivityPersonArray(activity: Mddjs2020CdEmActivityData): string[] {
  const values: Array<string> = [];
  if (activity.personYouth) {
    values.push(Mddjs2020CdEmActivityPersonOption.Youth);
  }
  if (activity.personParentGuardianCaretaker) {
    values.push(Mddjs2020CdEmActivityPersonOption.ParentGuardianCaretaker);
  }
  if (activity.personOtherDjsStaff) {
    values.push(Mddjs2020CdEmActivityPersonOption.OtherDjsStaff);
  }
  if (activity.personServiceProvider) {
    values.push(Mddjs2020CdEmActivityPersonOption.ServiceProvider);
  }
  if (activity.personDssStaff) {
    values.push(Mddjs2020CdEmActivityPersonOption.DssStaff);
  }
  if (activity.personOtherCollateral) {
    values.push(Mddjs2020CdEmActivityPersonOption.OtherCollateral);
  }
  if (activity.personNone) {
    values.push(Mddjs2020CdEmActivityPersonOption.None);
  }
  return values;
}
