import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { noneValidator } from '../../../../validators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { isArray } from "util";
import {
  getActivityPersonInvolvedArray,
  Humboldt2020PlacementActivityData
} from '../humboldt2020-placement-activity-data';
import { Humboldt2020PlacementActivityActivityOption } from './humboldt2020-placement-activity-activity-option.enum';
import { Humboldt2020PlacementActivityPersonInvolvedOption } from './humboldt2020-placement-activity-person-involved-option.enum';
import { Humboldt2020PlacementActivityMethodOption } from './humboldt2020-placement-activity-method-option.enum';
import { Humboldt2020PlacementActivityPlaceOption } from './humboldt2020-placement-activity-place-option.enum';
import { Humboldt2020PlacementActivityDialogData } from './humboldt2020-placement-activity-dialog-data';
import { Humboldt2020PlacementStudyCaseTypeOption } from '../humboldt2020-placement-study-case-type-option.enum';

@Component({
  selector: 'app-humboldt2020-placement-activity-dialog',
  templateUrl: './humboldt2020-placement-activity-dialog.component.html',
  styleUrls: ['./humboldt2020-placement-activity-dialog.component.css']
})
export class Humboldt2020PlacementActivityDialogComponent {
  currentActivity: Humboldt2020PlacementActivityData;
  form = this.formBuilder.group({
    activityDate: [null, Validators.required],
    activity: [null, Validators.required],
    personInvolved: [[], [Validators.required, noneValidator]],
    method: [null, Validators.required],
    place: [null, Validators.required],
    activityMinutesSpent: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    travelMinutesSpent: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    activityDescription: [null, Validators.maxLength(500)]
  });

  activityOptions = Object.values(Humboldt2020PlacementActivityActivityOption);
  activityOptionEnum = Humboldt2020PlacementActivityActivityOption;
  personInvolvedOptions = Object.values(Humboldt2020PlacementActivityPersonInvolvedOption);
  methodOptions = Object.values(Humboldt2020PlacementActivityMethodOption);
  placeOptions = Object.values(Humboldt2020PlacementActivityPlaceOption);

  constructor(
    private dialogRef: MatDialogRef<Humboldt2020PlacementActivityDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Humboldt2020PlacementActivityDialogData
  ) {
    // If Study Case Type is specified, remove the options not allowed for that case type
    if (data.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement) {
      this.activityOptions = Object.values(Humboldt2020PlacementActivityActivityOption).filter((value) => value !== Humboldt2020PlacementActivityActivityOption.RetentionActivity);
    } else if (data.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport) {
      this.activityOptions = Object.values(Humboldt2020PlacementActivityActivityOption).filter((value) => value !== Humboldt2020PlacementActivityActivityOption.PlacementSearch);
    }

    if (data.activity) {
      // Need to make a copy of the object so updating it doesn't update the origin object until save is applied
      this.currentActivity = {
        index: data.activity.index,
        activityDate: data.activity.activityDate,
        activity: data.activity.activity,
        personInvolvedDisplay: data.activity.personInvolvedDisplay,
        personInvolvedYouth: data.activity.personInvolvedYouth,
        personInvolvedRelativeNrefm: data.activity.personInvolvedRelativeNrefm,
        personInvolvedIntakeCoordinator: data.activity.personInvolvedIntakeCoordinator,
        personInvolvedSubstituteCareProvider: data.activity.personInvolvedSubstituteCareProvider,
        personInvolvedSocialWorker: data.activity.personInvolvedSocialWorker,
        personInvolvedDoj: data.activity.personInvolvedDoj,
        personInvolvedTribe: data.activity.personInvolvedTribe,
        personInvolvedSupervisor: data.activity.personInvolvedSupervisor,
        personInvolvedOther: data.activity.personInvolvedOther,
        personInvolvedNone: data.activity.personInvolvedNone,
        method: data.activity.method,
        place: data.activity.place,
        activityMinutesSpent: data.activity.activityMinutesSpent,
        travelMinutesSpent: data.activity.travelMinutesSpent,
        activityDescription: data.activity.activityDescription
      };

      if (this.currentActivity.activityDate) {
        this.form.controls.activityDate.setValue(moment(this.currentActivity.activityDate, 'x')); // x = unix milliseconds
      }
      this.form.controls.activity.setValue(this.currentActivity.activity);
      this.form.controls.personInvolved.setValue(getActivityPersonInvolvedArray(this.currentActivity));
      this.form.controls.method.setValue(this.currentActivity.method);
      this.form.controls.place.setValue(this.currentActivity.place);
      this.form.controls.activityMinutesSpent.setValue(this.currentActivity.activityMinutesSpent);
      this.form.controls.travelMinutesSpent.setValue(this.currentActivity.travelMinutesSpent);
      this.form.controls.activityDescription.setValue(this.currentActivity.activityDescription);
      this.updateActivityDescriptionValidators();
      this.form.markAllAsTouched();
    } else {
      this.currentActivity = {
        index: null,
        activityDate: null,
        activity: null,
        personInvolvedDisplay: null,
        personInvolvedYouth: false,
        personInvolvedRelativeNrefm: false,
        personInvolvedIntakeCoordinator: false,
        personInvolvedSubstituteCareProvider: false,
        personInvolvedSocialWorker: false,
        personInvolvedDoj: false,
        personInvolvedTribe: false,
        personInvolvedSupervisor: false,
        personInvolvedOther: false,
        personInvolvedNone: false,
        method: null,
        place: null,
        activityMinutesSpent: null,
        travelMinutesSpent: null,
        activityDescription: null
      };
    }

    this.formChangeSubscriptions();
  }

  formChangeSubscriptions(): void {
    this.form.controls.activityDate.valueChanges.subscribe(activityDate => {
      const numberValue = activityDate !== null ? parseInt(activityDate.format('x')) : null;
      if (this.currentActivity.activityDate !== numberValue) {
        this.currentActivity.activityDate = numberValue;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activity.valueChanges.subscribe(activity => {
      if (this.currentActivity.activity !== activity) {
        this.currentActivity.activity = activity;
        this.data.updateCurrentActivity(this.currentActivity);
        this.updateActivityDescriptionValidators();
      }
    });

    this.form.controls.personInvolved.valueChanges.subscribe(personInvolved => {
      let personInvolvedYouth = false;
      let personInvolvedRelativeNrefm = false;
      let personInvolvedIntakeCoordinator = false;
      let personInvolvedSubstituteCareProvider = false;
      let personInvolvedSocialWorker = false;
      let personInvolvedDoj = false;
      let personInvolvedTribe = false;
      let personInvolvedSupervisor = false;
      let personInvolvedOther = false;
      let personInvolvedNone = false;

      if (isArray(personInvolved)) {
        (personInvolved as string[]).forEach(personInvolved => {
          switch (personInvolved) {
            case Humboldt2020PlacementActivityPersonInvolvedOption.Youth:
              personInvolvedYouth = true;
              break;
            case Humboldt2020PlacementActivityPersonInvolvedOption.RelativeNrefm:
              personInvolvedRelativeNrefm = true;
              break;
            case Humboldt2020PlacementActivityPersonInvolvedOption.IntakeCoordinator:
              personInvolvedIntakeCoordinator = true;
              break;
            case Humboldt2020PlacementActivityPersonInvolvedOption.SubstituteCareProvider:
              personInvolvedSubstituteCareProvider = true;
              break;
            case Humboldt2020PlacementActivityPersonInvolvedOption.SocialWorker:
              personInvolvedSocialWorker = true;
              break;
            case Humboldt2020PlacementActivityPersonInvolvedOption.Doj:
              personInvolvedDoj = true;
              break;
            case Humboldt2020PlacementActivityPersonInvolvedOption.Tribe:
              personInvolvedTribe = true;
              break;
            case Humboldt2020PlacementActivityPersonInvolvedOption.Supervisor:
              personInvolvedSupervisor = true;
              break;
            case Humboldt2020PlacementActivityPersonInvolvedOption.Other:
              personInvolvedOther = true;
              break;
            case Humboldt2020PlacementActivityPersonInvolvedOption.None:
              personInvolvedNone = true;
              break;
          }
        })
      }

      let saveCurrentFormValues = false;
      if (this.currentActivity && this.currentActivity.personInvolvedYouth !== personInvolvedYouth) {
        this.currentActivity.personInvolvedYouth = personInvolvedYouth;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedRelativeNrefm !== personInvolvedRelativeNrefm) {
        this.currentActivity.personInvolvedRelativeNrefm = personInvolvedRelativeNrefm;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedIntakeCoordinator !== personInvolvedIntakeCoordinator) {
        this.currentActivity.personInvolvedIntakeCoordinator = personInvolvedIntakeCoordinator;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedSubstituteCareProvider !== personInvolvedSubstituteCareProvider) {
        this.currentActivity.personInvolvedSubstituteCareProvider = personInvolvedSubstituteCareProvider;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedSocialWorker !== personInvolvedSocialWorker) {
        this.currentActivity.personInvolvedSocialWorker = personInvolvedSocialWorker;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedDoj !== personInvolvedDoj) {
        this.currentActivity.personInvolvedDoj = personInvolvedDoj;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedTribe !== personInvolvedTribe) {
        this.currentActivity.personInvolvedTribe = personInvolvedTribe;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedSupervisor !== personInvolvedSupervisor) {
        this.currentActivity.personInvolvedSupervisor = personInvolvedSupervisor;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedOther !== personInvolvedOther) {
        this.currentActivity.personInvolvedOther = personInvolvedOther;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedNone !== personInvolvedNone) {
        this.currentActivity.personInvolvedNone = personInvolvedNone;
        saveCurrentFormValues = true;
      }
      const personInvolvedArray = getActivityPersonInvolvedArray(this.currentActivity);
      const personInvolvedDisplay = personInvolvedArray.length ? personInvolvedArray.join(', ') : null;
      if (this.currentActivity && this.currentActivity.personInvolvedDisplay !== personInvolvedDisplay) {
        this.currentActivity.personInvolvedDisplay = personInvolvedDisplay;
        saveCurrentFormValues = true;
      }
      if (saveCurrentFormValues) {
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.method.valueChanges.subscribe(method => {
      if (this.currentActivity.method !== method) {
        this.currentActivity.method = method;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.place.valueChanges.subscribe(place => {
      if (this.currentActivity.place !== place) {
        this.currentActivity.place = place;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityMinutesSpent.valueChanges.subscribe(activityMinutesSpent => {
      if (this.currentActivity.activityMinutesSpent !== activityMinutesSpent) {
        this.currentActivity.activityMinutesSpent = activityMinutesSpent;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.travelMinutesSpent.valueChanges.subscribe(travelMinutesSpent => {
      if (this.currentActivity.travelMinutesSpent !== travelMinutesSpent) {
        this.currentActivity.travelMinutesSpent = travelMinutesSpent;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityDescription.valueChanges.subscribe(activityDescription => {
      if (this.currentActivity.activityDescription !== activityDescription) {
        this.currentActivity.activityDescription = activityDescription;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });
  }

  /* updates the validators for activityDescription */
  updateActivityDescriptionValidators(): void {
    if (this.currentActivity && this.currentActivity.activity === Humboldt2020PlacementActivityActivityOption.Other) {
      this.form.controls.activityDescription.setValidators([Validators.required, Validators.maxLength(500)]);
    } else {
      this.form.controls.activityDescription.setValidators(Validators.maxLength(500));
    }
    this.form.controls.activityDescription.updateValueAndValidity();
  }

  updateActivityList(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dialogRef.close(this.currentActivity);
    }
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
