import { Humboldt2020ReferralInvestigationActivityActivityOption } from './humboldt2020-referral-investigation-activity-dialog/humboldt2020-referral-investigation-activity-activity-option.enum';
import { Humboldt2020ReferralInvestigationActivityMethodOption } from './humboldt2020-referral-investigation-activity-dialog/humboldt2020-referral-investigation-activity-method-option.enum';
import { Humboldt2020ReferralInvestigationActivityPlaceOption } from './humboldt2020-referral-investigation-activity-dialog/humboldt2020-referral-investigation-activity-place-option.enum';
import { Humboldt2020ReferralInvestigationActivityPersonInvolvedOption } from './humboldt2020-referral-investigation-activity-dialog/humboldt2020-referral-investigation-activity-person-involved-option.enum';
import { YesNoOption } from '../../../form/yes-no-option.enum';

export interface Humboldt2020ReferralInvestigationActivityData {
  index: number | null; // Used to store the index when passing data to the dialog
  activityDate: number | null; // unix timestamp in milliseconds
  activity: Humboldt2020ReferralInvestigationActivityActivityOption | null;
  personInvolvedDisplay: string | null;
  personInvolvedChildYouth: boolean;
  personInvolvedParentGuardian: boolean;
  personInvolvedTribe: boolean;
  personInvolvedSubstituteCareProvider: boolean;
  personInvolvedCollateralReporter: boolean;
  personInvolvedSupervisor: boolean;
  personInvolvedOtherCwsStaff: boolean;
  personInvolvedOther: boolean;
  personInvolvedNone: boolean;
  withReferralTeammate: YesNoOption | null;
  method: Humboldt2020ReferralInvestigationActivityMethodOption | null;
  place: Humboldt2020ReferralInvestigationActivityPlaceOption | null;
  activityMinutesSpent: number | null;
  travelMinutesSpent: number | null;
  activityDescription: string | null;
}

export function getActivityPersonInvolvedArray(activity: Humboldt2020ReferralInvestigationActivityData): string[] {
  const values: Array<string> = [];
  if (activity.personInvolvedChildYouth) {
    values.push(Humboldt2020ReferralInvestigationActivityPersonInvolvedOption.ChildYouth);
  }
  if (activity.personInvolvedParentGuardian) {
    values.push(Humboldt2020ReferralInvestigationActivityPersonInvolvedOption.ParentGuardian);
  }
  if (activity.personInvolvedTribe) {
    values.push(Humboldt2020ReferralInvestigationActivityPersonInvolvedOption.Tribe);
  }
  if (activity.personInvolvedSubstituteCareProvider) {
    values.push(Humboldt2020ReferralInvestigationActivityPersonInvolvedOption.SubstituteCareProvider);
  }
  if (activity.personInvolvedCollateralReporter) {
    values.push(Humboldt2020ReferralInvestigationActivityPersonInvolvedOption.CollateralReporter);
  }
  if (activity.personInvolvedSupervisor) {
    values.push(Humboldt2020ReferralInvestigationActivityPersonInvolvedOption.Supervisor);
  }
  if (activity.personInvolvedOtherCwsStaff) {
    values.push(Humboldt2020ReferralInvestigationActivityPersonInvolvedOption.OtherCwsStaff);
  }
  if (activity.personInvolvedOther) {
    values.push(Humboldt2020ReferralInvestigationActivityPersonInvolvedOption.Other);
  }
  if (activity.personInvolvedNone) {
    values.push(Humboldt2020ReferralInvestigationActivityPersonInvolvedOption.None);
  }
  return values;
}
