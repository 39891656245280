export enum Humboldt2020ReferralInvestigationActivityPersonInvolvedOption {
  ChildYouth = 'Child/youth',
  ParentGuardian = 'Parent/guardian',
  Tribe = 'Tribe',
  SubstituteCareProvider = 'Substitute care provider',
  CollateralReporter = 'Collateral/reporter',
  Supervisor = 'Supervisor',
  OtherCwsStaff = 'Other CWS staff',
  None = 'None',
  Other = 'Other'
}
