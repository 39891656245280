import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { FormListComponent } from './form-list/form-list.component';
import { LoginComponent } from './login/login.component';
import { FormListFilterDialogComponent } from './form-list/form-list-filter-dialog/form-list-filter-dialog.component';
import { FormDuplicateCreateWarningDialogComponent } from './form-list/form-duplicate-create-warning-dialog/form-duplicate-create-warning-dialog.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MAT_LABEL_GLOBAL_OPTIONS } from '@angular/material';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthTokenInterceptor } from './interceptor/auth-token-interceptor';
import { SharedModule } from './shared.module';
import { Doc2019Module } from './project/doc2019/doc2019.module';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormListUpdatedAfterOfflineSaveWarningDialogComponent } from './form-list/form-list-updated-after-offline-save-warning-dialog/form-list-updated-after-offline-save-warning-dialog.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { Humboldt2020Module } from './project/humboldt2020/humboldt2020.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { Mddjs2020Module } from './project/mddjs2020/mddjs2020.module';

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    FormListComponent,
    LoginComponent,
    FormListFilterDialogComponent,
    FormDuplicateCreateWarningDialogComponent,
    FormListUpdatedAfterOfflineSaveWarningDialogComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    Doc2019Module,
    Humboldt2020Module,
    Mddjs2020Module,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    })
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline'
      }
    },
    {
      provide: MAT_LABEL_GLOBAL_OPTIONS,
      useValue: {
        float: 'always'
      }
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true}
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    FormDuplicateCreateWarningDialogComponent,
    FormListFilterDialogComponent,
    FormListUpdatedAfterOfflineSaveWarningDialogComponent
  ]
})
export class AppModule { }
