<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>
    {{data.message}}
  </p>
  <ul *ngIf="data.messageList">
    <li *ngFor="let item of data.messageList">{{item}}</li>
  </ul>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()" *ngIf="!data.hideCancel">{{data.cancelButtonLabel}}</button>
  <button mat-raised-button color="primary" (click)="ok()" *ngIf="!data.hideOk">{{data.okButtonLabel}}</button>
</div>
