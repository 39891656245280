import { SharedFormData } from '../../../form/shared-form-data';
import { UserData } from '../../../auth/user-data';
import { YesNoOption } from '../../../form/yes-no-option.enum';
import { ApiSaveSurveyData } from '../../../api/api-save-survey-data';
import { ApiGetSurveyResponse } from '../../../api/api-get-survey-response';
import { isArray } from "util";
import { Humboldt2020IntakeScreeningActivityData, getActivityPersonInvolvedArray } from './humboldt2020-intake-screening-activity-data';
import { Humboldt2020IntakeScreeningReferralResponseTypeOption } from './humboldt2020-intake-screening-referral-response-type-option.enum';
import { Humboldt2020IntakeScreeningTribalAffiliationOption } from './humboldt2020-intake-screening-tribal-affiliation-option.enum';
import { Humboldt2020IntakeScreeningNumberOfAllegedVictimsOption } from './humboldt2020-intake-screening-number-of-alleged-victims-option.enum';

export class Humboldt2020IntakeScreeningFormData extends SharedFormData {
  referralId: string;
  referralResponseType: Humboldt2020IntakeScreeningReferralResponseTypeOption | null;
  reportByMandatedReporter: YesNoOption | null;
  tribalAffiliation: Humboldt2020IntakeScreeningTribalAffiliationOption | null;
  tribalAffiliationTypeLocalTribe: boolean;
  tribalAffiliationTypeNonLocalTribe: boolean;
  numberOfAllegedVictims: Humboldt2020IntakeScreeningNumberOfAllegedVictimsOption | null;
  comments: string | null;

  // currentActivity should only be used by the current form when an activity is open and being edited
  currentActivity: Humboldt2020IntakeScreeningActivityData | null;
  activities: Humboldt2020IntakeScreeningActivityData[];

  standardsMet: YesNoOption | null;
  reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet: boolean;
  reasonsStandardsNotMetCaseworkerStoppedTracking: boolean;
  reasonsStandardsNotMetNotAllWorkDuringMonthRecorded: boolean;
  reasonsStandardsNotMetOther: boolean;
  supervisorComments: string | null;

  constructor(data: any) {
    super(data);

    this.referralId = data.referralId || '';
    this.referralResponseType = data.referralResponseType || null;
    this.reportByMandatedReporter = data.reportByMandatedReporter || null;
    this.tribalAffiliation = data.tribalAffiliation || null;
    this.tribalAffiliationTypeLocalTribe = data.tribalAffiliationTypeLocalTribe || false;
    this.tribalAffiliationTypeNonLocalTribe = data.tribalAffiliationTypeNonLocalTribe || false;
    this.numberOfAllegedVictims = data.numberOfAllegedVictims || null;
    this.comments = data.comments || null;

    this.currentActivity = data.currentActivity || null;
    this.activities = data.activities || [];

    this.standardsMet = data.standardsMet || null;
    this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = data.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet || false;
    this.reasonsStandardsNotMetCaseworkerStoppedTracking = data.reasonsStandardsNotMetCaseworkerStoppedTracking || false;
    this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = data.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded || false;
    this.reasonsStandardsNotMetOther = data.reasonsStandardsNotMetOther || false;
    this.supervisorComments = data.supervisorComments || null;
  }

  /* Creates a new Humboldt2020IntakeScreeningFormData class from the data */
  static createNewForm(id: number, formTypeId: number, user: UserData, timestamp: number): Humboldt2020IntakeScreeningFormData {
    return new Humboldt2020IntakeScreeningFormData(SharedFormData.newFormData(id, formTypeId, user, timestamp));
  }

  /* creates the object to store in the database */
  toDatabaseObject(): object {
    const databaseObject: any = {
      referralId: this.referralId,
      referralResponseType: this.referralResponseType,
      reportByMandatedReporter: this.reportByMandatedReporter,
      tribalAffiliation: this.tribalAffiliation,
      tribalAffiliationTypeLocalTribe: this.tribalAffiliationTypeLocalTribe,
      tribalAffiliationTypeNonLocalTribe: this.tribalAffiliationTypeNonLocalTribe,
      numberOfAllegedVictims: this.numberOfAllegedVictims,
      comments: this.comments,

      currentActivity: this.currentActivity,

      standardsMet: this.standardsMet,
      reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet: this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet,
      reasonsStandardsNotMetCaseworkerStoppedTracking: this.reasonsStandardsNotMetCaseworkerStoppedTracking,
      reasonsStandardsNotMetNotAllWorkDuringMonthRecorded: this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded,
      reasonsStandardsNotMetOther: this.reasonsStandardsNotMetOther,
      supervisorComments: this.supervisorComments
    };

    if (this.activities.length > 0) {
      databaseObject.activities = this.activities;
    }

    return Object.assign({}, super.toDatabaseObject(), databaseObject);
  }

  /* Creates a Humboldt2020IntakeScreeningFormData class given the string stored in the database */
  static createFromDatabaseString(data: string): Humboldt2020IntakeScreeningFormData {
    return new Humboldt2020IntakeScreeningFormData(JSON.parse(data));
  }

  /* creates a data server object to send to the server */
  toServerObject(): ApiSaveSurveyData {
    const serverObject = super.toServerObject();

    SharedFormData.addSurveyItem(serverObject, 'ReferralId', this.referralId);
    SharedFormData.addSurveyItem(serverObject, 'ReferralResponseType', this.referralResponseType);
    SharedFormData.addSurveyItem(serverObject, 'ReportByMandatedReporter', this.reportByMandatedReporter);
    SharedFormData.addSurveyItem(serverObject, 'TribalAffiliation', this.tribalAffiliation);
    SharedFormData.addBooleanSurveyItem(serverObject, 'TribalAffiliationTypeLocalTribe',
      this.tribalAffiliation === Humboldt2020IntakeScreeningTribalAffiliationOption.YesMaybe ? this.tribalAffiliationTypeLocalTribe : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'TribalAffiliationTypeNonLocalTribe',
      this.tribalAffiliation === Humboldt2020IntakeScreeningTribalAffiliationOption.YesMaybe ? this.tribalAffiliationTypeNonLocalTribe : false);
    SharedFormData.addSurveyItem(serverObject, 'NumberOfAllegedVictims', this.numberOfAllegedVictims);
    SharedFormData.addSurveyItem(serverObject, 'Comments', this.comments);

    this.activities.forEach((activity, index) => {
      SharedFormData.addTimestampSurveyItem(serverObject, 'ActivityDate', activity.activityDate, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityActivity', activity.activity, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedReporter', activity.personInvolvedReporter, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedSupervisor', activity.personInvolvedSupervisor, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedCwsStaff', activity.personInvolvedCwsStaff, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedCollateral', activity.personInvolvedCollateral, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedLocalTribe', activity.personInvolvedLocalTribe, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedNonLocalTribe', activity.personInvolvedNonLocalTribe, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOther', activity.personInvolvedOther, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedNone', activity.personInvolvedNone, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityMethod', activity.method, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityPlace', activity.place, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityActivityMinutesSpent', activity.activityMinutesSpent, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityDescription', activity.activityDescription, index);
    });

    SharedFormData.addSurveyItem(serverObject, 'StandardsMet', this.standardsMet);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetCaseworkerStoppedTracking',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetCaseworkerStoppedTracking : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetNotAllWorkDuringMonthRecorded',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetOther',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetOther : false);
    SharedFormData.addSurveyItem(serverObject, 'SupervisorComments', this.supervisorComments);

    return serverObject;
  }

  /* creates this class from a data server object
  * fullRecord is false for records created from api/getlist */
  static createFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): Humboldt2020IntakeScreeningFormData {
    return new Humboldt2020IntakeScreeningFormData(Humboldt2020IntakeScreeningFormData.createDataFromServerObject(serverObject, fullRecord));
  }

  /* creates the data object to parse into a new class instance, exposed as a separate function so child classes can use it
  * fullRecord is false for records created from api/getlist */
  static createDataFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): any {
    const data = SharedFormData.createDataFromServerObject(serverObject, fullRecord);

    if (serverObject.SurveyItems && isArray(serverObject.SurveyItems)) {
      serverObject.SurveyItems.forEach(item => {
        let itemIndex = 0;
        switch (item.ItemName) {
          case 'ReferralId':
            data.referralId = item.ItemValue;
            break;
          case 'ReferralResponseType':
            data.referralResponseType = item.ItemValue;
            break;
          case 'ReportByMandatedReporter':
            data.reportByMandatedReporter = item.ItemValue;
            break;
          case 'TribalAffiliation':
            data.tribalAffiliation = item.ItemValue;
            break;
          case 'TribalAffiliationTypeLocalTribe':
            data.tribalAffiliationTypeLocalTribe = (item.ItemValue === 'true');
            break;
          case 'TribalAffiliationTypeNonLocalTribe':
            data.tribalAffiliationTypeNonLocalTribe = (item.ItemValue === 'true');
            break;
          case 'NumberOfAllegedVictims':
            data.numberOfAllegedVictims = item.ItemValue;
            break;
          case 'Comments':
            data.comments = item.ItemValue;
            break;

          case 'ActivityDate':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IntakeScreeningFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'ActivityActivity':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IntakeScreeningFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activity = item.ItemValue;
            break;
          case 'ActivityPersonInvolvedReporter':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IntakeScreeningFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedReporter = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedSupervisor':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IntakeScreeningFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedSupervisor = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedCwsStaff':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IntakeScreeningFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedCwsStaff = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedCollateral':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IntakeScreeningFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedCollateral = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedLocalTribe':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IntakeScreeningFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedLocalTribe = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedNonLocalTribe':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IntakeScreeningFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedNonLocalTribe = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOther':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IntakeScreeningFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOther = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedNone':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IntakeScreeningFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedNone = (item.ItemValue === 'true');
            break;
          case 'ActivityMethod':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IntakeScreeningFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].method = item.ItemValue;
            break;
          case 'ActivityPlace':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IntakeScreeningFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].place = item.ItemValue;
            break;
          case 'ActivityActivityMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IntakeScreeningFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityDescription':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IntakeScreeningFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDescription = item.ItemValue;
            break;

          case 'StandardsMet':
            data.standardsMet = item.ItemValue;
            break;
          case 'ReasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet':
            data.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetCaseworkerStoppedTracking':
            data.reasonsStandardsNotMetCaseworkerStoppedTracking = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetNotAllWorkDuringMonthRecorded':
            data.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetOther':
            data.reasonsStandardsNotMetOther = (item.ItemValue === 'true');
            break;
          case 'SupervisorComments':
            data.supervisorComments = item.ItemValue;
            break;

          default:
            console.error('Unknown item name (' + item.ItemName + ') while parsing SurveyItems');
        }
      });
    }

    if (data.activities && isArray(data.activities)) {
      data.activities.forEach(activity => {
        const personInvolvedArray = getActivityPersonInvolvedArray(activity);
        activity.personInvolvedDisplay = personInvolvedArray.length ? personInvolvedArray.join(', ') : null;
      })
    }

    return data;
  }

  /* creates empty placeholders in the activities array on data up to index */
  static setDataActivityCount(data: any, index: number) {
    if (!data.activities) data.activities = [];
    while (data.activities.length <= index) {
      const newActivity: Humboldt2020IntakeScreeningActivityData = {
        index: data.activities.length,
        activityDate: null,
        activity: null,
        personInvolvedDisplay: null,
        personInvolvedReporter: false,
        personInvolvedSupervisor: false,
        personInvolvedCwsStaff: false,
        personInvolvedCollateral: false,
        personInvolvedLocalTribe: false,
        personInvolvedNonLocalTribe: false,
        personInvolvedOther: false,
        personInvolvedNone: false,
        method: null,
        place: null,
        activityMinutesSpent: null,
        activityDescription: null
      };
      data.activities.push(newActivity);
    }
  }
}
