import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormListFilterDialogData } from './form-list-filter-dialog-data';
import { UserRole } from '../../auth/user-role.enum';

@Component({
  selector: 'app-form-list-filter-dialog',
  templateUrl: './form-list-filter-dialog.component.html',
  styleUrls: ['./form-list-filter-dialog.component.css']
})
export class FormListFilterDialogComponent {
  UserRole = UserRole;
  constructor(
    public dialogRef: MatDialogRef<FormListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormListFilterDialogData
  ) { }

  clear(): void {
    this.data.clearFunction();
  }

  close(): void {
    this.dialogRef.close();
  }
}
