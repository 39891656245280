import { Humboldt2020CourtIntakeActivityPersonInvolvedOption } from './humboldt2020-court-intake-activity-dialog/humboldt2020-court-intake-activity-person-involved-option.enum';
import { Humboldt2020CourtIntakeActivityActivityOption } from './humboldt2020-court-intake-activity-dialog/humboldt2020-court-intake-activity-activity-option.enum';
import { Humboldt2020CourtIntakeActivityMethodOption } from './humboldt2020-court-intake-activity-dialog/humboldt2020-court-intake-activity-method-option.enum';
import { Humboldt2020CourtIntakeActivityPlaceOption } from './humboldt2020-court-intake-activity-dialog/humboldt2020-court-intake-activity-place-option.enum';

export interface Humboldt2020CourtIntakeActivityData {
  index: number | null; // Used to store the index when passing data to the dialog
  activityDate: number | null; // unix timestamp in milliseconds
  activity: Humboldt2020CourtIntakeActivityActivityOption | null;
  personInvolvedDisplay: string | null;
  personInvolvedChildYouth: boolean;
  personInvolvedParentGuardian: boolean;
  personInvolvedTribe: boolean;
  personInvolvedCourtPersonnel: boolean;
  personInvolvedCountyCounsel: boolean;
  personInvolvedSubstituteCareProvider: boolean;
  personInvolvedCollateralReporter: boolean;
  personInvolvedSupervisor: boolean;
  personInvolvedOtherCwsStaff: boolean;
  personInvolvedOther: boolean;
  personInvolvedNone: boolean;
  method: Humboldt2020CourtIntakeActivityMethodOption | null;
  place: Humboldt2020CourtIntakeActivityPlaceOption | null;
  activityMinutesSpent: number | null;
  travelMinutesSpent: number | null;
  activityDescription: string | null;
}

export function getActivityPersonInvolvedArray(activity: Humboldt2020CourtIntakeActivityData): string[] {
  const values: Array<string> = [];
  if (activity.personInvolvedChildYouth) {
    values.push(Humboldt2020CourtIntakeActivityPersonInvolvedOption.ChildYouth);
  }
  if (activity.personInvolvedParentGuardian) {
    values.push(Humboldt2020CourtIntakeActivityPersonInvolvedOption.ParentGuardian);
  }
  if (activity.personInvolvedTribe) {
    values.push(Humboldt2020CourtIntakeActivityPersonInvolvedOption.Tribe);
  }
  if (activity.personInvolvedCourtPersonnel) {
    values.push(Humboldt2020CourtIntakeActivityPersonInvolvedOption.CourtPersonnel);
  }
  if (activity.personInvolvedCountyCounsel) {
    values.push(Humboldt2020CourtIntakeActivityPersonInvolvedOption.CountyCounsel);
  }
  if (activity.personInvolvedSubstituteCareProvider) {
    values.push(Humboldt2020CourtIntakeActivityPersonInvolvedOption.SubstituteCareProvider);
  }
  if (activity.personInvolvedCollateralReporter) {
    values.push(Humboldt2020CourtIntakeActivityPersonInvolvedOption.CollateralReporter);
  }
  if (activity.personInvolvedSupervisor) {
    values.push(Humboldt2020CourtIntakeActivityPersonInvolvedOption.Supervisor);
  }
  if (activity.personInvolvedOtherCwsStaff) {
    values.push(Humboldt2020CourtIntakeActivityPersonInvolvedOption.OtherCwsStaff);
  }
  if (activity.personInvolvedOther) {
    values.push(Humboldt2020CourtIntakeActivityPersonInvolvedOption.Other);
  }
  if (activity.personInvolvedNone) {
    values.push(Humboldt2020CourtIntakeActivityPersonInvolvedOption.None);
  }
  return values;
}
