import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../database/database.service";
import * as i2 from "@angular/material/dialog";
export class UnsavedChangesGuardService {
    constructor(databaseService, dialog) {
        this.databaseService = databaseService;
        this.dialog = dialog;
    }
    canDeactivate(component) {
        if (component.hasUnsavedChanges) {
            const dialogData = {
                title: 'Discard Unsaved Changes',
                message: 'You have unsaved changes on the form. You will lose these changes if you leave this page. Are you sure?',
                okButtonLabel: 'Discard Unsaved Changes'
            };
            return this.dialog.open(MessageDialogComponent, {
                data: dialogData,
                width: '800px'
            }).afterClosed().pipe(map(result => {
                if (result) {
                    component.hasUnsavedChanges = false;
                    this.databaseService.deleteCurrentForm();
                    return true;
                }
                return false;
            }));
        }
        return true;
    }
}
UnsavedChangesGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UnsavedChangesGuardService_Factory() { return new UnsavedChangesGuardService(i0.ɵɵinject(i1.DatabaseService), i0.ɵɵinject(i2.MatDialog)); }, token: UnsavedChangesGuardService, providedIn: "root" });
