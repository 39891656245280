import { Injectable } from '@angular/core';
import { ApiGetSurveyResponse } from './api-get-survey-response';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { ApiSaveSurveyResponse } from './api-save-survey-response';
import { ApiSaveSurveyData } from './api-save-survey-data';
import { UserRole } from '../auth/user-role.enum';
import { ApiAdminGetSurveyFilter } from './api-admin-get-survey-filter';
import { ApiAdminGetSurveyResponse } from './api-admin-get-survey-response';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  async apiGetList(): Promise<ApiGetSurveyResponse[]> {
    if (this.authService.user === null) {
      throw('No logged in user found.');
    }
    let getListResponse: ApiGetSurveyResponse[] | null = null;
    try {
      if (this.authService.user && this.authService.user.role === UserRole.Supervisor) {
        getListResponse = await this.http.get<ApiGetSurveyResponse[]>(environment.apiGetListSupervisor).toPromise();
      } else {
        getListResponse = await this.http.get<ApiGetSurveyResponse[]>(environment.apiGetList).toPromise();
      }
    } catch (error) {
      console.error('Error loading the form list', error);
      if (error.status) {
        switch (error.status) {
          case 401: // Unauthorized
            throw('Data server returned an unauthorized error (401) loading the form list.');
          case 500: // Server Error
            throw('Data server returned an internal server error (500) loading the form list.');
          case 504: // Error Generated by the Service worker
            throw('There was an error communicating with the data server while loading the form list.');
          default:
            throw('Data server returned an error (' + error.status + ') while loading the form list.');
        }
      }
      throw('Unknown error occurred while loading the form list from the data server.');
    }
    if (getListResponse === null) {
      throw('Unknown error occurred while loading the form list from the data server.');
    }

    return getListResponse;
  }

  async apiGetAdminList(page: number, filter: ApiAdminGetSurveyFilter): Promise<ApiAdminGetSurveyResponse> {
    if (this.authService.user === null) {
      throw('No logged in user found.');
    }
    let getListResponse: ApiAdminGetSurveyResponse | null = null;
    try {
      getListResponse = await this.http.post<ApiAdminGetSurveyResponse>(environment.apiGetListAdmin + page.toString(), filter).toPromise();
    } catch (error) {
      console.error('Error loading the admin form list', error);
      if (error.status) {
        switch (error.status) {
          case 401: // Unauthorized
            throw('Data server returned an unauthorized error (401) loading the admin form list.');
          case 500: // Server Error
            throw('Data server returned an internal server error (500) loading the admin form list.');
          case 504: // Error Generated by the Service worker
            throw('There was an error communicating with the data server while loading the admin form list.');
          default:
            throw('Data server returned an error (' + error.status + ') while loading the admin form list.');
        }
      }
      throw('Unknown error occurred while loading the admin form list from the data server.');
    }
    if (getListResponse === null) {
      throw('Unknown error occurred while loading the admin form list from the data server.');
    }

    return getListResponse;
  }

  async apiGetSurvey(formId: number): Promise<ApiGetSurveyResponse> {
    if (this.authService.user === null) {
      throw('No logged in user found.');
    }
    let getSurveyResponse: ApiGetSurveyResponse | null = null;
    try {
      getSurveyResponse = await this.http.get<ApiGetSurveyResponse>(environment.apiGetSurvey + formId.toString()).toPromise();
    } catch (error) {
      console.error('Error loading the form', error);
      if (error.status) {
        switch (error.status) {
          case 401: // Unauthorized
            throw('Data server returned an unauthorized error (401) loading the form.');
          case 500: // Server Error
            throw('Data server returned an internal server error (500) loading the form.');
          case 504: // Error Generated by the Service worker
            throw('There was an error communicating with the data server while loading the form.');
          default:
            throw('Data server returned an error (' + error.status + ') while loading the form.');
        }
      }
      throw('Unknown error occurred while loading the form from the data server.');
    }
    if (getSurveyResponse === null) {
      throw('Unknown error occurred while loading the form from the data server.');
    }

    return getSurveyResponse;
  }

  async saveSurvey(saveData: ApiSaveSurveyData): Promise<ApiSaveSurveyResponse> {
    if (this.authService.user === null) {
      throw('No logged in user found.');
    }
    let saveSurveyResponse: ApiSaveSurveyResponse | null = null;
    try {
      saveSurveyResponse = await this.http.post<ApiSaveSurveyResponse>(environment.apiSaveSurvey, saveData).toPromise();
    } catch (error) {
      console.error('Error saving the form', error);
      if (error.status) {
        switch (error.status) {
          case 401: // Unauthorized
            throw('Data server returned an unauthorized error (401) while saving the form.');
          case 500: // Server Error
            throw('Data server returned an internal server error (500) while saving the form.');
          case 504: // Error Generated by the Service worker
            throw('There was an error communicating with the data server while saving the form.');
          default:
            throw('Data server returned an error (' + error.status + ') while saving the form.');
        }
      }
      throw('Unknown error occurred while saving the form to the data server.');
    }
    if (saveSurveyResponse === null) {
      throw('Unknown error occurred while saving the form to the data server.');
    }

    return saveSurveyResponse;
  }
}
