import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'errorDisplay'
})
export class ErrorDisplayPipe implements PipeTransform {
  transform(controlErrors: any): string | null {
    if (!controlErrors || typeof controlErrors !== 'object') return null;

    const errorKeys = Object.keys(controlErrors);
    if (errorKeys.length === 0) return null;

    const displayErrors: string[] = [];

    if (controlErrors.required) {
      displayErrors.push('This field is required');
    }

    if (controlErrors.maxlength) {
      displayErrors.push('This field has a max length of ' + controlErrors.maxlength.requiredLength + ', but has ' + controlErrors.maxlength.actualLength + ' characters');
    }

    if (controlErrors.digitsOnly) {
      displayErrors.push('This field may only contain digits (0-9)');
    }

    if (controlErrors.Humboldt2020Id) {
      displayErrors.push('This field must be in the format 0000-0000-0000-0000000');
    }

    if (controlErrors.Humboldt2020FacilityNumber) {
      displayErrors.push('This field must be in the format 000000000 (9 numeric only digits)');
    }

    if (controlErrors.maxItems) {
      displayErrors.push('This field supports up to ' + controlErrors.maxItems.requiredLength + ' items selected, but ' + controlErrors.maxItems.actualLength + ' items are selected');
    }

    if (controlErrors.noneApply) {
      displayErrors.push('If None Apply is selected, it must be the only value selected');
    }

    if (controlErrors.none) {
      displayErrors.push('If None is selected, it must be the only value selected');
    }

    if (controlErrors.min) {
      displayErrors.push('The minimum value allowed is ' + controlErrors.min.min);
    }

    if (controlErrors.max) {
      displayErrors.push('The maximum value allowed is ' + controlErrors.max.max);
    }

    if (controlErrors.matDatepickerMin) {
      displayErrors.push('The date must be on or after ' + controlErrors.matDatepickerMin.min.format('L'))
    }

    if (controlErrors.matDatepickerMax) {
      displayErrors.push('The date must be on or before ' + controlErrors.matDatepickerMax.max.format('L'))
    }

    if (controlErrors.email) {
      displayErrors.push('This field must be a valid email address');
    }

    if (controlErrors.matDatepickerParse) {
      displayErrors.push(controlErrors.matDatepickerParse.text + ' is not a valid date');
    }

    if (controlErrors.passwordLength) {
      displayErrors.push('The password must be at least 6 characters');
    }

    if (controlErrors.passwordNumeric) {
      displayErrors.push('The password must be at least one number');
    }

    if (controlErrors.passwordLowercase) {
      displayErrors.push('The password must be at least one lowercase character');
    }

    if (controlErrors.passwordUppercase) {
      displayErrors.push('The password must be at least one uppercase character');
    }

    if (controlErrors.passwordNonAlpha) {
      displayErrors.push('The password must be at least one non-alphanumeric (not a letter or number) character');
    }

    if (controlErrors.passwordAmpersand) {
      displayErrors.push('The password may not contain an & character');
    }

    if (controlErrors.passwordDefault) {
      displayErrors.push('The password may not be set to the default password');
    }

    if (displayErrors.length !== errorKeys.length) {
      console.error('Unrecognized error in errorDisplayPipe', controlErrors);
      displayErrors.push('There is an unrecognized error')
    }

    return displayErrors.join(', ') + '.';
  }
}
