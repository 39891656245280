import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormListComponent } from './form-list/form-list.component';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { Doc2019OffenderSupervisionTimeComponent } from './project/doc2019/doc2019-offender-supervision-time/doc2019-offender-supervision-time.component';
import { Doc2019CaseSupportTimeComponent } from './project/doc2019/doc2019-case-support-time/doc2019-case-support-time.component';
import { Doc2019FieldInvestigationTimeComponent } from './project/doc2019/doc2019-field-investigation-time/doc2019-field-investigation-time.component';
import { UnsavedChangesGuardService } from './form/unsaved-changes-guard.service';
import { Humboldt2020CourtIntakeComponent } from './project/humboldt2020/humboldt2020-court-intake/humboldt2020-court-intake.component';
import { Humboldt2020IntakeScreeningComponent } from './project/humboldt2020/humboldt2020-intake-screening/humboldt2020-intake-screening.component';
import { Humboldt2020ReferralInvestigationComponent } from './project/humboldt2020/humboldt2020-referral-investigation/humboldt2020-referral-investigation.component';
import { Humboldt2020OngoingEfcAdoptComponent } from './project/humboldt2020/humboldt2020-ongoing-efc-adopt/humboldt2020-ongoing-efc-adopt.component';
import { Humboldt2020PlacementComponent } from './project/humboldt2020/humboldt2020-placement/humboldt2020-placement.component';
import { Humboldt2020ResourceFamilyApprovalComponent } from './project/humboldt2020/humboldt2020-resource-family-approval/humboldt2020-resource-family-approval.component';
import { Humboldt2020SupportAdminComponent } from './project/humboldt2020/humboldt2020-support-admin/humboldt2020-support-admin.component';
import { Humboldt2020IlsComponent } from './project/humboldt2020/humboldt2020-ils/humboldt2020-ils.component';
import { Mddjs2020IntakeComponent } from './project/mddjs2020/mddjs2020-intake/mddjs2020-intake.component';
import { Mddjs2020SupervisionComponent } from './project/mddjs2020/mddjs2020-supervision/mddjs2020-supervision.component';
import { Mddjs2020CaseSupportComponent } from './project/mddjs2020/mddjs2020-case-support/mddjs2020-case-support.component';
import { Mddjs2020CdEmComponent } from './project/mddjs2020/mddjs2020-cd-em/mddjs2020-cd-em.component';
import { Mddjs2020CdFloatingComponent } from './project/mddjs2020/mddjs2020-cd-floating/mddjs2020-cd-floating.component';

const routes: Routes = [
  {path: '', redirectTo: '/form-list', pathMatch: 'full'},
  {path: 'form-list', component: FormListComponent, canActivate: [AuthGuardService]}, // Title is set by component, to the user project name
  {path: 'login', component: LoginComponent, canActivate: [AuthGuardService], data: {title: 'NCCD Workload Study Login'}},
  {path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuardService], data: {title: 'Change Password'}},
  {path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [AuthGuardService], data: {title: 'Forgot Password'}},
  {path: 'reset-password', component: ResetPasswordComponent, canActivate: [AuthGuardService], data: {title: 'Reset Password'}},
  {path: 'form/1/:id', component: Doc2019CaseSupportTimeComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'Case Support and Non-Case Related Time'}},
  {path: 'form/2/:id', component: Doc2019FieldInvestigationTimeComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'Intake/Investigation Time'}},
  {path: 'form/3/:id', component: Doc2019OffenderSupervisionTimeComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'Offender Supervision Time'}},
  {path: 'form/4/:id', component: Humboldt2020CourtIntakeComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'Court Intake'}},
  {path: 'form/5/:id', component: Humboldt2020IntakeScreeningComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'Intake'}},
  {path: 'form/6/:id', component: Humboldt2020ReferralInvestigationComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'Referral'}},
  {path: 'form/7/:id', component: Humboldt2020OngoingEfcAdoptComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'Ongoing'}},
  {path: 'form/8/:id', component: Humboldt2020PlacementComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'Placement'}},
  {path: 'form/9/:id', component: Humboldt2020ResourceFamilyApprovalComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'Resource Family Approval (RFA)'}},
  {path: 'form/10/:id', component: Humboldt2020SupportAdminComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'Support and Administrative Activity'}},
  {path: 'form/11/:id', component: Humboldt2020IlsComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'ILS'}},
  {path: 'form/12/:id', component: Mddjs2020IntakeComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'Intake and Investigation'}},
  {path: 'form/13/:id', component: Mddjs2020SupervisionComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'Supervision'}},
  {path: 'form/14/:id', component: Mddjs2020CaseSupportComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'Case Support and Administrative Activity'}},
  {path: 'form/15/:id', component: Mddjs2020CdEmComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'CD/EM'}},
  {path: 'form/16/:id', component: Mddjs2020CdFloatingComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: {title: 'CD/EM Floating'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
