import { Mddjs2020Region } from './mddjs2020-region.enum';

interface OfficeData {
  region: Mddjs2020Region;
  name: string;
  cdEmOnly: boolean;
}

const offices: OfficeData[] = [
  {region: Mddjs2020Region.BaltimoreCity, name: 'Gay St', cdEmOnly: false},
  {region: Mddjs2020Region.BaltimoreCity, name: 'Reisterstown Plaza', cdEmOnly: false},
  {region: Mddjs2020Region.BaltimoreCity, name: 'MYCR', cdEmOnly: true},
  {region: Mddjs2020Region.BaltimoreCity, name: 'Hilton', cdEmOnly: false},
  {region: Mddjs2020Region.Central, name: 'Arbutus', cdEmOnly: false},
  {region: Mddjs2020Region.Central, name: 'Bel Air', cdEmOnly: false},
  {region: Mddjs2020Region.Central, name: 'Ellicott City', cdEmOnly: false},
  {region: Mddjs2020Region.Central, name: 'Essex', cdEmOnly: false},
  {region: Mddjs2020Region.Central, name: 'Garrison', cdEmOnly: false},
  {region: Mddjs2020Region.Central, name: 'Hunt Valley', cdEmOnly: false},
  {region: Mddjs2020Region.Central, name: 'Westminster', cdEmOnly: false},
  {region: Mddjs2020Region.EasternShore, name: 'Cambridge', cdEmOnly: false},
  {region: Mddjs2020Region.EasternShore, name: 'Centerville', cdEmOnly: false},
  {region: Mddjs2020Region.EasternShore, name: 'Chestertown', cdEmOnly: false},
  {region: Mddjs2020Region.EasternShore, name: 'Denton', cdEmOnly: false},
  {region: Mddjs2020Region.EasternShore, name: 'Easton', cdEmOnly: false},
  {region: Mddjs2020Region.EasternShore, name: 'Elkton', cdEmOnly: false},
  {region: Mddjs2020Region.EasternShore, name: 'Ocean City', cdEmOnly: false},
  {region: Mddjs2020Region.EasternShore, name: 'Princess Anne', cdEmOnly: false},
  {region: Mddjs2020Region.EasternShore, name: 'Salisbury', cdEmOnly: false},
  {region: Mddjs2020Region.EasternShore, name: 'Snow Hill', cdEmOnly: false},
  {region: Mddjs2020Region.Metro, name: 'Rockville', cdEmOnly: false},
  {region: Mddjs2020Region.Metro, name: 'Silver Spring', cdEmOnly: false},
  {region: Mddjs2020Region.Metro, name: 'Largo', cdEmOnly: false},
  {region: Mddjs2020Region.Metro, name: 'Upper Marlboro', cdEmOnly: false},
  {region: Mddjs2020Region.Southern, name: 'Annapolis', cdEmOnly: false},
  {region: Mddjs2020Region.Southern, name: 'Glen Burnie', cdEmOnly: false},
  {region: Mddjs2020Region.Southern, name: 'La Plata', cdEmOnly: false},
  {region: Mddjs2020Region.Southern, name: 'Leonardtown', cdEmOnly: false},
  {region: Mddjs2020Region.Southern, name: 'Prince Frederick', cdEmOnly: false},
  {region: Mddjs2020Region.Western, name: 'Cumberland', cdEmOnly: false},
  {region: Mddjs2020Region.Western, name: 'Frederick', cdEmOnly: false},
  {region: Mddjs2020Region.Western, name: 'Hagerstown', cdEmOnly: false},
  {region: Mddjs2020Region.Western, name: 'Oakland', cdEmOnly: false}
];

export function getOfficesForRegion(region: Mddjs2020Region): string[] {
  return offices.filter(office => office.region === region && !office.cdEmOnly).map(office => office.name);
}

export function getCdEmOfficesForRegion(region: Mddjs2020Region): string[] {
  return offices.filter(office => office.region === region).map(office => office.name);
}
