import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Mddjs2020IntakeActivityListFilterDialogData } from './mddjs2020-intake-activity-list-filter-dialog-data';

@Component({
  selector: 'app-mddjs2020-intake-activity-list-filter-dialog',
  templateUrl: './mddjs2020-intake-activity-list-filter-dialog.component.html',
  styleUrls: ['./mddjs2020-intake-activity-list-filter-dialog.component.css']
})
export class Mddjs2020IntakeActivityListFilterDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<Mddjs2020IntakeActivityListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Mddjs2020IntakeActivityListFilterDialogData
  ) { }

  clear(): void {
    this.data.clearFunction();
  }

  close(): void {
    this.dialogRef.close();
  }
}
