import { OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/service-worker";
export class OnlineService {
    constructor(dialog, swUpdate) {
        this.dialog = dialog;
        this.swUpdate = swUpdate;
        this.unsubscribe$ = new Subject();
        this.promptingForUpdate = false;
        this.online = new BehaviorSubject(navigator.onLine);
        fromEvent(window, 'online').pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.online.next(true);
        });
        fromEvent(window, 'offline').pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.online.next(false);
        });
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.pipe(takeUntil(this.unsubscribe$), filter(() => !this.promptingForUpdate)).subscribe(event => {
                this.promptingForUpdate = true;
                const dialogData = {
                    title: 'Update Available',
                    message: 'An update is available to the NCCD Workload Study application. Would you like to update?',
                    okButtonLabel: 'Update'
                };
                this.dialog.open(MessageDialogComponent, {
                    data: dialogData,
                    width: '800px'
                }).afterClosed().subscribe((result) => {
                    if (result) {
                        this.swUpdate.activateUpdate();
                    }
                    this.promptingForUpdate = false;
                });
            });
            this.swUpdate.activated.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
                window.location.reload();
            });
            // check for update every hour
            window.setInterval(this.checkForUpdate.bind(this), 60 * 60 * 1000);
        }
    }
    checkForUpdate() {
        if (this.online.value && this.swUpdate.isEnabled && !this.promptingForUpdate) {
            this.swUpdate.checkForUpdate();
        }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
OnlineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OnlineService_Factory() { return new OnlineService(i0.ɵɵinject(i1.MatDialog), i0.ɵɵinject(i2.SwUpdate)); }, token: OnlineService, providedIn: "root" });
