import { SharedFormData } from '../../../form/shared-form-data';
import { UserData } from '../../../auth/user-data';
import { YesNoOption } from '../../../form/yes-no-option.enum';
import { ApiSaveSurveyData } from '../../../api/api-save-survey-data';
import { ApiGetSurveyResponse } from '../../../api/api-get-survey-response';
import { isArray } from "util";
import { Humboldt2020ReferralInvestigationReferralTypeOption } from './humboldt2020-referral-investigation-referral-type-option.enum';
import { Humboldt2020ReferralInvestigationResponsePriorityOption } from './humboldt2020-referral-investigation-response-priority-option.enum';
import { Humboldt2020ReferralInvestigationTribalAffiliationOption } from './humboldt2020-referral-investigation-tribal-affiliation-option.enum';
import { Humboldt2020ReferralInvestigationNumberOfChildrenOption } from './humboldt2020-referral-investigation-number-of-children-option.enum';
import { Humboldt2020ReferralInvestigationInitialSdmSafetyDecisionOption } from './humboldt2020-referral-investigation-initial-sdm-safety-decision-option.enum';
import { Humboldt2020ReferralInvestigationFinalSdmSafetyDecisionOption } from './humboldt2020-referral-investigation-final-sdm-safety-decision-option.enum';
import { Humboldt2020ReferralInvestigationFinalSdmRiskLevelOption } from './humboldt2020-referral-investigation-final-sdm-risk-level-option.enum';
import { Humboldt2020ReferralInvestigationActivityData, getActivityPersonInvolvedArray } from './humboldt2020-referral-investigation-activity-data';

export class Humboldt2020ReferralInvestigationFormData extends SharedFormData {
  referralId: string;
  referralAssignmentDate: number | null;
  referralType: Humboldt2020ReferralInvestigationReferralTypeOption | null;
  responsePriority: Humboldt2020ReferralInvestigationResponsePriorityOption | null;
  tribalAffiliation: Humboldt2020ReferralInvestigationTribalAffiliationOption | null;
  tribalAffiliationTypeLocalTribe: boolean;
  tribalAffiliationTypeNonLocalTribe: boolean;
  numberOfChildrenInAllegationHousehold: Humboldt2020ReferralInvestigationNumberOfChildrenOption | null;
  comments: string | null;

  initialSdmSafetyDecision: Humboldt2020ReferralInvestigationInitialSdmSafetyDecisionOption | null;
  finalSdmSafetyDecision: Humboldt2020ReferralInvestigationFinalSdmSafetyDecisionOption | null;
  finalSdmRiskLevel: Humboldt2020ReferralInvestigationFinalSdmRiskLevelOption | null;
  allegationConclusionSubstantiated: boolean;
  allegationConclusionInconclusive: boolean;
  allegationConclusionUnfounded: boolean;
  allegationConclusionPending: boolean;
  referralOutcomeTransferToFamilyMaintenance: boolean;
  referralOutcomeTransferToCourtFamilyMaintenance: boolean;
  referralOutcomeTransferToFamilyReunification: boolean;
  referralOutcomeCloseNoFurtherAction: boolean;
  referralOutcomePending: boolean;

  // currentActivity should only be used by the current form when an activity is open and being edited
  currentActivity: Humboldt2020ReferralInvestigationActivityData | null;
  activities: Humboldt2020ReferralInvestigationActivityData[];

  standardsMet: YesNoOption | null;
  reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet: boolean;
  reasonsStandardsNotMetCaseworkerStoppedTracking: boolean;
  reasonsStandardsNotMetNotAllWorkDuringMonthRecorded: boolean;
  reasonsStandardsNotMetOther: boolean;
  supervisorComments: string | null;

  constructor(data: any) {
    super(data);

    this.referralId = data.referralId || '';
    this.referralAssignmentDate = data.referralAssignmentDate || null;
    this.referralType = data.referralType || null;
    this.responsePriority = data.responsePriority || null;
    this.tribalAffiliation = data.tribalAffiliation || null;
    this.tribalAffiliationTypeLocalTribe = data.tribalAffiliationTypeLocalTribe || false;
    this.tribalAffiliationTypeNonLocalTribe = data.tribalAffiliationTypeNonLocalTribe || false;
    this.numberOfChildrenInAllegationHousehold = data.numberOfChildrenInAllegationHousehold || null;
    this.comments = data.comments || null;

    this.initialSdmSafetyDecision = data.initialSdmSafetyDecision || null;
    this.finalSdmSafetyDecision = data.finalSdmSafetyDecision || null;
    this.finalSdmRiskLevel = data.finalSdmRiskLevel || null;
    this.allegationConclusionSubstantiated = data.allegationConclusionSubstantiated || false;
    this.allegationConclusionInconclusive = data.allegationConclusionInconclusive || false;
    this.allegationConclusionUnfounded = data.allegationConclusionUnfounded || false;
    this.allegationConclusionPending = data.allegationConclusionPending || false;
    this.referralOutcomeTransferToFamilyMaintenance = data.referralOutcomeTransferToFamilyMaintenance || false;
    this.referralOutcomeTransferToCourtFamilyMaintenance = data.referralOutcomeTransferToCourtFamilyMaintenance || false;
    this.referralOutcomeTransferToFamilyReunification = data.referralOutcomeTransferToFamilyReunification || false;
    this.referralOutcomeCloseNoFurtherAction = data.referralOutcomeCloseNoFurtherAction || false;
    this.referralOutcomePending = data.referralOutcomePending || false;

    this.currentActivity = data.currentActivity || null;
    this.activities = data.activities || [];

    this.standardsMet = data.standardsMet || null;
    this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = data.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet || false;
    this.reasonsStandardsNotMetCaseworkerStoppedTracking = data.reasonsStandardsNotMetCaseworkerStoppedTracking || false;
    this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = data.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded || false;
    this.reasonsStandardsNotMetOther = data.reasonsStandardsNotMetOther || false;
    this.supervisorComments = data.supervisorComments || null;
  }

  /* Creates a new Humboldt2020ReferralInvestigationFormData class from the data */
  static createNewForm(id: number, formTypeId: number, user: UserData, timestamp: number): Humboldt2020ReferralInvestigationFormData {
    return new Humboldt2020ReferralInvestigationFormData(SharedFormData.newFormData(id, formTypeId, user, timestamp));
  }

  /* creates the object to store in the database */
  toDatabaseObject(): object {
    const databaseObject: any = {
      referralId: this.referralId,
      referralAssignmentDate: this.referralAssignmentDate,
      referralType: this.referralType,
      responsePriority: this.responsePriority,
      tribalAffiliation: this.tribalAffiliation,
      tribalAffiliationTypeLocalTribe: this.tribalAffiliationTypeLocalTribe,
      tribalAffiliationTypeNonLocalTribe: this.tribalAffiliationTypeNonLocalTribe,
      numberOfChildrenInAllegationHousehold: this.numberOfChildrenInAllegationHousehold,
      comments: this.comments,

      initialSdmSafetyDecision: this.initialSdmSafetyDecision,
      finalSdmSafetyDecision: this.finalSdmSafetyDecision,
      finalSdmRiskLevel: this.finalSdmRiskLevel,
      allegationConclusionSubstantiated: this.allegationConclusionSubstantiated,
      allegationConclusionInconclusive: this.allegationConclusionInconclusive,
      allegationConclusionUnfounded: this.allegationConclusionUnfounded,
      allegationConclusionPending: this.allegationConclusionPending,
      referralOutcomeTransferToFamilyMaintenance: this.referralOutcomeTransferToFamilyMaintenance,
      referralOutcomeTransferToCourtFamilyMaintenance: this.referralOutcomeTransferToCourtFamilyMaintenance,
      referralOutcomeTransferToFamilyReunification: this.referralOutcomeTransferToFamilyReunification,
      referralOutcomeCloseNoFurtherAction: this.referralOutcomeCloseNoFurtherAction,
      referralOutcomePending: this.referralOutcomePending,

      currentActivity: this.currentActivity,

      standardsMet: this.standardsMet,
      reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet: this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet,
      reasonsStandardsNotMetCaseworkerStoppedTracking: this.reasonsStandardsNotMetCaseworkerStoppedTracking,
      reasonsStandardsNotMetNotAllWorkDuringMonthRecorded: this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded,
      reasonsStandardsNotMetOther: this.reasonsStandardsNotMetOther,
      supervisorComments: this.supervisorComments
    };

    if (this.activities.length > 0) {
      databaseObject.activities = this.activities;
    }

    return Object.assign({}, super.toDatabaseObject(), databaseObject);
  }

  /* Creates a Humboldt2020ReferralInvestigationFormData class given the string stored in the database */
  static createFromDatabaseString(data: string): Humboldt2020ReferralInvestigationFormData {
    return new Humboldt2020ReferralInvestigationFormData(JSON.parse(data));
  }

  /* creates a data server object to send to the server */
  toServerObject(): ApiSaveSurveyData {
    const serverObject = super.toServerObject();

    SharedFormData.addSurveyItem(serverObject, 'ReferralId', this.referralId);
    SharedFormData.addTimestampSurveyItem(serverObject, 'ReferralAssignmentDate', this.referralAssignmentDate);
    SharedFormData.addSurveyItem(serverObject, 'ReferralType', this.referralType);
    SharedFormData.addSurveyItem(serverObject, 'ResponsePriority', this.responsePriority);
    SharedFormData.addSurveyItem(serverObject, 'TribalAffiliation', this.tribalAffiliation);
    SharedFormData.addBooleanSurveyItem(serverObject, 'TribalAffiliationTypeLocalTribe',
      this.tribalAffiliation === Humboldt2020ReferralInvestigationTribalAffiliationOption.YesMaybe ? this.tribalAffiliationTypeLocalTribe : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'TribalAffiliationTypeNonLocalTribe',
      this.tribalAffiliation === Humboldt2020ReferralInvestigationTribalAffiliationOption.YesMaybe ? this.tribalAffiliationTypeNonLocalTribe : false);
    SharedFormData.addSurveyItem(serverObject, 'NumberOfChildrenInAllegationHousehold', this.numberOfChildrenInAllegationHousehold);
    SharedFormData.addSurveyItem(serverObject, 'Comments', this.comments);

    SharedFormData.addSurveyItem(serverObject, 'InitialSdmSafetyDecision', this.initialSdmSafetyDecision);
    SharedFormData.addSurveyItem(serverObject, 'FinalSdmSafetyDecision', this.finalSdmSafetyDecision);
    SharedFormData.addSurveyItem(serverObject, 'FinalSdmRiskLevel', this.finalSdmRiskLevel);
    SharedFormData.addBooleanSurveyItem(serverObject, 'AllegationConclusionSubstantiated', this.allegationConclusionSubstantiated);
    SharedFormData.addBooleanSurveyItem(serverObject, 'AllegationConclusionInconclusive', this.allegationConclusionInconclusive);
    SharedFormData.addBooleanSurveyItem(serverObject, 'AllegationConclusionUnfounded', this.allegationConclusionUnfounded);
    SharedFormData.addBooleanSurveyItem(serverObject, 'AllegationConclusionPending', this.allegationConclusionPending);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReferralOutcomeTransferToFamilyMaintenance', this.referralOutcomeTransferToFamilyMaintenance);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReferralOutcomeTransferToCourtFamilyMaintenance', this.referralOutcomeTransferToCourtFamilyMaintenance);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReferralOutcomeTransferToFamilyReunification', this.referralOutcomeTransferToFamilyReunification);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReferralOutcomeCloseNoFurtherAction', this.referralOutcomeCloseNoFurtherAction);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReferralOutcomePending', this.referralOutcomePending);

    this.activities.forEach((activity, index) => {
      SharedFormData.addTimestampSurveyItem(serverObject, 'ActivityDate', activity.activityDate, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityActivity', activity.activity, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedChildYouth', activity.personInvolvedChildYouth, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedParentGuardian', activity.personInvolvedParentGuardian, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedTribe', activity.personInvolvedTribe, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedSubstituteCareProvider', activity.personInvolvedSubstituteCareProvider, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedCollateralReporter', activity.personInvolvedCollateralReporter, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedSupervisor', activity.personInvolvedSupervisor, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOtherCwsStaff', activity.personInvolvedOtherCwsStaff, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOther', activity.personInvolvedOther, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedNone', activity.personInvolvedNone, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityWithReferralTeammate', activity.withReferralTeammate, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityMethod', activity.method, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityPlace', activity.place, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityActivityMinutesSpent', activity.activityMinutesSpent, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityTravelMinutesSpent', activity.travelMinutesSpent, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityDescription', activity.activityDescription, index);
    });

    SharedFormData.addSurveyItem(serverObject, 'StandardsMet', this.standardsMet);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetCaseworkerStoppedTracking',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetCaseworkerStoppedTracking : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetNotAllWorkDuringMonthRecorded',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetOther',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetOther : false);
    SharedFormData.addSurveyItem(serverObject, 'SupervisorComments', this.supervisorComments);

    return serverObject;
  }

  /* creates this class from a data server object
  * fullRecord is false for records created from api/getlist */
  static createFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): Humboldt2020ReferralInvestigationFormData {
    return new Humboldt2020ReferralInvestigationFormData(Humboldt2020ReferralInvestigationFormData.createDataFromServerObject(serverObject, fullRecord));
  }

  /* creates the data object to parse into a new class instance, exposed as a separate function so child classes can use it
  * fullRecord is false for records created from api/getlist */
  static createDataFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): any {
    const data = SharedFormData.createDataFromServerObject(serverObject, fullRecord);

    if (serverObject.SurveyItems && isArray(serverObject.SurveyItems)) {
      serverObject.SurveyItems.forEach(item => {
        let itemIndex = 0;
        switch (item.ItemName) {
          case 'ReferralId':
            data.referralId = item.ItemValue;
            break;
          case 'ReferralAssignmentDate':
            data.referralAssignmentDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'ReferralType':
            data.referralType = item.ItemValue;
            break;
          case 'ResponsePriority':
            data.responsePriority = item.ItemValue;
            break;
          case 'TribalAffiliation':
            data.tribalAffiliation = item.ItemValue;
            break;
          case 'TribalAffiliationTypeLocalTribe':
            data.tribalAffiliationTypeLocalTribe = (item.ItemValue === 'true');
            break;
          case 'TribalAffiliationTypeNonLocalTribe':
            data.tribalAffiliationTypeNonLocalTribe = (item.ItemValue === 'true');
            break;
          case 'NumberOfChildrenInAllegationHousehold':
            data.numberOfChildrenInAllegationHousehold = item.ItemValue;
            break;
          case 'Comments':
            data.comments = item.ItemValue;
            break;

          case 'InitialSdmSafetyDecision':
            data.initialSdmSafetyDecision = item.ItemValue;
            break;
          case 'FinalSdmSafetyDecision':
            data.finalSdmSafetyDecision = item.ItemValue;
            break;
          case 'FinalSdmRiskLevel':
            data.finalSdmRiskLevel = item.ItemValue;
            break;
          case 'AllegationConclusionSubstantiated':
            data.allegationConclusionSubstantiated = (item.ItemValue === 'true');
            break;
          case 'AllegationConclusionInconclusive':
            data.allegationConclusionInconclusive = (item.ItemValue === 'true');
            break;
          case 'AllegationConclusionUnfounded':
            data.allegationConclusionUnfounded = (item.ItemValue === 'true');
            break;
          case 'AllegationConclusionPending':
            data.allegationConclusionPending = (item.ItemValue === 'true');
            break;
          case 'ReferralOutcomeTransferToFamilyMaintenance':
            data.referralOutcomeTransferToFamilyMaintenance = (item.ItemValue === 'true');
            break;
          case 'ReferralOutcomeTransferToCourtFamilyMaintenance':
            data.referralOutcomeTransferToCourtFamilyMaintenance = (item.ItemValue === 'true');
            break;
          case 'ReferralOutcomeTransferToFamilyReunification':
            data.referralOutcomeTransferToFamilyReunification = (item.ItemValue === 'true');
            break;
          case 'ReferralOutcomeCloseNoFurtherAction':
            data.referralOutcomeCloseNoFurtherAction = (item.ItemValue === 'true');
            break;
          case 'ReferralOutcomePending':
            data.referralOutcomePending = (item.ItemValue === 'true');
            break;

          case 'ActivityDate':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'ActivityActivity':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activity = item.ItemValue;
            break;
          case 'ActivityPersonInvolvedChildYouth':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedChildYouth = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedParentGuardian':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedParentGuardian = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedTribe':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedTribe = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedSubstituteCareProvider':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedSubstituteCareProvider = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedCollateralReporter':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedCollateralReporter = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedSupervisor':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedSupervisor = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOtherCwsStaff':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOtherCwsStaff = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOther':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOther = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedNone':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedNone = (item.ItemValue === 'true');
            break;
          case 'ActivityWithReferralTeammate':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].withReferralTeammate = item.ItemValue;
            break;
          case 'ActivityMethod':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].method = item.ItemValue;
            break;
          case 'ActivityPlace':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].place = item.ItemValue;
            break;
          case 'ActivityActivityMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityTravelMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].travelMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityDescription':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ReferralInvestigationFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDescription = item.ItemValue;
            break;

          case 'StandardsMet':
            data.standardsMet = item.ItemValue;
            break;
          case 'ReasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet':
            data.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetCaseworkerStoppedTracking':
            data.reasonsStandardsNotMetCaseworkerStoppedTracking = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetNotAllWorkDuringMonthRecorded':
            data.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetOther':
            data.reasonsStandardsNotMetOther = (item.ItemValue === 'true');
            break;
          case 'SupervisorComments':
            data.supervisorComments = item.ItemValue;
            break;

          default:
            console.error('Unknown item name (' + item.ItemName + ') while parsing SurveyItems');
        }
      });
    }

    if (data.activities && isArray(data.activities)) {
      data.activities.forEach(activity => {
        const personInvolvedArray = getActivityPersonInvolvedArray(activity);
        activity.personInvolvedDisplay = personInvolvedArray.length ? personInvolvedArray.join(', ') : null;
      })
    }

    return data;
  }

  /* creates empty placeholders in the activities array on data up to index */
  static setDataActivityCount(data: any, index: number) {
    if (!data.activities) data.activities = [];
    while (data.activities.length <= index) {
      const newActivity: Humboldt2020ReferralInvestigationActivityData = {
        index: data.activities.length,
        activityDate: null,
        activity: null,
        personInvolvedDisplay: null,
        personInvolvedChildYouth: false,
        personInvolvedParentGuardian: false,
        personInvolvedTribe: false,
        personInvolvedSubstituteCareProvider: false,
        personInvolvedCollateralReporter: false,
        personInvolvedSupervisor: false,
        personInvolvedOtherCwsStaff: false,
        personInvolvedOther: false,
        personInvolvedNone: false,
        withReferralTeammate: null,
        method: null,
        place: null,
        activityMinutesSpent: null,
        travelMinutesSpent: null,
        activityDescription: null
      };
      data.activities.push(newActivity);
    }
  }
}
