import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { passwordValidator } from '../validators'
import { ActivatedRoute } from '@angular/router';

/* checks that otherControl's value matches the specified control */
function newPasswordsNoMatch(formGroup: FormGroup): {[key: string]: any} | null {
  const newPasswordControl: AbstractControl | null = formGroup.get('newPassword');
  const retypePasswordControl: AbstractControl | null = formGroup.get('retypePassword');

  if (newPasswordControl === null || retypePasswordControl === null) return null;

  if (newPasswordControl.value !== retypePasswordControl.value) {
    return {'newPasswordsNoMatch': true};
  }

  return null;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  resetPasswordForm = this.formBuilder.group({
    username: ['', Validators.required],
    code: ['', Validators.required],
    newPassword: ['', [Validators.required, passwordValidator]],
    retypePassword: ['', [Validators.required]]
  }, {
    validators: [newPasswordsNoMatch]
  });

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute
  ) {
    this.route.queryParamMap.subscribe(paramMap => {
      const username = paramMap.get('username');
      if (username) {
        this.resetPasswordForm.controls.username.setValue(username);
      }
      const code = paramMap.get('code');
      if (code) {
        this.resetPasswordForm.controls.code.setValue(code);
      }
    });
  }

  changePassword(): void {
    if (this.resetPasswordForm.valid) {
      this.authService.resetPassword(this.resetPasswordForm.controls.username.value, this.resetPasswordForm.controls.code.value, this.resetPasswordForm.controls.newPassword.value);
    } else {
      this.resetPasswordForm.markAllAsTouched();
    }
  }

  onNewPasswordKeydown(event) {
    if (event.key === "Enter") {
      this.changePassword();
    }
  }
}
