import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FormListFilterDialogComponent } from './form-list-filter-dialog/form-list-filter-dialog.component';
import { FormDuplicateCreateWarningDialogComponent } from './form-duplicate-create-warning-dialog/form-duplicate-create-warning-dialog.component';
import { getFormTypeById, getFormTypesByProjectId, getFormTypesByProjectIdWithCaseTypes } from '../project/form-type-data';
import { ProcessingDialogComponent } from '../processing-dialog/processing-dialog.component';
import { SharedFormData } from '../form/shared-form-data';
import { FormStatus } from '../form/form-status.enum';
import { FormListColumn } from './form-list-column.enum';
import { isArray } from 'util';
import { getDataClassForFormType } from '../project/form-type-to-data-class';
import { getCurrentMillisecondTimestampRoundedToNearestSecond } from '../utility-functions';
import { FormListUpdatedAfterOfflineSaveWarningDialogComponent } from './form-list-updated-after-offline-save-warning-dialog/form-list-updated-after-offline-save-warning-dialog.component';
import { environment } from '../../environments/environment';
import { UserRole } from '../auth/user-role.enum';
import { getProjectById, getProjectList } from '../project/project-data';
export class FormListComponent {
    constructor(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, router, titleService) {
        this.apiService = apiService;
        this.authService = authService;
        this.breakpointObserver = breakpointObserver;
        this.databaseService = databaseService;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.onlineService = onlineService;
        this.router = router;
        this.titleService = titleService;
        this.unsubscribe$ = new Subject();
        this.UserRole = UserRole;
        this.productionWarning = false;
        this.isSmallDisplay = false; // If true the display will show a list interface
        this.isLargeDisplay = false; // If true the display will show a table interface
        this.isSyncing = false;
        this.formList = [];
        this.dataSource = [];
        this.formListColumnEnum = FormListColumn;
        this.displayedColumns = [];
        this.filterForm = this.formBuilder.group({
            projectId: [null],
            createdUserId: [null],
            createdUserName: [null, { updateOn: 'blur' }],
            formTypeId: [null],
            caseType: [null],
            name: [null, { updateOn: (this.authService.user && (this.authService.user.role === UserRole.ProjectAdmin || this.authService.user.role === UserRole.NccdAdmin)) ? 'blur' : 'change' }],
            status: [null],
            sorting: [null]
        });
        this.formListFilters = {
            projectId: null,
            createdUserId: null,
            createdUserName: null,
            formTypeId: null,
            caseType: null,
            name: null,
            status: null,
            sortColumn: null,
            sortDirection: null,
        };
        this.filterOptionsProjects = [];
        this.filterOptionsFormTypes = [];
        this.filterOptionsCaseTypes = [];
        this.filterOptionsFormStatuses = [
            FormStatus.InProgress,
            FormStatus.InReview,
            FormStatus.Completed
        ];
        this.sortOptions = FormListComponent.SORT_OPTIONS;
        this.filterInfoString = '';
        this.showStatus = false;
        this.adminListPage = new BehaviorSubject(1);
        this.adminPageFirstRecord = null;
        this.adminPageEndRecord = null;
        this.adminLastRecord = null;
        this.adminPaginationMessage = null;
        this.currentAdminLodaingData = {
            message: new BehaviorSubject('')
        };
        this.currentAdminLoadingDialog = null;
        if (this.authService.user === null) {
            this.router.navigate(['/login']);
        }
        else if (this.authService.user.passwordResetRequired) {
            this.router.navigate(['/change-password']);
        }
        else {
            this.user = this.authService.user;
            if (this.user.project !== null && this.user.project.productionProject && !environment.production) {
                this.productionWarning = true;
            }
            this.syncFilterSortToMatSort();
            this.setUserSpecificFilterOptions();
            switch (this.user.role) {
                case UserRole.Agent:
                    this.filterForm.controls.status.valueChanges.subscribe(() => {
                        // Changing the status filter may change the columns displayed
                        this.setDisplayedColumns();
                    });
                    if (this.user.project !== null) {
                        this.titleService.title.next(this.user.project.name);
                    }
                    this.filterFormChangeSubscriptions();
                    this.initializeAgentOrSupervisor();
                    break;
                case UserRole.Supervisor:
                    if (this.user.project !== null) {
                        this.titleService.title.next(this.user.project.name);
                    }
                    this.filterFormChangeSubscriptions();
                    this.initializeAgentOrSupervisor();
                    break;
                case UserRole.ProjectAdmin:
                    if (this.user.project !== null) {
                        this.titleService.title.next(this.user.project.name + ' Admin');
                    }
                    this.initializeAdmin();
                    break;
                case UserRole.NccdAdmin:
                    this.titleService.title.next('Admin Form List');
                    this.initializeAdmin();
                    break;
            }
        }
        this.filterFormChangeSubscriptions();
        breakpointObserver.observe('(max-width: 960px)').pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.isSmallDisplay = result.matches;
            this.isLargeDisplay = !result.matches;
        });
    }
    set sortViewChild(value) {
        this.sort = value;
        // This timeout is needed or angular complains Expression has changed after it was checked.
        window.setTimeout(() => {
            // default in the current set sorting when the sorter is set
            // this happens if the width goes from isSmallDisplay to isLargeDisplay
            if (this.sort && this.filterForm && this.filterForm.controls.sorting.value) {
                const sorting = this.filterForm.controls.sorting.value;
                if (this.sort.active != sorting.sortColumn || this.sort.direction != sorting.sortDirection) {
                    if (this.sort) {
                        this.sort.sort({
                            id: sorting.sortColumn,
                            start: sorting.sortDirection,
                            disableClear: true
                        });
                    }
                }
            }
        });
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    /**
     * When filter form sort changes, propagate the changes to the MatSort if the mat sort is different
     */
    syncFilterSortToMatSort() {
        this.filterForm.controls.sorting.valueChanges.subscribe((sorting) => {
            if (sorting && this.sort && (this.sort.active !== sorting.sortColumn || (this.sort.direction || 'asc') !== sorting.sortDirection)) {
                this.sort.sort({
                    id: sorting.sortColumn,
                    start: sorting.sortDirection,
                    disableClear: true
                });
            }
        });
    }
    /**
     * Sets the filter options for a specific user configuration, called after we have a user
     */
    setUserSpecificFilterOptions() {
        if (this.user.project) {
            // Agent, Supervisor, and Project Admin Users
            const projectId = this.user.project.id;
            this.filterOptionsProjects = [];
            if (this.user.viewFormTypes) {
                this.filterOptionsFormTypes = this.user.viewFormTypes;
                this.filterOptionsCaseTypes = this.user.viewFormTypes.filter(formType => formType.projectId === projectId && formType.caseTypes.length > 0);
            }
            else {
                this.filterOptionsFormTypes = [];
                this.filterOptionsCaseTypes = [];
            }
        }
        else {
            // NCCD Admin Users
            this.filterOptionsProjects = getProjectList();
            this.filterOptionsFormTypes = []; // uses project list instead
            this.filterOptionsCaseTypes = [];
            this.filterOptionsProjects.forEach((project) => {
                project.forms = getFormTypesByProjectId(project.id);
                getFormTypesByProjectIdWithCaseTypes(project.id).forEach((formType) => {
                    const formTypeClone = Object.assign({}, formType);
                    formTypeClone.name = project.name + ' - ' + formType.name;
                    this.filterOptionsCaseTypes.push(formTypeClone);
                });
            });
        }
        // Add Deleted Form Status if the user is an admin
        if (this.user.role === UserRole.ProjectAdmin || this.user.role === UserRole.NccdAdmin) {
            this.filterOptionsFormStatuses.push(FormStatus.Deleted);
        }
    }
    /**
     * Triggers filter and sort when the value of the filter form changes
     * Only used for Agent and Supervisor
     */
    filterFormChangeSubscriptions() {
        this.filterForm.valueChanges.pipe(debounceTime(100), tap((next) => this.updateFormFiltersFromChanges(next))).subscribe(values => {
            this.filterAndSortFormList();
        });
    }
    /**
     * Updates formListFilters from the values passed and saves any changes to IndexedDb
     */
    updateFormFiltersFromChanges(values) {
        let saveFormListFilters = false;
        if (this.formListFilters.projectId !== values.projectId) {
            this.formListFilters.projectId = values.projectId;
            saveFormListFilters = true;
        }
        if (this.formListFilters.createdUserId !== values.createdUserId) {
            this.formListFilters.createdUserId = values.createdUserId;
            saveFormListFilters = true;
        }
        if (this.formListFilters.createdUserName !== values.createdUserName) {
            this.formListFilters.createdUserName = values.createdUserName;
            saveFormListFilters = true;
        }
        if (this.formListFilters.formTypeId !== values.formTypeId) {
            this.formListFilters.formTypeId = values.formTypeId;
            saveFormListFilters = true;
        }
        if (this.formListFilters.caseType !== values.caseType) {
            this.formListFilters.caseType = values.caseType;
            saveFormListFilters = true;
        }
        if (this.formListFilters.name !== values.name) {
            this.formListFilters.name = values.name;
            saveFormListFilters = true;
        }
        if (this.formListFilters.status !== values.status) {
            this.formListFilters.status = values.status;
            saveFormListFilters = true;
        }
        if (values.sorting) {
            if (this.formListFilters.sortColumn !== values.sorting.sortColumn || this.formListFilters.sortDirection !== values.sorting.sortDirection) {
                this.formListFilters.sortColumn = values.sorting.sortColumn;
                this.formListFilters.sortDirection = values.sorting.sortDirection;
                saveFormListFilters = true;
            }
        }
        else if (this.formListFilters.sortColumn !== null || this.formListFilters.sortDirection !== null) {
            this.formListFilters.sortColumn = null;
            this.formListFilters.sortDirection = null;
            saveFormListFilters = true;
        }
        if (saveFormListFilters) {
            this.databaseService.saveFormListFilters(this.formListFilters);
        }
    }
    /**
     * Called when we have a user and it is an Agent or Supervisor
     * Loads the list and filters from IndexedDB
     * If there is no data or the data is old, loads the list from the server
     */
    initializeAgentOrSupervisor() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const processingData = {
                message: new BehaviorSubject('')
            };
            this.dialog.open(ProcessingDialogComponent, {
                data: processingData,
                disableClose: true,
                width: '800px'
            });
            try {
                processingData.message.next('Loading form list from the device.');
                this.formList = yield this.databaseService.getFormList();
                yield this.loadFilters();
                this.setDisplayedColumns();
                this.filterAndSortFormList();
                if (this.onlineService.online.value) {
                    if (this.formList.length === 0) {
                        processingData.message.next('Loading form list from the data server.');
                        yield this.getListFromServerForAgentOrSupervisor(true);
                    }
                    else if (!this.formListFilters.listLastLoaded || this.formListFilters.listLastLoaded < (getCurrentMillisecondTimestampRoundedToNearestSecond() - (5 * 60 * 1000))) {
                        // Load list in the background if we haven't loaded it in the last 5 minutes
                        this.getListFromServerForAgentOrSupervisor(false);
                    }
                }
                processingData.message.complete();
            }
            catch (error) {
                console.error('Error loading form list', error);
                processingData.message.error(error);
            }
        });
    }
    /**
     * Reloads the list from IndexedDB, should only be used for Agent or Supervisor users
     */
    reloadListFromIndexedDB() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const formList = yield this.databaseService.getFormList();
                this.formList = formList;
                this.filterAndSortFormList();
            }
            catch (error) {
                console.error('Error reloading list from IndexedDB', error);
            }
        });
    }
    /**
     * Loads the list from the server for Agent or Supervisor Users
     */
    getListFromServerForAgentOrSupervisor(displayErrors) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.isSyncing = true;
                const getListResponse = yield this.apiService.apiGetList();
                // Not a filter, but it is convenient to store listLastLoaded with the filter data
                this.formListFilters.listLastLoaded = getCurrentMillisecondTimestampRoundedToNearestSecond();
                const processsPromises = [];
                const preloadPromises = [];
                const serverFormIds = [];
                if (getListResponse && isArray(getListResponse)) {
                    getListResponse.forEach(apiGetListItem => {
                        try {
                            const serverFormData = SharedFormData.createFromServerObject(apiGetListItem, false);
                            serverFormIds.push(serverFormData.id);
                            processsPromises.push(this.processServerFormListItemForAgentOrSupervisor(serverFormData, preloadPromises));
                        }
                        catch (error) {
                            console.error('Error processing data returned from server', error, apiGetListItem);
                        }
                    });
                }
                yield Promise.all(processsPromises);
                // saving because we set listLastLoaded above, don't bother to await, but do it after we process the response
                this.databaseService.saveFormListFilters(this.formListFilters);
                // Do not await preloads or cleanup
                const cleanupPromises = [];
                // Sync offline saves that are not on the server, remove deleted on server items from the list
                yield this.reloadListFromIndexedDB(); // get the list again after updating indexedDB with the server values
                this.formList.forEach(formData => {
                    if (formData.id < 0 && formData.fullRecord && formData.offlineSaveTimestamp !== null) {
                        // Matches items in IndexedDB that have never been saved to the server
                        // formData is only a partial load of the data, so we need to load the full data before saving
                        cleanupPromises.push(this.loadAndSaveOfflineSavedForm(formData));
                    }
                    if (formData.id > 0 && serverFormIds.indexOf(formData.id) === -1 && formData.offlineSaveTimestamp === null) {
                        // the loaded form is not in the list returned from the server, must be deleted on the server
                        cleanupPromises.push(this.databaseService.deleteForm(formData.id));
                    }
                });
                // Clean up is finished when any offline save records are resolved
                // and the records that need deleting are finished so update the data source that is being displayed
                if (cleanupPromises.length > 0) {
                    Promise.all(cleanupPromises).then(() => {
                        this.reloadListFromIndexedDB();
                    });
                }
                // Set the syncing flag (allows a new sync to start by pressing the button)
                // to off once preload and clean up are done
                Promise.all(preloadPromises.concat(cleanupPromises)).then(() => {
                    this.isSyncing = false;
                });
            }
            catch (error) {
                console.error('Error loading list from server', error);
                this.isSyncing = false;
                if (displayErrors) {
                    throw (error);
                }
            }
        });
    }
    /**
     * Processes a single form record returned by the getlist api
     * Puts it in IndexedDB, so only use for Agent or Supervisor
     */
    processServerFormListItemForAgentOrSupervisor(serverFormData, preloadPromises) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const dataFormClass = getDataClassForFormType(serverFormData.formType.id);
            const localFormData = yield this.databaseService.getForm(serverFormData.id, dataFormClass.createFromDatabaseString);
            if (localFormData === null || !localFormData.fullRecord ||
                (serverFormData.updated.timestamp > localFormData.updated.timestamp && localFormData.offlineSaveTimestamp === null)) {
                // local data does not exist OR haven't loaded the full record OR local data is behind the server and does not have an offline save timestamp
                yield this.databaseService.saveForm(serverFormData);
                preloadPromises.push(this.preloadForm(serverFormData, dataFormClass));
            }
            else if (localFormData.offlineSaveTimestamp !== null) {
                // the local record has an offline save
                if (localFormData.offlineSaveTimestamp > serverFormData.updated.timestamp) {
                    // the local save is after the server update timestamp, so we can go ahead and save the local record
                    this.saveOfflineSavedForm(localFormData).then(() => {
                        this.reloadListFromIndexedDB();
                    });
                }
                else {
                    // There is an offline save, but the server says the record has been updated since the offline save
                    this.displayUpdatedAfterOfflineSaveWarningDialog(localFormData, serverFormData);
                }
            }
        });
    }
    /**
     * Loads the full form data from the server and caches it into IndexedDB
     */
    preloadForm(form, dataFormClass) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const getSurveyResponse = yield this.apiService.apiGetSurvey(form.id);
                const formData = dataFormClass.createFromServerObject(getSurveyResponse, true);
                yield this.databaseService.saveForm(formData);
            }
            catch (error) {
                console.error('Error in preloadForm', error);
                // don't do anything foreground with the error since the preload happens in the background
            }
        });
    }
    /**
     * takes SharedFormData returned by databaseService.getFormList()
     * loads the full record with the form specific constructor
     * and sends it to saveOfflineSavedForm()
     */
    loadAndSaveOfflineSavedForm(localFormData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const dataFormClass = getDataClassForFormType(localFormData.formType.id);
            const fullFormData = yield this.databaseService.getForm(localFormData.id, dataFormClass.createFromDatabaseString);
            if (fullFormData !== null) {
                yield this.saveOfflineSavedForm(fullFormData);
            }
        });
    }
    /**
     * Saves offline saved records to the server, then updates IndexedDB
     *
     * localFormData needs to be the full record, processed through the form specific constructor,
     * not the SharedFormData returned by databaseService.getFormList()
     */
    saveOfflineSavedForm(localFormData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const saveSurveyResponse = yield this.apiService.saveSurvey(localFormData.toServerObject());
                if (saveSurveyResponse.Status === "Success") {
                    // successful save to the data server, update the local record
                    localFormData.offlineSaveTimestamp = null;
                    const responseSurveyId = parseInt(saveSurveyResponse.SurveyId, 10);
                    if (responseSurveyId !== localFormData.id) {
                        // server assigned a different id to the saving form
                        const oldFormId = localFormData.id;
                        localFormData.id = responseSurveyId;
                        if (localFormData.status !== FormStatus.Deleted) {
                            yield this.databaseService.saveForm(localFormData);
                        }
                        yield this.databaseService.deleteForm(oldFormId);
                    }
                    else {
                        // server responded with the same id
                        if (localFormData.status !== FormStatus.Deleted) {
                            yield this.databaseService.saveForm(localFormData);
                        }
                        else {
                            yield this.databaseService.deleteForm(localFormData.id);
                        }
                    }
                }
            }
            catch (error) {
                console.error('Error while saving offline saved form in list view', error);
                // Don't do anything for an error in saving, this is always in the background here
                return;
            }
        });
    }
    /**
     * Displays the warning if the offline save data is rejected because there is newer data on the server
     */
    displayUpdatedAfterOfflineSaveWarningDialog(localFormData, serverFormData) {
        const dialogFormData = {
            localFormData: localFormData,
            serverFormData: serverFormData
        };
        this.dialog.open(FormListUpdatedAfterOfflineSaveWarningDialogComponent, {
            data: dialogFormData,
            width: '800px'
        }).afterClosed().subscribe(result => {
            switch (result) {
                case 'discardChanges':
                    this.databaseService.saveForm(serverFormData).then(() => {
                        this.reloadListFromIndexedDB();
                        // preload is necessary here because serverFormData is only the SharedFormData
                        this.preloadForm(serverFormData, getDataClassForFormType(serverFormData.formType.id));
                    });
                    break;
                case 'saveChanges':
                    localFormData.updated.timestamp = getCurrentMillisecondTimestampRoundedToNearestSecond();
                    this.saveOfflineSavedForm(localFormData).then(() => {
                        this.reloadListFromIndexedDB();
                    });
                    break;
            }
        });
    }
    /**
     * Called when we have a user and it is an Project Admin or NCCD Admin
     * Loads the filters from IndexedDB
     * loads the page 1 of the list from the server
     */
    initializeAdmin() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const processingData = {
                message: new BehaviorSubject('')
            };
            this.dialog.open(ProcessingDialogComponent, {
                data: processingData,
                disableClose: true,
                width: '800px'
            });
            try {
                processingData.message.next('Loading filters from the device.');
                yield this.loadFilters();
                this.setDisplayedColumns();
                processingData.message.complete();
                this.initializeAdminListLoadObservable();
            }
            catch (error) {
                console.error('Error loading filters', error);
                processingData.message.error(error);
            }
        });
    }
    /**
     * Creates the observable that is used for laoding the admin form list
     */
    initializeAdminListLoadObservable() {
        const filterChangedObservable = this.filterForm.valueChanges.pipe(debounceTime(100)).subscribe((changes) => {
            this.updateFormFiltersFromChanges(changes);
            this.setFilterInfoString();
            this.adminListPage.next(1);
        });
        this.adminListPage.pipe(tap(() => {
            // Opens the loading data dialog to let the user know we are processing
            if (this.currentAdminLoadingDialog === null) {
                this.currentAdminLodaingData = {
                    message: new BehaviorSubject('Loading list from the server.')
                };
                this.currentAdminLoadingDialog = this.dialog.open(ProcessingDialogComponent, {
                    data: this.currentAdminLodaingData,
                    disableClose: true,
                    width: '800px'
                });
            }
            else {
                this.currentAdminLodaingData.message.next('Loading list from the server.');
            }
        }), switchMap(() => {
            const filters = {};
            if (this.formListFilters.projectId) {
                filters.Jurisdiction = this.formListFilters.projectId.toString();
            }
            if (this.formListFilters.createdUserName) {
                filters.CreatingUserName = this.formListFilters.createdUserName;
            }
            if (this.formListFilters.formTypeId) {
                filters.SurveyType = this.formListFilters.formTypeId;
            }
            if (this.formListFilters.caseType) {
                filters.CaseType = this.formListFilters.caseType;
            }
            if (this.formListFilters.name) {
                filters.SurveyName = this.formListFilters.name;
            }
            if (this.formListFilters.status) {
                filters.Status = this.formListFilters.status;
            }
            if (this.formListFilters.sortColumn) {
                switch (this.formListFilters.sortColumn) {
                    // these are the columns supported in the admin view
                    case FormListColumn.name:
                        filters.SortField = 'SurveyName';
                        break;
                    case FormListColumn.formTypeName:
                        filters.SortField = 'FormTypeName';
                        break;
                    case FormListColumn.caseType:
                        filters.SortField = 'CaseType';
                        break;
                    case FormListColumn.createdUserName:
                        filters.SortField = 'CreatingUserName';
                        break;
                    case FormListColumn.status:
                        filters.SortField = 'SurveyStatus';
                        break;
                    case FormListColumn.statusChangeTimestamp:
                        filters.SortField = 'StatusChangeDate';
                        break;
                }
            }
            if (this.formListFilters.sortDirection) {
                filters.Ascending = this.formListFilters.sortDirection === 'asc';
            }
            return this.apiService.apiGetAdminList(this.adminListPage.value, filters);
        }), catchError((error) => {
            console.error(error);
            this.currentAdminLodaingData.message.error(error);
            return of([]);
        })).subscribe((getListAdminResponse) => {
            try {
                const newDataSource = [];
                getListAdminResponse.Surveys.forEach((serverResponseItem) => {
                    newDataSource.push(SharedFormData.createFromServerObject(serverResponseItem, false));
                });
                this.dataSource = newDataSource;
                this.adminLastRecord = getListAdminResponse.TotalSurveyCount;
                this.adminPageFirstRecord = ((this.adminListPage.value - 1) * 50) + 1;
                this.adminPageEndRecord = this.adminPageFirstRecord + newDataSource.length - 1;
                this.adminPaginationMessage = this.adminPageFirstRecord.toString() + ' to ' + this.adminPageEndRecord.toString() + ' of ' + this.adminLastRecord.toString();
                this.currentAdminLodaingData.message.complete();
                this.currentAdminLoadingDialog = null;
            }
            catch (error) {
                this.currentAdminLodaingData.message.error('Error parsing list from the server');
                this.currentAdminLoadingDialog = null;
            }
        });
    }
    firstAdminPage() {
        if (this.adminListPage.value !== 1) {
            this.adminListPage.next(1);
        }
    }
    prevAdminPage() {
        if (this.adminListPage.value > 1) {
            this.adminListPage.next(this.adminListPage.value - 1);
        }
    }
    nextAdminPage() {
        if (this.adminLastRecord !== null) {
            const lastPage = Math.ceil(this.adminLastRecord / 50);
            if (this.adminListPage.value < lastPage) {
                this.adminListPage.next(this.adminListPage.value + 1);
            }
        }
    }
    lastAdminPage() {
        if (this.adminLastRecord !== null) {
            const lastPage = Math.ceil(this.adminLastRecord / 50);
            if (this.adminListPage.value !== lastPage) {
                this.adminListPage.next(lastPage);
            }
        }
    }
    /**
     * Loads the filters from IndexedDB
     */
    loadFilters() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const formListFilters = yield this.databaseService.getFormListFilters();
            let newSorting;
            if (formListFilters) {
                this.formListFilters = formListFilters;
                newSorting = this.sortOptions.filter(sortOption => sortOption.sortColumn === this.formListFilters.sortColumn &&
                    sortOption.sortDirection === this.formListFilters.sortDirection)[0];
            }
            else {
                // default filter values
                this.formListFilters.status = '';
                switch (this.user.role) {
                    case UserRole.Agent:
                        this.formListFilters.status = FormStatus.InProgress;
                        newSorting = this.sortOptions.filter(sortOption => sortOption.sortColumn === FormListColumn.name)[0];
                        break;
                    case UserRole.Supervisor:
                        this.formListFilters.status = FormStatus.InReview;
                        newSorting = this.sortOptions.filter(sortOption => sortOption.sortColumn === FormListColumn.name)[0];
                        break;
                    default:
                        this.formListFilters.status = null;
                        newSorting = this.sortOptions.filter(sortOption => sortOption.sortColumn === FormListColumn.name)[0];
                }
                this.formListFilters.sortColumn = newSorting.sortColumn;
                this.formListFilters.sortDirection = newSorting.sortDirection;
                this.databaseService.saveFormListFilters(this.formListFilters);
            }
            if (this.filterForm.controls.projectId.value !== this.formListFilters.projectId)
                this.filterForm.controls.projectId.setValue(this.formListFilters.projectId);
            if (this.filterForm.controls.createdUserId.value !== this.formListFilters.createdUserId)
                this.filterForm.controls.createdUserId.setValue(this.formListFilters.createdUserId);
            if (this.filterForm.controls.createdUserName.value !== this.formListFilters.createdUserName)
                this.filterForm.controls.createdUserName.setValue(this.formListFilters.createdUserName);
            if (this.filterForm.controls.formTypeId.value !== this.formListFilters.formTypeId)
                this.filterForm.controls.formTypeId.setValue(this.formListFilters.formTypeId);
            if (this.filterForm.controls.caseType.value !== this.formListFilters.caseType)
                this.filterForm.controls.caseType.setValue(this.formListFilters.caseType);
            if (this.filterForm.controls.name.value !== this.formListFilters.name)
                this.filterForm.controls.name.setValue(this.formListFilters.name);
            if (this.filterForm.controls.status.value !== this.formListFilters.status)
                this.filterForm.controls.status.setValue(this.formListFilters.status);
            if (this.filterForm.controls.sorting.value !== newSorting)
                this.filterForm.controls.sorting.setValue(newSorting);
        });
    }
    /**
     *  updates the displayed columns of the table
     */
    setDisplayedColumns() {
        if (this.filterForm) {
            switch (this.user.role) {
                case UserRole.NccdAdmin:
                    this.displayedColumns = [
                        FormListColumn.createdUserName,
                        FormListColumn.formTypeName,
                        FormListColumn.caseType,
                        FormListColumn.name,
                        FormListColumn.status,
                        FormListColumn.statusChangeTimestamp
                    ];
                    this.showStatus = true;
                    break;
                case UserRole.ProjectAdmin:
                case UserRole.Supervisor:
                    this.displayedColumns = [
                        FormListColumn.createdUserName,
                        FormListColumn.formTypeName,
                        FormListColumn.caseType,
                        FormListColumn.name,
                        FormListColumn.status,
                        FormListColumn.statusChangeTimestamp
                    ];
                    this.showStatus = true;
                    break;
                case UserRole.Agent:
                    if (this.filterForm.controls.status.value === FormStatus.InProgress) {
                        this.displayedColumns = [
                            FormListColumn.formTypeName,
                            FormListColumn.caseType,
                            FormListColumn.name,
                            FormListColumn.createdTimestamp,
                            FormListColumn.offlineSaveTimestamp
                        ];
                        this.showStatus = false;
                    }
                    else {
                        this.displayedColumns = [
                            FormListColumn.formTypeName,
                            FormListColumn.caseType,
                            FormListColumn.name,
                            FormListColumn.createdTimestamp,
                            FormListColumn.status,
                            FormListColumn.statusChangeTimestamp,
                            FormListColumn.offlineSaveTimestamp
                        ];
                        this.showStatus = true;
                    }
                    break;
            }
            this.sortOptions = [];
            this.displayedColumns.forEach(displayedColumn => {
                FormListComponent.SORT_OPTIONS
                    .filter(sortOption => sortOption.sortColumn === displayedColumn)
                    .forEach(sortOption => this.sortOptions.push(sortOption));
            });
            if (this.sortOptions.indexOf(this.filterForm.controls.sorting.value) === -1) {
                // Currently specified sorting is not part of the sortOptions after changing the columns displayed
                if (this.user.role === UserRole.Supervisor) {
                    this.filterForm.controls.sorting.setValue(this.sortOptions.filter(sortOption => sortOption.sortColumn === FormListColumn.createdUserName)[0]);
                }
                else {
                    this.filterForm.controls.sorting.setValue(this.sortOptions.filter(sortOption => sortOption.sortColumn === FormListColumn.name)[0]);
                }
            }
        }
    }
    /**
     * Sorts formList and puts it in dataSource
     * This is used by the Agent and Supervisor views because formList comes from IndexedDb
     */
    filterAndSortFormList() {
        let sortColumn = 'name';
        let sortDirection = 'asc';
        if (this.filterForm.controls.sorting.value) {
            const sorting = this.filterForm.controls.sorting.value;
            sortColumn = sorting.sortColumn;
            sortDirection = sorting.sortDirection;
        }
        this.dataSource = this.formList.filter((form) => {
            if (this.filterForm.controls.createdUserId.value && form.created.userId !== this.filterForm.controls.createdUserId.value) {
                return false;
            }
            if (this.filterForm.controls.formTypeId.value && form.formType.id !== this.filterForm.controls.formTypeId.value) {
                return false;
            }
            if (this.filterForm.controls.caseType.value && form.caseType !== this.filterForm.controls.caseType.value) {
                return false;
            }
            if (this.filterForm.controls.name.value && form.name.toLowerCase().indexOf(this.filterForm.controls.name.value.toLowerCase()) === -1) {
                return false;
            }
            if (this.filterForm.controls.status.value) {
                if (form.status !== this.filterForm.controls.status.value) {
                    return false;
                }
            }
            else if (form.status === FormStatus.Deleted) {
                // Might want to show if there is an offline save, but for now we are not showing it
                return false;
            }
            return true;
        }).sort((form1, form2) => {
            switch (sortColumn) {
                case FormListColumn.createdUserName:
                    if (form1.created.userName > form2.created.userName)
                        return sortDirection === 'desc' ? -1 : 1;
                    if (form1.created.userName < form2.created.userName)
                        return sortDirection === 'desc' ? 1 : -1;
                    break;
                case FormListColumn.createdTimestamp:
                    if (form1.created.timestamp > form2.created.timestamp)
                        return sortDirection === 'desc' ? -1 : 1;
                    if (form1.created.timestamp < form2.created.timestamp)
                        return sortDirection === 'desc' ? 1 : -1;
                    break;
                case FormListColumn.formTypeName:
                    if (form1.formType.name > form2.formType.name)
                        return sortDirection === 'desc' ? -1 : 1;
                    if (form1.formType.name < form2.formType.name)
                        return sortDirection === 'desc' ? 1 : -1;
                    break;
            }
            if (form1.hasOwnProperty(sortColumn) && form2.hasOwnProperty(sortColumn)) {
                if (form1[sortColumn] > form2[sortColumn])
                    return sortDirection === 'desc' ? -1 : 1;
                if (form1[sortColumn] < form2[sortColumn])
                    return sortDirection === 'desc' ? 1 : -1;
            }
            // sensible defaults if the key doesn't exist, or if the initial result is a tie
            if (form1.name < form2.name)
                return -1;
            if (form1.name > form2.name)
                return 1;
            return 0;
        });
        this.setFilterInfoString();
    }
    setFilterInfoString() {
        const info = [];
        if (this.filterForm.controls.projectId.value) {
            const projectData = getProjectById(this.filterForm.controls.projectId.value);
            if (projectData) {
                info.push('Project: ' + projectData.name);
            }
        }
        if (this.user.usersSupervised && this.filterForm.controls.createdUserId.value) {
            const createdUser = this.user.usersSupervised.filter(user => user.id === this.filterForm.controls.createdUserId.value);
            if (createdUser.length) {
                info.push('Created By: ' + createdUser[0].name);
            }
        }
        if (this.filterForm.controls.createdUserName.value) {
            info.push('Created By: ' + this.filterForm.controls.createdUserName.value);
        }
        if (this.filterForm.controls.formTypeId.value) {
            const formTypeData = getFormTypeById(this.filterForm.controls.formTypeId.value);
            if (formTypeData) {
                info.push('Form Type: ' + formTypeData.name);
            }
        }
        if (this.filterForm.controls.caseType.value) {
            info.push('Case Type: ' + this.filterForm.controls.caseType.value);
        }
        if (this.filterForm.controls.name.value) {
            info.push('Name: ' + this.filterForm.controls.name.value);
        }
        if (this.filterForm.controls.status.value) {
            info.push('Status: ' + this.filterForm.controls.status.value);
        }
        if (this.filterForm.controls.sorting.value) {
            info.push('Sort: ' + this.filterForm.controls.sorting.value.display);
        }
        this.filterInfoString = info.join(', ');
    }
    clearFilters() {
        let newStatus = null;
        let newSorting = null;
        switch (this.user.role) {
            case UserRole.NccdAdmin:
            case UserRole.ProjectAdmin:
                newSorting = this.sortOptions.filter(sortOption => sortOption.sortColumn === FormListColumn.name)[0];
                break;
            case UserRole.Supervisor:
                newStatus = FormStatus.InReview;
                newSorting = this.sortOptions.filter(sortOption => sortOption.sortColumn === FormListColumn.createdUserName)[0];
                break;
            case UserRole.Agent:
                newStatus = FormStatus.InProgress;
                newSorting = this.sortOptions.filter(sortOption => sortOption.sortColumn === FormListColumn.name)[0];
                break;
        }
        if (this.filterForm.controls.projectId.value !== null)
            this.filterForm.controls.projectId.setValue(null);
        if (this.filterForm.controls.createdUserId.value !== null)
            this.filterForm.controls.createdUserId.setValue(null);
        if (this.filterForm.controls.createdUserName.value !== null)
            this.filterForm.controls.createdUserName.setValue(null);
        if (this.filterForm.controls.formTypeId.value !== null)
            this.filterForm.controls.formTypeId.setValue(null);
        if (this.filterForm.controls.caseType.value !== null)
            this.filterForm.controls.caseType.setValue(null);
        if (this.filterForm.controls.name.value !== null)
            this.filterForm.controls.name.setValue(null);
        if (this.filterForm.controls.status.value !== newStatus)
            this.filterForm.controls.status.setValue(newStatus);
        if (this.filterForm.controls.sorting.value !== newSorting)
            this.filterForm.controls.sorting.setValue(newSorting);
    }
    openFilterDialog() {
        this.dialog.open(FormListFilterDialogComponent, {
            data: {
                filterForm: this.filterForm,
                clearFunction: this.clearFilters.bind(this),
                projectOptions: this.filterOptionsProjects,
                formTypes: this.filterOptionsFormTypes,
                caseTypes: this.filterOptionsCaseTypes,
                formStatuses: this.filterOptionsFormStatuses,
                sortOptions: this.sortOptions,
                user: this.user
            },
            width: '800px'
        });
    }
    createNewForm(formType) {
        if (formType.restrictToOneInProgressWarning !== null) {
            const matchingForms = this.formList.filter(form => form.formType.id === formType.id && form.status === FormStatus.InProgress);
            if (matchingForms.length) {
                const dialogFormData = {
                    duplicateForm: matchingForms[0],
                    message: formType.restrictToOneInProgressWarning
                };
                this.dialog.open(FormDuplicateCreateWarningDialogComponent, {
                    data: dialogFormData,
                    width: '800px'
                }).afterClosed().subscribe(result => {
                    if (result) {
                        this.router.navigate(['/form', formType.id, result]);
                    }
                });
                return;
            }
        }
        // No warning
        this.router.navigate(['/form', formType.id, 'new']);
    }
    openForm(form) {
        this.router.navigate(['/form', form.formType.id, form.id]);
    }
    /**
     * update the form filter when the table headers are clicked to keep them in sync
     * @param {Sort} sort
     */
    onSortChange(sort) {
        const sortingValue = this.sortOptions.filter(sortOption => sortOption.sortColumn === sort.active && sortOption.sortDirection === (sort.direction || 'asc'));
        if (sortingValue.length > 0 && sortingValue[0] !== this.filterForm.controls.sorting.value) {
            this.filterForm.controls.sorting.setValue(sortingValue[0]);
        }
    }
}
FormListComponent.SORT_OPTIONS = [
    { sortColumn: FormListColumn.name, sortDirection: 'asc', display: 'Name Ascending' },
    { sortColumn: FormListColumn.name, sortDirection: 'desc', display: 'Name Descending' },
    { sortColumn: FormListColumn.formTypeName, sortDirection: 'asc', display: 'Form Type Ascending' },
    { sortColumn: FormListColumn.formTypeName, sortDirection: 'desc', display: 'Form Type Descending' },
    { sortColumn: FormListColumn.caseType, sortDirection: 'asc', display: 'Case Type Ascending' },
    { sortColumn: FormListColumn.caseType, sortDirection: 'desc', display: 'Case Type Descending' },
    { sortColumn: FormListColumn.createdUserName, sortDirection: 'asc', display: 'Created By Ascending' },
    { sortColumn: FormListColumn.createdUserName, sortDirection: 'desc', display: 'Created By Descending' },
    { sortColumn: FormListColumn.createdTimestamp, sortDirection: 'asc', display: 'Created Ascending' },
    { sortColumn: FormListColumn.createdTimestamp, sortDirection: 'desc', display: 'Created Descending' },
    { sortColumn: FormListColumn.status, sortDirection: 'asc', display: 'Status Ascending' },
    { sortColumn: FormListColumn.status, sortDirection: 'desc', display: 'Status Descending' },
    { sortColumn: FormListColumn.statusChangeTimestamp, sortDirection: 'asc', display: 'Status Changed Ascending' },
    { sortColumn: FormListColumn.statusChangeTimestamp, sortDirection: 'desc', display: 'Status Changed Descending' },
    { sortColumn: FormListColumn.offlineSaveTimestamp, sortDirection: 'asc', display: 'Offline Saved Ascending' },
    { sortColumn: FormListColumn.offlineSaveTimestamp, sortDirection: 'desc', display: 'Offline Saved Descending' },
];
