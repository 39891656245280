<div class="center-content">

  <p class="warning-color required-change" *ngIf="authService?.user?.passwordResetRequired">
    You are required to change your password.
  </p>
  <p>
    The new password requires at least one number, uppercase, lowercase, and non-alphanumeric character. The required length of new password is 6 characters or greater. Finally, new password should not contain the character &.
  </p>

  <form [formGroup]="changePasswordForm">
    <mat-form-field>
      <mat-label>Current Password</mat-label>
      <input matInput type="password" formControlName="currentPassword" autocomplete="current-password">
      <mat-error>{{changePasswordForm.controls.currentPassword.errors | errorDisplay}}</mat-error>
      <mat-hint class="warning-color" *ngIf="passwordContainsPlus">Passwords set prior to 2/21 that contained a + will need to use a space instead of the +.</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>New Password</mat-label>
      <input matInput type="password" formControlName="newPassword" autocomplete="new-password">
      <mat-error>{{changePasswordForm.controls.newPassword.errors | errorDisplay}}</mat-error>
      <mat-hint class="warning-color" *ngIf="changePasswordForm.errors?.passwordNotChanged">New password must be different from the Current Password.</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Retype New Password</mat-label>
      <input matInput type="password" formControlName="retypePassword" (keydown)="onNewPasswordKeydown($event)" autocomplete="new-password">
      <mat-error>{{changePasswordForm.controls.retypePassword.errors | errorDisplay}}</mat-error>
      <mat-hint class="warning-color" *ngIf="changePasswordForm.errors?.newPasswordsNoMatch">New passwords do not match.</mat-hint>
    </mat-form-field>
  </form>

  <button mat-raised-button color="primary" (click)="changePassword()">Change Password</button>
  <button mat-button color="primary" (click)="cancel()" *ngIf="!authService?.user?.passwordResetRequired">Cancel</button>
</div>
