import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { noneValidator } from '../../../../validators';
import {
  getActivityPersonInvolvedArray,
  Humboldt2020IntakeScreeningActivityData
} from '../humboldt2020-intake-screening-activity-data';
import { Humboldt2020IntakeScreeningActivityActivityOption } from './humboldt2020-intake-screening-activity-activity-option.enum';
import { Humboldt2020IntakeScreeningActivityPersonInvolvedOption } from './humboldt2020-intake-screening-activity-person-involved-option.enum';
import { Humboldt2020IntakeScreeningActivityMethodOption } from './humboldt2020-intake-screening-activity-method-option.enum';
import { Humboldt2020IntakeScreeningActivityPlaceOption } from './humboldt2020-intake-screening-activity-place-option.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Humboldt2020IntakeScreeningActivityDialogData } from './humboldt2020-intake-screening-activity-dialog-data';
import * as moment from 'moment';
import { isArray } from "util";

@Component({
  selector: 'app-humboldt2020-intake-screening-activity-dialog',
  templateUrl: './humboldt2020-intake-screening-activity-dialog.component.html',
  styleUrls: ['./humboldt2020-intake-screening-activity-dialog.component.css']
})
export class Humboldt2020IntakeScreeningActivityDialogComponent {
  currentActivity: Humboldt2020IntakeScreeningActivityData;
  form = this.formBuilder.group({
    activityDate: [null, Validators.required],
    activity: [null, Validators.required],
    personInvolved: [[], [Validators.required, noneValidator]],
    method: [null, Validators.required],
    place: [null, Validators.required],
    activityMinutesSpent: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    activityDescription: [null, Validators.maxLength(500)]
  });

  activityOptions = Object.values(Humboldt2020IntakeScreeningActivityActivityOption);
  activityOptionEnum = Humboldt2020IntakeScreeningActivityActivityOption;
  personInvolvedOptions = Object.values(Humboldt2020IntakeScreeningActivityPersonInvolvedOption);
  methodOptions = Object.values(Humboldt2020IntakeScreeningActivityMethodOption);
  placeOptions = Object.values(Humboldt2020IntakeScreeningActivityPlaceOption);

  constructor(
    private dialogRef: MatDialogRef<Humboldt2020IntakeScreeningActivityDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Humboldt2020IntakeScreeningActivityDialogData
  ) {
    if (data.activity) {
      // Need to make a copy of the object so updating it doesn't update the origin object until save is applied
      this.currentActivity = {
        index: data.activity.index,
        activityDate: data.activity.activityDate,
        activity: data.activity.activity,
        personInvolvedDisplay: data.activity.personInvolvedDisplay,
        personInvolvedReporter: data.activity.personInvolvedReporter,
        personInvolvedSupervisor: data.activity.personInvolvedSupervisor,
        personInvolvedCwsStaff: data.activity.personInvolvedCwsStaff,
        personInvolvedCollateral: data.activity.personInvolvedCollateral,
        personInvolvedLocalTribe: data.activity.personInvolvedLocalTribe,
        personInvolvedNonLocalTribe: data.activity.personInvolvedNonLocalTribe,
        personInvolvedOther: data.activity.personInvolvedOther,
        personInvolvedNone: data.activity.personInvolvedNone,
        method: data.activity.method,
        place: data.activity.place,
        activityMinutesSpent: data.activity.activityMinutesSpent,
        activityDescription: data.activity.activityDescription
      };

      if (this.currentActivity.activityDate) {
        this.form.controls.activityDate.setValue(moment(this.currentActivity.activityDate, 'x')); // x = unix milliseconds
      }
      this.form.controls.activity.setValue(this.currentActivity.activity);
      this.form.controls.personInvolved.setValue(getActivityPersonInvolvedArray(this.currentActivity));
      this.form.controls.method.setValue(this.currentActivity.method);
      this.form.controls.place.setValue(this.currentActivity.place);
      this.form.controls.activityMinutesSpent.setValue(this.currentActivity.activityMinutesSpent);
      this.form.controls.activityDescription.setValue(this.currentActivity.activityDescription);
      this.updateActivityDescriptionValidators();
      this.form.markAllAsTouched();
    } else {
      this.currentActivity = {
        index: null,
        activityDate: null,
        activity: null,
        personInvolvedDisplay: null,
        personInvolvedReporter: false,
        personInvolvedSupervisor: false,
        personInvolvedCwsStaff: false,
        personInvolvedCollateral: false,
        personInvolvedLocalTribe: false,
        personInvolvedNonLocalTribe: false,
        personInvolvedOther: false,
        personInvolvedNone: false,
        method: null,
        place: null,
        activityMinutesSpent: null,
        activityDescription: null
      };
    }

    this.formChangeSubscriptions();
  }

  formChangeSubscriptions(): void {
    this.form.controls.activityDate.valueChanges.subscribe(activityDate => {
      const numberValue = activityDate !== null ? parseInt(activityDate.format('x')) : null;
      if (this.currentActivity.activityDate !== numberValue) {
        this.currentActivity.activityDate = numberValue;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activity.valueChanges.subscribe(activity => {
      if (this.currentActivity.activity !== activity) {
        this.currentActivity.activity = activity;
        this.data.updateCurrentActivity(this.currentActivity);
        this.updateActivityDescriptionValidators();
      }
    });

    this.form.controls.personInvolved.valueChanges.subscribe(personInvolved => {
      let personInvolvedReporter = false;
      let personInvolvedSupervisor = false;
      let personInvolvedCwsStaff = false;
      let personInvolvedCollateral = false;
      let personInvolvedLocalTribe = false;
      let personInvolvedNonLocalTribe = false;
      let personInvolvedOther = false;
      let personInvolvedNone = false;

      if (isArray(personInvolved)) {
        (personInvolved as string[]).forEach(personInvolved => {
          switch (personInvolved) {
            case Humboldt2020IntakeScreeningActivityPersonInvolvedOption.Reporter:
              personInvolvedReporter = true;
              break;
            case Humboldt2020IntakeScreeningActivityPersonInvolvedOption.Supervisor:
              personInvolvedSupervisor = true;
              break;
            case Humboldt2020IntakeScreeningActivityPersonInvolvedOption.CwsStaff:
              personInvolvedCwsStaff = true;
              break;
            case Humboldt2020IntakeScreeningActivityPersonInvolvedOption.Collateral:
              personInvolvedCollateral = true;
              break;
            case Humboldt2020IntakeScreeningActivityPersonInvolvedOption.LocalTribe:
              personInvolvedLocalTribe = true;
              break;
            case Humboldt2020IntakeScreeningActivityPersonInvolvedOption.NonLocalTribe:
              personInvolvedNonLocalTribe = true;
              break;
            case Humboldt2020IntakeScreeningActivityPersonInvolvedOption.Other:
              personInvolvedOther = true;
              break;
            case Humboldt2020IntakeScreeningActivityPersonInvolvedOption.None:
              personInvolvedNone = true;
              break;
          }
        })
      }

      let saveCurrentFormValues = false;
      if (this.currentActivity && this.currentActivity.personInvolvedReporter !== personInvolvedReporter) {
        this.currentActivity.personInvolvedReporter = personInvolvedReporter;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedSupervisor !== personInvolvedSupervisor) {
        this.currentActivity.personInvolvedSupervisor = personInvolvedSupervisor;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedCwsStaff !== personInvolvedCwsStaff) {
        this.currentActivity.personInvolvedCwsStaff = personInvolvedCwsStaff;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedCollateral !== personInvolvedCollateral) {
        this.currentActivity.personInvolvedCollateral = personInvolvedCollateral;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedLocalTribe !== personInvolvedLocalTribe) {
        this.currentActivity.personInvolvedLocalTribe = personInvolvedLocalTribe;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedNonLocalTribe !== personInvolvedNonLocalTribe) {
        this.currentActivity.personInvolvedNonLocalTribe = personInvolvedNonLocalTribe;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedOther !== personInvolvedOther) {
        this.currentActivity.personInvolvedOther = personInvolvedOther;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedNone !== personInvolvedNone) {
        this.currentActivity.personInvolvedNone = personInvolvedNone;
        saveCurrentFormValues = true;
      }
      const personInvolvedArray = getActivityPersonInvolvedArray(this.currentActivity);
      const personInvolvedDisplay = personInvolvedArray.length ? personInvolvedArray.join(', ') : null;
      if (this.currentActivity && this.currentActivity.personInvolvedDisplay !== personInvolvedDisplay) {
        this.currentActivity.personInvolvedDisplay = personInvolvedDisplay;
        saveCurrentFormValues = true;
      }
      if (saveCurrentFormValues) {
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.method.valueChanges.subscribe(method => {
      if (this.currentActivity.method !== method) {
        this.currentActivity.method = method;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.place.valueChanges.subscribe(place => {
      if (this.currentActivity.place !== place) {
        this.currentActivity.place = place;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityMinutesSpent.valueChanges.subscribe(activityMinutesSpent => {
      if (this.currentActivity.activityMinutesSpent !== activityMinutesSpent) {
        this.currentActivity.activityMinutesSpent = activityMinutesSpent;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityDescription.valueChanges.subscribe(activityDescription => {
      if (this.currentActivity.activityDescription !== activityDescription) {
        this.currentActivity.activityDescription = activityDescription;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });
  }

  /* updates the validators for activityDescription */
  updateActivityDescriptionValidators(): void {
    if (this.currentActivity && this.currentActivity.activity === Humboldt2020IntakeScreeningActivityActivityOption.Other) {
      this.form.controls.activityDescription.setValidators([Validators.required, Validators.maxLength(500)]);
    } else {
      this.form.controls.activityDescription.setValidators(Validators.maxLength(500));
    }
    this.form.controls.activityDescription.updateValueAndValidity();
  }

  updateActivityList(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dialogRef.close(this.currentActivity);
    }
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
