<form [formGroup]="form">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab label="Sample Information">
      <padded-content>
        <mat-form-field *ngIf="user?.role !== UserRole.Agent">
          <mat-label>Created By</mat-label>
          <input matInput type="text" formControlName="agentName" readonly>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Created</mat-label>
          <input matInput formControlName="createdDate" readonly>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Status</mat-label>
          <input matInput formControlName="status" readonly>
        </mat-form-field>

        <mat-form-field *ngIf="user?.role !== UserRole.Agent">
          <mat-label>Status Changed</mat-label>
          <input matInput formControlName="statusChangedDate" readonly>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Last Updated</mat-label>
          <input matInput formControlName="updatedDate" readonly>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Sampled Referral Name</mat-label>
          <input #referralName matInput type="text" maxlength="200" formControlName="referralName" required autocomplete="off">
          <mat-hint align="end">{{referralName.value.length}} / 200</mat-hint>
          <mat-error>{{form.controls.referralName.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Referral ID</mat-label>
          <input #referralId matInput type="text" maxlength="22" formControlName="referralId" inputmode="numeric" humboldt2020Id required autocomplete="off">
          <mat-hint align="end">{{referralId.value.length}} / 22</mat-hint>
          <mat-error>{{form.controls.referralId.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Referral Assignment Date</mat-label>
          <input matInput [matDatepicker]="referralAssignmentDatePicker" formControlName="referralAssignmentDate" required autocomplete="off">
          <mat-datepicker-toggle matSuffix [for]="referralAssignmentDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #referralAssignmentDatePicker></mat-datepicker>
          <mat-error>{{form.controls.referralAssignmentDate.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Referral Type</mat-label>
          <mat-select formControlName="referralType" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of referralTypeOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.referralType.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Response Priority</mat-label>
          <mat-select formControlName="responsePriority" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of responsePriorityOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.responsePriority.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Tribal Affiliation</mat-label>
          <mat-select formControlName="tribalAffiliation" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of tribalAffiliationOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.tribalAffiliation.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="currentFormData?.tribalAffiliation === tribalAffiliationOptionEnum.YesMaybe">
          <mat-label>Tribal Affiliation Type</mat-label>
          <mat-select formControlName="tribalAffiliationType" required multiple>
            <mat-option *ngFor="let option of tribalAffiliationTypeOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.tribalAffiliationType.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Number of children in allegation household</mat-label>
          <mat-select formControlName="numberOfChildrenInAllegationHousehold" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of numberOfChildrenInAllegationHouseholdOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.numberOfChildrenInAllegationHousehold.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Optional Comments</mat-label>
          <textarea #comments matInput maxlength="500" formControlName="comments" [cdkTextareaAutosize]="true" autocomplete="off"></textarea>
          <mat-hint align="end">{{comments.value.length}} / 500</mat-hint>
          <mat-error>{{form.controls.comments.errors | errorDisplay}}</mat-error>
        </mat-form-field>
      </padded-content>
    </mat-tab>
    <mat-tab label="Activities">
      <div class="filter-form" formGroupName="activityListFilter" *ngIf="isLargeDisplay">
        <mat-form-field>
          <mat-label>Activity Filter</mat-label>
          <mat-select formControlName="activity">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let activity of activityOptions" [value]="activity">{{activity}}</mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-icon-button aria-label="Clear Filters" (click)="clearActivityListFilters()"><mat-icon>clear</mat-icon></button>
      </div>

      <mat-toolbar class="small-display-info-string-section mat-elevation-z3" *ngIf="isSmallDisplay" (click)="openActivityListFilterDialog()">
        <button mat-icon-button aria-label="Filters">
          <mat-icon>filter_list</mat-icon>
        </button>
        <div class="filter-info-string mat-small">{{ activityListFilterInfoString }}</div>
      </mat-toolbar>

      <table class="activity-table" mat-table #activityTable matSort [dataSource]="activitiesDataSource" (matSortChange)="onActivityListSortChange($event)" *ngIf="isLargeDisplay">
        <ng-container [matColumnDef]="activityListColumnEnum.action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let activity" class="action-column-cell">
            <button mat-icon-button color="primary" aria-label="Edit Activity" (click)="editActivity(activity)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="primary" aria-label="Delete Activity" (click)="deleteActivity(activity)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.activityDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let activity">{{ activity.activityDate | date:'M/d/yyyy' }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.activity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity</th>
          <td mat-cell *matCellDef="let activity">{{ activity.activity }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.personInvolved">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Person Involved</th>
          <td mat-cell *matCellDef="let activity">{{ activity.personInvolvedDisplay }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.withReferralTeammate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>With Teammate</th>
          <td mat-cell *matCellDef="let activity">{{ activity.withReferralTeammate }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.method">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Method</th>
          <td mat-cell *matCellDef="let activity">{{ activity.method }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.place">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Place</th>
          <td mat-cell *matCellDef="let activity">{{ activity.place }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.activityMinutesSpent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity Minutes Spent</th>
          <td mat-cell *matCellDef="let activity">{{ activity.activityMinutesSpent }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.travelMinutesSpent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Travel Minutes Spent</th>
          <td mat-cell *matCellDef="let activity">{{ activity.travelMinutesSpent }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.activityDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td mat-cell *matCellDef="let activity" class="newline-display">{{ activity.activityDescription }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="activityListDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let activity; columns: activityListDisplayedColumns;"></tr>
      </table>

      <mat-nav-list *ngIf="isSmallDisplay">
        <a mat-list-item *ngFor="let activity of activitiesDataSource">
          <h2 matLine>{{ activity.activityDate | date:'M/d/yyyy' }} {{ activity.activity }}</h2>
          <div matLine>{{ activity.personInvolvedDisplay }}</div>
          <div matLine>With Teammate: {{ activity.withReferralTeammate }}</div>
          <div matLine>{{ activity.method }} at {{ activity.place }}</div>
          <div matLine>{{ activity.activityMinutesSpent }} min / travel {{ activity.travelMinutesSpent }} min</div>
          <div matLine *ngIf="activity.activityDescription">{{ activity.activityDescription }}</div>
          <div class="form-mat-list-actions">
            <button mat-icon-button color="primary" aria-label="Edit Activity" (click)="editActivity(activity)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="primary" aria-label="Delete Activity" (click)="deleteActivity(activity)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </a>
      </mat-nav-list>

      <padded-content *ngIf="currentFormData?.activities?.length === 0 && activitiesDataSource.length === 0">No activities yet. Create one with the Add (+) button in the lower right.</padded-content>
      <padded-content *ngIf="currentFormData?.activities?.length !== activitiesDataSource.length" class="filter-warning">
        Some activities have been hidden by the Activity Filter.
        <button mat-flat-button color="primary" (click)="clearActivityListFilters()">Clear Filters</button>
        to see all activities.
      </padded-content>
    </mat-tab>
    <mat-tab label="Sample Updates">
      <padded-content>
        <mat-form-field>
          <mat-label>Initial SDM Safety Decision</mat-label>
          <mat-select formControlName="initialSdmSafetyDecision" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of initialSdmSafetyDecisionOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.initialSdmSafetyDecision.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Final SDM Safety Decision</mat-label>
          <mat-select formControlName="finalSdmSafetyDecision" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of finalSdmSafetyDecisionOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.finalSdmSafetyDecision.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Final SDM Risk Level</mat-label>
          <mat-select formControlName="finalSdmRiskLevel" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of finalSdmRiskLevelOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.finalSdmRiskLevel.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Allegation Conclusion (check all that apply)</mat-label>
          <mat-select formControlName="allegationConclusions" multiple required>
            <mat-option *ngFor="let option of allegationConclusionOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.allegationConclusions.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Referral Outcome (check all that apply)</mat-label>
          <mat-select formControlName="referralOutcomes" multiple required>
            <mat-option *ngFor="let option of referralOutcomeOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.referralOutcomes.errors | errorDisplay}}</mat-error>
        </mat-form-field>
      </padded-content>
    </mat-tab>
    <mat-tab label="Supervisor" *ngIf="displaySupervisorFields">
      <padded-content>
        <mat-form-field>
          <mat-label>Supervisor Final Review: Standards met?</mat-label>
          <mat-select formControlName="standardsMet" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of yesNoOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.standardsMet.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="currentFormData?.standardsMet === yesNoOptionsEnum.No">
          <mat-label>Reason standards not met</mat-label>
          <mat-select formControlName="reasonsStandardsNotMet" required multiple>
            <mat-option *ngFor="let option of reasonsStandardsNotMetOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.reasonsStandardsNotMet.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Supervisor Optional Comments</mat-label>
          <textarea #supervisorComments matInput maxlength="500" formControlName="supervisorComments" [cdkTextareaAutosize]="true" autocomplete="off"></textarea>
          <mat-hint align="end">{{supervisorComments.value.length}} / 500</mat-hint>
          <mat-error>{{form.controls.supervisorComments.errors | errorDisplay}}</mat-error>
        </mat-form-field>
      </padded-content>
    </mat-tab>
  </mat-tab-group>
</form>

<div class="fab-button-padding"></div>
<div class="lower-right-action">
  <button mat-fab (click)="deleteFormDialog()" *ngIf="!readonly && user?.role === UserRole.Agent" [matTooltip]="deleteFormDialogData.title" matTooltipPosition="above"><mat-icon>delete</mat-icon></button>
  <button mat-mini-fab (click)="deleteFormDialog()" *ngIf="!readonly && user?.role === UserRole.Agent" [matTooltip]="deleteFormDialogData.title" matTooltipPosition="above"><mat-icon>delete</mat-icon></button>

  <button mat-fab (click)="discardChangesDialog()" [matTooltip]="discardChangesDialogData.title" matTooltipPosition="above"><mat-icon>undo</mat-icon></button>
  <button mat-mini-fab (click)="discardChangesDialog()" [matTooltip]="discardChangesDialogData.title" matTooltipPosition="above"><mat-icon>undo</mat-icon></button>

  <button mat-fab (click)="saveForm()" *ngIf="!readonly" matTooltip="Save Form" matTooltipPosition="above"><mat-icon>save</mat-icon></button>
  <button mat-mini-fab (click)="saveForm()" *ngIf="!readonly" matTooltip="Save Form" matTooltipPosition="above"><mat-icon>save</mat-icon></button>

  <button mat-fab (click)="submitFormDialog()" *ngIf="!readonly && user?.role === UserRole.Agent" [matTooltip]="submitFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_turned_in</mat-icon></button>
  <button mat-mini-fab (click)="submitFormDialog()" *ngIf="!readonly && user?.role === UserRole.Agent" [matTooltip]="submitFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_turned_in</mat-icon></button>

  <button mat-fab (click)="sendBackFormDialog()" *ngIf="!readonly && user?.role === UserRole.Supervisor" [matTooltip]="sendBackFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_return</mat-icon></button>
  <button mat-mini-fab (click)="sendBackFormDialog()" *ngIf="!readonly && user?.role === UserRole.Supervisor" [matTooltip]="sendBackFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_return</mat-icon></button>

  <button mat-fab (click)="completeFormDialog()" *ngIf="!readonly && user?.role === UserRole.Supervisor" [matTooltip]="completeFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_turned_in</mat-icon></button>
  <button mat-mini-fab (click)="completeFormDialog()" *ngIf="!readonly && user?.role === UserRole.Supervisor" [matTooltip]="completeFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_turned_in</mat-icon></button>

  <button mat-fab (click)="editActivity(null)" *ngIf="!readonly" matTooltip="Add Activity" matTooltipPosition="above"><mat-icon>add</mat-icon></button>
  <button mat-mini-fab (click)="editActivity(null)" *ngIf="!readonly" matTooltip="Add Activity" matTooltipPosition="above"><mat-icon>add</mat-icon></button>
</div>
