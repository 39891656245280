import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { FormComponent } from '../../../form/form.component';
import { getFormTypeById } from '../../form-type-data';
import { MatSort } from '@angular/material/sort';
import { Validators } from '@angular/forms';
import { Humboldt2020IdValidator } from '../../../validators';
import { debounceTime } from 'rxjs/operators';
import { FormStatus } from '../../../form/form-status.enum';
import * as moment from 'moment';
import { MessageDialogComponent } from '../../../message-dialog/message-dialog.component';
import { Humboldt2020OngoingEfcAdoptFormData } from './humboldt2020-ongoing-efc-adopt-form-data';
import { Humboldt2020OngoingEfcAdoptActivityListColumn } from './humboldt2020-ongoing-efc-adopt-activity-list-column.enum';
import { Humboldt2020OngoingEfcAdoptActivityActivityOption } from './humboldt2020-ongoing-efc-adopt-activity-dialog/humboldt2020-ongoing-efc-adopt-activity-activity-option.enum';
import { Humboldt2020OngoingEfcAdoptCaseAssignmentLengthOption } from './humboldt2020-ongoing-efc-adopt-case-assignment-length-option.enum';
import { Humboldt2020OngoingEfcAdoptStudyCaseTypeOption } from './humboldt2020-ongoing-efc-adopt-study-case-type-option.enum';
import { Humboldt2020OngoingEfcAdoptNumberOfYouthOption } from './humboldt2020-ongoing-efc-adopt-number-of-youth-option.enum';
import { Humboldt2020OngoingEfcAdoptRecentRiskLevelOption } from './humboldt2020-ongoing-efc-adopt-recent-risk-level-option.enum';
import { Humboldt2020OngoingEfcAdoptTribalAffiliationOption } from './humboldt2020-ongoing-efc-adopt-tribal-affiliation-option.enum';
import { Humboldt2020OngoingEfcAdoptTribalAffiliationTypeOption } from './humboldt2020-ongoing-efc-adopt-tribal-affiliation-type-option.enum';
import { Humboldt2020OngoingEfcAdoptReasonsStandardsNotMetOption } from './humboldt2020-ongoing-efc-adopt-reasons-standards-not-met-option.enum';
import { isArray } from "util";
import { YesNoOption } from '../../../form/yes-no-option.enum';
import { Humboldt2020OngoingEfcAdoptActivityListFilterDialogComponent } from './humboldt2020-ongoing-efc-adopt-activity-list-filter-dialog/humboldt2020-ongoing-efc-adopt-activity-list-filter-dialog.component';
import { Humboldt2020OngoingEfcAdoptActivityDialogComponent } from './humboldt2020-ongoing-efc-adopt-activity-dialog/humboldt2020-ongoing-efc-adopt-activity-dialog.component';
export class Humboldt2020OngoingEfcAdoptComponent extends FormComponent {
    constructor(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, titleService) {
        super(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, Humboldt2020OngoingEfcAdoptFormData);
        this.apiService = apiService;
        this.authService = authService;
        this.breakpointObserver = breakpointObserver;
        this.databaseService = databaseService;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.onlineService = onlineService;
        this.route = route;
        this.router = router;
        this.titleService = titleService;
        this.formTypeId = 7;
        this.formType = getFormTypeById(this.formTypeId);
        this.formDataClass = Humboldt2020OngoingEfcAdoptFormData;
        this.currentFormData = null;
        this.activitiesDataSource = [];
        this.activityListColumnEnum = Humboldt2020OngoingEfcAdoptActivityListColumn;
        this.activityListDisplayedColumns = Object.values(Humboldt2020OngoingEfcAdoptActivityListColumn);
        this.activityListFilterForm = this.formBuilder.group({
            activity: [null],
            sorting: [null]
        });
        this.activityListFilters = {
            activity: null,
            sortColumn: null,
            sortDirection: null
        };
        this.activityListFilterInfoString = '';
        this.activityOptions = Object.values(Humboldt2020OngoingEfcAdoptActivityActivityOption);
        this.activityListSortOptions = [
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.activityDate, sortDirection: 'asc', display: 'Date Ascending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.activityDate, sortDirection: 'desc', display: 'Date Descending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.activity, sortDirection: 'asc', display: 'Activity Ascending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.activity, sortDirection: 'desc', display: 'Activity Descending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.personInvolved, sortDirection: 'asc', display: 'Person Involved Ascending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.personInvolved, sortDirection: 'desc', display: 'Person Involved Descending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.method, sortDirection: 'asc', display: 'Method Ascending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.method, sortDirection: 'desc', display: 'Method Descending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.place, sortDirection: 'asc', display: 'Place Ascending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.place, sortDirection: 'desc', display: 'Place Descending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.activityMinutesSpent, sortDirection: 'asc', display: 'Activity Minutes Spent Ascending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.activityMinutesSpent, sortDirection: 'desc', display: 'Activity Minutes Spent Descending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.travelMinutesSpent, sortDirection: 'asc', display: 'Travel Minutes Spent Ascending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.travelMinutesSpent, sortDirection: 'desc', display: 'Travel Minutes Spent Descending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.activityDescription, sortDirection: 'asc', display: 'Description Ascending' },
            { sortColumn: Humboldt2020OngoingEfcAdoptActivityListColumn.activityDescription, sortDirection: 'desc', display: 'Description Descending' },
        ];
        this.form = this.formBuilder.group({
            agentName: [null],
            createdDate: [null],
            status: [null],
            statusChangedDate: [null],
            updatedDate: [null],
            caseName: [null, [Validators.required, Validators.maxLength(200)]],
            caseId: [null, [Validators.required, Validators.maxLength(22), Humboldt2020IdValidator]],
            caseStartDate: [null, Validators.required],
            caseAssignmentLength: [null, Validators.required],
            studyCaseType: [null, Validators.required],
            youthPlacedOutOfCounty: [null],
            numberOfYouthOnCasePlan: [null],
            ageOfSampledYouth: [null, [Validators.required, Validators.min(0), Validators.max(25)]],
            mostRecentRiskLevelAtStartOfMonth: [null],
            tribalAffiliation: [null, Validators.required],
            tribalAffiliationType: [[]],
            parentalRightTerminated: [null, Validators.required],
            parentingYouth: [null],
            youthChildOpenCwsCase: [null],
            comments: [null, Validators.maxLength(500)],
            youthAwolDuringMonth: [null, Validators.required],
            youthOpenToIntensiveCareCoordinationServicesDuringMonth: [null, Validators.required],
            newScreenedReferralOnCaseDuringMonth: [null],
            numberOfPlacementChangesDuringMonth: [null],
            newAdoptiveHomeIdentifiedDuringMonth: [null],
            caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth: [null, Validators.required],
            caseStatusChangeDate: [null],
            activityListFilter: this.activityListFilterForm,
            standardsMet: [null],
            reasonsStandardsNotMet: [[]],
            supervisorComments: [null, Validators.maxLength(500)]
        });
        this.caseAssignmentLengthOptions = Object.values(Humboldt2020OngoingEfcAdoptCaseAssignmentLengthOption);
        this.studyCaseTypeOptions = Object.values(Humboldt2020OngoingEfcAdoptStudyCaseTypeOption);
        this.numberOfYouthOnCasePlanOptions = Object.values(Humboldt2020OngoingEfcAdoptNumberOfYouthOption);
        this.mostRecentRiskLevelAtStartOfMonthOptions = Object.values(Humboldt2020OngoingEfcAdoptRecentRiskLevelOption);
        this.tribalAffiliationOptionEnum = Humboldt2020OngoingEfcAdoptTribalAffiliationOption;
        this.tribalAffiliationOptions = Object.values(Humboldt2020OngoingEfcAdoptTribalAffiliationOption);
        this.tribalAffiliationTypeOptions = Object.values(Humboldt2020OngoingEfcAdoptTribalAffiliationTypeOption);
        this.reasonsStandardsNotMetOptions = Object.values(Humboldt2020OngoingEfcAdoptReasonsStandardsNotMetOption);
        this.showYouthPlacedOutOfCountyQuestion = false;
        this.showNumberOfYouthOnCasePlanQuestion = false;
        this.showMostRecentRiskLevelAtStartOfMonthQuestion = false;
        this.showParentingYouthQuestion = false;
        this.showYouthChildOpenCwsCaseQuestion = false;
        this.showNewScreenedReferralOnCaseDuringMonthQuestion = false;
        this.showNumberOfPlacementChangesDuringMonthQuestion = false;
        this.showNewAdoptiveHomeIdentifiedDuringMonthQuestion = false;
        this.showCaseStatusChangeDateQuestion = false;
        this.loadFormTypeSettings();
        this.formChangeSubscriptions();
        this.saveFormDialogErrorData.message = 'You must provide a Sampled Case Name to save the form.';
        this.sendBackFormDialogErrorData.message = 'You must provide a Sampled Case Name to save the form.';
        this.submitFormDialogData.message = 'Submitting the form will send it to your supervisor for review and you will no longer be able to edit this form. Make sure you have:';
        this.submitFormDialogData.messageList = [
            'Completed all of the fields in the Sample Information and Sample Updates tabs.',
            'Recorded all the time spent working on this case during the month.'
        ];
        this.discardChangesDialogData.message = 'If you discard changes, you will lose any changes you have made to this' +
            ' form since you last saved it. This includes any changes to sample information, sample updates, and activities.' +
            ' Are you sure you want to do this?';
        this.sendBackFormDialogErrorData.title = 'Send Back to Caseworker Error';
        this.sendBackFormDialogData.title = 'Send Back to Caseworker';
        this.sendBackFormDialogData.message = 'This action will send the form back to the Caseworker for modification.' +
            ' This will revert the status of the form to "In Progress" and' +
            ' you will not be able to edit the form until the Caseworker submits it again.' +
            ' Are you sure you want to do this?';
        this.sendBackFormDialogData.okButtonLabel = 'Send Back to Caseworker';
        this.completeFormDialogData.message = 'Approving the form will remove it from your queue and you will no longer be able to edit this form. Make sure:';
        this.completeFormDialogData.messageList = [
            'The form captures all the work that was completed by the caseworker.',
            'You have reviewed the form for required work activities designated by standards.',
            'Time is recorded in minutes.'
        ];
    }
    set activityListSortViewChild(value) {
        this.activityListSort = value;
        // This timeout is needed or angular complains Expression has changed after it was checked.
        window.setTimeout(() => {
            // default in the current set sorting when the sorter is set
            // this happens if the width goes from isSmallDisplay to isLargeDisplay
            if (this.activityListSort && this.activityListFilterForm && this.activityListFilterForm.controls.sorting.value) {
                const sorting = this.activityListFilterForm.controls.sorting.value;
                if (this.activityListSort.active != sorting.sortColumn || this.activityListSort.direction != sorting.sortDirection) {
                    if (this.activityListSort) {
                        this.activityListSort.sort({
                            id: sorting.sortColumn,
                            start: sorting.sortDirection,
                            disableClear: true
                        });
                    }
                }
            }
        });
    }
    /* adds subscriptions for each of the form fields to update this.currentFormData */
    formChangeSubscriptions() {
        this.form.controls.caseName.valueChanges.subscribe(caseName => {
            if (this.currentFormData && this.currentFormData.name !== caseName) {
                this.currentFormData.name = caseName;
                this.saveCurrentFormValues();
            }
            if (this.formType && this.currentFormData) {
                const title = this.formType.name + ' - ' + this.currentFormData.name;
                if (this.titleService.title.value !== title) {
                    this.titleService.title.next(title);
                }
            }
        });
        this.form.controls.caseId.valueChanges.subscribe(caseId => {
            if (this.currentFormData && this.currentFormData.caseId !== caseId) {
                this.currentFormData.caseId = caseId;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.caseStartDate.valueChanges.subscribe(caseStartDate => {
            const numberValue = caseStartDate !== null ? parseInt(caseStartDate.format('x')) : null;
            if (this.currentFormData && this.currentFormData.caseStartDate !== numberValue) {
                this.currentFormData.caseStartDate = numberValue;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.caseAssignmentLength.valueChanges.subscribe(caseAssignmentLength => {
            if (this.currentFormData && this.currentFormData.caseAssignmentLength !== caseAssignmentLength) {
                this.currentFormData.caseAssignmentLength = caseAssignmentLength;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.studyCaseType.valueChanges.subscribe(studyCaseType => {
            if (this.currentFormData && this.currentFormData.studyCaseType !== studyCaseType) {
                this.currentFormData.caseType = studyCaseType;
                this.currentFormData.studyCaseType = studyCaseType;
                this.saveCurrentFormValues();
                this.updateYouthPlacedOutOfCountyValidators();
                this.updateNumberOfYouthOnCasePlanValidators();
                this.updateMostRecentRiskLevelAtStartOfMonthValidators();
                this.updateParentingYouthValidators();
                this.updateYouthChildOpenCwsCaseValidators();
                this.updateNewScreenedReferralOnCaseDuringMonthValidators();
                this.updateNumberOfPlacementChangesDuringMonthValidators();
                this.updateNewAdoptiveHomeIdentifiedDuringMonthValidators();
            }
        });
        this.form.controls.youthPlacedOutOfCounty.valueChanges.subscribe(youthPlacedOutOfCounty => {
            if (this.currentFormData && this.currentFormData.youthPlacedOutOfCounty !== youthPlacedOutOfCounty) {
                this.currentFormData.youthPlacedOutOfCounty = youthPlacedOutOfCounty;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.numberOfYouthOnCasePlan.valueChanges.subscribe(numberOfYouthOnCasePlan => {
            if (this.currentFormData && this.currentFormData.numberOfYouthOnCasePlan !== numberOfYouthOnCasePlan) {
                this.currentFormData.numberOfYouthOnCasePlan = numberOfYouthOnCasePlan;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.ageOfSampledYouth.valueChanges.subscribe(ageOfSampledYouth => {
            if (this.currentFormData && this.currentFormData.ageOfSampledYouth !== ageOfSampledYouth) {
                this.currentFormData.ageOfSampledYouth = ageOfSampledYouth;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.mostRecentRiskLevelAtStartOfMonth.valueChanges.subscribe(mostRecentRiskLevelAtStartOfMonth => {
            if (this.currentFormData && this.currentFormData.mostRecentRiskLevelAtStartOfMonth !== mostRecentRiskLevelAtStartOfMonth) {
                this.currentFormData.mostRecentRiskLevelAtStartOfMonth = mostRecentRiskLevelAtStartOfMonth;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.tribalAffiliation.valueChanges.subscribe(tribalAffiliation => {
            if (this.currentFormData && this.currentFormData.tribalAffiliation !== tribalAffiliation) {
                this.currentFormData.tribalAffiliation = tribalAffiliation;
                this.saveCurrentFormValues();
                this.updateTribalAffiliationTypeValidators();
            }
        });
        this.form.controls.tribalAffiliationType.valueChanges.subscribe(tribalAffiliationTypes => {
            let tribalAffiliationTypeLocalTribe = false;
            let tribalAffiliationTypeNonLocalTribe = false;
            if (isArray(tribalAffiliationTypes)) {
                tribalAffiliationTypes.forEach(tribalAffiliationType => {
                    switch (tribalAffiliationType) {
                        case Humboldt2020OngoingEfcAdoptTribalAffiliationTypeOption.LocalTribe:
                            tribalAffiliationTypeLocalTribe = true;
                            break;
                        case Humboldt2020OngoingEfcAdoptTribalAffiliationTypeOption.NonLocalTribe:
                            tribalAffiliationTypeNonLocalTribe = true;
                            break;
                    }
                });
            }
            let saveCurrentFormValues = false;
            if (this.currentFormData && this.currentFormData.tribalAffiliationTypeLocalTribe !== tribalAffiliationTypeLocalTribe) {
                this.currentFormData.tribalAffiliationTypeLocalTribe = tribalAffiliationTypeLocalTribe;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.tribalAffiliationTypeNonLocalTribe !== tribalAffiliationTypeNonLocalTribe) {
                this.currentFormData.tribalAffiliationTypeNonLocalTribe = tribalAffiliationTypeNonLocalTribe;
                saveCurrentFormValues = true;
            }
            if (saveCurrentFormValues) {
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.parentalRightTerminated.valueChanges.subscribe(parentalRightTerminated => {
            if (this.currentFormData && this.currentFormData.parentalRightTerminated !== parentalRightTerminated) {
                this.currentFormData.parentalRightTerminated = parentalRightTerminated;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.parentingYouth.valueChanges.subscribe(parentingYouth => {
            if (this.currentFormData && this.currentFormData.parentingYouth !== parentingYouth) {
                this.currentFormData.parentingYouth = parentingYouth;
                this.saveCurrentFormValues();
                this.updateYouthChildOpenCwsCaseValidators();
            }
        });
        this.form.controls.youthChildOpenCwsCase.valueChanges.subscribe(youthChildOpenCwsCase => {
            if (this.currentFormData && this.currentFormData.youthChildOpenCwsCase !== youthChildOpenCwsCase) {
                this.currentFormData.youthChildOpenCwsCase = youthChildOpenCwsCase;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.comments.valueChanges.subscribe(comments => {
            if (this.currentFormData && this.currentFormData.comments !== comments) {
                this.currentFormData.comments = comments;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.youthAwolDuringMonth.valueChanges.subscribe(youthAwolDuringMonth => {
            if (this.currentFormData && this.currentFormData.youthAwolDuringMonth !== youthAwolDuringMonth) {
                this.currentFormData.youthAwolDuringMonth = youthAwolDuringMonth;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.youthOpenToIntensiveCareCoordinationServicesDuringMonth.valueChanges.subscribe(youthOpenToIntensiveCareCoordinationServicesDuringMonth => {
            if (this.currentFormData && this.currentFormData.youthOpenToIntensiveCareCoordinationServicesDuringMonth !== youthOpenToIntensiveCareCoordinationServicesDuringMonth) {
                this.currentFormData.youthOpenToIntensiveCareCoordinationServicesDuringMonth = youthOpenToIntensiveCareCoordinationServicesDuringMonth;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.newScreenedReferralOnCaseDuringMonth.valueChanges.subscribe(newScreenedReferralOnCaseDuringMonth => {
            if (this.currentFormData && this.currentFormData.newScreenedReferralOnCaseDuringMonth !== newScreenedReferralOnCaseDuringMonth) {
                this.currentFormData.newScreenedReferralOnCaseDuringMonth = newScreenedReferralOnCaseDuringMonth;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.numberOfPlacementChangesDuringMonth.valueChanges.subscribe(numberOfPlacementChangesDuringMonth => {
            if (this.currentFormData && this.currentFormData.numberOfPlacementChangesDuringMonth !== numberOfPlacementChangesDuringMonth) {
                this.currentFormData.numberOfPlacementChangesDuringMonth = numberOfPlacementChangesDuringMonth;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.newAdoptiveHomeIdentifiedDuringMonth.valueChanges.subscribe(newAdoptiveHomeIdentifiedDuringMonth => {
            if (this.currentFormData && this.currentFormData.newAdoptiveHomeIdentifiedDuringMonth !== newAdoptiveHomeIdentifiedDuringMonth) {
                this.currentFormData.newAdoptiveHomeIdentifiedDuringMonth = newAdoptiveHomeIdentifiedDuringMonth;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth.valueChanges.subscribe(caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth => {
            if (this.currentFormData && this.currentFormData.caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth !== caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth) {
                this.currentFormData.caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth = caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth;
                this.saveCurrentFormValues();
                this.updateCaseStatusChangeDateValidators();
            }
        });
        this.form.controls.caseStatusChangeDate.valueChanges.subscribe(caseStatusChangeDate => {
            const numberValue = caseStatusChangeDate !== null ? parseInt(caseStatusChangeDate.format('x')) : null;
            if (this.currentFormData && this.currentFormData.caseStatusChangeDate !== numberValue) {
                this.currentFormData.caseStatusChangeDate = numberValue;
                this.saveCurrentFormValues();
            }
        });
        this.activityListFilterForm.controls.sorting.valueChanges.subscribe((sorting) => {
            if (sorting && this.activityListSort && (this.activityListSort.active !== sorting.sortColumn || (this.activityListSort.direction || 'asc') !== sorting.sortDirection)) {
                this.activityListSort.sort({
                    id: sorting.sortColumn,
                    start: sorting.sortDirection,
                    disableClear: true
                });
            }
        });
        this.activityListFilterForm.valueChanges.pipe(debounceTime(100)).subscribe(values => {
            let saveActivityListFilters = false;
            if (this.activityListFilters.activity !== values.activity) {
                this.activityListFilters.activity = values.activity;
                saveActivityListFilters = true;
            }
            if (values.sorting) {
                if (this.activityListFilters.sortColumn !== values.sorting.sortColumn || this.activityListFilters.sortDirection !== values.sorting.sortDirection) {
                    this.activityListFilters.sortColumn = values.sorting.sortColumn;
                    this.activityListFilters.sortDirection = values.sorting.sortDirection;
                    saveActivityListFilters = true;
                }
            }
            else if (this.activityListFilters.sortColumn !== null || this.activityListFilters.sortDirection !== null) {
                this.activityListFilters.sortColumn = null;
                this.activityListFilters.sortDirection = null;
                saveActivityListFilters = true;
            }
            if (saveActivityListFilters) {
                this.saveFormTypeSettings();
            }
            this.activityListFilterAndSortData();
        });
        this.form.controls.standardsMet.valueChanges.subscribe(standardsMet => {
            if (this.currentFormData && this.currentFormData.standardsMet !== standardsMet) {
                this.currentFormData.standardsMet = standardsMet;
                this.saveCurrentFormValues();
                this.updateReasonsStandardsNotMetValidators();
            }
        });
        this.form.controls.reasonsStandardsNotMet.valueChanges.subscribe(reasonsStandardsNotMet => {
            let reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = false;
            let reasonsStandardsNotMetCaseworkerStoppedTracking = false;
            let reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = false;
            let reasonsStandardsNotMetOther = false;
            if (isArray(reasonsStandardsNotMet)) {
                reasonsStandardsNotMet.forEach(reasonStandardsNotMet => {
                    switch (reasonStandardsNotMet) {
                        case Humboldt2020OngoingEfcAdoptReasonsStandardsNotMetOption.NumberOfContactsOtherStandardsNotMet:
                            reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = true;
                            break;
                        case Humboldt2020OngoingEfcAdoptReasonsStandardsNotMetOption.CaseworkerStoppedTracking:
                            reasonsStandardsNotMetCaseworkerStoppedTracking = true;
                            break;
                        case Humboldt2020OngoingEfcAdoptReasonsStandardsNotMetOption.NotAllWorkDuringMonthRecorded:
                            reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = true;
                            break;
                        case Humboldt2020OngoingEfcAdoptReasonsStandardsNotMetOption.Other:
                            reasonsStandardsNotMetOther = true;
                            break;
                    }
                });
            }
            let saveCurrentFormValues = false;
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet !== reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet) {
                this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking !== reasonsStandardsNotMetCaseworkerStoppedTracking) {
                this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking = reasonsStandardsNotMetCaseworkerStoppedTracking;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded !== reasonsStandardsNotMetNotAllWorkDuringMonthRecorded) {
                this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = reasonsStandardsNotMetNotAllWorkDuringMonthRecorded;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetOther !== reasonsStandardsNotMetOther) {
                this.currentFormData.reasonsStandardsNotMetOther = reasonsStandardsNotMetOther;
                saveCurrentFormValues = true;
            }
            if (saveCurrentFormValues) {
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.supervisorComments.valueChanges.subscribe(supervisorComments => {
            if (this.currentFormData && this.currentFormData.supervisorComments !== supervisorComments) {
                this.currentFormData.supervisorComments = supervisorComments;
                this.saveCurrentFormValues();
            }
        });
    }
    /* updates the validators for youthPlacedOutOfCounty */
    updateYouthPlacedOutOfCountyValidators() {
        if (this.currentFormData && this.currentFormData.studyCaseType !== null &&
            this.currentFormData.studyCaseType !== Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.FamilyMaintenance &&
            this.currentFormData.studyCaseType !== Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.CourtFamilyMaintenance) {
            this.form.controls.youthPlacedOutOfCounty.setValidators(Validators.required);
            this.showYouthPlacedOutOfCountyQuestion = true;
        }
        else {
            this.form.controls.youthPlacedOutOfCounty.clearValidators();
            this.showYouthPlacedOutOfCountyQuestion = false;
        }
        this.form.controls.youthPlacedOutOfCounty.updateValueAndValidity();
    }
    /* updates the validators for numberOfYouthOnCasePlan */
    updateNumberOfYouthOnCasePlanValidators() {
        if (this.currentFormData && this.currentFormData.studyCaseType !== null &&
            this.currentFormData.studyCaseType !== Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.ExtendedFosterCare) {
            this.form.controls.numberOfYouthOnCasePlan.setValidators(Validators.required);
            this.showNumberOfYouthOnCasePlanQuestion = true;
        }
        else {
            this.form.controls.numberOfYouthOnCasePlan.clearValidators();
            this.showNumberOfYouthOnCasePlanQuestion = false;
        }
        this.form.controls.numberOfYouthOnCasePlan.updateValueAndValidity();
    }
    /* updates the validators for mostRecentRiskLevelAtStartOfMonth */
    updateMostRecentRiskLevelAtStartOfMonthValidators() {
        if (this.currentFormData && this.currentFormData.studyCaseType !== null &&
            this.currentFormData.studyCaseType !== Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpAdoptions) {
            this.form.controls.mostRecentRiskLevelAtStartOfMonth.setValidators(Validators.required);
            this.showMostRecentRiskLevelAtStartOfMonthQuestion = true;
        }
        else {
            this.form.controls.mostRecentRiskLevelAtStartOfMonth.clearValidators();
            this.showMostRecentRiskLevelAtStartOfMonthQuestion = false;
        }
        this.form.controls.mostRecentRiskLevelAtStartOfMonth.updateValueAndValidity();
    }
    /* updates the validators for tribalAffiliationType */
    updateTribalAffiliationTypeValidators() {
        if (this.currentFormData && this.currentFormData.tribalAffiliation === Humboldt2020OngoingEfcAdoptTribalAffiliationOption.YesMaybe) {
            this.form.controls.tribalAffiliationType.setValidators(Validators.required);
        }
        else {
            this.form.controls.tribalAffiliationType.clearValidators();
        }
        this.form.controls.tribalAffiliationType.updateValueAndValidity();
    }
    /* updates the validators for parentingYouth */
    updateParentingYouthValidators() {
        if (this.currentFormData && this.currentFormData.studyCaseType !== null &&
            this.currentFormData.studyCaseType !== Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpAdoptions) {
            this.form.controls.parentingYouth.setValidators(Validators.required);
            this.showParentingYouthQuestion = true;
        }
        else {
            this.form.controls.parentingYouth.clearValidators();
            this.showParentingYouthQuestion = false;
        }
        this.form.controls.parentingYouth.updateValueAndValidity();
    }
    /* updates the validators for youthChildOpenCwsCase */
    updateYouthChildOpenCwsCaseValidators() {
        if (this.currentFormData && this.currentFormData.studyCaseType !== null &&
            this.currentFormData.studyCaseType !== Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpAdoptions &&
            this.currentFormData.parentingYouth === YesNoOption.Yes) {
            this.form.controls.youthChildOpenCwsCase.setValidators(Validators.required);
            this.showYouthChildOpenCwsCaseQuestion = true;
        }
        else {
            this.form.controls.youthChildOpenCwsCase.clearValidators();
            this.showYouthChildOpenCwsCaseQuestion = false;
        }
        this.form.controls.youthChildOpenCwsCase.updateValueAndValidity();
    }
    /* updates the validators for newScreenedReferralOnCaseDuringMonth */
    updateNewScreenedReferralOnCaseDuringMonthValidators() {
        if (this.currentFormData && this.currentFormData.studyCaseType !== null &&
            this.currentFormData.studyCaseType !== Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.ExtendedFosterCare) {
            this.form.controls.newScreenedReferralOnCaseDuringMonth.setValidators(Validators.required);
            this.showNewScreenedReferralOnCaseDuringMonthQuestion = true;
        }
        else {
            this.form.controls.newScreenedReferralOnCaseDuringMonth.clearValidators();
            this.showNewScreenedReferralOnCaseDuringMonthQuestion = false;
        }
        this.form.controls.newScreenedReferralOnCaseDuringMonth.updateValueAndValidity();
    }
    /* updates the validators for numberOfPlacementChangesDuringMonth */
    updateNumberOfPlacementChangesDuringMonthValidators() {
        if (this.currentFormData &&
            (this.currentFormData.studyCaseType === Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.FamilyReunification ||
                this.currentFormData.studyCaseType === Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.ExtendedFosterCare ||
                this.currentFormData.studyCaseType === Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpAdoptions ||
                this.currentFormData.studyCaseType === Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpGuardianship ||
                this.currentFormData.studyCaseType === Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpAppla)) {
            this.form.controls.numberOfPlacementChangesDuringMonth.setValidators([Validators.required, Validators.min(0), Validators.max(99)]);
            this.showNumberOfPlacementChangesDuringMonthQuestion = true;
        }
        else {
            this.form.controls.numberOfPlacementChangesDuringMonth.clearValidators();
            this.showNumberOfPlacementChangesDuringMonthQuestion = false;
        }
        this.form.controls.numberOfPlacementChangesDuringMonth.updateValueAndValidity();
    }
    /* updates the validators for newAdoptiveHomeIdentifiedDuringMonth */
    updateNewAdoptiveHomeIdentifiedDuringMonthValidators() {
        if (this.currentFormData && this.currentFormData.studyCaseType !== null &&
            this.currentFormData.studyCaseType === Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpAdoptions) {
            this.form.controls.newAdoptiveHomeIdentifiedDuringMonth.setValidators(Validators.required);
            this.showNewAdoptiveHomeIdentifiedDuringMonthQuestion = true;
        }
        else {
            this.form.controls.newAdoptiveHomeIdentifiedDuringMonth.clearValidators();
            this.showNewAdoptiveHomeIdentifiedDuringMonthQuestion = false;
        }
        this.form.controls.newAdoptiveHomeIdentifiedDuringMonth.updateValueAndValidity();
    }
    /* updates the validators for caseStatusChangeDate */
    updateCaseStatusChangeDateValidators() {
        if (this.currentFormData && this.currentFormData.caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth === YesNoOption.Yes) {
            this.form.controls.caseStatusChangeDate.setValidators(Validators.required);
            this.showCaseStatusChangeDateQuestion = true;
        }
        else {
            this.form.controls.caseStatusChangeDate.clearValidators();
            this.showCaseStatusChangeDateQuestion = false;
        }
        this.form.controls.caseStatusChangeDate.updateValueAndValidity();
    }
    /* updates the validators for standardsMet */
    updateStandardsMetValidators() {
        if (this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
            this.form.controls.standardsMet.setValidators(Validators.required);
        }
        else {
            this.form.controls.standardsMet.clearValidators();
        }
        this.form.controls.standardsMet.updateValueAndValidity();
    }
    /* updates the validators for reasonsStandardsNotMet */
    updateReasonsStandardsNotMetValidators() {
        if (this.currentFormData && this.currentFormData.status === FormStatus.InReview && this.currentFormData.standardsMet === YesNoOption.No) {
            this.form.controls.reasonsStandardsNotMet.setValidators(Validators.required);
        }
        else {
            this.form.controls.reasonsStandardsNotMet.clearValidators();
        }
        this.form.controls.reasonsStandardsNotMet.updateValueAndValidity();
    }
    /* Hydrates form with currentFormData values */
    afterFormDataLoaded() {
        super.afterFormDataLoaded();
        if (this.formType && this.currentFormData) {
            const title = this.formType.name + ' - ' + this.currentFormData.name;
            if (this.titleService.title.value !== title) {
                this.titleService.title.next(title);
            }
        }
        if (this.currentFormData) {
            this.form.controls.agentName.setValue(this.currentFormData.created.userName);
            this.form.controls.createdDate.setValue(moment(this.currentFormData.created.timestamp, 'x').format('L LT'));
            this.form.controls.status.setValue(this.currentFormData.status);
            this.form.controls.statusChangedDate.setValue(moment(this.currentFormData.statusChangeTimestamp, 'x').format('L LT'));
            this.form.controls.updatedDate.setValue(moment(this.currentFormData.updated.timestamp, 'x').format('L LT'));
            this.form.controls.caseName.setValue(this.currentFormData.name);
            this.form.controls.caseId.setValue(this.currentFormData.caseId);
            this.form.controls.caseStartDate.setValue(this.currentFormData.caseStartDate !== null ? moment(this.currentFormData.caseStartDate, 'x') : null);
            this.form.controls.caseAssignmentLength.setValue(this.currentFormData.caseAssignmentLength);
            this.form.controls.studyCaseType.setValue(this.currentFormData.studyCaseType);
            this.form.controls.youthPlacedOutOfCounty.setValue(this.currentFormData.youthPlacedOutOfCounty);
            this.form.controls.numberOfYouthOnCasePlan.setValue(this.currentFormData.numberOfYouthOnCasePlan);
            this.form.controls.ageOfSampledYouth.setValue(this.currentFormData.ageOfSampledYouth);
            this.form.controls.mostRecentRiskLevelAtStartOfMonth.setValue(this.currentFormData.mostRecentRiskLevelAtStartOfMonth);
            this.form.controls.tribalAffiliation.setValue(this.currentFormData.tribalAffiliation);
            const tribalAffiliationType = [];
            if (this.currentFormData.tribalAffiliationTypeLocalTribe)
                tribalAffiliationType.push(Humboldt2020OngoingEfcAdoptTribalAffiliationTypeOption.LocalTribe);
            if (this.currentFormData.tribalAffiliationTypeNonLocalTribe)
                tribalAffiliationType.push(Humboldt2020OngoingEfcAdoptTribalAffiliationTypeOption.NonLocalTribe);
            this.form.controls.tribalAffiliationType.setValue(tribalAffiliationType);
            this.form.controls.parentalRightTerminated.setValue(this.currentFormData.parentalRightTerminated);
            this.form.controls.parentingYouth.setValue(this.currentFormData.parentingYouth);
            this.form.controls.youthChildOpenCwsCase.setValue(this.currentFormData.youthChildOpenCwsCase);
            this.form.controls.comments.setValue(this.currentFormData.comments);
            this.form.controls.youthAwolDuringMonth.setValue(this.currentFormData.youthAwolDuringMonth);
            this.form.controls.youthOpenToIntensiveCareCoordinationServicesDuringMonth.setValue(this.currentFormData.youthOpenToIntensiveCareCoordinationServicesDuringMonth);
            this.form.controls.newScreenedReferralOnCaseDuringMonth.setValue(this.currentFormData.newScreenedReferralOnCaseDuringMonth);
            this.form.controls.numberOfPlacementChangesDuringMonth.setValue(this.currentFormData.numberOfPlacementChangesDuringMonth);
            this.form.controls.newAdoptiveHomeIdentifiedDuringMonth.setValue(this.currentFormData.newAdoptiveHomeIdentifiedDuringMonth);
            this.form.controls.caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth.setValue(this.currentFormData.caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth);
            this.form.controls.caseStatusChangeDate.setValue(this.currentFormData.caseStatusChangeDate !== null ? moment(this.currentFormData.caseStatusChangeDate, 'x') : null);
            this.activityListFilterAndSortData();
            this.form.controls.standardsMet.setValue(this.currentFormData.standardsMet);
            const reasonsStandardsNotMet = [];
            if (this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet)
                reasonsStandardsNotMet.push(Humboldt2020OngoingEfcAdoptReasonsStandardsNotMetOption.NumberOfContactsOtherStandardsNotMet);
            if (this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking)
                reasonsStandardsNotMet.push(Humboldt2020OngoingEfcAdoptReasonsStandardsNotMetOption.CaseworkerStoppedTracking);
            if (this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded)
                reasonsStandardsNotMet.push(Humboldt2020OngoingEfcAdoptReasonsStandardsNotMetOption.NotAllWorkDuringMonthRecorded);
            if (this.currentFormData.reasonsStandardsNotMetOther)
                reasonsStandardsNotMet.push(Humboldt2020OngoingEfcAdoptReasonsStandardsNotMetOption.Other);
            this.form.controls.reasonsStandardsNotMet.setValue(reasonsStandardsNotMet);
            this.form.controls.supervisorComments.setValue(this.currentFormData.supervisorComments);
            this.updateYouthPlacedOutOfCountyValidators();
            this.updateNumberOfYouthOnCasePlanValidators();
            this.updateMostRecentRiskLevelAtStartOfMonthValidators();
            this.updateTribalAffiliationTypeValidators();
            this.updateParentingYouthValidators();
            this.updateYouthChildOpenCwsCaseValidators();
            this.updateNewScreenedReferralOnCaseDuringMonthValidators();
            this.updateNumberOfPlacementChangesDuringMonthValidators();
            this.updateNewAdoptiveHomeIdentifiedDuringMonthValidators();
            this.updateCaseStatusChangeDateValidators();
            this.updateStandardsMetValidators();
            this.updateReasonsStandardsNotMetValidators();
            // See if the loaded data has a current activity
            // This should only happen if the loaded record is a current form and the user was in the middle of editing that dialog
            if (this.currentFormData.currentActivity) {
                this.editActivity(this.currentFormData.currentActivity);
            }
        }
    }
    /**
     * This function does validation other than form.valid before submitting or completing
     * If validation does not pass it should return false and possibly set
     * submitFormDialogErrorData.message or completeFormDialogErrorData.message
     */
    extraFormValidation(action) {
        let errorMessage = null;
        if (this.currentFormData !== null && isArray(this.currentFormData.activities)) {
            if (this.currentFormData.studyCaseType === Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpAdoptions) {
                const missingRelatedToAdoptionFinalizationActivities = this.currentFormData.activities.filter((activity) => !activity.relatedToAdoptionFinalization);
                if (missingRelatedToAdoptionFinalizationActivities.length) {
                    errorMessage = 'There are activities on the form where the "Was this activity related to adoptions finalization?" question has not been answered.' +
                        ' This question is required for Study Case Type of ' + Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpAdoptions + '.' +
                        ' Please update these activities.';
                }
            }
            if (errorMessage !== null) {
                if (action === 'submit') {
                    this.submitFormDialogErrorData.message = errorMessage;
                }
                if (action === 'complete') {
                    this.completeFormDialogErrorData.message = errorMessage;
                }
                return false;
            }
        }
        return true;
    }
    loadFormTypeSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const settingsString = yield this.databaseService.getFormTypeSettings(this.formTypeId);
                if (settingsString) {
                    const settings = JSON.parse(settingsString);
                    if (settings.activityListFilters) {
                        this.activityListFilters = settings.activityListFilters;
                        this.activityListFilterForm.controls.activity.setValue(this.activityListFilters.activity);
                        const activitySorting = this.activityListSortOptions.filter(sorting => sorting.sortColumn === this.activityListFilters.sortColumn && sorting.sortDirection === this.activityListFilters.sortDirection);
                        if (activitySorting.length > 0) {
                            this.activityListFilterForm.controls.sorting.setValue(activitySorting[0]);
                        }
                        else {
                            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
                        }
                    }
                    else {
                        this.clearActivityListFilters();
                    }
                }
                else {
                    this.clearActivityListFilters();
                }
            }
            catch (error) {
                console.error('Error retrieving form type settings', error);
            }
        });
    }
    saveFormTypeSettings() {
        try {
            const settings = {
                activityListFilters: this.activityListFilters
            };
            this.databaseService.saveFormTypeSettings(this.formTypeId, JSON.stringify(settings));
        }
        catch (error) {
            console.error('Error saving form type settings', error);
        }
    }
    activityListFilterAndSortData() {
        let sortColumn = Humboldt2020OngoingEfcAdoptActivityListColumn.activityDate;
        let sortDirection = 'asc';
        if (this.activityListFilterForm.controls.sorting.value) {
            const sorting = this.activityListFilterForm.controls.sorting.value;
            sortColumn = sorting.sortColumn;
            sortDirection = sorting.sortDirection;
        }
        if (this.currentFormData && this.currentFormData.activities) {
            this.activitiesDataSource = this.currentFormData.activities.filter((activity) => {
                return !(this.activityListFilterForm.controls.activity.value && activity.activity !== this.activityListFilterForm.controls.activity.value);
            }).sort((activity1, activity2) => {
                switch (sortColumn) {
                    case Humboldt2020OngoingEfcAdoptActivityListColumn.activityDate:
                        if ((activity1.activityDate || 0) > (activity2.activityDate || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityDate || 0) < (activity2.activityDate || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020OngoingEfcAdoptActivityListColumn.activity:
                        if ((activity1.activity || '') > (activity2.activity || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activity || '') < (activity2.activity || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020OngoingEfcAdoptActivityListColumn.personInvolved:
                        if ((activity1.personInvolvedDisplay || '') > (activity2.personInvolvedDisplay || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.personInvolvedDisplay || '') < (activity2.personInvolvedDisplay || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020OngoingEfcAdoptActivityListColumn.method:
                        if ((activity1.method || '') > (activity2.method || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.method || '') < (activity2.method || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020OngoingEfcAdoptActivityListColumn.place:
                        if ((activity1.place || '') > (activity2.place || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.place || '') < (activity2.place || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020OngoingEfcAdoptActivityListColumn.activityMinutesSpent:
                        if ((activity1.activityMinutesSpent || 0) > (activity2.activityMinutesSpent || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityMinutesSpent || 0) < (activity2.activityMinutesSpent || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020OngoingEfcAdoptActivityListColumn.travelMinutesSpent:
                        if ((activity1.travelMinutesSpent || 0) > (activity2.travelMinutesSpent || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.travelMinutesSpent || 0) < (activity2.travelMinutesSpent || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020OngoingEfcAdoptActivityListColumn.activityDescription:
                        if ((activity1.activityDescription || '') > (activity2.activityDescription || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityDescription || '') < (activity2.activityDescription || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                }
                // sensible defaults if the key doesn't exist, or if the initial result is a tie
                if ((activity1.activityDate || 0) < (activity2.activityDate || 0))
                    return -1;
                if ((activity1.activityDate || 0) > (activity2.activityDate || 0))
                    return 1;
                return 0;
            });
        }
        this.setActivityListFilterInfoString();
    }
    setActivityListFilterInfoString() {
        const info = [];
        if (this.activityListFilterForm.controls.activity.value) {
            info.push('Activity Filter: ' + this.activityListFilterForm.controls.activity.value);
        }
        if (this.activityListFilterForm.controls.sorting.value) {
            info.push('Sort: ' + this.activityListFilterForm.controls.sorting.value.display);
        }
        this.activityListFilterInfoString = info.join(', ');
    }
    clearActivityListFilters() {
        if (this.activityListFilterForm.controls.activity.value !== null)
            this.activityListFilterForm.controls.activity.setValue(null);
        if (this.activityListFilterForm.controls.sorting.value !== this.activityListSortOptions[0])
            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
    }
    openActivityListFilterDialog() {
        const dialogData = {
            filterForm: this.activityListFilterForm,
            clearFunction: this.clearActivityListFilters.bind(this),
            activityOptions: this.activityOptions,
            sortOptions: this.activityListSortOptions
        };
        this.dialog.open(Humboldt2020OngoingEfcAdoptActivityListFilterDialogComponent, {
            data: dialogData,
            width: '800px'
        });
    }
    /**
     * update the form filter when the table headers are clicked to keep them in sync
     */
    onActivityListSortChange(sort) {
        const sortingValue = this.activityListSortOptions.filter(sortOption => sortOption.sortColumn === sort.active && sortOption.sortDirection === (sort.direction || 'asc'));
        if (sortingValue.length > 0 && sortingValue[0] !== this.activityListFilterForm.controls.sorting.value) {
            this.activityListFilterForm.controls.sorting.setValue(sortingValue[0]);
        }
    }
    editActivity(activity) {
        const dialogData = {
            studyCaseType: this.currentFormData ? this.currentFormData.studyCaseType : null,
            activity: activity,
            updateCurrentActivity: this.updateCurrentActivity.bind(this)
        };
        this.dialog.open(Humboldt2020OngoingEfcAdoptActivityDialogComponent, {
            data: dialogData,
            disableClose: true,
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (this.currentFormData) {
                if (result) {
                    if (result.index !== null) {
                        this.currentFormData.activities[result.index] = result;
                    }
                    else {
                        this.currentFormData.activities.push(result);
                    }
                    this.currentFormData.activities.forEach((activity, index) => activity.index = index);
                    this.activityListFilterAndSortData();
                }
                this.currentFormData.currentActivity = null;
                this.saveCurrentFormValues();
            }
        });
    }
    updateCurrentActivity(activity) {
        if (this.currentFormData) {
            this.currentFormData.currentActivity = activity;
            this.saveCurrentFormValues();
        }
    }
    deleteActivity(activity) {
        this.dialog.open(MessageDialogComponent, {
            data: {
                title: 'Delete Activity',
                message: 'Deleting this activity is irreversible.' +
                    ' Once deleted any information you have entered for the activity will be gone forever.' +
                    ' Are you sure you want to do this?',
                okButtonLabel: 'Delete Activity'
            },
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (this.currentFormData && result && activity.index !== null) {
                this.currentFormData.activities.splice(activity.index, 1);
                this.currentFormData.activities.forEach((activity, index) => activity.index = index);
                this.activityListFilterAndSortData();
                this.saveCurrentFormValues();
            }
        });
    }
}
