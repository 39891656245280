export enum Doc2019FieldInvestigationTimeInvestigationTypeOption {
  PreRelease = 'Pre-release',
  IntakeGeneral = 'Intake-General',
  IntakePartial = 'Intake-Partial',
  IntakeOwi = 'Intake-OWI 2/3',
  IntakeNewSexOffender = 'Intake (new)-Sex offender',
  NewReleaseSexOffender = 'New Release-Sex offender',
  NewRelease = 'New Release',
  ProbationIntakeAfterPsi = 'Probation intake after PSI',
  PsiFull = 'PSI-full',
  IntrastateTransferIn = 'Intrastate transfer (in)',
  Revocation = 'Revocation'
}
