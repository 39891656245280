export enum Humboldt2020SupportAdminActivityAdminActivityOption {
  CwsTraining = 'CWS training',
  CwsAdministrativeTasksTaskForcesCommitteesStaffMeetings = 'CWS administrative tasks/task forces/committees/staff meetings',
  CoachingSupervisionNotCaseSpecific = 'Coaching/supervision (not case-specific)',
  ClinicalSupervision = 'Clinical supervision',
  CwsPublicInformationInCommunityRecruitment = 'CWS public information in community/recruitment',
  InformationAndReferralCallsAndWalkIns = 'Information and referral calls and walk-ins',
  SelfCareSelfMaintenance = 'Self-care/self-maintenance',
  AgencyLevelTribalCollaboration = 'Agency-level tribal collaboration',
  OtherNonCaseRelatedAdministrativeActivity = 'Other non-case-related administrative activity',
  Outreach = 'Outreach',
  WorkshopsAndEventsCoordinationPlanning = 'Workshops and events coordination/planning',
  WorkshopAndEventDelivery = 'Workshop and event delivery',
  CalendarNewsletter = 'Calendar/newsletter'
}
