export enum FormListColumn {
  name = 'name',
  formTypeName = 'formTypeName',
  caseType = 'caseType',
  createdUserName = 'createdUserName',
  createdTimestamp = 'createdTimestamp',
  status = 'status',
  statusChangeTimestamp = 'statusChangeTimestamp',
  offlineSaveTimestamp = 'offlineSaveTimestamp'
}
