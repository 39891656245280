export enum Doc2019CaseSupportTimeActivityCaseSupportActivityOption {
  CaseStaffingCaseConsultationMentoringShadowing = 'Case staffing/case consultation/mentoring/shadowing (not my case)',
  CaseCoverageRole = 'Case coverage role (e.g. AOD/cover agent) (not my case)',
  AccompanyingAgentTransportOrCustodyAssistHomeVisitOrSearch = 'Accompanying agent/transport or custody assist/home visit or search (not my case)',
  LiaisonDutiesProgramCoordination = 'Liaison duties/program coordination (TLP, DRC, HWH, TX, court, etc.)',
  SentencingHearingAttendancePrep = 'Sentencing hearing attendance/prep (points expired)',
  RevocationHearingAttendancePrep = 'Revocation hearing attendance/prep (points expired)',
  IntrastateTransfer = 'Intrastate transfer (residence assessment before transfer)',
  OtherCaseSupport = 'Other case support'
}
