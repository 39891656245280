export enum Humboldt2020OngoingEfcAdoptActivityActivityOption {
  InterviewAssessConsult = 'Interview/assess/consult',
  DocumentationReviewRequest = 'Documentation review/request',
  Documentation = 'Documentation',
  CasePlanning = 'Case planning',
  CaseStaffing = 'Case staffing',
  ArrangeServicesServiceAuthorizationPayments = 'Arrange services/service authorization/payments',
  CoachingCaseSpecific = 'Coaching (case specific)',
  TransportFamilyYouth = 'Transport family/youth',
  Other = 'Other'
}
