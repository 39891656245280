import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaddedContentComponent } from './padded-content/padded-content.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { ProcessingDialogComponent } from './processing-dialog/processing-dialog.component';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { ErrorDisplayPipe } from './pipes/error-display.pipe';

@NgModule({
  declarations: [
    PaddedContentComponent,
    MessageDialogComponent,
    ProcessingDialogComponent,
    ErrorDisplayPipe
  ],
  imports: [
    CommonModule,
    AngularMaterialModule
  ],
  exports: [
    PaddedContentComponent,
    MessageDialogComponent,
    ProcessingDialogComponent,
    ErrorDisplayPipe
  ],
  entryComponents: [
    ProcessingDialogComponent,
    MessageDialogComponent
  ]
})
export class SharedModule { }
