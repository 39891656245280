import { SharedFormData } from '../../../form/shared-form-data';
import { UserData } from '../../../auth/user-data';
import { YesNoOption } from '../../../form/yes-no-option.enum';
import { ApiSaveSurveyData } from '../../../api/api-save-survey-data';
import { ApiGetSurveyResponse } from '../../../api/api-get-survey-response';
import { isArray } from "util";
import { Humboldt2020PlacementStudyCaseTypeOption } from './humboldt2020-placement-study-case-type-option.enum';
import { Humboldt2020PlacementPlacementNeedOption } from './humboldt2020-placement-placement-need-option.enum';
import { Humboldt2020PlacementTimeHomeHasBeenRfHomeOption } from './humboldt2020-placement-time-home-has-been-rf-home-option.enum';
import { Humboldt2020PlacementPlacementsRfSinceApprovedOption } from './humboldt2020-placement-placements-rf-since-approved-option.enum';
import { Humboldt2020PlacementFinalPlacementTypeOption } from './humboldt2020-placement-final-placement-type-option.enum';
import { Humboldt2020PlacementChangeInApprovalStatusDuringMonthOption } from './humboldt2020-placement-change-in-approval-status-during-month-option.enum';
import { Humboldt2020PlacementActivityData, getActivityPersonInvolvedArray } from './humboldt2020-placement-activity-data';

export class Humboldt2020PlacementFormData extends SharedFormData {
  studyCaseType: Humboldt2020PlacementStudyCaseTypeOption | null;
  caseId: string | null;
  youthName: string | null;
  placementRequestDate: number | null;
  rfFacilityNumber: string | null;
  rfName: string | null;
  ageOfSampledYouth: number | null;
  attemptingToPlaceYouthAsPartOfASiblingSet: YesNoOption | null;
  placementNeed: Humboldt2020PlacementPlacementNeedOption | null;
  lengthOfTimeHomeHasBeenRfHome: Humboldt2020PlacementTimeHomeHasBeenRfHomeOption | null;
  howManyPlacementsHasRfHadSinceApproved: Humboldt2020PlacementPlacementsRfSinceApprovedOption | null;
  youthAlreadyPlacedInHomeAtBeginningOfStudyMonth: YesNoOption | null;
  comments: string | null;

  placementTypesSearchedFfaRfaIncludesTsh: boolean;
  placementTypesSearchedStrtpGroupHome: boolean;
  placementTypesSearchedTriballyApprovedHome: boolean;
  placementTypesSearchedTfcIsfc: boolean;
  placementTypesSearchedThppThpFc: boolean;
  finalPlacementType: Humboldt2020PlacementFinalPlacementTypeOption | null;
  complaintReceivedDuringMonth: YesNoOption | null;
  changeInApprovalStatusDuringMonth: Humboldt2020PlacementChangeInApprovalStatusDuringMonthOption | null;
  statusChangeDate: number | null;
  newYouthPlacedInHomeDuringStudyMonth: YesNoOption | null;

  // currentActivity should only be used by the current form when an activity is open and being edited
  currentActivity: Humboldt2020PlacementActivityData | null;
  activities: Humboldt2020PlacementActivityData[];

  standardsMet: YesNoOption | null;
  reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet: boolean;
  reasonsStandardsNotMetCaseworkerStoppedTracking: boolean;
  reasonsStandardsNotMetNotAllWorkDuringMonthRecorded: boolean;
  reasonsStandardsNotMetOther: boolean;
  supervisorComments: string | null;

  constructor(data: any) {
    super(data);

    this.studyCaseType = data.studyCaseType || null;
    this.caseId = data.caseId || null;
    this.youthName = data.youthName || null;
    this.placementRequestDate = data.placementRequestDate || null;
    this.rfFacilityNumber = data.rfFacilityNumber || null;
    this.rfName = data.rfName || null;
    this.ageOfSampledYouth = data.ageOfSampledYouth === 0 ? 0 : (data.ageOfSampledYouth || null);
    this.attemptingToPlaceYouthAsPartOfASiblingSet = data.attemptingToPlaceYouthAsPartOfASiblingSet || null;
    this.placementNeed = data.placementNeed || null;
    this.lengthOfTimeHomeHasBeenRfHome = data.lengthOfTimeHomeHasBeenRfHome || null;
    this.howManyPlacementsHasRfHadSinceApproved = data.howManyPlacementsHasRfHadSinceApproved || null;
    this.youthAlreadyPlacedInHomeAtBeginningOfStudyMonth = data.youthAlreadyPlacedInHomeAtBeginningOfStudyMonth || null;
    this.comments = data.comments || null;

    this.placementTypesSearchedFfaRfaIncludesTsh = data.placementTypesSearchedFfaRfaIncludesTsh || false;
    this.placementTypesSearchedStrtpGroupHome = data.placementTypesSearchedStrtpGroupHome || false;
    this.placementTypesSearchedTriballyApprovedHome = data.placementTypesSearchedTriballyApprovedHome || false;
    this.placementTypesSearchedTfcIsfc = data.placementTypesSearchedTfcIsfc || false;
    this.placementTypesSearchedThppThpFc = data.placementTypesSearchedThppThpFc || false;
    this.finalPlacementType = data.finalPlacementType || null;
    this.complaintReceivedDuringMonth = data.complaintReceivedDuringMonth || null;
    this.changeInApprovalStatusDuringMonth = data.changeInApprovalStatusDuringMonth || null;
    this.statusChangeDate = data.statusChangeDate || null;
    this.newYouthPlacedInHomeDuringStudyMonth = data.newYouthPlacedInHomeDuringStudyMonth || null;

    this.currentActivity = data.currentActivity || null;
    this.activities = data.activities || [];

    this.standardsMet = data.standardsMet || null;
    this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = data.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet || false;
    this.reasonsStandardsNotMetCaseworkerStoppedTracking = data.reasonsStandardsNotMetCaseworkerStoppedTracking || false;
    this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = data.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded || false;
    this.reasonsStandardsNotMetOther = data.reasonsStandardsNotMetOther || false;
    this.supervisorComments = data.supervisorComments || null;
  }

  /* Creates a new Humboldt2020PlacementFormData class from the data */
  static createNewForm(id: number, formTypeId: number, user: UserData, timestamp: number): Humboldt2020PlacementFormData {
    return new Humboldt2020PlacementFormData(SharedFormData.newFormData(id, formTypeId, user, timestamp));
  }

  /* creates the object to store in the database */
  toDatabaseObject(): object {
    const databaseObject: any = {
      studyCaseType: this.studyCaseType,
      caseId: this.caseId,
      youthName: this.youthName,
      placementRequestDate: this.placementRequestDate,
      rfFacilityNumber: this.rfFacilityNumber,
      rfName: this.rfName,
      ageOfSampledYouth: this.ageOfSampledYouth,
      attemptingToPlaceYouthAsPartOfASiblingSet: this.attemptingToPlaceYouthAsPartOfASiblingSet,
      placementNeed: this.placementNeed,
      lengthOfTimeHomeHasBeenRfHome: this.lengthOfTimeHomeHasBeenRfHome,
      howManyPlacementsHasRfHadSinceApproved: this.howManyPlacementsHasRfHadSinceApproved,
      youthAlreadyPlacedInHomeAtBeginningOfStudyMonth: this.youthAlreadyPlacedInHomeAtBeginningOfStudyMonth,
      comments: this.comments,

      placementTypesSearchedFfaRfaIncludesTsh: this.placementTypesSearchedFfaRfaIncludesTsh,
      placementTypesSearchedStrtpGroupHome: this.placementTypesSearchedStrtpGroupHome,
      placementTypesSearchedTriballyApprovedHome: this.placementTypesSearchedTriballyApprovedHome,
      placementTypesSearchedTfcIsfc: this.placementTypesSearchedTfcIsfc,
      placementTypesSearchedThppThpFc: this.placementTypesSearchedThppThpFc,
      finalPlacementType: this.finalPlacementType,
      complaintReceivedDuringMonth: this.complaintReceivedDuringMonth,
      changeInApprovalStatusDuringMonth: this.changeInApprovalStatusDuringMonth,
      statusChangeDate: this.statusChangeDate,
      newYouthPlacedInHomeDuringStudyMonth: this.newYouthPlacedInHomeDuringStudyMonth,

      currentActivity: this.currentActivity,

      standardsMet: this.standardsMet,
      reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet: this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet,
      reasonsStandardsNotMetCaseworkerStoppedTracking: this.reasonsStandardsNotMetCaseworkerStoppedTracking,
      reasonsStandardsNotMetNotAllWorkDuringMonthRecorded: this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded,
      reasonsStandardsNotMetOther: this.reasonsStandardsNotMetOther,
      supervisorComments: this.supervisorComments
    };

    if (this.activities.length > 0) {
      databaseObject.activities = this.activities;
    }

    return Object.assign({}, super.toDatabaseObject(), databaseObject);
  }

  /* Creates a Humboldt2020PlacementFormData class given the string stored in the database */
  static createFromDatabaseString(data: string): Humboldt2020PlacementFormData {
    return new Humboldt2020PlacementFormData(JSON.parse(data));
  }

  /* creates a data server object to send to the server */
  toServerObject(): ApiSaveSurveyData {
    const serverObject = super.toServerObject();

    SharedFormData.addSurveyItem(serverObject, 'StudyCaseType', this.studyCaseType);
    SharedFormData.addSurveyItem(serverObject, 'CaseId',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement ? this.caseId : null);
    SharedFormData.addSurveyItem(serverObject, 'YouthName',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement ? this.youthName : null);
    SharedFormData.addTimestampSurveyItem(serverObject, 'PlacementRequestDate', this.placementRequestDate);
    SharedFormData.addSurveyItem(serverObject, 'RfFacilityNumber',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport ? this.rfFacilityNumber : null);
    SharedFormData.addSurveyItem(serverObject, 'RfName',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport ? this.rfName : null);
    SharedFormData.addNumberSurveyItem(serverObject, 'AgeOfSampledYouth',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement ? this.ageOfSampledYouth : null);
    SharedFormData.addSurveyItem(serverObject, 'AttemptingToPlaceYouthAsPartOfASiblingSet',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement ? this.attemptingToPlaceYouthAsPartOfASiblingSet : null);
    SharedFormData.addSurveyItem(serverObject, 'PlacementNeed',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement ? this.placementNeed : null);
    SharedFormData.addSurveyItem(serverObject, 'LengthOfTimeHomeHasBeenRfHome',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport ? this.lengthOfTimeHomeHasBeenRfHome : null);
    SharedFormData.addSurveyItem(serverObject, 'HowManyPlacementsHasRfHadSinceApproved',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport ? this.howManyPlacementsHasRfHadSinceApproved : null);
    SharedFormData.addSurveyItem(serverObject, 'YouthAlreadyPlacedInHomeAtBeginningOfStudyMonth',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport ? this.youthAlreadyPlacedInHomeAtBeginningOfStudyMonth : null);
    SharedFormData.addSurveyItem(serverObject, 'Comments', this.comments);

    SharedFormData.addBooleanSurveyItem(serverObject, 'PlacementTypesSearchedFfaRfaIncludesTsh',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement ? this.placementTypesSearchedFfaRfaIncludesTsh : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'PlacementTypesSearchedStrtpGroupHome',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement ? this.placementTypesSearchedStrtpGroupHome : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'PlacementTypesSearchedTriballyApprovedHome',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement ? this.placementTypesSearchedTriballyApprovedHome : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'PlacementTypesSearchedTfcIsfc',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement ? this.placementTypesSearchedTfcIsfc : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'PlacementTypesSearchedThppThpFc',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement ? this.placementTypesSearchedThppThpFc : false);
    SharedFormData.addSurveyItem(serverObject, 'FinalPlacementType',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement ? this.finalPlacementType : null);
    SharedFormData.addSurveyItem(serverObject, 'ComplaintReceivedDuringMonth',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport ? this.complaintReceivedDuringMonth : null);
    SharedFormData.addSurveyItem(serverObject, 'ChangeInApprovalStatusDuringMonth',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport ? this.changeInApprovalStatusDuringMonth : null);
    const askNewYouthPlacedInHomeDuringStudyMonth = this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport &&
      (this.changeInApprovalStatusDuringMonth === Humboldt2020PlacementChangeInApprovalStatusDuringMonthOption.YesHold ||
        this.changeInApprovalStatusDuringMonth === Humboldt2020PlacementChangeInApprovalStatusDuringMonthOption.YesApprovalEnded);
    SharedFormData.addTimestampSurveyItem(serverObject, 'StatusChangeDate', askNewYouthPlacedInHomeDuringStudyMonth ? this.statusChangeDate : null);
    SharedFormData.addSurveyItem(serverObject, 'NewYouthPlacedInHomeDuringStudyMonth',
      this.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport ? this.newYouthPlacedInHomeDuringStudyMonth : null);

    this.activities.forEach((activity, index) => {
      SharedFormData.addTimestampSurveyItem(serverObject, 'ActivityDate', activity.activityDate, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityActivity', activity.activity, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedYouth', activity.personInvolvedYouth, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedRelativeNrefm', activity.personInvolvedRelativeNrefm, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedIntakeCoordinator', activity.personInvolvedIntakeCoordinator, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedSubstituteCareProvider', activity.personInvolvedSubstituteCareProvider, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedSocialWorker', activity.personInvolvedSocialWorker, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedDoj', activity.personInvolvedDoj, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedTribe', activity.personInvolvedTribe, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedSupervisor', activity.personInvolvedSupervisor, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOther', activity.personInvolvedOther, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedNone', activity.personInvolvedNone, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityMethod', activity.method, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityPlace', activity.place, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityActivityMinutesSpent', activity.activityMinutesSpent, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityTravelMinutesSpent', activity.travelMinutesSpent, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityDescription', activity.activityDescription, index);
    });

    SharedFormData.addSurveyItem(serverObject, 'StandardsMet', this.standardsMet);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetCaseworkerStoppedTracking',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetCaseworkerStoppedTracking : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetNotAllWorkDuringMonthRecorded',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetOther',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetOther : false);
    SharedFormData.addSurveyItem(serverObject, 'SupervisorComments', this.supervisorComments);

    return serverObject;
  }

  /* creates this class from a data server object
  * fullRecord is false for records created from api/getlist */
  static createFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): Humboldt2020PlacementFormData {
    return new Humboldt2020PlacementFormData(Humboldt2020PlacementFormData.createDataFromServerObject(serverObject, fullRecord));
  }

  /* creates the data object to parse into a new class instance, exposed as a separate function so child classes can use it
  * fullRecord is false for records created from api/getlist */
  static createDataFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): any {
    const data = SharedFormData.createDataFromServerObject(serverObject, fullRecord);

    if (serverObject.SurveyItems && isArray(serverObject.SurveyItems)) {
      serverObject.SurveyItems.forEach(item => {
        let itemIndex = 0;
        switch (item.ItemName) {
          case 'StudyCaseType':
            data.studyCaseType = item.ItemValue;
            break;
          case 'CaseId':
            data.caseId = item.ItemValue;
            break;
          case 'YouthName':
            data.youthName = item.ItemValue;
            break;
          case 'PlacementRequestDate':
            data.placementRequestDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'RfFacilityNumber':
            data.rfFacilityNumber = item.ItemValue;
            break;
          case 'RfName':
            data.rfName = item.ItemValue;
            break;
          case 'AgeOfSampledYouth':
            data.ageOfSampledYouth = parseInt(item.ItemValue);
            break;
          case 'AttemptingToPlaceYouthAsPartOfASiblingSet':
            data.attemptingToPlaceYouthAsPartOfASiblingSet = item.ItemValue;
            break;
          case 'PlacementNeed':
            data.placementNeed = item.ItemValue;
            break;
          case 'LengthOfTimeHomeHasBeenRfHome':
            data.lengthOfTimeHomeHasBeenRfHome = item.ItemValue;
            break;
          case 'HowManyPlacementsHasRfHadSinceApproved':
            data.howManyPlacementsHasRfHadSinceApproved = item.ItemValue;
            break;
          case 'YouthAlreadyPlacedInHomeAtBeginningOfStudyMonth':
            data.youthAlreadyPlacedInHomeAtBeginningOfStudyMonth = item.ItemValue;
            break;
          case 'Comments':
            data.comments = item.ItemValue;
            break;

          case 'PlacementTypesSearchedFfaRfaIncludesTsh':
            data.placementTypesSearchedFfaRfaIncludesTsh = (item.ItemValue === 'true');
            break;
          case 'PlacementTypesSearchedStrtpGroupHome':
            data.placementTypesSearchedStrtpGroupHome = (item.ItemValue === 'true');
            break;
          case 'PlacementTypesSearchedTriballyApprovedHome':
            data.placementTypesSearchedTriballyApprovedHome = (item.ItemValue === 'true');
            break;
          case 'PlacementTypesSearchedTfcIsfc':
            data.placementTypesSearchedTfcIsfc = (item.ItemValue === 'true');
            break;
          case 'PlacementTypesSearchedThppThpFc':
            data.placementTypesSearchedThppThpFc = (item.ItemValue === 'true');
            break;
          case 'FinalPlacementType':
            data.finalPlacementType = item.ItemValue;
            break;
          case 'ComplaintReceivedDuringMonth':
            data.complaintReceivedDuringMonth = item.ItemValue;
            break;
          case 'ChangeInApprovalStatusDuringMonth':
            data.changeInApprovalStatusDuringMonth = item.ItemValue;
            break;
          case 'StatusChangeDate':
            data.statusChangeDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'NewYouthPlacedInHomeDuringStudyMonth':
            data.newYouthPlacedInHomeDuringStudyMonth = item.ItemValue;
            break;

          case 'ActivityDate':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'ActivityActivity':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activity = item.ItemValue;
            break;
          case 'ActivityPersonInvolvedYouth':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedYouth = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedRelativeNrefm':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedRelativeNrefm = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedIntakeCoordinator':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedIntakeCoordinator = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedSubstituteCareProvider':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedSubstituteCareProvider = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedSocialWorker':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedSocialWorker = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedDoj':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedDoj = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedTribe':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedTribe = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedSupervisor':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedSupervisor = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOther':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOther = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedNone':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedNone = (item.ItemValue === 'true');
            break;
          case 'ActivityMethod':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].method = item.ItemValue;
            break;
          case 'ActivityPlace':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].place = item.ItemValue;
            break;
          case 'ActivityActivityMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityTravelMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].travelMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityDescription':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020PlacementFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDescription = item.ItemValue;
            break;

          case 'StandardsMet':
            data.standardsMet = item.ItemValue;
            break;
          case 'ReasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet':
            data.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetCaseworkerStoppedTracking':
            data.reasonsStandardsNotMetCaseworkerStoppedTracking = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetNotAllWorkDuringMonthRecorded':
            data.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetOther':
            data.reasonsStandardsNotMetOther = (item.ItemValue === 'true');
            break;
          case 'SupervisorComments':
            data.supervisorComments = item.ItemValue;
            break;

          default:
            console.error('Unknown item name (' + item.ItemName + ') while parsing SurveyItems');
        }
      });
    }

    if (data.activities && isArray(data.activities)) {
      data.activities.forEach(activity => {
        const personInvolvedArray = getActivityPersonInvolvedArray(activity);
        activity.personInvolvedDisplay = personInvolvedArray.length ? personInvolvedArray.join(', ') : null;
      })
    }

    return data;
  }

  /* creates empty placeholders in the activities array on data up to index */
  static setDataActivityCount(data: any, index: number) {
    if (!data.activities) data.activities = [];
    while (data.activities.length <= index) {
      const newActivity: Humboldt2020PlacementActivityData = {
        index: data.activities.length,
        activityDate: null,
        activity: null,
        personInvolvedDisplay: null,
        personInvolvedYouth: false,
        personInvolvedRelativeNrefm: false,
        personInvolvedIntakeCoordinator: false,
        personInvolvedSubstituteCareProvider: false,
        personInvolvedSocialWorker: false,
        personInvolvedDoj: false,
        personInvolvedTribe: false,
        personInvolvedSupervisor: false,
        personInvolvedOther: false,
        personInvolvedNone: false,
        method: null,
        place: null,
        activityMinutesSpent: null,
        travelMinutesSpent: null,
        activityDescription: null
      };
      data.activities.push(newActivity);
    }
  }
}
