<mat-toolbar color="primary" role="heading">
  <h1>{{isProduction ? title : 'TEST - ' + title}}</h1>
  <div class="user-name" *ngIf="isLoggedIn === true">{{user?.name}}</div>
  <button class="account-button" mat-icon-button aria-label="User Account Menu" [mat-menu-trigger-for]="userMenu" *ngIf="isLoggedIn === true">
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #userMenu="matMenu" xPosition="before">
    <div mat-menu-item class="user-name">{{user?.name}}</div>
    <a mat-menu-item routerLink="change-password">Change Password</a>
    <a mat-menu-item (click)="authService.logout()">Logout</a>
    <div mat-menu-item>App Version {{appVersion}}</div>
    <a mat-menu-item (click)="onlineService.checkForUpdate()" *ngIf="onlineService.online | async">Check for update</a>
  </mat-menu>
  <button class="settings-button" mat-icon-button aria-label="Setings Menu" [mat-menu-trigger-for]="settingsMenu" *ngIf="isLoggedIn === false">
    <mat-icon>settings</mat-icon>
  </button>
  <mat-menu #settingsMenu="matMenu" xPosition="before">
    <a mat-menu-item (click)="clearDataDialog()">Clear Local Device Data</a>
    <div mat-menu-item>App Version {{appVersion}}</div>
    <a mat-menu-item (click)="onlineService.checkForUpdate()" *ngIf="onlineService.online | async">Check for update</a>
  </mat-menu>
</mat-toolbar>
