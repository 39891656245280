export enum Humboldt2020IntakeScreeningActivityPersonInvolvedOption {
  Reporter = 'Reporter',
  Supervisor = 'Supervisor',
  CwsStaff = 'CWS staff',
  Collateral = 'Collateral',
  LocalTribe = 'Local tribe',
  NonLocalTribe = 'Non-local tribe',
  None = 'None',
  Other = 'Other'
}
