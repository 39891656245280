import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TitleService } from './title/title.service';
import { ActivatedRoute, GuardsCheckEnd, NavigationEnd, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  title = 'nccd-form-app';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: TitleService
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(route => {
      const routeData = route.data as BehaviorSubject<any>;
      if (routeData.value.hasOwnProperty('title')) {
        this.titleService.title.next(routeData.value['title']);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
