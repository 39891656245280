import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MessageDialogData } from './message-dialog-data';

@Component({
  selector: 'app-form-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MessageDialogData
  ) {
    if (!data.okButtonLabel) {
      data.okButtonLabel = 'OK';
    }
    if (!data.cancelButtonLabel) {
      data.cancelButtonLabel = 'Cancel';
    }
  }

  ok(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
