import { Injectable } from '@angular/core';
import { openDB, IDBPDatabase, unwrap } from 'idb';
import { NccdDatabaseSchema } from './nccd-database-schema';
import { SHA3, AES, enc } from 'crypto-js';
import { UserData } from '../auth/user-data';
import { SharedFormData } from '../form/shared-form-data';
import { FormListFilterData } from '../form-list/form-list-filter-data';
import { UserOfflineLoginData } from './user-offline-login-data';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private static ENCRYPTION_CHECK = 'Encryption Correct';
  readonly databasePromise: Promise<IDBPDatabase<NccdDatabaseSchema>>;

  constructor() {
    this.databasePromise = DatabaseService.openDatabase();
  }

  private static openDatabase(): Promise<IDBPDatabase<NccdDatabaseSchema>> {
    return openDB('nccd-form-app', 2, {
      upgrade(db: IDBPDatabase<NccdDatabaseSchema>, oldVersion: number): void {
        switch (oldVersion) {
          case 0:
            db.createObjectStore('form-list-filters', {keyPath: 'userId'});

            // Edge doesn't support compound keypaths
            // const formStore = db.createObjectStore('form', {keyPath: ['userId', 'formId']});
            // formStore.createIndex('by-user-id', 'userId');
            const formStore = db.createObjectStore('formv2', {keyPath: 'key'});
            formStore.createIndex('by-user-id', 'userId');
            db.createObjectStore('form-current', {keyPath: 'userId'});
            //db.createObjectStore('form-type-settings', {keyPath: ['userId', 'formTypeId']});
            db.createObjectStore('form-type-settingsv2', {keyPath: 'key'});
            db.createObjectStore('form-unique-id', {keyPath: 'userId'});

            db.createObjectStore('user', {keyPath: 'userId'});
            db.createObjectStore('user-current', {keyPath: 'userId'});
            db.createObjectStore('user-offline-login', {keyPath: 'username'});
            db.createObjectStore('user-encryption-check', {keyPath: 'userId'});
            break;
          case 1:
            const formStoreV2 = db.createObjectStore('formv2', {keyPath: 'key'});
            formStoreV2.createIndex('by-user-id', 'userId');
            // Ideally we would copy all existing forms from form to formv2, but not going to bother since we are still in testing
            db.deleteObjectStore('form');

            db.createObjectStore('form-type-settingsv2', {keyPath: 'key'});
            db.deleteObjectStore('form-type-settings');
            break;
        }
      }
    });
  }

  /* Clears all data out of the database */
  async clearAllData(): Promise<void> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    await db.clear('form-list-filters');

    await db.clear('formv2');
    await db.clear('form-current');
    await db.clear('form-type-settingsv2');
    await db.clear('form-unique-id');

    await db.clear('user');
    await db.clear('user-current');
    await db.clear('user-offline-login');
    await db.clear('user-encryption-check');
  }

  /* Caches the current user value in IndexedDB so we don't have to constantly retrieve it */
  private currentUser: UserData | null;
  /* Get the user object from IndexedDB if there is one, null if not */
  async getCurrentUser(): Promise<UserData | null> {
    if (this.currentUser) return this.currentUser;

    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    /*
    Not working correctly in Edge as documented at https://github.com/jakearchibald/idb
    const userRecords = await db.getAll('user-current');
     */
    const userRecords = await this.getAllSupportEdge(db, 'user-current');
    if (!userRecords.length) {
      this.currentUser = null;
      return null; // No current user record stored
    }

    if (userRecords.length > 1) {
      throw new Error('More than one current user found.');
    }

    const userId = userRecords[0].userId;
    if (!userId) {
      throw new Error('User Id for current user not found.');
    }

    const encryptionKey = userRecords[0].encryptionKey;
    if (!encryptionKey) {
      throw new Error('Encryption key for current user not found.');
    }

    this.currentUser = await this.getUser(userId, encryptionKey);
    return this.currentUser;
  }

  /* Once Edge moves to Chromium hopefully we can get rid of this */
  getAllSupportEdge(db: IDBPDatabase<NccdDatabaseSchema>, storeName): Promise<Array<any>> {
    return new Promise<Array<any>>(function(resolve, reject) {
      const transaction = db.transaction(storeName, 'readonly');
      const objectStore: any = unwrap(transaction.objectStore(storeName));

      if ('getAll' in objectStore) {
        // IDBObjectStore.getAll() will return the full set of items in our store.
        objectStore.getAll().onsuccess = function(event: any) {
          resolve(event.target.result);
        };
      } else {
        // Fallback to the traditional cursor approach if getAll isn't supported.
        const objects: Array<any> = [];
        objectStore.openCursor().onsuccess = function(event: any) {
          const cursor: any = event.target.result;
          if (cursor) {
            objects.push(cursor.value);
            cursor.continue();
          } else {
            resolve(objects);
          }
        };
      }
    });
  }

  /* Clear the current user data from IndexedDB */
  async clearCurrentUser(): Promise<void> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    await db.clear('user-current');
    this.currentUser = null;
    this.nextFormId = null;
  }

  /* Sets the current user data to IndexedDB */
  async setCurrentUser(user: UserData) {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    await this.saveUser(user);
    this.currentUser = user;

    await db.clear('user-current');
    await db.put('user-current', {
      userId: user.id,
      encryptionKey: user.encryptionKey
    });
  }

  /* gets the user data from IndexedDB  */
  async getUser(userId: number, encryptionKey: string): Promise<UserData | null> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const record = await db.get('user', userId);
    if (!record) {
      return null;
    }

    const encryptedData = record.encryptedData;
    if (!encryptedData) {
      throw new Error('Encrypted user record not found.');
    }

    return UserData.createFromDatabaseString(AES.decrypt(encryptedData, encryptionKey).toString(enc.Utf8));
  }

  /* Saves user data to IndexedDB */
  async saveUser(user: UserData): Promise<void> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    await db.put('user', {
      userId: user.id,
      encryptedData: AES.encrypt(user.toDatabaseString(), user.encryptionKey).toString()
    });
  }

  /* Saves the user offline login data to IndexedDB, encrypted by the user's password */
  async saveUserOfflineLogin(username: string, password: string, userId: number, encryptionKey: string): Promise<void> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const userOfflineLogin: UserOfflineLoginData = {
      userId: userId,
      encryptionKey: encryptionKey
    };

    const salt = Date.now().toString();
    const hash = SHA3(salt + password).toString();

    await db.put('user-offline-login', {
      username: username,
      salt: salt,
      encryptedData: AES.encrypt(JSON.stringify(userOfflineLogin), hash).toString()
    });

    await db.put('user-encryption-check', {
      userId: userId,
      checkValue: AES.encrypt(DatabaseService.ENCRYPTION_CHECK, encryptionKey).toString()
    });
  }

  async getUserOfflineLogin(username: string, password: string): Promise<UserOfflineLoginData> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const record = await db.get('user-offline-login', username);
    if (!record) {
      throw new Error('Username not found in offline login data. This user has not logged in to this device since data was cleared.');
    }

    if (!record.salt) {
      throw new Error('Salt not found for specified username.');
    }

    const hash = SHA3(record.salt + password).toString();

    if (!record.encryptedData) {
      throw new Error('Encrypted data not found for specified username.');
    }

    const decryptedData = AES.decrypt(record.encryptedData, hash).toString(enc.Utf8);

    let userOfflineLogin: UserOfflineLoginData | null = null;
    try {
      userOfflineLogin = JSON.parse(decryptedData);
    } catch (error) {
      throw new Error('Password is incorrect.');
    }

    if (!userOfflineLogin || !userOfflineLogin.userId || !userOfflineLogin.encryptionKey) {
      throw new Error('Password is incorrect.');
    }

    return userOfflineLogin;
  }

  /* Verify the encryptionKey is the same one as last time, may insert a new encryption-check if record not present */
  async verifyUserEncryptionKey(userId: number, encryptionKey: string): Promise<boolean> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const record = await db.get('user-encryption-check', userId);
    if (!record) {
      // No record stored, add a new record and return true
      await db.add('user-encryption-check', {
        userId: userId,
        checkValue: AES.encrypt(DatabaseService.ENCRYPTION_CHECK, encryptionKey).toString()
      });

      return true;
    }

    const checkValue = record.checkValue;
    if (!checkValue) {
      throw new Error('Encryption check value not found.');
    }

    const decryptedCheckValue = AES.decrypt(checkValue, encryptionKey).toString(enc.Utf8);
    return (DatabaseService.ENCRYPTION_CHECK === decryptedCheckValue);
  }

  /* Get the form list filters record from IndexedDB */
  async getFormListFilters(): Promise<FormListFilterData | null> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const user = await this.getCurrentUser();
    if (!user) {
      throw new Error('No logged in user, cannot get form list filters.');
    }

    const record = await db.get('form-list-filters', user.id);
    if (!record) {
      return null;
    }

    return JSON.parse(AES.decrypt(record.encryptedData, user.encryptionKey).toString(enc.Utf8));
  }

  /* Saves the form list filters record to IndexedDB */
  async saveFormListFilters(filters: FormListFilterData): Promise<void> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const user = await this.getCurrentUser();
    if (!user) {
      throw new Error('No logged in user, cannot save form list filters.');
    }

    await db.put('form-list-filters', {
      userId: user.id,
      encryptedData: AES.encrypt(JSON.stringify(filters), user.encryptionKey).toString()
    });
  }

  /* Get a list of forms from IndexedDB */
  async getFormList(): Promise<SharedFormData[]> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const user = await this.getCurrentUser();
    if (!user) {
      throw new Error('No logged in user, cannot get form list.');
    }

    /*
    Not working correctly in Edge as documented at https://github.com/jakearchibald/idb
    const recordList = await db.getAllFromIndex('form', 'by-user-id', user.id);
     */
    const recordList = await this.getAllFromIndexSupportEdge(db, 'formv2', 'by-user-id', user.id);
    if (!recordList) {
      return [];
    }

    return recordList.map(record => SharedFormData.createFromDatabaseString(AES.decrypt(record.encryptedData, user.encryptionKey).toString(enc.Utf8)));
  }

  /* Once Edge moves to Chromium hopefully we can get rid of this */
  getAllFromIndexSupportEdge(db: IDBPDatabase<NccdDatabaseSchema>, storeName, indexName, indexValue): Promise<Array<any>> {
    return new Promise<Array<any>>(function(resolve, reject) {
      const transaction = db.transaction(storeName, 'readonly');
      const objectStore: any = unwrap(transaction.objectStore(storeName));
      const index = objectStore.index(indexName);

      if ('getAll' in index) {
        index.getAll(indexValue).onsuccess = function(event: any) {
          resolve(event.target.result);
        };
      } else {
        // Fallback to the traditional cursor approach if getAll isn't supported.
        const objects: Array<any> = [];
        index.openCursor(indexValue).onsuccess = function(event: any) {
          const cursor: any = event.target.result;
          if (cursor) {
            objects.push(cursor.value);
            cursor.continue();
          } else {
            resolve(objects);
          }
        };
      }
    });
  }

  /* Caches the value in IndexedDB so we don't have to constantly retrieve it */
  private nextFormId: number | null = null;
  /* returns a unique id to assign to the form until it is saved to the server */
  async getNewFormId(): Promise<number> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const user = await this.getCurrentUser();
    if (!user) {
      throw new Error('No logged in user, cannot get form.');
    }

    if (this.nextFormId === null) {
      const record = await db.get('form-unique-id', user.id);
      if (record) {
        if (!record.encryptedData) {
          throw new Error('Encrypted next form id not found.');
        }

        const dataObject = JSON.parse(AES.decrypt(record.encryptedData, user.encryptionKey).toString(enc.Utf8));
        if (!dataObject) {
          throw new Error('Unable to parse decrypted next form id data.');
        }

        /* check again in case the value changed while waiting for the await above */
        if (this.nextFormId === null && dataObject.nextFormId) {
          this.nextFormId = dataObject.nextFormId;
        }
      }
      /* this happens if we can't retrieve a value from IndexedDB */
      if (this.nextFormId === null) {
        this.nextFormId = -1;
      }
    }

    // ids are assigned sequentially below 0, positive ids come from the server
    const nextFormId = this.nextFormId--;

    // Need to store in an object, was having trouble encrypting "-number" as a string
    const saveObject = {nextFormId: this.nextFormId || -1};
    await db.put('form-unique-id', {
      userId: user.id,
      encryptedData: AES.encrypt(JSON.stringify(saveObject), user.encryptionKey).toString()
    });

    return nextFormId;
  }

  /* Get the form record from IndexedDB */
  async getForm(formId: number, createFromDatabaseString: (string) => SharedFormData): Promise<SharedFormData | null> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const user = await this.getCurrentUser();
    if (!user) {
      throw new Error('No logged in user, cannot get form.');
    }

    const formKey = this.getFormV2Key(user.id, formId);
    const record = await db.get('formv2', formKey);
    if (!record) {
      return null;
    }

    if (!record.encryptedData) {
      throw new Error('Encrypted form data not found.');
    }
    return createFromDatabaseString(AES.decrypt(record.encryptedData, user.encryptionKey).toString(enc.Utf8));
  }

  getFormV2Key(userId: number, formId: number): string {
    return userId.toString() + '_' + formId.toString();
  }

  /* Save the form record to IndexedDB */
  async saveForm(form: SharedFormData): Promise<void> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const user = await this.getCurrentUser();
    if (!user) {
      throw new Error('No logged in user, cannot save form.');
    }

    await db.put('formv2', {
      key: this.getFormV2Key(user.id, form.id),
      userId: user.id,
      formId: form.id,
      encryptedData: AES.encrypt(form.toDatabaseString(), user.encryptionKey).toString()
    });
  }

  /* deletes a form record from IndexedDB */
  async deleteForm(formId: number): Promise<void> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const user = await this.getCurrentUser();
    if (!user) {
      throw new Error('No logged in user, cannot delete form.');
    }

    const formKey = this.getFormV2Key(user.id, formId);
    await db.delete('formv2', formKey);
  }

  /* Get the current form data from IndexedDB */
  async getCurrentForm(createFromDatabaseString: (string) => SharedFormData): Promise<SharedFormData | null> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const user = await this.getCurrentUser();
    if (!user) {
      throw new Error('No logged in user, cannot get current form.');
    }

    const record = await db.get('form-current', user.id);
    if (!record) {
      return null;
    }

    if (!record.encryptedData) {
      throw new Error('Encrypted form data not found.');
    }

    return createFromDatabaseString(AES.decrypt(record.encryptedData, user.encryptionKey).toString(enc.Utf8));
  }

  /* Save the current form data record to IndexedDB */
  async saveCurrentForm(form: SharedFormData): Promise<void> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const user = await this.getCurrentUser();
    if (!user) {
      throw new Error('No logged in user, cannot save current form.');
    }

    await db.put('form-current', {
      userId: user.id,
      encryptedData: AES.encrypt(form.toDatabaseString(), user.encryptionKey).toString()
    });
  }

  /* deletes a offline saved form record from IndexedDB */
  async deleteCurrentForm(): Promise<void> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const user = await this.getCurrentUser();
    if (!user) {
      throw new Error('No logged in user, cannot delete current form.');
    }

    await db.delete('form-current', user.id);
  }

  /* Get the form type settings data from IndexedDB */
  async getFormTypeSettings(formTypeId: number): Promise<string | null> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const user = await this.getCurrentUser();
    if (!user) {
      throw new Error('No logged in user, cannot get current form.');
    }

    const formTypeSettingsKey = this.getFormTypeSettingsV2Key(user.id, formTypeId);
    const record = await db.get('form-type-settingsv2', formTypeSettingsKey);
    if (!record) {
      return null;
    }

    if (!record.encryptedData) {
      throw new Error('Encrypted form data not found.');
    }

    return AES.decrypt(record.encryptedData, user.encryptionKey).toString(enc.Utf8);
  }

  getFormTypeSettingsV2Key(userId: number, formTypeId: number): string {
    return userId.toString() + '_' + formTypeId.toString();
  }

  /* Save the form type settings data record to IndexedDB */
  async saveFormTypeSettings(formTypeId: number, data: string): Promise<void> {
    const db = await this.databasePromise;
    if (!db) {
      throw new Error('IndexedDB database not found.');
    }

    const user = await this.getCurrentUser();
    if (!user) {
      throw new Error('No logged in user, cannot save current form.');
    }

    await db.put('form-type-settingsv2', {
      key: this.getFormTypeSettingsV2Key(user.id, formTypeId),
      userId: user.id,
      formTypeId: formTypeId,
      encryptedData: AES.encrypt(data, user.encryptionKey).toString()
    });
  }
}
