export enum Humboldt2020IlsActivityActivityOption {
  CorrespondAssessConsult = 'Correspond/assess/consult',
  DocumentationReviewCaseReview = 'Documentation review/case review',
  DocumentationContactCasePlanNotes = 'Documentation (contact/case plan notes)',
  IlsOrientation = 'ILS orientation',
  TlipGoalReview = 'TILP goal review',
  CaseStaffing = 'Case staffing',
  ArrangeServicesServiceAuthorizationPayments = 'Arrange services/service authorization/payments',
  EducationCoachingCaseSpecific = 'Education/coaching (case specific)',
  TransportYouth = 'Transport youth',
  Other = 'Other'
}
