import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[humboldt2020Id]'
})
export class Humboldt2020IdDirective implements OnInit {
  inputElement = this.el.nativeElement;
  navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  navigationOrDeleteKeypress = false;
  formControl: FormControl | null = null;

  constructor(
    private el: ElementRef,
    private control: NgControl
  ) {}

  ngOnInit(): void {
    if (this.control.control instanceof FormControl) {
      this.formControl = this.control.control;
      this.formControl.valueChanges.subscribe(value => {
        this.formatValue();
      });
    }
  }

  /**
   * Formats the control's value as 0000-0000-0000-0000000, adding - as necessary
   * Preserves the user's selection if editing the middle of the value
   */
  private formatValue(): void {
    if (this.formControl === null) return;

    const value = this.formControl.value;
    if (typeof value !== 'string') return;

    const formatedValue = this.formatedValue(value, !this.navigationOrDeleteKeypress);
    if (value === formatedValue) return; // No change in value

    const selectionStart = this.inputElement.selectionStart;
    const selectionEnd = this.inputElement.selectionEnd;

    this.formControl.setValue(formatedValue);

    if (selectionStart !== value.length) {
      // If the selection was not at the end before changing the value, reset the selection
      const valueBeforeSelectionStart = value.substr(0, selectionStart);
      const formattedValueBeforeSelectionStart = this.formatedValue(valueBeforeSelectionStart, !this.navigationOrDeleteKeypress);
      const newSelectionStart = formattedValueBeforeSelectionStart.length;

      const valueBeforeSelectionEnd = value.substr(0, selectionEnd);
      const formattedValueBeforeSelectionEnd = this.formatedValue(valueBeforeSelectionEnd, !this.navigationOrDeleteKeypress);
      const newSelectionEnd = formattedValueBeforeSelectionEnd.length;

      this.inputElement.setSelectionRange(newSelectionStart, newSelectionEnd);
    }
  }

  /**
   * Formats the value as 0000-0000-0000-0000000, adding - as necessary
   */
  private formatedValue(value: string, addTrailingSymbol: boolean): string {
    const digitsOnly = value.replace(/[^\d]/g, '');
    if (addTrailingSymbol) {
      return digitsOnly.substr(0, 4)
        + (digitsOnly.length > 3 ? '-' + digitsOnly.substr(4, 4) : '')
        + (digitsOnly.length > 7 ? '-' + digitsOnly.substr(8, 4) : '')
        + (digitsOnly.length > 11 ? '-' + digitsOnly.substr(12, 7) : '');
    }
    return digitsOnly.substr(0, 4)
      + (digitsOnly.length > 4 ? '-' + digitsOnly.substr(4, 4) : '')
      + (digitsOnly.length > 8 ? '-' + digitsOnly.substr(8, 4) : '')
      + (digitsOnly.length > 12 ? '-' + digitsOnly.substr(12, 7) : '');
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    if (this.navigationKeys.indexOf(event.key) > -1) { // Allow: navigation keys: backspace, delete, arrows etc.
      // We don't want to format the id if the user is moving or deleting
      this.navigationOrDeleteKeypress = true;
      // let it happen, don't do anything
      return ;
    } else if (
      (event.key === 'a' && event.ctrlKey === true) || // Allow: Ctrl+A
      (event.key === 'c' && event.ctrlKey === true) || // Allow: Ctrl+C
      (event.key === 'v' && event.ctrlKey === true) || // Allow: Ctrl+V
      (event.key === 'x' && event.ctrlKey === true) || // Allow: Ctrl+X
      (event.key === 'a' && event.metaKey === true) || // Allow: Cmd+A (Mac)
      (event.key === 'c' && event.metaKey === true) || // Allow: Cmd+C (Mac)
      (event.key === 'v' && event.metaKey === true) || // Allow: Cmd+V (Mac)
      (event.key === 'x' && event.metaKey === true) || // Allow: Cmd+X (Mac)
      event.key === '0' || event.key === '1' || event.key === '2' || event.key === '3' || event.key === '4' ||
      event.key === '5' || event.key === '6' || event.key === '7' || event.key === '8' || event.key === '9' ||
      event.key === '-' ||
      event.key === "Unidentified" // This is returned in Chrome on Android for - (and other keys) allowing it and the formatting code will clean up bad inputs
    ) {
      this.navigationOrDeleteKeypress = false;
      // let it happen, don't do anything
      return;
    }
    event.preventDefault();
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
    this.navigationOrDeleteKeypress = false;
    if (!event.clipboardData) {
      event.preventDefault();
      return;
    }
    const pasteText = event.clipboardData.getData('text/plain');
    const updatedText = pasteText.replace(/[^0-9\-]/gi, '');
    if (updatedText !== pasteText) {
      document.execCommand('insertText', false, updatedText);
      event.preventDefault();
    }
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
    this.navigationOrDeleteKeypress = false;
    if (!event.dataTransfer) {
      event.preventDefault();
      return;
    }
    const textData = event.dataTransfer.getData('text');
    const updatedText = textData.replace(/[^0-9\-]/gi, '');
    if (updatedText !== textData) {
      this.inputElement.focus();
      document.execCommand('insertText', false, updatedText);
      event.preventDefault();
    }
  }
}
