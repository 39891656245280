export enum Humboldt2020SupportAdminCwsProgramSupportOption {
  Intake = 'Intake',
  ErReferral = 'ER Referral',
  CourtIntake = 'Court Intake',
  OngoingServices = 'Ongoing services',
  Adoptions = 'Adoptions',
  Efc = 'EFC',
  Ils = 'ILS',
  Placement = 'Placement',
  Rfa = 'RFA'
}
