export enum Mddjs2020SupervisionActivityActivityOption {
  InterviewAssessment = 'Interview/assessment',
  CompletionOfTreatmentPlan = 'Completion of treatment plan',
  ClientMonitoring = 'Client monitoring',
  CaseFileReviewReportWritingDataEntry = 'Case file review/report writing/data entry',
  CourtHearing = 'Court hearing',
  Staffing = 'Staffing (e.g., MAST, resource)',
  ArrangeServices = 'Arrange services',
  AimActivities = 'AIM activities',
  Other = 'Other'
}
