import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { Mddjs2020IntakeComponent } from './mddjs2020-intake/mddjs2020-intake.component';
import { Mddjs2020IntakeActivityDialogComponent } from './mddjs2020-intake/mddjs2020-intake-activity-dialog/mddjs2020-intake-activity-dialog.component';
import { Mddjs2020IntakeActivityListFilterDialogComponent } from './mddjs2020-intake/mddjs2020-intake-activity-list-filter-dialog/mddjs2020-intake-activity-list-filter-dialog.component';
import { Mddjs2020SupervisionComponent } from './mddjs2020-supervision/mddjs2020-supervision.component';
import { Mddjs2020SupervisionActivityDialogComponent } from './mddjs2020-supervision/mddjs2020-supervision-activity-dialog/mddjs2020-supervision-activity-dialog.component';
import { Mddjs2020SupervisionActivityListFilterDialogComponent } from './mddjs2020-supervision/mddjs2020-supervision-activity-list-filter-dialog/mddjs2020-supervision-activity-list-filter-dialog.component';
import { Mddjs2020CaseSupportComponent } from './mddjs2020-case-support/mddjs2020-case-support.component';
import { Mddjs2020CaseSupportActivityDialogComponent } from './mddjs2020-case-support/mddjs2020-case-support-activity-dialog/mddjs2020-case-support-activity-dialog.component';
import { Mddjs2020CaseSupportActivityListFilterDialogComponent } from './mddjs2020-case-support/mddjs2020-case-support-activity-list-filter-dialog/mddjs2020-case-support-activity-list-filter-dialog.component';
import { Mddjs2020CdEmComponent } from './mddjs2020-cd-em/mddjs2020-cd-em.component';
import { Mddjs2020CdEmActivityDialogComponent } from './mddjs2020-cd-em/mddjs2020-cd-em-activity-dialog/mddjs2020-cd-em-activity-dialog.component';
import { Mddjs2020CdEmActivityListFilterDialogComponent } from './mddjs2020-cd-em/mddjs2020-cd-em-activity-list-filter-dialog/mddjs2020-cd-em-activity-list-filter-dialog.component';
import { Mddjs2020CdFloatingComponent } from './mddjs2020-cd-floating/mddjs2020-cd-floating.component';
import { Mddjs2020CdFloatingActivityDialogComponent } from './mddjs2020-cd-floating/mddjs2020-cd-floating-activity-dialog/mddjs2020-cd-floating-activity-dialog.component';
import { Mddjs2020CdFloatingActivityListFilterDialogComponent } from './mddjs2020-cd-floating/mddjs2020-cd-floating-activity-list-filter-dialog/mddjs2020-cd-floating-activity-list-filter-dialog.component';

@NgModule({
  declarations: [
    Mddjs2020IntakeComponent,
    Mddjs2020IntakeActivityDialogComponent,
    Mddjs2020IntakeActivityListFilterDialogComponent,
    Mddjs2020SupervisionComponent,
    Mddjs2020SupervisionActivityDialogComponent,
    Mddjs2020SupervisionActivityListFilterDialogComponent,
    Mddjs2020CaseSupportComponent,
    Mddjs2020CaseSupportActivityDialogComponent,
    Mddjs2020CaseSupportActivityListFilterDialogComponent,
    Mddjs2020CdEmComponent,
    Mddjs2020CdEmActivityDialogComponent,
    Mddjs2020CdEmActivityListFilterDialogComponent,
    Mddjs2020CdFloatingComponent,
    Mddjs2020CdFloatingActivityDialogComponent,
    Mddjs2020CdFloatingActivityListFilterDialogComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    DigitOnlyModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  entryComponents: [
    Mddjs2020IntakeActivityDialogComponent,
    Mddjs2020IntakeActivityListFilterDialogComponent,
    Mddjs2020SupervisionActivityDialogComponent,
    Mddjs2020SupervisionActivityListFilterDialogComponent,
    Mddjs2020CaseSupportActivityDialogComponent,
    Mddjs2020CaseSupportActivityListFilterDialogComponent,
    Mddjs2020CdEmActivityDialogComponent,
    Mddjs2020CdEmActivityListFilterDialogComponent,
    Mddjs2020CdFloatingActivityDialogComponent,
    Mddjs2020CdFloatingActivityListFilterDialogComponent
  ]
})
export class Mddjs2020Module { }
