import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { Doc2019OffenderSupervisionTimeViolationData } from '../doc2019-offender-supervision-time-violation-data';
import { controlErrorMessage } from '../../../../utility-functions';
import * as moment from 'moment';
import { YesNoOption } from '../../../../form/yes-no-option.enum';
import { Doc2019OffenderSupervisionTimeViolationDialogData } from './doc2019-offender-supervision-time-violation-dialog-data';

@Component({
  selector: 'app-doc2019-offender-supervision-time-violation-dialog',
  templateUrl: './doc2019-offender-supervision-time-violation-dialog.component.html',
  styleUrls: ['./doc2019-offender-supervision-time-violation-dialog.component.css']
})
export class Doc2019OffenderSupervisionTimeViolationDialogComponent {
  currentViolation: Doc2019OffenderSupervisionTimeViolationData;
  form = this.formBuilder.group({
    violationAddressedDate: [null, Validators.required],
    custodyViolation: [null, Validators.required],
    decisionDate: [null]
  });
  errorMessage = controlErrorMessage;

  yesNoOptions = Object.values(YesNoOption);

  minViolationAddressedDate = moment('20191001', 'YYYYMMDD');
  maxViolationAddressedDate = moment('20200330', 'YYYYMMDD');
  minDecisionDate = moment('20191230', 'YYYYMMDD');
  maxDecisionDate = moment('20200330', 'YYYYMMDD');

  constructor(
    private dialogRef: MatDialogRef<Doc2019OffenderSupervisionTimeViolationDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Doc2019OffenderSupervisionTimeViolationDialogData
  ) {
    if (data.violation) {
      // Need to make a copy of the object so updating it doesn't update the origin object until save is applied
      this.currentViolation = {
        index: data.violation.index,
        violationAddressedDate: data.violation.violationAddressedDate,
        custodyViolation: data.violation.custodyViolation,
        decisionDate: data.violation.decisionDate
      };

      if (this.currentViolation.violationAddressedDate) {
        this.form.controls.violationAddressedDate.setValue(moment(this.currentViolation.violationAddressedDate, 'x')); // x = unix milliseconds
      }
      this.form.controls.custodyViolation.setValue(this.currentViolation.custodyViolation);
      if (this.currentViolation.decisionDate) {
        this.form.controls.decisionDate.setValue(moment(this.currentViolation.decisionDate, 'x')); // x = unix milliseconds
      }
      this.form.markAllAsTouched();
    } else {
      this.currentViolation = {
        index: null,
        violationAddressedDate: null,
        custodyViolation: null,
        decisionDate: null
      };
    }

    this.formChangeSubscriptions();
  }

  formChangeSubscriptions(): void {
    this.form.controls.violationAddressedDate.valueChanges.subscribe(violationAddressedDate => {
      const numberValue = violationAddressedDate !== null ? violationAddressedDate.format('x') : null;
      if (this.currentViolation.violationAddressedDate !== numberValue) {
        this.currentViolation.violationAddressedDate = numberValue;
        this.data.updateCurrentViolation(this.currentViolation);
      }
    });

    this.form.controls.custodyViolation.valueChanges.subscribe(custodyViolation => {
      if (this.currentViolation.custodyViolation !== custodyViolation) {
        this.currentViolation.custodyViolation = custodyViolation;
        this.data.updateCurrentViolation(this.currentViolation);
      }
    });

    this.form.controls.decisionDate.valueChanges.subscribe(decisionDate => {
      const numberValue = decisionDate !== null ? decisionDate.format('x') : null;
      if (this.currentViolation.decisionDate !== numberValue) {
        this.currentViolation.decisionDate = numberValue;
        this.data.updateCurrentViolation(this.currentViolation);
      }
    });
  }

  updateViolationList(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dialogRef.close(this.currentViolation);
    }
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
