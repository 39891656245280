import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { FormComponent } from '../../../form/form.component';
import { getFormTypeById } from '../../form-type-data';
import { MatSort } from '@angular/material/sort';
import { Validators } from '@angular/forms';
import { Humboldt2020FacilityNumberValidator, Humboldt2020IdValidator } from '../../../validators';
import { debounceTime } from 'rxjs/operators';
import { FormStatus } from '../../../form/form-status.enum';
import * as moment from 'moment';
import { MessageDialogComponent } from '../../../message-dialog/message-dialog.component';
import { Humboldt2020PlacementFormData } from './humboldt2020-placement-form-data';
import { Humboldt2020PlacementActivityListColumn } from './humboldt2020-placement-activity-list-column.enum';
import { Humboldt2020PlacementActivityActivityOption } from './humboldt2020-placement-activity-dialog/humboldt2020-placement-activity-activity-option.enum';
import { Humboldt2020PlacementPlacementNeedOption } from './humboldt2020-placement-placement-need-option.enum';
import { Humboldt2020PlacementTimeHomeHasBeenRfHomeOption } from './humboldt2020-placement-time-home-has-been-rf-home-option.enum';
import { Humboldt2020PlacementPlacementsRfSinceApprovedOption } from './humboldt2020-placement-placements-rf-since-approved-option.enum';
import { Humboldt2020PlacementStudyCaseTypeOption } from './humboldt2020-placement-study-case-type-option.enum';
import { Humboldt2020PlacementPlacementTypesSearchedOption } from './humboldt2020-placement-placement-types-searched-option.enum';
import { Humboldt2020PlacementFinalPlacementTypeOption } from './humboldt2020-placement-final-placement-type-option.enum';
import { Humboldt2020PlacementChangeInApprovalStatusDuringMonthOption } from './humboldt2020-placement-change-in-approval-status-during-month-option.enum';
import { Humboldt2020PlacementReasonsStandardsNotMetOption } from './humboldt2020-placement-reasons-standards-not-met-option.enum';
import { Humboldt2020PlacementActivityListFilterDialogComponent } from './humboldt2020-placement-activity-list-filter-dialog/humboldt2020-placement-activity-list-filter-dialog.component';
import { Humboldt2020PlacementActivityDialogComponent } from './humboldt2020-placement-activity-dialog/humboldt2020-placement-activity-dialog.component';
import { isArray } from "util";
import { YesNoOption } from '../../../form/yes-no-option.enum';
export class Humboldt2020PlacementComponent extends FormComponent {
    constructor(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, titleService) {
        super(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, Humboldt2020PlacementFormData);
        this.apiService = apiService;
        this.authService = authService;
        this.breakpointObserver = breakpointObserver;
        this.databaseService = databaseService;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.onlineService = onlineService;
        this.route = route;
        this.router = router;
        this.titleService = titleService;
        this.formTypeId = 8;
        this.formType = getFormTypeById(this.formTypeId);
        this.formDataClass = Humboldt2020PlacementFormData;
        this.currentFormData = null;
        this.activitiesDataSource = [];
        this.activityListColumnEnum = Humboldt2020PlacementActivityListColumn;
        this.activityListDisplayedColumns = Object.values(Humboldt2020PlacementActivityListColumn);
        this.activityListFilterForm = this.formBuilder.group({
            activity: [null],
            sorting: [null]
        });
        this.activityListFilters = {
            activity: null,
            sortColumn: null,
            sortDirection: null
        };
        this.activityListFilterInfoString = '';
        this.activityOptions = Object.values(Humboldt2020PlacementActivityActivityOption);
        this.activityListSortOptions = [
            { sortColumn: Humboldt2020PlacementActivityListColumn.activityDate, sortDirection: 'asc', display: 'Date Ascending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.activityDate, sortDirection: 'desc', display: 'Date Descending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.activity, sortDirection: 'asc', display: 'Activity Ascending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.activity, sortDirection: 'desc', display: 'Activity Descending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.personInvolved, sortDirection: 'asc', display: 'Person Involved Ascending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.personInvolved, sortDirection: 'desc', display: 'Person Involved Descending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.method, sortDirection: 'asc', display: 'Method Ascending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.method, sortDirection: 'desc', display: 'Method Descending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.place, sortDirection: 'asc', display: 'Place Ascending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.place, sortDirection: 'desc', display: 'Place Descending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.activityMinutesSpent, sortDirection: 'asc', display: 'Activity Minutes Spent Ascending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.activityMinutesSpent, sortDirection: 'desc', display: 'Activity Minutes Spent Descending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.travelMinutesSpent, sortDirection: 'asc', display: 'Travel Minutes Spent Ascending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.travelMinutesSpent, sortDirection: 'desc', display: 'Travel Minutes Spent Descending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.activityDescription, sortDirection: 'asc', display: 'Description Ascending' },
            { sortColumn: Humboldt2020PlacementActivityListColumn.activityDescription, sortDirection: 'desc', display: 'Description Descending' },
        ];
        this.form = this.formBuilder.group({
            agentName: [null],
            createdDate: [null],
            status: [null],
            statusChangedDate: [null],
            updatedDate: [null],
            studyCaseType: [null, Validators.required],
            caseId: [null],
            youthName: [null],
            placementRequestDate: [null],
            rfFacilityNumber: [null],
            rfName: [null],
            ageOfSampledYouth: [null],
            attemptingToPlaceYouthAsPartOfASiblingSet: [null],
            placementNeed: [null],
            lengthOfTimeHomeHasBeenRfHome: [null],
            howManyPlacementsHasRfHadSinceApproved: [null],
            youthAlreadyPlacedInHomeAtBeginningOfStudyMonth: [null],
            comments: [null, Validators.maxLength(500)],
            placementTypesSearched: [[]],
            finalPlacementType: [null],
            complaintReceivedDuringMonth: [null],
            changeInApprovalStatusDuringMonth: [null],
            statusChangeDate: [null],
            newYouthPlacedInHomeDuringStudyMonth: [null],
            activityListFilter: this.activityListFilterForm,
            standardsMet: [null],
            reasonsStandardsNotMet: [[]],
            supervisorComments: [null, Validators.maxLength(500)]
        });
        this.studyCaseTypeOptions = Object.values(Humboldt2020PlacementStudyCaseTypeOption);
        this.studyCaseTypeOptionEnum = Humboldt2020PlacementStudyCaseTypeOption;
        this.placementNeedOptions = Object.values(Humboldt2020PlacementPlacementNeedOption);
        this.lengthOfTimeHomeHasBeenRfHomeOptions = Object.values(Humboldt2020PlacementTimeHomeHasBeenRfHomeOption);
        this.howManyPlacementsHasRfHadSinceApprovedOptions = Object.values(Humboldt2020PlacementPlacementsRfSinceApprovedOption);
        this.placementTypesSearchedOptions = Object.values(Humboldt2020PlacementPlacementTypesSearchedOption);
        this.finalPlacementTypeOptions = Object.values(Humboldt2020PlacementFinalPlacementTypeOption);
        this.changeInApprovalStatusDuringMonthOptions = Object.values(Humboldt2020PlacementChangeInApprovalStatusDuringMonthOption);
        this.reasonsStandardsNotMetOptions = Object.values(Humboldt2020PlacementReasonsStandardsNotMetOption);
        this.showStatusChangeDateQuestion = false;
        this.loadFormTypeSettings();
        this.formChangeSubscriptions();
        this.discardChangesDialogData.message = 'If you discard changes, you will lose any changes you have made to this' +
            ' form since you last saved it. This includes any changes to sample information, sample updates, and activities.' +
            ' Are you sure you want to do this?';
        this.submitFormDialogData.message = 'Submitting the form will send it to your supervisor for review and you will no longer be able to edit this form. Make sure you have:';
        this.submitFormDialogData.messageList = [
            'Completed all of the fields in the Sample Information and Sample Updates tabs.',
            'Recorded all the time spent working on this placement study case during the month.'
        ];
        this.sendBackFormDialogErrorData.title = 'Send Back to Caseworker Error';
        this.sendBackFormDialogData.title = 'Send Back to Caseworker';
        this.sendBackFormDialogData.message = 'This action will send the form back to the Caseworker for modification.' +
            ' This will revert the status of the form to "In Progress" and' +
            ' you will not be able to edit the form until the Caseworker submits it again.' +
            ' Are you sure you want to do this?';
        this.sendBackFormDialogData.okButtonLabel = 'Send Back to Caseworker';
        this.completeFormDialogData.message = 'Approving the form will remove it from your queue and you will no longer be able to edit this form. Make sure:';
        this.completeFormDialogData.messageList = [
            'The form captures all the work that was completed by the caseworker.',
            'You have reviewed the form for required work activities designated by standards.',
            'Time is recorded in minutes.'
        ];
    }
    set activityListSortViewChild(value) {
        this.activityListSort = value;
        // This timeout is needed or angular complains Expression has changed after it was checked.
        window.setTimeout(() => {
            // default in the current set sorting when the sorter is set
            // this happens if the width goes from isSmallDisplay to isLargeDisplay
            if (this.activityListSort && this.activityListFilterForm && this.activityListFilterForm.controls.sorting.value) {
                const sorting = this.activityListFilterForm.controls.sorting.value;
                if (this.activityListSort.active != sorting.sortColumn || this.activityListSort.direction != sorting.sortDirection) {
                    if (this.activityListSort) {
                        this.activityListSort.sort({
                            id: sorting.sortColumn,
                            start: sorting.sortDirection,
                            disableClear: true
                        });
                    }
                }
            }
        });
    }
    /* adds subscriptions for each of the form fields to update this.currentFormData */
    formChangeSubscriptions() {
        this.form.controls.studyCaseType.valueChanges.subscribe(studyCaseType => {
            if (this.currentFormData && this.currentFormData.studyCaseType !== studyCaseType) {
                this.currentFormData.caseType = studyCaseType;
                this.currentFormData.studyCaseType = studyCaseType;
                this.calculateName();
                this.saveCurrentFormValues();
                this.updateValidatorsBasedOnStudyCaseType();
                this.updateStatusChangeDataValidators();
            }
        });
        this.form.controls.caseId.valueChanges.subscribe(caseId => {
            if (this.currentFormData && this.currentFormData.caseId !== caseId) {
                this.currentFormData.caseId = caseId;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.youthName.valueChanges.subscribe(youthName => {
            if (this.currentFormData && this.currentFormData.youthName !== youthName) {
                this.currentFormData.name = youthName;
                this.currentFormData.youthName = youthName;
                this.calculateName();
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.placementRequestDate.valueChanges.subscribe(placementRequestDate => {
            const numberValue = placementRequestDate !== null ? parseInt(placementRequestDate.format('x')) : null;
            if (this.currentFormData && this.currentFormData.placementRequestDate !== numberValue) {
                this.currentFormData.placementRequestDate = numberValue;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.rfFacilityNumber.valueChanges.subscribe(rfFacilityNumber => {
            if (this.currentFormData && this.currentFormData.rfFacilityNumber !== rfFacilityNumber) {
                this.currentFormData.rfFacilityNumber = rfFacilityNumber;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.rfName.valueChanges.subscribe(rfName => {
            if (this.currentFormData && this.currentFormData.rfName !== rfName) {
                this.currentFormData.name = rfName;
                this.currentFormData.rfName = rfName;
                this.calculateName();
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.ageOfSampledYouth.valueChanges.subscribe(ageOfSampledYouth => {
            if (this.currentFormData && this.currentFormData.ageOfSampledYouth !== ageOfSampledYouth) {
                this.currentFormData.ageOfSampledYouth = ageOfSampledYouth;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.attemptingToPlaceYouthAsPartOfASiblingSet.valueChanges.subscribe(attemptingToPlaceYouthAsPartOfASiblingSet => {
            if (this.currentFormData && this.currentFormData.attemptingToPlaceYouthAsPartOfASiblingSet !== attemptingToPlaceYouthAsPartOfASiblingSet) {
                this.currentFormData.attemptingToPlaceYouthAsPartOfASiblingSet = attemptingToPlaceYouthAsPartOfASiblingSet;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.placementNeed.valueChanges.subscribe(placementNeed => {
            if (this.currentFormData && this.currentFormData.placementNeed !== placementNeed) {
                this.currentFormData.placementNeed = placementNeed;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.lengthOfTimeHomeHasBeenRfHome.valueChanges.subscribe(lengthOfTimeHomeHasBeenRfHome => {
            if (this.currentFormData && this.currentFormData.lengthOfTimeHomeHasBeenRfHome !== lengthOfTimeHomeHasBeenRfHome) {
                this.currentFormData.lengthOfTimeHomeHasBeenRfHome = lengthOfTimeHomeHasBeenRfHome;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.howManyPlacementsHasRfHadSinceApproved.valueChanges.subscribe(howManyPlacementsHasRfHadSinceApproved => {
            if (this.currentFormData && this.currentFormData.howManyPlacementsHasRfHadSinceApproved !== howManyPlacementsHasRfHadSinceApproved) {
                this.currentFormData.howManyPlacementsHasRfHadSinceApproved = howManyPlacementsHasRfHadSinceApproved;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.youthAlreadyPlacedInHomeAtBeginningOfStudyMonth.valueChanges.subscribe(youthAlreadyPlacedInHomeAtBeginningOfStudyMonth => {
            if (this.currentFormData && this.currentFormData.youthAlreadyPlacedInHomeAtBeginningOfStudyMonth !== youthAlreadyPlacedInHomeAtBeginningOfStudyMonth) {
                this.currentFormData.youthAlreadyPlacedInHomeAtBeginningOfStudyMonth = youthAlreadyPlacedInHomeAtBeginningOfStudyMonth;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.comments.valueChanges.subscribe(comments => {
            if (this.currentFormData && this.currentFormData.comments !== comments) {
                this.currentFormData.comments = comments;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.placementTypesSearched.valueChanges.subscribe(placementTypesSearched => {
            let placementTypesSearchedFfaRfaIncludesTsh = false;
            let placementTypesSearchedStrtpGroupHome = false;
            let placementTypesSearchedTriballyApprovedHome = false;
            let placementTypesSearchedTfcIsfc = false;
            let placementTypesSearchedThppThpFc = false;
            if (isArray(placementTypesSearched)) {
                placementTypesSearched.forEach(placementTypeSearched => {
                    switch (placementTypeSearched) {
                        case Humboldt2020PlacementPlacementTypesSearchedOption.FfaRfaIncludesTsh:
                            placementTypesSearchedFfaRfaIncludesTsh = true;
                            break;
                        case Humboldt2020PlacementPlacementTypesSearchedOption.StrtpGroupHome:
                            placementTypesSearchedStrtpGroupHome = true;
                            break;
                        case Humboldt2020PlacementPlacementTypesSearchedOption.TriballyApprovedHome:
                            placementTypesSearchedTriballyApprovedHome = true;
                            break;
                        case Humboldt2020PlacementPlacementTypesSearchedOption.TfcIsfc:
                            placementTypesSearchedTfcIsfc = true;
                            break;
                        case Humboldt2020PlacementPlacementTypesSearchedOption.ThppThpFc:
                            placementTypesSearchedThppThpFc = true;
                            break;
                    }
                });
            }
            let saveCurrentFormValues = false;
            if (this.currentFormData && this.currentFormData.placementTypesSearchedFfaRfaIncludesTsh !== placementTypesSearchedFfaRfaIncludesTsh) {
                this.currentFormData.placementTypesSearchedFfaRfaIncludesTsh = placementTypesSearchedFfaRfaIncludesTsh;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.placementTypesSearchedStrtpGroupHome !== placementTypesSearchedStrtpGroupHome) {
                this.currentFormData.placementTypesSearchedStrtpGroupHome = placementTypesSearchedStrtpGroupHome;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.placementTypesSearchedTriballyApprovedHome !== placementTypesSearchedTriballyApprovedHome) {
                this.currentFormData.placementTypesSearchedTriballyApprovedHome = placementTypesSearchedTriballyApprovedHome;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.placementTypesSearchedTfcIsfc !== placementTypesSearchedTfcIsfc) {
                this.currentFormData.placementTypesSearchedTfcIsfc = placementTypesSearchedTfcIsfc;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.placementTypesSearchedThppThpFc !== placementTypesSearchedThppThpFc) {
                this.currentFormData.placementTypesSearchedThppThpFc = placementTypesSearchedThppThpFc;
                saveCurrentFormValues = true;
            }
            if (saveCurrentFormValues) {
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.finalPlacementType.valueChanges.subscribe(finalPlacementType => {
            if (this.currentFormData && this.currentFormData.finalPlacementType !== finalPlacementType) {
                this.currentFormData.finalPlacementType = finalPlacementType;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.complaintReceivedDuringMonth.valueChanges.subscribe(complaintReceivedDuringMonth => {
            if (this.currentFormData && this.currentFormData.complaintReceivedDuringMonth !== complaintReceivedDuringMonth) {
                this.currentFormData.complaintReceivedDuringMonth = complaintReceivedDuringMonth;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.changeInApprovalStatusDuringMonth.valueChanges.subscribe(changeInApprovalStatusDuringMonth => {
            if (this.currentFormData && this.currentFormData.changeInApprovalStatusDuringMonth !== changeInApprovalStatusDuringMonth) {
                this.currentFormData.changeInApprovalStatusDuringMonth = changeInApprovalStatusDuringMonth;
                this.saveCurrentFormValues();
                this.updateStatusChangeDataValidators();
            }
        });
        this.form.controls.statusChangeDate.valueChanges.subscribe(statusChangeDate => {
            const numberValue = statusChangeDate !== null ? parseInt(statusChangeDate.format('x')) : null;
            if (this.currentFormData && this.currentFormData.statusChangeDate !== numberValue) {
                this.currentFormData.statusChangeDate = numberValue;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.newYouthPlacedInHomeDuringStudyMonth.valueChanges.subscribe(newYouthPlacedInHomeDuringStudyMonth => {
            if (this.currentFormData && this.currentFormData.newYouthPlacedInHomeDuringStudyMonth !== newYouthPlacedInHomeDuringStudyMonth) {
                this.currentFormData.newYouthPlacedInHomeDuringStudyMonth = newYouthPlacedInHomeDuringStudyMonth;
                this.saveCurrentFormValues();
            }
        });
        this.activityListFilterForm.controls.sorting.valueChanges.subscribe((sorting) => {
            if (sorting && this.activityListSort && (this.activityListSort.active !== sorting.sortColumn || (this.activityListSort.direction || 'asc') !== sorting.sortDirection)) {
                this.activityListSort.sort({
                    id: sorting.sortColumn,
                    start: sorting.sortDirection,
                    disableClear: true
                });
            }
        });
        this.activityListFilterForm.valueChanges.pipe(debounceTime(100)).subscribe(values => {
            let saveActivityListFilters = false;
            if (this.activityListFilters.activity !== values.activity) {
                this.activityListFilters.activity = values.activity;
                saveActivityListFilters = true;
            }
            if (values.sorting) {
                if (this.activityListFilters.sortColumn !== values.sorting.sortColumn || this.activityListFilters.sortDirection !== values.sorting.sortDirection) {
                    this.activityListFilters.sortColumn = values.sorting.sortColumn;
                    this.activityListFilters.sortDirection = values.sorting.sortDirection;
                    saveActivityListFilters = true;
                }
            }
            else if (this.activityListFilters.sortColumn !== null || this.activityListFilters.sortDirection !== null) {
                this.activityListFilters.sortColumn = null;
                this.activityListFilters.sortDirection = null;
                saveActivityListFilters = true;
            }
            if (saveActivityListFilters) {
                this.saveFormTypeSettings();
            }
            this.activityListFilterAndSortData();
        });
        this.form.controls.standardsMet.valueChanges.subscribe(standardsMet => {
            if (this.currentFormData && this.currentFormData.standardsMet !== standardsMet) {
                this.currentFormData.standardsMet = standardsMet;
                this.saveCurrentFormValues();
                this.updateReasonsStandardsNotMetValidators();
            }
        });
        this.form.controls.reasonsStandardsNotMet.valueChanges.subscribe(reasonsStandardsNotMet => {
            let reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = false;
            let reasonsStandardsNotMetCaseworkerStoppedTracking = false;
            let reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = false;
            let reasonsStandardsNotMetOther = false;
            if (isArray(reasonsStandardsNotMet)) {
                reasonsStandardsNotMet.forEach(reasonStandardsNotMet => {
                    switch (reasonStandardsNotMet) {
                        case Humboldt2020PlacementReasonsStandardsNotMetOption.NumberOfContactsOtherStandardsNotMet:
                            reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = true;
                            break;
                        case Humboldt2020PlacementReasonsStandardsNotMetOption.CaseworkerStoppedTracking:
                            reasonsStandardsNotMetCaseworkerStoppedTracking = true;
                            break;
                        case Humboldt2020PlacementReasonsStandardsNotMetOption.NotAllWorkDuringMonthRecorded:
                            reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = true;
                            break;
                        case Humboldt2020PlacementReasonsStandardsNotMetOption.Other:
                            reasonsStandardsNotMetOther = true;
                            break;
                    }
                });
            }
            let saveCurrentFormValues = false;
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet !== reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet) {
                this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking !== reasonsStandardsNotMetCaseworkerStoppedTracking) {
                this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking = reasonsStandardsNotMetCaseworkerStoppedTracking;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded !== reasonsStandardsNotMetNotAllWorkDuringMonthRecorded) {
                this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = reasonsStandardsNotMetNotAllWorkDuringMonthRecorded;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetOther !== reasonsStandardsNotMetOther) {
                this.currentFormData.reasonsStandardsNotMetOther = reasonsStandardsNotMetOther;
                saveCurrentFormValues = true;
            }
            if (saveCurrentFormValues) {
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.supervisorComments.valueChanges.subscribe(supervisorComments => {
            if (this.currentFormData && this.currentFormData.supervisorComments !== supervisorComments) {
                this.currentFormData.supervisorComments = supervisorComments;
                this.saveCurrentFormValues();
            }
        });
    }
    calculateName() {
        let name = '';
        if (this.currentFormData) {
            if (this.currentFormData.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement) {
                name = this.currentFormData.youthName || '';
                this.saveFormDialogErrorData.message = 'You must provide a Youth name to save the form.';
                this.sendBackFormDialogErrorData.message = 'You must provide a Youth name to save the form.';
            }
            else if (this.currentFormData.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport) {
                name = this.currentFormData.rfName || '';
                this.saveFormDialogErrorData.message = 'You must provide a RF Name to save the form.';
                this.sendBackFormDialogErrorData.message = 'You must provide a RF Name to save the form.';
            }
            else {
                this.saveFormDialogErrorData.message = 'You must provide a Study Case Type to save the form.';
                this.sendBackFormDialogErrorData.message = 'You must provide a Study Case Type to save the form.';
            }
            this.currentFormData.name = name;
            if (this.formType) {
                const title = this.formType.name + ' - ' + this.currentFormData.name;
                if (this.titleService.title.value !== title) {
                    this.titleService.title.next(title);
                }
            }
        }
    }
    /* updates the validators based on studyCaseType */
    updateValidatorsBasedOnStudyCaseType() {
        if (this.currentFormData && this.currentFormData.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement) {
            this.form.controls.caseId.setValidators([Validators.required, Validators.maxLength(22), Humboldt2020IdValidator]);
            this.form.controls.youthName.setValidators([Validators.required, Validators.maxLength(200)]);
            this.form.controls.placementRequestDate.setValidators(Validators.required);
            this.form.controls.ageOfSampledYouth.setValidators([Validators.required, Validators.min(0), Validators.max(25)]);
            this.form.controls.attemptingToPlaceYouthAsPartOfASiblingSet.setValidators(Validators.required);
            this.form.controls.placementNeed.setValidators(Validators.required);
            this.form.controls.placementTypesSearched.setValidators(Validators.required);
            this.form.controls.finalPlacementType.setValidators(Validators.required);
        }
        else {
            this.form.controls.caseId.clearValidators();
            this.form.controls.youthName.clearValidators();
            this.form.controls.placementRequestDate.clearValidators();
            this.form.controls.ageOfSampledYouth.clearValidators();
            this.form.controls.attemptingToPlaceYouthAsPartOfASiblingSet.clearValidators();
            this.form.controls.placementNeed.clearValidators();
            this.form.controls.placementTypesSearched.clearValidators();
            this.form.controls.finalPlacementType.clearValidators();
        }
        if (this.currentFormData && this.currentFormData.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport) {
            this.form.controls.rfFacilityNumber.setValidators([Validators.required, Validators.maxLength(9), Humboldt2020FacilityNumberValidator]);
            this.form.controls.rfName.setValidators([Validators.required, Validators.maxLength(200)]);
            this.form.controls.lengthOfTimeHomeHasBeenRfHome.setValidators(Validators.required);
            this.form.controls.howManyPlacementsHasRfHadSinceApproved.setValidators(Validators.required);
            this.form.controls.youthAlreadyPlacedInHomeAtBeginningOfStudyMonth.setValidators(Validators.required);
            this.form.controls.complaintReceivedDuringMonth.setValidators(Validators.required);
            this.form.controls.changeInApprovalStatusDuringMonth.setValidators(Validators.required);
            // statusChangeDate is handled in it's own function
            this.form.controls.newYouthPlacedInHomeDuringStudyMonth.setValidators(Validators.required);
        }
        else {
            this.form.controls.rfFacilityNumber.clearValidators();
            this.form.controls.rfName.clearValidators();
            this.form.controls.lengthOfTimeHomeHasBeenRfHome.clearValidators();
            this.form.controls.howManyPlacementsHasRfHadSinceApproved.clearValidators();
            this.form.controls.youthAlreadyPlacedInHomeAtBeginningOfStudyMonth.clearValidators();
            this.form.controls.complaintReceivedDuringMonth.clearValidators();
            this.form.controls.changeInApprovalStatusDuringMonth.clearValidators();
            // statusChangeDate is handled in it's own function
            this.form.controls.newYouthPlacedInHomeDuringStudyMonth.clearValidators();
        }
        this.form.controls.caseId.updateValueAndValidity();
        this.form.controls.youthName.updateValueAndValidity();
        this.form.controls.placementRequestDate.updateValueAndValidity();
        this.form.controls.rfFacilityNumber.updateValueAndValidity();
        this.form.controls.rfName.updateValueAndValidity();
        this.form.controls.ageOfSampledYouth.updateValueAndValidity();
        this.form.controls.attemptingToPlaceYouthAsPartOfASiblingSet.updateValueAndValidity();
        this.form.controls.placementNeed.updateValueAndValidity();
        this.form.controls.lengthOfTimeHomeHasBeenRfHome.updateValueAndValidity();
        this.form.controls.howManyPlacementsHasRfHadSinceApproved.updateValueAndValidity();
        this.form.controls.youthAlreadyPlacedInHomeAtBeginningOfStudyMonth.updateValueAndValidity();
        this.form.controls.placementTypesSearched.updateValueAndValidity();
        this.form.controls.finalPlacementType.updateValueAndValidity();
        this.form.controls.complaintReceivedDuringMonth.updateValueAndValidity();
        this.form.controls.changeInApprovalStatusDuringMonth.updateValueAndValidity();
        // statusChangeDate is handled in it's own function
        this.form.controls.newYouthPlacedInHomeDuringStudyMonth.updateValueAndValidity();
    }
    /* updates the validators for statusChangeDate */
    updateStatusChangeDataValidators() {
        if (this.currentFormData &&
            this.currentFormData.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport &&
            (this.currentFormData.changeInApprovalStatusDuringMonth === Humboldt2020PlacementChangeInApprovalStatusDuringMonthOption.YesHold ||
                this.currentFormData.changeInApprovalStatusDuringMonth === Humboldt2020PlacementChangeInApprovalStatusDuringMonthOption.YesApprovalEnded)) {
            this.showStatusChangeDateQuestion = true;
            this.form.controls.statusChangeDate.setValidators(Validators.required);
        }
        else {
            this.showStatusChangeDateQuestion = false;
            this.form.controls.statusChangeDate.clearValidators();
        }
        this.form.controls.statusChangeDate.updateValueAndValidity();
    }
    /* updates the validators for standardsMet */
    updateStandardsMetValidators() {
        if (this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
            this.form.controls.standardsMet.setValidators(Validators.required);
        }
        else {
            this.form.controls.standardsMet.clearValidators();
        }
        this.form.controls.standardsMet.updateValueAndValidity();
    }
    /* updates the validators for reasonsStandardsNotMet */
    updateReasonsStandardsNotMetValidators() {
        if (this.currentFormData && this.currentFormData.status === FormStatus.InReview && this.currentFormData.standardsMet === YesNoOption.No) {
            this.form.controls.reasonsStandardsNotMet.setValidators(Validators.required);
        }
        else {
            this.form.controls.reasonsStandardsNotMet.clearValidators();
        }
        this.form.controls.reasonsStandardsNotMet.updateValueAndValidity();
    }
    /* Hydrates form with currentFormData values */
    afterFormDataLoaded() {
        super.afterFormDataLoaded();
        if (this.formType && this.currentFormData) {
            const title = this.formType.name + ' - ' + this.currentFormData.name;
            if (this.titleService.title.value !== title) {
                this.titleService.title.next(title);
            }
        }
        if (this.currentFormData) {
            this.form.controls.agentName.setValue(this.currentFormData.created.userName);
            this.form.controls.createdDate.setValue(moment(this.currentFormData.created.timestamp, 'x').format('L LT'));
            this.form.controls.status.setValue(this.currentFormData.status);
            this.form.controls.statusChangedDate.setValue(moment(this.currentFormData.statusChangeTimestamp, 'x').format('L LT'));
            this.form.controls.updatedDate.setValue(moment(this.currentFormData.updated.timestamp, 'x').format('L LT'));
            this.form.controls.studyCaseType.setValue(this.currentFormData.studyCaseType);
            this.form.controls.caseId.setValue(this.currentFormData.caseId);
            this.form.controls.youthName.setValue(this.currentFormData.youthName);
            this.form.controls.placementRequestDate.setValue(this.currentFormData.placementRequestDate !== null ? moment(this.currentFormData.placementRequestDate, 'x') : null);
            this.form.controls.rfFacilityNumber.setValue(this.currentFormData.rfFacilityNumber);
            this.form.controls.rfName.setValue(this.currentFormData.rfName);
            this.form.controls.ageOfSampledYouth.setValue(this.currentFormData.ageOfSampledYouth);
            this.form.controls.attemptingToPlaceYouthAsPartOfASiblingSet.setValue(this.currentFormData.attemptingToPlaceYouthAsPartOfASiblingSet);
            this.form.controls.placementNeed.setValue(this.currentFormData.placementNeed);
            this.form.controls.lengthOfTimeHomeHasBeenRfHome.setValue(this.currentFormData.lengthOfTimeHomeHasBeenRfHome);
            this.form.controls.howManyPlacementsHasRfHadSinceApproved.setValue(this.currentFormData.howManyPlacementsHasRfHadSinceApproved);
            this.form.controls.youthAlreadyPlacedInHomeAtBeginningOfStudyMonth.setValue(this.currentFormData.youthAlreadyPlacedInHomeAtBeginningOfStudyMonth);
            this.form.controls.comments.setValue(this.currentFormData.comments);
            const placementTypesSearched = [];
            if (this.currentFormData.placementTypesSearchedFfaRfaIncludesTsh)
                placementTypesSearched.push(Humboldt2020PlacementPlacementTypesSearchedOption.FfaRfaIncludesTsh);
            if (this.currentFormData.placementTypesSearchedStrtpGroupHome)
                placementTypesSearched.push(Humboldt2020PlacementPlacementTypesSearchedOption.StrtpGroupHome);
            if (this.currentFormData.placementTypesSearchedTriballyApprovedHome)
                placementTypesSearched.push(Humboldt2020PlacementPlacementTypesSearchedOption.TriballyApprovedHome);
            if (this.currentFormData.placementTypesSearchedTfcIsfc)
                placementTypesSearched.push(Humboldt2020PlacementPlacementTypesSearchedOption.TfcIsfc);
            if (this.currentFormData.placementTypesSearchedThppThpFc)
                placementTypesSearched.push(Humboldt2020PlacementPlacementTypesSearchedOption.ThppThpFc);
            this.form.controls.placementTypesSearched.setValue(placementTypesSearched);
            this.form.controls.finalPlacementType.setValue(this.currentFormData.finalPlacementType);
            this.form.controls.complaintReceivedDuringMonth.setValue(this.currentFormData.complaintReceivedDuringMonth);
            this.form.controls.changeInApprovalStatusDuringMonth.setValue(this.currentFormData.changeInApprovalStatusDuringMonth);
            this.form.controls.statusChangeDate.setValue(this.currentFormData.statusChangeDate !== null ? moment(this.currentFormData.statusChangeDate, 'x') : null);
            this.form.controls.newYouthPlacedInHomeDuringStudyMonth.setValue(this.currentFormData.newYouthPlacedInHomeDuringStudyMonth);
            this.activityListFilterAndSortData();
            this.form.controls.standardsMet.setValue(this.currentFormData.standardsMet);
            const reasonsStandardsNotMet = [];
            if (this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet)
                reasonsStandardsNotMet.push(Humboldt2020PlacementReasonsStandardsNotMetOption.NumberOfContactsOtherStandardsNotMet);
            if (this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking)
                reasonsStandardsNotMet.push(Humboldt2020PlacementReasonsStandardsNotMetOption.CaseworkerStoppedTracking);
            if (this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded)
                reasonsStandardsNotMet.push(Humboldt2020PlacementReasonsStandardsNotMetOption.NotAllWorkDuringMonthRecorded);
            if (this.currentFormData.reasonsStandardsNotMetOther)
                reasonsStandardsNotMet.push(Humboldt2020PlacementReasonsStandardsNotMetOption.Other);
            this.form.controls.reasonsStandardsNotMet.setValue(reasonsStandardsNotMet);
            this.form.controls.supervisorComments.setValue(this.currentFormData.supervisorComments);
            this.updateValidatorsBasedOnStudyCaseType();
            this.updateStatusChangeDataValidators();
            this.updateStandardsMetValidators();
            this.updateReasonsStandardsNotMetValidators();
            // See if the loaded data has a current activity
            // This should only happen if the loaded record is a current form and the user was in the middle of editing that dialog
            if (this.currentFormData.currentActivity) {
                this.editActivity(this.currentFormData.currentActivity);
            }
        }
    }
    /**
     * This function does validation other than form.valid before submitting or completing
     * If validation does not pass it should return false and possibly set
     * submitFormDialogErrorData.message or completeFormDialogErrorData.message
     */
    extraFormValidation(action) {
        let errorMessage = null;
        if (this.currentFormData !== null && isArray(this.currentFormData.activities)) {
            if (this.currentFormData.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.Placement) {
                const retentionActivities = this.currentFormData.activities.filter((activity) => activity.activity === Humboldt2020PlacementActivityActivityOption.RetentionActivity);
                if (retentionActivities.length) {
                    errorMessage = 'There are ' + Humboldt2020PlacementActivityActivityOption.RetentionActivity + ' activities on the form,' +
                        ' but this activity type is not valid for Study Case Type of ' + Humboldt2020PlacementStudyCaseTypeOption.Placement + '.' +
                        ' Please change these activity types.';
                }
            }
            else if (this.currentFormData.studyCaseType === Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport) {
                const placementActivities = this.currentFormData.activities.filter((activity) => activity.activity === Humboldt2020PlacementActivityActivityOption.PlacementSearch);
                if (placementActivities.length) {
                    errorMessage = 'There are ' + Humboldt2020PlacementActivityActivityOption.PlacementSearch + ' activities on the form,' +
                        ' but this activity type is not valid for Study Case Type of ' + Humboldt2020PlacementStudyCaseTypeOption.ResourceFamilyRetentionSupport + '.' +
                        ' Please change these activity types.';
                }
            }
            if (errorMessage !== null) {
                if (action === 'submit') {
                    this.submitFormDialogErrorData.message = errorMessage;
                }
                if (action === 'complete') {
                    this.completeFormDialogErrorData.message = errorMessage;
                }
                return false;
            }
        }
        return true;
    }
    loadFormTypeSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const settingsString = yield this.databaseService.getFormTypeSettings(this.formTypeId);
                if (settingsString) {
                    const settings = JSON.parse(settingsString);
                    if (settings.activityListFilters) {
                        this.activityListFilters = settings.activityListFilters;
                        this.activityListFilterForm.controls.activity.setValue(this.activityListFilters.activity);
                        const activitySorting = this.activityListSortOptions.filter(sorting => sorting.sortColumn === this.activityListFilters.sortColumn && sorting.sortDirection === this.activityListFilters.sortDirection);
                        if (activitySorting.length > 0) {
                            this.activityListFilterForm.controls.sorting.setValue(activitySorting[0]);
                        }
                        else {
                            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
                        }
                    }
                    else {
                        this.clearActivityListFilters();
                    }
                }
                else {
                    this.clearActivityListFilters();
                }
            }
            catch (error) {
                console.error('Error retrieving form type settings', error);
            }
        });
    }
    saveFormTypeSettings() {
        try {
            const settings = {
                activityListFilters: this.activityListFilters
            };
            this.databaseService.saveFormTypeSettings(this.formTypeId, JSON.stringify(settings));
        }
        catch (error) {
            console.error('Error saving form type settings', error);
        }
    }
    activityListFilterAndSortData() {
        let sortColumn = Humboldt2020PlacementActivityListColumn.activityDate;
        let sortDirection = 'asc';
        if (this.activityListFilterForm.controls.sorting.value) {
            const sorting = this.activityListFilterForm.controls.sorting.value;
            sortColumn = sorting.sortColumn;
            sortDirection = sorting.sortDirection;
        }
        if (this.currentFormData && this.currentFormData.activities) {
            this.activitiesDataSource = this.currentFormData.activities.filter((activity) => {
                return !(this.activityListFilterForm.controls.activity.value && activity.activity !== this.activityListFilterForm.controls.activity.value);
            }).sort((activity1, activity2) => {
                switch (sortColumn) {
                    case Humboldt2020PlacementActivityListColumn.activityDate:
                        if ((activity1.activityDate || 0) > (activity2.activityDate || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityDate || 0) < (activity2.activityDate || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020PlacementActivityListColumn.activity:
                        if ((activity1.activity || '') > (activity2.activity || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activity || '') < (activity2.activity || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020PlacementActivityListColumn.personInvolved:
                        if ((activity1.personInvolvedDisplay || '') > (activity2.personInvolvedDisplay || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.personInvolvedDisplay || '') < (activity2.personInvolvedDisplay || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020PlacementActivityListColumn.method:
                        if ((activity1.method || '') > (activity2.method || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.method || '') < (activity2.method || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020PlacementActivityListColumn.place:
                        if ((activity1.place || '') > (activity2.place || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.place || '') < (activity2.place || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020PlacementActivityListColumn.activityMinutesSpent:
                        if ((activity1.activityMinutesSpent || 0) > (activity2.activityMinutesSpent || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityMinutesSpent || 0) < (activity2.activityMinutesSpent || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020PlacementActivityListColumn.travelMinutesSpent:
                        if ((activity1.travelMinutesSpent || 0) > (activity2.travelMinutesSpent || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.travelMinutesSpent || 0) < (activity2.travelMinutesSpent || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020PlacementActivityListColumn.activityDescription:
                        if ((activity1.activityDescription || '') > (activity2.activityDescription || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityDescription || '') < (activity2.activityDescription || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                }
                // sensible defaults if the key doesn't exist, or if the initial result is a tie
                if ((activity1.activityDate || 0) < (activity2.activityDate || 0))
                    return -1;
                if ((activity1.activityDate || 0) > (activity2.activityDate || 0))
                    return 1;
                return 0;
            });
        }
        this.setActivityListFilterInfoString();
    }
    setActivityListFilterInfoString() {
        const info = [];
        if (this.activityListFilterForm.controls.activity.value) {
            info.push('Activity Filter: ' + this.activityListFilterForm.controls.activity.value);
        }
        if (this.activityListFilterForm.controls.sorting.value) {
            info.push('Sort: ' + this.activityListFilterForm.controls.sorting.value.display);
        }
        this.activityListFilterInfoString = info.join(', ');
    }
    clearActivityListFilters() {
        if (this.activityListFilterForm.controls.activity.value !== null)
            this.activityListFilterForm.controls.activity.setValue(null);
        if (this.activityListFilterForm.controls.sorting.value !== this.activityListSortOptions[0])
            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
    }
    openActivityListFilterDialog() {
        const dialogData = {
            filterForm: this.activityListFilterForm,
            clearFunction: this.clearActivityListFilters.bind(this),
            activityOptions: this.activityOptions,
            sortOptions: this.activityListSortOptions
        };
        this.dialog.open(Humboldt2020PlacementActivityListFilterDialogComponent, {
            data: dialogData,
            width: '800px'
        });
    }
    /**
     * update the form filter when the table headers are clicked to keep them in sync
     */
    onActivityListSortChange(sort) {
        const sortingValue = this.activityListSortOptions.filter(sortOption => sortOption.sortColumn === sort.active && sortOption.sortDirection === (sort.direction || 'asc'));
        if (sortingValue.length > 0 && sortingValue[0] !== this.activityListFilterForm.controls.sorting.value) {
            this.activityListFilterForm.controls.sorting.setValue(sortingValue[0]);
        }
    }
    editActivity(activity) {
        const dialogData = {
            activity: activity,
            studyCaseType: this.currentFormData ? this.currentFormData.studyCaseType : null,
            updateCurrentActivity: this.updateCurrentActivity.bind(this)
        };
        this.dialog.open(Humboldt2020PlacementActivityDialogComponent, {
            data: dialogData,
            disableClose: true,
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (this.currentFormData) {
                if (result) {
                    if (result.index !== null) {
                        this.currentFormData.activities[result.index] = result;
                    }
                    else {
                        this.currentFormData.activities.push(result);
                    }
                    this.currentFormData.activities.forEach((activity, index) => activity.index = index);
                    this.activityListFilterAndSortData();
                }
                this.currentFormData.currentActivity = null;
                this.saveCurrentFormValues();
            }
        });
    }
    updateCurrentActivity(activity) {
        if (this.currentFormData) {
            this.currentFormData.currentActivity = activity;
            this.saveCurrentFormValues();
        }
    }
    deleteActivity(activity) {
        this.dialog.open(MessageDialogComponent, {
            data: {
                title: 'Delete Activity',
                message: 'Deleting this activity is irreversible.' +
                    ' Once deleted any information you have entered for the activity will be gone forever.' +
                    ' Are you sure you want to do this?',
                okButtonLabel: 'Delete Activity'
            },
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (this.currentFormData && result && activity.index !== null) {
                this.currentFormData.activities.splice(activity.index, 1);
                this.currentFormData.activities.forEach((activity, index) => activity.index = index);
                this.activityListFilterAndSortData();
                this.saveCurrentFormValues();
            }
        });
    }
}
