import { Mddjs2020CdFloatingActivityPlaceOption } from './mddjs2020-cd-floating-activity-dialog/mddjs2020-cd-floating-activity-place-option.enum';
import { Mddjs2020CdFloatingActivityPersonOption } from './mddjs2020-cd-floating-activity-dialog/mddjs2020-cd-floating-activity-person-option.enum';
import { Mddjs2020CdFloatingActivityMethodOption } from './mddjs2020-cd-floating-activity-dialog/mddjs2020-cd-floating-activity-method-option.enum';
import { Mddjs2020CdFloatingActivityActivityOption } from './mddjs2020-cd-floating-activity-dialog/mddjs2020-cd-floating-activity-activity-option.enum';

export interface Mddjs2020CdFloatingActivityData {
  index: number | null; // Used to store the index when passing data to the dialog
  caseName: string | null;
  djsClientId: string | null;
  activityDate: number | null; // unix timestamp in milliseconds
  activity: Mddjs2020CdFloatingActivityActivityOption | null;
  personDisplay: string | null;
  personYouth: boolean;
  personParentGuardianCaretaker: boolean;
  personOtherDjsStaff: boolean;
  personServiceProvider: boolean;
  personDssStaff: boolean;
  personOtherCollateral: boolean;
  personNone: boolean;
  method: Mddjs2020CdFloatingActivityMethodOption | null;
  place: Mddjs2020CdFloatingActivityPlaceOption | null;
  activityMinutes: number | null;
  travelMinutes: number | null;
  waitingMinutes: number | null;
  activityDescription: string | null;
}

export function getActivityPersonArray(activity: Mddjs2020CdFloatingActivityData): string[] {
  const values: Array<string> = [];
  if (activity.personYouth) {
    values.push(Mddjs2020CdFloatingActivityPersonOption.Youth);
  }
  if (activity.personParentGuardianCaretaker) {
    values.push(Mddjs2020CdFloatingActivityPersonOption.ParentGuardianCaretaker);
  }
  if (activity.personOtherDjsStaff) {
    values.push(Mddjs2020CdFloatingActivityPersonOption.OtherDjsStaff);
  }
  if (activity.personServiceProvider) {
    values.push(Mddjs2020CdFloatingActivityPersonOption.ServiceProvider);
  }
  if (activity.personDssStaff) {
    values.push(Mddjs2020CdFloatingActivityPersonOption.DssStaff);
  }
  if (activity.personOtherCollateral) {
    values.push(Mddjs2020CdFloatingActivityPersonOption.OtherCollateral);
  }
  if (activity.personNone) {
    values.push(Mddjs2020CdFloatingActivityPersonOption.None);
  }
  return values;
}
