import { FormControl } from '@angular/forms';

/* Checks a control and builds an english error message from the errors array */
export function controlErrorMessage(control: any): string | null {
  if (!(control instanceof FormControl)) {
    return null;
  }

  if (control.valid || !control.errors) {
    return null;
  }

  const errors: Array<string> = [];

  if (control.errors.required) {
    errors.push('This field is required');
  }

  if (control.errors.maxlength) {
    errors.push('This field has a max length of ' + control.errors.maxlength.requiredLength + ', but has ' + control.errors.maxlength.actualLength + ' characters');
  }

  if (control.errors.digitsOnly) {
    errors.push('This field may only contain digits (0-9)');
  }

  if (control.errors.Humboldt2020Id) {
    errors.push('This field must be in the format 0000-0000-0000-0000000');
  }

  if (control.errors.maxItems) {
    errors.push('This field supports up to ' + control.errors.maxItems.requiredLength + ' items selected, but ' + control.errors.maxItems.actualLength + ' items are selected');
  }

  if (control.errors.noneApply) {
    errors.push('If None Apply is selected, it must be the only value selected');
  }

  if (control.errors.none) {
    errors.push('If None is selected, it must be the only value selected');
  }

  if (control.errors.min) {
    errors.push('The minimum value allowed is ' + control.errors.min.min);
  }

  if (control.errors.max) {
    errors.push('The maximum value allowed is ' + control.errors.max.max);
  }

  if (control.errors.matDatepickerMin) {
    errors.push('The date must be on or after ' + control.errors.matDatepickerMin.min.format('L'))
  }

  if (control.errors.matDatepickerMax) {
    errors.push('The date must be on or before ' + control.errors.matDatepickerMax.max.format('L'))
  }

  if (control.errors.email) {
    errors.push('This field must be a valid email address');
  }

  if (control.errors.matDatepickerParse) {
    errors.push(control.errors.matDatepickerParse.text + ' is not a valid date');
  }

  if (control.errors.passwordLength) {
    errors.push('The password must be at least 6 characters');
  }

  if (control.errors.passwordNumeric) {
    errors.push('The password must be at least one number');
  }

  if (control.errors.passwordLowercase) {
    errors.push('The password must be at least one lowercase character');
  }

  if (control.errors.passwordUppercase) {
    errors.push('The password must be at least one uppercase character');
  }

  if (control.errors.passwordNonAlpha) {
    errors.push('The password must be at least one non-alphanumeric (not a letter or number) character');
  }

  if (control.errors.passwordAmpersand) {
    errors.push('The password may not contain an & character');
  }

  if (control.errors.passwordDefault) {
    errors.push('The password may not be set to the default password');
  }

  if (errors.length === 0) {
    console.error('Unrecognized formControl errors', control.errors);
    return null;
  }

  return errors.join(', ') + '.';
}

/* generates the current millisecond timestamps rounded to nearest second */
export function getCurrentMillisecondTimestampRoundedToNearestSecond(): number {
  return Math.round(Date.now() / 1000) * 1000;
}
