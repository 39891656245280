import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Mddjs2020CdFloatingActivityListFilterDialogData } from './mddjs2020-cd-floating-activity-list-filter-dialog-data';

@Component({
  selector: 'app-mddjs2020-cd-floating-activity-list-filter-dialog',
  templateUrl: './mddjs2020-cd-floating-activity-list-filter-dialog.component.html',
  styleUrls: ['./mddjs2020-cd-floating-activity-list-filter-dialog.component.css']
})
export class Mddjs2020CdFloatingActivityListFilterDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<Mddjs2020CdFloatingActivityListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Mddjs2020CdFloatingActivityListFilterDialogData
  ) { }

  clear(): void {
    this.data.clearFunction();
  }

  close(): void {
    this.dialogRef.close();
  }
}
