export enum Humboldt2020SupportAdminPrimaryProgramResponsibilityOption {
  ErIntake = 'ER Intake',
  ErReferrals = 'ER Referrals',
  CourtIntake = 'Court Intake',
  Ongoing = 'Ongoing',
  Adoptions = 'Adoptions',
  Placement = 'Placement',
  Rfa = 'RFA',
  ExtendedFosterCare = 'Extended Foster Care',
  Ils = 'ILS'
}
