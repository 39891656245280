import { SharedFormData } from '../../../form/shared-form-data';
import { UserData } from '../../../auth/user-data';
import { YesNoOption } from '../../../form/yes-no-option.enum';
import { ApiSaveSurveyData } from '../../../api/api-save-survey-data';
import { ApiGetSurveyResponse } from '../../../api/api-get-survey-response';
import { isArray } from "util";
import { Mddjs2020SupervisionStudyMonthOption } from './mddjs2020-supervision-study-month-option.enum';
import { Mddjs2020Region } from '../mddjs2020-region.enum';
import { Mddjs2020SupervisionNewCaseTypeOption } from './mddjs2020-supervision-new-case-type-option.enum';
import { Mddjs2020SupervisionOngoingCaseStatusOption } from './mddjs2020-supervision-ongoing-case-status-option.enum';
import { Mddjs2020SupervisionSupervisionLevelOption } from './mddjs2020-supervision-supervision-level-option.enum';
import { Mddjs2020SupervisionSpecializedCaseTypeOption } from './mddjs2020-supervision-specialized-case-type-option.enum';
import { Mddjs2020SupervisionYouthGenderOption } from './mddjs2020-supervision-youth-gender-option.enum';
import { getActivityPersonArray, Mddjs2020SupervisionActivityData } from './mddjs2020-supervision-activity-data';
import { Mddjs2020SupervisionReasonServiceStandardNotMetOption } from './mddjs2020-supervision-reason-service-standard-not-met-option.enum';

export class Mddjs2020SupervisionFormData extends SharedFormData {
  studyMonth: Mddjs2020SupervisionStudyMonthOption | null;
  djsClientId: string | null;
  region: Mddjs2020Region | null;
  office: string | null;
  newCaseType: Mddjs2020SupervisionNewCaseTypeOption | null;
  ongoingCaseStatus: Mddjs2020SupervisionOngoingCaseStatusOption | null;
  supervisionLevel: Mddjs2020SupervisionSupervisionLevelOption | null;
  specializedCaseType: Mddjs2020SupervisionSpecializedCaseTypeOption | null;
  youthGender: Mddjs2020SupervisionYouthGenderOption | null;
  caseEndOrChangeCaseTypesDuringStudyMonth: YesNoOption | null;
  comments: string | null;

  // currentActivity should only be used by the current form when an activity is open and being edited
  currentActivity: Mddjs2020SupervisionActivityData | null;
  activities: Mddjs2020SupervisionActivityData[];

  serviceStandardMet: YesNoOption | null;
  reasonServiceStandardNotMet: Mddjs2020SupervisionReasonServiceStandardNotMetOption | null;
  supervisorComments: string | null;

  constructor(data: any) {
    super(data);

    this.studyMonth = data.studyMonth || null;
    this.djsClientId = data.djsClientId || null;
    this.region = data.region || null;
    this.office = data.office || null;
    this.newCaseType = data.newCaseType || null;
    this.ongoingCaseStatus = data.ongoingCaseStatus || null;
    this.supervisionLevel = data.supervisionLevel || null;
    this.specializedCaseType = data.specializedCaseType || null;
    this.youthGender = data.youthGender || null;
    this.caseEndOrChangeCaseTypesDuringStudyMonth = data.caseEndOrChangeCaseTypesDuringStudyMonth || null;
    this.comments = data.comments || null;

    this.currentActivity = data.currentActivity || null;
    this.activities = data.activities || [];

    this.serviceStandardMet = data.serviceStandardMet || null;
    this.reasonServiceStandardNotMet = data.reasonServiceStandardNotMet || null;
    this.supervisorComments = data.supervisorComments || null;
  }

  /* Creates a new Mddjs2020SupervisionFormData class from the data */
  static createNewForm(id: number, formTypeId: number, user: UserData, timestamp: number): Mddjs2020SupervisionFormData {
    return new Mddjs2020SupervisionFormData(SharedFormData.newFormData(id, formTypeId, user, timestamp));
  }

  /* creates the object to store in the database */
  toDatabaseObject(): object {
    const databaseObject: any = {
      studyMonth: this.studyMonth,
      djsClientId: this.djsClientId,
      region: this.region,
      office: this.office,
      newCaseType: this.newCaseType,
      ongoingCaseStatus: this.ongoingCaseStatus,
      supervisionLevel: this.supervisionLevel,
      specializedCaseType: this.specializedCaseType,
      youthGender: this.youthGender,
      caseEndOrChangeCaseTypesDuringStudyMonth: this.caseEndOrChangeCaseTypesDuringStudyMonth,
      comments: this.comments,

      currentActivity: this.currentActivity,

      serviceStandardMet: this.serviceStandardMet,
      reasonServiceStandardNotMet: this.reasonServiceStandardNotMet,
      supervisorComments: this.supervisorComments
    };

    if (this.activities.length > 0) {
      databaseObject.activities = this.activities;
    }

    return Object.assign({}, super.toDatabaseObject(), databaseObject);
  }

  /* Creates a Mddjs2020SupervisionFormData class given the string stored in the database */
  static createFromDatabaseString(data: string): Mddjs2020SupervisionFormData {
    return new Mddjs2020SupervisionFormData(JSON.parse(data));
  }

  /* creates a data server object to send to the server */
  toServerObject(): ApiSaveSurveyData {
    const serverObject = super.toServerObject();

    SharedFormData.addSurveyItem(serverObject, 'StudyMonth', this.studyMonth);
    SharedFormData.addSurveyItem(serverObject, 'DjsClientId', this.djsClientId);
    SharedFormData.addSurveyItem(serverObject, 'Region', this.region);
    SharedFormData.addSurveyItem(serverObject, 'Office', this.office);
    SharedFormData.addSurveyItem(serverObject, 'NewCaseType', this.newCaseType);
    SharedFormData.addSurveyItem(serverObject, 'OngoingCaseStatus',
      this.newCaseType === Mddjs2020SupervisionNewCaseTypeOption.NotApplicableOngoingCase ? this.ongoingCaseStatus : null);
    SharedFormData.addSurveyItem(serverObject, 'SupervisionLevel', this.supervisionLevel);
    SharedFormData.addSurveyItem(serverObject, 'SpecializedCaseType', this.specializedCaseType);
    SharedFormData.addSurveyItem(serverObject, 'YouthGender', this.youthGender);
    SharedFormData.addSurveyItem(serverObject, 'CaseEndOrChangeCaseTypesDuringStudyMonth', this.caseEndOrChangeCaseTypesDuringStudyMonth);
    SharedFormData.addSurveyItem(serverObject, 'Comments', this.comments);

    this.activities.forEach((activity, index) => {
      SharedFormData.addTimestampSurveyItem(serverObject, 'ActivityDate', activity.activityDate, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityActivity', activity.activity, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonYouth', activity.personYouth, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonParentGuardianCaretaker', activity.personParentGuardianCaretaker, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonDjsStaff', activity.personOtherDjsStaff, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonServiceProvider', activity.personServiceProvider, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonDssStaff', activity.personDssStaff, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonOtherCollateral', activity.personOtherCollateral, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonNone', activity.personNone, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityMethod', activity.method, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityPlace', activity.place, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityActivityMinutes', activity.activityMinutes, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityTravelMinutes', activity.travelMinutes, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityWaitingMinutes', activity.waitingMinutes, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityDescription', activity.activityDescription, index);
    });

    SharedFormData.addSurveyItem(serverObject, 'ServiceStandardMet', this.serviceStandardMet);
    SharedFormData.addSurveyItem(serverObject, 'ReasonServiceStandardNotMet',
      this.serviceStandardMet === YesNoOption.No ? this.reasonServiceStandardNotMet : null);
    SharedFormData.addSurveyItem(serverObject, 'SupervisorComments', this.supervisorComments);

    return serverObject;
  }

  /* creates this class from a data server object
  * fullRecord is false for records created from api/getlist */
  static createFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): Mddjs2020SupervisionFormData {
    return new Mddjs2020SupervisionFormData(Mddjs2020SupervisionFormData.createDataFromServerObject(serverObject, fullRecord));
  }

  /* creates the data object to parse into a new class instance, exposed as a separate function so child classes can use it
  * fullRecord is false for records created from api/getlist */
  static createDataFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): any {
    const data = SharedFormData.createDataFromServerObject(serverObject, fullRecord);

    if (serverObject.SurveyItems && isArray(serverObject.SurveyItems)) {
      serverObject.SurveyItems.forEach(item => {
        let itemIndex = 0;
        switch (item.ItemName) {
          case 'StudyMonth':
            data.studyMonth = item.ItemValue;
            break;
          case 'DjsClientId':
            data.djsClientId = item.ItemValue;
            break;
          case 'Region':
            data.region = item.ItemValue;
            break;
          case 'Office':
            data.office = item.ItemValue;
            break;
          case 'NewCaseType':
            data.newCaseType = item.ItemValue;
            break;
          case 'OngoingCaseStatus':
            data.ongoingCaseStatus = item.ItemValue;
            break;
          case 'SupervisionLevel':
            data.supervisionLevel = item.ItemValue;
            break;
          case 'SpecializedCaseType':
            data.specializedCaseType = item.ItemValue;
            break;
          case 'YouthGender':
            data.youthGender = item.ItemValue;
            break;
          case 'CaseEndOrChangeCaseTypesDuringStudyMonth':
            data.caseEndOrChangeCaseTypesDuringStudyMonth = item.ItemValue;
            break;
          case 'Comments':
            data.comments = item.ItemValue;
            break;

          case 'ActivityDate':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'ActivityActivity':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activity = item.ItemValue;
            break;
          case 'ActivityPersonYouth':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personYouth = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonParentGuardianCaretaker':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personParentGuardianCaretaker = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonDjsStaff':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personOtherDjsStaff = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonServiceProvider':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personServiceProvider = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonDssStaff':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personDssStaff = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonOtherCollateral':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personOtherCollateral = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonNone':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personNone = (item.ItemValue === 'true');
            break;
          case 'ActivityMethod':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].method = item.ItemValue;
            break;
          case 'ActivityPlace':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].place = item.ItemValue;
            break;
          case 'ActivityActivityMinutes':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityMinutes = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityTravelMinutes':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].travelMinutes = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityWaitingMinutes':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].waitingMinutes = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityDescription':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Mddjs2020SupervisionFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDescription = item.ItemValue;
            break;

          case 'ServiceStandardMet':
            data.serviceStandardMet = item.ItemValue;
            break;
          case 'ReasonServiceStandardNotMet':
            data.reasonServiceStandardNotMet = item.ItemValue;
            break;
          case 'SupervisorComments':
            data.supervisorComments = item.ItemValue;
            break;

          default:
            console.error('Unknown item name (' + item.ItemName + ') while parsing SurveyItems');
        }
      });
    }

    if (data.activities && isArray(data.activities)) {
      data.activities.forEach(activity => {
        const personInvolvedArray = getActivityPersonArray(activity);
        activity.personDisplay = personInvolvedArray.length ? personInvolvedArray.join(', ') : null;
      })
    }

    return data;
  }

  /* creates empty placeholders in the activities array on data up to index */
  static setDataActivityCount(data: any, index: number) {
    if (!data.activities) data.activities = [];
    while (data.activities.length <= index) {
      const newActivity: Mddjs2020SupervisionActivityData = {
        index: data.activities.length,
        activityDate: null,
        activity: null,
        personDisplay: null,
        personYouth: false,
        personParentGuardianCaretaker: false,
        personOtherDjsStaff: false,
        personServiceProvider: false,
        personDssStaff: false,
        personOtherCollateral: false,
        personNone: false,
        method: null,
        place: null,
        activityMinutes: null,
        travelMinutes: null,
        waitingMinutes: null,
        activityDescription: null
      };
      data.activities.push(newActivity);
    }
  }
}
