import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Doc2019CaseSupportTimeActivityListFilterDialogData } from './doc2019-case-support-time-activity-list-filter-dialog-data';

@Component({
  selector: 'app-doc2019-case-support-time-activity-list-filter-dialog',
  templateUrl: './doc2019-case-support-time-activity-list-filter-dialog.component.html',
  styleUrls: ['./doc2019-case-support-time-activity-list-filter-dialog.component.css']
})
export class Doc2019CaseSupportTimeActivityListFilterDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<Doc2019CaseSupportTimeActivityListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Doc2019CaseSupportTimeActivityListFilterDialogData
  ) { }

  clear(): void {
    this.data.clearFunction();
  }

  close(): void {
    this.dialogRef.close();
  }
}
