import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.authService.isLoggedIn.pipe(
      filter(isLoggedIn => isLoggedIn !== null), // Remove the null values which means we haven't figured out the state yet
      map(isLoggedIn => {
        const path = next.url[0].path;
        if (path === 'login' || path === 'forgot-password' || path === 'reset-password') {
          if (!isLoggedIn) {
            return true;
          } else {
            return this.router.createUrlTree(['/']);
          }
        }

        if (isLoggedIn) {
          return true;
        } else {
          return this.router.createUrlTree(['/login']);
        }
      })
    );
  }
}
