import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormComponent } from '../../../form/form.component';
import { FormStatus } from '../../../form/form-status.enum';
import { getFormTypeById } from '../../form-type-data';
import { Humboldt2020IntakeScreeningFormData } from './humboldt2020-intake-screening-form-data';
import { MatSort, Sort } from '@angular/material/sort';
import { Humboldt2020IntakeScreeningActivityData } from './humboldt2020-intake-screening-activity-data';
import { Humboldt2020IntakeScreeningActivityListColumn } from './humboldt2020-intake-screening-activity-list-column.enum';
import { Humboldt2020IntakeScreeningActivityListFilterData } from './humboldt2020-intake-screening-activity-list-filter-data';
import { Humboldt2020IntakeScreeningActivityActivityOption } from './humboldt2020-intake-screening-activity-dialog/humboldt2020-intake-screening-activity-activity-option.enum';
import { Humboldt2020IntakeScreeningActivityListSortOptionData } from './humboldt2020-intake-screening-activity-list-sort-option-data';
import { FormBuilder, Validators } from '@angular/forms';
import { Humboldt2020IdValidator } from '../../../validators';
import { Humboldt2020IntakeScreeningReferralResponseTypeOption } from './humboldt2020-intake-screening-referral-response-type-option.enum';
import { Humboldt2020IntakeScreeningTribalAffiliationOption } from './humboldt2020-intake-screening-tribal-affiliation-option.enum';
import { Humboldt2020IntakeScreeningTribalAffiliationTypeOption } from './humboldt2020-intake-screening-tribal-affiliation-type-option.enum';
import { Humboldt2020IntakeScreeningNumberOfAllegedVictimsOption } from './humboldt2020-intake-screening-number-of-alleged-victims-option.enum';
import { Humboldt2020IntakeScreeningReasonsStandardsNotMetOption } from './humboldt2020-intake-screening-reasons-standards-not-met-option.enum';
import { ApiService } from '../../../api/api.service';
import { AuthService } from '../../../auth/auth.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DatabaseService } from '../../../database/database.service';
import { MatDialog } from '@angular/material/dialog';
import { OnlineService } from '../../../online/online.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from '../../../title/title.service';
import { debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
import { Humboldt2020IntakeScreeningActivityListFilterDialogData } from './humboldt2020-intake-screening-activity-list-filter-dialog/humboldt2020-intake-screening-activity-list-filter-dialog-data';
import { Humboldt2020IntakeScreeningActivityListFilterDialogComponent } from './humboldt2020-intake-screening-activity-list-filter-dialog/humboldt2020-intake-screening-activity-list-filter-dialog.component';
import { Humboldt2020IntakeScreeningActivityDialogData } from './humboldt2020-intake-screening-activity-dialog/humboldt2020-intake-screening-activity-dialog-data';
import { Humboldt2020IntakeScreeningActivityDialogComponent } from './humboldt2020-intake-screening-activity-dialog/humboldt2020-intake-screening-activity-dialog.component';
import { MessageDialogComponent } from '../../../message-dialog/message-dialog.component';
import { isArray } from "util";
import { YesNoOption } from '../../../form/yes-no-option.enum';

@Component({
  selector: 'app-humboldt2020-intake-screening',
  templateUrl: './humboldt2020-intake-screening.component.html',
  styleUrls: ['./humboldt2020-intake-screening.component.css']
})
export class Humboldt2020IntakeScreeningComponent extends FormComponent implements OnDestroy {
  formTypeId = 5;
  formType = getFormTypeById(this.formTypeId);
  formDataClass = Humboldt2020IntakeScreeningFormData;
  currentFormData: Humboldt2020IntakeScreeningFormData | null = null;

  activitiesDataSource: Humboldt2020IntakeScreeningActivityData[] = [];
  activityListColumnEnum = Humboldt2020IntakeScreeningActivityListColumn;
  activityListDisplayedColumns = Object.values(Humboldt2020IntakeScreeningActivityListColumn);
  activityListFilterForm = this.formBuilder.group({
    activity: [null],
    sorting: [null]
  });
  activityListFilters: Humboldt2020IntakeScreeningActivityListFilterData = {
    activity: null,
    sortColumn: null,
    sortDirection: null
  };
  activityListFilterInfoString = '';
  activityOptions = Object.values(Humboldt2020IntakeScreeningActivityActivityOption);
  activityListSortOptions: Humboldt2020IntakeScreeningActivityListSortOptionData[] = [
    {sortColumn: Humboldt2020IntakeScreeningActivityListColumn.activityDate, sortDirection: 'asc', display: 'Date Ascending'},
    {sortColumn: Humboldt2020IntakeScreeningActivityListColumn.activityDate, sortDirection: 'desc', display: 'Date Descending'},
    {sortColumn: Humboldt2020IntakeScreeningActivityListColumn.activity, sortDirection: 'asc', display: 'Activity Ascending'},
    {sortColumn: Humboldt2020IntakeScreeningActivityListColumn.activity, sortDirection: 'desc', display: 'Activity Descending'},
    {sortColumn: Humboldt2020IntakeScreeningActivityListColumn.personInvolved, sortDirection: 'asc', display: 'Person Involved Ascending'},
    {sortColumn: Humboldt2020IntakeScreeningActivityListColumn.personInvolved, sortDirection: 'desc', display: 'Person Involved Descending'},
    {sortColumn: Humboldt2020IntakeScreeningActivityListColumn.method, sortDirection: 'asc', display: 'Method Ascending'},
    {sortColumn: Humboldt2020IntakeScreeningActivityListColumn.method, sortDirection: 'desc', display: 'Method Descending'},
    {sortColumn: Humboldt2020IntakeScreeningActivityListColumn.place, sortDirection: 'asc', display: 'Place Ascending'},
    {sortColumn: Humboldt2020IntakeScreeningActivityListColumn.place, sortDirection: 'desc', display: 'Place Descending'},
    {sortColumn: Humboldt2020IntakeScreeningActivityListColumn.activityMinutesSpent, sortDirection: 'asc', display: 'Activity Minutes Spent Ascending'},
    {sortColumn: Humboldt2020IntakeScreeningActivityListColumn.activityMinutesSpent, sortDirection: 'desc', display: 'Activity Minutes Spent Descending'},
    {sortColumn: Humboldt2020IntakeScreeningActivityListColumn.activityDescription, sortDirection: 'asc', display: 'Description Ascending'},
    {sortColumn: Humboldt2020IntakeScreeningActivityListColumn.activityDescription, sortDirection: 'desc', display: 'Description Descending'},
  ];
  activityListSort: MatSort;
  @ViewChild('activityTable', {read: MatSort, static: false}) set activityListSortViewChild(value: MatSort) {
    this.activityListSort = value;
    // This timeout is needed or angular complains Expression has changed after it was checked.
    window.setTimeout(() => {
      // default in the current set sorting when the sorter is set
      // this happens if the width goes from isSmallDisplay to isLargeDisplay
      if (this.activityListSort && this.activityListFilterForm && this.activityListFilterForm.controls.sorting.value) {
        const sorting: Humboldt2020IntakeScreeningActivityListSortOptionData = this.activityListFilterForm.controls.sorting.value;
        if (this.activityListSort.active != sorting.sortColumn || this.activityListSort.direction != sorting.sortDirection) {
          if (this.activityListSort) {
            this.activityListSort.sort({
              id: sorting.sortColumn,
              start: sorting.sortDirection,
              disableClear: true
            });
          }
        }
      }
    });
  }

  form = this.formBuilder.group({
    agentName: [null],
    createdDate: [null],
    status: [null],
    statusChangedDate: [null],
    updatedDate: [null],
    referralName: [null, [Validators.required, Validators.maxLength(200)]],
    referralId: [null, [Validators.required, Validators.maxLength(22), Humboldt2020IdValidator]],
    referralResponseType: [null, Validators.required],
    reportByMandatedReporter: [null, Validators.required],
    tribalAffiliation: [null, Validators.required],
    tribalAffiliationType: [[]],
    numberOfAllegedVictims: [null, Validators.required],
    comments: [null, Validators.maxLength(500)],
    activityListFilter: this.activityListFilterForm,
    standardsMet: [null],
    reasonsStandardsNotMet: [[]],
    supervisorComments: [null, Validators.maxLength(500)]
  });

  referralResponseTypeOptions = Object.values(Humboldt2020IntakeScreeningReferralResponseTypeOption);
  tribalAffiliationOptionEnum = Humboldt2020IntakeScreeningTribalAffiliationOption;
  tribalAffiliationOptions = Object.values(Humboldt2020IntakeScreeningTribalAffiliationOption);
  tribalAffiliationTypeOptions = Object.values(Humboldt2020IntakeScreeningTribalAffiliationTypeOption);
  numberOfAllegedVictimsOptions = Object.values(Humboldt2020IntakeScreeningNumberOfAllegedVictimsOption);
  reasonsStandardsNotMetOptions = Object.values(Humboldt2020IntakeScreeningReasonsStandardsNotMetOption);

  constructor(
    public apiService: ApiService,
    public authService: AuthService,
    public breakpointObserver: BreakpointObserver,
    public databaseService: DatabaseService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    public onlineService: OnlineService,
    public route: ActivatedRoute,
    public router: Router,
    public titleService: TitleService
  ) {
    super(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, Humboldt2020IntakeScreeningFormData);
    this.loadFormTypeSettings();
    this.formChangeSubscriptions();

    this.saveFormDialogErrorData.message = 'You must provide a Sampled Referral Name to save the form.';
    this.sendBackFormDialogErrorData.message = 'You must provide a Sampled Referral Name to save the form.';

    this.discardChangesDialogData.message = 'If you discard changes, you will lose any changes you have made to this' +
      ' form since you last saved it. This includes any changes to sample information and activities.' +
      ' Are you sure you want to do this?';

    this.submitFormDialogData.message = 'Submitting the form will send it to your supervisor for review and you will no longer be able to edit this form. Make sure you have:';
    this.submitFormDialogData.messageList = [
      'Completed all of the Sample Information fields.',
      'Recorded all the time spent working on this intake during the month.'
    ];

    this.sendBackFormDialogErrorData.title = 'Send Back to Caseworker Error';
    this.sendBackFormDialogData.title = 'Send Back to Caseworker';
    this.sendBackFormDialogData.message = 'This action will send the form back to the Caseworker for modification.' +
      ' This will revert the status of the form to "In Progress" and' +
      ' you will not be able to edit the form until the Caseworker submits it again.' +
      ' Are you sure you want to do this?';
    this.sendBackFormDialogData.okButtonLabel = 'Send Back to Caseworker';

    this.completeFormDialogData.message = 'Approving the form will remove it from your queue and you will no longer be able to edit this form. Make sure:';
    this.completeFormDialogData.messageList = [
      'The form captures all the work that was completed by the caseworker.',
      'You have reviewed the form for required work activities designated by standards.',
      'Time is recorded in minutes.'
    ];
  }


  /* adds subscriptions for each of the form fields to update this.currentFormData */
  formChangeSubscriptions(): void {
    this.form.controls.referralName.valueChanges.subscribe(referralName => {
      if (this.currentFormData && this.currentFormData.name !== referralName) {
        this.currentFormData.name = referralName;
        this.saveCurrentFormValues();
      }
      if (this.formType && this.currentFormData) {
        const title = this.formType.name + ' - ' + this.currentFormData.name;
        if (this.titleService.title.value !== title) {
          this.titleService.title.next(title);
        }
      }
    });

    this.form.controls.referralId.valueChanges.subscribe(referralId => {
      if (this.currentFormData && this.currentFormData.referralId !== referralId) {
        this.currentFormData.referralId = referralId;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.referralResponseType.valueChanges.subscribe(referralResponseType => {
      if (this.currentFormData && this.currentFormData.referralResponseType !== referralResponseType) {
        this.currentFormData.caseType = referralResponseType;
        this.currentFormData.referralResponseType = referralResponseType;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.reportByMandatedReporter.valueChanges.subscribe(reportByMandatedReporter => {
      if (this.currentFormData && this.currentFormData.reportByMandatedReporter !== reportByMandatedReporter) {
        this.currentFormData.reportByMandatedReporter = reportByMandatedReporter;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.tribalAffiliation.valueChanges.subscribe(tribalAffiliation => {
      if (this.currentFormData && this.currentFormData.tribalAffiliation !== tribalAffiliation) {
        this.currentFormData.tribalAffiliation = tribalAffiliation;
        this.saveCurrentFormValues();
        this.updateTribalAffiliationTypeValidators();
      }
    });

    this.form.controls.tribalAffiliationType.valueChanges.subscribe(tribalAffiliationTypes => {
      let tribalAffiliationTypeLocalTribe = false;
      let tribalAffiliationTypeNonLocalTribe = false;

      if (isArray(tribalAffiliationTypes)) {
        (tribalAffiliationTypes as string[]).forEach(tribalAffiliationType => {
          switch (tribalAffiliationType) {
            case Humboldt2020IntakeScreeningTribalAffiliationTypeOption.LocalTribe:
              tribalAffiliationTypeLocalTribe = true;
              break;
            case Humboldt2020IntakeScreeningTribalAffiliationTypeOption.NonLocalTribe:
              tribalAffiliationTypeNonLocalTribe = true;
              break;
          }
        })
      }

      let saveCurrentFormValues = false;
      if (this.currentFormData && this.currentFormData.tribalAffiliationTypeLocalTribe !== tribalAffiliationTypeLocalTribe) {
        this.currentFormData.tribalAffiliationTypeLocalTribe = tribalAffiliationTypeLocalTribe;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.tribalAffiliationTypeNonLocalTribe !== tribalAffiliationTypeNonLocalTribe) {
        this.currentFormData.tribalAffiliationTypeNonLocalTribe = tribalAffiliationTypeNonLocalTribe;
        saveCurrentFormValues = true;
      }
      if (saveCurrentFormValues) {
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.numberOfAllegedVictims.valueChanges.subscribe(numberOfAllegedVictims => {
      if (this.currentFormData && this.currentFormData.numberOfAllegedVictims !== numberOfAllegedVictims) {
        this.currentFormData.numberOfAllegedVictims = numberOfAllegedVictims;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.comments.valueChanges.subscribe(comments => {
      if (this.currentFormData && this.currentFormData.comments !== comments) {
        this.currentFormData.comments = comments;
        this.saveCurrentFormValues();
      }
    });

    this.activityListFilterForm.controls.sorting.valueChanges.subscribe((sorting: Humboldt2020IntakeScreeningActivityListSortOptionData | null ) => {
      if (sorting && this.activityListSort && (this.activityListSort.active !== sorting.sortColumn || (this.activityListSort.direction || 'asc') !== sorting.sortDirection)) {
        this.activityListSort.sort({
          id: sorting.sortColumn,
          start: sorting.sortDirection,
          disableClear: true
        });
      }
    });

    this.activityListFilterForm.valueChanges.pipe(debounceTime(100)).subscribe(values => {
      let saveActivityListFilters = false;
      if (this.activityListFilters.activity !== values.activity) {
        this.activityListFilters.activity = values.activity;
        saveActivityListFilters = true;
      }

      if (values.sorting) {
        if (this.activityListFilters.sortColumn !== values.sorting.sortColumn || this.activityListFilters.sortDirection !== values.sorting.sortDirection) {
          this.activityListFilters.sortColumn = values.sorting.sortColumn;
          this.activityListFilters.sortDirection = values.sorting.sortDirection;
          saveActivityListFilters = true;
        }
      } else if (this.activityListFilters.sortColumn !== null || this.activityListFilters.sortDirection !== null) {
        this.activityListFilters.sortColumn = null;
        this.activityListFilters.sortDirection = null;
        saveActivityListFilters = true;
      }

      if (saveActivityListFilters) {
        this.saveFormTypeSettings();
      }

      this.activityListFilterAndSortData();
    });

    this.form.controls.standardsMet.valueChanges.subscribe(standardsMet => {
      if (this.currentFormData && this.currentFormData.standardsMet !== standardsMet) {
        this.currentFormData.standardsMet = standardsMet;
        this.saveCurrentFormValues();
        this.updateReasonsStandardsNotMetValidators();
      }
    });

    this.form.controls.reasonsStandardsNotMet.valueChanges.subscribe(reasonsStandardsNotMet => {
      let reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = false;
      let reasonsStandardsNotMetCaseworkerStoppedTracking = false;
      let reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = false;
      let reasonsStandardsNotMetOther = false;

      if (isArray(reasonsStandardsNotMet)) {
        (reasonsStandardsNotMet as string[]).forEach(reasonStandardsNotMet => {
          switch (reasonStandardsNotMet) {
            case Humboldt2020IntakeScreeningReasonsStandardsNotMetOption.NumberOfContactsOtherStandardsNotMet:
              reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = true;
              break;
            case Humboldt2020IntakeScreeningReasonsStandardsNotMetOption.CaseworkerStoppedTracking:
              reasonsStandardsNotMetCaseworkerStoppedTracking = true;
              break;
            case Humboldt2020IntakeScreeningReasonsStandardsNotMetOption.NotAllWorkDuringMonthRecorded:
              reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = true;
              break;
            case Humboldt2020IntakeScreeningReasonsStandardsNotMetOption.Other:
              reasonsStandardsNotMetOther = true;
              break;
          }
        })
      }

      let saveCurrentFormValues = false;
      if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet !== reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet) {
        this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking !== reasonsStandardsNotMetCaseworkerStoppedTracking) {
        this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking = reasonsStandardsNotMetCaseworkerStoppedTracking;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded !== reasonsStandardsNotMetNotAllWorkDuringMonthRecorded) {
        this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = reasonsStandardsNotMetNotAllWorkDuringMonthRecorded;
        saveCurrentFormValues = true;
      }
      if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetOther !== reasonsStandardsNotMetOther) {
        this.currentFormData.reasonsStandardsNotMetOther = reasonsStandardsNotMetOther;
        saveCurrentFormValues = true;
      }
      if (saveCurrentFormValues) {
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.supervisorComments.valueChanges.subscribe(supervisorComments => {
      if (this.currentFormData && this.currentFormData.supervisorComments !== supervisorComments) {
        this.currentFormData.supervisorComments = supervisorComments;
        this.saveCurrentFormValues();
      }
    });
  }

  /* updates the validators for tribalAffiliationType */
  updateTribalAffiliationTypeValidators(): void {
    if (this.currentFormData && this.currentFormData.tribalAffiliation === Humboldt2020IntakeScreeningTribalAffiliationOption.YesMaybe) {
      this.form.controls.tribalAffiliationType.setValidators(Validators.required);
    } else {
      this.form.controls.tribalAffiliationType.clearValidators();
    }
    this.form.controls.tribalAffiliationType.updateValueAndValidity();
  }

  /* updates the validators for standardsMet */
  updateStandardsMetValidators(): void {
    if (this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
      this.form.controls.standardsMet.setValidators(Validators.required);
    } else {
      this.form.controls.standardsMet.clearValidators();
    }
    this.form.controls.standardsMet.updateValueAndValidity();
  }

  /* updates the validators for reasonsStandardsNotMet */
  updateReasonsStandardsNotMetValidators(): void {
    if (this.currentFormData && this.currentFormData.status === FormStatus.InReview && this.currentFormData.standardsMet === YesNoOption.No) {
      this.form.controls.reasonsStandardsNotMet.setValidators(Validators.required);
    } else {
      this.form.controls.reasonsStandardsNotMet.clearValidators();
    }
    this.form.controls.reasonsStandardsNotMet.updateValueAndValidity();
  }

  /* Hydrates form with currentFormData values */
  afterFormDataLoaded(): void {
    super.afterFormDataLoaded();
    if (this.formType && this.currentFormData) {
      const title = this.formType.name + ' - ' + this.currentFormData.name;
      if (this.titleService.title.value !== title) {
        this.titleService.title.next(title);
      }
    }
    if (this.currentFormData) {
      this.form.controls.agentName.setValue(this.currentFormData.created.userName);
      this.form.controls.createdDate.setValue(moment(this.currentFormData.created.timestamp, 'x').format('L LT'));
      this.form.controls.status.setValue(this.currentFormData.status);
      this.form.controls.statusChangedDate.setValue(moment(this.currentFormData.statusChangeTimestamp, 'x').format('L LT'));
      this.form.controls.updatedDate.setValue(moment(this.currentFormData.updated.timestamp, 'x').format('L LT'));
      this.form.controls.referralName.setValue(this.currentFormData.name);
      this.form.controls.referralId.setValue(this.currentFormData.referralId);
      this.form.controls.referralResponseType.setValue(this.currentFormData.referralResponseType);
      this.form.controls.reportByMandatedReporter.setValue(this.currentFormData.reportByMandatedReporter);
      this.form.controls.tribalAffiliation.setValue(this.currentFormData.tribalAffiliation);
      const tribalAffiliationType: string[] = [];
      if (this.currentFormData.tribalAffiliationTypeLocalTribe) tribalAffiliationType.push(Humboldt2020IntakeScreeningTribalAffiliationTypeOption.LocalTribe);
      if (this.currentFormData.tribalAffiliationTypeNonLocalTribe) tribalAffiliationType.push(Humboldt2020IntakeScreeningTribalAffiliationTypeOption.NonLocalTribe);
      this.form.controls.tribalAffiliationType.setValue(tribalAffiliationType);
      this.form.controls.numberOfAllegedVictims.setValue(this.currentFormData.numberOfAllegedVictims);
      this.form.controls.comments.setValue(this.currentFormData.comments);

      this.activityListFilterAndSortData();

      this.form.controls.standardsMet.setValue(this.currentFormData.standardsMet);
      const reasonsStandardsNotMet: string[] = [];
      if (this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet) reasonsStandardsNotMet.push(Humboldt2020IntakeScreeningReasonsStandardsNotMetOption.NumberOfContactsOtherStandardsNotMet);
      if (this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking) reasonsStandardsNotMet.push(Humboldt2020IntakeScreeningReasonsStandardsNotMetOption.CaseworkerStoppedTracking);
      if (this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded) reasonsStandardsNotMet.push(Humboldt2020IntakeScreeningReasonsStandardsNotMetOption.NotAllWorkDuringMonthRecorded);
      if (this.currentFormData.reasonsStandardsNotMetOther) reasonsStandardsNotMet.push(Humboldt2020IntakeScreeningReasonsStandardsNotMetOption.Other);
      this.form.controls.reasonsStandardsNotMet.setValue(reasonsStandardsNotMet);
      this.form.controls.supervisorComments.setValue(this.currentFormData.supervisorComments);

      this.updateTribalAffiliationTypeValidators();
      this.updateStandardsMetValidators();
      this.updateReasonsStandardsNotMetValidators();

      // See if the loaded data has a current activity
      // This should only happen if the loaded record is a current form and the user was in the middle of editing that dialog
      if (this.currentFormData.currentActivity) {
        this.editActivity(this.currentFormData.currentActivity);
      }
    }
  }

  private async loadFormTypeSettings(): Promise<void> {
    try {
      const settingsString = await this.databaseService.getFormTypeSettings(this.formTypeId);
      if (settingsString) {
        const settings = JSON.parse(settingsString);
        if (settings.activityListFilters) {
          this.activityListFilters = settings.activityListFilters;
          this.activityListFilterForm.controls.activity.setValue(this.activityListFilters.activity);
          const activitySorting = this.activityListSortOptions.filter(sorting => sorting.sortColumn === this.activityListFilters.sortColumn && sorting.sortDirection === this.activityListFilters.sortDirection);
          if (activitySorting.length > 0) {
            this.activityListFilterForm.controls.sorting.setValue(activitySorting[0]);
          } else {
            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
          }
        } else {
          this.clearActivityListFilters();
        }
      } else {
        this.clearActivityListFilters();
      }
    } catch (error) {
      console.error('Error retrieving form type settings', error);
    }
  }

  private saveFormTypeSettings(): void {
    try {
      const settings = {
        activityListFilters: this.activityListFilters
      };
      this.databaseService.saveFormTypeSettings(this.formTypeId, JSON.stringify(settings));
    } catch (error) {
      console.error('Error saving form type settings', error);
    }
  }

  private activityListFilterAndSortData(): void {
    let sortColumn = Humboldt2020IntakeScreeningActivityListColumn.activityDate;
    let sortDirection = 'asc';
    if (this.activityListFilterForm.controls.sorting.value) {
      const sorting: Humboldt2020IntakeScreeningActivityListSortOptionData = this.activityListFilterForm.controls.sorting.value;
      sortColumn = sorting.sortColumn;
      sortDirection = sorting.sortDirection;
    }

    if (this.currentFormData && this.currentFormData.activities) {
      this.activitiesDataSource = this.currentFormData.activities.filter((activity: Humboldt2020IntakeScreeningActivityData) => {
        return !(this.activityListFilterForm.controls.activity.value && activity.activity !== this.activityListFilterForm.controls.activity.value);
      }).sort((activity1: Humboldt2020IntakeScreeningActivityData, activity2: Humboldt2020IntakeScreeningActivityData) => {
        switch (sortColumn) {
          case Humboldt2020IntakeScreeningActivityListColumn.activityDate:
            if ((activity1.activityDate || 0) > (activity2.activityDate || 0)) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.activityDate || 0) < (activity2.activityDate || 0)) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Humboldt2020IntakeScreeningActivityListColumn.activity:
            if ((activity1.activity || '') > (activity2.activity || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.activity || '') < (activity2.activity || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Humboldt2020IntakeScreeningActivityListColumn.personInvolved:
            if ((activity1.personInvolvedDisplay || '') > (activity2.personInvolvedDisplay || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.personInvolvedDisplay || '') < (activity2.personInvolvedDisplay || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Humboldt2020IntakeScreeningActivityListColumn.method:
            if ((activity1.method || '') > (activity2.method || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.method || '') < (activity2.method || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Humboldt2020IntakeScreeningActivityListColumn.place:
            if ((activity1.place || '') > (activity2.place || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.place || '') < (activity2.place || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Humboldt2020IntakeScreeningActivityListColumn.activityMinutesSpent:
            if ((activity1.activityMinutesSpent || 0) > (activity2.activityMinutesSpent || 0)) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.activityMinutesSpent || 0) < (activity2.activityMinutesSpent || 0)) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Humboldt2020IntakeScreeningActivityListColumn.activityDescription:
            if ((activity1.activityDescription || '') > (activity2.activityDescription || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.activityDescription || '') < (activity2.activityDescription || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
        }
        // sensible defaults if the key doesn't exist, or if the initial result is a tie
        if ((activity1.activityDate || 0) < (activity2.activityDate || 0)) return -1;
        if ((activity1.activityDate || 0) > (activity2.activityDate || 0)) return 1;
        return 0;
      });
    }
    this.setActivityListFilterInfoString();
  }

  private setActivityListFilterInfoString(): void {
    const info: Array<string> = [];
    if (this.activityListFilterForm.controls.activity.value) {
      info.push('Activity Filter: ' + this.activityListFilterForm.controls.activity.value);
    }
    if (this.activityListFilterForm.controls.sorting.value) {
      info.push('Sort: ' + this.activityListFilterForm.controls.sorting.value.display);
    }

    this.activityListFilterInfoString = info.join(', ');
  }

  clearActivityListFilters(): void {
    if (this.activityListFilterForm.controls.activity.value !== null) this.activityListFilterForm.controls.activity.setValue(null);
    if (this.activityListFilterForm.controls.sorting.value !== this.activityListSortOptions[0]) this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
  }

  openActivityListFilterDialog(): void {
    const dialogData: Humboldt2020IntakeScreeningActivityListFilterDialogData = {
      filterForm: this.activityListFilterForm,
      clearFunction: this.clearActivityListFilters.bind(this),
      activityOptions: this.activityOptions,
      sortOptions: this.activityListSortOptions
    };
    this.dialog.open(Humboldt2020IntakeScreeningActivityListFilterDialogComponent, {
      data: dialogData,
      width: '800px'
    });
  }

  /**
   * update the form filter when the table headers are clicked to keep them in sync
   */
  onActivityListSortChange(sort: Sort): void {
    const sortingValue = this.activityListSortOptions.filter(sortOption => sortOption.sortColumn === sort.active && sortOption.sortDirection === (sort.direction || 'asc'));
    if (sortingValue.length > 0 && sortingValue[0] !== this.activityListFilterForm.controls.sorting.value) {
      this.activityListFilterForm.controls.sorting.setValue(sortingValue[0]);
    }
  }

  editActivity(activity: Humboldt2020IntakeScreeningActivityData | null): void {
    const dialogData: Humboldt2020IntakeScreeningActivityDialogData = {
      activity: activity,
      updateCurrentActivity: this.updateCurrentActivity.bind(this)
    };
    this.dialog.open(Humboldt2020IntakeScreeningActivityDialogComponent, {
      data: dialogData,
      disableClose: true,
      width: '800px'
    }).afterClosed().subscribe((result: Humboldt2020IntakeScreeningActivityData | null) => {
      if (this.currentFormData) {
        if (result) {
          if (result.index !== null) {
            this.currentFormData.activities[result.index] = result;
          } else {
            this.currentFormData.activities.push(result);
          }
          this.currentFormData.activities.forEach((activity, index) => activity.index = index);
          this.activityListFilterAndSortData();
        }
        this.currentFormData.currentActivity = null;
        this.saveCurrentFormValues();
      }
    });
  }

  updateCurrentActivity(activity: Humboldt2020IntakeScreeningActivityData): void {
    if (this.currentFormData) {
      this.currentFormData.currentActivity = activity;
      this.saveCurrentFormValues();
    }
  }

  deleteActivity(activity: Humboldt2020IntakeScreeningActivityData): void {
    this.dialog.open(MessageDialogComponent, {
      data: {
        title: 'Delete Activity',
        message: 'Deleting this activity is irreversible.' +
          ' Once deleted any information you have entered for the activity will be gone forever.' +
          ' Are you sure you want to do this?',
        okButtonLabel: 'Delete Activity'
      },
      width: '800px'
    }).afterClosed().subscribe((result: boolean | null) => {
      if (this.currentFormData && result && activity.index !== null) {
        this.currentFormData.activities.splice(activity.index, 1);
        this.currentFormData.activities.forEach((activity, index) => activity.index = index);
        this.activityListFilterAndSortData();
        this.saveCurrentFormValues();
      }
    });
  }
}
