import { Injectable } from '@angular/core';
import { FormComponent } from './form.component';
import { CanDeactivate } from '@angular/router';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { MessageDialogData } from '../message-dialog/message-dialog-data';
import { DatabaseService } from '../database/database.service';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuardService implements CanDeactivate<FormComponent> {
  constructor(
    private databaseService: DatabaseService,
    private dialog: MatDialog
  ) {}

  canDeactivate(component: FormComponent): Observable<boolean> | boolean {
    if (component.hasUnsavedChanges) {
      const dialogData: MessageDialogData = {
        title: 'Discard Unsaved Changes',
        message: 'You have unsaved changes on the form. You will lose these changes if you leave this page. Are you sure?',
        okButtonLabel: 'Discard Unsaved Changes'
      };

      return this.dialog.open(MessageDialogComponent, {
        data: dialogData,
        width: '800px'
      }).afterClosed().pipe(
        map(result => {
          if (result) {
            component.hasUnsavedChanges = false;
            this.databaseService.deleteCurrentForm();
            return true;
          }
          return false;
        })
      );
    }

    return true;
  }
}
