import { getFormTypeById, FormTypeData } from '../project/form-type-data';
import { UserData } from '../auth/user-data';
import { FormStatus, FormStatuses } from './form-status.enum';
import { ApiSaveSurveyData } from '../api/api-save-survey-data';
import { ApiGetSurveyResponse } from '../api/api-get-survey-response';
import { isArray } from 'util';

export class SharedFormData {
  id: number;
  name: string;
  formType: FormTypeData;
  caseType: string;
  created: {
    userId: number;
    userName: string;
    timestamp: number; // unix timestamp in milliseconds
  };
  updated: {
    userId: number;
    userName: string;
    timestamp: number; // unix timestamp in milliseconds
  };
  status: string;
  statusChangeTimestamp: number; // unix timestamp in milliseconds
  fullRecord: boolean; // false for records created from api/getlist
  offlineSaveTimestamp: number | null; // unix timestamp in milliseconds

  constructor(data: any) {
    if (!data.id) {
      throw new Error('Missing id from SharedFormData constructor data.');
    }
    this.id = data.id;

    this.name = data.name || '';

    if (data.formTypeId) {
      const formTypeReturn = getFormTypeById(data.formTypeId) || null;
      if (formTypeReturn === null) {
        throw new Error('Provided formTypeId: ' + data.formTypeId.toString() + ' in SharedFormData constructor data is not valid.');
      }
      this.formType = formTypeReturn;
    } else if (data.formType) {
      this.formType = data.formType;
    } else {
      throw new Error('Missing formTypeId and formType from SharedFormData constructor data.');
    }

    this.caseType = data.caseType || '';

    if (!data.created) {
      throw new Error('Missing created from SharedFormData constructor data.');
    }

    if (!data.created.userId) {
      throw new Error('Missing created.userId from SharedFormData constructor data.');
    }
    if (!data.created.userName) {
      throw new Error('Missing created.userName from SharedFormData constructor data.');
    }
    if (!data.created.timestamp) {
      throw new Error('Missing created.timestamp from SharedFormData constructor data.');
    }
    this.created = {
      userId: data.created.userId,
      userName: data.created.userName,
      timestamp: data.created.timestamp
    };

    if (!data.updated) {
      throw new Error('Missing updated from SharedFormData constructor data.');
    }
    if (!data.updated.userId) {
      throw new Error('Missing updated.userId from SharedFormData constructor data.');
    }
    if (!data.updated.userName) {
      throw new Error('Missing updated.userName from SharedFormData constructor data.');
    }
    if (!data.updated.timestamp) {
      throw new Error('Missing updated.timestamp from SharedFormData constructor data.');
    }
    this.updated = {
      userId: data.updated.userId,
      userName: data.updated.userName,
      timestamp: data.updated.timestamp
    };

    if (!data.status) {
      throw new Error('Missing status from SharedFormData constructor data.');
    }
    if (FormStatuses.indexOf(data.status) === -1) {
      throw new Error('Invalid status from SharedFormData constructor data.');
    }
    this.status = data.status;

    if (!data.statusChangeTimestamp) {
      throw new Error('Missing statusChangeTimestamp from SharedFormData constructor data.');
    }
    this.statusChangeTimestamp = data.statusChangeTimestamp;

    this.fullRecord = data.fullRecord || false;
    this.offlineSaveTimestamp = data.offlineSaveTimestamp || null;
  }

  /* Creates a new SharedFormData class from the data */
  static createNewForm(id: number, formTypeId: number, user: UserData, timestamp: number): SharedFormData {
    return new SharedFormData(SharedFormData.newFormData(id, formTypeId, user, timestamp));
  }

  /* Constructs the data for a new form, exposed as a separate function so child classes can use it */
  static newFormData(id: number, formTypeId: number, user: UserData, timestamp: number): object {
    return {
      id: id,
      formTypeId: formTypeId,
      created: {
        userId: user.id,
        userName: user.name,
        timestamp: timestamp
      },
      updated: {
        userId: user.id,
          userName: user.name,
          timestamp: timestamp
      },
      status: FormStatus.InProgress,
      fullRecord: true,
      statusChangeTimestamp: timestamp
    }
  }

  /* creates the string to store in the database */
  toDatabaseString(): string {
    return JSON.stringify(this.toDatabaseObject());
  }

  /* creates the object to store in the database, exposed as a separate function so child classes can use it */
  toDatabaseObject(): object {
    return {
      id: this.id,
      name: this.name,
      formTypeId: this.formType.id,
      caseType: this.caseType,
      created: {
        userId: this.created.userId,
        userName: this.created.userName,
        timestamp: this.created.timestamp
      },
      updated: {
        userId: this.updated.userId,
        userName: this.updated.userName,
        timestamp: this.updated.timestamp
      },
      status: this.status,
      statusChangeTimestamp: this.statusChangeTimestamp,
      fullRecord: this.fullRecord,
      offlineSaveTimestamp: this.offlineSaveTimestamp
    };
  }

  /* Creates a SharedFormData class given the string stored in the database */
  static createFromDatabaseString(data: string): SharedFormData {
    return new SharedFormData(JSON.parse(data));
  }

  /* creates a data server object to send to the server */
  toServerObject(): ApiSaveSurveyData {
    return {
      // Less than zero is a local id only, send as -1 to have the server assign an id
      SurveyId: this.id < 0 ? '-1' : this.id.toString(10),
      SurveyName: this.name,
      SurveyTypeId: this.formType.id.toString(10),
      CaseType: this.caseType,
      CreatedBy: this.created.userId.toString(10),
      CreatedDate: Math.round(this.created.timestamp / 1000).toString(10), // milliseconds to seconds
      UpdatedBy: this.updated.userId.toString(10),
      UpdatedDate: Math.round(this.updated.timestamp / 1000).toString(10), // milliseconds to seconds
      StatusChangeDate: Math.round(this.statusChangeTimestamp / 1000).toString(10), // milliseconds to seconds
      SurveyStatus: this.status,
      SurveyItems: []
    };
  }

  static addSurveyItem(serverObject: ApiSaveSurveyData, itemName: string, itemValue: string | null, itemIndex?: number) {
    if (itemName.length > 100) {
      throw('Item name ' + itemName + ' is too long.');
    }
    serverObject.SurveyItems.push({
      ItemName: itemName,
      ItemValue: itemValue,
      ItemIndex: itemIndex !== undefined ? itemIndex.toString(10) : '-1'
    })
  }

  static addBooleanSurveyItem(serverObject: ApiSaveSurveyData, itemName: string, itemValue: boolean | null, itemIndex?: number) {
    if (itemName.length > 100) {
      throw('Item name ' + itemName + ' is too long.');
    }
    serverObject.SurveyItems.push({
      ItemName: itemName,
      ItemValue: itemValue ? 'true' : 'false',
      ItemIndex: itemIndex !== undefined ? itemIndex.toString(10) : '-1'
    })
  }

  static addNumberSurveyItem(serverObject: ApiSaveSurveyData, itemName: string, itemValue: number | null, itemIndex?: number) {
    if (itemName.length > 100) {
      throw('Item name ' + itemName + ' is too long.');
    }
    serverObject.SurveyItems.push({
      ItemName: itemName,
      ItemValue: itemValue === null ? null : itemValue.toString(10),
      ItemIndex: itemIndex !== undefined ? itemIndex.toString(10) : '-1'
    })
  }

  static addTimestampSurveyItem(serverObject: ApiSaveSurveyData, itemName: string, itemValue: number | null, itemIndex?: number) {
    if (itemName.length > 100) {
      throw('Item name ' + itemName + ' is too long.');
    }
    serverObject.SurveyItems.push({
      ItemName: itemName,
      ItemValue: itemValue === null ? null : Math.round(itemValue / 1000).toString(10),
      ItemIndex: itemIndex !== undefined ? itemIndex.toString(10) : '-1'
    })
  }

  /* creates this class from a data server object
  * fullRecord is false for records created from api/getlist */
  static createFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): SharedFormData {
    return new SharedFormData(SharedFormData.createDataFromServerObject(serverObject, fullRecord));
  }

  /* creates the data object to parse into a new class instance, exposed as a separate function so child classes can use it
  * fullRecord is false for records created from api/getlist */
  static createDataFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): any {
    const data: any = {
      id: parseInt(serverObject.SurveyId, 10),
      name: serverObject.SurveyName,
      formTypeId: parseInt(serverObject.SurveyTypeId, 10),
      caseType: serverObject.CaseType,
      created: {
        userId: parseInt(serverObject.Created.UserId, 10),
        userName: serverObject.Created.UserFullName,
        timestamp: parseInt(serverObject.Created.Date, 10) * 1000 // seconds to milliseconds
      },
      updated: {
        userId: parseInt(serverObject.Updated.UserId, 10),
        userName: serverObject.Updated.UserFullName,
        timestamp: parseInt(serverObject.Updated.Date, 10) * 1000 // seconds to milliseconds
      },
      status: serverObject.SurveyStatus,
      fullRecord: fullRecord,
      statusChangeTimestamp: parseInt(serverObject.StatusChangeDate, 10) * 1000 // seconds to milliseconds
    };

    return data;
  }

  static checkMultipleResponseIndex(item: {ItemName: string, ItemIndex: string}, parsedIndex: number): boolean {
    if (isNaN(parsedIndex) || parsedIndex < 0) {
      console.error('Bad index (' + item.ItemIndex + ') for key ' + item.ItemName + ' while parsing SurveyItems');
      return false;
    }
    return true;
  }
}
