export enum Humboldt2020OngoingEfcAdoptActivityPersonInvolvedOption {
  Youth = 'Youth',
  ParentGuardian = 'Parent/guardian',
  Tribe = 'Tribe',
  SubstituteCareProvider = 'Substitute care provider',
  Collateral = 'Collateral',
  OtherCwsStaff = 'Other CWS staff',
  Supervisor = 'Supervisor',
  None = 'None',
  Other = 'Other'
}
