import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { controlErrorMessage } from '../../../utility-functions';
import { Doc2019CaseSupportTimeActivityDialogComponent } from './doc2019-case-support-time-activity-dialog/doc2019-case-support-time-activity-dialog.component';
import { MessageDialogComponent } from '../../../message-dialog/message-dialog.component';
import { Doc2019CaseSupportTimeFormData } from './doc2019-case-support-time-form-data';
import { FormComponent } from '../../../form/form.component';
import { FormStatus } from '../../../form/form-status.enum';
import * as moment from 'moment';
import { Doc2019CaseSupportTimeActivityCaseSupportActivityOption } from './doc2019-case-support-time-activity-dialog/doc2019-case-support-time-activity-case-support-activity-option.enum';
import { Doc2019CaseSupportTimeActivityNonCaseRelatedActivityOption } from './doc2019-case-support-time-activity-dialog/doc2019-case-support-time-activity-non-case-related-activity-option.enum';
import { MatSort } from '@angular/material/sort';
import { Doc2019CaseSupportTimeActivityListColumn } from './doc2019-case-support-time-activity-list-column.enum';
import { debounceTime } from 'rxjs/operators';
import { Doc2019CaseSupportTimeActivityListFilterDialogComponent } from './doc2019-case-support-time-activity-list-filter-dialog/doc2019-case-support-time-activity-list-filter-dialog.component';
import { getFormTypeById } from '../../form-type-data';
export class Doc2019CaseSupportTimeComponent extends FormComponent {
    constructor(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, titleService) {
        super(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, Doc2019CaseSupportTimeFormData);
        this.apiService = apiService;
        this.authService = authService;
        this.breakpointObserver = breakpointObserver;
        this.databaseService = databaseService;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.onlineService = onlineService;
        this.route = route;
        this.router = router;
        this.titleService = titleService;
        this.formTypeId = 1;
        this.formType = getFormTypeById(this.formTypeId);
        this.formDataClass = Doc2019CaseSupportTimeFormData;
        this.currentFormData = null;
        this.activitiesDataSource = [];
        this.activityListColumnEnum = Doc2019CaseSupportTimeActivityListColumn;
        this.activityListDisplayedColumns = Object.values(Doc2019CaseSupportTimeActivityListColumn);
        this.activityListFilterForm = this.formBuilder.group({
            activity: [null],
            sorting: [null]
        });
        this.activityListFilters = {
            activity: null,
            sortColumn: null,
            sortDirection: null
        };
        this.activityListFilterInfoString = '';
        this.nonCaseRelatedActivityOptionEnum = Doc2019CaseSupportTimeActivityNonCaseRelatedActivityOption;
        this.nonCaseRelatedActivityOptions = Object.values(Doc2019CaseSupportTimeActivityNonCaseRelatedActivityOption);
        this.caseSupportActivityOptionEnum = Doc2019CaseSupportTimeActivityCaseSupportActivityOption;
        this.caseSupportActivityOptions = Object.values(Doc2019CaseSupportTimeActivityCaseSupportActivityOption);
        this.activityListSortOptions = [
            { sortColumn: Doc2019CaseSupportTimeActivityListColumn.activityDate, sortDirection: 'asc', display: 'Date Ascending' },
            { sortColumn: Doc2019CaseSupportTimeActivityListColumn.activityDate, sortDirection: 'desc', display: 'Date Descending' },
            { sortColumn: Doc2019CaseSupportTimeActivityListColumn.activity, sortDirection: 'asc', display: 'Activity Ascending' },
            { sortColumn: Doc2019CaseSupportTimeActivityListColumn.activity, sortDirection: 'desc', display: 'Activity Descending' },
            { sortColumn: Doc2019CaseSupportTimeActivityListColumn.activityMinutesSpent, sortDirection: 'asc', display: 'Activity Minutes Spent Ascending' },
            { sortColumn: Doc2019CaseSupportTimeActivityListColumn.activityMinutesSpent, sortDirection: 'desc', display: 'Activity Minutes Spent Descending' },
            { sortColumn: Doc2019CaseSupportTimeActivityListColumn.travelMinutesSpent, sortDirection: 'asc', display: 'Travel Minutes Spent Ascending' },
            { sortColumn: Doc2019CaseSupportTimeActivityListColumn.travelMinutesSpent, sortDirection: 'desc', display: 'Travel Minutes Spent Descending' },
            { sortColumn: Doc2019CaseSupportTimeActivityListColumn.notes, sortDirection: 'asc', display: 'Notes Ascending' },
            { sortColumn: Doc2019CaseSupportTimeActivityListColumn.notes, sortDirection: 'desc', display: 'Notes Descending' },
        ];
        this.form = this.formBuilder.group({
            agentName: [null],
            createdDate: [null],
            status: [null],
            statusChangedDate: [null],
            updatedDate: [null],
            agentComments: [null],
            activityListFilter: this.activityListFilterForm,
            allWorkDocumented: [null]
        });
        this.errorMessage = controlErrorMessage;
        this.loadFormTypeSettings();
        this.formChangeSubscriptions();
        this.submitFormDialogData.message = 'Submitting the form will send it to your supervisor for review and you will no longer be able to edit this form. Make sure you have:';
        this.submitFormDialogData.messageList = [
            'Recorded all the time spent on case support and non-case-related activities during the month'
        ];
        this.completeFormDialogData.message = 'Approving the form will remove it from your queue and you will no longer be able to edit this form. Make sure:';
        this.completeFormDialogData.messageList = [
            'The form captures all the work that was completed by the agent.',
            'Time is recorded in minutes.'
        ];
    }
    set activityListSortViewChild(value) {
        this.activityListSort = value;
        // This timeout is needed or angular complains Expression has changed after it was checked.
        window.setTimeout(() => {
            // default in the current set sorting when the sorter is set
            // this happens if the width goes from isSmallDisplay to isLargeDisplay
            if (this.activityListSort && this.activityListFilterForm && this.activityListFilterForm.controls.sorting.value) {
                const sorting = this.activityListFilterForm.controls.sorting.value;
                if (this.activityListSort.active != sorting.sortColumn || this.activityListSort.direction != sorting.sortDirection) {
                    if (this.activityListSort) {
                        this.activityListSort.sort({
                            id: sorting.sortColumn,
                            start: sorting.sortDirection,
                            disableClear: true
                        });
                    }
                }
            }
        });
    }
    /* adds subscriptions for each of the form fields to update this.currentFormData */
    formChangeSubscriptions() {
        this.form.controls.agentComments.valueChanges.subscribe(agentComments => {
            if (this.currentFormData && this.currentFormData.agentComments !== agentComments) {
                this.currentFormData.agentComments = agentComments;
                this.saveCurrentFormValues();
            }
        });
        this.activityListFilterForm.controls.sorting.valueChanges.subscribe((sorting) => {
            if (sorting && this.activityListSort && (this.activityListSort.active !== sorting.sortColumn || (this.activityListSort.direction || 'asc') !== sorting.sortDirection)) {
                this.activityListSort.sort({
                    id: sorting.sortColumn,
                    start: sorting.sortDirection,
                    disableClear: true
                });
            }
        });
        this.activityListFilterForm.valueChanges.pipe(debounceTime(100)).subscribe(values => {
            let saveActivityListFilters = false;
            if (this.activityListFilters.activity !== values.activity) {
                this.activityListFilters.activity = values.activity;
                saveActivityListFilters = true;
            }
            if (values.sorting) {
                if (this.activityListFilters.sortColumn !== values.sorting.sortColumn || this.activityListFilters.sortDirection !== values.sorting.sortDirection) {
                    this.activityListFilters.sortColumn = values.sorting.sortColumn;
                    this.activityListFilters.sortDirection = values.sorting.sortDirection;
                    saveActivityListFilters = true;
                }
            }
            else if (this.activityListFilters.sortColumn !== null || this.activityListFilters.sortDirection !== null) {
                this.activityListFilters.sortColumn = null;
                this.activityListFilters.sortDirection = null;
                saveActivityListFilters = true;
            }
            if (saveActivityListFilters) {
                this.saveFormTypeSettings();
            }
            this.activityListFilterAndSortData();
        });
        this.form.controls.allWorkDocumented.valueChanges.subscribe(allWorkDocumented => {
            if (this.currentFormData && this.currentFormData.allWorkDocumented !== allWorkDocumented) {
                this.currentFormData.allWorkDocumented = allWorkDocumented;
                this.saveCurrentFormValues();
            }
        });
    }
    /* updates the validators for workMetStandardsNoReasons */
    updateAllWorkDocumentedValidators() {
        if (this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
            this.form.controls.allWorkDocumented.setValidators(Validators.required);
        }
        else {
            this.form.controls.allWorkDocumented.clearValidators();
        }
        this.form.controls.allWorkDocumented.updateValueAndValidity();
    }
    /* Hydrates form with currentFormData values */
    afterFormDataLoaded() {
        super.afterFormDataLoaded();
        if (this.currentFormData) {
            if (!this.currentFormData.name || this.currentFormData.name !== this.currentFormData.created.userName) {
                // Setting the name from the created user's name since there is no name field on the form
                this.currentFormData.name = this.currentFormData.created.userName;
            }
            if (this.formType) {
                const title = this.formType.name + ' - ' + this.currentFormData.name;
                if (this.titleService.title.value !== title) {
                    this.titleService.title.next(title);
                }
            }
            this.form.controls.agentName.setValue(this.currentFormData.created.userName);
            this.form.controls.createdDate.setValue(moment(this.currentFormData.created.timestamp, 'x').format('L LT'));
            this.form.controls.status.setValue(this.currentFormData.status);
            this.form.controls.statusChangedDate.setValue(moment(this.currentFormData.statusChangeTimestamp, 'x').format('L LT'));
            this.form.controls.updatedDate.setValue(moment(this.currentFormData.updated.timestamp, 'x').format('L LT'));
            this.form.controls.agentComments.setValue(this.currentFormData.agentComments);
            this.form.controls.allWorkDocumented.setValue(this.currentFormData.allWorkDocumented);
            this.activityListFilterAndSortData();
            this.updateAllWorkDocumentedValidators();
            // See if the loaded data has a current activity
            // This should only happen if the loaded record is a current form and the user was in the middle of editing that dialog
            if (this.currentFormData.currentActivity) {
                this.editActivity(this.currentFormData.currentActivity);
            }
        }
    }
    loadFormTypeSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const settingsString = yield this.databaseService.getFormTypeSettings(this.formTypeId);
                if (settingsString) {
                    const settings = JSON.parse(settingsString);
                    if (settings.activityListFilters) {
                        this.activityListFilters = settings.activityListFilters;
                        this.activityListFilterForm.controls.activity.setValue(this.activityListFilters.activity);
                        const activitySorting = this.activityListSortOptions.filter(sorting => sorting.sortColumn === this.activityListFilters.sortColumn && sorting.sortDirection === this.activityListFilters.sortDirection);
                        if (activitySorting.length > 0) {
                            this.activityListFilterForm.controls.sorting.setValue(activitySorting[0]);
                        }
                        else {
                            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
                        }
                    }
                    else {
                        this.clearActivityListFilters();
                    }
                }
                else {
                    this.clearActivityListFilters();
                }
            }
            catch (error) {
                console.error('Error retrieving form type settings', error);
            }
        });
    }
    saveFormTypeSettings() {
        const settings = {
            activityListFilters: this.activityListFilters
        };
        this.databaseService.saveFormTypeSettings(this.formTypeId, JSON.stringify(settings));
    }
    activityListFilterAndSortData() {
        let sortColumn = Doc2019CaseSupportTimeActivityListColumn.activityDate;
        let sortDirection = 'asc';
        if (this.activityListFilterForm.controls.sorting.value) {
            const sorting = this.activityListFilterForm.controls.sorting.value;
            sortColumn = sorting.sortColumn;
            sortDirection = sorting.sortDirection;
        }
        if (this.currentFormData && this.currentFormData.activities) {
            this.activitiesDataSource = this.currentFormData.activities.filter((activity) => {
                if (this.activityListFilterForm.controls.activity.value && activity.activity !== this.activityListFilterForm.controls.activity.value) {
                    return false;
                }
                return true;
            }).sort((activity1, activity2) => {
                switch (sortColumn) {
                    case Doc2019CaseSupportTimeActivityListColumn.activityDate:
                        if ((activity1.activityDate || 0) > (activity2.activityDate || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityDate || 0) < (activity2.activityDate || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Doc2019CaseSupportTimeActivityListColumn.activity:
                        const activityDisplay1 = (activity1.activity === Doc2019CaseSupportTimeActivityNonCaseRelatedActivityOption.OtherNonCaseRelatedActivity ||
                            activity1.activity === Doc2019CaseSupportTimeActivityCaseSupportActivityOption.OtherCaseSupport) &&
                            activity1.activityOther ? activity1.activityOther : activity1.activity;
                        const activityDisplay2 = (activity2.activity === Doc2019CaseSupportTimeActivityNonCaseRelatedActivityOption.OtherNonCaseRelatedActivity ||
                            activity2.activity === Doc2019CaseSupportTimeActivityCaseSupportActivityOption.OtherCaseSupport) &&
                            activity2.activityOther ? activity2.activityOther : activity2.activity;
                        if ((activityDisplay1 || '') > (activityDisplay2 || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activityDisplay1 || '') < (activityDisplay2 || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Doc2019CaseSupportTimeActivityListColumn.activityMinutesSpent:
                        if ((activity1.activityMinutesSpent || 0) > (activity2.activityMinutesSpent || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityMinutesSpent || 0) < (activity2.activityMinutesSpent || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Doc2019CaseSupportTimeActivityListColumn.travelMinutesSpent:
                        if ((activity1.travelMinutesSpent || 0) > (activity2.travelMinutesSpent || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.travelMinutesSpent || 0) < (activity2.travelMinutesSpent || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Doc2019CaseSupportTimeActivityListColumn.notes:
                        if ((activity1.notes || '') > (activity2.notes || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.notes || '') < (activity2.notes || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                }
                // sensible defaults if the key doesn't exist, or if the initial result is a tie
                if ((activity1.activityDate || 0) < (activity2.activityDate || 0))
                    return -1;
                if ((activity1.activityDate || 0) > (activity2.activityDate || 0))
                    return 1;
                return 0;
            });
        }
        this.setActivityListFilterInfoString();
    }
    setActivityListFilterInfoString() {
        const info = [];
        if (this.activityListFilterForm.controls.activity.value) {
            info.push('Activity Filter: ' + this.activityListFilterForm.controls.activity.value);
        }
        if (this.activityListFilterForm.controls.sorting.value) {
            info.push('Sort: ' + this.activityListFilterForm.controls.sorting.value.display);
        }
        this.activityListFilterInfoString = info.join(', ');
    }
    clearActivityListFilters() {
        if (this.activityListFilterForm.controls.activity.value !== null)
            this.activityListFilterForm.controls.activity.setValue(null);
        if (this.activityListFilterForm.controls.sorting.value !== this.activityListSortOptions[0])
            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
    }
    openActivityListFilterDialog() {
        const dialogData = {
            filterForm: this.activityListFilterForm,
            clearFunction: this.clearActivityListFilters.bind(this),
            nonCaseRelatedActivityOptions: this.nonCaseRelatedActivityOptions,
            caseSupportActivityOptions: this.caseSupportActivityOptions,
            sortOptions: this.activityListSortOptions
        };
        this.dialog.open(Doc2019CaseSupportTimeActivityListFilterDialogComponent, {
            data: dialogData,
            width: '800px'
        });
    }
    /**
     * update the form filter when the table headers are clicked to keep them in sync
     */
    onActivityListSortChange(sort) {
        const sortingValue = this.activityListSortOptions.filter(sortOption => sortOption.sortColumn === sort.active && sortOption.sortDirection === (sort.direction || 'asc'));
        if (sortingValue.length > 0 && sortingValue[0] !== this.activityListFilterForm.controls.sorting.value) {
            this.activityListFilterForm.controls.sorting.setValue(sortingValue[0]);
        }
    }
    editActivity(activity) {
        const dialogData = {
            activity: activity,
            updateCurrentActivity: this.updateCurrentActivity.bind(this)
        };
        this.dialog.open(Doc2019CaseSupportTimeActivityDialogComponent, {
            data: dialogData,
            disableClose: true,
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (this.currentFormData) {
                if (result) {
                    if (result.index !== null) {
                        this.currentFormData.activities[result.index] = result;
                    }
                    else {
                        this.currentFormData.activities.push(result);
                    }
                    this.currentFormData.activities.forEach((activity, index) => activity.index = index);
                    this.activityListFilterAndSortData();
                }
                this.currentFormData.currentActivity = null;
                this.saveCurrentFormValues();
            }
        });
    }
    updateCurrentActivity(activity) {
        if (this.currentFormData) {
            this.currentFormData.currentActivity = activity;
            this.saveCurrentFormValues();
        }
    }
    deleteActivity(activity) {
        this.dialog.open(MessageDialogComponent, {
            data: {
                title: 'Delete Activity',
                message: 'Deleting this activity is irreversible.' +
                    ' Once deleted any information you have entered for the activity will be gone forever.' +
                    ' Are you sure you want to do this?',
                okButtonLabel: 'Delete Activity'
            },
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (this.currentFormData && result && activity.index !== null) {
                this.currentFormData.activities.splice(activity.index, 1);
                this.currentFormData.activities.forEach((activity, index) => activity.index = index);
                this.activityListFilterAndSortData();
                this.saveCurrentFormValues();
            }
        });
    }
}
