import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormComponent } from '../../../form/form.component';
import { getFormTypeById } from '../../form-type-data';
import { MatSort, Sort } from '@angular/material/sort';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../api/api.service';
import { AuthService } from '../../../auth/auth.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DatabaseService } from '../../../database/database.service';
import { MatDialog } from '@angular/material/dialog';
import { OnlineService } from '../../../online/online.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from '../../../title/title.service';
import { isArray } from "util";
import { debounceTime } from 'rxjs/operators';
import { FormStatus } from '../../../form/form-status.enum';
import * as moment from 'moment';
import { MessageDialogComponent } from '../../../message-dialog/message-dialog.component';
import { Humboldt2020SupportAdminFormData } from './humboldt2020-support-admin-form-data';
import { Humboldt2020SupportAdminActivityData } from './humboldt2020-support-admin-activity-data';
import { Humboldt2020SupportAdminActivityListColumn } from './humboldt2020-support-admin-activity-list-column.enum';
import { Humboldt2020SupportAdminActivityListFilterData } from './humboldt2020-support-admin-activity-list-filter-data';
import { Humboldt2020SupportAdminActivitySupportActivityOption } from './humboldt2020-support-admin-activity-dialog/humboldt2020-support-admin-activity-support-activity-option.enum';
import { Humboldt2020SupportAdminActivityAdminActivityOption } from './humboldt2020-support-admin-activity-dialog/humboldt2020-support-admin-activity-admin-activity-option.enum';
import { Humboldt2020SupportAdminActivityListSortOptionData } from './humboldt2020-support-admin-activity-list-sort-option-data';
import { Humboldt2020SupportAdminPrimaryProgramResponsibilityOption } from './humboldt2020-support-admin-primary-program-responsibility-option.enum';
import { Humboldt2020SupportAdminYearsOfExperienceOption } from './humboldt2020-support-admin-years-of-experience-option.enum';
import { Humboldt2020SupportAdminActivityListFilterDialogData } from './humboldt2020-support-admin-activity-list-filter-dialog/humboldt2020-support-admin-activity-list-filter-dialog-data';
import { Humboldt2020SupportAdminActivityListFilterDialogComponent } from './humboldt2020-support-admin-activity-list-filter-dialog/humboldt2020-support-admin-activity-list-filter-dialog.component';
import { Humboldt2020SupportAdminActivityDialogData } from './humboldt2020-support-admin-activity-dialog/humboldt2020-support-admin-activity-dialog-data';
import { Humboldt2020SupportAdminActivityDialogComponent } from './humboldt2020-support-admin-activity-dialog/humboldt2020-support-admin-activity-dialog.component';

@Component({
  selector: 'app-humboldt2020-support-admin',
  templateUrl: './humboldt2020-support-admin.component.html',
  styleUrls: ['./humboldt2020-support-admin.component.css']
})
export class Humboldt2020SupportAdminComponent extends FormComponent implements OnDestroy {
  formTypeId = 10;
  formType = getFormTypeById(this.formTypeId);
  formDataClass = Humboldt2020SupportAdminFormData;
  currentFormData: Humboldt2020SupportAdminFormData | null = null;

  activitiesDataSource: Humboldt2020SupportAdminActivityData[] = [];
  activityListColumnEnum = Humboldt2020SupportAdminActivityListColumn;
  activityListDisplayedColumns = Object.values(Humboldt2020SupportAdminActivityListColumn);
  activityListFilterForm = this.formBuilder.group({
    activity: [null],
    sorting: [null]
  });
  activityListFilters: Humboldt2020SupportAdminActivityListFilterData = {
    activity: null,
    sortColumn: null,
    sortDirection: null
  };
  activityListFilterInfoString = '';
  supportActivityOptions = Object.values(Humboldt2020SupportAdminActivitySupportActivityOption);
  adminActivityOptions = Object.values(Humboldt2020SupportAdminActivityAdminActivityOption);
  activityListSortOptions: Humboldt2020SupportAdminActivityListSortOptionData[] = [
    {sortColumn: Humboldt2020SupportAdminActivityListColumn.activityDate, sortDirection: 'asc', display: 'Date Ascending'},
    {sortColumn: Humboldt2020SupportAdminActivityListColumn.activityDate, sortDirection: 'desc', display: 'Date Descending'},
    {sortColumn: Humboldt2020SupportAdminActivityListColumn.activity, sortDirection: 'asc', display: 'Activity Ascending'},
    {sortColumn: Humboldt2020SupportAdminActivityListColumn.activity, sortDirection: 'desc', display: 'Activity Descending'},
    {sortColumn: Humboldt2020SupportAdminActivityListColumn.cwsProgramSupport, sortDirection: 'asc', display: 'CWS Program Supported Ascending'},
    {sortColumn: Humboldt2020SupportAdminActivityListColumn.cwsProgramSupport, sortDirection: 'desc', display: 'CWS Program Supported Descending'},
    {sortColumn: Humboldt2020SupportAdminActivityListColumn.activityMinutesSpent, sortDirection: 'asc', display: 'Activity Minutes Spent Ascending'},
    {sortColumn: Humboldt2020SupportAdminActivityListColumn.activityMinutesSpent, sortDirection: 'desc', display: 'Activity Minutes Spent Descending'},
    {sortColumn: Humboldt2020SupportAdminActivityListColumn.travelMinutesSpent, sortDirection: 'asc', display: 'Travel Minutes Spent Ascending'},
    {sortColumn: Humboldt2020SupportAdminActivityListColumn.travelMinutesSpent, sortDirection: 'desc', display: 'Travel Minutes Spent Descending'},
    {sortColumn: Humboldt2020SupportAdminActivityListColumn.activityDescription, sortDirection: 'asc', display: 'Description Ascending'},
    {sortColumn: Humboldt2020SupportAdminActivityListColumn.activityDescription, sortDirection: 'desc', display: 'Description Descending'},
  ];
  activityListSort: MatSort;
  @ViewChild('activityTable', {read: MatSort, static: false}) set activityListSortViewChild(value: MatSort) {
    this.activityListSort = value;
    // This timeout is needed or angular complains Expression has changed after it was checked.
    window.setTimeout(() => {
      // default in the current set sorting when the sorter is set
      // this happens if the width goes from isSmallDisplay to isLargeDisplay
      if (this.activityListSort && this.activityListFilterForm && this.activityListFilterForm.controls.sorting.value) {
        const sorting: Humboldt2020SupportAdminActivityListSortOptionData = this.activityListFilterForm.controls.sorting.value;
        if (this.activityListSort.active != sorting.sortColumn || this.activityListSort.direction != sorting.sortDirection) {
          if (this.activityListSort) {
            this.activityListSort.sort({
              id: sorting.sortColumn,
              start: sorting.sortDirection,
              disableClear: true
            });
          }
        }
      }
    });
  }

  form = this.formBuilder.group({
    agentName: [null],
    createdDate: [null],
    status: [null],
    statusChangedDate: [null],
    updatedDate: [null],
    primaryProgramResponsibility: [null, Validators.required],
    yearsOfExperience: [null, Validators.required],
    comments: [null, Validators.maxLength(500)],
    activityListFilter: this.activityListFilterForm,
    allWorkDocumented: [null],
    supervisorComments: [null, Validators.maxLength(500)]
  });

  primaryProgramResponsibilityOptions = Object.values(Humboldt2020SupportAdminPrimaryProgramResponsibilityOption);
  yearsOfExperienceOptions = Object.values(Humboldt2020SupportAdminYearsOfExperienceOption);

  constructor(
    public apiService: ApiService,
    public authService: AuthService,
    public breakpointObserver: BreakpointObserver,
    public databaseService: DatabaseService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    public onlineService: OnlineService,
    public route: ActivatedRoute,
    public router: Router,
    public titleService: TitleService
  ) {
    super(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, Humboldt2020SupportAdminFormData);
    this.loadFormTypeSettings();
    this.formChangeSubscriptions();

    this.submitFormDialogData.message = 'Submitting the form will send it to your supervisor for review and you will no longer be able to edit this form. Make sure you have:';
    this.submitFormDialogData.messageList = [
      'Completed all of the header fields.',
      'Recorded all the time spent on support and administrative activities during the month\n.'
    ];

    this.discardChangesDialogData.message = 'If you discard changes, you will lose any changes you have made to this' +
      ' form since you last saved it. This includes any changes to header and activities.' +
      ' Are you sure you want to do this?';

    this.sendBackFormDialogErrorData.title = 'Send Back to Caseworker Error';
    this.sendBackFormDialogData.title = 'Send Back to Caseworker';
    this.sendBackFormDialogData.message = 'This action will send the form back to the Caseworker for modification.' +
      ' This will revert the status of the form to "In Progress" and' +
      ' you will not be able to edit the form until the Caseworker submits it again.' +
      ' Are you sure you want to do this?';
    this.sendBackFormDialogData.okButtonLabel = 'Send Back to Caseworker';

    this.completeFormDialogData.message = 'Approving the form will remove it from your queue and you will no longer be able to edit this form. Make sure:';
    this.completeFormDialogData.messageList = [
      'The form captures all the work that was completed by the caseworker.',
      'You have reviewed the form for required support and non-case administrative work activities.',
      'Time is recorded in minutes.'
    ];
  }

  /* adds subscriptions for each of the form fields to update this.currentFormData */
  formChangeSubscriptions(): void {
    this.form.controls.primaryProgramResponsibility.valueChanges.subscribe(primaryProgramResponsibility => {
      if (this.currentFormData && this.currentFormData.primaryProgramResponsibility !== primaryProgramResponsibility) {
        this.currentFormData.primaryProgramResponsibility = primaryProgramResponsibility;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.yearsOfExperience.valueChanges.subscribe(yearsOfExperience => {
      if (this.currentFormData && this.currentFormData.yearsOfExperience !== yearsOfExperience) {
        this.currentFormData.yearsOfExperience = yearsOfExperience;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.comments.valueChanges.subscribe(comments => {
      if (this.currentFormData && this.currentFormData.comments !== comments) {
        this.currentFormData.comments = comments;
        this.saveCurrentFormValues();
      }
    });

    this.activityListFilterForm.controls.sorting.valueChanges.subscribe((sorting: Humboldt2020SupportAdminActivityListSortOptionData | null ) => {
      if (sorting && this.activityListSort && (this.activityListSort.active !== sorting.sortColumn || (this.activityListSort.direction || 'asc') !== sorting.sortDirection)) {
        this.activityListSort.sort({
          id: sorting.sortColumn,
          start: sorting.sortDirection,
          disableClear: true
        });
      }
    });

    this.activityListFilterForm.valueChanges.pipe(debounceTime(100)).subscribe(values => {
      let saveActivityListFilters = false;
      if (this.activityListFilters.activity !== values.activity) {
        this.activityListFilters.activity = values.activity;
        saveActivityListFilters = true;
      }

      if (values.sorting) {
        if (this.activityListFilters.sortColumn !== values.sorting.sortColumn || this.activityListFilters.sortDirection !== values.sorting.sortDirection) {
          this.activityListFilters.sortColumn = values.sorting.sortColumn;
          this.activityListFilters.sortDirection = values.sorting.sortDirection;
          saveActivityListFilters = true;
        }
      } else if (this.activityListFilters.sortColumn !== null || this.activityListFilters.sortDirection !== null) {
        this.activityListFilters.sortColumn = null;
        this.activityListFilters.sortDirection = null;
        saveActivityListFilters = true;
      }

      if (saveActivityListFilters) {
        this.saveFormTypeSettings();
      }

      this.activityListFilterAndSortData();
    });

    this.form.controls.allWorkDocumented.valueChanges.subscribe(allWorkDocumented => {
      if (this.currentFormData && this.currentFormData.allWorkDocumented !== allWorkDocumented) {
        this.currentFormData.allWorkDocumented = allWorkDocumented;
        this.saveCurrentFormValues();
      }
    });

    this.form.controls.supervisorComments.valueChanges.subscribe(supervisorComments => {
      if (this.currentFormData && this.currentFormData.supervisorComments !== supervisorComments) {
        this.currentFormData.supervisorComments = supervisorComments;
        this.saveCurrentFormValues();
      }
    });
  }

  /* updates the validators for allWorkDocumented */
  updateAllWorkDocumentedValidators(): void {
    if (this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
      this.form.controls.allWorkDocumented.setValidators(Validators.required);
    } else {
      this.form.controls.allWorkDocumented.clearValidators();
    }
    this.form.controls.allWorkDocumented.updateValueAndValidity();
  }

  /* Hydrates form with currentFormData values */
  afterFormDataLoaded(): void {
    super.afterFormDataLoaded();
    if (this.currentFormData) {
      if (!this.currentFormData.name || this.currentFormData.name !== this.currentFormData.created.userName) {
        // Setting the name from the created user's name since there is no name field on the form
        this.currentFormData.name = this.currentFormData.created.userName;
      }
      if (this.formType) {
        const title = this.formType.name + ' - ' + this.currentFormData.name;
        if (this.titleService.title.value !== title) {
          this.titleService.title.next(title);
        }
      }
      this.form.controls.agentName.setValue(this.currentFormData.created.userName);
      this.form.controls.createdDate.setValue(moment(this.currentFormData.created.timestamp, 'x').format('L LT'));
      this.form.controls.status.setValue(this.currentFormData.status);
      this.form.controls.statusChangedDate.setValue(moment(this.currentFormData.statusChangeTimestamp, 'x').format('L LT'));
      this.form.controls.updatedDate.setValue(moment(this.currentFormData.updated.timestamp, 'x').format('L LT'));

      this.form.controls.primaryProgramResponsibility.setValue(this.currentFormData.primaryProgramResponsibility);
      this.form.controls.yearsOfExperience.setValue(this.currentFormData.yearsOfExperience);
      this.form.controls.comments.setValue(this.currentFormData.comments);

      this.activityListFilterAndSortData();

      this.form.controls.allWorkDocumented.setValue(this.currentFormData.allWorkDocumented);
      this.form.controls.supervisorComments.setValue(this.currentFormData.supervisorComments);

      this.updateAllWorkDocumentedValidators();

      // See if the loaded data has a current activity
      // This should only happen if the loaded record is a current form and the user was in the middle of editing that dialog
      if (this.currentFormData.currentActivity) {
        this.editActivity(this.currentFormData.currentActivity);
      }
    }
  }

  /**
   * This function does validation other than form.valid before submitting or completing
   * If validation does not pass it should return false and possibly set
   * submitFormDialogErrorData.message or completeFormDialogErrorData.message
   */
  extraFormValidation(action: 'submit' | 'complete'): boolean {
    let errorMessage: string | null = null;
    if (this.currentFormData !== null && isArray(this.currentFormData.activities)) {
      if (this.currentFormData.primaryProgramResponsibility !== Humboldt2020SupportAdminPrimaryProgramResponsibilityOption.CourtIntake) {
        const nonRemovalWarrantsOtherReportsActivities = this.currentFormData.activities.filter((activity) => activity.activity === Humboldt2020SupportAdminActivitySupportActivityOption.NonRemovalWarrantsOtherReports);
        if (nonRemovalWarrantsOtherReportsActivities.length) {
          errorMessage = 'There are ' + Humboldt2020SupportAdminActivitySupportActivityOption.NonRemovalWarrantsOtherReports + ' activities on the form,' +
            ' but this activity type is not valid for Primary Program Responsibility of ' + this.currentFormData.primaryProgramResponsibility + '.' +
            ' Please change these activity types.';
        }
      }
      if (this.currentFormData.primaryProgramResponsibility !== Humboldt2020SupportAdminPrimaryProgramResponsibilityOption.Rfa) {
        const nonRemovalWarrantsOtherReportsActivities = this.currentFormData.activities.filter((activity) => activity.activity === Humboldt2020SupportAdminActivitySupportActivityOption.RfaFollowUpCaseManagement);
        if (nonRemovalWarrantsOtherReportsActivities.length) {
          errorMessage = 'There are ' + Humboldt2020SupportAdminActivitySupportActivityOption.RfaFollowUpCaseManagement + ' activities on the form,' +
            ' but this activity type is not valid for Primary Program Responsibility of ' + this.currentFormData.primaryProgramResponsibility + '.' +
            ' Please change these activity types.';
        }
      }
      if (errorMessage === null && this.currentFormData.primaryProgramResponsibility !== Humboldt2020SupportAdminPrimaryProgramResponsibilityOption.Ils) {
        const outreachActiveCasesActivities = this.currentFormData.activities.filter((activity) => activity.activity === Humboldt2020SupportAdminActivityAdminActivityOption.Outreach);
        const workshopsAndEventsCoordinationPlanningActivities = this.currentFormData.activities.filter((activity) => activity.activity === Humboldt2020SupportAdminActivityAdminActivityOption.WorkshopsAndEventsCoordinationPlanning);
        const workshopAndEventDeliveryActivities = this.currentFormData.activities.filter((activity) => activity.activity === Humboldt2020SupportAdminActivityAdminActivityOption.WorkshopAndEventDelivery);
        const calendarNewsletterActivities = this.currentFormData.activities.filter((activity) => activity.activity === Humboldt2020SupportAdminActivityAdminActivityOption.CalendarNewsletter);
        if (outreachActiveCasesActivities.length) {
          errorMessage = 'There are ' + Humboldt2020SupportAdminActivityAdminActivityOption.Outreach + ' activities on the form,' +
            ' but this activity type is not valid for Primary Program Responsibility of ' + this.currentFormData.primaryProgramResponsibility + '.' +
            ' Please change these activity types.';
        } else if (workshopsAndEventsCoordinationPlanningActivities.length) {
          errorMessage = 'There are ' + Humboldt2020SupportAdminActivityAdminActivityOption.WorkshopsAndEventsCoordinationPlanning + ' activities on the form,' +
            ' but this activity type is not valid for Primary Program Responsibility of ' + this.currentFormData.primaryProgramResponsibility + '.' +
            ' Please change these activity types.';
        } else if (workshopAndEventDeliveryActivities.length) {
          errorMessage = 'There are ' + Humboldt2020SupportAdminActivityAdminActivityOption.WorkshopAndEventDelivery + ' activities on the form,' +
            ' but this activity type is not valid for Primary Program Responsibility of ' + this.currentFormData.primaryProgramResponsibility + '.' +
            ' Please change these activity types.';
        } else if (calendarNewsletterActivities.length) {
          errorMessage = 'There are ' + Humboldt2020SupportAdminActivityAdminActivityOption.CalendarNewsletter + ' activities on the form,' +
            ' but this activity type is not valid for Primary Program Responsibility of ' + this.currentFormData.primaryProgramResponsibility + '.' +
            ' Please change these activity types.';
        }
      }

      if (errorMessage !== null) {
        if (action === 'submit') {
          this.submitFormDialogErrorData.message = errorMessage;
        }
        if (action === 'complete') {
          this.completeFormDialogErrorData.message = errorMessage;
        }
        return false;
      }
    }

    return true;
  }

  private async loadFormTypeSettings(): Promise<void> {
    try {
      const settingsString = await this.databaseService.getFormTypeSettings(this.formTypeId);
      if (settingsString) {
        const settings = JSON.parse(settingsString);
        if (settings.activityListFilters) {
          this.activityListFilters = settings.activityListFilters;
          this.activityListFilterForm.controls.activity.setValue(this.activityListFilters.activity);
          const activitySorting = this.activityListSortOptions.filter(sorting => sorting.sortColumn === this.activityListFilters.sortColumn && sorting.sortDirection === this.activityListFilters.sortDirection);
          if (activitySorting.length > 0) {
            this.activityListFilterForm.controls.sorting.setValue(activitySorting[0]);
          } else {
            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
          }
        } else {
          this.clearActivityListFilters();
        }
      } else {
        this.clearActivityListFilters();
      }
    } catch (error) {
      console.error('Error retrieving form type settings', error);
    }
  }

  private saveFormTypeSettings(): void {
    try {
      const settings = {
        activityListFilters: this.activityListFilters
      };
      this.databaseService.saveFormTypeSettings(this.formTypeId, JSON.stringify(settings));
    } catch (error) {
      console.error('Error saving form type settings', error);
    }
  }

  private activityListFilterAndSortData(): void {
    let sortColumn = Humboldt2020SupportAdminActivityListColumn.activityDate;
    let sortDirection = 'asc';
    if (this.activityListFilterForm.controls.sorting.value) {
      const sorting: Humboldt2020SupportAdminActivityListSortOptionData = this.activityListFilterForm.controls.sorting.value;
      sortColumn = sorting.sortColumn;
      sortDirection = sorting.sortDirection;
    }

    if (this.currentFormData && this.currentFormData.activities) {
      this.activitiesDataSource = this.currentFormData.activities.filter((activity: Humboldt2020SupportAdminActivityData) => {
        return !(this.activityListFilterForm.controls.activity.value && activity.activity !== this.activityListFilterForm.controls.activity.value);
      }).sort((activity1: Humboldt2020SupportAdminActivityData, activity2: Humboldt2020SupportAdminActivityData) => {
        switch (sortColumn) {
          case Humboldt2020SupportAdminActivityListColumn.activityDate:
            if ((activity1.activityDate || 0) > (activity2.activityDate || 0)) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.activityDate || 0) < (activity2.activityDate || 0)) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Humboldt2020SupportAdminActivityListColumn.activity:
            if ((activity1.activity || '') > (activity2.activity || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.activity || '') < (activity2.activity || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Humboldt2020SupportAdminActivityListColumn.cwsProgramSupport:
            if ((activity1.cwsProgramSupport || '') > (activity2.cwsProgramSupport || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.cwsProgramSupport || '') < (activity2.cwsProgramSupport || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Humboldt2020SupportAdminActivityListColumn.activityMinutesSpent:
            if ((activity1.activityMinutesSpent || 0) > (activity2.activityMinutesSpent || 0)) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.activityMinutesSpent || 0) < (activity2.activityMinutesSpent || 0)) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Humboldt2020SupportAdminActivityListColumn.travelMinutesSpent:
            if ((activity1.travelMinutesSpent || 0) > (activity2.travelMinutesSpent || 0)) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.travelMinutesSpent || 0) < (activity2.travelMinutesSpent || 0)) return sortDirection === 'desc' ? 1 : -1;
            break;
          case Humboldt2020SupportAdminActivityListColumn.activityDescription:
            if ((activity1.activityDescription || '') > (activity2.activityDescription || '')) return sortDirection === 'desc' ? -1 : 1;
            if ((activity1.activityDescription || '') < (activity2.activityDescription || '')) return sortDirection === 'desc' ? 1 : -1;
            break;
        }
        // sensible defaults if the key doesn't exist, or if the initial result is a tie
        if ((activity1.activityDate || 0) < (activity2.activityDate || 0)) return -1;
        if ((activity1.activityDate || 0) > (activity2.activityDate || 0)) return 1;
        return 0;
      });
    }
    this.setActivityListFilterInfoString();
  }

  private setActivityListFilterInfoString(): void {
    const info: Array<string> = [];
    if (this.activityListFilterForm.controls.activity.value) {
      info.push('Activity Filter: ' + this.activityListFilterForm.controls.activity.value);
    }
    if (this.activityListFilterForm.controls.sorting.value) {
      info.push('Sort: ' + this.activityListFilterForm.controls.sorting.value.display);
    }

    this.activityListFilterInfoString = info.join(', ');
  }

  clearActivityListFilters(): void {
    if (this.activityListFilterForm.controls.activity.value !== null) this.activityListFilterForm.controls.activity.setValue(null);
    if (this.activityListFilterForm.controls.sorting.value !== this.activityListSortOptions[0]) this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
  }

  openActivityListFilterDialog(): void {
    const dialogData: Humboldt2020SupportAdminActivityListFilterDialogData = {
      filterForm: this.activityListFilterForm,
      clearFunction: this.clearActivityListFilters.bind(this),
      supportActivityOptions: this.supportActivityOptions,
      adminActivityOptions: this.adminActivityOptions,
      sortOptions: this.activityListSortOptions
    };
    this.dialog.open(Humboldt2020SupportAdminActivityListFilterDialogComponent, {
      data: dialogData,
      width: '800px'
    });
  }

  /**
   * update the form filter when the table headers are clicked to keep them in sync
   */
  onActivityListSortChange(sort: Sort): void {
    const sortingValue = this.activityListSortOptions.filter(sortOption => sortOption.sortColumn === sort.active && sortOption.sortDirection === (sort.direction || 'asc'));
    if (sortingValue.length > 0 && sortingValue[0] !== this.activityListFilterForm.controls.sorting.value) {
      this.activityListFilterForm.controls.sorting.setValue(sortingValue[0]);
    }
  }

  editActivity(activity: Humboldt2020SupportAdminActivityData | null): void {
    const dialogData: Humboldt2020SupportAdminActivityDialogData = {
      activity: activity,
      primaryProgramResponsibility: this.currentFormData ? this.currentFormData.primaryProgramResponsibility : null,
      updateCurrentActivity: this.updateCurrentActivity.bind(this)
    };
    this.dialog.open(Humboldt2020SupportAdminActivityDialogComponent, {
      data: dialogData,
      disableClose: true,
      width: '800px'
    }).afterClosed().subscribe((result: Humboldt2020SupportAdminActivityData | null) => {
      if (this.currentFormData) {
        if (result) {
          if (result.index !== null) {
            this.currentFormData.activities[result.index] = result;
          } else {
            this.currentFormData.activities.push(result);
          }
          this.currentFormData.activities.forEach((activity, index) => activity.index = index);
          this.activityListFilterAndSortData();
        }
        this.currentFormData.currentActivity = null;
        this.saveCurrentFormValues();
      }
    });
  }

  updateCurrentActivity(activity: Humboldt2020SupportAdminActivityData): void {
    if (this.currentFormData) {
      this.currentFormData.currentActivity = activity;
      this.saveCurrentFormValues();
    }
  }

  deleteActivity(activity: Humboldt2020SupportAdminActivityData): void {
    this.dialog.open(MessageDialogComponent, {
      data: {
        title: 'Delete Activity',
        message: 'Deleting this activity is irreversible.' +
          ' Once deleted any information you have entered for the activity will be gone forever.' +
          ' Are you sure you want to do this?',
        okButtonLabel: 'Delete Activity'
      },
      width: '800px'
    }).afterClosed().subscribe((result: boolean | null) => {
      if (this.currentFormData && result && activity.index !== null) {
        this.currentFormData.activities.splice(activity.index, 1);
        this.currentFormData.activities.forEach((activity, index) => activity.index = index);
        this.activityListFilterAndSortData();
        this.saveCurrentFormValues();
      }
    });
  }
}
