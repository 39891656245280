<h1 mat-dialog-title>Activity</h1>
<div mat-dialog-content>
  <form class="filter-form" [formGroup]="form">
    <mat-form-field>
      <mat-label>Date</mat-label>
      <input matInput [min]="minActivityDate" [max]="maxActivityDate" [matDatepicker]="activityDatePicker" formControlName="activityDate">
      <mat-datepicker-toggle matSuffix [for]="activityDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #activityDatePicker></mat-datepicker>
      <mat-error *ngIf="form.controls.activityDate.invalid">{{errorMessage(form.controls.activityDate)}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Violation Work?</mat-label>
      <mat-select formControlName="violationWork">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let option of yesNoOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls.violationWork.invalid">{{errorMessage(form.controls.violationWork)}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Activity</mat-label>
      <mat-select formControlName="activity">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let option of activityOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls.activity.invalid">{{errorMessage(form.controls.activity)}}</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="currentActivity?.activity === activityOptionEnum.Other">
      <mat-label>Activity Other</mat-label>
      <input #activityOther matInput type="text" maxlength="500" formControlName="activityOther">
      <mat-hint align="end">{{activityOther.value.length}} / 500</mat-hint>
      <mat-error *ngIf="form.controls.activityOther.invalid">{{errorMessage(form.controls.activityOther)}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Person Involved (select up to 3)</mat-label>
      <mat-select formControlName="personInvolved" multiple>
        <mat-option *ngFor="let option of personInvolvedOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls.personInvolved.invalid">{{errorMessage(form.controls.personInvolved)}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Method</mat-label>
      <mat-select formControlName="method">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let option of methodOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls.method.invalid">{{errorMessage(form.controls.method)}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Agent's Location</mat-label>
      <mat-select formControlName="agentLocation">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let option of agentLocationOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls.agentLocation.invalid">{{errorMessage(form.controls.agentLocation)}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Activity Minutes Spent</mat-label>
      <input matInput type="number" formControlName="activityMinutesSpent" min="0" max="1440" step="1">
      <mat-error *ngIf="form.controls.activityMinutesSpent.invalid">{{errorMessage(form.controls.activityMinutesSpent)}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Travel Minutes Spent</mat-label>
      <input matInput type="number" formControlName="travelMinutesSpent" min="0" max="1440" step="1">
      <mat-error *ngIf="form.controls.travelMinutesSpent.invalid">{{errorMessage(form.controls.travelMinutesSpent)}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Waiting Minutes Spent</mat-label>
      <input matInput type="number" formControlName="waitingMinutesSpent" min="0" max="1440" step="1">
      <mat-error *ngIf="form.controls.waitingMinutesSpent.invalid">{{errorMessage(form.controls.waitingMinutesSpent)}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Notes</mat-label>
      <textarea #notes matInput maxlength="500" formControlName="notes" [cdkTextareaAutosize]="true"></textarea>
      <mat-hint align="end">{{notes.value.length}} / 500</mat-hint>
      <mat-error *ngIf="form.controls.notes.invalid">{{errorMessage(form.controls.notes)}}</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="updateActivityList()">Update</button>
</div>
