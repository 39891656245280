import * as tslib_1 from "tslib";
import { map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { SharedFormData } from './shared-form-data';
import { ProcessingDialogComponent } from '../processing-dialog/processing-dialog.component';
import { getCurrentMillisecondTimestampRoundedToNearestSecond } from '../utility-functions';
import { FormStatus } from './form-status.enum';
import { YesNoOption } from './yes-no-option.enum';
import { UserRole } from '../auth/user-role.enum';
/* This class is not intended to be used directly, but extended for various forms */
export class FormComponent {
    constructor(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, formDataClass) {
        this.apiService = apiService;
        this.authService = authService;
        this.breakpointObserver = breakpointObserver;
        this.databaseService = databaseService;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.onlineService = onlineService;
        this.route = route;
        this.router = router;
        this.unsubscribe$ = new Subject();
        this.UserRole = UserRole;
        this.isSmallDisplay = false; // If true the display will show a list interface
        this.isLargeDisplay = false; // If true the display will show a table interface
        this.formId = null;
        this.formStatusEnum = FormStatus;
        this.formDataClass = SharedFormData;
        this.currentFormData = null;
        this.readonly = true;
        this.displaySupervisorFields = false;
        this.hasUnsavedChanges = false;
        this.yesNoOptionsEnum = YesNoOption;
        this.yesNoOptions = Object.values(YesNoOption);
        this.cancelOfflineSaveStatus = null;
        this.cancelOfflineSaveTimestamp = null;
        this.deleteFormDialogData = {
            title: 'Delete Form',
            message: 'STOP! Deleting this form is irreversible.' +
                ' Once deleted any information you have entered for the form will be gone forever.' +
                ' This action should only be used if this form was created by mistake.' +
                ' Are you sure you want to do this? ',
            okButtonLabel: 'Delete Form'
        };
        this.discardChangesDialogData = {
            title: 'Discard Changes',
            message: 'Discarding changes will revert any changes you have made to this form since you last saved it.' +
                ' This includes any changes to lists or tables made through dialogs.' +
                ' Are you sure you want to do this?',
            okButtonLabel: 'Discard Changes'
        };
        this.saveFormDialogErrorData = {
            title: 'Save Form Error',
            message: 'You must provide a name to save the form.',
            okButtonLabel: 'Return to Form',
            hideCancel: true
        };
        this.saveOfflineDialogData = {
            title: 'Save Form Offline',
            message: ' Do you wish to save the data offline?' +
                ' This will save the data locally to this device and sync the data to the data server later.',
            okButtonLabel: 'Save Form Offline'
        };
        /* This is exposed as a separate element because the extraFormValidation method may change the error message
          and it needs to be reset to this before the extra form validation is run. */
        this.submitFormDialogErrorMessage = 'There are errors (e.g. required fields that are missing information) in the form' +
            ' that prevent you from submitting the form. Please review each tab and correct the errors displayed in red for each field.';
        this.submitFormDialogErrorData = {
            title: 'Submit Form Error',
            message: this.submitFormDialogErrorMessage,
            okButtonLabel: 'Return to Form',
            hideCancel: true
        };
        this.submitFormDialogData = {
            title: 'Submit Form',
            message: 'This action will send the form to your supervisor for review.' +
                ' This will change the status from "In Progress" to "In Review" and' +
                ' you will not be able to edit the form after you submit it.' +
                ' Are you sure you want to do this?',
            okButtonLabel: 'Submit Form'
        };
        this.sendBackFormDialogErrorData = {
            title: 'Send Back to Agent Error',
            message: 'You must provide a name to save the form.',
            okButtonLabel: 'Return to Form',
            hideCancel: true
        };
        this.sendBackFormDialogData = {
            title: 'Send Back to Agent',
            message: 'This action will send the form back to the Agent for modification.' +
                ' This will revert the status of the form to "In Progress" and' +
                ' you will not be able to edit the form until the Agent submits it again.' +
                ' Are you sure you want to do this?',
            okButtonLabel: 'Send Back to Agent'
        };
        /* This is exposed as a separate element because the extraFormValidation method may change the error message
          and it needs to be reset to this before the extra form validation is run. */
        this.completeFormDialogErrorMessage = 'There are errors (e.g. required fields that are missing information) in the form' +
            ' that prevent you from completing the form. Please review each tab and correct the errors displayed in red for each field.';
        this.completeFormDialogErrorData = {
            title: 'Complete Form Error',
            message: this.completeFormDialogErrorMessage,
            okButtonLabel: 'Return to Form',
            hideCancel: true
        };
        this.completeFormDialogData = {
            title: 'Complete Form',
            message: 'This action will change the status from "In Review" to "Completed" and' +
                ' you will not be able to edit the form after you complete it.' +
                ' Are you sure you want to do this?',
            okButtonLabel: 'Complete Form'
        };
        this.formDataClass = formDataClass;
        if (this.authService.user !== null) {
            this.user = this.authService.user;
            this.checkDataLoad();
        }
        else {
            this.router.navigate(['/login']);
        }
        this.route.paramMap.subscribe(paramMap => {
            this.formId = paramMap.get('id');
            this.checkDataLoad();
        });
        breakpointObserver.observe('(max-width: 960px)').pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.isSmallDisplay = result.matches;
            this.isLargeDisplay = !result.matches;
        });
        this.unsavedChangesFunction = this.warnBeforeUnload.bind(this);
        window.addEventListener('beforeunload', this.unsavedChangesFunction);
        this.authService.logoutCheck = this.logoutCheck.bind(this);
    }
    /* Method is used by child classes */
    ngOnDestroy() {
        window.removeEventListener('beforeunload', this.unsavedChangesFunction);
        this.authService.logoutCheck = null;
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    /* This warning is for when the browser is navigating away from the site or closing the tab */
    warnBeforeUnload(event) {
        if (this.hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
            return event.returnValue;
        }
        return undefined;
    }
    /* Called before logout, to make sure we can logout */
    logoutCheck() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.hasUnsavedChanges) {
                const dialogData = {
                    title: 'Discard Unsaved Changes',
                    message: 'You have unsaved changes on the form. You will lose these changes if you logout. Are you sure?',
                    okButtonLabel: 'Discard Unsaved Changes'
                };
                return this.dialog.open(MessageDialogComponent, {
                    data: dialogData,
                    width: '800px'
                }).afterClosed().pipe(map(result => {
                    if (result) {
                        this.hasUnsavedChanges = false;
                        this.databaseService.deleteCurrentForm();
                        return true;
                    }
                    return false;
                })).toPromise();
            }
            return Promise.resolve(true);
        });
    }
    checkDataLoad() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.formId !== null) {
                if (this.user.role === UserRole.ProjectAdmin || this.user.role === UserRole.NccdAdmin) {
                    yield this.loadFormDataForAdmin();
                    this.afterFormDataLoaded();
                }
                else if (this.currentFormData === null || this.formId === 'new' || parseInt(this.formId, 10) !== this.currentFormData.id) {
                    if (this.formId === 'new') {
                        yield this.createNewFormData();
                    }
                    else {
                        yield this.loadFormDataForAgentOrSupervisor();
                    }
                    this.afterFormDataLoaded();
                }
            }
        });
    }
    /* Creates a new currentFormData object with the appropriate class*/
    createNewFormData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.currentFormData = this.formDataClass.createNewForm(yield this.databaseService.getNewFormId(), this.formTypeId, this.user, getCurrentMillisecondTimestampRoundedToNearestSecond());
                yield this.saveCurrentFormValues();
                this.router.navigate(['/form', this.formTypeId.toString(), this.currentFormData.id.toString()], { replaceUrl: true });
            }
            catch (error) {
                console.error('Error creating new form data object', error);
            }
        });
    }
    /* Loads the currentFormData object with the appropriate class */
    loadFormDataForAgentOrSupervisor() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const processingData = {
                message: new BehaviorSubject('')
            };
            const dialogRef = this.dialog.open(ProcessingDialogComponent, {
                data: processingData,
                disableClose: true,
                width: '800px'
            });
            try {
                if (this.formId) {
                    const formId = parseInt(this.formId, 10);
                    processingData.message.next('Loading form data from local device.');
                    const currentFormData = yield this.databaseService.getCurrentForm(this.formDataClass.createFromDatabaseString);
                    if (currentFormData !== null && currentFormData.id === formId) {
                        this.currentFormData = currentFormData;
                        this.hasUnsavedChanges = true;
                    }
                    else {
                        this.currentFormData = yield this.databaseService.getForm(formId, this.formDataClass.createFromDatabaseString);
                        if (this.onlineService.online.value && formId > 0 && (this.currentFormData && this.currentFormData.offlineSaveTimestamp === null)) {
                            // Device is online, not local only id, and not offline saved
                            processingData.message.next('Loading form data from data server.');
                            let getSurveyResponse = null;
                            try {
                                const getSurveyResponse = yield this.apiService.apiGetSurvey(formId);
                                const serverVersion = this.formDataClass.createFromServerObject(getSurveyResponse, true);
                                if (!this.currentFormData.fullRecord || serverVersion.updated.timestamp > this.currentFormData.updated.timestamp) {
                                    this.currentFormData = serverVersion;
                                    yield this.databaseService.saveForm(this.currentFormData);
                                }
                            }
                            catch (error) {
                                if (this.currentFormData && this.currentFormData.fullRecord) {
                                    processingData.message.error(error + ' Using the locally cached data.');
                                    return;
                                }
                                else {
                                    processingData.message.error(error);
                                    dialogRef.afterClosed().subscribe(() => this.router.navigate(['/form-list']));
                                    return;
                                }
                            }
                        }
                    }
                }
                if (this.currentFormData === null) {
                    processingData.message.error('Unable to load form.');
                    dialogRef.afterClosed().subscribe(() => this.router.navigate(['/form-list']));
                    return;
                }
                if (!this.currentFormData.fullRecord) {
                    console.error('Only summary data loaded on edit page', this.currentFormData);
                    processingData.message.error('Only summary form data is loaded. Unable to load the rest of the form from the data server.');
                    dialogRef.afterClosed().subscribe(() => this.router.navigate(['/form-list']));
                    return;
                }
                processingData.message.complete();
            }
            catch (error) {
                console.error('Error loading form', error);
                processingData.message.error(error);
            }
        });
    }
    /* Loads the currentFormData object with the appropriate class */
    loadFormDataForAdmin() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const processingData = {
                message: new BehaviorSubject('')
            };
            const dialogRef = this.dialog.open(ProcessingDialogComponent, {
                data: processingData,
                disableClose: true,
                width: '800px'
            });
            try {
                if (this.formId) {
                    const formId = parseInt(this.formId, 10);
                    processingData.message.next('Loading form data from data server.');
                    const getSurveyResponse = yield this.apiService.apiGetSurvey(formId);
                    this.currentFormData = this.formDataClass.createFromServerObject(getSurveyResponse, true);
                }
                if (this.currentFormData === null) {
                    processingData.message.error('Unable to load form.');
                    dialogRef.afterClosed().subscribe(() => this.router.navigate(['/form-list']));
                    return;
                }
                processingData.message.complete();
            }
            catch (error) {
                console.error('Error loading form', error);
                processingData.message.error(error);
            }
        });
    }
    /* This function should be overridden in child classes to hydrate form with currentFormData values */
    afterFormDataLoaded() {
        if (this.currentFormData) {
            this.readonly = (this.user.role === UserRole.Agent && this.currentFormData.status !== FormStatus.InProgress) ||
                (this.user.role === UserRole.Supervisor && this.currentFormData.status !== FormStatus.InReview) ||
                this.user.role === UserRole.ProjectAdmin || this.user.role === UserRole.NccdAdmin;
            this.displaySupervisorFields = (this.user.role !== UserRole.Agent || this.currentFormData.status !== FormStatus.InProgress);
        }
    }
    saveCurrentFormValues() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.readonly && this.currentFormData) {
                    this.hasUnsavedChanges = true;
                    yield this.databaseService.saveCurrentForm(this.currentFormData);
                }
            }
            catch (error) {
                console.error('Error saving current form values', error);
            }
        });
    }
    deleteFormDialog() {
        if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InProgress) {
            this.dialog.open(MessageDialogComponent, {
                data: this.deleteFormDialogData,
                width: '800px'
            }).afterClosed().subscribe((result) => {
                if (result) {
                    this.deleteForm();
                }
            });
        }
    }
    deleteForm() {
        if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InProgress) {
            this.currentFormData.updated = {
                userId: this.user.id,
                userName: this.user.name,
                timestamp: getCurrentMillisecondTimestampRoundedToNearestSecond()
            };
            this.cancelOfflineSaveStatus = this.currentFormData.status;
            this.cancelOfflineSaveTimestamp = this.currentFormData.statusChangeTimestamp;
            this.currentFormData.status = FormStatus.Deleted;
            this.currentFormData.statusChangeTimestamp = this.currentFormData.updated.timestamp;
            this.saveToServer();
        }
    }
    discardChangesDialog() {
        if (this.readonly || !this.hasUnsavedChanges) {
            this.router.navigate(['/form-list']);
        }
        else {
            this.dialog.open(MessageDialogComponent, {
                data: this.discardChangesDialogData,
                width: '800px'
            }).afterClosed().subscribe((result) => {
                if (result) {
                    this.discardChanges();
                }
            });
        }
    }
    discardChanges() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.hasUnsavedChanges = false;
                yield this.databaseService.deleteCurrentForm();
                this.router.navigate(['/form-list']);
            }
            catch (error) {
                console.error('Error discarding changes', error);
            }
        });
    }
    saveForm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.readonly) {
                if (!this.currentFormData || !this.currentFormData.name) {
                    this.dialog.open(MessageDialogComponent, {
                        data: this.saveFormDialogErrorData,
                        width: '800px'
                    });
                }
                else {
                    this.currentFormData.updated = {
                        userId: this.user.id,
                        userName: this.user.name,
                        timestamp: getCurrentMillisecondTimestampRoundedToNearestSecond()
                    };
                    this.saveToServer();
                }
            }
        });
    }
    saveToServer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.onlineService.online.value) {
                this.saveOfflineDialog('The device is not currently connected to the internet.');
                return;
            }
            if (!this.readonly && this.currentFormData) {
                const processingData = {
                    message: new BehaviorSubject('')
                };
                this.dialog.open(ProcessingDialogComponent, {
                    data: processingData,
                    disableClose: true,
                    width: '800px'
                });
                try {
                    processingData.message.next('Saving form to the data server.');
                    let saveSurveyResponse = null;
                    try {
                        saveSurveyResponse = yield this.apiService.saveSurvey(this.currentFormData.toServerObject());
                    }
                    catch (error) {
                        processingData.message.complete();
                        this.saveOfflineDialog(error);
                        return;
                    }
                    if (saveSurveyResponse.Status !== "Success") {
                        processingData.message.error('Data server returned an error while saving the form. Error: ' + saveSurveyResponse.Error);
                        return;
                    }
                    processingData.message.next('Saving data locally to device.');
                    if (this.currentFormData.offlineSaveTimestamp !== null) {
                        this.currentFormData.offlineSaveTimestamp = null;
                    }
                    const responseSurveyId = parseInt(saveSurveyResponse.SurveyId, 10);
                    if (responseSurveyId !== this.currentFormData.id) {
                        const oldId = this.currentFormData.id;
                        this.currentFormData.id = responseSurveyId;
                        if (this.currentFormData.status !== FormStatus.Deleted) {
                            yield this.databaseService.saveForm(this.currentFormData);
                        }
                        yield this.databaseService.deleteForm(oldId);
                        // Replace url with new id for back button purposes
                        this.router.navigate(['/form', this.currentFormData.formType.id.toString(), this.currentFormData.id.toString()], { replaceUrl: true });
                    }
                    else {
                        if (this.currentFormData.status !== FormStatus.Deleted) {
                            yield this.databaseService.saveForm(this.currentFormData);
                        }
                        else {
                            yield this.databaseService.deleteForm(this.currentFormData.id);
                        }
                    }
                    this.hasUnsavedChanges = false;
                    yield this.databaseService.deleteCurrentForm();
                    processingData.message.complete();
                    this.router.navigate(['/form-list']);
                }
                catch (error) {
                    console.error(error);
                    processingData.message.error(error);
                }
            }
        });
    }
    saveOfflineDialog(error) {
        const dialogData = {
            title: this.saveOfflineDialogData.title,
            message: error + this.saveOfflineDialogData.message,
            okButtonLabel: this.saveOfflineDialogData.okButtonLabel
        };
        this.dialog.open(MessageDialogComponent, {
            data: dialogData,
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.saveOffline();
            }
            else if (this.currentFormData !== null && this.cancelOfflineSaveStatus !== null && this.cancelOfflineSaveTimestamp !== null) {
                this.currentFormData.status = this.cancelOfflineSaveStatus;
                this.currentFormData.statusChangeTimestamp = this.cancelOfflineSaveTimestamp;
                this.cancelOfflineSaveStatus = null;
                this.cancelOfflineSaveTimestamp = null;
            }
        });
    }
    saveOffline() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.readonly && this.currentFormData) {
                const processingData = {
                    message: new BehaviorSubject('')
                };
                this.dialog.open(ProcessingDialogComponent, {
                    data: processingData,
                    disableClose: true,
                    width: '800px'
                });
                try {
                    processingData.message.next('Saving data locally to device.');
                    if (this.currentFormData.updated) {
                        this.currentFormData.offlineSaveTimestamp = this.currentFormData.updated.timestamp;
                    }
                    else {
                        this.currentFormData.offlineSaveTimestamp = getCurrentMillisecondTimestampRoundedToNearestSecond();
                    }
                    yield this.databaseService.saveForm(this.currentFormData);
                    this.hasUnsavedChanges = false;
                    yield this.databaseService.deleteCurrentForm();
                    processingData.message.complete();
                    this.router.navigate(['/form-list']);
                }
                catch (error) {
                    console.error(error);
                    processingData.message.error(error);
                    return;
                }
            }
        });
    }
    /**
     * This function does validation other than form.valid before submitting or completing
     * If validation does not pass it should return false and possibly set
     * submitFormDialogErrorData.message or completeFormDialogErrorData.message
     */
    extraFormValidation(action) {
        return true;
    }
    submitFormDialog() {
        if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InProgress) {
            this.form.markAllAsTouched();
            this.submitFormDialogErrorData.message = this.submitFormDialogErrorMessage;
            delete this.submitFormDialogErrorData.messageList;
            if (!this.form.valid || !this.extraFormValidation('submit')) {
                this.dialog.open(MessageDialogComponent, {
                    data: this.submitFormDialogErrorData,
                    width: '800px'
                });
            }
            else {
                this.dialog.open(MessageDialogComponent, {
                    data: this.submitFormDialogData,
                    width: '800px'
                }).afterClosed().subscribe((result) => {
                    if (result) {
                        this.submitForm();
                    }
                });
            }
        }
    }
    submitForm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InProgress) {
                this.currentFormData.updated = {
                    userId: this.user.id,
                    userName: this.user.name,
                    timestamp: getCurrentMillisecondTimestampRoundedToNearestSecond()
                };
                this.cancelOfflineSaveStatus = this.currentFormData.status;
                this.cancelOfflineSaveTimestamp = this.currentFormData.statusChangeTimestamp;
                this.currentFormData.status = FormStatus.InReview;
                this.currentFormData.statusChangeTimestamp = this.currentFormData.updated.timestamp;
                this.saveToServer();
            }
        });
    }
    sendBackFormDialog() {
        if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
            if (!this.currentFormData || !this.currentFormData.name) {
                this.dialog.open(MessageDialogComponent, {
                    data: this.sendBackFormDialogErrorData,
                    width: '800px'
                });
            }
            else {
                this.dialog.open(MessageDialogComponent, {
                    data: this.sendBackFormDialogData,
                    width: '800px'
                }).afterClosed().subscribe((result) => {
                    if (result) {
                        this.sendBackForm();
                    }
                });
            }
        }
    }
    sendBackForm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
                this.currentFormData.updated = {
                    userId: this.user.id,
                    userName: this.user.name,
                    timestamp: getCurrentMillisecondTimestampRoundedToNearestSecond()
                };
                this.cancelOfflineSaveStatus = this.currentFormData.status;
                this.cancelOfflineSaveTimestamp = this.currentFormData.statusChangeTimestamp;
                this.currentFormData.status = FormStatus.InProgress;
                this.currentFormData.statusChangeTimestamp = this.currentFormData.updated.timestamp;
                this.saveToServer();
            }
        });
    }
    completeFormDialog() {
        if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
            this.form.markAllAsTouched();
            this.completeFormDialogErrorData.message = this.completeFormDialogErrorMessage;
            delete this.completeFormDialogErrorData.messageList;
            if (!this.form.valid || !this.extraFormValidation('complete')) {
                this.dialog.open(MessageDialogComponent, {
                    data: this.completeFormDialogErrorData,
                    width: '800px'
                });
            }
            else {
                this.dialog.open(MessageDialogComponent, {
                    data: this.completeFormDialogData,
                    width: '800px'
                }).afterClosed().subscribe((result) => {
                    if (result) {
                        this.completeForm();
                    }
                });
            }
        }
    }
    completeForm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.readonly && this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
                this.currentFormData.updated = {
                    userId: this.user.id,
                    userName: this.user.name,
                    timestamp: getCurrentMillisecondTimestampRoundedToNearestSecond()
                };
                this.cancelOfflineSaveStatus = this.currentFormData.status;
                this.cancelOfflineSaveTimestamp = this.currentFormData.statusChangeTimestamp;
                this.currentFormData.status = FormStatus.Completed;
                this.currentFormData.statusChangeTimestamp = this.currentFormData.updated.timestamp;
                this.saveToServer();
            }
        });
    }
}
