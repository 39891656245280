export enum Humboldt2020PlacementActivityPersonInvolvedOption {
  Youth = 'Youth',
  RelativeNrefm = 'Relative/NREFM',
  IntakeCoordinator = 'Intake coordinator',
  SubstituteCareProvider = 'Substitute care provider',
  SocialWorker = 'Social worker',
  Doj = 'DOJ',
  Tribe = 'Tribe',
  Supervisor = 'Supervisor',
  None = 'None',
  Other = 'Other'
}
