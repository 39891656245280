<form [formGroup]="form">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab label="Case Information">
      <padded-content>
        <mat-form-field *ngIf="user?.role !== UserRole.Agent">
          <mat-label>Created By</mat-label>
          <input matInput type="text" formControlName="agentName" readonly>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Created</mat-label>
          <input matInput formControlName="createdDate" readonly>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Status</mat-label>
          <input matInput formControlName="status" readonly>
        </mat-form-field>

        <mat-form-field *ngIf="user?.role !== UserRole.Agent">
          <mat-label>Status Changed</mat-label>
          <input matInput formControlName="statusChangedDate" readonly>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Last Updated</mat-label>
          <input matInput formControlName="updatedDate" readonly>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Study Month</mat-label>
          <mat-select formControlName="studyMonth" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of studyMonthOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.studyMonth.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Sample Case Name</mat-label>
          <input #caseName matInput type="text" maxlength="200" formControlName="caseName" required autocomplete="off">
          <mat-hint align="end">{{caseName.value.length}} / 200</mat-hint>
          <mat-error>{{form.controls.caseName.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>DJS Client ID</mat-label>
          <input #djsClientId matInput type="text" maxlength="7" formControlName="djsClientId" inputmode="numeric" required digitOnly autocomplete="off">
          <mat-hint align="end">{{djsClientId.value.length}} / 7</mat-hint>
          <mat-error>{{form.controls.djsClientId.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Region</mat-label>
          <mat-select formControlName="region" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of regionOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.region.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Office</mat-label>
          <mat-select formControlName="office" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of officeOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.office.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>New Case Type</mat-label>
          <mat-select formControlName="newCaseType" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of newCaseTypeOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.newCaseType.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="currentFormData?.newCaseType === newCaseTypeOptionEnum.NotApplicableOngoingCase">
          <mat-label>Ongoing Case Status</mat-label>
          <mat-select formControlName="ongoingCaseStatus" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of ongoingCaseStatusOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.ongoingCaseStatus.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Supervision Level</mat-label>
          <mat-select formControlName="supervisionLevel" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of supervisionLevelOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.supervisionLevel.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Specialized Case Type</mat-label>
          <mat-select formControlName="specializedCaseType" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of specializedCaseTypeOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.specializedCaseType.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Youth Gender</mat-label>
          <mat-select formControlName="youthGender" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of youthGenderOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.youthGender.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Complete Prior to Submitting Form: Did the case end or change case types during the study month?</mat-label>
          <mat-select formControlName="caseEndOrChangeCaseTypesDuringStudyMonth" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of yesNoOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.caseEndOrChangeCaseTypesDuringStudyMonth.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Optional Comments</mat-label>
          <textarea #comments matInput maxlength="500" formControlName="comments" [cdkTextareaAutosize]="true" autocomplete="off"></textarea>
          <mat-hint align="end">{{comments.value.length}} / 500</mat-hint>
          <mat-error>{{form.controls.comments.errors | errorDisplay}}</mat-error>
        </mat-form-field>
      </padded-content>
    </mat-tab>
    <mat-tab label="Activities">
      <div class="filter-form" formGroupName="activityListFilter" *ngIf="isLargeDisplay">
        <mat-form-field>
          <mat-label>Activity Filter</mat-label>
          <mat-select formControlName="activity">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let activity of activityOptions" [value]="activity">{{activity}}</mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-icon-button aria-label="Clear Filters" (click)="clearActivityListFilters()"><mat-icon>clear</mat-icon></button>
      </div>

      <mat-toolbar class="small-display-info-string-section mat-elevation-z3" *ngIf="isSmallDisplay" (click)="openActivityListFilterDialog()">
        <button mat-icon-button aria-label="Filters">
          <mat-icon>filter_list</mat-icon>
        </button>
        <div class="filter-info-string mat-small">{{ activityListFilterInfoString }}</div>
      </mat-toolbar>

      <table class="activity-table" mat-table #activityTable matSort [dataSource]="activitiesDataSource" (matSortChange)="onActivityListSortChange($event)" *ngIf="isLargeDisplay">
        <ng-container [matColumnDef]="activityListColumnEnum.action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let activity" class="action-column-cell">
            <button mat-icon-button color="primary" aria-label="Edit Activity" (click)="editActivity(activity)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="primary" aria-label="Delete Activity" (click)="deleteActivity(activity)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.activityDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let activity">{{ activity.activityDate | date:'M/d/yyyy' }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.activity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity</th>
          <td mat-cell *matCellDef="let activity">{{ activity.activity }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.person">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Person</th>
          <td mat-cell *matCellDef="let activity">{{ activity.personDisplay }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.method">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Method</th>
          <td mat-cell *matCellDef="let activity">{{ activity.method }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.place">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Place</th>
          <td mat-cell *matCellDef="let activity">{{ activity.place }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.activityMinutes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity Minutes</th>
          <td mat-cell *matCellDef="let activity">{{ activity.activityMinutes }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.travelMinutes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Travel Minutes</th>
          <td mat-cell *matCellDef="let activity">{{ activity.travelMinutes }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.waitingMinutes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Waiting Minutes</th>
          <td mat-cell *matCellDef="let activity">{{ activity.waitingMinutes }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.activityDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td mat-cell *matCellDef="let activity" class="newline-display">{{ activity.activityDescription }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="activityListDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let activity; columns: activityListDisplayedColumns;"></tr>
      </table>

      <mat-nav-list *ngIf="isSmallDisplay">
        <a mat-list-item *ngFor="let activity of activitiesDataSource">
          <h2 matLine>{{ activity.activityDate | date:'M/d/yyyy' }} {{ activity.activity }}</h2>
          <div matLine>{{ activity.personDisplay }}</div>
          <div matLine>{{ activity.method }} at {{ activity.place }}</div>
          <div matLine>{{ activity.activityMinutes }} min / travel {{ activity.travelMinutes }} min / waiting {{ activity.waitingMinutes }} min</div>
          <div matLine *ngIf="activity.activityDescription">{{ activity.activityDescription }}</div>
          <div class="form-mat-list-actions">
            <button mat-icon-button color="primary" aria-label="Edit Activity" (click)="editActivity(activity)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="primary" aria-label="Delete Activity" (click)="deleteActivity(activity)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </a>
      </mat-nav-list>

      <padded-content *ngIf="currentFormData?.activities?.length === 0 && activitiesDataSource.length === 0">No activities yet. Create one with the Add (+) button in the lower right.</padded-content>
      <padded-content *ngIf="currentFormData?.activities?.length !== activitiesDataSource.length" class="filter-warning">
        Some activities have been hidden by the Activity Filter.
        <button mat-flat-button color="primary" (click)="clearActivityListFilters()">Clear Filters</button>
        to see all activities.
      </padded-content>
    </mat-tab>
    <mat-tab label="Supervisor Review" *ngIf="displaySupervisorFields">
      <padded-content>
        <mat-form-field>
          <mat-label>Supervisor Final Review: Service standard met?</mat-label>
          <mat-select formControlName="serviceStandardMet" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of yesNoOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.serviceStandardMet.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="currentFormData?.serviceStandardMet === yesNoOptionsEnum.No">
          <mat-label>Reason standards not met</mat-label>
          <mat-select formControlName="reasonServiceStandardNotMet" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of reasonServiceStandardNotMetOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error>{{form.controls.reasonServiceStandardNotMet.errors | errorDisplay}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Supervisor Optional Comments</mat-label>
          <textarea #supervisorComments matInput maxlength="500" formControlName="supervisorComments" [cdkTextareaAutosize]="true" [required]="currentFormData?.reasonServiceStandardNotMet === reasonServiceStandardNotMetOptionsEnum.Other" autocomplete="off"></textarea>
          <mat-hint align="end">{{supervisorComments.value.length}} / 500</mat-hint>
          <mat-error>{{form.controls.supervisorComments.errors | errorDisplay}}</mat-error>
        </mat-form-field>
      </padded-content>
    </mat-tab>
  </mat-tab-group>
</form>

<div class="fab-button-padding"></div>
<div class="lower-right-action">
  <button mat-fab (click)="deleteFormDialog()" *ngIf="!readonly && user?.role === UserRole.Agent" [matTooltip]="deleteFormDialogData.title" matTooltipPosition="above"><mat-icon>delete</mat-icon></button>
  <button mat-mini-fab (click)="deleteFormDialog()" *ngIf="!readonly && user?.role === UserRole.Agent" [matTooltip]="deleteFormDialogData.title" matTooltipPosition="above"><mat-icon>delete</mat-icon></button>

  <button mat-fab (click)="discardChangesDialog()" [matTooltip]="discardChangesDialogData.title" matTooltipPosition="above"><mat-icon>undo</mat-icon></button>
  <button mat-mini-fab (click)="discardChangesDialog()" [matTooltip]="discardChangesDialogData.title" matTooltipPosition="above"><mat-icon>undo</mat-icon></button>

  <button mat-fab (click)="saveForm()" *ngIf="!readonly" matTooltip="Save Form" matTooltipPosition="above"><mat-icon>save</mat-icon></button>
  <button mat-mini-fab (click)="saveForm()" *ngIf="!readonly" matTooltip="Save Form" matTooltipPosition="above"><mat-icon>save</mat-icon></button>

  <button mat-fab (click)="submitFormDialog()" *ngIf="!readonly && user?.role === UserRole.Agent" [matTooltip]="submitFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_turned_in</mat-icon></button>
  <button mat-mini-fab (click)="submitFormDialog()" *ngIf="!readonly && user?.role === UserRole.Agent" [matTooltip]="submitFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_turned_in</mat-icon></button>

  <button mat-fab (click)="sendBackFormDialog()" *ngIf="!readonly && user?.role === UserRole.Supervisor" [matTooltip]="sendBackFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_return</mat-icon></button>
  <button mat-mini-fab (click)="sendBackFormDialog()" *ngIf="!readonly && user?.role === UserRole.Supervisor" [matTooltip]="sendBackFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_return</mat-icon></button>

  <button mat-fab (click)="completeFormDialog()" *ngIf="!readonly && user?.role === UserRole.Supervisor" [matTooltip]="completeFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_turned_in</mat-icon></button>
  <button mat-mini-fab (click)="completeFormDialog()" *ngIf="!readonly && user?.role === UserRole.Supervisor" [matTooltip]="completeFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_turned_in</mat-icon></button>

  <button mat-fab (click)="editActivity(null)" *ngIf="!readonly" matTooltip="Add Activity" matTooltipPosition="above"><mat-icon>add</mat-icon></button>
  <button mat-mini-fab (click)="editActivity(null)" *ngIf="!readonly" matTooltip="Add Activity" matTooltipPosition="above"><mat-icon>add</mat-icon></button>
</div>
