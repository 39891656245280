import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormDuplicateCreateWarningDialogData } from './form-duplicate-create-warning-dialog-data';

@Component({
  selector: 'app-form-duplicate-create-warning-dialog',
  templateUrl: './form-duplicate-create-warning-dialog.component.html',
  styleUrls: ['./form-duplicate-create-warning-dialog.component.css']
})
export class FormDuplicateCreateWarningDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<FormDuplicateCreateWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDuplicateCreateWarningDialogData
  ) { }

  /* returns the id of the form to open as a string or new if there should be a new form */
  close(result: string | null): void {
    this.dialogRef.close(result);
  }
}
