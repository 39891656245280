import { FormTypeData, getFormTypeById, getFormTypesByProjectId, sortFormTypesByName } from '../project/form-type-data';
import { isArray } from 'util';
import { getProjectByDatabaseString, getProjectById, ProjectData } from '../project/project-data';
import { ApiUserInfoResponse } from './api-user-info-response';
import { ApiTokenResponse } from './api-token-response';
import { getCurrentMillisecondTimestampRoundedToNearestSecond } from '../utility-functions';
import { UserRole } from './user-role.enum';

export class UserData {
  id: number;
  name: string;
  username: string;
  role: UserRole;
  isSupervisor: boolean;
  usersSupervised: {
    id: string,
    name: string
  }[];
  project: ProjectData | null;
  createFormTypes: FormTypeData[];
  viewFormTypes: FormTypeData[];
  encryptionKey: string;
  token: string;
  expireTimestamp: number; // unix timestamp in milliseconds;
  refreshToken: string;
  passwordResetRequired: boolean;

  constructor(data: any) {
    if (!data.id) {
      throw new Error('Missing id from user constructor data.');
    }
    if (typeof data.id === 'string') {
      this.id = parseInt(data.id, 10);
    } else {
      this.id = data.id;
    }

    if (!data.name) {
      throw new Error('Missing name from user constructor data.');
    }
    this.name = data.name;

    if (!data.username) {
      throw new Error('Missing username from user constructor data.');
    }
    this.username = data.username;

    this.role = data.role || UserRole.Agent;

    if (isArray(data.usersSupervised)) {
      this.usersSupervised = data.usersSupervised;
    } else {
      this.usersSupervised = [];
    }

    if (data.projectId === null) {
      if (this.role !== UserRole.NccdAdmin) {
        throw new Error('Unable to find project id in user constructor.');
      }
      this.project = null;
    } else {
      const project = getProjectById(data.projectId);
      if (!project) {
        throw new Error('Unable to find project from project id (' + data.projectId + ') in user constructor.');
      }
      this.project = project;
    }

    if (!data.createFormTypeIds || !isArray(data.createFormTypeIds)) {
      throw new Error('Missing create form type ids from user constructor data.');
    }
    this.createFormTypes = data.createFormTypeIds.map(formId => getFormTypeById(formId)).filter(form => form !== null).sort(sortFormTypesByName);

    if (data.viewFormTypesIds && isArray(data.viewFormTypesIds)) {
      this.viewFormTypes = data.viewFormTypesIds.map(formId => getFormTypeById(formId)).filter(form => form !== null).sort(sortFormTypesByName);
    } else {
      switch (this.role) {
        case UserRole.Agent:
          this.viewFormTypes = this.createFormTypes;
          break;
        case UserRole.Supervisor:
        case UserRole.ProjectAdmin:
          if (this.project !== null) {
            this.viewFormTypes = getFormTypesByProjectId(this.project.id);
          }
          break;
        case UserRole.NccdAdmin:
          this.viewFormTypes = []; // NCCD Admins will view all types, this property is not used
          break;
      }
    }


    if (!data.encryptionKey) {
      throw new Error('Missing encryption key from user constructor data.');
    }
    this.encryptionKey = data.encryptionKey;

    if (!data.token) {
      throw new Error('Missing token from user constructor data.');
    }
    this.token = data.token;

    if (!data.expireTimestamp) {
      throw new Error('Missing expire timestamp from user constructor data.');
    }
    this.expireTimestamp = data.expireTimestamp;

    if (!data.refreshToken) {
      throw new Error('Missing refresh token from user constructor data.');
    }
    this.refreshToken = data.refreshToken;

    this.passwordResetRequired = data.passwordResetRequired || false;
  }

  static createFromApi(userInfo: ApiUserInfoResponse, token: ApiTokenResponse) {
    if (isNaN(parseInt(userInfo.UserId, 10))) {
      throw new Error('User Data UserId (' + userInfo.UserId + ') provided by the data server is not a number.');
    }

    let role = userInfo.Role;
    if (Object.values(UserRole).indexOf(role) === -1) {
      throw new Error('User Data Role (' + userInfo.Role + ') provided by the data server is not recognized.');
    }

    let project: ProjectData | null = null;
    if (role !== UserRole.NccdAdmin) {
      const databaseProjectId = parseInt(userInfo.ProjectId, 10);
      if (!isNaN(databaseProjectId)) {
        project = getProjectById(databaseProjectId);
      }
      if (project === null) {
        project = getProjectByDatabaseString(userInfo.ProjectId);
      }
      if (project === null) {
        throw new Error('User Data ProjectId (' + userInfo.ProjectId + ') provided by the data server is not a recognized project identifer.');
      }
    }

    let createFormIds: number[] = [];
    if (userInfo.Items.CreateFormId) {
      if (typeof userInfo.Items.CreateFormId === 'string') {
        const createFormId = parseInt(userInfo.Items.CreateFormId, 10);
        if (isNaN(createFormId)) {
          throw new Error('User data CreateFormId (' + userInfo.Items.CreateFormId + ') provided by the data server is not a number.');
        }
        createFormIds.push(createFormId);
      } else if (isArray(userInfo.Items.CreateFormId)) {
        createFormIds = userInfo.Items.CreateFormId.map(item => parseInt(item, 10));
        if (createFormIds.filter(item => isNaN(item)).length > 0) {
          throw new Error('User data CreateFormId (' + userInfo.Items.CreateFormId.toString() + ') provided by the data server is not an array of numbers.');
        }
      }
      createFormIds.forEach(id => {
        if (getFormTypeById(id) === null) {
          throw new Error('User data CreateFormId (' + userInfo.Items.CreateFormId + ') provided by the data server has an unrecognized form type id (' + id.toString() + ').');
        }
      })
    }

    let viewFormIds: number[] | null = null;
    if (userInfo.Items.ViewFormId) {
      if (typeof userInfo.Items.ViewFormId === 'string') {
        const viewFormId = parseInt(userInfo.Items.ViewFormId, 10);
        if (isNaN(viewFormId)) {
          throw new Error('User data ViewFormId (' + userInfo.Items.ViewFormId + ') provided by the data server is not a number.');
        }
        viewFormIds = [viewFormId];
      } else if (isArray(userInfo.Items.ViewFormId)) {
        viewFormIds = userInfo.Items.ViewFormId.map(item => parseInt(item, 10));
        if (viewFormIds.filter(item => isNaN(item)).length > 0) {
          throw new Error('User data ViewFormId (' + userInfo.Items.ViewFormId.toString() + ') provided by the data server is not an array of numbers.');
        }
      }
      if (viewFormIds !== null) {
        viewFormIds.forEach(id => {
          if (getFormTypeById(id) === null) {
            throw new Error('User data ViewFormId (' + userInfo.Items.ViewFormId + ') provided by the data server has an unrecognized form type id (' + id.toString() + ').');
          }
        })
      }
    }

    if (typeof userInfo.Items.EncryptionKey !== 'string' || userInfo.Items.EncryptionKey.length === 0) {
      throw new Error('EncryptionKey missing from user data provided by the data server.');
    }

    const data: any = {
      id: userInfo.UserId,
      name: userInfo.Name,
      username: userInfo.Username,
      role: role,
      usersSupervised: [],
      projectId: project === null ? null : project.id,
      createFormTypeIds: createFormIds,
      viewFormTypeIds: viewFormIds,
      encryptionKey: userInfo.Items.EncryptionKey,
      token: token.access_token,
      expireTimestamp: getCurrentMillisecondTimestampRoundedToNearestSecond() + (token.expires_in * 1000),
      refreshToken: token.refresh_token,
      passwordResetRequired: userInfo.PasswordResetRequired || false
    };
    if (role === UserRole.Supervisor && userInfo.UsersSupervised && isArray(userInfo.UsersSupervised)) {
      data.usersSupervised = userInfo.UsersSupervised.map(user => {return {id: parseInt(user.UserId), name: user.Name};});
    }
    return new UserData(data);
  }

  /* creates the string to store in the database */
  toDatabaseString(): string {
    return JSON.stringify({
      id: this.id,
      name: this.name,
      username: this.username,
      role: this.role,
      usersSupervised: this.usersSupervised,
      projectId: this.project === null ? null : this.project.id,
      createFormTypeIds: this.createFormTypes.map(formType => formType.id),
      viewFormTypeIds: this.viewFormTypes.map(formType => formType.id),
      encryptionKey: this.encryptionKey,
      token: this.token,
      expireTimestamp: this.expireTimestamp,
      refreshToken: this.refreshToken,
      passwordResetRequired: this.passwordResetRequired
    });
  }

  /* Creates a UserData class given the string stored in the database */
  static createFromDatabaseString(data: string): UserData {
    const parsedData = JSON.parse(data);
    // This handles data stored from previous versions of the app where there was an isSupervisor key instead of role
    if (!parsedData.role) {
      parsedData.role = parsedData.isSupervisor ? UserRole.Supervisor : UserRole.Agent;
    }
    return new UserData(JSON.parse(data));
  }
}
