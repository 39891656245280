import { SharedFormData } from '../../../form/shared-form-data';
import { UserData } from '../../../auth/user-data';
import { YesNoOption } from '../../../form/yes-no-option.enum';
import { ApiSaveSurveyData } from '../../../api/api-save-survey-data';
import { ApiGetSurveyResponse } from '../../../api/api-get-survey-response';
import { isArray } from "util";
import { Humboldt2020OngoingEfcAdoptCaseAssignmentLengthOption } from './humboldt2020-ongoing-efc-adopt-case-assignment-length-option.enum';
import { Humboldt2020OngoingEfcAdoptStudyCaseTypeOption } from './humboldt2020-ongoing-efc-adopt-study-case-type-option.enum';
import { Humboldt2020OngoingEfcAdoptNumberOfYouthOption } from './humboldt2020-ongoing-efc-adopt-number-of-youth-option.enum';
import { Humboldt2020OngoingEfcAdoptRecentRiskLevelOption } from './humboldt2020-ongoing-efc-adopt-recent-risk-level-option.enum';
import { Humboldt2020OngoingEfcAdoptTribalAffiliationOption } from './humboldt2020-ongoing-efc-adopt-tribal-affiliation-option.enum';
import { Humboldt2020OngoingEfcAdoptActivityData, getActivityPersonInvolvedArray } from './humboldt2020-ongoing-efc-adopt-activity-data';

export class Humboldt2020OngoingEfcAdoptFormData extends SharedFormData {
  caseId: string;
  caseStartDate: number | null; // unix timestamp in milliseconds
  caseAssignmentLength: Humboldt2020OngoingEfcAdoptCaseAssignmentLengthOption | null;
  studyCaseType: Humboldt2020OngoingEfcAdoptStudyCaseTypeOption | null;
  youthPlacedOutOfCounty: YesNoOption | null;
  numberOfYouthOnCasePlan: Humboldt2020OngoingEfcAdoptNumberOfYouthOption | null;
  ageOfSampledYouth: number | null;
  mostRecentRiskLevelAtStartOfMonth: Humboldt2020OngoingEfcAdoptRecentRiskLevelOption | null;
  tribalAffiliation: Humboldt2020OngoingEfcAdoptTribalAffiliationOption | null;
  tribalAffiliationTypeLocalTribe: boolean;
  tribalAffiliationTypeNonLocalTribe: boolean;
  parentalRightTerminated: YesNoOption | null;
  parentingYouth: YesNoOption | null;
  youthChildOpenCwsCase: YesNoOption | null;
  comments: string | null;

  youthAwolDuringMonth: YesNoOption | null;
  youthOpenToIntensiveCareCoordinationServicesDuringMonth: YesNoOption | null;
  newScreenedReferralOnCaseDuringMonth: YesNoOption | null;
  numberOfPlacementChangesDuringMonth: number | null;
  newAdoptiveHomeIdentifiedDuringMonth: YesNoOption | null;
  caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth: YesNoOption | null;
  caseStatusChangeDate: number | null; // unix timestamp in milliseconds

  // currentActivity should only be used by the current form when an activity is open and being edited
  currentActivity: Humboldt2020OngoingEfcAdoptActivityData | null;
  activities: Humboldt2020OngoingEfcAdoptActivityData[];

  standardsMet: YesNoOption | null;
  reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet: boolean;
  reasonsStandardsNotMetCaseworkerStoppedTracking: boolean;
  reasonsStandardsNotMetNotAllWorkDuringMonthRecorded: boolean;
  reasonsStandardsNotMetOther: boolean;
  supervisorComments: string | null;

  constructor(data: any) {
    super(data);

    this.caseId = data.caseId || '';
    this.caseStartDate = data.caseStartDate || null;
    this.caseAssignmentLength = data.caseAssignmentLength || null;
    this.studyCaseType = data.studyCaseType || null;
    this.youthPlacedOutOfCounty = data.youthPlacedOutOfCounty || null;
    this.numberOfYouthOnCasePlan = data.numberOfYouthOnCasePlan || null;
    this.ageOfSampledYouth = data.ageOfSampledYouth === 0 ? 0 : (data.ageOfSampledYouth || null);
    this.mostRecentRiskLevelAtStartOfMonth = data.mostRecentRiskLevelAtStartOfMonth || null;
    this.tribalAffiliation = data.tribalAffiliation || null;
    this.tribalAffiliationTypeLocalTribe = data.tribalAffiliationTypeLocalTribe || false;
    this.tribalAffiliationTypeNonLocalTribe = data.tribalAffiliationTypeNonLocalTribe || false;
    this.parentalRightTerminated = data.parentalRightTerminated || null;
    this.parentingYouth = data.parentingYouth || null;
    this.youthChildOpenCwsCase = data.youthChildOpenCwsCase || null;
    this.comments = data.comments || null;

    this.youthAwolDuringMonth = data.youthAwolDuringMonth || null;
    this.youthOpenToIntensiveCareCoordinationServicesDuringMonth = data.youthOpenToIntensiveCareCoordinationServicesDuringMonth || null;
    this.newScreenedReferralOnCaseDuringMonth = data.newScreenedReferralOnCaseDuringMonth || null;
    this.numberOfPlacementChangesDuringMonth = data.numberOfPlacementChangesDuringMonth === 0 ? 0 : (data.numberOfPlacementChangesDuringMonth || null);
    this.newAdoptiveHomeIdentifiedDuringMonth = data.newAdoptiveHomeIdentifiedDuringMonth || null;
    this.caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth = data.caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth || null;
    this.caseStatusChangeDate = data.caseStatusChangeDate || null;

    this.currentActivity = data.currentActivity || null;
    this.activities = data.activities || [];

    this.standardsMet = data.standardsMet || null;
    this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = data.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet || false;
    this.reasonsStandardsNotMetCaseworkerStoppedTracking = data.reasonsStandardsNotMetCaseworkerStoppedTracking || false;
    this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = data.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded || false;
    this.reasonsStandardsNotMetOther = data.reasonsStandardsNotMetOther || false;
    this.supervisorComments = data.supervisorComments || null;
  }

  /* Creates a new Humboldt2020OngoingEfcAdoptFormData class from the data */
  static createNewForm(id: number, formTypeId: number, user: UserData, timestamp: number): Humboldt2020OngoingEfcAdoptFormData {
    return new Humboldt2020OngoingEfcAdoptFormData(SharedFormData.newFormData(id, formTypeId, user, timestamp));
  }

  /* creates the object to store in the database */
  toDatabaseObject(): object {
    const databaseObject: any = {
      caseId: this.caseId,
      caseStartDate: this.caseStartDate,
      caseAssignmentLength: this.caseAssignmentLength,
      studyCaseType: this.studyCaseType,
      youthPlacedOutOfCounty: this.youthPlacedOutOfCounty,
      numberOfYouthOnCasePlan: this.numberOfYouthOnCasePlan,
      ageOfSampledYouth: this.ageOfSampledYouth,
      mostRecentRiskLevelAtStartOfMonth: this.mostRecentRiskLevelAtStartOfMonth,
      tribalAffiliation: this.tribalAffiliation,
      tribalAffiliationTypeLocalTribe: this.tribalAffiliationTypeLocalTribe,
      tribalAffiliationTypeNonLocalTribe: this.tribalAffiliationTypeNonLocalTribe,
      parentalRightTerminated: this.parentalRightTerminated,
      parentingYouth: this.parentingYouth,
      youthChildOpenCwsCase: this.youthChildOpenCwsCase,
      comments: this.comments,

      youthAwolDuringMonth: this.youthAwolDuringMonth,
      youthOpenToIntensiveCareCoordinationServicesDuringMonth: this.youthOpenToIntensiveCareCoordinationServicesDuringMonth,
      newScreenedReferralOnCaseDuringMonth: this.newScreenedReferralOnCaseDuringMonth,
      numberOfPlacementChangesDuringMonth: this.numberOfPlacementChangesDuringMonth,
      newAdoptiveHomeIdentifiedDuringMonth: this.newAdoptiveHomeIdentifiedDuringMonth,
      caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth: this.caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth,
      caseStatusChangeDate: this.caseStatusChangeDate,

      currentActivity: this.currentActivity,

      standardsMet: this.standardsMet,
      reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet: this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet,
      reasonsStandardsNotMetCaseworkerStoppedTracking: this.reasonsStandardsNotMetCaseworkerStoppedTracking,
      reasonsStandardsNotMetNotAllWorkDuringMonthRecorded: this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded,
      reasonsStandardsNotMetOther: this.reasonsStandardsNotMetOther,
      supervisorComments: this.supervisorComments
    };

    if (this.activities.length > 0) {
      databaseObject.activities = this.activities;
    }

    return Object.assign({}, super.toDatabaseObject(), databaseObject);
  }

  /* Creates a Humboldt2020OngoingEfcAdoptFormData class given the string stored in the database */
  static createFromDatabaseString(data: string): Humboldt2020OngoingEfcAdoptFormData {
    return new Humboldt2020OngoingEfcAdoptFormData(JSON.parse(data));
  }

  /* creates a data server object to send to the server */
  toServerObject(): ApiSaveSurveyData {
    const serverObject = super.toServerObject();

    SharedFormData.addSurveyItem(serverObject, 'CaseId', this.caseId);
    SharedFormData.addTimestampSurveyItem(serverObject, 'CaseStartDate', this.caseStartDate);
    SharedFormData.addSurveyItem(serverObject, 'CaseAssignmentLength', this.caseAssignmentLength);
    SharedFormData.addSurveyItem(serverObject, 'StudyCaseType', this.studyCaseType);
    const askYouthPlacedOutOfCounty = this.studyCaseType !== null && this.studyCaseType !== Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.FamilyMaintenance && this.studyCaseType !== Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.CourtFamilyMaintenance;
    SharedFormData.addSurveyItem(serverObject, 'YouthPlacedOutOfCounty', askYouthPlacedOutOfCounty ? this.youthPlacedOutOfCounty : null);
    const askNumberOfYouthOnCasePlan = this.studyCaseType !== null && this.studyCaseType !== Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.ExtendedFosterCare;
    SharedFormData.addSurveyItem(serverObject, 'NumberOfYouthOnCasePlan', askNumberOfYouthOnCasePlan ? this.numberOfYouthOnCasePlan : null);
    SharedFormData.addNumberSurveyItem(serverObject, 'AgeOfSampledYouth', this.ageOfSampledYouth);
    const askMostRecentRiskLevelAtStartOfMonth = this.studyCaseType !== null && this.studyCaseType !== Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpAdoptions;
    SharedFormData.addSurveyItem(serverObject, 'MostRecentRiskLevelAtStartOfMonth', askMostRecentRiskLevelAtStartOfMonth ? this.mostRecentRiskLevelAtStartOfMonth : null);
    SharedFormData.addSurveyItem(serverObject, 'TribalAffiliation', this.tribalAffiliation);
    SharedFormData.addBooleanSurveyItem(serverObject, 'TribalAffiliationTypeLocalTribe',
      this.tribalAffiliation === Humboldt2020OngoingEfcAdoptTribalAffiliationOption.YesMaybe ? this.tribalAffiliationTypeLocalTribe : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'TribalAffiliationTypeNonLocalTribe',
      this.tribalAffiliation === Humboldt2020OngoingEfcAdoptTribalAffiliationOption.YesMaybe ? this.tribalAffiliationTypeNonLocalTribe : false);
    SharedFormData.addSurveyItem(serverObject, 'ParentalRightTerminated', this.parentalRightTerminated);
    const askParentingYouth = this.studyCaseType !== null && this.studyCaseType !== Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpAdoptions;
    SharedFormData.addSurveyItem(serverObject, 'ParentingYouth', askParentingYouth ? this.parentingYouth : null);
    const askYouthChildOpenCwsCase = askParentingYouth && this.parentingYouth === YesNoOption.Yes;
    SharedFormData.addSurveyItem(serverObject, 'YouthChildOpenCwsCase', askYouthChildOpenCwsCase ? this.youthChildOpenCwsCase : null);
    SharedFormData.addSurveyItem(serverObject, 'Comments', this.comments);

    SharedFormData.addSurveyItem(serverObject, 'YouthAwolDuringMonth', this.youthAwolDuringMonth);
    SharedFormData.addSurveyItem(serverObject, 'YouthOpenToIntensiveCareCoordinationServicesDuringMonth', this.youthOpenToIntensiveCareCoordinationServicesDuringMonth);
    const askNewScreenedReferralOnCaseDuringMonth = this.studyCaseType !== null && this.studyCaseType !== Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.ExtendedFosterCare;
    SharedFormData.addSurveyItem(serverObject, 'NewScreenedReferralOnCaseDuringMonth', askNewScreenedReferralOnCaseDuringMonth ? this.newScreenedReferralOnCaseDuringMonth : null);
    const askNumberOfPlacementChangesDuringMonth = this.studyCaseType !== null &&
      (Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.FamilyReunification === this.studyCaseType ||
        Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.ExtendedFosterCare === this.studyCaseType ||
        Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpAdoptions === this.studyCaseType ||
        Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpGuardianship === this.studyCaseType ||
        Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpAppla === this.studyCaseType);
    SharedFormData.addNumberSurveyItem(serverObject, 'NumberOfPlacementChangesDuringMonth', askNumberOfPlacementChangesDuringMonth ? this.numberOfPlacementChangesDuringMonth : null);
    const askNewAdoptiveHomeIdentifiedDuringMonth = this.studyCaseType !== null && this.studyCaseType === Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpAdoptions;
    SharedFormData.addSurveyItem(serverObject, 'NewAdoptiveHomeIdentifiedDuringMonth', askNewAdoptiveHomeIdentifiedDuringMonth ? this.newAdoptiveHomeIdentifiedDuringMonth : null);
    SharedFormData.addSurveyItem(serverObject, 'CaseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth', this.caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth);
    const askCaseStatusChangeDate = this.caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth !== null && this.caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth === YesNoOption.Yes;
    SharedFormData.addTimestampSurveyItem(serverObject, 'CaseStatusChangeDate', askCaseStatusChangeDate ? this.caseStatusChangeDate : null);

    this.activities.forEach((activity, index) => {
      SharedFormData.addTimestampSurveyItem(serverObject, 'ActivityDate', activity.activityDate, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityActivity', activity.activity, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityRelatedToAdoptionFinalization',
        Humboldt2020OngoingEfcAdoptStudyCaseTypeOption.PpAdoptions === this.studyCaseType ? activity.relatedToAdoptionFinalization : null, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedYouth', activity.personInvolvedYouth, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedParentGuardian', activity.personInvolvedParentGuardian, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedTribe', activity.personInvolvedTribe, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedSubstituteCareProvider', activity.personInvolvedSubstituteCareProvider, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedCollateral', activity.personInvolvedCollateral, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOtherCwsStaff', activity.personInvolvedOtherCwsStaff, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedSupervisor', activity.personInvolvedSupervisor, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOther', activity.personInvolvedOther, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedNone', activity.personInvolvedNone, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityMethod', activity.method, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityPlace', activity.place, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityActivityMinutesSpent', activity.activityMinutesSpent, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityTravelMinutesSpent', activity.travelMinutesSpent, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityDescription', activity.activityDescription, index);
    });

    SharedFormData.addSurveyItem(serverObject, 'StandardsMet', this.standardsMet);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetCaseworkerStoppedTracking',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetCaseworkerStoppedTracking : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetNotAllWorkDuringMonthRecorded',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetOther',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetOther : false);
    SharedFormData.addSurveyItem(serverObject, 'SupervisorComments', this.supervisorComments);

    return serverObject;
  }

  /* creates this class from a data server object
  * fullRecord is false for records created from api/getlist */
  static createFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): Humboldt2020OngoingEfcAdoptFormData {
    return new Humboldt2020OngoingEfcAdoptFormData(Humboldt2020OngoingEfcAdoptFormData.createDataFromServerObject(serverObject, fullRecord));
  }

  /* creates the data object to parse into a new class instance, exposed as a separate function so child classes can use it
  * fullRecord is false for records created from api/getlist */
  static createDataFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): any {
    const data = SharedFormData.createDataFromServerObject(serverObject, fullRecord);

    if (serverObject.SurveyItems && isArray(serverObject.SurveyItems)) {
      serverObject.SurveyItems.forEach(item => {
        let itemIndex = 0;
        switch (item.ItemName) {
          case 'CaseId':
            data.caseId = item.ItemValue;
            break;
          case 'CaseStartDate':
            data.caseStartDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'CaseAssignmentLength':
            data.caseAssignmentLength = item.ItemValue;
            break;
          case 'StudyCaseType':
            data.studyCaseType = item.ItemValue;
            break;
          case 'YouthPlacedOutOfCounty':
            data.youthPlacedOutOfCounty = item.ItemValue;
            break;
          case 'NumberOfYouthOnCasePlan':
            data.numberOfYouthOnCasePlan = item.ItemValue;
            break;
          case 'AgeOfSampledYouth':
            data.ageOfSampledYouth = parseInt(item.ItemValue, 10);
            break;
          case 'MostRecentRiskLevelAtStartOfMonth':
            data.mostRecentRiskLevelAtStartOfMonth = item.ItemValue;
            break;
          case 'TribalAffiliation':
            data.tribalAffiliation = item.ItemValue;
            break;
          case 'TribalAffiliationTypeLocalTribe':
            data.tribalAffiliationTypeLocalTribe = (item.ItemValue === 'true');
            break;
          case 'TribalAffiliationTypeNonLocalTribe':
            data.tribalAffiliationTypeNonLocalTribe = (item.ItemValue === 'true');
            break;
          case 'ParentalRightTerminated':
            data.parentalRightTerminated = item.ItemValue;
            break;
          case 'ParentingYouth':
            data.parentingYouth = item.ItemValue;
            break;
          case 'YouthChildOpenCwsCase':
            data.youthChildOpenCwsCase = item.ItemValue;
            break;
          case 'Comments':
            data.comments = item.ItemValue;
            break;

          case 'YouthAwolDuringMonth':
            data.youthAwolDuringMonth = item.ItemValue;
            break;
          case 'YouthOpenToIntensiveCareCoordinationServicesDuringMonth':
            data.youthOpenToIntensiveCareCoordinationServicesDuringMonth = item.ItemValue;
            break;
          case 'NewScreenedReferralOnCaseDuringMonth':
            data.newScreenedReferralOnCaseDuringMonth = item.ItemValue;
            break;
          case 'NumberOfPlacementChangesDuringMonth':
            data.numberOfPlacementChangesDuringMonth = parseInt(item.ItemValue, 10);
            break;
          case 'NewAdoptiveHomeIdentifiedDuringMonth':
            data.newAdoptiveHomeIdentifiedDuringMonth = item.ItemValue;
            break;
          case 'CaseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth':
            data.caseCloseChangeServiceComponentOrPermanencyGoalChangeDuringMonth = item.ItemValue;
            break;
          case 'CaseStatusChangeDate':
            data.caseStatusChangeDate = parseInt(item.ItemValue, 10) * 1000;
            break;

          case 'ActivityDate':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'ActivityActivity':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activity = item.ItemValue;
            break;
          case 'ActivityRelatedToAdoptionFinalization':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].relatedToAdoptionFinalization = item.ItemValue;
            break;
          case 'ActivityPersonInvolvedYouth':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedYouth = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedParentGuardian':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedParentGuardian = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedTribe':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedTribe = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedSubstituteCareProvider':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedSubstituteCareProvider = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedCollateral':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedCollateral = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOtherCwsStaff':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOtherCwsStaff = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedSupervisor':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedSupervisor = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOther':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOther = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedNone':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedNone = (item.ItemValue === 'true');
            break;
          case 'ActivityMethod':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].method = item.ItemValue;
            break;
          case 'ActivityPlace':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].place = item.ItemValue;
            break;
          case 'ActivityActivityMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityTravelMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].travelMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityDescription':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020OngoingEfcAdoptFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDescription = item.ItemValue;
            break;

          case 'StandardsMet':
            data.standardsMet = item.ItemValue;
            break;
          case 'ReasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet':
            data.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetCaseworkerStoppedTracking':
            data.reasonsStandardsNotMetCaseworkerStoppedTracking = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetNotAllWorkDuringMonthRecorded':
            data.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetOther':
            data.reasonsStandardsNotMetOther = (item.ItemValue === 'true');
            break;
          case 'SupervisorComments':
            data.supervisorComments = item.ItemValue;
            break;

          default:
            console.error('Unknown item name (' + item.ItemName + ') while parsing SurveyItems');
        }
      });
    }

    if (data.activities && isArray(data.activities)) {
      data.activities.forEach(activity => {
        const personInvolvedArray = getActivityPersonInvolvedArray(activity);
        activity.personInvolvedDisplay = personInvolvedArray.length ? personInvolvedArray.join(', ') : null;
      })
    }

    return data;
  }

  /* creates empty placeholders in the activities array on data up to index */
  static setDataActivityCount(data: any, index: number) {
    if (!data.activities) data.activities = [];
    while (data.activities.length <= index) {
      const newActivity: Humboldt2020OngoingEfcAdoptActivityData = {
        index: data.activities.length,
        activityDate: null,
        activity: null,
        relatedToAdoptionFinalization: null,
        personInvolvedDisplay: null,
        personInvolvedYouth: false,
        personInvolvedParentGuardian: false,
        personInvolvedTribe: false,
        personInvolvedSubstituteCareProvider: false,
        personInvolvedCollateral: false,
        personInvolvedOtherCwsStaff: false,
        personInvolvedSupervisor: false,
        personInvolvedOther: false,
        personInvolvedNone: false,
        method: null,
        place: null,
        activityMinutesSpent: null,
        travelMinutesSpent: null,
        activityDescription: null
      };
      data.activities.push(newActivity);
    }
  }
}
