import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { FormComponent } from '../../../form/form.component';
import { FormStatus } from '../../../form/form-status.enum';
import { getFormTypeById } from '../../form-type-data';
import { MatSort } from '@angular/material/sort';
import { Validators } from '@angular/forms';
import { Humboldt2020IdValidator } from '../../../validators';
import { debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
import { MessageDialogComponent } from '../../../message-dialog/message-dialog.component';
import { Humboldt2020ReferralInvestigationFormData } from './humboldt2020-referral-investigation-form-data';
import { Humboldt2020ReferralInvestigationActivityListColumn } from './humboldt2020-referral-investigation-activity-list-column.enum';
import { Humboldt2020ReferralInvestigationActivityActivityOption } from './humboldt2020-referral-investigation-activity-dialog/humboldt2020-referral-investigation-activity-activity-option.enum';
import { Humboldt2020ReferralInvestigationReferralTypeOption } from './humboldt2020-referral-investigation-referral-type-option.enum';
import { Humboldt2020ReferralInvestigationResponsePriorityOption } from './humboldt2020-referral-investigation-response-priority-option.enum';
import { Humboldt2020ReferralInvestigationTribalAffiliationOption } from './humboldt2020-referral-investigation-tribal-affiliation-option.enum';
import { Humboldt2020ReferralInvestigationTribalAffiliationTypeOption } from './humboldt2020-referral-investigation-tribal-affiliation-type-option.enum';
import { Humboldt2020ReferralInvestigationNumberOfChildrenOption } from './humboldt2020-referral-investigation-number-of-children-option.enum';
import { Humboldt2020ReferralInvestigationInitialSdmSafetyDecisionOption } from './humboldt2020-referral-investigation-initial-sdm-safety-decision-option.enum';
import { Humboldt2020ReferralInvestigationFinalSdmSafetyDecisionOption } from './humboldt2020-referral-investigation-final-sdm-safety-decision-option.enum';
import { Humboldt2020ReferralInvestigationFinalSdmRiskLevelOption } from './humboldt2020-referral-investigation-final-sdm-risk-level-option.enum';
import { Humboldt2020ReferralInvestigationAllegationConclusionOption } from './humboldt2020-referral-investigation-allegation-conclusion-option.enum';
import { Humboldt2020ReferralInvestigationReferralOutcomeOption } from './humboldt2020-referral-investigation-referral-outcome-option.enum';
import { Humboldt2020ReferralInvestigationReasonsStandardsNotMetOption } from './humboldt2020-referral-investigation-reasons-standards-not-met-option.enum';
import { Humboldt2020ReferralInvestigationActivityListFilterDialogComponent } from './humboldt2020-referral-investigation-activity-list-filter-dialog/humboldt2020-referral-investigation-activity-list-filter-dialog.component';
import { Humboldt2020ReferralInvestigationActivityDialogComponent } from './humboldt2020-referral-investigation-activity-dialog/humboldt2020-referral-investigation-activity-dialog.component';
import { isArray } from "util";
import { YesNoOption } from '../../../form/yes-no-option.enum';
export class Humboldt2020ReferralInvestigationComponent extends FormComponent {
    constructor(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, titleService) {
        super(apiService, authService, breakpointObserver, databaseService, dialog, formBuilder, onlineService, route, router, Humboldt2020ReferralInvestigationFormData);
        this.apiService = apiService;
        this.authService = authService;
        this.breakpointObserver = breakpointObserver;
        this.databaseService = databaseService;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.onlineService = onlineService;
        this.route = route;
        this.router = router;
        this.titleService = titleService;
        this.formTypeId = 6;
        this.formType = getFormTypeById(this.formTypeId);
        this.formDataClass = Humboldt2020ReferralInvestigationFormData;
        this.currentFormData = null;
        this.activitiesDataSource = [];
        this.activityListColumnEnum = Humboldt2020ReferralInvestigationActivityListColumn;
        this.activityListDisplayedColumns = Object.values(Humboldt2020ReferralInvestigationActivityListColumn);
        this.activityListFilterForm = this.formBuilder.group({
            activity: [null],
            sorting: [null]
        });
        this.activityListFilters = {
            activity: null,
            sortColumn: null,
            sortDirection: null
        };
        this.activityListFilterInfoString = '';
        this.activityOptions = Object.values(Humboldt2020ReferralInvestigationActivityActivityOption);
        this.activityListSortOptions = [
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.activityDate, sortDirection: 'asc', display: 'Date Ascending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.activityDate, sortDirection: 'desc', display: 'Date Descending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.activity, sortDirection: 'asc', display: 'Activity Ascending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.activity, sortDirection: 'desc', display: 'Activity Descending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.personInvolved, sortDirection: 'asc', display: 'Person Involved Ascending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.personInvolved, sortDirection: 'desc', display: 'Person Involved Descending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.withReferralTeammate, sortDirection: 'asc', display: 'With Teammate Ascending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.withReferralTeammate, sortDirection: 'desc', display: 'With Teammate Descending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.method, sortDirection: 'asc', display: 'Method Ascending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.method, sortDirection: 'desc', display: 'Method Descending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.place, sortDirection: 'asc', display: 'Place Ascending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.place, sortDirection: 'desc', display: 'Place Descending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.activityMinutesSpent, sortDirection: 'asc', display: 'Activity Minutes Spent Ascending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.activityMinutesSpent, sortDirection: 'desc', display: 'Activity Minutes Spent Descending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.travelMinutesSpent, sortDirection: 'asc', display: 'Travel Minutes Spent Ascending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.travelMinutesSpent, sortDirection: 'desc', display: 'Travel Minutes Spent Descending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.activityDescription, sortDirection: 'asc', display: 'Description Ascending' },
            { sortColumn: Humboldt2020ReferralInvestigationActivityListColumn.activityDescription, sortDirection: 'desc', display: 'Description Descending' },
        ];
        this.form = this.formBuilder.group({
            agentName: [null],
            createdDate: [null],
            status: [null],
            statusChangedDate: [null],
            updatedDate: [null],
            referralName: [null, [Validators.required, Validators.maxLength(200)]],
            referralId: [null, [Validators.required, Validators.maxLength(22), Humboldt2020IdValidator]],
            referralAssignmentDate: [null, Validators.required],
            referralType: [null, Validators.required],
            responsePriority: [null, Validators.required],
            tribalAffiliation: [null, Validators.required],
            tribalAffiliationType: [[]],
            numberOfChildrenInAllegationHousehold: [null, Validators.required],
            comments: [null, Validators.maxLength(500)],
            initialSdmSafetyDecision: [null, Validators.required],
            finalSdmSafetyDecision: [null, Validators.required],
            finalSdmRiskLevel: [null, Validators.required],
            allegationConclusions: [[], Validators.required],
            referralOutcomes: [[], Validators.required],
            activityListFilter: this.activityListFilterForm,
            standardsMet: [null],
            reasonsStandardsNotMet: [[]],
            supervisorComments: [null, Validators.maxLength(500)]
        });
        this.referralTypeOptions = Object.values(Humboldt2020ReferralInvestigationReferralTypeOption);
        this.responsePriorityOptions = Object.values(Humboldt2020ReferralInvestigationResponsePriorityOption);
        this.tribalAffiliationOptionEnum = Humboldt2020ReferralInvestigationTribalAffiliationOption;
        this.tribalAffiliationOptions = Object.values(Humboldt2020ReferralInvestigationTribalAffiliationOption);
        this.tribalAffiliationTypeOptions = Object.values(Humboldt2020ReferralInvestigationTribalAffiliationTypeOption);
        this.numberOfChildrenInAllegationHouseholdOptions = Object.values(Humboldt2020ReferralInvestigationNumberOfChildrenOption);
        this.initialSdmSafetyDecisionOptions = Object.values(Humboldt2020ReferralInvestigationInitialSdmSafetyDecisionOption);
        this.finalSdmSafetyDecisionOptions = Object.values(Humboldt2020ReferralInvestigationFinalSdmSafetyDecisionOption);
        this.finalSdmRiskLevelOptions = Object.values(Humboldt2020ReferralInvestigationFinalSdmRiskLevelOption);
        this.allegationConclusionOptions = Object.values(Humboldt2020ReferralInvestigationAllegationConclusionOption);
        this.referralOutcomeOptions = Object.values(Humboldt2020ReferralInvestigationReferralOutcomeOption);
        this.reasonsStandardsNotMetOptions = Object.values(Humboldt2020ReferralInvestigationReasonsStandardsNotMetOption);
        this.loadFormTypeSettings();
        this.formChangeSubscriptions();
        this.saveFormDialogErrorData.message = 'You must provide a Sampled Referral Name to save the form.';
        this.sendBackFormDialogErrorData.message = 'You must provide a Sampled Referral Name to save the form.';
        this.discardChangesDialogData.message = 'If you discard changes, you will lose any changes you have made to this' +
            ' form since you last saved it. This includes any changes to sample information, sample updates, and activities.' +
            ' Are you sure you want to do this?';
        this.submitFormDialogData.message = 'Submitting the form will send it to your supervisor for review and you will no longer be able to edit this form. Make sure you have:';
        this.submitFormDialogData.messageList = [
            'Completed all of the fields in the Sample Information and Sample Updates tabs.',
            'Recorded all the time spent working on this referral during the month.'
        ];
        this.sendBackFormDialogErrorData.title = 'Send Back to Caseworker Error';
        this.sendBackFormDialogData.title = 'Send Back to Caseworker';
        this.sendBackFormDialogData.message = 'This action will send the form back to the Caseworker for modification.' +
            ' This will revert the status of the form to "In Progress" and' +
            ' you will not be able to edit the form until the Caseworker submits it again.' +
            ' Are you sure you want to do this?';
        this.sendBackFormDialogData.okButtonLabel = 'Send Back to Caseworker';
        this.completeFormDialogData.message = 'Approving the form will remove it from your queue and you will no longer be able to edit this form. Make sure:';
        this.completeFormDialogData.messageList = [
            'The form captures all the work that was completed by the caseworker.',
            'You have reviewed the form for required work activities designated by standards.',
            'Time is recorded in minutes.'
        ];
    }
    set activityListSortViewChild(value) {
        this.activityListSort = value;
        // This timeout is needed or angular complains Expression has changed after it was checked.
        window.setTimeout(() => {
            // default in the current set sorting when the sorter is set
            // this happens if the width goes from isSmallDisplay to isLargeDisplay
            if (this.activityListSort && this.activityListFilterForm && this.activityListFilterForm.controls.sorting.value) {
                const sorting = this.activityListFilterForm.controls.sorting.value;
                if (this.activityListSort.active != sorting.sortColumn || this.activityListSort.direction != sorting.sortDirection) {
                    if (this.activityListSort) {
                        this.activityListSort.sort({
                            id: sorting.sortColumn,
                            start: sorting.sortDirection,
                            disableClear: true
                        });
                    }
                }
            }
        });
    }
    /* adds subscriptions for each of the form fields to update this.currentFormData */
    formChangeSubscriptions() {
        this.form.controls.referralName.valueChanges.subscribe(referralName => {
            if (this.currentFormData && this.currentFormData.name !== referralName) {
                this.currentFormData.name = referralName;
                this.saveCurrentFormValues();
            }
            if (this.formType && this.currentFormData) {
                const title = this.formType.name + ' - ' + this.currentFormData.name;
                if (this.titleService.title.value !== title) {
                    this.titleService.title.next(title);
                }
            }
        });
        this.form.controls.referralId.valueChanges.subscribe(referralId => {
            if (this.currentFormData && this.currentFormData.referralId !== referralId) {
                this.currentFormData.referralId = referralId;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.referralAssignmentDate.valueChanges.subscribe(referralAssignmentDate => {
            const numberValue = referralAssignmentDate !== null ? parseInt(referralAssignmentDate.format('x')) : null;
            if (this.currentFormData && this.currentFormData.referralAssignmentDate !== numberValue) {
                this.currentFormData.referralAssignmentDate = numberValue;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.referralType.valueChanges.subscribe(referralType => {
            if (this.currentFormData && this.currentFormData.referralType !== referralType) {
                this.currentFormData.caseType = referralType;
                this.currentFormData.referralType = referralType;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.responsePriority.valueChanges.subscribe(responsePriority => {
            if (this.currentFormData && this.currentFormData.responsePriority !== responsePriority) {
                this.currentFormData.responsePriority = responsePriority;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.tribalAffiliation.valueChanges.subscribe(tribalAffiliation => {
            if (this.currentFormData && this.currentFormData.tribalAffiliation !== tribalAffiliation) {
                this.currentFormData.tribalAffiliation = tribalAffiliation;
                this.saveCurrentFormValues();
                this.updateTribalAffiliationTypeValidators();
            }
        });
        this.form.controls.tribalAffiliationType.valueChanges.subscribe(tribalAffiliationTypes => {
            let tribalAffiliationTypeLocalTribe = false;
            let tribalAffiliationTypeNonLocalTribe = false;
            if (isArray(tribalAffiliationTypes)) {
                tribalAffiliationTypes.forEach(tribalAffiliationType => {
                    switch (tribalAffiliationType) {
                        case Humboldt2020ReferralInvestigationTribalAffiliationTypeOption.LocalTribe:
                            tribalAffiliationTypeLocalTribe = true;
                            break;
                        case Humboldt2020ReferralInvestigationTribalAffiliationTypeOption.NonLocalTribe:
                            tribalAffiliationTypeNonLocalTribe = true;
                            break;
                    }
                });
            }
            let saveCurrentFormValues = false;
            if (this.currentFormData && this.currentFormData.tribalAffiliationTypeLocalTribe !== tribalAffiliationTypeLocalTribe) {
                this.currentFormData.tribalAffiliationTypeLocalTribe = tribalAffiliationTypeLocalTribe;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.tribalAffiliationTypeNonLocalTribe !== tribalAffiliationTypeNonLocalTribe) {
                this.currentFormData.tribalAffiliationTypeNonLocalTribe = tribalAffiliationTypeNonLocalTribe;
                saveCurrentFormValues = true;
            }
            if (saveCurrentFormValues) {
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.numberOfChildrenInAllegationHousehold.valueChanges.subscribe(numberOfChildrenInAllegationHousehold => {
            if (this.currentFormData && this.currentFormData.numberOfChildrenInAllegationHousehold !== numberOfChildrenInAllegationHousehold) {
                this.currentFormData.numberOfChildrenInAllegationHousehold = numberOfChildrenInAllegationHousehold;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.comments.valueChanges.subscribe(comments => {
            if (this.currentFormData && this.currentFormData.comments !== comments) {
                this.currentFormData.comments = comments;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.initialSdmSafetyDecision.valueChanges.subscribe(initialSdmSafetyDecision => {
            if (this.currentFormData && this.currentFormData.initialSdmSafetyDecision !== initialSdmSafetyDecision) {
                this.currentFormData.initialSdmSafetyDecision = initialSdmSafetyDecision;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.finalSdmSafetyDecision.valueChanges.subscribe(finalSdmSafetyDecision => {
            if (this.currentFormData && this.currentFormData.finalSdmSafetyDecision !== finalSdmSafetyDecision) {
                this.currentFormData.finalSdmSafetyDecision = finalSdmSafetyDecision;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.finalSdmRiskLevel.valueChanges.subscribe(finalSdmRiskLevel => {
            if (this.currentFormData && this.currentFormData.finalSdmRiskLevel !== finalSdmRiskLevel) {
                this.currentFormData.finalSdmRiskLevel = finalSdmRiskLevel;
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.allegationConclusions.valueChanges.subscribe(allegationConclusions => {
            let allegationConclusionSubstantiated = false;
            let allegationConclusionInconclusive = false;
            let allegationConclusionUnfounded = false;
            let allegationConclusionPending = false;
            if (isArray(allegationConclusions)) {
                allegationConclusions.forEach(allegationConclusion => {
                    switch (allegationConclusion) {
                        case Humboldt2020ReferralInvestigationAllegationConclusionOption.Substantiated:
                            allegationConclusionSubstantiated = true;
                            break;
                        case Humboldt2020ReferralInvestigationAllegationConclusionOption.Inconclusive:
                            allegationConclusionInconclusive = true;
                            break;
                        case Humboldt2020ReferralInvestigationAllegationConclusionOption.Unfounded:
                            allegationConclusionUnfounded = true;
                            break;
                        case Humboldt2020ReferralInvestigationAllegationConclusionOption.Pending:
                            allegationConclusionPending = true;
                            break;
                    }
                });
            }
            let saveCurrentFormValues = false;
            if (this.currentFormData && this.currentFormData.allegationConclusionSubstantiated !== allegationConclusionSubstantiated) {
                this.currentFormData.allegationConclusionSubstantiated = allegationConclusionSubstantiated;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.allegationConclusionInconclusive !== allegationConclusionInconclusive) {
                this.currentFormData.allegationConclusionInconclusive = allegationConclusionInconclusive;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.allegationConclusionUnfounded !== allegationConclusionUnfounded) {
                this.currentFormData.allegationConclusionUnfounded = allegationConclusionUnfounded;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.allegationConclusionPending !== allegationConclusionPending) {
                this.currentFormData.allegationConclusionPending = allegationConclusionPending;
                saveCurrentFormValues = true;
            }
            if (saveCurrentFormValues) {
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.referralOutcomes.valueChanges.subscribe(referralOutcomes => {
            let referralOutcomeTransferToFamilyMaintenance = false;
            let referralOutcomeTransferToCourtFamilyMaintenance = false;
            let referralOutcomeTransferToFamilyReunification = false;
            let referralOutcomeCloseNoFurtherAction = false;
            let referralOutcomePending = false;
            if (isArray(referralOutcomes)) {
                referralOutcomes.forEach(referralOutcome => {
                    switch (referralOutcome) {
                        case Humboldt2020ReferralInvestigationReferralOutcomeOption.TransferToFamilyMaintenance:
                            referralOutcomeTransferToFamilyMaintenance = true;
                            break;
                        case Humboldt2020ReferralInvestigationReferralOutcomeOption.TransferToCourtFamilyMaintenance:
                            referralOutcomeTransferToCourtFamilyMaintenance = true;
                            break;
                        case Humboldt2020ReferralInvestigationReferralOutcomeOption.TransferToFamilyReunification:
                            referralOutcomeTransferToFamilyReunification = true;
                            break;
                        case Humboldt2020ReferralInvestigationReferralOutcomeOption.CloseNoFurtherAction:
                            referralOutcomeCloseNoFurtherAction = true;
                            break;
                        case Humboldt2020ReferralInvestigationReferralOutcomeOption.Pending:
                            referralOutcomePending = true;
                            break;
                    }
                });
            }
            let saveCurrentFormValues = false;
            if (this.currentFormData && this.currentFormData.referralOutcomeTransferToFamilyMaintenance !== referralOutcomeTransferToFamilyMaintenance) {
                this.currentFormData.referralOutcomeTransferToFamilyMaintenance = referralOutcomeTransferToFamilyMaintenance;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.referralOutcomeTransferToCourtFamilyMaintenance !== referralOutcomeTransferToCourtFamilyMaintenance) {
                this.currentFormData.referralOutcomeTransferToCourtFamilyMaintenance = referralOutcomeTransferToCourtFamilyMaintenance;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.referralOutcomeTransferToFamilyReunification !== referralOutcomeTransferToFamilyReunification) {
                this.currentFormData.referralOutcomeTransferToFamilyReunification = referralOutcomeTransferToFamilyReunification;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.referralOutcomeCloseNoFurtherAction !== referralOutcomeCloseNoFurtherAction) {
                this.currentFormData.referralOutcomeCloseNoFurtherAction = referralOutcomeCloseNoFurtherAction;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.referralOutcomePending !== referralOutcomePending) {
                this.currentFormData.referralOutcomePending = referralOutcomePending;
                saveCurrentFormValues = true;
            }
            if (saveCurrentFormValues) {
                this.saveCurrentFormValues();
            }
        });
        this.activityListFilterForm.controls.sorting.valueChanges.subscribe((sorting) => {
            if (sorting && this.activityListSort && (this.activityListSort.active !== sorting.sortColumn || (this.activityListSort.direction || 'asc') !== sorting.sortDirection)) {
                this.activityListSort.sort({
                    id: sorting.sortColumn,
                    start: sorting.sortDirection,
                    disableClear: true
                });
            }
        });
        this.activityListFilterForm.valueChanges.pipe(debounceTime(100)).subscribe(values => {
            let saveActivityListFilters = false;
            if (this.activityListFilters.activity !== values.activity) {
                this.activityListFilters.activity = values.activity;
                saveActivityListFilters = true;
            }
            if (values.sorting) {
                if (this.activityListFilters.sortColumn !== values.sorting.sortColumn || this.activityListFilters.sortDirection !== values.sorting.sortDirection) {
                    this.activityListFilters.sortColumn = values.sorting.sortColumn;
                    this.activityListFilters.sortDirection = values.sorting.sortDirection;
                    saveActivityListFilters = true;
                }
            }
            else if (this.activityListFilters.sortColumn !== null || this.activityListFilters.sortDirection !== null) {
                this.activityListFilters.sortColumn = null;
                this.activityListFilters.sortDirection = null;
                saveActivityListFilters = true;
            }
            if (saveActivityListFilters) {
                this.saveFormTypeSettings();
            }
            this.activityListFilterAndSortData();
        });
        this.form.controls.standardsMet.valueChanges.subscribe(standardsMet => {
            if (this.currentFormData && this.currentFormData.standardsMet !== standardsMet) {
                this.currentFormData.standardsMet = standardsMet;
                this.saveCurrentFormValues();
                this.updateReasonsStandardsNotMetValidators();
            }
        });
        this.form.controls.reasonsStandardsNotMet.valueChanges.subscribe(reasonsStandardsNotMet => {
            let reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = false;
            let reasonsStandardsNotMetCaseworkerStoppedTracking = false;
            let reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = false;
            let reasonsStandardsNotMetOther = false;
            if (isArray(reasonsStandardsNotMet)) {
                reasonsStandardsNotMet.forEach(reasonStandardsNotMet => {
                    switch (reasonStandardsNotMet) {
                        case Humboldt2020ReferralInvestigationReasonsStandardsNotMetOption.NumberOfContactsOtherStandardsNotMet:
                            reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = true;
                            break;
                        case Humboldt2020ReferralInvestigationReasonsStandardsNotMetOption.CaseworkerStoppedTracking:
                            reasonsStandardsNotMetCaseworkerStoppedTracking = true;
                            break;
                        case Humboldt2020ReferralInvestigationReasonsStandardsNotMetOption.NotAllWorkDuringMonthRecorded:
                            reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = true;
                            break;
                        case Humboldt2020ReferralInvestigationReasonsStandardsNotMetOption.Other:
                            reasonsStandardsNotMetOther = true;
                            break;
                    }
                });
            }
            let saveCurrentFormValues = false;
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet !== reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet) {
                this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking !== reasonsStandardsNotMetCaseworkerStoppedTracking) {
                this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking = reasonsStandardsNotMetCaseworkerStoppedTracking;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded !== reasonsStandardsNotMetNotAllWorkDuringMonthRecorded) {
                this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = reasonsStandardsNotMetNotAllWorkDuringMonthRecorded;
                saveCurrentFormValues = true;
            }
            if (this.currentFormData && this.currentFormData.reasonsStandardsNotMetOther !== reasonsStandardsNotMetOther) {
                this.currentFormData.reasonsStandardsNotMetOther = reasonsStandardsNotMetOther;
                saveCurrentFormValues = true;
            }
            if (saveCurrentFormValues) {
                this.saveCurrentFormValues();
            }
        });
        this.form.controls.supervisorComments.valueChanges.subscribe(supervisorComments => {
            if (this.currentFormData && this.currentFormData.supervisorComments !== supervisorComments) {
                this.currentFormData.supervisorComments = supervisorComments;
                this.saveCurrentFormValues();
            }
        });
    }
    /* updates the validators for tribalAffiliationType */
    updateTribalAffiliationTypeValidators() {
        if (this.currentFormData && this.currentFormData.tribalAffiliation === Humboldt2020ReferralInvestigationTribalAffiliationOption.YesMaybe) {
            this.form.controls.tribalAffiliationType.setValidators(Validators.required);
        }
        else {
            this.form.controls.tribalAffiliationType.clearValidators();
        }
        this.form.controls.tribalAffiliationType.updateValueAndValidity();
    }
    /* updates the validators for standardsMet */
    updateStandardsMetValidators() {
        if (this.currentFormData && this.currentFormData.status === FormStatus.InReview) {
            this.form.controls.standardsMet.setValidators(Validators.required);
        }
        else {
            this.form.controls.standardsMet.clearValidators();
        }
        this.form.controls.standardsMet.updateValueAndValidity();
    }
    /* updates the validators for reasonsStandardsNotMet */
    updateReasonsStandardsNotMetValidators() {
        if (this.currentFormData && this.currentFormData.status === FormStatus.InReview && this.currentFormData.standardsMet === YesNoOption.No) {
            this.form.controls.reasonsStandardsNotMet.setValidators(Validators.required);
        }
        else {
            this.form.controls.reasonsStandardsNotMet.clearValidators();
        }
        this.form.controls.reasonsStandardsNotMet.updateValueAndValidity();
    }
    /* Hydrates form with currentFormData values */
    afterFormDataLoaded() {
        super.afterFormDataLoaded();
        if (this.formType && this.currentFormData) {
            const title = this.formType.name + ' - ' + this.currentFormData.name;
            if (this.titleService.title.value !== title) {
                this.titleService.title.next(title);
            }
        }
        if (this.currentFormData) {
            this.form.controls.agentName.setValue(this.currentFormData.created.userName);
            this.form.controls.createdDate.setValue(moment(this.currentFormData.created.timestamp, 'x').format('L LT'));
            this.form.controls.status.setValue(this.currentFormData.status);
            this.form.controls.statusChangedDate.setValue(moment(this.currentFormData.statusChangeTimestamp, 'x').format('L LT'));
            this.form.controls.updatedDate.setValue(moment(this.currentFormData.updated.timestamp, 'x').format('L LT'));
            this.form.controls.referralName.setValue(this.currentFormData.name);
            this.form.controls.referralId.setValue(this.currentFormData.referralId);
            this.form.controls.referralAssignmentDate.setValue(this.currentFormData.referralAssignmentDate !== null ? moment(this.currentFormData.referralAssignmentDate, 'x') : null);
            this.form.controls.referralType.setValue(this.currentFormData.referralType);
            this.form.controls.responsePriority.setValue(this.currentFormData.responsePriority);
            this.form.controls.tribalAffiliation.setValue(this.currentFormData.tribalAffiliation);
            const tribalAffiliationType = [];
            if (this.currentFormData.tribalAffiliationTypeLocalTribe)
                tribalAffiliationType.push(Humboldt2020ReferralInvestigationTribalAffiliationTypeOption.LocalTribe);
            if (this.currentFormData.tribalAffiliationTypeNonLocalTribe)
                tribalAffiliationType.push(Humboldt2020ReferralInvestigationTribalAffiliationTypeOption.NonLocalTribe);
            this.form.controls.tribalAffiliationType.setValue(tribalAffiliationType);
            this.form.controls.numberOfChildrenInAllegationHousehold.setValue(this.currentFormData.numberOfChildrenInAllegationHousehold);
            this.form.controls.comments.setValue(this.currentFormData.comments);
            this.form.controls.initialSdmSafetyDecision.setValue(this.currentFormData.initialSdmSafetyDecision);
            this.form.controls.finalSdmSafetyDecision.setValue(this.currentFormData.finalSdmSafetyDecision);
            this.form.controls.finalSdmRiskLevel.setValue(this.currentFormData.finalSdmRiskLevel);
            const allegationConclusions = [];
            if (this.currentFormData.allegationConclusionSubstantiated)
                allegationConclusions.push(Humboldt2020ReferralInvestigationAllegationConclusionOption.Substantiated);
            if (this.currentFormData.allegationConclusionInconclusive)
                allegationConclusions.push(Humboldt2020ReferralInvestigationAllegationConclusionOption.Inconclusive);
            if (this.currentFormData.allegationConclusionUnfounded)
                allegationConclusions.push(Humboldt2020ReferralInvestigationAllegationConclusionOption.Unfounded);
            if (this.currentFormData.allegationConclusionPending)
                allegationConclusions.push(Humboldt2020ReferralInvestigationAllegationConclusionOption.Pending);
            this.form.controls.allegationConclusions.setValue(allegationConclusions);
            const referralOutcomes = [];
            if (this.currentFormData.referralOutcomeTransferToFamilyMaintenance)
                referralOutcomes.push(Humboldt2020ReferralInvestigationReferralOutcomeOption.TransferToFamilyMaintenance);
            if (this.currentFormData.referralOutcomeTransferToCourtFamilyMaintenance)
                referralOutcomes.push(Humboldt2020ReferralInvestigationReferralOutcomeOption.TransferToCourtFamilyMaintenance);
            if (this.currentFormData.referralOutcomeTransferToFamilyReunification)
                referralOutcomes.push(Humboldt2020ReferralInvestigationReferralOutcomeOption.TransferToFamilyReunification);
            if (this.currentFormData.referralOutcomeCloseNoFurtherAction)
                referralOutcomes.push(Humboldt2020ReferralInvestigationReferralOutcomeOption.CloseNoFurtherAction);
            if (this.currentFormData.referralOutcomePending)
                referralOutcomes.push(Humboldt2020ReferralInvestigationReferralOutcomeOption.Pending);
            this.form.controls.referralOutcomes.setValue(referralOutcomes);
            this.activityListFilterAndSortData();
            this.form.controls.standardsMet.setValue(this.currentFormData.standardsMet);
            const reasonsStandardsNotMet = [];
            if (this.currentFormData.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet)
                reasonsStandardsNotMet.push(Humboldt2020ReferralInvestigationReasonsStandardsNotMetOption.NumberOfContactsOtherStandardsNotMet);
            if (this.currentFormData.reasonsStandardsNotMetCaseworkerStoppedTracking)
                reasonsStandardsNotMet.push(Humboldt2020ReferralInvestigationReasonsStandardsNotMetOption.CaseworkerStoppedTracking);
            if (this.currentFormData.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded)
                reasonsStandardsNotMet.push(Humboldt2020ReferralInvestigationReasonsStandardsNotMetOption.NotAllWorkDuringMonthRecorded);
            if (this.currentFormData.reasonsStandardsNotMetOther)
                reasonsStandardsNotMet.push(Humboldt2020ReferralInvestigationReasonsStandardsNotMetOption.Other);
            this.form.controls.reasonsStandardsNotMet.setValue(reasonsStandardsNotMet);
            this.form.controls.supervisorComments.setValue(this.currentFormData.supervisorComments);
            this.updateTribalAffiliationTypeValidators();
            this.updateStandardsMetValidators();
            this.updateReasonsStandardsNotMetValidators();
            // See if the loaded data has a current activity
            // This should only happen if the loaded record is a current form and the user was in the middle of editing that dialog
            if (this.currentFormData.currentActivity) {
                this.editActivity(this.currentFormData.currentActivity);
            }
        }
    }
    loadFormTypeSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const settingsString = yield this.databaseService.getFormTypeSettings(this.formTypeId);
                if (settingsString) {
                    const settings = JSON.parse(settingsString);
                    if (settings.activityListFilters) {
                        this.activityListFilters = settings.activityListFilters;
                        this.activityListFilterForm.controls.activity.setValue(this.activityListFilters.activity);
                        const activitySorting = this.activityListSortOptions.filter(sorting => sorting.sortColumn === this.activityListFilters.sortColumn && sorting.sortDirection === this.activityListFilters.sortDirection);
                        if (activitySorting.length > 0) {
                            this.activityListFilterForm.controls.sorting.setValue(activitySorting[0]);
                        }
                        else {
                            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
                        }
                    }
                    else {
                        this.clearActivityListFilters();
                    }
                }
                else {
                    this.clearActivityListFilters();
                }
            }
            catch (error) {
                console.error('Error retrieving form type settings', error);
            }
        });
    }
    saveFormTypeSettings() {
        try {
            const settings = {
                activityListFilters: this.activityListFilters
            };
            this.databaseService.saveFormTypeSettings(this.formTypeId, JSON.stringify(settings));
        }
        catch (error) {
            console.error('Error saving form type settings', error);
        }
    }
    activityListFilterAndSortData() {
        let sortColumn = Humboldt2020ReferralInvestigationActivityListColumn.activityDate;
        let sortDirection = 'asc';
        if (this.activityListFilterForm.controls.sorting.value) {
            const sorting = this.activityListFilterForm.controls.sorting.value;
            sortColumn = sorting.sortColumn;
            sortDirection = sorting.sortDirection;
        }
        if (this.currentFormData && this.currentFormData.activities) {
            this.activitiesDataSource = this.currentFormData.activities.filter((activity) => {
                return !(this.activityListFilterForm.controls.activity.value && activity.activity !== this.activityListFilterForm.controls.activity.value);
            }).sort((activity1, activity2) => {
                switch (sortColumn) {
                    case Humboldt2020ReferralInvestigationActivityListColumn.activityDate:
                        if ((activity1.activityDate || 0) > (activity2.activityDate || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityDate || 0) < (activity2.activityDate || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ReferralInvestigationActivityListColumn.activity:
                        if ((activity1.activity || '') > (activity2.activity || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activity || '') < (activity2.activity || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ReferralInvestigationActivityListColumn.personInvolved:
                        if ((activity1.personInvolvedDisplay || '') > (activity2.personInvolvedDisplay || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.personInvolvedDisplay || '') < (activity2.personInvolvedDisplay || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ReferralInvestigationActivityListColumn.withReferralTeammate:
                        if ((activity1.withReferralTeammate || '') > (activity2.withReferralTeammate || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.withReferralTeammate || '') < (activity2.withReferralTeammate || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ReferralInvestigationActivityListColumn.method:
                        if ((activity1.method || '') > (activity2.method || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.method || '') < (activity2.method || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ReferralInvestigationActivityListColumn.place:
                        if ((activity1.place || '') > (activity2.place || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.place || '') < (activity2.place || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ReferralInvestigationActivityListColumn.activityMinutesSpent:
                        if ((activity1.activityMinutesSpent || 0) > (activity2.activityMinutesSpent || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityMinutesSpent || 0) < (activity2.activityMinutesSpent || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ReferralInvestigationActivityListColumn.travelMinutesSpent:
                        if ((activity1.travelMinutesSpent || 0) > (activity2.travelMinutesSpent || 0))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.travelMinutesSpent || 0) < (activity2.travelMinutesSpent || 0))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                    case Humboldt2020ReferralInvestigationActivityListColumn.activityDescription:
                        if ((activity1.activityDescription || '') > (activity2.activityDescription || ''))
                            return sortDirection === 'desc' ? -1 : 1;
                        if ((activity1.activityDescription || '') < (activity2.activityDescription || ''))
                            return sortDirection === 'desc' ? 1 : -1;
                        break;
                }
                // sensible defaults if the key doesn't exist, or if the initial result is a tie
                if ((activity1.activityDate || 0) < (activity2.activityDate || 0))
                    return -1;
                if ((activity1.activityDate || 0) > (activity2.activityDate || 0))
                    return 1;
                return 0;
            });
        }
        this.setActivityListFilterInfoString();
    }
    setActivityListFilterInfoString() {
        const info = [];
        if (this.activityListFilterForm.controls.activity.value) {
            info.push('Activity Filter: ' + this.activityListFilterForm.controls.activity.value);
        }
        if (this.activityListFilterForm.controls.sorting.value) {
            info.push('Sort: ' + this.activityListFilterForm.controls.sorting.value.display);
        }
        this.activityListFilterInfoString = info.join(', ');
    }
    clearActivityListFilters() {
        if (this.activityListFilterForm.controls.activity.value !== null)
            this.activityListFilterForm.controls.activity.setValue(null);
        if (this.activityListFilterForm.controls.sorting.value !== this.activityListSortOptions[0])
            this.activityListFilterForm.controls.sorting.setValue(this.activityListSortOptions[0]);
    }
    openActivityListFilterDialog() {
        const dialogData = {
            filterForm: this.activityListFilterForm,
            clearFunction: this.clearActivityListFilters.bind(this),
            activityOptions: this.activityOptions,
            sortOptions: this.activityListSortOptions
        };
        this.dialog.open(Humboldt2020ReferralInvestigationActivityListFilterDialogComponent, {
            data: dialogData,
            width: '800px'
        });
    }
    /**
     * update the form filter when the table headers are clicked to keep them in sync
     */
    onActivityListSortChange(sort) {
        const sortingValue = this.activityListSortOptions.filter(sortOption => sortOption.sortColumn === sort.active && sortOption.sortDirection === (sort.direction || 'asc'));
        if (sortingValue.length > 0 && sortingValue[0] !== this.activityListFilterForm.controls.sorting.value) {
            this.activityListFilterForm.controls.sorting.setValue(sortingValue[0]);
        }
    }
    editActivity(activity) {
        const dialogData = {
            activity: activity,
            updateCurrentActivity: this.updateCurrentActivity.bind(this)
        };
        this.dialog.open(Humboldt2020ReferralInvestigationActivityDialogComponent, {
            data: dialogData,
            disableClose: true,
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (this.currentFormData) {
                if (result) {
                    if (result.index !== null) {
                        this.currentFormData.activities[result.index] = result;
                    }
                    else {
                        this.currentFormData.activities.push(result);
                    }
                    this.currentFormData.activities.forEach((activity, index) => activity.index = index);
                    this.activityListFilterAndSortData();
                }
                this.currentFormData.currentActivity = null;
                this.saveCurrentFormValues();
            }
        });
    }
    updateCurrentActivity(activity) {
        if (this.currentFormData) {
            this.currentFormData.currentActivity = activity;
            this.saveCurrentFormValues();
        }
    }
    deleteActivity(activity) {
        this.dialog.open(MessageDialogComponent, {
            data: {
                title: 'Delete Activity',
                message: 'Deleting this activity is irreversible.' +
                    ' Once deleted any information you have entered for the activity will be gone forever.' +
                    ' Are you sure you want to do this?',
                okButtonLabel: 'Delete Activity'
            },
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (this.currentFormData && result && activity.index !== null) {
                this.currentFormData.activities.splice(activity.index, 1);
                this.currentFormData.activities.forEach((activity, index) => activity.index = index);
                this.activityListFilterAndSortData();
                this.saveCurrentFormValues();
            }
        });
    }
}
