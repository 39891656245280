export enum Humboldt2020CourtIntakeActivityActivityOption {
  InterviewAssessConsult = 'Interview/assess/consult',
  ReadClets = 'Read CLETS',
  DocumentationReview = 'Documentation review',
  DocumentationContacts = 'Documentation (contacts)',
  CourtReportWriting = 'Court report writing',
  ReferralCaseConferenceReviewStaffing = 'Referral/case conference/review/staffing',
  WarrantSigning = 'Warrant signing',
  Other = 'Other'
}
