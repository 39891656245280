export enum Doc2019OffenderSupervisionTimeOffenderCaseClassificationOption {
  Gps1 = 'GPS 1',
  Gps2 = 'GPS 2',
  Gps3 = 'GPS 3',
  Enhanced = 'Enhanced',
  IntensiveSexOffender = 'Intensive Sex Offender',
  Maximum = 'Maximum',
  Medium = 'Medium',
  Minimum = 'Minimum',
  OutOfState = 'Out of State'
}
