import { Humboldt2020OngoingEfcAdoptActivityActivityOption } from './humboldt2020-ongoing-efc-adopt-activity-dialog/humboldt2020-ongoing-efc-adopt-activity-activity-option.enum';
import { Humboldt2020OngoingEfcAdoptActivityMethodOption } from './humboldt2020-ongoing-efc-adopt-activity-dialog/humboldt2020-ongoing-efc-adopt-activity-method-option.enum';
import { Humboldt2020OngoingEfcAdoptActivityPlaceOption } from './humboldt2020-ongoing-efc-adopt-activity-dialog/humboldt2020-ongoing-efc-adopt-activity-place-option.enum';
import { Humboldt2020OngoingEfcAdoptActivityPersonInvolvedOption } from './humboldt2020-ongoing-efc-adopt-activity-dialog/humboldt2020-ongoing-efc-adopt-activity-person-involved-option.enum';
import { YesNoOption } from '../../../form/yes-no-option.enum';

export interface Humboldt2020OngoingEfcAdoptActivityData {
  index: number | null; // Used to store the index when passing data to the dialog
  activityDate: number | null; // unix timestamp in milliseconds
  activity: Humboldt2020OngoingEfcAdoptActivityActivityOption | null;
  relatedToAdoptionFinalization: YesNoOption | null;
  personInvolvedDisplay: string | null;
  personInvolvedYouth: boolean;
  personInvolvedParentGuardian: boolean;
  personInvolvedTribe: boolean;
  personInvolvedSubstituteCareProvider: boolean;
  personInvolvedCollateral: boolean;
  personInvolvedOtherCwsStaff: boolean;
  personInvolvedSupervisor: boolean;
  personInvolvedOther: boolean;
  personInvolvedNone: boolean;
  method: Humboldt2020OngoingEfcAdoptActivityMethodOption | null;
  place: Humboldt2020OngoingEfcAdoptActivityPlaceOption | null;
  activityMinutesSpent: number | null;
  travelMinutesSpent: number | null;
  activityDescription: string | null;
}

export function getActivityPersonInvolvedArray(activity: Humboldt2020OngoingEfcAdoptActivityData): string[] {
  const values: Array<string> = [];
  if (activity.personInvolvedYouth) {
    values.push(Humboldt2020OngoingEfcAdoptActivityPersonInvolvedOption.Youth);
  }
  if (activity.personInvolvedParentGuardian) {
    values.push(Humboldt2020OngoingEfcAdoptActivityPersonInvolvedOption.ParentGuardian);
  }
  if (activity.personInvolvedTribe) {
    values.push(Humboldt2020OngoingEfcAdoptActivityPersonInvolvedOption.Tribe);
  }
  if (activity.personInvolvedSubstituteCareProvider) {
    values.push(Humboldt2020OngoingEfcAdoptActivityPersonInvolvedOption.SubstituteCareProvider);
  }
  if (activity.personInvolvedCollateral) {
    values.push(Humboldt2020OngoingEfcAdoptActivityPersonInvolvedOption.Collateral);
  }
  if (activity.personInvolvedOtherCwsStaff) {
    values.push(Humboldt2020OngoingEfcAdoptActivityPersonInvolvedOption.OtherCwsStaff);
  }
  if (activity.personInvolvedSupervisor) {
    values.push(Humboldt2020OngoingEfcAdoptActivityPersonInvolvedOption.Supervisor);
  }
  if (activity.personInvolvedOther) {
    values.push(Humboldt2020OngoingEfcAdoptActivityPersonInvolvedOption.Other);
  }
  if (activity.personInvolvedNone) {
    values.push(Humboldt2020OngoingEfcAdoptActivityPersonInvolvedOption.None);
  }
  return values;
}
