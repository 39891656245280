import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProcessingDialogData } from './processing-dialog-data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-processing-dialog',
  templateUrl: './processing-dialog.component.html',
  styleUrls: ['./processing-dialog.component.css']
})
export class ProcessingDialogComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  message: string | null = null;
  errored = false;

  constructor(
    private dialogRef: MatDialogRef<ProcessingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProcessingDialogData
  ) {
    if (!this.data.errorCloseButtonLabel) {
      this.data.errorCloseButtonLabel = 'Close';
    }
    this.data.message.pipe(takeUntil(this.unsubscribe$)).subscribe(message => {
      if (!this.errored) {
        this.message = message;
      }
    }, error => {
      this.errored = true;
      this.message = error;

      if (this.data.title) {
        if (this.data.title.toLowerCase().indexOf('error') === -1) {
          this.data.title += ' ' + 'Error';
        }
      } else {
        this.data.title = 'Error';
      }
    }, () => {
      this.close();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  additionalButtonClick(): void {
    this.dialogRef.close();
    if (this.data.additionalButtonAction && this.data.additionalButtonAction.value !== null) {
      this.data.additionalButtonAction.value();
    }
  }
}
