<h1 mat-dialog-title>Form List Options</h1>
<div mat-dialog-content>
  <form class="filter-form" [formGroup]="data.filterForm">
    <mat-form-field *ngIf="data.user?.role === UserRole.NccdAdmin">
      <mat-label>Project</mat-label>
      <mat-select formControlName="projectId">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let project of data.projectOptions" [value]="project.id">{{project.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="data.user?.role === UserRole.Supervisor">
      <mat-label>Created By</mat-label>
      <mat-select formControlName="createdUserId">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let userSupervised of data.user.usersSupervised" [value]="userSupervised.id">{{userSupervised.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="data.user?.role === UserRole.ProjectAdmin || data.user?.role === UserRole.NccdAdmin">
      <mat-label>Created By</mat-label>
      <input matInput type="text" formControlName="createdUserName" (keydown.enter)="$event.target.blur()" autocomplete="off">
    </mat-form-field>

    <mat-form-field *ngIf="data.user?.role === UserRole.NccdAdmin">
      <mat-label>Form Type</mat-label>
      <mat-select formControlName="formTypeId">
        <mat-option [value]="null"></mat-option>
        <mat-optgroup *ngFor="let project of data.projectOptions" [label]="project.name">
          <mat-option *ngFor="let form of project.forms" [value]="form.id">{{form.name}}</mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="data.user?.role !== UserRole.NccdAdmin">
      <mat-label>Form Type</mat-label>
      <mat-select formControlName="formTypeId">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let formType of data.formTypes" [value]="formType.id">{{formType.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Case Type</mat-label>
      <mat-select formControlName="caseType">
        <mat-option [value]="null"></mat-option>
        <mat-optgroup *ngFor="let formType of data.caseTypes" [label]="formType.name">
          <mat-option *ngFor="let caseType of formType.caseTypes" [value]="caseType">{{caseType}}</mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" (keydown.enter)="$event.target.blur()" autocomplete="off">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let formStatus of data.formStatuses" [value]="formStatus">{{formStatus}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Sorting</mat-label>
      <mat-select formControlName="sorting">
        <mat-option *ngFor="let sort of data.sortOptions" [value]="sort">{{sort.display}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Close</button>
  <button mat-button (click)="clear()">Clear</button>
</div>
