import { Humboldt2020ResourceFamilyApprovalActivityActivityOption } from './humboldt2020-resource-family-approval-activity-dialog/humboldt2020-resource-family-approval-activity-activity-option.enum';
import { Humboldt2020ResourceFamilyApprovalActivityMethodOption } from './humboldt2020-resource-family-approval-activity-dialog/humboldt2020-resource-family-approval-activity-method-option.enum';
import { Humboldt2020ResourceFamilyApprovalActivityPlaceOption } from './humboldt2020-resource-family-approval-activity-dialog/humboldt2020-resource-family-approval-activity-place-option.enum';
import { Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption } from './humboldt2020-resource-family-approval-activity-dialog/humboldt2020-resource-family-approval-activity-person-involved-option.enum';

export interface Humboldt2020ResourceFamilyApprovalActivityData {
  index: number | null; // Used to store the index when passing data to the dialog
  activityDate: number | null; // unix timestamp in milliseconds
  activity: Humboldt2020ResourceFamilyApprovalActivityActivityOption | null;
  personInvolvedDisplay: string | null;
  personInvolvedResourceFamily: boolean;
  personInvolvedHouseholdMember: boolean;
  personInvolvedRegularlyPresentAdult: boolean;
  personInvolvedYouthInHome: boolean;
  personInvolvedCollateralCommunity: boolean;
  personInvolvedSupervisor: boolean;
  personInvolvedOtherCwsStaff: boolean;
  personInvolvedTribe: boolean;
  personInvolvedLegalConsultant: boolean;
  personInvolvedOther: boolean;
  personInvolvedNone: boolean;
  method: Humboldt2020ResourceFamilyApprovalActivityMethodOption | null;
  place: Humboldt2020ResourceFamilyApprovalActivityPlaceOption | null;
  activityMinutesSpent: number | null;
  travelMinutesSpent: number | null;
  activityDescription: string | null;
}

export function getActivityPersonInvolvedArray(activity: Humboldt2020ResourceFamilyApprovalActivityData): string[] {
  const values: Array<string> = [];
  if (activity.personInvolvedResourceFamily) {
    values.push(Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.ResourceFamily);
  }
  if (activity.personInvolvedHouseholdMember) {
    values.push(Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.HouseholdMember);
  }
  if (activity.personInvolvedRegularlyPresentAdult) {
    values.push(Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.RegularlyPresentAdult);
  }
  if (activity.personInvolvedYouthInHome) {
    values.push(Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.YouthInHome);
  }
  if (activity.personInvolvedCollateralCommunity) {
    values.push(Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.CollateralCommunity);
  }
  if (activity.personInvolvedSupervisor) {
    values.push(Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.Supervisor);
  }
  if (activity.personInvolvedOtherCwsStaff) {
    values.push(Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.OtherCwsStaff);
  }
  if (activity.personInvolvedTribe) {
    values.push(Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.Tribe);
  }
  if (activity.personInvolvedLegalConsultant) {
    values.push(Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.LegalConsultant);
  }
  if (activity.personInvolvedOther) {
    values.push(Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.Other);
  }
  if (activity.personInvolvedNone) {
    values.push(Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption.None);
  }
  return values;
}
