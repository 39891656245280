import { Doc2019OffenderSupervisionTimeOffenderCaseClassificationOption } from './doc2019/doc2019-offender-supervision-time/doc2019-offender-supervision-time-offender-case-classification-option.enum';
import { Doc2019FieldInvestigationTimeInvestigationTypeOption } from './doc2019/doc2019-field-investigation-time/doc2019-field-investigation-time-investigation-type-option.enum';
import { Humboldt2020CourtIntakeCourtReportTypeOption } from './humboldt2020/humboldt2020-court-intake/humboldt2020-court-intake-court-report-type-option.enum';
import { Humboldt2020IntakeScreeningReferralResponseTypeOption } from './humboldt2020/humboldt2020-intake-screening/humboldt2020-intake-screening-referral-response-type-option.enum';
import { Humboldt2020ReferralInvestigationReferralTypeOption } from './humboldt2020/humboldt2020-referral-investigation/humboldt2020-referral-investigation-referral-type-option.enum';
import { Humboldt2020OngoingEfcAdoptStudyCaseTypeOption } from './humboldt2020/humboldt2020-ongoing-efc-adopt/humboldt2020-ongoing-efc-adopt-study-case-type-option.enum';
import { Humboldt2020PlacementStudyCaseTypeOption } from './humboldt2020/humboldt2020-placement/humboldt2020-placement-study-case-type-option.enum';
import { Humboldt2020ResourceFamilyApprovalAssignmentTypeOption } from './humboldt2020/humboldt2020-resource-family-approval/humboldt2020-resource-family-approval-assignment-type-option.enum';
import { Humboldt2020IlsStudyCaseTypeOption } from './humboldt2020/humboldt2020-ils/humboldt2020-ils-study-case-type-option.enum';
import { Mddjs2020IntakeCaseTypeOption } from './mddjs2020/mddjs2020-intake/mddjs2020-intake-case-type-option.enum';
import { Mddjs2020SupervisionNewCaseTypeOption } from './mddjs2020/mddjs2020-supervision/mddjs2020-supervision-new-case-type-option.enum';
import { Mddjs2020SupervisionOngoingCaseStatusOption } from './mddjs2020/mddjs2020-supervision/mddjs2020-supervision-ongoing-case-status-option.enum';
import { Mddjs2020CdEmCaseTypeOption } from './mddjs2020/mddjs2020-cd-em/mddjs2020-cd-em-case-type-option.enum';

export interface FormTypeData {
  id: number;
  name: string;
  projectId: number;
  restrictToOneInProgressWarning: string | null;
  caseTypes: string[];
}

const doc2019SupportRestrictWarning = 'You already have a form of type "Case Support and Non-Case Related Time" with a status of "In Progress".' +
  ' Are you sure you would like to create another one?';
const humboldt2020SupportRestrictWarning = 'STOP! You are creating a duplicate support and administrative time form;' +
  ' only one form per caseworker should be submitted. Please make sure all support and administrative time is recorded' +
  ' on one form and delete any duplicates you may have created.';
const mddjs2020SupervisionCaseTypes = Object.values(Mddjs2020SupervisionNewCaseTypeOption)
  .filter(value => value !== Mddjs2020SupervisionNewCaseTypeOption.NotApplicableOngoingCase)
  .concat(Object.values(Mddjs2020SupervisionOngoingCaseStatusOption));
const mddjs2020CaseSupportRestrictWarning = 'STOP! You are creating a duplicate Case Support and Administrative Activity form.' +
  ' Please make sure all case support and administrative time is recorded on one form and delete any duplicates you may have created.';
const mddjs2020CdFloatingRestrictWarning = 'STOP! You are creating a duplicate CD/EM Floating form.' +
  ' Please make sure all time is recorded on one form and delete any duplicates you may have created.';

const FORM_TYPES: FormTypeData[] = [
  {id: 1, name: 'Case Support and Non-Case Related Time', projectId: 1, restrictToOneInProgressWarning: doc2019SupportRestrictWarning, caseTypes: []},
  {id: 2, name: 'Intake/Investigation Time', projectId: 1, restrictToOneInProgressWarning: null, caseTypes: Object.values(Doc2019FieldInvestigationTimeInvestigationTypeOption)},
  {id: 3, name: 'Offender Supervision Time', projectId: 1, restrictToOneInProgressWarning: null, caseTypes: Object.values(Doc2019OffenderSupervisionTimeOffenderCaseClassificationOption)},
  {id: 4, name: 'Court Intake', projectId: 2, restrictToOneInProgressWarning: null, caseTypes: Object.values(Humboldt2020CourtIntakeCourtReportTypeOption)},
  {id: 5, name: 'Intake', projectId: 2, restrictToOneInProgressWarning: null, caseTypes: Object.values(Humboldt2020IntakeScreeningReferralResponseTypeOption)},
  {id: 6, name: 'Referral', projectId: 2, restrictToOneInProgressWarning: null, caseTypes: Object.values(Humboldt2020ReferralInvestigationReferralTypeOption)},
  {id: 7, name: 'Ongoing', projectId: 2, restrictToOneInProgressWarning: null, caseTypes: Object.values(Humboldt2020OngoingEfcAdoptStudyCaseTypeOption)},
  {id: 8, name: 'Placement', projectId: 2, restrictToOneInProgressWarning: null, caseTypes: Object.values(Humboldt2020PlacementStudyCaseTypeOption)},
  {id: 9, name: 'Resource Family Approval (RFA)', projectId: 2, restrictToOneInProgressWarning: null, caseTypes: Object.values(Humboldt2020ResourceFamilyApprovalAssignmentTypeOption)},
  {id: 10, name: 'Support and Administrative Activity', projectId: 2, restrictToOneInProgressWarning: humboldt2020SupportRestrictWarning, caseTypes: []},
  {id: 11, name: 'ILS', projectId: 2, restrictToOneInProgressWarning: null, caseTypes: Object.values(Humboldt2020IlsStudyCaseTypeOption)},
  {id: 12, name: 'Intake and Investigation', projectId: 3, restrictToOneInProgressWarning: null, caseTypes: Object.values(Mddjs2020IntakeCaseTypeOption)},
  {id: 13, name: 'Supervision', projectId: 3, restrictToOneInProgressWarning: null, caseTypes: mddjs2020SupervisionCaseTypes},
  {id: 14, name: 'Case Support and Administrative Activity', projectId: 3, restrictToOneInProgressWarning: mddjs2020CaseSupportRestrictWarning, caseTypes: []},
  {id: 15, name: 'CD/EM', projectId: 3, restrictToOneInProgressWarning: null, caseTypes: Object.values(Mddjs2020CdEmCaseTypeOption)},
  {id: 16, name: 'CD/EM Floating', projectId: 3, restrictToOneInProgressWarning: mddjs2020CdFloatingRestrictWarning, caseTypes: []}
];

/* get the form type data for a particular id */
export function getFormTypeById(id: number): FormTypeData | null {
  const filtered = FORM_TYPES.filter(form => form.id === id);
  return filtered.length === 1 ? filtered[0] : null;
}

/* get all the form types for a particular project */
export function getFormTypesByProjectId(projectId: number): FormTypeData[] {
  return FORM_TYPES.filter(formType => formType.projectId === projectId).sort(sortFormTypesByName);
}

/* get all the form types for a particular project that also have caseTypes */
export function getFormTypesByProjectIdWithCaseTypes(projectId: number): FormTypeData[] {
  return FORM_TYPES.filter(formType => formType.projectId === projectId && formType.caseTypes.length > 0).sort(sortFormTypesByName);
}

export function sortFormTypesByName(form1: FormTypeData, form2: FormTypeData) {
  if (form1.name < form2.name) {
    return -1;
  }
  if (form1.name > form2.name) {
    return 1;
  }
  return 0;
}
