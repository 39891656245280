import { Mddjs2020SupervisionActivityActivityOption } from './mddjs2020-supervision-activity-dialog/mddjs2020-supervision-activity-activity-option.enum';
import { Mddjs2020SupervisionActivityMethodOption } from './mddjs2020-supervision-activity-dialog/mddjs2020-supervision-activity-method-option.enum';
import { Mddjs2020SupervisionActivityPlaceOption } from './mddjs2020-supervision-activity-dialog/mddjs2020-supervision-activity-place-option.enum';
import { Mddjs2020SupervisionActivityPersonOption } from './mddjs2020-supervision-activity-dialog/mddjs2020-supervision-activity-person-option.enum';

export interface Mddjs2020SupervisionActivityData {
  index: number | null; // Used to store the index when passing data to the dialog
  activityDate: number | null; // unix timestamp in milliseconds
  activity: Mddjs2020SupervisionActivityActivityOption | null;
  personDisplay: string | null;
  personYouth: boolean;
  personParentGuardianCaretaker: boolean;
  personOtherDjsStaff: boolean;
  personServiceProvider: boolean;
  personDssStaff: boolean;
  personOtherCollateral: boolean;
  personNone: boolean;
  method: Mddjs2020SupervisionActivityMethodOption | null;
  place: Mddjs2020SupervisionActivityPlaceOption | null;
  activityMinutes: number | null;
  travelMinutes: number | null;
  waitingMinutes: number | null;
  activityDescription: string | null;
}

export function getActivityPersonArray(activity: Mddjs2020SupervisionActivityData): string[] {
  const values: Array<string> = [];
  if (activity.personYouth) {
    values.push(Mddjs2020SupervisionActivityPersonOption.Youth);
  }
  if (activity.personParentGuardianCaretaker) {
    values.push(Mddjs2020SupervisionActivityPersonOption.ParentGuardianCaretaker);
  }
  if (activity.personOtherDjsStaff) {
    values.push(Mddjs2020SupervisionActivityPersonOption.OtherDjsStaff);
  }
  if (activity.personServiceProvider) {
    values.push(Mddjs2020SupervisionActivityPersonOption.ServiceProvider);
  }
  if (activity.personDssStaff) {
    values.push(Mddjs2020SupervisionActivityPersonOption.DssStaff);
  }
  if (activity.personOtherCollateral) {
    values.push(Mddjs2020SupervisionActivityPersonOption.OtherCollateral);
  }
  if (activity.personNone) {
    values.push(Mddjs2020SupervisionActivityPersonOption.None);
  }
  return values;
}
