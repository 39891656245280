import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Humboldt2020IntakeScreeningActivityListFilterDialogData } from './humboldt2020-intake-screening-activity-list-filter-dialog-data';

@Component({
  selector: 'app-humboldt2020-intake-screening-activity-list-filter-dialog',
  templateUrl: './humboldt2020-intake-screening-activity-list-filter-dialog.component.html',
  styleUrls: ['./humboldt2020-intake-screening-activity-list-filter-dialog.component.css']
})
export class Humboldt2020IntakeScreeningActivityListFilterDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<Humboldt2020IntakeScreeningActivityListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Humboldt2020IntakeScreeningActivityListFilterDialogData
  ) { }

  clear(): void {
    this.data.clearFunction();
  }

  close(): void {
    this.dialogRef.close();
  }
}
