<h2 class="warning-color production-warning" *ngIf="productionWarning">
  You are on the test site. Your project is in production.
  Please go to <a href="https://workload.nccdglobal.org">workload.nccdglobal.org</a>
</h2>

<h2 *ngIf="isLargeDisplay">Filter</h2>

<form class="filter-form" [formGroup]="filterForm" *ngIf="isLargeDisplay">
  <mat-form-field *ngIf="user?.role === UserRole.NccdAdmin">
    <mat-label>Project</mat-label>
    <mat-select formControlName="projectId">
      <mat-option [value]="null"></mat-option>
      <mat-option *ngFor="let project of filterOptionsProjects" [value]="project.id">{{project.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="user?.role === UserRole.Supervisor">
    <mat-label>Created By</mat-label>
    <mat-select formControlName="createdUserId">
      <mat-option [value]="null"></mat-option>
      <mat-option *ngFor="let userSupervised of user.usersSupervised" [value]="userSupervised.id">{{userSupervised.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="user?.role === UserRole.ProjectAdmin || user?.role === UserRole.NccdAdmin">
    <mat-label>Created By</mat-label>
    <input matInput type="text" formControlName="createdUserName" (keydown.enter)="$event.target.blur()" autocomplete="off">
  </mat-form-field>

  <mat-form-field *ngIf="user?.role === UserRole.NccdAdmin">
    <mat-label>Form Type</mat-label>
    <mat-select formControlName="formTypeId">
      <mat-option [value]="null"></mat-option>
      <mat-optgroup *ngFor="let project of filterOptionsProjects" [label]="project.name">
        <mat-option *ngFor="let form of project.forms" [value]="form.id">{{form.name}}</mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="user?.role !== UserRole.NccdAdmin">
    <mat-label>Form Type</mat-label>
    <mat-select formControlName="formTypeId">
      <mat-option [value]="null"></mat-option>
      <mat-option *ngFor="let formType of filterOptionsFormTypes" [value]="formType.id">{{formType.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Case Type</mat-label>
    <mat-select formControlName="caseType">
      <mat-option [value]="null"></mat-option>
      <mat-optgroup *ngFor="let formType of filterOptionsCaseTypes" [label]="formType.name">
        <mat-option *ngFor="let caseType of formType.caseTypes" [value]="caseType">{{caseType}}</mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput type="text" formControlName="name" (keydown.enter)="$event.target.blur()" autocomplete="off">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Status</mat-label>
    <mat-select formControlName="status">
      <mat-option [value]="null"></mat-option>
      <mat-option *ngFor="let formStatus of filterOptionsFormStatuses" [value]="formStatus">{{formStatus}}</mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-icon-button aria-label="Clear Filters" (click)="clearFilters()"><mat-icon>clear</mat-icon></button>
</form>

<mat-toolbar class="small-display-info-string-section mat-elevation-z3" *ngIf="isSmallDisplay" (click)="openFilterDialog()">
  <button mat-icon-button aria-label="Filters">
    <mat-icon>filter_list</mat-icon>
  </button>
  <div class="filter-info-string mat-small">{{ filterInfoString }}</div>
</mat-toolbar>

<h2 *ngIf="isLargeDisplay">Sampled Caseload</h2>

<table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)" *ngIf="isLargeDisplay">
  <ng-container [matColumnDef]="formListColumnEnum.name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let form">{{ form.name }}</td>
  </ng-container>

  <ng-container [matColumnDef]="formListColumnEnum.formTypeName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Form</th>
    <td mat-cell *matCellDef="let form">{{ form.formType.name }}</td>
  </ng-container>

  <ng-container [matColumnDef]="formListColumnEnum.caseType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Case Type</th>
    <td mat-cell *matCellDef="let form">{{ form.caseType }}</td>
  </ng-container>

  <ng-container [matColumnDef]="formListColumnEnum.createdUserName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
    <td mat-cell *matCellDef="let form">{{ form.created.userName }}</td>
  </ng-container>

  <ng-container [matColumnDef]="formListColumnEnum.createdTimestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
    <td mat-cell *matCellDef="let form">{{ form.created.timestamp | date:'short' }}</td>
  </ng-container>

  <ng-container [matColumnDef]="formListColumnEnum.status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let form">{{ form.status }}</td>
  </ng-container>

  <ng-container [matColumnDef]="formListColumnEnum.statusChangeTimestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status Changed</th>
    <td mat-cell *matCellDef="let form">{{ form.statusChangeTimestamp | date:'short' }}</td>
  </ng-container>

  <ng-container [matColumnDef]="formListColumnEnum.offlineSaveTimestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Offline Saved</th>
    <td mat-cell *matCellDef="let form">{{ form.offlineSaveTimestamp | date:'short' }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let form; columns: displayedColumns;" (click)="openForm(form)"></tr>
</table>

<mat-nav-list *ngIf="isSmallDisplay && (user?.role !== UserRole.Agent)">
  <a mat-list-item *ngFor="let form of dataSource" (click)="openForm(form)">
    <h2 matLine>{{ form.created.userName }}</h2>
    <div matLine>{{ form.formType.name }}</div>
    <div matLine>{{ form.name }}</div>
    <div matLine *ngIf="form.caseType">{{ form.caseType }}</div>
    <div matLine>{{ form.status }} {{ form.statusChangeTimestamp | date:'short' }}</div>
    <div matLine *ngIf="form.offlineSaveTimestamp">Offline Save {{ form.offlineSaveTimestamp | date:'short' }}</div>
    <mat-icon>navigate_next</mat-icon>
  </a>
</mat-nav-list>

<mat-nav-list *ngIf="isSmallDisplay && (user?.role === UserRole.Agent)">
  <a mat-list-item *ngFor="let form of dataSource" (click)="openForm(form)">
    <h2 matLine>{{ form.name }}</h2>
    <div matLine>{{ form.formType.name }}</div>
    <div matLine *ngIf="form.caseType">{{ form.caseType }}</div>
    <div matLine>Created {{ form.created.timestamp | date:'short' }}</div>
    <div matLine *ngIf="showStatus">{{ form.status }} {{ form.statusChangeTimestamp | date:'short' }}</div>
    <div matLine *ngIf="form.offlineSaveTimestamp">Offline Save {{ form.offlineSaveTimestamp | date:'short' }}</div>
    <mat-icon>navigate_next</mat-icon>
  </a>
</mat-nav-list>

<div class="fab-button-padding"></div>
<div class="lower-right-action">
  <button mat-fab [mat-menu-trigger-for]="newFormMenu" *ngIf="user?.role === UserRole.Agent && user?.createFormTypes.length > 0"><mat-icon>note_add</mat-icon></button>
  <button mat-mini-fab [mat-menu-trigger-for]="newFormMenu" *ngIf="user?.role === UserRole.Agent && user?.createFormTypes.length > 0"><mat-icon>note_add</mat-icon></button>
  <mat-menu #newFormMenu="matMenu" class="form-list-new-form-menu" xPosition="before">
    <a mat-menu-item *ngFor="let formType of user.createFormTypes" (click)="createNewForm(formType)">{{formType.name}}</a>
  </mat-menu>
</div>

<mat-toolbar color="primary" class="offline-warning mat-elevation-z2" *ngIf="user?.role === UserRole.Agent || user?.role === UserRole.Supervisor">
  <button mat-button *ngIf="!isSyncing && !(onlineService.online | async)"><mat-icon>signal_wifi_off</mat-icon> Offline</button>
  <button mat-button *ngIf="!isSyncing && (onlineService.online | async)" (click)="getListFromServerForAgentOrSupervisor(false)"><mat-icon>refresh</mat-icon>Reload</button>
  <div class="mat-small" *ngIf="!isSyncing && formListFilters?.listLastLoaded">List last loaded {{ formListFilters?.listLastLoaded | date:'short' }}</div>
  <mat-progress-spinner mode="indeterminate" diameter="20" *ngIf="isSyncing"></mat-progress-spinner>
  <div class="mat-small" *ngIf="isSyncing">Syncing Data From Server</div>
</mat-toolbar>

<mat-toolbar color="primary" class="admin-navigation-bar mat-elevation-z2" *ngIf="user?.role === UserRole.ProjectAdmin || user?.role === UserRole.NccdAdmin">
  <button class="admin-navigation-button" mat-button (click)="firstAdminPage()"><mat-icon>first_page</mat-icon></button>
  <button class="admin-navigation-button" mat-button (click)="prevAdminPage()"><mat-icon>chevron_left</mat-icon></button>
  <div class="mat-small admin-navigation-message">{{ adminPaginationMessage }}</div>
  <button class="admin-navigation-button" mat-button (click)="nextAdminPage()"><mat-icon>chevron_right</mat-icon></button>
  <button class="admin-navigation-button" mat-button (click)="lastAdminPage()"><mat-icon>last_page</mat-icon></button>
</mat-toolbar>

