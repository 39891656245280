<form [formGroup]="form">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab label="Case Information">
      <padded-content>
        <mat-form-field *ngIf="user?.role !== UserRole.Agent">
          <mat-label>Agent Name</mat-label>
          <input matInput type="text" formControlName="agentName" readonly>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Created</mat-label>
          <input matInput formControlName="createdDate" readonly>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Status</mat-label>
          <input matInput formControlName="status" readonly>
        </mat-form-field>

        <mat-form-field *ngIf="user?.role !== UserRole.Agent">
          <mat-label>Status Changed</mat-label>
          <input matInput formControlName="statusChangedDate" readonly>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Last Updated</mat-label>
          <input matInput formControlName="updatedDate" readonly>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Offender Name</mat-label>
          <input #offenderName matInput type="text" maxlength="200" formControlName="offenderName">
          <mat-hint align="end">{{offenderName.value.length}} / 200</mat-hint>
          <mat-error *ngIf="form.controls.offenderName.invalid">{{errorMessage(form.controls.offenderName)}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Offender Id</mat-label>
          <input #offenderId matInput type="text" maxlength="9" formControlName="offenderId" inputmode="numeric" pattern="[0-9]*" digitOnly>
          <mat-hint align="end">{{offenderId.value.length}} / 9</mat-hint>
          <mat-error *ngIf="form.controls.offenderId.invalid">{{errorMessage(form.controls.offenderId)}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Type of Investigation or Intake</mat-label>
          <mat-select formControlName="investigationType">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of investigationTypeOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.investigationType.invalid">{{errorMessage(form.controls.investigationType)}}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="currentFormData?.investigationType === investigationTypeOptionEnum.IntakeGeneral">
          <mat-label>Intake General Status</mat-label>
          <mat-select formControlName="intakeGeneralStatus">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of intakeGeneralStatusOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.intakeGeneralStatus.invalid">{{errorMessage(form.controls.intakeGeneralStatus)}}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="currentFormData?.investigationType === investigationTypeOptionEnum.Revocation">
          <mat-label>Revocation hearing waived?</mat-label>
          <mat-select formControlName="revocationHearingWaived">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of yesNoOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.revocationHearingWaived.invalid">{{errorMessage(form.controls.revocationHearingWaived)}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Do any of the following programs apply?</mat-label>
          <mat-select formControlName="programs" multiple>
            <mat-option *ngFor="let option of programsOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.programs.invalid">{{errorMessage(form.controls.programs)}}</mat-error>
        </mat-form-field>
      </padded-content>
    </mat-tab>
    <mat-tab label="Activities">
      <div class="filter-form" formGroupName="activityListFilter" *ngIf="isLargeDisplay">
        <mat-form-field>
          <mat-label>Activity Filter</mat-label>
          <mat-select formControlName="activity">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let activity of activityOptions" [value]="activity">{{activity}}</mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-icon-button aria-label="Clear Filters" (click)="clearActivityListFilters()"><mat-icon>clear</mat-icon></button>
      </div>

      <mat-toolbar class="small-display-info-string-section mat-elevation-z3" *ngIf="isSmallDisplay" (click)="openActivityListFilterDialog()">
        <button mat-icon-button aria-label="Filters">
          <mat-icon>filter_list</mat-icon>
        </button>
        <div class="filter-info-string mat-small">{{ activityListFilterInfoString }}</div>
      </mat-toolbar>

      <table class="activity-table" mat-table #activityTable matSort [dataSource]="activitiesDataSource" (matSortChange)="onActivityListSortChange($event)" *ngIf="isLargeDisplay">
        <ng-container [matColumnDef]="activityListColumnEnum.action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let activity" class="action-column-cell">
            <button mat-icon-button color="primary" aria-label="Edit Activity" (click)="editActivity(activity)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="primary" aria-label="Delete Activity" (click)="deleteActivity(activity)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.activityDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let activity">{{ activity.activityDate | date:'M/d/yyyy' }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.violationWork">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Violation Work</th>
          <td mat-cell *matCellDef="let activity">{{ activity.violationWork }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.activity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity</th>
          <td mat-cell *matCellDef="let activity">{{ activity.activity === activityOptionEnum.Other && activity.activityOther ? activity.activityOther : activity.activity }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.personInvolved">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Person Involved</th>
          <td mat-cell *matCellDef="let activity">{{ activity.personInvolvedDisplay }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.method">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Method</th>
          <td mat-cell *matCellDef="let activity">{{ activity.method }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.agentLocation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Agent's Location</th>
          <td mat-cell *matCellDef="let activity">{{ activity.agentLocation }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.activityMinutesSpent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity Minutes Spent</th>
          <td mat-cell *matCellDef="let activity">{{ activity.activityMinutesSpent }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.travelMinutesSpent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Travel Minutes Spent</th>
          <td mat-cell *matCellDef="let activity">{{ activity.travelMinutesSpent }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.waitingMinutesSpent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Waiting Minutes Spent</th>
          <td mat-cell *matCellDef="let activity">{{ activity.waitingMinutesSpent }}</td>
        </ng-container>

        <ng-container [matColumnDef]="activityListColumnEnum.notes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
          <td mat-cell *matCellDef="let activity" class="newline-display">{{ activity.notes }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="activityListDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: activityListDisplayedColumns;"></tr>
      </table>

      <mat-nav-list *ngIf="isSmallDisplay">
        <a mat-list-item *ngFor="let activity of activitiesDataSource">
          <h2 matLine>{{ activity.activityDate | date:'M/d/yyyy' }}
            {{ activity.activity === activityOptionEnum.Other && activity.activityOther ? activity.activityOther : activity.activity }}</h2>
          <div matLine>{{ activity.personInvolvedDisplay }}</div>
          <div matLine>{{ activity.method }} at {{ activity.agentLocation }}</div>
          <div matLine>{{ activity.activityMinutesSpent }} min / travel {{ activity.travelMinutesSpent }} min / waiting {{ activity.waitingMinutesSpent }} min</div>
          <div matLine *ngIf="activity.notes">{{ activity.notes }}</div>
          <div class="form-mat-list-actions">
            <button mat-icon-button color="primary" aria-label="Edit Activity" (click)="editActivity(activity)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="primary" aria-label="Delete Activity" (click)="deleteActivity(activity)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </a>
      </mat-nav-list>

      <padded-content *ngIf="currentFormData?.activities?.length === 0 && activitiesDataSource.length === 0">No activities yet. Create one with the Add (+) button in the lower right.</padded-content>
      <padded-content *ngIf="currentFormData?.activities?.length !== activitiesDataSource.length" class="filter-warning">
        Some activities have been hidden by the Activity Filter.
        <button mat-flat-button color="primary" (click)="clearActivityListFilters()">Clear Filters</button>
        to see all activities.
      </padded-content>
    </mat-tab>
    <mat-tab label="Case Changes">
      <padded-content>
        <h2>Case changes during the study</h2>
        <mat-form-field>
          <mat-label>Case Activity</mat-label>
          <mat-select formControlName="caseActivity" multiple>
            <mat-option *ngFor="let option of caseActivityOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.caseActivity.invalid">{{errorMessage(form.controls.caseActivity)}}</mat-error>
        </mat-form-field>

        <p *ngIf="currentFormData?.caseActivityIntrastateTransferOutgoing">
          <span class="caseActivityInstructionType">{{caseActivityOptionEnum.IntrastateTransferOutgoing}}</span>
          Stop tracking time on this form when transfer accepted and points have transferred.
          Track any additional time for this case on case support.
        </p>

        <p *ngIf="currentFormData?.caseActivityRevocation">
          <span class="caseActivityInstructionType">{{caseActivityOptionEnum.Revocation}}</span>
          Fill in the date for “Date decision made to not revoke or date 414 served”, stop tracking on this form,
          and start tracking on the investigation form if this happens before January 20. 
        </p>

        <p *ngIf="currentFormData?.caseActivityAbsconder">
          <span class="caseActivityInstructionType">{{caseActivityOptionEnum.Absconder}}</span>
          Continue tracking until the primary status changes to "absconded" or until completion of the
          intake/investigation, whichever comes first.
        </p>

        <p *ngIf="currentFormData?.caseActivityViolation">
          <span class="caseActivityInstructionType">{{caseActivityOptionEnum.Violation}}</span>
          Continue to record all time spent investigating the violation on the current form but remember to mark that
          it was violation work. Do your best to distinguish between work primarily related to the violation
          investigation as opposed to the intake/investigation.
        </p>
      </padded-content>

      <table mat-table [dataSource]="violationsDataSource" *ngIf="isLargeDisplay && currentFormData?.caseActivityViolation">
        <ng-container [matColumnDef]="violationListColumnEnum.action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let violation" class="action-column-cell">
            <button mat-icon-button color="primary" aria-label="Edit Violation" (click)="editViolation(violation)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="primary" aria-label="Delete Violation" (click)="deleteViolation(violation)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="violationListColumnEnum.violationAddressedDate">
          <th mat-header-cell *matHeaderCellDef>Date violation addressed</th>
          <td mat-cell *matCellDef="let violation">{{ violation.violationAddressedDate | date:'M/d/yyyy' }}</td>
        </ng-container>

        <ng-container [matColumnDef]="violationListColumnEnum.custodyViolation">
          <th mat-header-cell *matHeaderCellDef>Custody violation?</th>
          <td mat-cell *matCellDef="let violation">{{ violation.custodyViolation }}</td>
        </ng-container>

        <ng-container [matColumnDef]="violationListColumnEnum.decisionDate">
          <th mat-header-cell *matHeaderCellDef>Date decision made to not revoke or date 414 served</th>
          <td mat-cell *matCellDef="let violation">{{ violation.decisionDate | date:'M/d/yyyy' }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="violationListDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let violation; columns: violationListDisplayedColumns;"></tr>
      </table>

      <mat-nav-list *ngIf="isSmallDisplay && currentFormData?.caseActivityViolation">
        <a mat-list-item *ngFor="let violation of currentFormData?.violations">
          <div matLine>Addressed {{ violation.violationAddressedDate | date:'M/d/yyyy' }}</div>
          <div matLine>Custody Violation? {{ violation.custodyViolation }}</div>
          <div matLine>Decision or 414 {{ violation.decisionDate | date:'M/d/yyyy' }}</div>
          <div class="form-mat-list-actions">
            <button mat-icon-button color="primary" aria-label="Edit Violation" (click)="editViolation(violation)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="primary" aria-label="Delete Violation" (click)="deleteViolation(violation)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </a>
      </mat-nav-list>

      <padded-content class="button-bar" *ngIf="currentFormData?.caseActivityViolation">
        <button mat-raised-button color="primary" (click)="editViolation(null);$event.preventDefault()"><mat-icon>add</mat-icon>Add Violation</button>
      </padded-content>

      <padded-content>
        <mat-form-field>
          <mat-label>Agent Optional Comments</mat-label>
          <textarea #agentComments matInput maxlength="500" formControlName="agentComments" [cdkTextareaAutosize]="true"></textarea>
          <mat-hint align="end">{{agentComments.value.length}} / 500</mat-hint>
          <mat-error *ngIf="form.controls.agentComments.invalid">{{errorMessage(form.controls.agentComments)}}</mat-error>
        </mat-form-field>
      </padded-content>
    </mat-tab>
    <mat-tab label="Supervisor Review" *ngIf="currentFormData?.status !== formStatusEnum.InProgress">
      <padded-content>
        <mat-form-field>
          <mat-label>Supervisor Review: Work Met Standards</mat-label>
          <mat-select formControlName="workMetStandards">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let option of yesNoOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.workMetStandards.invalid">{{errorMessage(form.controls.workMetStandards)}}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="currentFormData?.workMetStandards === yesNoOptionsEnum.No">
          <mat-label>Why didn’t the case meet standards?</mat-label>
          <mat-select formControlName="workMetStandardsNoReasons" multiple>
            <mat-option *ngFor="let option of workMetStandardsNoReasonsOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.workMetStandardsNoReasons.invalid">{{errorMessage(form.controls.workMetStandardsNoReasons)}}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="currentFormData?.workMetStandards === yesNoOptionsEnum.No && currentFormData?.workMetStandardsNoReasonOther">
          <mat-label>Why didn’t the case meet standards? Other</mat-label>
          <textarea #workMetStandardsNoReasonOtherText matInput maxlength="500" formControlName="workMetStandardsNoReasonOtherText" [cdkTextareaAutosize]="true"></textarea>
          <mat-hint align="end">{{workMetStandardsNoReasonOtherText.value.length}} / 500</mat-hint>
          <mat-error *ngIf="form.controls.workMetStandardsNoReasonOtherText.invalid">{{errorMessage(form.controls.workMetStandardsNoReasonOtherText)}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Supervisor Optional Comments</mat-label>
          <textarea #supervisorComments matInput maxlength="500" formControlName="supervisorComments" [cdkTextareaAutosize]="true"></textarea>
          <mat-hint align="end">{{supervisorComments.value.length}} / 500</mat-hint>
          <mat-error *ngIf="form.controls.supervisorComments.invalid">{{errorMessage(form.controls.supervisorComments)}}</mat-error>
        </mat-form-field>
      </padded-content>
    </mat-tab>
  </mat-tab-group>
</form>

<div class="fab-button-padding"></div>
<div class="lower-right-action">
  <button mat-fab (click)="deleteFormDialog()" *ngIf="!readonly && user?.role === UserRole.Agent" [matTooltip]="deleteFormDialogData.title" matTooltipPosition="above"><mat-icon>delete</mat-icon></button>
  <button mat-mini-fab (click)="deleteFormDialog()" *ngIf="!readonly && user?.role === UserRole.Agent" [matTooltip]="deleteFormDialogData.title" matTooltipPosition="above"><mat-icon>delete</mat-icon></button>

  <button mat-fab (click)="discardChangesDialog()" [matTooltip]="discardChangesDialogData.title" matTooltipPosition="above"><mat-icon>undo</mat-icon></button>
  <button mat-mini-fab (click)="discardChangesDialog()" [matTooltip]="discardChangesDialogData.title" matTooltipPosition="above"><mat-icon>undo</mat-icon></button>

  <button mat-fab (click)="saveForm()" *ngIf="!readonly" matTooltip="Save Form" matTooltipPosition="above"><mat-icon>save</mat-icon></button>
  <button mat-mini-fab (click)="saveForm()" *ngIf="!readonly" matTooltip="Save Form" matTooltipPosition="above"><mat-icon>save</mat-icon></button>

  <button mat-fab (click)="submitFormDialog()" *ngIf="!readonly && user?.role === UserRole.Agent" [matTooltip]="submitFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_turned_in</mat-icon></button>
  <button mat-mini-fab (click)="submitFormDialog()" *ngIf="!readonly && user?.role === UserRole.Agent" [matTooltip]="submitFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_turned_in</mat-icon></button>

  <button mat-fab (click)="sendBackFormDialog()" *ngIf="!readonly && user?.role === UserRole.Supervisor" [matTooltip]="sendBackFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_return</mat-icon></button>
  <button mat-mini-fab (click)="sendBackFormDialog()" *ngIf="!readonly && user?.role === UserRole.Supervisor" [matTooltip]="sendBackFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_return</mat-icon></button>

  <button mat-fab (click)="completeFormDialog()" *ngIf="!readonly && user?.role === UserRole.Supervisor" [matTooltip]="completeFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_turned_in</mat-icon></button>
  <button mat-mini-fab (click)="completeFormDialog()" *ngIf="!readonly && user?.role === UserRole.Supervisor" [matTooltip]="completeFormDialogData.title" matTooltipPosition="above"><mat-icon>assignment_turned_in</mat-icon></button>

  <button mat-fab (click)="editActivity(null)" *ngIf="!readonly" matTooltip="Add Activity" matTooltipPosition="above"><mat-icon>add</mat-icon></button>
  <button mat-mini-fab (click)="editActivity(null)" *ngIf="!readonly" matTooltip="Add Activity" matTooltipPosition="above"><mat-icon>add</mat-icon></button>
</div>
