import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import { UserRole } from '../auth/user-role.enum';
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth.service";
import * as i2 from "@angular/common/http";
export class ApiService {
    constructor(authService, http) {
        this.authService = authService;
        this.http = http;
    }
    apiGetList() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.authService.user === null) {
                throw ('No logged in user found.');
            }
            let getListResponse = null;
            try {
                if (this.authService.user && this.authService.user.role === UserRole.Supervisor) {
                    getListResponse = yield this.http.get(environment.apiGetListSupervisor).toPromise();
                }
                else {
                    getListResponse = yield this.http.get(environment.apiGetList).toPromise();
                }
            }
            catch (error) {
                console.error('Error loading the form list', error);
                if (error.status) {
                    switch (error.status) {
                        case 401: // Unauthorized
                            throw ('Data server returned an unauthorized error (401) loading the form list.');
                        case 500: // Server Error
                            throw ('Data server returned an internal server error (500) loading the form list.');
                        case 504: // Error Generated by the Service worker
                            throw ('There was an error communicating with the data server while loading the form list.');
                        default:
                            throw ('Data server returned an error (' + error.status + ') while loading the form list.');
                    }
                }
                throw ('Unknown error occurred while loading the form list from the data server.');
            }
            if (getListResponse === null) {
                throw ('Unknown error occurred while loading the form list from the data server.');
            }
            return getListResponse;
        });
    }
    apiGetAdminList(page, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.authService.user === null) {
                throw ('No logged in user found.');
            }
            let getListResponse = null;
            try {
                getListResponse = yield this.http.post(environment.apiGetListAdmin + page.toString(), filter).toPromise();
            }
            catch (error) {
                console.error('Error loading the admin form list', error);
                if (error.status) {
                    switch (error.status) {
                        case 401: // Unauthorized
                            throw ('Data server returned an unauthorized error (401) loading the admin form list.');
                        case 500: // Server Error
                            throw ('Data server returned an internal server error (500) loading the admin form list.');
                        case 504: // Error Generated by the Service worker
                            throw ('There was an error communicating with the data server while loading the admin form list.');
                        default:
                            throw ('Data server returned an error (' + error.status + ') while loading the admin form list.');
                    }
                }
                throw ('Unknown error occurred while loading the admin form list from the data server.');
            }
            if (getListResponse === null) {
                throw ('Unknown error occurred while loading the admin form list from the data server.');
            }
            return getListResponse;
        });
    }
    apiGetSurvey(formId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.authService.user === null) {
                throw ('No logged in user found.');
            }
            let getSurveyResponse = null;
            try {
                getSurveyResponse = yield this.http.get(environment.apiGetSurvey + formId.toString()).toPromise();
            }
            catch (error) {
                console.error('Error loading the form', error);
                if (error.status) {
                    switch (error.status) {
                        case 401: // Unauthorized
                            throw ('Data server returned an unauthorized error (401) loading the form.');
                        case 500: // Server Error
                            throw ('Data server returned an internal server error (500) loading the form.');
                        case 504: // Error Generated by the Service worker
                            throw ('There was an error communicating with the data server while loading the form.');
                        default:
                            throw ('Data server returned an error (' + error.status + ') while loading the form.');
                    }
                }
                throw ('Unknown error occurred while loading the form from the data server.');
            }
            if (getSurveyResponse === null) {
                throw ('Unknown error occurred while loading the form from the data server.');
            }
            return getSurveyResponse;
        });
    }
    saveSurvey(saveData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.authService.user === null) {
                throw ('No logged in user found.');
            }
            let saveSurveyResponse = null;
            try {
                saveSurveyResponse = yield this.http.post(environment.apiSaveSurvey, saveData).toPromise();
            }
            catch (error) {
                console.error('Error saving the form', error);
                if (error.status) {
                    switch (error.status) {
                        case 401: // Unauthorized
                            throw ('Data server returned an unauthorized error (401) while saving the form.');
                        case 500: // Server Error
                            throw ('Data server returned an internal server error (500) while saving the form.');
                        case 504: // Error Generated by the Service worker
                            throw ('There was an error communicating with the data server while saving the form.');
                        default:
                            throw ('Data server returned an error (' + error.status + ') while saving the form.');
                    }
                }
                throw ('Unknown error occurred while saving the form to the data server.');
            }
            if (saveSurveyResponse === null) {
                throw ('Unknown error occurred while saving the form to the data server.');
            }
            return saveSurveyResponse;
        });
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.HttpClient)); }, token: ApiService, providedIn: "root" });
