import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Mddjs2020CdEmActivityListFilterDialogData } from './mddjs2020-cd-em-activity-list-filter-dialog-data';

@Component({
  selector: 'app-mddjs2020-cd-em-activity-list-filter-dialog',
  templateUrl: './mddjs2020-cd-em-activity-list-filter-dialog.component.html',
  styleUrls: ['./mddjs2020-cd-em-activity-list-filter-dialog.component.css']
})
export class Mddjs2020CdEmActivityListFilterDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<Mddjs2020CdEmActivityListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Mddjs2020CdEmActivityListFilterDialogData
  ) { }

  clear(): void {
    this.data.clearFunction();
  }

  close(): void {
    this.dialogRef.close();
  }
}
