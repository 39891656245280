import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { noneValidator } from '../../../../validators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { isArray } from "util";
import { Humboldt2020IlsActivityData, getActivityPersonInvolvedArray } from '../humboldt2020-ils-activity-data';
import { Humboldt2020IlsActivityActivityOption } from './humboldt2020-ils-activity-activity-option.enum';
import { Humboldt2020IlsActivityPersonInvolvedOption } from './humboldt2020-ils-activity-person-involved-option.enum';
import { Humboldt2020IlsActivityMethodOption } from './humboldt2020-ils-activity-method-option.enum';
import { Humboldt2020IlsActivityPlaceOption } from './humboldt2020-ils-activity-place-option.enum';
import { Humboldt2020IlsActivityDialogData } from './humboldt2020-ils-activity-dialog-data';

@Component({
  selector: 'app-humboldt2020-ils-activity-dialog',
  templateUrl: './humboldt2020-ils-activity-dialog.component.html',
  styleUrls: ['./humboldt2020-ils-activity-dialog.component.css']
})
export class Humboldt2020IlsActivityDialogComponent {
  currentActivity: Humboldt2020IlsActivityData;
  form = this.formBuilder.group({
    activityDate: [null, Validators.required],
    activity: [null, Validators.required],
    personInvolved: [[], [Validators.required, noneValidator]],
    method: [null, Validators.required],
    place: [null, Validators.required],
    activityMinutesSpent: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    travelMinutesSpent: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    activityDescription: [null, Validators.maxLength(500)]
  });

  activityOptions = Object.values(Humboldt2020IlsActivityActivityOption);
  activityOptionEnum = Humboldt2020IlsActivityActivityOption;
  personInvolvedOptions = Object.values(Humboldt2020IlsActivityPersonInvolvedOption);
  methodOptions = Object.values(Humboldt2020IlsActivityMethodOption);
  placeOptions = Object.values(Humboldt2020IlsActivityPlaceOption);

  constructor(
    private dialogRef: MatDialogRef<Humboldt2020IlsActivityDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Humboldt2020IlsActivityDialogData
  ) {
    if (data.activity) {
      // Need to make a copy of the object so updating it doesn't update the origin object until save is applied
      this.currentActivity = {
        index: data.activity.index,
        activityDate: data.activity.activityDate,
        activity: data.activity.activity,
        personInvolvedDisplay: data.activity.personInvolvedDisplay,
        personInvolvedYouth: data.activity.personInvolvedYouth,
        personInvolvedTribe: data.activity.personInvolvedTribe,
        personInvolvedOtherCwsTayStaff: data.activity.personInvolvedOtherCwsTayStaff,
        personInvolvedSupervisor: data.activity.personInvolvedSupervisor,
        personInvolvedOtherServiceProvider: data.activity.personInvolvedOtherServiceProvider,
        personInvolvedOther: data.activity.personInvolvedOther,
        personInvolvedNone: data.activity.personInvolvedNone,
        method: data.activity.method,
        place: data.activity.place,
        activityMinutesSpent: data.activity.activityMinutesSpent,
        travelMinutesSpent: data.activity.travelMinutesSpent,
        activityDescription: data.activity.activityDescription
      };

      if (this.currentActivity.activityDate) {
        this.form.controls.activityDate.setValue(moment(this.currentActivity.activityDate, 'x')); // x = unix milliseconds
      }
      this.form.controls.activity.setValue(this.currentActivity.activity);
      this.form.controls.personInvolved.setValue(getActivityPersonInvolvedArray(this.currentActivity));
      this.form.controls.method.setValue(this.currentActivity.method);
      this.form.controls.place.setValue(this.currentActivity.place);
      this.form.controls.activityMinutesSpent.setValue(this.currentActivity.activityMinutesSpent);
      this.form.controls.travelMinutesSpent.setValue(this.currentActivity.travelMinutesSpent);
      this.form.controls.activityDescription.setValue(this.currentActivity.activityDescription);
      this.updateActivityDescriptionValidators();
      this.form.markAllAsTouched();
    } else {
      this.currentActivity = {
        index: null,
        activityDate: null,
        activity: null,
        personInvolvedDisplay: null,
        personInvolvedYouth: false,
        personInvolvedTribe: false,
        personInvolvedOtherCwsTayStaff: false,
        personInvolvedSupervisor: false,
        personInvolvedOtherServiceProvider: false,
        personInvolvedOther: false,
        personInvolvedNone: false,
        method: null,
        place: null,
        activityMinutesSpent: null,
        travelMinutesSpent: null,
        activityDescription: null
      };
    }

    this.formChangeSubscriptions();
  }

  formChangeSubscriptions(): void {
    this.form.controls.activityDate.valueChanges.subscribe(activityDate => {
      const numberValue = activityDate !== null ? parseInt(activityDate.format('x')) : null;
      if (this.currentActivity.activityDate !== numberValue) {
        this.currentActivity.activityDate = numberValue;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activity.valueChanges.subscribe(activity => {
      if (this.currentActivity.activity !== activity) {
        this.currentActivity.activity = activity;
        this.data.updateCurrentActivity(this.currentActivity);
        this.updateActivityDescriptionValidators();
      }
    });

    this.form.controls.personInvolved.valueChanges.subscribe(personInvolved => {
      let personInvolvedYouth = false;
      let personInvolvedTribe = false;
      let personInvolvedOtherCwsTayStaff = false;
      let personInvolvedSupervisor = false;
      let personInvolvedOtherServiceProvider = false;
      let personInvolvedOther = false;
      let personInvolvedNone = false;

      if (isArray(personInvolved)) {
        (personInvolved as string[]).forEach(personInvolved => {
          switch (personInvolved) {
            case Humboldt2020IlsActivityPersonInvolvedOption.Youth:
              personInvolvedYouth = true;
              break;
            case Humboldt2020IlsActivityPersonInvolvedOption.Tribe:
              personInvolvedTribe = true;
              break;
            case Humboldt2020IlsActivityPersonInvolvedOption.OtherCwsTayStaff:
              personInvolvedOtherCwsTayStaff = true;
              break;
            case Humboldt2020IlsActivityPersonInvolvedOption.Supervisor:
              personInvolvedSupervisor = true;
              break;
            case Humboldt2020IlsActivityPersonInvolvedOption.OtherServiceProvider:
              personInvolvedOtherServiceProvider= true;
              break;
            case Humboldt2020IlsActivityPersonInvolvedOption.Other:
              personInvolvedOther = true;
              break;
            case Humboldt2020IlsActivityPersonInvolvedOption.None:
              personInvolvedNone = true;
              break;
          }
        })
      }

      let saveCurrentFormValues = false;
      if (this.currentActivity && this.currentActivity.personInvolvedYouth !== personInvolvedYouth) {
        this.currentActivity.personInvolvedYouth = personInvolvedYouth;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedTribe !== personInvolvedTribe) {
        this.currentActivity.personInvolvedTribe = personInvolvedTribe;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedOtherCwsTayStaff !== personInvolvedOtherCwsTayStaff) {
        this.currentActivity.personInvolvedOtherCwsTayStaff = personInvolvedOtherCwsTayStaff;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedSupervisor !== personInvolvedSupervisor) {
        this.currentActivity.personInvolvedSupervisor = personInvolvedSupervisor;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedOtherServiceProvider !== personInvolvedOtherServiceProvider) {
        this.currentActivity.personInvolvedOtherServiceProvider = personInvolvedOtherServiceProvider;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedOther !== personInvolvedOther) {
        this.currentActivity.personInvolvedOther = personInvolvedOther;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personInvolvedNone !== personInvolvedNone) {
        this.currentActivity.personInvolvedNone = personInvolvedNone;
        saveCurrentFormValues = true;
      }
      const personInvolvedArray = getActivityPersonInvolvedArray(this.currentActivity);
      const personInvolvedDisplay = personInvolvedArray.length ? personInvolvedArray.join(', ') : null;
      if (this.currentActivity && this.currentActivity.personInvolvedDisplay !== personInvolvedDisplay) {
        this.currentActivity.personInvolvedDisplay = personInvolvedDisplay;
        saveCurrentFormValues = true;
      }
      if (saveCurrentFormValues) {
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.method.valueChanges.subscribe(method => {
      if (this.currentActivity.method !== method) {
        this.currentActivity.method = method;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.place.valueChanges.subscribe(place => {
      if (this.currentActivity.place !== place) {
        this.currentActivity.place = place;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityMinutesSpent.valueChanges.subscribe(activityMinutesSpent => {
      if (this.currentActivity.activityMinutesSpent !== activityMinutesSpent) {
        this.currentActivity.activityMinutesSpent = activityMinutesSpent;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.travelMinutesSpent.valueChanges.subscribe(travelMinutesSpent => {
      if (this.currentActivity.travelMinutesSpent !== travelMinutesSpent) {
        this.currentActivity.travelMinutesSpent = travelMinutesSpent;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityDescription.valueChanges.subscribe(activityDescription => {
      if (this.currentActivity.activityDescription !== activityDescription) {
        this.currentActivity.activityDescription = activityDescription;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });
  }

  /* updates the validators for activityDescription */
  updateActivityDescriptionValidators(): void {
    if (this.currentActivity && this.currentActivity.activity === Humboldt2020IlsActivityActivityOption.Other) {
      this.form.controls.activityDescription.setValidators([Validators.required, Validators.maxLength(500)]);
    } else {
      this.form.controls.activityDescription.setValidators(Validators.maxLength(500));
    }
    this.form.controls.activityDescription.updateValueAndValidity();
  }

  updateActivityList(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dialogRef.close(this.currentActivity);
    }
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
