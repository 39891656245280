import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { maxItemsValidator, noneValidator } from '../../../../validators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { isArray } from "util";
import { getActivityPersonArray, Mddjs2020IntakeActivityData } from '../mddjs2020-intake-activity-data';
import { Mddjs2020IntakeActivityActivityOption } from './mddjs2020-intake-activity-activity-option.enum';
import { Mddjs2020IntakeActivityPersonOption } from './mddjs2020-intake-activity-person-option.enum';
import { Mddjs2020IntakeActivityMethodOption } from './mddjs2020-intake-activity-method-option.enum';
import { Mddjs2020IntakeActivityPlaceOption } from './mddjs2020-intake-activity-place-option.enum';
import { Mddjs2020IntakeActivityDialogData } from './mddjs2020-intake-activity-dialog-data';

@Component({
  selector: 'app-mddjs2020-intake-activity-dialog',
  templateUrl: './mddjs2020-intake-activity-dialog.component.html',
  styleUrls: ['./mddjs2020-intake-activity-dialog.component.css']
})
export class Mddjs2020IntakeActivityDialogComponent {
  currentActivity: Mddjs2020IntakeActivityData;
  form = this.formBuilder.group({
    activityDate: [null, Validators.required],
    activity: [null, Validators.required],
    person: [[], [Validators.required, maxItemsValidator(3), noneValidator]],
    method: [null, Validators.required],
    place: [null, Validators.required],
    activityMinutes: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    travelMinutes: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    waitingMinutes: [null, [Validators.required, Validators.min(0), Validators.max(1440)]],
    activityDescription: [null, Validators.maxLength(500)]
  });

  activityOptions = Object.values(Mddjs2020IntakeActivityActivityOption);
  activityOptionEnum = Mddjs2020IntakeActivityActivityOption;
  personOptions = Object.values(Mddjs2020IntakeActivityPersonOption);
  methodOptions = Object.values(Mddjs2020IntakeActivityMethodOption);
  placeOptions = Object.values(Mddjs2020IntakeActivityPlaceOption);

  constructor(
    private dialogRef: MatDialogRef<Mddjs2020IntakeActivityDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Mddjs2020IntakeActivityDialogData
  ) {
    if (data.activity) {
      // Need to make a copy of the object so updating it doesn't update the origin object until save is applied
      this.currentActivity = {
        index: data.activity.index,
        activityDate: data.activity.activityDate,
        activity: data.activity.activity,
        personDisplay: data.activity.personDisplay,
        personYouth: data.activity.personYouth,
        personParentGuardianCaretaker: data.activity.personParentGuardianCaretaker,
        personOtherDjsStaff: data.activity.personOtherDjsStaff,
        personServiceProvider: data.activity.personServiceProvider,
        personDssStaff: data.activity.personDssStaff,
        personOtherCollateral: data.activity.personOtherCollateral,
        personNone: data.activity.personNone,
        method: data.activity.method,
        place: data.activity.place,
        activityMinutes: data.activity.activityMinutes,
        travelMinutes: data.activity.travelMinutes,
        waitingMinutes: data.activity.waitingMinutes,
        activityDescription: data.activity.activityDescription
      };

      if (this.currentActivity.activityDate) {
        this.form.controls.activityDate.setValue(moment(this.currentActivity.activityDate, 'x')); // x = unix milliseconds
      }
      this.form.controls.activity.setValue(this.currentActivity.activity);
      this.form.controls.person.setValue(getActivityPersonArray(this.currentActivity));
      this.form.controls.method.setValue(this.currentActivity.method);
      this.form.controls.place.setValue(this.currentActivity.place);
      this.form.controls.activityMinutes.setValue(this.currentActivity.activityMinutes);
      this.form.controls.travelMinutes.setValue(this.currentActivity.travelMinutes);
      this.form.controls.waitingMinutes.setValue(this.currentActivity.waitingMinutes);
      this.form.controls.activityDescription.setValue(this.currentActivity.activityDescription);
      this.updateActivityDescriptionValidators();
      this.form.markAllAsTouched();
    } else {
      this.currentActivity = {
        index: null,
        activityDate: null,
        activity: null,
        personDisplay: null,
        personYouth: false,
        personParentGuardianCaretaker: false,
        personOtherDjsStaff: false,
        personServiceProvider: false,
        personDssStaff: false,
        personOtherCollateral: false,
        personNone: false,
        method: null,
        place: null,
        activityMinutes: null,
        travelMinutes: null,
        waitingMinutes: null,
        activityDescription: null
      };
    }

    this.formChangeSubscriptions();
  }

  formChangeSubscriptions(): void {
    this.form.controls.activityDate.valueChanges.subscribe(activityDate => {
      const numberValue = activityDate !== null ? parseInt(activityDate.format('x')) : null;
      if (this.currentActivity.activityDate !== numberValue) {
        this.currentActivity.activityDate = numberValue;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activity.valueChanges.subscribe(activity => {
      if (this.currentActivity.activity !== activity) {
        this.currentActivity.activity = activity;
        this.data.updateCurrentActivity(this.currentActivity);
        this.updateActivityDescriptionValidators();
      }
    });

    this.form.controls.person.valueChanges.subscribe(person => {
      let personYouth = false;
      let personParentGuardianCaretaker = false;
      let personOtherDjsStaff = false;
      let personServiceProvider = false;
      let personDssStaff = false;
      let personOtherCollateral = false;
      let personNone = false;

      if (isArray(person)) {
        (person as string[]).forEach(person => {
          switch (person) {
            case Mddjs2020IntakeActivityPersonOption.Youth:
              personYouth = true;
              break;
            case Mddjs2020IntakeActivityPersonOption.ParentGuardianCaretaker:
              personParentGuardianCaretaker = true;
              break;
            case Mddjs2020IntakeActivityPersonOption.OtherDjsStaff:
              personOtherDjsStaff = true;
              break;
            case Mddjs2020IntakeActivityPersonOption.ServiceProvider:
              personServiceProvider = true;
              break;
            case Mddjs2020IntakeActivityPersonOption.DssStaff:
              personDssStaff = true;
              break;
            case Mddjs2020IntakeActivityPersonOption.OtherCollateral:
              personOtherCollateral = true;
              break;
            case Mddjs2020IntakeActivityPersonOption.None:
              personNone = true;
              break;
          }
        })
      }

      let saveCurrentFormValues = false;
      if (this.currentActivity && this.currentActivity.personYouth !== personYouth) {
        this.currentActivity.personYouth = personYouth;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personParentGuardianCaretaker !== personParentGuardianCaretaker) {
        this.currentActivity.personParentGuardianCaretaker = personParentGuardianCaretaker;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personOtherDjsStaff !== personOtherDjsStaff) {
        this.currentActivity.personOtherDjsStaff = personOtherDjsStaff;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personServiceProvider !== personServiceProvider) {
        this.currentActivity.personServiceProvider = personServiceProvider;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personDssStaff !== personDssStaff) {
        this.currentActivity.personDssStaff = personDssStaff;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personOtherCollateral !== personOtherCollateral) {
        this.currentActivity.personOtherCollateral = personOtherCollateral;
        saveCurrentFormValues = true;
      }
      if (this.currentActivity && this.currentActivity.personNone !== personNone) {
        this.currentActivity.personNone = personNone;
        saveCurrentFormValues = true;
      }
      const personArray = getActivityPersonArray(this.currentActivity);
      const personDisplay = personArray.length ? personArray.join(', ') : null;
      if (this.currentActivity && this.currentActivity.personDisplay !== personDisplay) {
        this.currentActivity.personDisplay = personDisplay;
        saveCurrentFormValues = true;
      }
      if (saveCurrentFormValues) {
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.method.valueChanges.subscribe(method => {
      if (this.currentActivity.method !== method) {
        this.currentActivity.method = method;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.place.valueChanges.subscribe(place => {
      if (this.currentActivity.place !== place) {
        this.currentActivity.place = place;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityMinutes.valueChanges.subscribe(activityMinutes => {
      if (this.currentActivity.activityMinutes !== activityMinutes) {
        this.currentActivity.activityMinutes = activityMinutes;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.travelMinutes.valueChanges.subscribe(travelMinutes => {
      if (this.currentActivity.travelMinutes !== travelMinutes) {
        this.currentActivity.travelMinutes = travelMinutes;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.waitingMinutes.valueChanges.subscribe(waitingMinutes => {
      if (this.currentActivity.waitingMinutes !== waitingMinutes) {
        this.currentActivity.waitingMinutes = waitingMinutes;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });

    this.form.controls.activityDescription.valueChanges.subscribe(activityDescription => {
      if (this.currentActivity.activityDescription !== activityDescription) {
        this.currentActivity.activityDescription = activityDescription;
        this.data.updateCurrentActivity(this.currentActivity);
      }
    });
  }

  /* updates the validators for activityDescription */
  updateActivityDescriptionValidators(): void {
    if (this.currentActivity && this.currentActivity.activity === Mddjs2020IntakeActivityActivityOption.Other) {
      this.form.controls.activityDescription.setValidators([Validators.required, Validators.maxLength(500)]);
    } else {
      this.form.controls.activityDescription.setValidators(Validators.maxLength(500));
    }
    this.form.controls.activityDescription.updateValueAndValidity();
  }

  updateActivityList(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dialogRef.close(this.currentActivity);
    }
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
