import { FormTypeData } from './form-type-data';

export interface ProjectData {
  id: number;
  databaseString: string;
  name: string;
  productionProject: boolean;
  forms?: FormTypeData[];
}

const PROJECTS: ProjectData[] = [
  {id: 1, databaseString: 'doc2019', name: 'DOC Workload Study', productionProject: true},
  {id: 2, databaseString: 'humboldt2020', name: 'Humboldt Workload Study', productionProject: true},
  {id: 3, databaseString: 'mddjs2020', name: 'MD DJS Workload Study', productionProject: false}
];

export function getProjectList(): ProjectData[] {
  return PROJECTS.sort(sortProjectsByName);
}

/* get the project data for a particular id */
export function getProjectById(id: number): ProjectData | null {
  const filtered = PROJECTS.filter(project => project.id === id);
  return filtered.length === 1 ? filtered[0] : null;
}

/* get the project data for a particular database string */
export function getProjectByDatabaseString(databaseString: string): ProjectData | null {
  const filtered = PROJECTS.filter(project => project.databaseString === databaseString);
  return filtered.length === 1 ? filtered[0] : null;
}

function sortProjectsByName(project1: ProjectData, project2: ProjectData) {
  if (project1.name < project2.name) {
    return -1;
  }
  if (project1.name > project2.name) {
    return 1;
  }
  return 0;
}
