import { SharedFormData } from '../../../form/shared-form-data';
import {
  Doc2019FieldInvestigationTimeActivityData,
  getActivityPersonInvolvedArray
} from './doc2019-field-investigation-time-activity.data';
import { Doc2019FieldInvestigationTimeViolationData } from './doc2019-field-investigation-time-violation.data';
import { UserData } from '../../../auth/user-data';
import { YesNoOption } from '../../../form/yes-no-option.enum';
import { ApiSaveSurveyData } from '../../../api/api-save-survey-data';
import { ApiGetSurveyResponse } from '../../../api/api-get-survey-response';
import { isArray } from "util";
import { Doc2019FieldInvestigationTimeInvestigationTypeOption } from './doc2019-field-investigation-time-investigation-type-option.enum';
import { Doc2019FieldInvestigationTimeIntakeGeneralStatusOption } from './doc2019-field-investigation-time-intake-general-status-option.enum';
import { Doc2019FieldInvestigationTimeActivityActivityOption } from './doc2019-field-investigation-time-activity-dialog/doc2019-field-investigation-time-activity-activity-option.enum';

export class Doc2019FieldInvestigationTimeFormData extends SharedFormData {
  offenderId: string;
  investigationType: Doc2019FieldInvestigationTimeInvestigationTypeOption | null;
  intakeGeneralStatus: Doc2019FieldInvestigationTimeIntakeGeneralStatusOption | null;
  revocationHearingWaived: YesNoOption | null;

  programEmpBracelet: boolean;
  programGps: boolean;
  programOars: boolean;
  programSoberlinkTad: boolean;
  programSpecialtyCourt: boolean;
  programNoneApply: boolean;

  // currentActivity should only be used by the current form when an activity is open and being edited
  currentActivity: Doc2019FieldInvestigationTimeActivityData | null;
  activities: Doc2019FieldInvestigationTimeActivityData[];

  caseActivityAbsconder: boolean;
  caseActivityInstallRemoveMonitoringDevice: boolean;
  caseActivityIntrastateTransferOutgoing: boolean;
  caseActivityViolation: boolean;
  caseActivityAtr: boolean;
  caseActivityRevocation: boolean;
  caseActivityNoneApply: boolean;

  // currentViolation should only be used by the current form when a violation is open and being edited
  currentViolation: Doc2019FieldInvestigationTimeViolationData | null;
  violations: Doc2019FieldInvestigationTimeViolationData[];

  agentComments: string | null;

  workMetStandards: string | null;
  workMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet: boolean;
  workMetStandardsNoReasonActivitiesNotReflective: boolean;
  workMetStandardsNoReasonWorkerStoppedTracking: boolean;
  workMetStandardsNoReasonNotAllWorkDoneDuringMonth: boolean;
  workMetStandardsNoReasonOther: boolean;
  workMetStandardsNoReasonOtherText: string | null;
  supervisorComments: string | null;

  constructor(data: any) {
    super(data);

    this.offenderId = data.offenderId || '';
    this.investigationType = data.investigationType || null;
    this.intakeGeneralStatus = data.intakeGeneralStatus || null;
    this.revocationHearingWaived = data.revocationHearingWaived || null;

    this.programEmpBracelet = data.programEmpBracelet || false;
    this.programGps = data.programGps || false;
    this.programOars = data.programOars || false;
    this.programSoberlinkTad = data.programSoberlinkTad || false;
    this.programSpecialtyCourt = data.programSpecialtyCourt || false;
    this.programNoneApply = data.programNoneApply || false;

    this.currentActivity = data.currentActivity || null;
    this.activities = data.activities || [];

    this.caseActivityAbsconder = data.caseActivityAbsconder || false;
    this.caseActivityInstallRemoveMonitoringDevice = data.caseActivityInstallRemoveMonitoringDevice || false;
    this.caseActivityIntrastateTransferOutgoing = data.caseActivityIntrastateTransferOutgoing || false;
    this.caseActivityViolation = data.caseActivityViolation || false;
    this.caseActivityAtr = data.caseActivityAtr || false;
    this.caseActivityRevocation = data.caseActivityRevocation || false;
    this.caseActivityNoneApply = data.caseActivityNoneApply || false;

    this.currentViolation = data.currentViolation || null;
    this.violations = data.violations || [];

    this.agentComments = data.agentComments || null;

    this.workMetStandards = data.workMetStandards || null;
    this.workMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet = data.workMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet || false;
    this.workMetStandardsNoReasonActivitiesNotReflective = data.workMetStandardsNoReasonActivitiesNotReflective || false;
    this.workMetStandardsNoReasonWorkerStoppedTracking = data.workMetStandardsNoReasonWorkerStoppedTracking || false;
    this.workMetStandardsNoReasonNotAllWorkDoneDuringMonth = data.workMetStandardsNoReasonNotAllWorkDoneDuringMonth || false;
    this.workMetStandardsNoReasonOther = data.workMetStandardsNoReasonOther || false;
    this.workMetStandardsNoReasonOtherText = data.workMetStandardsNoReasonOtherText || null;
    this.supervisorComments = data.supervisorComments || null;
  }

  /* Creates a new Doc2019FieldInvestigationTimeFormData class from the data */
  static createNewForm(id: number, formTypeId: number, user: UserData, timestamp: number): Doc2019FieldInvestigationTimeFormData {
    return new Doc2019FieldInvestigationTimeFormData(SharedFormData.newFormData(id, formTypeId, user, timestamp));
  }

  /* creates the object to store in the database */
  toDatabaseObject(): object {
    const databaseObject: any = {
      offenderId: this.offenderId,
      investigationType: this.investigationType,
      intakeGeneralStatus: this.intakeGeneralStatus,
      revocationHearingWaived: this.revocationHearingWaived,

      programEmpBracelet: this.programEmpBracelet,
      programGps: this.programGps,
      programOars: this.programOars,
      programSoberlinkTad: this.programSoberlinkTad,
      programSpecialtyCourt: this.programSpecialtyCourt,
      programNoneApply: this.programNoneApply,

      currentActivity: this.currentActivity,

      caseActivityAbsconder: this.caseActivityAbsconder,
      caseActivityInstallRemoveMonitoringDevice: this.caseActivityInstallRemoveMonitoringDevice,
      caseActivityIntrastateTransferOutgoing: this.caseActivityIntrastateTransferOutgoing,
      caseActivityViolation: this.caseActivityViolation,
      caseActivityAtr: this.caseActivityAtr,
      caseActivityRevocation: this.caseActivityRevocation,
      caseActivityNoneApply: this.caseActivityNoneApply,

      currentViolation: this.currentViolation,

      agentComments: this.agentComments,

      workMetStandards: this.workMetStandards,
      workMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet: this.workMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet,
      workMetStandardsNoReasonActivitiesNotReflective: this.workMetStandardsNoReasonActivitiesNotReflective,
      workMetStandardsNoReasonWorkerStoppedTracking: this.workMetStandardsNoReasonWorkerStoppedTracking,
      workMetStandardsNoReasonNotAllWorkDoneDuringMonth: this.workMetStandardsNoReasonNotAllWorkDoneDuringMonth,
      workMetStandardsNoReasonOther: this.workMetStandardsNoReasonOther,
      workMetStandardsNoReasonOtherText: this.workMetStandardsNoReasonOtherText,
      supervisorComments: this.supervisorComments
    };

    if (this.violations.length > 0) {
      databaseObject.violations = this.violations;
    }
    if (this.activities.length > 0) {
      databaseObject.activities = this.activities;
    }

    return Object.assign({}, super.toDatabaseObject(), databaseObject);
  }

  /* Creates a Doc2019FieldInvestigationTimeFormData class given the string stored in the database */
  static createFromDatabaseString(data: string): Doc2019FieldInvestigationTimeFormData {
    return new Doc2019FieldInvestigationTimeFormData(JSON.parse(data));
  }


  /* creates a data server object to send to the server */
  toServerObject(): ApiSaveSurveyData {
    const serverObject = super.toServerObject();

    SharedFormData.addSurveyItem(serverObject, 'OffenderId', this.offenderId);
    SharedFormData.addSurveyItem(serverObject, 'InvestigationType', this.investigationType);
    SharedFormData.addSurveyItem(serverObject, 'IntakeGeneralStatus',
      this.investigationType === Doc2019FieldInvestigationTimeInvestigationTypeOption.IntakeGeneral ? this.intakeGeneralStatus : null);
    SharedFormData.addSurveyItem(serverObject, 'RevocationHearingWaived',
      this.investigationType === Doc2019FieldInvestigationTimeInvestigationTypeOption.Revocation ? this.revocationHearingWaived : null);

    SharedFormData.addBooleanSurveyItem(serverObject, 'ProgramEmpBracelet', this.programEmpBracelet);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ProgramGps', this.programGps);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ProgramOars', this.programOars);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ProgramSoberlinkTad', this.programSoberlinkTad);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ProgramSpecialtyCourt', this.programSpecialtyCourt);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ProgramNoneApply', this.programNoneApply);

    this.activities.forEach((activity, index) => {
      SharedFormData.addTimestampSurveyItem(serverObject, 'ActivityDate', activity.activityDate, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityViolationWork', activity.violationWork, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityActivity', activity.activity, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityActivityOther',
        activity.activity === Doc2019FieldInvestigationTimeActivityActivityOption.Other ? activity.activityOther : null, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOffender', activity.personInvolvedOffender, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedCollateral', activity.personInvolvedCollateral, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedUnitSupervisor', activity.personInvolvedUnitSupervisor, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOtherDocStaff', activity.personInvolvedOtherDocStaff, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedVictim', activity.personInvolvedVictim, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOther', activity.personInvolvedOther, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedNone', activity.personInvolvedNone, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityMethod', activity.method, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityAgentLocation', activity.agentLocation, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityActivityMinutesSpent', activity.activityMinutesSpent, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityTravelMinutesSpent', activity.travelMinutesSpent, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityWaitingMinutesSpent', activity.waitingMinutesSpent, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityNotes', activity.notes, index);
    });

    SharedFormData.addBooleanSurveyItem(serverObject, 'CaseActivityAbsconder', this.caseActivityAbsconder);
    SharedFormData.addBooleanSurveyItem(serverObject, 'CaseActivityInstallRemoveMonitoringDevice', this.caseActivityInstallRemoveMonitoringDevice);
    SharedFormData.addBooleanSurveyItem(serverObject, 'CaseActivityIntrastateTransferOutgoing', this.caseActivityIntrastateTransferOutgoing);
    SharedFormData.addBooleanSurveyItem(serverObject, 'CaseActivityViolation', this.caseActivityViolation);
    SharedFormData.addBooleanSurveyItem(serverObject, 'CaseActivityAtr', this.caseActivityAtr);
    SharedFormData.addBooleanSurveyItem(serverObject, 'CaseActivityRevocation', this.caseActivityRevocation);
    SharedFormData.addBooleanSurveyItem(serverObject, 'CaseActivityNoneApply', this.caseActivityNoneApply);

    if (this.caseActivityViolation) {
      this.violations.forEach((violation, index) => {
        SharedFormData.addTimestampSurveyItem(serverObject, 'ViolationAddressedDate', violation.violationAddressedDate, index);
        SharedFormData.addSurveyItem(serverObject, 'ActivityCustodyViolation', violation.custodyViolation, index);
        SharedFormData.addTimestampSurveyItem(serverObject, 'ViolationDecisionDate', violation.decisionDate, index);
      });
    }

    SharedFormData.addSurveyItem(serverObject, 'AgentComments', this.agentComments);

    SharedFormData.addSurveyItem(serverObject, 'WorkMetStandards', this.workMetStandards);
    SharedFormData.addBooleanSurveyItem(serverObject, 'WorkMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet',
      this.workMetStandards === YesNoOption.No ? this.workMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'WorkMetStandardsNoReasonActivitiesNotReflective',
      this.workMetStandards === YesNoOption.No ? this.workMetStandardsNoReasonActivitiesNotReflective : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'WorkMetStandardsNoReasonWorkerStoppedTracking',
      this.workMetStandards === YesNoOption.No ? this.workMetStandardsNoReasonWorkerStoppedTracking : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'WorkMetStandardsNoReasonNotAllWorkDoneDuringMonth',
      this.workMetStandards === YesNoOption.No ? this.workMetStandardsNoReasonNotAllWorkDoneDuringMonth : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'WorkMetStandardsNoReasonOther',
      this.workMetStandards === YesNoOption.No ? this.workMetStandardsNoReasonOther : false);
    SharedFormData.addSurveyItem(serverObject, 'WorkMetStandardsNoReasonOtherText',
      this.workMetStandards === YesNoOption.No && this.workMetStandardsNoReasonOther ? this.workMetStandardsNoReasonOtherText : null);
    SharedFormData.addSurveyItem(serverObject, 'SupervisorComments', this.supervisorComments);

    return serverObject;
  }

  /* creates this class from a data server object
  * fullRecord is false for records created from api/getlist */
  static createFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): Doc2019FieldInvestigationTimeFormData {
    return new Doc2019FieldInvestigationTimeFormData(Doc2019FieldInvestigationTimeFormData.createDataFromServerObject(serverObject, fullRecord));
  }

  /* creates the data object to parse into a new class instance, exposed as a separate function so child classes can use it
  * fullRecord is false for records created from api/getlist */
  static createDataFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): any {
    const data = SharedFormData.createDataFromServerObject(serverObject, fullRecord);

    if (serverObject.SurveyItems && isArray(serverObject.SurveyItems)) {
      serverObject.SurveyItems.forEach(item => {
        let itemIndex = 0;
        switch (item.ItemName) {
          case 'OffenderId':
            data.offenderId = item.ItemValue;
            break;
          case 'InvestigationType':
            data.investigationType = item.ItemValue;
            break;
          case 'IntakeGeneralStatus':
            data.intakeGeneralStatus = item.ItemValue;
            break;
          case 'RevocationHearingWaived':
            data.revocationHearingWaived = item.ItemValue;
            break;

          case 'ProgramEmpBracelet':
            data.programEmpBracelet = (item.ItemValue === 'true');
            break;
          case 'ProgramGps':
            data.programGps = (item.ItemValue === 'true');
            break;
          case 'ProgramOars':
            data.programOars = (item.ItemValue === 'true');
            break;
          case 'ProgramSoberlinkTad':
            data.programSoberlinkTad = (item.ItemValue === 'true');
            break;
          case 'ProgramSpecialtyCourt':
            data.programSpecialtyCourt = (item.ItemValue === 'true');
            break;
          case 'ProgramNoneApply':
            data.programNoneApply = (item.ItemValue === 'true');
            break;

          case 'ActivityDate':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'ActivityViolationWork':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].violationWork = item.ItemValue;
            break;
          case 'ActivityActivity':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activity = item.ItemValue;
            break;
          case 'ActivityActivityOther':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityOther = item.ItemValue;
            break;
          case 'ActivityPersonInvolvedOffender':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOffender = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedCollateral':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedCollateral = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedUnitSupervisor':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedUnitSupervisor = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOtherDocStaff':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOtherDocStaff = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedVictim':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedVictim = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOther':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOther = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedNone':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedNone = (item.ItemValue === 'true');
            break;
          case 'ActivityMethod':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].method = item.ItemValue;
            break;
          case 'ActivityAgentLocation':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].agentLocation = item.ItemValue;
            break;
          case 'ActivityActivityMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityTravelMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].travelMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityWaitingMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].waitingMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityNotes':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].notes = item.ItemValue;
            break;

          case 'CaseActivityAbsconder':
            data.caseActivityAbsconder = (item.ItemValue === 'true');
            break;
          case 'CaseActivityInstallRemoveMonitoringDevice':
            data.caseActivityInstallRemoveMonitoringDevice = (item.ItemValue === 'true');
            break;
          case 'CaseActivityIntrastateTransferOutgoing':
            data.caseActivityIntrastateTransferOutgoing = (item.ItemValue === 'true');
            break;
          case 'CaseActivityViolation':
            data.caseActivityViolation = (item.ItemValue === 'true');
            break;
          case 'CaseActivityAtr':
            data.caseActivityAtr = (item.ItemValue === 'true');
            break;
          case 'CaseActivityRevocation':
            data.caseActivityRevocation = (item.ItemValue === 'true');
            break;
          case 'CaseActivityNoneApply':
            data.caseActivityNoneApply = (item.ItemValue === 'true');
            break;

          case 'ViolationAddressedDate':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataViolationCount(data, itemIndex);
            data.violations[itemIndex].violationAddressedDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'ActivityCustodyViolation':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataViolationCount(data, itemIndex);
            data.violations[itemIndex].custodyViolation = item.ItemValue;
            break;
          case 'ViolationDecisionDate':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Doc2019FieldInvestigationTimeFormData.setDataViolationCount(data, itemIndex);
            data.violations[itemIndex].decisionDate = parseInt(item.ItemValue, 10) * 1000;
            break;

          case 'AgentComments':
            data.agentComments = item.ItemValue;
            break;

          case 'WorkMetStandards':
            data.workMetStandards = item.ItemValue;
            break;
          case 'WorkMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet':
            data.workMetStandardsNoReasonNumberOfContactsOtherStandardsNotMet = (item.ItemValue === 'true');
            break;
          case 'WorkMetStandardsNoReasonActivitiesNotReflective':
            data.workMetStandardsNoReasonActivitiesNotReflective = (item.ItemValue === 'true');
            break;
          case 'WorkMetStandardsNoReasonWorkerStoppedTracking':
            data.workMetStandardsNoReasonWorkerStoppedTracking = (item.ItemValue === 'true');
            break;
          case 'WorkMetStandardsNoReasonNotAllWorkDoneDuringMonth':
            data.workMetStandardsNoReasonNotAllWorkDoneDuringMonth = (item.ItemValue === 'true');
            break;
          case 'WorkMetStandardsNoReasonOther':
            data.workMetStandardsNoReasonOther = (item.ItemValue === 'true');
            break;
          case 'WorkMetStandardsNoReasonOtherText':
            data.workMetStandardsNoReasonOtherText = item.ItemValue;
            break;
          case 'SupervisorComments':
            data.supervisorComments = item.ItemValue;
            break;

          default:
            console.error('Unknown item name (' + item.ItemName + ') while parsing SurveyItems');
        }
      });
    }

    if (data.activities && isArray(data.activities)) {
      data.activities.forEach(activity => {
        const personInvolvedArray = getActivityPersonInvolvedArray(activity);
        activity.personInvolvedDisplay = personInvolvedArray.length ? personInvolvedArray.join(', ') : null;
      })
    }

    return data;
  }

  /* creates empty placeholders in the activities array on data up to index */
  static setDataActivityCount(data: any, index: number) {
    if (!data.activities) data.activities = [];
    while (data.activities.length <= index) {
      const newActivity: Doc2019FieldInvestigationTimeActivityData = {
        index: data.activities.length,
        activityDate: null,
        violationWork: null,
        activity: null,
        activityOther: null,
        personInvolvedDisplay: null,
        personInvolvedOffender: false,
        personInvolvedCollateral: false,
        personInvolvedUnitSupervisor: false,
        personInvolvedOtherDocStaff: false,
        personInvolvedVictim: false,
        personInvolvedOther: false,
        personInvolvedNone: false,
        method: null,
        agentLocation: null,
        activityMinutesSpent: null,
        travelMinutesSpent: null,
        waitingMinutesSpent: null,
        notes: null
      };
      data.activities.push(newActivity);
    }
  }

  /* creates empty placeholders in the violations array on data up to index */
  static setDataViolationCount(data: any, index: number) {
    if (!data.violations) data.violations = [];
    while (data.violations.length <= index) {
      const newViolation: Doc2019FieldInvestigationTimeViolationData = {
        index: data.violations.length,
        violationAddressedDate: null,
        custodyViolation: null,
        decisionDate: null
      };
      data.violations.push(newViolation);
    }
  }
}
