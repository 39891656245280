import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Humboldt2020OngoingEfcAdoptActivityListFilterDialogData } from './humboldt2020-ongoing-efc-adopt-activity-list-filter-dialog-data';

@Component({
  selector: 'app-humboldt2020-ongoing-efc-adopt-activity-list-filter-dialog',
  templateUrl: './humboldt2020-ongoing-efc-adopt-activity-list-filter-dialog.component.html',
  styleUrls: ['./humboldt2020-ongoing-efc-adopt-activity-list-filter-dialog.component.css']
})
export class Humboldt2020OngoingEfcAdoptActivityListFilterDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<Humboldt2020OngoingEfcAdoptActivityListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Humboldt2020OngoingEfcAdoptActivityListFilterDialogData
  ) { }

  clear(): void {
    this.data.clearFunction();
  }

  close(): void {
    this.dialogRef.close();
  }
}
