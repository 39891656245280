export enum Humboldt2020ResourceFamilyApprovalActivityPersonInvolvedOption {
  ResourceFamily = 'Resource family',
  HouseholdMember = 'Household member',
  RegularlyPresentAdult = 'Regularly present adult',
  YouthInHome = 'Youth in home',
  CollateralCommunity = 'Collateral/community',
  Supervisor = 'Supervisor',
  OtherCwsStaff = 'Other CWS staff',
  Tribe = 'Tribe',
  LegalConsultant = 'Legal consultant',
  None = 'None',
  Other = 'Other'
}
