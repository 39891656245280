import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DatabaseService } from '../database/database.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UserData } from './user-data';
import { MatDialog } from '@angular/material';
import { ProcessingDialogComponent } from '../processing-dialog/processing-dialog.component';
import { ProcessingDialogData } from '../processing-dialog/processing-dialog-data';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiTokenResponse } from './api-token-response';
import { ApiUserInfoResponse } from './api-user-info-response';
import { OnlineService } from '../online/online.service';
import { SharedFormData } from '../form/shared-form-data';
import { MessageDialogData } from '../message-dialog/message-dialog-data';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { getCurrentMillisecondTimestampRoundedToNearestSecond } from '../utility-functions';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = new BehaviorSubject<boolean | null>(null);
  token: string | null = null;
  user: UserData | null = null;
  logoutCheck: (() => Promise<boolean>) | null = null;
  // This is used to populate the change password form - current password, when redirected from login
  tempPasswordHolder: string | null = null;

  constructor(
    private databaseService: DatabaseService,
    private dialog: MatDialog,
    private http: HttpClient,
    private onlineService: OnlineService,
    private router: Router
  ) {
    this.initialize();
  }

  /* Loads a user from IndexedDB if one exists */
  async initialize(): Promise<void> {
    const processingData: ProcessingDialogData = {
      message: new BehaviorSubject<string>('')
    };
    this.dialog.open(ProcessingDialogComponent, {
      data: processingData,
      disableClose: true,
      width: '800px'
    });

    try {
      processingData.message.next('Loading user record from device');
      const user = await this.databaseService.getCurrentUser();
      if (user) {
        this.token = user.token;
        this.user = user;
        this.isLoggedIn.next(true);
        this.databaseService.getCurrentForm(SharedFormData.createFromDatabaseString).then(formData => {
          this.formInProgressDialog(formData);
        });
      } else {
        this.token = null;
        this.user = null;
        this.isLoggedIn.next(false);
      }
      processingData.message.complete();
    } catch(error) {
      processingData.message.error(error);
    }
  }

  private formInProgressDialog(formData: SharedFormData | null): void {
    if (formData && ('/form/' + formData.formType.id + '/' + formData.id) !== this.router.url) {
      const dialogData: MessageDialogData = {
        title: 'Form In Progress',
        message: 'You currently have a form in progress on this device. Would you like to load it?',
        okButtonLabel: 'Load Form'
      };

      this.dialog.open(MessageDialogComponent, {
        data: dialogData,
        width: '800px'
      }).afterClosed().subscribe((result: boolean | null) => {
        if (result) {
          this.router.navigate(['/form', formData.formType.id, formData.id]);
        } else if (result === false) {
          // Cancel button pressed, not off dialog clicked
          this.databaseService.deleteCurrentForm();
        }
      });
    }
  }

  /* Logs in a user, redirects to form list page when done */
  async login(username: string, password: string): Promise<void> {
    if (this.onlineService.online.value) {
      let loginOfflineOption = true;
      const processingData: ProcessingDialogData = {
        message: new BehaviorSubject<string>(''),
        additionalButtonText: new BehaviorSubject<string | null>(null),
        additionalButtonAction: new BehaviorSubject<(() => void) | null>(null),
      };
      this.dialog.open(ProcessingDialogComponent, {
        data: processingData,
        disableClose: true,
        width: '800px'
      });

      try {
        processingData.message.next('Verifying the login credentials with the data server.');
        const tokenResult = await this.getUserTokenFromDataServer(username, password);
        this.token = tokenResult.access_token; // setting so the next api call will work

        processingData.message.next('Getting authenticated user data from the data server.');
        const userInfoResult = await this.getUserInfoFromDataServer();

        loginOfflineOption = false;

        this.user = UserData.createFromApi(userInfoResult, tokenResult);

        processingData.message.next('Verifying user encryption key provided by the data server with the local device.');
        if (!await this.databaseService.verifyUserEncryptionKey(this.user.id, this.user.encryptionKey)) {
          processingData.message.error('Encryption key provided by the server does not match the local device user encryption check.' +
            ' This can be fixed by clearing the local device\'s data (using the gear on the upper right).' +
            ' Clearing the data will remove any offline saved data on this device.');
          return;
        }

        processingData.message.next('Saving user record to the local device.');
        await this.databaseService.setCurrentUser(this.user);
        await this.databaseService.saveUserOfflineLogin(username, password, this.user.id, this.user.encryptionKey);
        this.isLoggedIn.next(true);

        processingData.message.complete();
        if (this.user.passwordResetRequired) {
          this.tempPasswordHolder = password;
          this.router.navigate(['/change-password']);
          return;
        }
        const currentFormData = await this.databaseService.getCurrentForm(SharedFormData.createFromDatabaseString);
        if (currentFormData) {
          this.formInProgressDialog(currentFormData);
        }
        this.router.navigate(['/form-list']);
      } catch (error) {
        this.token = null;
        this.user = null;
        this.isLoggedIn.next(false);
        if (loginOfflineOption && error !== 'Incorrect username or password.') {
          if (processingData.additionalButtonText) {
            processingData.additionalButtonText.next('Login Offline');
          }
          if (processingData.additionalButtonAction) {
            processingData.additionalButtonAction.next(() => {
              this.loginOffline(username, password);
            });
          }
        }
        console.error(error);
        processingData.message.error(error);
      }
    } else {
      this.loginOffline(username, password);
    }
  }

  private async getUserTokenFromDataServer(username: string, password: string): Promise<ApiTokenResponse> {
    let tokenResult: ApiTokenResponse | null = null;
    try {
      // customEncoder needed because by default angular doesn't encode + = /
      const params = new HttpParams({encoder: this.customEncoder})
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', password);

      tokenResult = await this.http.post<ApiTokenResponse>(environment.apiTokenUrl, params).toPromise();
    } catch (error) {
      console.error('Error returned from ' + environment.apiTokenUrl, error);
      if (error.status) {
        switch (error.status) {
          case 400: // Bad Request
            throw new Error('Incorrect username or password.');
          case 500: // Server Error
            throw new Error('Data server returned an internal server error (500) while verifying the login credentials.');
          case 504: // Error Generated by the Service worker
            throw('There was an error communicating with the data server while verifying the login credentials.');
          default:
            throw new Error('Data server returned an error (' + error.status + ') while verifying the login credentials.');
        }
      }
      throw new Error('Unknown error occurred while verifying the login credentials with the data server.');
    }
    if (tokenResult === null) {
      throw new Error('Unknown error occurred while verifying the login credentials with the data server.');
    }
    return tokenResult;
  }

  private async getUserInfoFromDataServer(): Promise<ApiUserInfoResponse> {
    let userInfoResult: ApiUserInfoResponse | null = null;
    try {
      userInfoResult = await this.http.get<ApiUserInfoResponse>(environment.apiUserInfoUrl).toPromise();
    } catch (error) {
      console.error('Error returned from ' + environment.apiUserInfoUrl, error);
      if (error.status) {
        switch (error.status) {
          case 401: // Unauthorized
            throw new Error('Data server returned an unauthorized error (401) while getting authenticated user data.');
          case 500: // Server Error
            throw new Error('Data server returned an internal server error (500) while getting authenticated user data.');
          case 504: // Error Generated by the Service worker
            throw('There was an error communicating with the data server while getting authenticated user data.');
          default:
            throw new Error('Data server returned an error (' + error.status + ') while getting authenticated user data.');
        }
      }
      throw new Error('Unknown error occurred while getting authenticated user data from the data server.');
    }

    if (userInfoResult === null) {
      throw new Error('Unknown error occurred while getting authenticated user data from the data server.');
    }
    return userInfoResult;
  }

  private async loginOffline(username: string, password: string): Promise<void> {
    const processingData: ProcessingDialogData = {
      message: new BehaviorSubject<string>('')
    };
    this.dialog.open(ProcessingDialogComponent, {
      data: processingData,
      disableClose: true,
      width: '800px'
    });

    try {
      processingData.message.next('Verifying the login credentials with the local device.');
      const userOfflineLogin = await this.databaseService.getUserOfflineLogin(username, password);

      processingData.message.next('Verifying user encryption key with the local device.');
      if (!await this.databaseService.verifyUserEncryptionKey(userOfflineLogin.userId, userOfflineLogin.encryptionKey)) {
        processingData.message.error('Encryption key  does not match the local device user encryption check.');
        return;
      }

      processingData.message.next('Loading user record from the local device.');
      this.user = await this.databaseService.getUser(userOfflineLogin.userId, userOfflineLogin.encryptionKey);
      if (!this.user) {
        processingData.message.error('User not found.');
        return;
      }

      await this.databaseService.setCurrentUser(this.user);

      this.token = this.user.token;
      this.isLoggedIn.next(true);
      processingData.message.complete();
      const currentFormData = await this.databaseService.getCurrentForm(SharedFormData.createFromDatabaseString);
      if (currentFormData) {
        this.formInProgressDialog(currentFormData);
      }
      this.router.navigate(['/form-list']);
    } catch(error) {
      this.token = null;
      this.user = null;
      this.isLoggedIn.next(false);
      console.error(error);
      processingData.message.error(error);
    }
  }

  refreshToken(): Observable<boolean> {
      if (this.user && this.user.expireTimestamp <= getCurrentMillisecondTimestampRoundedToNearestSecond()) {
        // customEncoder needed because by default angular doesn't encode + = /
        const params = new HttpParams({encoder: this.customEncoder})
        .set('grant_type', 'refresh_token')
        .set('refresh_token', this.user.refreshToken);

        return this.http.post<ApiTokenResponse>(environment.apiTokenUrl, params).pipe(
          map(apiTokenResponse => {
            if (this.user && apiTokenResponse.access_token && apiTokenResponse.expires_in && apiTokenResponse.refresh_token) {
              this.token = apiTokenResponse.access_token;
              this.user.token = apiTokenResponse.access_token;
              this.user.expireTimestamp = getCurrentMillisecondTimestampRoundedToNearestSecond() + (apiTokenResponse.expires_in * 1000);
              this.user.refreshToken = apiTokenResponse.refresh_token;
              this.databaseService.setCurrentUser(this.user);
              return true;
            }
            return false;
          }),
          catchError(() => of(false)) // If there is an error refreshing the token, don't return anything so it doesn't retry
        );
      } else {
        return of(false);
      }
  }

  /* Logs out the current user, redirects to login page when done */
  async logout(): Promise<void> {
    if (this.logoutCheck && !await this.logoutCheck()) {
      return ;
    }

    const processingData: ProcessingDialogData = {
      message: new BehaviorSubject<string>('')
    };
    this.dialog.open(ProcessingDialogComponent, {
      data: processingData,
      disableClose: true,
      width: '800px'
    });

    try {
      processingData.message.next('Logging out user from device.');
      await this.databaseService.clearCurrentUser();
      this.token = null;
      this.user = null;
      this.isLoggedIn.next(false);
      processingData.message.complete();
      this.router.navigate(['/login']);
    } catch(error) {
      processingData.message.error(error);
    }
  }

  async changePassword(currentPassword: string, newPassword: string): Promise<boolean> {
    if (this.user === null) {
      return false; // Must be logged in to change password
    }

    const processingData: ProcessingDialogData = {
      message: new BehaviorSubject<string>('')
    };
    this.dialog.open(ProcessingDialogComponent, {
      data: processingData,
      disableClose: true,
      width: '800px'
    });

    try {
      processingData.message.next('Changing the password with the data server.');
      await this.changePasswordWithDataServer(currentPassword, newPassword);

      processingData.message.next('Updating user record on the local device.');
      this.tempPasswordHolder = null;
      this.user.passwordResetRequired = false; // remove password reset requirement
      await this.databaseService.setCurrentUser(this.user);
      await this.databaseService.saveUserOfflineLogin(this.user.username, newPassword, this.user.id, this.user.encryptionKey);

      processingData.message.complete();
      return true;
    } catch(error) {
      processingData.message.error(error);
      return false;
    }
  }

  private async changePasswordWithDataServer(currentPassword: string, newPassword: string): Promise<void> {
    let changePasswordResult: any | null = null;
    try {
      // customEncoder needed because by default angular doesn't encode + = /
      const params = new HttpParams({encoder: this.customEncoder})
      .set('current_password', currentPassword)
      .set('new_password', newPassword);

      await this.http.post<any>(environment.apiChangePasswordUrl, params).toPromise();
    } catch (error) {
      console.error('Error returned from ' + environment.apiChangePasswordUrl, error);
      if (error.status) {
        switch (error.status) {
          case 400: // Bad Request
            throw new Error('Data server returned a bad request error (400). It is likely your old password is wrong.');
          case 401: // Unauthorized
            throw new Error('Data server returned an unauthorized error (401). Try logging out and logging back in, then changing your password.');
          case 500: // Server Error
            throw new Error('Data server returned an internal server error (500) while attempting a change password request.');
          case 504: // Error Generated by the Service worker
            throw('There was an error communicating with the data server while attempting a change password request.');
          default:
            throw new Error('Data server returned an error (' + error.status + ') while attempting a change password request.');
        }
      }
      throw new Error('Unknown error occurred while attempting a change password request with the data server.');
    }
  }

  async forgotPassword(username: string): Promise<boolean> {
    const processingData: ProcessingDialogData = {
      message: new BehaviorSubject<string>('')
    };
    this.dialog.open(ProcessingDialogComponent, {
      data: processingData,
      disableClose: true,
      width: '800px'
    });

    try {
      processingData.message.next('Requesting a reset password code from the data server.');
      await this.forgotPasswordWithDataServer(username);

      processingData.message.complete();
      this.router.navigate(['/reset-password']);
      return true;
    } catch(error) {
      processingData.message.error(error);
      return false;
    }
  }

  private async forgotPasswordWithDataServer(username: string): Promise<void> {
    let changePasswordResult: any | null = null;
    try {
      // customEncoder needed because by default angular doesn't encode + = /
      const params = new HttpParams({encoder: this.customEncoder})
      .set('username', username)

      await this.http.post<any>(environment.apiForgotPasswordUrl, params).toPromise();
    } catch (error) {
      console.error('Error returned from ' + environment.apiForgotPasswordUrl, error);
      if (error.status) {
        switch (error.status) {
          case 400: // Bad Request
            throw new Error('Data server returned a bad request error (400) while attempting a forgot password request.');
          case 401: // Unauthorized
            throw new Error('Data server returned an unauthorized error (401) while attempting a forgot password request.');
          case 500: // Server Error
            throw new Error('Data server returned an internal server error (500) while attempting a forgot password request.');
          case 504: // Error Generated by the Service worker
            throw('There was an error communicating with the data server while attempting a forgot password request.');
          default:
            throw new Error('Data server returned an error (' + error.status + ') while attempting a forgot password request.');
        }
      }
      throw new Error('Unknown error occurred while attempting a forgot password request with the data server.');
    }
  }

  async resetPassword(username: string, code: string, newPassword: string): Promise<boolean> {
    const processingData: ProcessingDialogData = {
      message: new BehaviorSubject<string>('')
    };
    this.dialog.open(ProcessingDialogComponent, {
      data: processingData,
      disableClose: true,
      width: '800px'
    });

    try {
      processingData.message.next('Resetting password with the data server.');
      await this.resetPasswordWithDataServer(username, code, newPassword);
      processingData.message.complete();
      this.login(username, newPassword);
      return true;
    } catch(error) {
      processingData.message.error(error);
      return false;
    }
  }

  /**
   * This is needed because by default angular doesn't encode + = / which was causing problems in the resetPasswordWithDataServer function
   */
  private customEncoder = {
    encodeKey: (key: string) => {
      return encodeURIComponent(key);
    },
    encodeValue: (value: string) => {
      return encodeURIComponent(value);
    },
    decodeKey: (key: string) => {
      return decodeURIComponent(key);
    },
    decodeValue: (value: string) => {
      return decodeURIComponent(value);
    }
  };

  private async resetPasswordWithDataServer(username: string, code: string, newPassword: string): Promise<void> {
    let changePasswordResult: any | null = null;
    try {
      // customEncoder needed because by default angular doesn't encode + = /
      const params = new HttpParams({encoder: this.customEncoder})
      .set('username', username)
      .set('code', code)
      .set('new_password', newPassword);

      await this.http.post<any>(environment.apiResetPasswordUrl, params).toPromise();
    } catch (error) {
      console.error('Error returned from ' + environment.apiChangePasswordUrl, error);
      if (error.status) {
        switch (error.status) {
          case 400: // Bad Request
            throw new Error('Data server returned a bad request error (400). It is likely your username or code are wrong.');
          case 401: // Unauthorized
            throw new Error('Data server returned an unauthorized error (401) while attempting a reset password request.');
          case 500: // Server Error
            throw new Error('Data server returned an internal server error (500) while attempting a reset password request.');
          case 504: // Error Generated by the Service worker
            throw('There was an error communicating with the data server while attempting a reset password request.');
          default:
            throw new Error('Data server returned an error (' + error.status + ') while attempting a reset password request.');
        }
      }
      throw new Error('Unknown error occurred while attempting a reset password request with the data server.');
    }
  }
}
