import { Mddjs2020IntakeActivityActivityOption } from './mddjs2020-intake-activity-dialog/mddjs2020-intake-activity-activity-option.enum';
import { Mddjs2020IntakeActivityMethodOption } from './mddjs2020-intake-activity-dialog/mddjs2020-intake-activity-method-option.enum';
import { Mddjs2020IntakeActivityPlaceOption } from './mddjs2020-intake-activity-dialog/mddjs2020-intake-activity-place-option.enum';
import { Mddjs2020IntakeActivityPersonOption } from './mddjs2020-intake-activity-dialog/mddjs2020-intake-activity-person-option.enum';

export interface Mddjs2020IntakeActivityData {
  index: number | null; // Used to store the index when passing data to the dialog
  activityDate: number | null; // unix timestamp in milliseconds
  activity: Mddjs2020IntakeActivityActivityOption | null;
  personDisplay: string | null;
  personYouth: boolean;
  personParentGuardianCaretaker: boolean;
  personOtherDjsStaff: boolean;
  personServiceProvider: boolean;
  personDssStaff: boolean;
  personOtherCollateral: boolean;
  personNone: boolean;
  method: Mddjs2020IntakeActivityMethodOption | null;
  place: Mddjs2020IntakeActivityPlaceOption | null;
  activityMinutes: number | null;
  travelMinutes: number | null;
  waitingMinutes: number | null;
  activityDescription: string | null;
}

export function getActivityPersonArray(activity: Mddjs2020IntakeActivityData): string[] {
  const values: Array<string> = [];
  if (activity.personYouth) {
    values.push(Mddjs2020IntakeActivityPersonOption.Youth);
  }
  if (activity.personParentGuardianCaretaker) {
    values.push(Mddjs2020IntakeActivityPersonOption.ParentGuardianCaretaker);
  }
  if (activity.personOtherDjsStaff) {
    values.push(Mddjs2020IntakeActivityPersonOption.OtherDjsStaff);
  }
  if (activity.personServiceProvider) {
    values.push(Mddjs2020IntakeActivityPersonOption.ServiceProvider);
  }
  if (activity.personDssStaff) {
    values.push(Mddjs2020IntakeActivityPersonOption.DssStaff);
  }
  if (activity.personOtherCollateral) {
    values.push(Mddjs2020IntakeActivityPersonOption.OtherCollateral);
  }
  if (activity.personNone) {
    values.push(Mddjs2020IntakeActivityPersonOption.None);
  }
  return values;
}
