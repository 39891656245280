import { Routes } from '@angular/router';
import { FormListComponent } from './form-list/form-list.component';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { Doc2019OffenderSupervisionTimeComponent } from './project/doc2019/doc2019-offender-supervision-time/doc2019-offender-supervision-time.component';
import { Doc2019CaseSupportTimeComponent } from './project/doc2019/doc2019-case-support-time/doc2019-case-support-time.component';
import { Doc2019FieldInvestigationTimeComponent } from './project/doc2019/doc2019-field-investigation-time/doc2019-field-investigation-time.component';
import { UnsavedChangesGuardService } from './form/unsaved-changes-guard.service';
import { Humboldt2020CourtIntakeComponent } from './project/humboldt2020/humboldt2020-court-intake/humboldt2020-court-intake.component';
import { Humboldt2020IntakeScreeningComponent } from './project/humboldt2020/humboldt2020-intake-screening/humboldt2020-intake-screening.component';
import { Humboldt2020ReferralInvestigationComponent } from './project/humboldt2020/humboldt2020-referral-investigation/humboldt2020-referral-investigation.component';
import { Humboldt2020OngoingEfcAdoptComponent } from './project/humboldt2020/humboldt2020-ongoing-efc-adopt/humboldt2020-ongoing-efc-adopt.component';
import { Humboldt2020PlacementComponent } from './project/humboldt2020/humboldt2020-placement/humboldt2020-placement.component';
import { Humboldt2020ResourceFamilyApprovalComponent } from './project/humboldt2020/humboldt2020-resource-family-approval/humboldt2020-resource-family-approval.component';
import { Humboldt2020SupportAdminComponent } from './project/humboldt2020/humboldt2020-support-admin/humboldt2020-support-admin.component';
import { Humboldt2020IlsComponent } from './project/humboldt2020/humboldt2020-ils/humboldt2020-ils.component';
import { Mddjs2020IntakeComponent } from './project/mddjs2020/mddjs2020-intake/mddjs2020-intake.component';
import { Mddjs2020SupervisionComponent } from './project/mddjs2020/mddjs2020-supervision/mddjs2020-supervision.component';
import { Mddjs2020CaseSupportComponent } from './project/mddjs2020/mddjs2020-case-support/mddjs2020-case-support.component';
import { Mddjs2020CdEmComponent } from './project/mddjs2020/mddjs2020-cd-em/mddjs2020-cd-em.component';
import { Mddjs2020CdFloatingComponent } from './project/mddjs2020/mddjs2020-cd-floating/mddjs2020-cd-floating.component';
const ɵ0 = { title: 'NCCD Workload Study Login' }, ɵ1 = { title: 'Change Password' }, ɵ2 = { title: 'Forgot Password' }, ɵ3 = { title: 'Reset Password' }, ɵ4 = { title: 'Case Support and Non-Case Related Time' }, ɵ5 = { title: 'Intake/Investigation Time' }, ɵ6 = { title: 'Offender Supervision Time' }, ɵ7 = { title: 'Court Intake' }, ɵ8 = { title: 'Intake' }, ɵ9 = { title: 'Referral' }, ɵ10 = { title: 'Ongoing' }, ɵ11 = { title: 'Placement' }, ɵ12 = { title: 'Resource Family Approval (RFA)' }, ɵ13 = { title: 'Support and Administrative Activity' }, ɵ14 = { title: 'ILS' }, ɵ15 = { title: 'Intake and Investigation' }, ɵ16 = { title: 'Supervision' }, ɵ17 = { title: 'Case Support and Administrative Activity' }, ɵ18 = { title: 'CD/EM' }, ɵ19 = { title: 'CD/EM Floating' };
const routes = [
    { path: '', redirectTo: '/form-list', pathMatch: 'full' },
    { path: 'form-list', component: FormListComponent, canActivate: [AuthGuardService] },
    { path: 'login', component: LoginComponent, canActivate: [AuthGuardService], data: ɵ0 },
    { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuardService], data: ɵ1 },
    { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [AuthGuardService], data: ɵ2 },
    { path: 'reset-password', component: ResetPasswordComponent, canActivate: [AuthGuardService], data: ɵ3 },
    { path: 'form/1/:id', component: Doc2019CaseSupportTimeComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ4 },
    { path: 'form/2/:id', component: Doc2019FieldInvestigationTimeComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ5 },
    { path: 'form/3/:id', component: Doc2019OffenderSupervisionTimeComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ6 },
    { path: 'form/4/:id', component: Humboldt2020CourtIntakeComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ7 },
    { path: 'form/5/:id', component: Humboldt2020IntakeScreeningComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ8 },
    { path: 'form/6/:id', component: Humboldt2020ReferralInvestigationComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ9 },
    { path: 'form/7/:id', component: Humboldt2020OngoingEfcAdoptComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ10 },
    { path: 'form/8/:id', component: Humboldt2020PlacementComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ11 },
    { path: 'form/9/:id', component: Humboldt2020ResourceFamilyApprovalComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ12 },
    { path: 'form/10/:id', component: Humboldt2020SupportAdminComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ13 },
    { path: 'form/11/:id', component: Humboldt2020IlsComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ14 },
    { path: 'form/12/:id', component: Mddjs2020IntakeComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ15 },
    { path: 'form/13/:id', component: Mddjs2020SupervisionComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ16 },
    { path: 'form/14/:id', component: Mddjs2020CaseSupportComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ17 },
    { path: 'form/15/:id', component: Mddjs2020CdEmComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ18 },
    { path: 'form/16/:id', component: Mddjs2020CdFloatingComponent, canActivate: [AuthGuardService], canDeactivate: [UnsavedChangesGuardService], data: ɵ19 }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19 };
