<div class="center-content">
  <form [formGroup]="loginForm">
    <mat-form-field>
      <mat-label>Username</mat-label>
      <input matInput type="text" formControlName="username" autocomplete="username">
      <mat-error>{{loginForm.controls.username.errors | errorDisplay}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput type="password" formControlName="password" (keydown)="onPasswordKeydown($event)" autocomplete="current-password">
      <mat-error>{{loginForm.controls.password.errors | errorDisplay}}</mat-error>
      <mat-hint class="warning-color" *ngIf="passwordContainsPlus">Passwords set prior to 2/21 that contained a + will need to use a space instead of the +.</mat-hint>
    </mat-form-field>
  </form>

  <button mat-raised-button color="primary" (click)="login()">Login</button>
  <button mat-button color="primary" routerLink="/forgot-password">Forgot Password</button>
</div>

