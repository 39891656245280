import { SharedFormData } from '../../../form/shared-form-data';
import { UserData } from '../../../auth/user-data';
import { YesNoOption } from '../../../form/yes-no-option.enum';
import { ApiSaveSurveyData } from '../../../api/api-save-survey-data';
import { ApiGetSurveyResponse } from '../../../api/api-get-survey-response';
import { isArray } from "util";
import { Humboldt2020ResourceFamilyApprovalAssignmentTypeOption } from './humboldt2020-resource-family-approval-assignment-type-option.enum';
import { Humboldt2020ResourceFamilyApprovalRfTypeOption } from './humboldt2020-resource-family-approval-rf-type-option.enum';
import { Humboldt2020ResourceFamilyApprovalTribalAffiliationOption } from './humboldt2020-resource-family-approval-tribal-affiliation-option.enum';
import { Humboldt2020ResourceFamilyApprovalComplaintInvestigationFindingsOption } from './humboldt2020-resource-family-approval-complaint-investigation-findings-option.enum';
import { Humboldt2020ResourceFamilyApprovalActivityData, getActivityPersonInvolvedArray } from './humboldt2020-resource-family-approval-activity-data';

export class Humboldt2020ResourceFamilyApprovalFormData extends SharedFormData {
  facilityNumber: string | null;
  assignmentDate: number | null;
  assignmentType: Humboldt2020ResourceFamilyApprovalAssignmentTypeOption | null;
  rfType: Humboldt2020ResourceFamilyApprovalRfTypeOption | null;
  tribalAffiliation: Humboldt2020ResourceFamilyApprovalTribalAffiliationOption | null;
  tribalAffiliationTypeLocalTribe: boolean;
  tribalAffiliationTypeNonLocalTribe: boolean;
  adultsLivingInHome: number | null;
  regularlyPresentAdultsInHome: number | null;
  youthLivingInHome: number | null;
  icpc: YesNoOption | null;
  comments: string | null;

  investigatedErReferralOnThisFacilityDuringTrackingPeriod: YesNoOption | null;
  complaintInvestigationFindings: Humboldt2020ResourceFamilyApprovalComplaintInvestigationFindingsOption | null;
  studyCaseOccurrenceExemption: boolean;
  studyCaseOccurrenceAppeal: boolean;
  studyCaseOccurrenceNoneApply: boolean;

  // currentActivity should only be used by the current form when an activity is open and being edited
  currentActivity: Humboldt2020ResourceFamilyApprovalActivityData | null;
  activities: Humboldt2020ResourceFamilyApprovalActivityData[];

  standardsMet: YesNoOption | null;
  reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet: boolean;
  reasonsStandardsNotMetCaseworkerStoppedTracking: boolean;
  reasonsStandardsNotMetNotAllWorkDuringMonthRecorded: boolean;
  reasonsStandardsNotMetOther: boolean;
  supervisorComments: string | null;

  constructor(data: any) {
    super(data);

    this.facilityNumber = data.facilityNumber || null;
    this.assignmentDate = data.assignmentDate || null;
    this.assignmentType = data.assignmentType || null;
    this.rfType = data.rfType || null;
    this.tribalAffiliation = data.tribalAffiliation || null;
    this.tribalAffiliationTypeLocalTribe = data.tribalAffiliationTypeLocalTribe || false;
    this.tribalAffiliationTypeNonLocalTribe = data.tribalAffiliationTypeNonLocalTribe || false;
    this.adultsLivingInHome = data.adultsLivingInHome === 0 ? 0 : (data.adultsLivingInHome || null);
    this.regularlyPresentAdultsInHome = data.regularlyPresentAdultsInHome === 0 ? 0 : (data.regularlyPresentAdultsInHome || null);
    this.youthLivingInHome = data.youthLivingInHome === 0 ? 0 : (data.youthLivingInHome || null);
    this.icpc = data.icpc || null;
    this.comments = data.comments || null;

    this.investigatedErReferralOnThisFacilityDuringTrackingPeriod = data.investigatedErReferralOnThisFacilityDuringTrackingPeriod || null;
    this.complaintInvestigationFindings = data.complaintInvestigationFindings || null;
    this.studyCaseOccurrenceExemption = data.studyCaseOccurrenceExemption || false;
    this.studyCaseOccurrenceAppeal = data.studyCaseOccurrenceAppeal || false;
    this.studyCaseOccurrenceNoneApply = data.studyCaseOccurrenceNoneApply || false;

    this.currentActivity = data.currentActivity || null;
    this.activities = data.activities || [];

    this.standardsMet = data.standardsMet || null;
    this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = data.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet || false;
    this.reasonsStandardsNotMetCaseworkerStoppedTracking = data.reasonsStandardsNotMetCaseworkerStoppedTracking || false;
    this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = data.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded || false;
    this.reasonsStandardsNotMetOther = data.reasonsStandardsNotMetOther || false;
    this.supervisorComments = data.supervisorComments || null;
  }

  /* Creates a new Humboldt2020ResourceFamilyApprovalFormData class from the data */
  static createNewForm(id: number, formTypeId: number, user: UserData, timestamp: number): Humboldt2020ResourceFamilyApprovalFormData {
    return new Humboldt2020ResourceFamilyApprovalFormData(SharedFormData.newFormData(id, formTypeId, user, timestamp));
  }

  /* creates the object to store in the database */
  toDatabaseObject(): object {
    const databaseObject: any = {
      facilityNumber: this.facilityNumber,
      assignmentDate: this.assignmentDate,
      assignmentType: this.assignmentType,
      rfType: this.rfType,
      tribalAffiliation: this.tribalAffiliation,
      tribalAffiliationTypeLocalTribe: this.tribalAffiliationTypeLocalTribe,
      tribalAffiliationTypeNonLocalTribe: this.tribalAffiliationTypeNonLocalTribe,
      adultsLivingInHome: this.adultsLivingInHome,
      regularlyPresentAdultsInHome: this.regularlyPresentAdultsInHome,
      youthLivingInHome: this.youthLivingInHome,
      icpc: this.icpc,
      comments: this.comments,

      investigatedErReferralOnThisFacilityDuringTrackingPeriod: this.investigatedErReferralOnThisFacilityDuringTrackingPeriod,
      complaintInvestigationFindings: this.complaintInvestigationFindings,
      studyCaseOccurrenceExemption: this.studyCaseOccurrenceExemption,
      studyCaseOccurrenceAppeal: this.studyCaseOccurrenceAppeal,
      studyCaseOccurrenceNoneApply: this.studyCaseOccurrenceNoneApply,

      currentActivity: this.currentActivity,

      standardsMet: this.standardsMet,
      reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet: this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet,
      reasonsStandardsNotMetCaseworkerStoppedTracking: this.reasonsStandardsNotMetCaseworkerStoppedTracking,
      reasonsStandardsNotMetNotAllWorkDuringMonthRecorded: this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded,
      reasonsStandardsNotMetOther: this.reasonsStandardsNotMetOther,
      supervisorComments: this.supervisorComments
    };

    if (this.activities.length > 0) {
      databaseObject.activities = this.activities;
    }

    return Object.assign({}, super.toDatabaseObject(), databaseObject);
  }

  /* Creates a Humboldt2020ResourceFamilyApprovalFormData class given the string stored in the database */
  static createFromDatabaseString(data: string): Humboldt2020ResourceFamilyApprovalFormData {
    return new Humboldt2020ResourceFamilyApprovalFormData(JSON.parse(data));
  }

  /* creates a data server object to send to the server */
  toServerObject(): ApiSaveSurveyData {
    const serverObject = super.toServerObject();

    SharedFormData.addSurveyItem(serverObject, 'FacilityNumber', this.facilityNumber);
    SharedFormData.addTimestampSurveyItem(serverObject, 'AssignmentDate', this.assignmentDate);
    SharedFormData.addSurveyItem(serverObject, 'AssignmentType', this.assignmentType);
    SharedFormData.addSurveyItem(serverObject, 'RfType', this.rfType);
    SharedFormData.addSurveyItem(serverObject, 'TribalAffiliation',
      this.rfType === Humboldt2020ResourceFamilyApprovalRfTypeOption.MatchedHome ? this.tribalAffiliation : null);
    const askTribalAffiliationType = this.rfType === Humboldt2020ResourceFamilyApprovalRfTypeOption.MatchedHome &&
      this.tribalAffiliation === Humboldt2020ResourceFamilyApprovalTribalAffiliationOption.YesMaybe;
    SharedFormData.addBooleanSurveyItem(serverObject, 'TribalAffiliationTypeLocalTribe',
      askTribalAffiliationType ? this.tribalAffiliationTypeLocalTribe : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'TribalAffiliationTypeNonLocalTribe',
      askTribalAffiliationType ? this.tribalAffiliationTypeNonLocalTribe : false);
    SharedFormData.addNumberSurveyItem(serverObject, 'AdultsLivingInHome', this.adultsLivingInHome);
    SharedFormData.addNumberSurveyItem(serverObject, 'RegularlyPresentAdultsInHome', this.regularlyPresentAdultsInHome);
    SharedFormData.addNumberSurveyItem(serverObject, 'YouthLivingInHome', this.youthLivingInHome);
    SharedFormData.addSurveyItem(serverObject, 'Icpc',
      this.assignmentType === Humboldt2020ResourceFamilyApprovalAssignmentTypeOption.PreApproval ? this.icpc : null);
    SharedFormData.addSurveyItem(serverObject, 'Comments', this.comments);

    const askInvestigatedErReferralOnThisFacilityDuringTrackingPeriod =
      this.assignmentType === Humboldt2020ResourceFamilyApprovalAssignmentTypeOption.PreApproval ||
      this.assignmentType === Humboldt2020ResourceFamilyApprovalAssignmentTypeOption.ComplaintInvestigation;
    SharedFormData.addSurveyItem(serverObject, 'InvestigatedErReferralOnThisFacilityDuringTrackingPeriod',
      askInvestigatedErReferralOnThisFacilityDuringTrackingPeriod ? this.investigatedErReferralOnThisFacilityDuringTrackingPeriod : null);
    SharedFormData.addSurveyItem(serverObject, 'ComplaintInvestigationFindings',
      this.assignmentType === Humboldt2020ResourceFamilyApprovalAssignmentTypeOption.ComplaintInvestigation ? this.complaintInvestigationFindings : null);
    SharedFormData.addBooleanSurveyItem(serverObject, 'StudyCaseOccurrenceExemption', this.studyCaseOccurrenceExemption);
    SharedFormData.addBooleanSurveyItem(serverObject, 'StudyCaseOccurrenceAppeal', this.studyCaseOccurrenceAppeal);
    SharedFormData.addBooleanSurveyItem(serverObject, 'StudyCaseOccurrenceNoneApply', this.studyCaseOccurrenceNoneApply);

    this.activities.forEach((activity, index) => {
      SharedFormData.addTimestampSurveyItem(serverObject, 'ActivityDate', activity.activityDate, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityActivity', activity.activity, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedResourceFamily', activity.personInvolvedResourceFamily, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedHouseholdMember', activity.personInvolvedHouseholdMember, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedRegularlyPresentAdult', activity.personInvolvedRegularlyPresentAdult, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedYouthInHome', activity.personInvolvedYouthInHome, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedCollateralCommunity', activity.personInvolvedCollateralCommunity, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedSupervisor', activity.personInvolvedSupervisor, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOtherCwsStaff', activity.personInvolvedOtherCwsStaff, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedTribe', activity.personInvolvedTribe, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedLegalConsultant', activity.personInvolvedLegalConsultant, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOther', activity.personInvolvedOther, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedNone', activity.personInvolvedNone, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityMethod', activity.method, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityPlace', activity.place, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityActivityMinutesSpent', activity.activityMinutesSpent, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityTravelMinutesSpent', activity.travelMinutesSpent, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityDescription', activity.activityDescription, index);
    });

    SharedFormData.addSurveyItem(serverObject, 'StandardsMet', this.standardsMet);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetCaseworkerStoppedTracking',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetCaseworkerStoppedTracking : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetNotAllWorkDuringMonthRecorded',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetOther',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetOther : false);
    SharedFormData.addSurveyItem(serverObject, 'SupervisorComments', this.supervisorComments);

    return serverObject;
  }

  /* creates this class from a data server object
  * fullRecord is false for records created from api/getlist */
  static createFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): Humboldt2020ResourceFamilyApprovalFormData {
    return new Humboldt2020ResourceFamilyApprovalFormData(Humboldt2020ResourceFamilyApprovalFormData.createDataFromServerObject(serverObject, fullRecord));
  }

  /* creates the data object to parse into a new class instance, exposed as a separate function so child classes can use it
  * fullRecord is false for records created from api/getlist */
  static createDataFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): any {
    const data = SharedFormData.createDataFromServerObject(serverObject, fullRecord);

    if (serverObject.SurveyItems && isArray(serverObject.SurveyItems)) {
      serverObject.SurveyItems.forEach(item => {
        let itemIndex = 0;
        switch (item.ItemName) {
          case 'FacilityNumber':
            data.facilityNumber = item.ItemValue;
            break;
          case 'AssignmentDate':
            data.assignmentDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'AssignmentType':
            data.assignmentType = item.ItemValue;
            break;
          case 'RfType':
            data.rfType = item.ItemValue;
            break;
          case 'TribalAffiliation':
            data.tribalAffiliation = item.ItemValue;
            break;
          case 'TribalAffiliationTypeLocalTribe':
            data.tribalAffiliationTypeLocalTribe = (item.ItemValue === 'true');
            break;
          case 'TribalAffiliationTypeNonLocalTribe':
            data.tribalAffiliationTypeNonLocalTribe = (item.ItemValue === 'true');
            break;
          case 'AdultsLivingInHome':
            data.adultsLivingInHome = parseInt(item.ItemValue);
            break;
          case 'RegularlyPresentAdultsInHome':
            data.regularlyPresentAdultsInHome = parseInt(item.ItemValue);
            break;
          case 'YouthLivingInHome':
            data.youthLivingInHome = parseInt(item.ItemValue);
            break;
          case 'Icpc':
            data.icpc = item.ItemValue;
            break;
          case 'Comments':
            data.comments = item.ItemValue;
            break;

          case 'InvestigatedErReferralOnThisFacilityDuringTrackingPeriod':
            data.investigatedErReferralOnThisFacilityDuringTrackingPeriod = item.ItemValue;
            break;
          case 'ComplaintInvestigationFindings':
            data.complaintInvestigationFindings = item.ItemValue;
            break;
          case 'StudyCaseOccurrenceExemption':
            data.studyCaseOccurrenceExemption = (item.ItemValue === 'true');
            break;
          case 'StudyCaseOccurrenceAppeal':
            data.studyCaseOccurrenceAppeal = (item.ItemValue === 'true');
            break;
          case 'StudyCaseOccurrenceNoneApply':
            data.studyCaseOccurrenceNoneApply = (item.ItemValue === 'true');
            break;

          case 'ActivityDate':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'ActivityActivity':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activity = item.ItemValue;
            break;
          case 'ActivityPersonInvolvedResourceFamily':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedResourceFamily = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedHouseholdMember':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedHouseholdMember = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedRegularlyPresentAdult':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedRegularlyPresentAdult = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedYouthInHome':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedYouthInHome = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedCollateralCommunity':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedCollateralCommunity = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedSupervisor':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedSupervisor = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOtherCwsStaff':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOtherCwsStaff = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedTribe':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedTribe = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedLegalConsultant':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedLegalConsultant = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOther':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOther = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedNone':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedNone = (item.ItemValue === 'true');
            break;
          case 'ActivityMethod':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].method = item.ItemValue;
            break;
          case 'ActivityPlace':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].place = item.ItemValue;
            break;
          case 'ActivityActivityMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityTravelMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].travelMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityDescription':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020ResourceFamilyApprovalFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDescription = item.ItemValue;
            break;

          case 'StandardsMet':
            data.standardsMet = item.ItemValue;
            break;
          case 'ReasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet':
            data.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetCaseworkerStoppedTracking':
            data.reasonsStandardsNotMetCaseworkerStoppedTracking = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetNotAllWorkDuringMonthRecorded':
            data.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetOther':
            data.reasonsStandardsNotMetOther = (item.ItemValue === 'true');
            break;
          case 'SupervisorComments':
            data.supervisorComments = item.ItemValue;
            break;

          default:
            console.error('Unknown item name (' + item.ItemName + ') while parsing SurveyItems');
        }
      });
    }

    if (data.activities && isArray(data.activities)) {
      data.activities.forEach(activity => {
        const personInvolvedArray = getActivityPersonInvolvedArray(activity);
        activity.personInvolvedDisplay = personInvolvedArray.length ? personInvolvedArray.join(', ') : null;
      })
    }

    return data;
  }

  /* creates empty placeholders in the activities array on data up to index */
  static setDataActivityCount(data: any, index: number) {
    if (!data.activities) data.activities = [];
    while (data.activities.length <= index) {
      const newActivity: Humboldt2020ResourceFamilyApprovalActivityData = {
        index: data.activities.length,
        activityDate: null,
        activity: null,
        personInvolvedDisplay: null,
        personInvolvedResourceFamily: false,
        personInvolvedHouseholdMember: false,
        personInvolvedRegularlyPresentAdult: false,
        personInvolvedYouthInHome: false,
        personInvolvedCollateralCommunity: false,
        personInvolvedSupervisor: false,
        personInvolvedOtherCwsStaff: false,
        personInvolvedTribe: false,
        personInvolvedLegalConsultant: false,
        personInvolvedOther: false,
        personInvolvedNone: false,
        method: null,
        place: null,
        activityMinutesSpent: null,
        travelMinutesSpent: null,
        activityDescription: null
      };
      data.activities.push(newActivity);
    }
  }
}
