export enum Humboldt2020ResourceFamilyApprovalActivityListColumn {
  action = 'action',
  activityDate = 'activityDate',
  activity = 'activity',
  personInvolved = 'personInvolved',
  method = 'method',
  place = 'place',
  activityMinutesSpent = 'activityMinutesSpent',
  travelMinutesSpent = 'travelMinutesSpent',
  activityDescription = 'activityDescription'
}
