<div class="center-content">
  <p>
    If you have forgotten your password, enter your username here.
    The system will email you a code to use to reset your password.
  </p>

  <form [formGroup]="forgotPasswordForm">
    <mat-form-field>
      <mat-label>Username</mat-label>
      <input matInput type="text" formControlName="username" (keydown)="onUsernameKeydown($event)" autocomplete="username">
      <mat-error>{{forgotPasswordForm.controls.username.errors | errorDisplay}}</mat-error>
    </mat-form-field>
  </form>

  <button mat-raised-button color="primary" (click)="requestChangePasswordCode()">Request Code</button>
  <button mat-button color="primary" routerLink="/login">Cancel</button>
</div>
