import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { MessageDialogData } from '../message-dialog/message-dialog-data';

@Injectable({
  providedIn: 'root'
})
export class OnlineService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  online: BehaviorSubject<boolean>;
  private promptingForUpdate = false;

  constructor(
    private dialog: MatDialog,
    private swUpdate: SwUpdate
  ) {
    this.online = new BehaviorSubject<boolean>(navigator.onLine);
    fromEvent(window, 'online').pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.online.next(true);
    });
    fromEvent(window, 'offline').pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.online.next(false);
    });

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.pipe(
        takeUntil(this.unsubscribe$),
        filter(() => !this.promptingForUpdate)
      ).subscribe(event => {
        this.promptingForUpdate = true;
        const dialogData: MessageDialogData = {
          title: 'Update Available',
          message: 'An update is available to the NCCD Workload Study application. Would you like to update?',
          okButtonLabel: 'Update'
        };
        this.dialog.open(MessageDialogComponent, {
          data: dialogData,
          width: '800px'
        }).afterClosed().subscribe((result: boolean | null) => {
          if (result) {
            this.swUpdate.activateUpdate();
          }
          this.promptingForUpdate = false;
        });
      });

      this.swUpdate.activated.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
        window.location.reload();
      });

      // check for update every hour
      window.setInterval(this.checkForUpdate.bind(this), 60 * 60 * 1000);
    }
  }

  checkForUpdate(): void {
    if (this.online.value && this.swUpdate.isEnabled && !this.promptingForUpdate) {
      this.swUpdate.checkForUpdate();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
