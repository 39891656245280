import { filter, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
export class AuthGuardService {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate(next) {
        return this.authService.isLoggedIn.pipe(filter(isLoggedIn => isLoggedIn !== null), // Remove the null values which means we haven't figured out the state yet
        map(isLoggedIn => {
            const path = next.url[0].path;
            if (path === 'login' || path === 'forgot-password' || path === 'reset-password') {
                if (!isLoggedIn) {
                    return true;
                }
                else {
                    return this.router.createUrlTree(['/']);
                }
            }
            if (isLoggedIn) {
                return true;
            }
            else {
                return this.router.createUrlTree(['/login']);
            }
        }));
    }
}
AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AuthGuardService, providedIn: "root" });
