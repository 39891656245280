<h1 mat-dialog-title>Activity</h1>
<div mat-dialog-content>
  <form class="filter-form" [formGroup]="form">
    <mat-form-field>
      <mat-label>Date</mat-label>
      <input matInput [matDatepicker]="activityDatePicker" formControlName="activityDate" required autocomplete="off">
      <mat-datepicker-toggle matSuffix [for]="activityDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #activityDatePicker></mat-datepicker>
      <mat-error>{{form.controls.activityDate.errors | errorDisplay}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Activity</mat-label>
      <mat-select formControlName="activity" required>
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let option of activityOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
      <mat-error>{{form.controls.activity.errors | errorDisplay}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Person</mat-label>
      <mat-select formControlName="person" multiple required>
        <mat-option *ngFor="let option of personOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
      <mat-error>{{form.controls.person.errors | errorDisplay}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Method</mat-label>
      <mat-select formControlName="method" required>
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let option of methodOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
      <mat-error>{{form.controls.method.errors | errorDisplay}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Place</mat-label>
      <mat-select formControlName="place" required>
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let option of placeOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
      <mat-error>{{form.controls.place.errors | errorDisplay}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Activity Minutes</mat-label>
      <input matInput type="number" formControlName="activityMinutes" min="0" max="1440" step="1" digitOnly required autocomplete="off">
      <mat-error>{{form.controls.activityMinutes.errors | errorDisplay}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Travel Minutes</mat-label>
      <input matInput type="number" formControlName="travelMinutes" min="0" max="1440" step="1" digitOnly required autocomplete="off">
      <mat-error>{{form.controls.travelMinutes.errors | errorDisplay}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Waiting Minutes</mat-label>
      <input matInput type="number" formControlName="waitingMinutes" min="0" max="1440" step="1" digitOnly required autocomplete="off">
      <mat-error>{{form.controls.waitingMinutes.errors | errorDisplay}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Activity Description</mat-label>
      <textarea #activityDescription matInput maxlength="500" formControlName="activityDescription" [cdkTextareaAutosize]="true" [required]="currentActivity?.activity === activityOptionEnum.Other" autocomplete="off"></textarea>
      <mat-hint align="end">{{activityDescription.value.length}} / 500</mat-hint>
      <mat-error>{{form.controls.activityDescription.errors | errorDisplay}}</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="updateActivityList()">Update</button>
</div>
