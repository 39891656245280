/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./app-header/app-header.component.ngfactory";
import * as i3 from "./app-header/app-header.component";
import * as i4 from "./auth/auth.service";
import * as i5 from "./database/database.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "./online/online.service";
import * as i8 from "./title/title.service";
import * as i9 from "@angular/router";
import * as i10 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [["class", "mat-elevation-z4"]], null, null, null, i2.View_AppHeaderComponent_0, i2.RenderType_AppHeaderComponent)), i1.ɵdid(1, 180224, null, 0, i3.AppHeaderComponent, [i4.AuthService, i5.DatabaseService, i6.MatDialog, i7.OnlineService, i8.TitleService], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 180224, null, 0, i10.AppComponent, [i9.ActivatedRoute, i9.Router, i8.TitleService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
