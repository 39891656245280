import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isArray } from 'util';

/* checks that the only characters in the string are digits 0-9 */
export function digitsOnlyValidator(control: AbstractControl): {[key: string]: any} | null {
  const digitsRegExp = /^[0-9]*$/;
  return !digitsRegExp.test(control.value) ? {'digitsOnly': {value: control.value}} : null;
}

/* checks that the string matches the Humboldt2020 Referral/Case ID format */
export function Humboldt2020IdValidator(control: AbstractControl): {[key: string]: any} | null {
  if (control.value === null || control.value.length === 0) return null; // will be caught by required rather than this check
  const digitsRegExp = /^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{7}$/;
  return !digitsRegExp.test(control.value) ? {'Humboldt2020Id': {value: control.value}} : null;
}

/* checks that the string matches the Humboldt2020 FacilityNumber format */
export function Humboldt2020FacilityNumberValidator(control: AbstractControl): {[key: string]: any} | null {
  if (control.value === null || control.value.length === 0) return null; // will be caught by required rather than this check
  const digitsRegExp = /^[0-9]{9}$/;
  return !digitsRegExp.test(control.value) ? {'Humboldt2020FacilityNumber': {value: control.value}} : null;
}

/* checks multiselects that if None Apply/None apply is selected it is the only thing selected */
export function noneApplyValidator(control: AbstractControl): {[key: string]: any} | null {
  if (!isArray(control.value)) return null; // Not a multiselect
  if (control.value.length < 2) return null; // Only 1 or 0 values are selected
  if (control.value.indexOf('None Apply') === -1 && control.value.indexOf('None apply') === -1) return null; // None Apply not selected
  return {'noneApply': {value: control.value}};
}

/* checks multiselects that if None is selected it is the only thing selected */
export function noneValidator(control: AbstractControl): {[key: string]: any} | null {
  if (!isArray(control.value)) return null; // Not a multiselect
  if (control.value.length < 2) return null; // Only 1 or 0 values are selected
  if (control.value.indexOf('None') === -1) return null; // None not selected
  return {'none': {value: control.value}};
}

/* checks multiselects that only upto maxItemCount items are selected */
export function maxItemsValidator(maxItemCount: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (!isArray(control.value)) return null; // Not a multiselect
    if (control.value.length <= maxItemCount) return null;
    return {'maxItems': {requiredLength: maxItemCount, actualLength: control.value.length}};
  };
}

/* Validates password requirements */
export function passwordValidator(control: AbstractControl): {[key: string]: any} | null {
  if (control.value.length === 0) return null; // will be caught by required rather than this check

  if (control.value.length < 6) return {'passwordLength': {value: control.value}}; // Too short

  const numericRegExp = /[0-9]+/; // Has a numeric character
  if (!numericRegExp.test(control.value)) return {'passwordNumeric': {value: control.value}};

  const lowercaseRegExp = /[a-z]+/; // Has a lowercase character
  if (!lowercaseRegExp.test(control.value)) return {'passwordLowercase': {value: control.value}};

  const uppercaseRegExp = /[A-Z]+/; // Has an uppercase character
  if (!uppercaseRegExp.test(control.value)) return {'passwordUppercase': {value: control.value}};

  const nonAlphaNumericRegExp = /[^0-9a-zA-Z&]+/; // Has a non alpha numeric character
  if (!nonAlphaNumericRegExp.test(control.value)) return {'passwordNonAlpha': {value: control.value}};

  const ampersandRegExp = /&+/; // Has &
  if (ampersandRegExp.test(control.value)) return {'passwordAmpersand': {value: control.value}};

  if (control.value === 'Password123!') return {'passwordDefault': {value: control.value}};

  return null;
}
