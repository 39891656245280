import { SharedFormData } from '../../../form/shared-form-data';
import { UserData } from '../../../auth/user-data';
import { YesNoOption } from '../../../form/yes-no-option.enum';
import { ApiSaveSurveyData } from '../../../api/api-save-survey-data';
import { ApiGetSurveyResponse } from '../../../api/api-get-survey-response';
import { isArray } from "util";
import { Humboldt2020IlsCaseAssignmentLengthOption } from './humboldt2020-ils-case-assignment-length-option.enum';
import { Humboldt2020IlsStudyCaseTypeOption } from './humboldt2020-ils-study-case-type-option.enum';
import { Humboldt2020IlsYouthFromOutOfCountryOption } from './humboldt2020-ils-youth-from-out-of-country-option.enum';
import { Humboldt2020IlsTribalAffiliationOption } from './humboldt2020-ils-tribal-affiliation-option.enum';
import { Humboldt2020IlsActivityData, getActivityPersonInvolvedArray } from './humboldt2020-ils-activity-data';

export class Humboldt2020IlsFormData extends SharedFormData {
  referralId: string | null;
  initialIlsReferralDate: number | null;
  caseAssignmentLength: Humboldt2020IlsCaseAssignmentLengthOption | null;
  studyCaseType: Humboldt2020IlsStudyCaseTypeOption | null;
  youthFromOutOfCountry: Humboldt2020IlsYouthFromOutOfCountryOption | null;
  tribalAffiliation: Humboldt2020IlsTribalAffiliationOption | null;
  tribalAffiliationTypeLocalTribe: boolean;
  tribalAffiliationTypeNonLocalTribe: boolean;
  parentingYouth: YesNoOption | null;
  youthsChildOpenCwsCase: YesNoOption | null;
  comments: string | null;

  youthOpenToIntensiveCareCoordinationServicesDuringMonth: YesNoOption | null;

  // currentActivity should only be used by the current form when an activity is open and being edited
  currentActivity: Humboldt2020IlsActivityData | null;
  activities: Humboldt2020IlsActivityData[];

  standardsMet: YesNoOption | null;
  reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet: boolean;
  reasonsStandardsNotMetCaseworkerStoppedTracking: boolean;
  reasonsStandardsNotMetNotAllWorkDuringMonthRecorded: boolean;
  reasonsStandardsNotMetOther: boolean;
  supervisorComments: string | null;

  constructor(data: any) {
    super(data);

    this.referralId = data.referralId || null;
    this.initialIlsReferralDate = data.initialIlsReferralDate || null;
    this.caseAssignmentLength = data.caseAssignmentLength || null;
    this.studyCaseType = data.studyCaseType || null;
    this.youthFromOutOfCountry = data.youthFromOutOfCountry || null;
    this.tribalAffiliation = data.tribalAffiliation || null;
    this.tribalAffiliationTypeLocalTribe = data.tribalAffiliationTypeLocalTribe || false;
    this.tribalAffiliationTypeNonLocalTribe = data.tribalAffiliationTypeNonLocalTribe || false;
    this.parentingYouth = data.parentingYouth || null;
    this.youthsChildOpenCwsCase = data.youthsChildOpenCwsCase || null;
    this.comments = data.comments || null;

    this.youthOpenToIntensiveCareCoordinationServicesDuringMonth = data.youthOpenToIntensiveCareCoordinationServicesDuringMonth || null;

    this.currentActivity = data.currentActivity || null;
    this.activities = data.activities || [];

    this.standardsMet = data.standardsMet || null;
    this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = data.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet || false;
    this.reasonsStandardsNotMetCaseworkerStoppedTracking = data.reasonsStandardsNotMetCaseworkerStoppedTracking || false;
    this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = data.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded || false;
    this.reasonsStandardsNotMetOther = data.reasonsStandardsNotMetOther || false;
    this.supervisorComments = data.supervisorComments || null;
  }

  /* Creates a new Humboldt2020IlsFormData class from the data */
  static createNewForm(id: number, formTypeId: number, user: UserData, timestamp: number): Humboldt2020IlsFormData {
    return new Humboldt2020IlsFormData(SharedFormData.newFormData(id, formTypeId, user, timestamp));
  }

  /* creates the object to store in the database */
  toDatabaseObject(): object {
    const databaseObject: any = {
      referralId: this.referralId,
      initialIlsReferralDate: this.initialIlsReferralDate,
      caseAssignmentLength: this.caseAssignmentLength,
      studyCaseType: this.studyCaseType,
      youthFromOutOfCountry: this.youthFromOutOfCountry,
      tribalAffiliation: this.tribalAffiliation,
      tribalAffiliationTypeLocalTribe: this.tribalAffiliationTypeLocalTribe,
      tribalAffiliationTypeNonLocalTribe: this.tribalAffiliationTypeNonLocalTribe,
      parentingYouth: this.parentingYouth,
      youthsChildOpenCwsCase: this.youthsChildOpenCwsCase,
      comments: this.comments,

      youthOpenToIntensiveCareCoordinationServicesDuringMonth: this.youthOpenToIntensiveCareCoordinationServicesDuringMonth,

      currentActivity: this.currentActivity,

      standardsMet: this.standardsMet,
      reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet: this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet,
      reasonsStandardsNotMetCaseworkerStoppedTracking: this.reasonsStandardsNotMetCaseworkerStoppedTracking,
      reasonsStandardsNotMetNotAllWorkDuringMonthRecorded: this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded,
      reasonsStandardsNotMetOther: this.reasonsStandardsNotMetOther,
      supervisorComments: this.supervisorComments
    };

    if (this.activities.length > 0) {
      databaseObject.activities = this.activities;
    }

    return Object.assign({}, super.toDatabaseObject(), databaseObject);
  }

  /* Creates a Humboldt2020IlsFormData class given the string stored in the database */
  static createFromDatabaseString(data: string): Humboldt2020IlsFormData {
    return new Humboldt2020IlsFormData(JSON.parse(data));
  }

  /* creates a data server object to send to the server */
  toServerObject(): ApiSaveSurveyData {
    const serverObject = super.toServerObject();

    SharedFormData.addSurveyItem(serverObject, 'ReferralId', this.referralId);
    SharedFormData.addTimestampSurveyItem(serverObject, 'InitialIlsReferralDate', this.initialIlsReferralDate);
    SharedFormData.addSurveyItem(serverObject, 'CaseAssignmentLength', this.caseAssignmentLength);
    SharedFormData.addSurveyItem(serverObject, 'StudyCaseType', this.studyCaseType);
    SharedFormData.addSurveyItem(serverObject, 'YouthFromOutOfCountry', this.youthFromOutOfCountry);
    SharedFormData.addSurveyItem(serverObject, 'TribalAffiliation', this.tribalAffiliation);
    SharedFormData.addBooleanSurveyItem(serverObject, 'TribalAffiliationTypeLocalTribe',
      this.tribalAffiliation === Humboldt2020IlsTribalAffiliationOption.YesMaybe ? this.tribalAffiliationTypeLocalTribe : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'TribalAffiliationTypeNonLocalTribe',
      this.tribalAffiliation === Humboldt2020IlsTribalAffiliationOption.YesMaybe ? this.tribalAffiliationTypeNonLocalTribe : false);
    SharedFormData.addSurveyItem(serverObject, 'ParentingYouth', this.parentingYouth);
    SharedFormData.addSurveyItem(serverObject, 'YouthsChildOpenCwsCase',
      this.parentingYouth === YesNoOption.Yes ? this.youthsChildOpenCwsCase : null);
    SharedFormData.addSurveyItem(serverObject, 'Comments', this.comments);

    SharedFormData.addSurveyItem(serverObject, 'YouthOpenToIntensiveCareCoordinationServicesDuringMonth', this.youthOpenToIntensiveCareCoordinationServicesDuringMonth);

    this.activities.forEach((activity, index) => {
      SharedFormData.addTimestampSurveyItem(serverObject, 'ActivityDate', activity.activityDate, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityActivity', activity.activity, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedYouth', activity.personInvolvedYouth, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedTribe', activity.personInvolvedTribe, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOtherCwsTayStaff', activity.personInvolvedOtherCwsTayStaff, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedSupervisor', activity.personInvolvedSupervisor, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOtherServiceProvider', activity.personInvolvedOtherServiceProvider, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedOther', activity.personInvolvedOther, index);
      SharedFormData.addBooleanSurveyItem(serverObject, 'ActivityPersonInvolvedNone', activity.personInvolvedNone, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityMethod', activity.method, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityPlace', activity.place, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityActivityMinutesSpent', activity.activityMinutesSpent, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityTravelMinutesSpent', activity.travelMinutesSpent, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityDescription', activity.activityDescription, index);
    });

    SharedFormData.addSurveyItem(serverObject, 'StandardsMet', this.standardsMet);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetCaseworkerStoppedTracking',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetCaseworkerStoppedTracking : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetNotAllWorkDuringMonthRecorded',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded : false);
    SharedFormData.addBooleanSurveyItem(serverObject, 'ReasonsStandardsNotMetOther',
      this.standardsMet === YesNoOption.No ? this.reasonsStandardsNotMetOther : false);
    SharedFormData.addSurveyItem(serverObject, 'SupervisorComments', this.supervisorComments);

    return serverObject;
  }

  /* creates this class from a data server object
  * fullRecord is false for records created from api/getlist */
  static createFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): Humboldt2020IlsFormData {
    return new Humboldt2020IlsFormData(Humboldt2020IlsFormData.createDataFromServerObject(serverObject, fullRecord));
  }

  /* creates the data object to parse into a new class instance, exposed as a separate function so child classes can use it
  * fullRecord is false for records created from api/getlist */
  static createDataFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): any {
    const data = SharedFormData.createDataFromServerObject(serverObject, fullRecord);

    if (serverObject.SurveyItems && isArray(serverObject.SurveyItems)) {
      serverObject.SurveyItems.forEach(item => {
        let itemIndex = 0;
        switch (item.ItemName) {
          case 'ReferralId':
            data.referralId = item.ItemValue;
            break;
          case 'InitialIlsReferralDate':
            data.initialIlsReferralDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'CaseAssignmentLength':
            data.caseAssignmentLength = item.ItemValue;
            break;
          case 'StudyCaseType':
            data.studyCaseType = item.ItemValue;
            break;
          case 'YouthFromOutOfCountry':
            data.youthFromOutOfCountry = item.ItemValue;
            break;
          case 'TribalAffiliation':
            data.tribalAffiliation = item.ItemValue;
            break;
          case 'TribalAffiliationTypeLocalTribe':
            data.tribalAffiliationTypeLocalTribe = (item.ItemValue === 'true');
            break;
          case 'TribalAffiliationTypeNonLocalTribe':
            data.tribalAffiliationTypeNonLocalTribe = (item.ItemValue === 'true');
            break;
          case 'ParentingYouth':
            data.parentingYouth = item.ItemValue;
            break;
          case 'YouthsChildOpenCwsCase':
            data.youthsChildOpenCwsCase = item.ItemValue;
            break;
          case 'Comments':
            data.comments = item.ItemValue;
            break;

          case 'YouthOpenToIntensiveCareCoordinationServicesDuringMonth':
            data.youthOpenToIntensiveCareCoordinationServicesDuringMonth = item.ItemValue;
            break;

          case 'ActivityDate':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IlsFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'ActivityActivity':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IlsFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activity = item.ItemValue;
            break;
          case 'ActivityPersonInvolvedYouth':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IlsFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedYouth = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedTribe':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IlsFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedTribe = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOtherCwsTayStaff':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IlsFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOtherCwsTayStaff = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedSupervisor':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IlsFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedSupervisor = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOtherServiceProvider':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IlsFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOtherServiceProvider = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedOther':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IlsFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedOther = (item.ItemValue === 'true');
            break;
          case 'ActivityPersonInvolvedNone':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IlsFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].personInvolvedNone = (item.ItemValue === 'true');
            break;
          case 'ActivityMethod':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IlsFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].method = item.ItemValue;
            break;
          case 'ActivityPlace':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IlsFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].place = item.ItemValue;
            break;
          case 'ActivityActivityMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IlsFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityTravelMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IlsFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].travelMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityDescription':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020IlsFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDescription = item.ItemValue;
            break;

          case 'StandardsMet':
            data.standardsMet = item.ItemValue;
            break;
          case 'ReasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet':
            data.reasonsStandardsNotMetNumberOfContactsOtherStandardsNotMet = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetCaseworkerStoppedTracking':
            data.reasonsStandardsNotMetCaseworkerStoppedTracking = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetNotAllWorkDuringMonthRecorded':
            data.reasonsStandardsNotMetNotAllWorkDuringMonthRecorded = (item.ItemValue === 'true');
            break;
          case 'ReasonsStandardsNotMetOther':
            data.reasonsStandardsNotMetOther = (item.ItemValue === 'true');
            break;
          case 'SupervisorComments':
            data.supervisorComments = item.ItemValue;
            break;

          default:
            console.error('Unknown item name (' + item.ItemName + ') while parsing SurveyItems');
        }
      });
    }

    if (data.activities && isArray(data.activities)) {
      data.activities.forEach(activity => {
        const personInvolvedArray = getActivityPersonInvolvedArray(activity);
        activity.personInvolvedDisplay = personInvolvedArray.length ? personInvolvedArray.join(', ') : null;
      })
    }

    return data;
  }

  /* creates empty placeholders in the activities array on data up to index */
  static setDataActivityCount(data: any, index: number) {
    if (!data.activities) data.activities = [];
    while (data.activities.length <= index) {
      const newActivity: Humboldt2020IlsActivityData = {
        index: data.activities.length,
        activityDate: null,
        activity: null,
        personInvolvedDisplay: null,
        personInvolvedYouth: false,
        personInvolvedTribe: false,
        personInvolvedOtherCwsTayStaff: false,
        personInvolvedSupervisor: false,
        personInvolvedOtherServiceProvider: false,
        personInvolvedOther: false,
        personInvolvedNone: false,
        method: null,
        place: null,
        activityMinutesSpent: null,
        travelMinutesSpent: null,
        activityDescription: null
      };
      data.activities.push(newActivity);
    }
  }
}
