import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ProcessingDialogComponent } from '../processing-dialog/processing-dialog.component';
import { environment } from '../../environments/environment';
export class AppHeaderComponent {
    constructor(authService, databaseService, dialog, onlineService, titleService) {
        this.authService = authService;
        this.databaseService = databaseService;
        this.dialog = dialog;
        this.onlineService = onlineService;
        this.titleService = titleService;
        this.unsubscribe$ = new Subject();
        this.isLoggedIn = null;
        this.user = null;
        this.title = '';
        this.appVersion = '3.2';
        this.isProduction = environment.production;
        this.authService.isLoggedIn.pipe(takeUntil(this.unsubscribe$)).subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
            this.user = this.authService.user;
        });
        this.titleService.title.pipe(takeUntil(this.unsubscribe$)).subscribe(title => this.title = title);
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    clearDataDialog() {
        this.dialog.open(MessageDialogComponent, {
            data: {
                title: 'Clear Local Device Data',
                message: 'This will clear offline data stored on the local device. Any offline data that has not been synced to the data server for any users will be lost.' +
                    ' Are you sure you want to do this?',
                okButtonLabel: 'Clear Local Device Data'
            },
            width: '800px'
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.clearData();
            }
        });
    }
    clearData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const processingData = {
                message: new BehaviorSubject('')
            };
            this.dialog.open(ProcessingDialogComponent, {
                data: processingData,
                disableClose: true,
                width: '800px'
            });
            try {
                processingData.message.next('Clearing offline data');
                yield this.databaseService.clearAllData();
                processingData.message.complete();
            }
            catch (error) {
                processingData.message.error(error);
            }
        });
    }
}
