import { Doc2019FieldInvestigationTimeActivityPersonInvolvedOption } from './doc2019-field-investigation-time-activity-dialog/doc2019-field-investigation-time-activity-person-involved-option.enum';

export interface Doc2019FieldInvestigationTimeActivityData {
  index: number | null; // Used to store the index when passing data to the dialog
  activityDate: number | null; // unix timestamp in milliseconds
  violationWork: string | null;
  activity: string | null;
  activityOther: string | null;
  personInvolvedDisplay: string | null;
  personInvolvedOffender: boolean;
  personInvolvedCollateral: boolean;
  personInvolvedUnitSupervisor: boolean;
  personInvolvedOtherDocStaff: boolean;
  personInvolvedVictim: boolean;
  personInvolvedOther: boolean;
  personInvolvedNone: boolean;
  method: string | null;
  agentLocation: string | null;
  activityMinutesSpent: number | null;
  travelMinutesSpent: number | null;
  waitingMinutesSpent: number | null;
  notes: string | null;
}

export function getActivityPersonInvolvedArray(activity: Doc2019FieldInvestigationTimeActivityData): string[] {
  const values: Array<string> = [];
  if (activity.personInvolvedOffender) {
    values.push(Doc2019FieldInvestigationTimeActivityPersonInvolvedOption.Offender);
  }
  if (activity.personInvolvedCollateral) {
    values.push(Doc2019FieldInvestigationTimeActivityPersonInvolvedOption.Collateral);
  }
  if (activity.personInvolvedUnitSupervisor) {
    values.push(Doc2019FieldInvestigationTimeActivityPersonInvolvedOption.UnitSupervisor);
  }
  if (activity.personInvolvedOtherDocStaff) {
    values.push(Doc2019FieldInvestigationTimeActivityPersonInvolvedOption.OtherDocStaff);
  }
  if (activity.personInvolvedVictim) {
    values.push(Doc2019FieldInvestigationTimeActivityPersonInvolvedOption.Victim);
  }
  if (activity.personInvolvedOther) {
    values.push(Doc2019FieldInvestigationTimeActivityPersonInvolvedOption.Other);
  }
  if (activity.personInvolvedNone) {
    values.push(Doc2019FieldInvestigationTimeActivityPersonInvolvedOption.None);
  }
  return values;
}
