export enum Mddjs2020CdFloatingActivityListColumn {
  action = 'action',
  caseName = 'caseName',
  djsClientId = 'djsClientId',
  activityDate = 'activityDate',
  activity = 'activity',
  person = 'person',
  method = 'method',
  place = 'place',
  activityMinutes = 'activityMinutes',
  travelMinutes = 'travelMinutes',
  waitingMinutes = 'waitingMinutes',
  activityDescription = 'activityDescription'
}
