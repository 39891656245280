<div class="center-content">
  <p>
    You should have received an email with your username and code to reset your password.
    Either click the link in the email, or copy and paste those values here.
    Then enter a new password.
  </p>

  <form [formGroup]="resetPasswordForm">
    <mat-form-field>
      <mat-label>Username</mat-label>
      <input matInput type="text" formControlName="username" autocomplete="off">
      <mat-error>{{resetPasswordForm.controls.username.errors | errorDisplay}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Reset Password Code</mat-label>
      <input matInput type="text" formControlName="code" autocomplete="off">
      <mat-error>{{resetPasswordForm.controls.code.errors | errorDisplay}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>New Password</mat-label>
      <input matInput type="password" formControlName="newPassword" autocomplete="new-password">
      <mat-error>{{resetPasswordForm.controls.newPassword.errors | errorDisplay}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Retype New Password</mat-label>
      <input matInput type="password" formControlName="retypePassword" (keydown)="onNewPasswordKeydown($event)" autocomplete="new-password">
      <mat-error>{{resetPasswordForm.controls.retypePassword.errors | errorDisplay}}</mat-error>
      <mat-hint class="warning-color" *ngIf="resetPasswordForm.errors?.newPasswordsNoMatch">New passwords do not match.</mat-hint>
    </mat-form-field>
  </form>

  <button mat-raised-button color="primary" (click)="changePassword()">Change Password</button>
  <button mat-button color="primary" routerLink="/login">Cancel</button>
</div>
