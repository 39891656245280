import { SharedFormData } from '../../../form/shared-form-data';
import { UserData } from '../../../auth/user-data';
import { YesNoOption } from '../../../form/yes-no-option.enum';
import { ApiSaveSurveyData } from '../../../api/api-save-survey-data';
import { ApiGetSurveyResponse } from '../../../api/api-get-survey-response';
import { isArray } from "util";
import { Humboldt2020SupportAdminPrimaryProgramResponsibilityOption } from './humboldt2020-support-admin-primary-program-responsibility-option.enum';
import { Humboldt2020SupportAdminYearsOfExperienceOption } from './humboldt2020-support-admin-years-of-experience-option.enum';
import { Humboldt2020SupportAdminActivityData } from './humboldt2020-support-admin-activity-data';
import { Humboldt2020SupportAdminActivitySupportActivityOption } from './humboldt2020-support-admin-activity-dialog/humboldt2020-support-admin-activity-support-activity-option.enum';

export class Humboldt2020SupportAdminFormData extends SharedFormData {
  primaryProgramResponsibility: Humboldt2020SupportAdminPrimaryProgramResponsibilityOption | null;
  yearsOfExperience: Humboldt2020SupportAdminYearsOfExperienceOption | null;
  comments: string | null;

  // currentActivity should only be used by the current form when an activity is open and being edited
  currentActivity: Humboldt2020SupportAdminActivityData | null;
  activities: Humboldt2020SupportAdminActivityData[];

  allWorkDocumented: YesNoOption | null;
  supervisorComments: string | null;

  constructor(data: any) {
    super(data);

    this.primaryProgramResponsibility = data.primaryProgramResponsibility || null;
    this.yearsOfExperience = data.yearsOfExperience || null;
    this.comments = data.comments || null;

    this.currentActivity = data.currentActivity || null;
    this.activities = data.activities || [];

    this.allWorkDocumented = data.allWorkDocumented || null;
    this.supervisorComments = data.supervisorComments || null;
  }

  /* Creates a new Humboldt2020SupportAdminFormData class from the data */
  static createNewForm(id: number, formTypeId: number, user: UserData, timestamp: number): Humboldt2020SupportAdminFormData {
    return new Humboldt2020SupportAdminFormData(SharedFormData.newFormData(id, formTypeId, user, timestamp));
  }

  /* creates the object to store in the database */
  toDatabaseObject(): object {
    const databaseObject: any = {
      primaryProgramResponsibility: this.primaryProgramResponsibility,
      yearsOfExperience: this.yearsOfExperience,
      comments: this.comments,

      currentActivity: this.currentActivity,

      allWorkDocumented: this.allWorkDocumented,
      supervisorComments: this.supervisorComments
    };

    if (this.activities.length > 0) {
      databaseObject.activities = this.activities;
    }

    return Object.assign({}, super.toDatabaseObject(), databaseObject);
  }

  /* Creates a Humboldt2020SupportAdminFormData class given the string stored in the database */
  static createFromDatabaseString(data: string): Humboldt2020SupportAdminFormData {
    return new Humboldt2020SupportAdminFormData(JSON.parse(data));
  }

  /* creates a data server object to send to the server */
  toServerObject(): ApiSaveSurveyData {
    const serverObject = super.toServerObject();

    SharedFormData.addSurveyItem(serverObject, 'PrimaryProgramResponsibility', this.primaryProgramResponsibility);
    SharedFormData.addSurveyItem(serverObject, 'YearsOfExperience', this.yearsOfExperience);
    SharedFormData.addSurveyItem(serverObject, 'Comments', this.comments);

    this.activities.forEach((activity, index) => {
      SharedFormData.addTimestampSurveyItem(serverObject, 'ActivityDate', activity.activityDate, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityActivity', activity.activity, index);
      const askCwsProgramSupport = (activity.activity === Humboldt2020SupportAdminActivitySupportActivityOption.CwsSupportSubstituteCoverage ||
        activity.activity === Humboldt2020SupportAdminActivitySupportActivityOption.NonRemovalWarrantsOtherReports);
      SharedFormData.addSurveyItem(serverObject, 'ActivityCwsProgramSupport', askCwsProgramSupport ? activity.cwsProgramSupport : null, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityActivityMinutesSpent', activity.activityMinutesSpent, index);
      SharedFormData.addNumberSurveyItem(serverObject, 'ActivityTravelMinutesSpent', activity.travelMinutesSpent, index);
      SharedFormData.addSurveyItem(serverObject, 'ActivityDescription', activity.activityDescription, index);
    });

    SharedFormData.addSurveyItem(serverObject, 'AllWorkDocumented', this.allWorkDocumented);
    SharedFormData.addSurveyItem(serverObject, 'SupervisorComments', this.supervisorComments);

    return serverObject;
  }

  /* creates this class from a data server object
  * fullRecord is false for records created from api/getlist */
  static createFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): Humboldt2020SupportAdminFormData {
    return new Humboldt2020SupportAdminFormData(Humboldt2020SupportAdminFormData.createDataFromServerObject(serverObject, fullRecord));
  }

  /* creates the data object to parse into a new class instance, exposed as a separate function so child classes can use it
  * fullRecord is false for records created from api/getlist */
  static createDataFromServerObject(serverObject: ApiGetSurveyResponse, fullRecord: boolean): any {
    const data = SharedFormData.createDataFromServerObject(serverObject, fullRecord);

    if (serverObject.SurveyItems && isArray(serverObject.SurveyItems)) {
      serverObject.SurveyItems.forEach(item => {
        let itemIndex = 0;
        switch (item.ItemName) {
          case 'PrimaryProgramResponsibility':
            data.primaryProgramResponsibility = item.ItemValue;
            break;
          case 'YearsOfExperience':
            data.yearsOfExperience = item.ItemValue;
            break;
          case 'Comments':
            data.comments = item.ItemValue;
            break;

          case 'ActivityDate':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020SupportAdminFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDate = parseInt(item.ItemValue, 10) * 1000;
            break;
          case 'ActivityActivity':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020SupportAdminFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activity = item.ItemValue;
            break;
          case 'ActivityCwsProgramSupport':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020SupportAdminFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].askCwsProgramSupport = item.ItemValue;
            break;
          case 'ActivityActivityMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020SupportAdminFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityTravelMinutesSpent':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020SupportAdminFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].travelMinutesSpent = parseInt(item.ItemValue, 10);
            break;
          case 'ActivityDescription':
            itemIndex = parseInt(item.ItemIndex, 10);
            if (!SharedFormData.checkMultipleResponseIndex(item, itemIndex)) break;
            Humboldt2020SupportAdminFormData.setDataActivityCount(data, itemIndex);
            data.activities[itemIndex].activityDescription = item.ItemValue;
            break;

          case 'AllWorkDocumented':
            data.allWorkDocumented = item.ItemValue;
            break;
          case 'SupervisorComments':
            data.supervisorComments = item.ItemValue;
            break;

          default:
            console.error('Unknown item name (' + item.ItemName + ') while parsing SurveyItems');
        }
      });
    }

    return data;
  }

  /* creates empty placeholders in the activities array on data up to index */
  static setDataActivityCount(data: any, index: number) {
    if (!data.activities) data.activities = [];
    while (data.activities.length <= index) {
      const newActivity: Humboldt2020SupportAdminActivityData = {
        index: data.activities.length,
        activityDate: null,
        activity: null,
        cwsProgramSupport: null,
        activityMinutesSpent: null,
        travelMinutesSpent: null,
        activityDescription: null
      };
      data.activities.push(newActivity);
    }
  }
}
